import { DayIndex } from '@root/store/commonTypes'
import { useCallback, useEffect, useState } from 'react'
import { useDragZone } from './useDragZone'
import { WeekDimensions } from './useWeekDimensions.types'

export function useSessionsPlacerDimensions(dates: DayIndex[], noPadding: boolean, dimensionsBasedOnWindowObject: boolean) {
	const { container, setContainer, dragZoneId } = useDragZone('calendar')

	const calculateDimensions = useCallback((): WeekDimensions => {
		const isMultiSpan = dates.length > 1
		const containerWidth = container?.getBoundingClientRect().width
		const width = (dimensionsBasedOnWindowObject ? window.innerWidth - 60 : containerWidth) - (isMultiSpan ? 50 : 0)

		return {
			padding: noPadding ? 0 : 30,
			gap: noPadding ? 0 : 10,
			containerWidth: noPadding ? width : width - 60,
			dayWidth: ((noPadding ? width : width - 60) - dates.length - 1) / dates.length,
		}
	}, [container, dates, dimensionsBasedOnWindowObject, noPadding])

	const [dimensions, setDimensions] = useState(calculateDimensions())

	useEffect(() => {
		const resizeCallback = () => {
			requestAnimationFrame(() => {
				setDimensions(calculateDimensions())
			})
		}
		resizeCallback()
		window.addEventListener('resize', resizeCallback)
		return () => {
			window.removeEventListener('resize', resizeCallback)
		}
	}, [calculateDimensions])

	return { dimensions, container, setContainer, dragZoneId }
}
