import { Button } from '@comps/interactive/button';
import { Flex } from '@comps/layout/flex';
import { SimplePage } from '@comps/layout/simplePage';
import { SimplePageLogo } from '@comps/layout/simplePageLogo';
import { Bouncer } from '@comps/static/bouncer';
import { Text } from '@comps/typography/text';
import { useWatcherObserver } from '@root/hooks/useWatcher';
import { authActions } from '@root/store/slices/auth';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

type EmailActivationState = 'loading' | 'wrong-code' | 'success'
export const AuthHandlerEmailActivation: React.FC = () => {
	const dispatch = useDispatch()
	const [state, setState] = useState<EmailActivationState>('loading')
	const { state: watcher } = useWatcherObserver('EMAIL_ACTIVATION')
	const [queryParams] = useSearchParams()
	const navigate = useNavigate()

	useEffect(() => {
		const actionCode = queryParams.get('oobCode')
		if (actionCode) dispatch(authActions.authHandleEmailVerification({ actionCode, watcherId: 'EMAIL_ACTIVATION' }))
		else setState('wrong-code')
	}, [dispatch, setState, queryParams])

	useEffect(() => {
		if (watcher == 'done') {
			setState('success')
		} else if (watcher == 'fail') {
			setState('wrong-code')
		}
	}, [watcher])

	const handleClick = useCallback(() => {
		dispatch(authActions.logout())
		navigate('/')
	}, [navigate, dispatch])

	if (state == 'success')
		return (
			<SimplePage center>
				<Flex grow align="center" justify="center" minHeight="100%" direction="column" spacing={30} maxWidth="400px">
					<SimplePageLogo larger />
					<Text level="title70">Success!</Text>
					<Text level="body" align="center">
						Your email has now been confirmed.
						<br />
						You can now start using Clepside.
					</Text>
					<Button inset="buttonMedium" color="accent" onClick={handleClick}>
						Continue to Clepside
					</Button>
				</Flex>
			</SimplePage>
		)

	if (state == 'wrong-code')
		return (
			<SimplePage center>
				<Flex grow align="center" justify="center" minHeight="100%" direction="column" spacing={30} maxWidth="400px">
					<SimplePageLogo larger />
					<Text level="title70">Bad Activation Code</Text>
					<Text level="body" align="center">
						This email has either been activated before or the provided activation code is not valid at all.
					</Text>
					<Link to="/">
						<Button inset="buttonMedium" color="accent">
							Take me back
						</Button>
					</Link>
				</Flex>
			</SimplePage>
		)

	return (
		<SimplePage center>
			<Flex grow align="center" justify="center" minHeight="100%">
				<Bouncer size={42} />
			</Flex>
		</SimplePage>
	)
}
