import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';

import { RootState } from '@root/store';

import { FlowsController } from './FlowsController';

export const FlowsControllerBouncer: React.FC = () => {
	const selector = useSelector((state: RootState) => state.flows)
	return <AnimatePresence>{selector.activeFlows.length > 0 ? <FlowsController /> : null}</AnimatePresence>
}
