import { ActivityData } from './activitiesTypes'
import { BoardData } from './boardsTypes'
import { NormalisedRoutineScheduleData } from './routineSchedules.types'

export const AllPotentialFlows = [
	'plan-session' as const,
	'create-or-edit-activity' as const,
	'create-or-edit-board' as const,
	'share' as const,
	'share-select-role' as const,
	'select-date' as const,
	'routine-schedule-editing' as const,
]

export type FlowID = typeof AllPotentialFlows[0]

export const FlowsArchitecture = {
	'plan-session': ['select-activity' as const],
	'create-or-edit-activity': ['overview' as const, 'select-color' as const, 'select-icon' as const],
	'share': ['share-manage' as const],
	'select-date': ['select-date' as const],
	'create-or-edit-board': ['overview' as const,  'select-color' as const],
	'share-select-role': ['share-select-role' as const],
	'routine-schedule-editing': [
		'routine-select-period' as const,
		'routine-period-daily' as const
	]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TypeCheckFlowsArchitectureType = {
    [key in FlowID]: typeof FlowsArchitecture[key]
};

type GenericFlowContext<K extends FlowID> = {
	flowType: K,
	ancestor?: FlowID,
	navigationStack: TypeCheckFlowsArchitectureType[K][0][],
}

type FlowContextPlanSession = GenericFlowContext<'plan-session'> & {
	start: string,
	end: string,
	createdActivityId?: string
}
type FlowContextEditRoutineSchedule = GenericFlowContext<'routine-schedule-editing'> & {
	schedule?: NormalisedRoutineScheduleData
}
type FlowContextCreateActivity = GenericFlowContext<'create-or-edit-activity'> & Omit<ActivityData, 'id' | 'role'> & { id?: string }
type FlowContextCreateBoard = GenericFlowContext<'create-or-edit-board'> & Omit<BoardData, 'id'> & { id?: string }
type FlowContextSelectDate = GenericFlowContext<'select-date'> & { date?: Date }
type FlowContextShare = GenericFlowContext<'share'> & { activityId: string }
type FlowContextShareSelectRole = GenericFlowContext<'share-select-role'> & { activityId: string, email: string }
export type FlowRequiredContext = FlowContextPlanSession 
| FlowContextCreateActivity 
| FlowContextShare 
| FlowContextShareSelectRole 
| FlowContextCreateBoard 
| FlowContextEditRoutineSchedule
| FlowContextSelectDate

export type FlowQueryContext = {
	flowType: 'plan-session',
	start?: string,
	end?: string
	createdActivityId?: string
} | {
	flowType: 'create-or-edit-activity',
	activityData?: ActivityData,
	ancestor?: FlowID
} | {
	flowType: 'create-or-edit-board',
	boardData?: BoardData,
} | {
	flowType: 'share',
	activityId: string
} | {
	flowType: 'share-select-role',
	activityId: string
	email: string
} | {
	flowType: 'routine-schedule-editing',
	routineScheduleData?: NormalisedRoutineScheduleData
} | {
	flowType: 'select-date',
	date?: Date
	ancestor?: FlowID
}


type FlowContextForID<ID extends FlowID> = 
  Extract<FlowRequiredContext, { flowType: ID }> extends infer T
    ? T extends FlowRequiredContext 
      ? T
      : never
    : never;

export const flowsState: {
	activeFlows: FlowID[]	
	flowContexts: {
		[id in FlowID]?: FlowContextForID<id>
	}
} = {
	activeFlows: [],
	flowContexts: {}
}