



import { Database, SyncBoardEntity } from '@root/database';

import {
    getSearchableTermsFromPacketOrRune
} from './persist.search.getSearchableTermsFromPacketOrRune';
import { RuneObject } from './runes.types';
import { StoringExtras } from './sync.tsx.packets.types';
import { SyncableRune, ValidRuneType } from './sync.types';
import { SyncKey } from './sync.utils';

export function* mergeRunesToLocalTransaction<T extends ValidRuneType>(runes: RuneObject<T>[], config: SyncableRune<any>, syncKey?: SyncKey): any {
	const writes: Promise<any>[] = []
	const { database: db } = config
	const storingExtra: { [id: string]: StoringExtras }  = {}

	// Make searchable
	for (const rune of runes) {
		const searchTerms = yield getSearchableTermsFromPacketOrRune(undefined, rune)
		storingExtra[rune.id] = {
			clientOnlySearchTerms: searchTerms
		}
	}
	
	const finalRunes: RuneObject<any> = yield Database.transaction('rw', [db, Database.syncBoard], async () => {
		// let localRune: RuneObject<T> | undefined
		
		const committedRunes = []
		let highestStamp = 0

		for (const rune of runes) {
			let localRune: RuneObject<any> | undefined
			
			try {
				localRune = await db.get(rune.id)
			} catch (e) {
				console.error('Failed to get the local rune', e)
			}

			if (!localRune || localRune?.lastModifiedAt < rune.lastModifiedAt) {
				const freshRune = {
					...storingExtra[rune.id],
					...rune,
				}
				committedRunes.push(freshRune)
				writes.push(db.put(freshRune as any, rune.id))
			}

			if (rune.lastModifiedAt > highestStamp) {
				highestStamp = rune.lastModifiedAt					
			}
		}

		if (syncKey) {
			let sync = undefined
			try {
				sync = await Database.syncBoard.get(syncKey)
			} catch (e) {
				console.error('Failed to get the syncKey', e)
			}

			if (highestStamp > (sync?.at || 0)) {
				writes.push(Database.syncBoard.put({
					...(sync as SyncBoardEntity),
					key: syncKey,
					at: highestStamp,
				}, syncKey))
			}
		}

		try {
			await Promise.all(writes)
		} catch (e) {
			console.error(e)
		}	
		
		return committedRunes
	})

	return finalRunes
}