import { SyncServiceClient } from '@clepside/clepsidejs/lib/services/sync_v1_grpc_web_pb'
import {
	BatchedSendablesStream,
	FetchActivitiesRequest,
	FetchBoardsRequest,
	FetchNotificationsRequest,
	FetchSessionsByActivityRequest,
	SendableStream,
	StreamActivityRequest,
	StreamBoardRequest,
	StreamCardRequest,
	StreamRoutineRequest,
	StreamSessionRequest
} from '@clepside/clepsidejs/lib/services/sync_v1_pb'
import { EventChannel, eventChannel } from 'redux-saga'
import { getGRPCMeta, mountStreamListeners } from './utils'

if (!process.env.REACT_APP_API_URL)
    throw new Error('No RAPURL')
export const SyncClient = new SyncServiceClient(process.env.REACT_APP_API_URL)

export const SyncGRPC = {
    constructBoardsFetchingChannel: (request: FetchBoardsRequest) => new Promise<EventChannel<BatchedSendablesStream>>((res, rej) => {
            (async () => {
                try {
                    const meta = await getGRPCMeta()
                    const stream = SyncClient.fetchBoards(request, meta)
                    const channel = eventChannel<BatchedSendablesStream>(mountStreamListeners(stream, 'BoardsFetching'))
                    res(channel)
                } catch (e) {
                    console.error('Failed to start the stream: ', e)
                    rej(e)
                }
            })();
        }
    ),
    constructActivitiesFetchingChannel: (request: FetchActivitiesRequest) => new Promise<EventChannel<BatchedSendablesStream>>((res, rej) => {
            (async () => {
                try {
                    const meta = await getGRPCMeta()
                    const stream = SyncClient.fetchActivities(request, meta)
                    const channel = eventChannel<BatchedSendablesStream>(mountStreamListeners(stream, 'ActivitiesFetching'))
                    res(channel)
                } catch (e) {
                    console.error('Failed to start the stream: ', e)
                    rej(e)
                }
            })();
        }
    ),
    constructSessionsStreamingChannel: (request: StreamSessionRequest) => new Promise<EventChannel<SendableStream>>((res, rej) => {
        (async () => {
            try {
                const meta = await getGRPCMeta()
                const stream = SyncClient.streamSession(request, meta)
                const channel = eventChannel<SendableStream>(mountStreamListeners(stream, 'SessionsStreaming'))
                res(channel)
            } catch (e) {
                console.error('Failed to start the session stream: ', e)
                rej(e)
            }
        })();
    }),
    constructBoardStreamingChannel: (request: StreamBoardRequest) => new Promise<EventChannel<SendableStream>>((res, rej) => {
        (async () => {
            try {
                const meta = await getGRPCMeta()
                const stream = SyncClient.streamBoard(request, meta)
                const channel = eventChannel<SendableStream>(mountStreamListeners(stream, 'BoardStreaming'))
                res(channel)
            } catch (e) {
                console.error('Failed to start the board stream: ', e)
                rej(e)
            }
        })();
    }),
    constructCardStreamingChannel: (request: StreamCardRequest) => new Promise<EventChannel<SendableStream>>((res, rej) => {
        (async () => {
            try {
                const meta = await getGRPCMeta()
                const stream = SyncClient.streamCard(request, meta)
                const channel = eventChannel<SendableStream>(mountStreamListeners(stream, 'CardStreaming'))
                res(channel)
            } catch (e) {
                console.error('Failed to start the card stream: ', e)
                rej(e)
            }
        })();
    }),
    constructRoutinesStreamingChannel: (request: StreamRoutineRequest) => new Promise<EventChannel<SendableStream>>((res, rej) => {
        (async () => {
            try {
                const meta = await getGRPCMeta()
                const stream = SyncClient.streamRoutine(request, meta)
                const channel = eventChannel<SendableStream>(mountStreamListeners(stream, 'RoutinesStreaming'))
                res(channel)
            } catch (e) {
                console.error('Failed to start the routines stream: ', e)
                rej(e)
            }
        })();
    }),
    constructActivitiesStreamingChannel: (request: StreamActivityRequest) => new Promise<EventChannel<SendableStream>>((res, rej) => {
        (async () => {
            try {
                const meta = await getGRPCMeta()
                const stream = SyncClient.streamActivity(request, meta)
                const channel = eventChannel<SendableStream>(mountStreamListeners(stream, 'ActivitiesStreaming'))
                res(channel)
            } catch (e) {
                console.error('Failed to start the activities stream: ', e)
                rej(e)
            }
        })();
    }),
    constructNotificationsStreamingChannel: (request: FetchNotificationsRequest) => new Promise<EventChannel<BatchedSendablesStream>>((res, rej) => {
        (async () => {
            try {
                const meta = await getGRPCMeta()
                const stream = SyncClient.fetchNotifications(request, meta)
                const channel = eventChannel<BatchedSendablesStream>(mountStreamListeners(stream, 'NotificationsStreaming'))
                res(channel)
            } catch (e) {
                console.error('Failed to start the notifications stream: ', e)
                rej(e)
            }
        })();
    }),
    constructSessionsByActivityStreamingChannel: (request: FetchSessionsByActivityRequest) => new Promise<EventChannel<SendableStream>>((res, rej) => {
        (async () => {
            try {
                const meta = await getGRPCMeta()
                const stream = SyncClient.fetchSessionsByActivity(request, meta)
                const channel = eventChannel<SendableStream>(mountStreamListeners(stream, 'SessionsByActivityFetching'))
                res(channel)
            } catch (e) {
                console.error('Failed to start the activities stream: ', e)
                rej(e)
            }
        })();
    }),
}
