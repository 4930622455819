import { useSelector } from 'react-redux';

import { RootState } from '@root/store';
import { ViewID } from '@root/store/slices/interface.types';

export const useHotkeysActiveScope = () => {
	const scope = useSelector((state: RootState) => state.interface?.scopeWithIds)
	const selection = useSelector((state: RootState) => state.selection.scope)

    return (selection || scope) as ViewID
}