import { useCallback } from 'react';

import { DragDropCore } from '@root/cores/dragDropCore';
import { isNotLeftClick } from '@root/utils/general';

import { SessionPlacerDimensions } from './sessionPlacer.types';
import { getCursorDateFromEvent } from './sessionPlacer.utils';

export const useSessionHighlightStart = (
	shownDatesIndexes: number[],
	vector: number[],
	container: HTMLDivElement,
	dimensions: SessionPlacerDimensions
) => {
	const handleDragStart = useCallback(
		(e: any) => {
			if (isNotLeftClick(e)) return
			const start = getCursorDateFromEvent(container, e, shownDatesIndexes, vector, dimensions)

			if (start)
				DragDropCore.startMoving(e, {
					type: 'highlight',
					startPoint: start.getTime(),
				})
		},
		[container, shownDatesIndexes, dimensions, vector]
	)

	return {
		handleDragStart,
	}
}
