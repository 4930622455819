
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { arrayInsertUnique } from '@root/utils/general';

import { ViewID } from './interface.types';
import { SelectionItemContext, SelectionStatus, State } from './selection.types';

const selectionSlice = createSlice({
	name: 'selection',
	initialState: State,
	reducers: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
        reset: (state, {  }: PayloadAction) => {
            // state.context = undefined
            state.status = SelectionStatus.EMPTY
            state.scope = undefined
			state.scopeTemplate = undefined
            state.all = []
			state.type = undefined
        },
		editContext: (state, { payload: {  forId, ctx } }: PayloadAction<{ forId: string,  ctx: Partial<SelectionItemContext> }>) => {
			for (let i = 0; i < state.all.length; i++) { 
				if (state.all[i].id == forId) {
					state.all[i] = {
						...state.all[i],
						...(ctx as any)
					}
				}
			}
		},
		select: (state, { payload: {  ids, scopeTemplate, scope, append } }: PayloadAction<{ ids: SelectionItemContext[],  scopeTemplate?: ViewID, scope?: string, append?: boolean }>) => {
			let uniformContext = undefined
			for (const ctx of state.all) {
				if (uniformContext == undefined) {
					uniformContext = ctx.itemType
					break
				}
			}
			let itemType = undefined
			for (const id of ids) {
				if (!id) continue
				if (uniformContext == id.itemType) {
					if (append) {
						if (state.all.find((i) => i.id == id.id)) {
							state.all = state.all.filter(j => j.id != id.id)
						} else {
							state.all = arrayInsertUnique(state.all, id).filter(j => !!j)
						}
						itemType = id.itemType
					} else {
						// state.context = context
						state.all = ids.filter(j => !!j)
						itemType = id.itemType
					}
				} else {
					// state.context = context
					state.all = ids.filter(j => !!j)
					itemType = id.itemType
				}
			}

			if (itemType !== undefined) {
				state.type = itemType
			}

			// if (scope)
            // 	state.scope = scope
			
			// if (scopeTemplate)
			// 	state.scopeTemplate = scopeTemplate

			if (state.all.length > 1) {
				if (state.status != SelectionStatus.MULTI_SELECTED) {
					state.status = SelectionStatus.MULTI_SELECTED
				}
			}
        },
	},
})

export const selectionSagas = {
	// *select({ payload }: ReturnType<typeof selectionSlice.actions.select>): any {
	// 	const { runeObject } = payload
	// 	for (const [name, sendableConfig] of Object.entries(SendableConfig)) {
	// 		if ('store' in sendableConfig && sendableConfig.store && sendableConfig.rop == 'rune') {
	// 			if (sendableConfig.type == runeObject.type) {
	// 				const store = sendableConfig.store as any
	// 				const rune = getRuneObjectFromRemoteObject(runeObject)
	// 				if (rune) {
	// 					yield put(store(rune))
	// 				}
	// 			}
	// 		}
	// 	}
	// },
}

export const selectionActions = selectionSlice.actions
export const selectionReducers = selectionSlice.reducer
