import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb';;
import { useMemo } from 'react';
import styled from 'styled-components';

export const ADCMIcon: React.FC<{ type: CardType }> = ({ type }) => {
	const icon = useMemo(() => {
		switch (type) {
			case CardType.NOTE:
				return (
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M6 0C2.68629 0 0 2.68629 0 6V10C0 13.3137 2.68629 16 6 16H10C13.3137 16 16 13.3137 16 10V6C16 2.68629 13.3137 0 10 0H6ZM11 5H5V6H11V5ZM5 7.5H11V8.5H5V7.5ZM8 10H5V11H8V10Z"
							fill="#B79A00"
						/>
					</svg>
				)
			case CardType.TODO:
				return (
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M6 0C2.68652 0 0 2.68652 0 6V10C0 13.3135 2.68652 16 6 16H10C13.3135 16 16 13.3135 16 10V6C16 2.68652 13.3135 0 10 0H6ZM11.3945 5.80713L10.6055 5.19287L7.45312 9.24609L5.85352 7.64648L5.14648 8.35352L7.54688 10.7539L11.3945 5.80713Z"
							fill="#00A66A"
						/>
					</svg>
				)
			case CardType.LINK:
				return (
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M6 0C2.68652 0 0 2.68652 0 6V10C0 13.3135 2.68652 16 6 16H10C13.3135 16 16 13.3135 16 10V6C16 2.68652 13.3135 0 10 0H6ZM10.5381 4.69629C9.79199 3.91113 8.58301 3.91113 7.83691 4.69629L6.74219 5.84766C5.99609 6.63281 5.99609 7.90527 6.74219 8.68994C7.15723 9.12646 7.71484 9.32031 8.25684 9.27148L7.91211 8.23535C7.85156 8.22266 7.79102 8.20361 7.7334 8.17822C7.69336 8.16064 7.65527 8.14062 7.61719 8.11768C7.55078 8.07617 7.48828 8.02539 7.43066 7.96533C7.06543 7.58057 7.06543 6.95703 7.43066 6.57275L8.52539 5.4209C8.89062 5.03662 9.4834 5.03662 9.84863 5.4209C10.1943 5.78467 10.2139 6.36182 9.90527 6.74902L10.4541 7.62646L10.5381 7.53857C11.2842 6.75342 11.2842 5.48096 10.5381 4.69629ZM5.56934 8.34814L5.46191 8.46094C4.71582 9.24609 4.71582 10.5186 5.46191 11.3032C6.20801 12.0884 7.41699 12.0884 8.16309 11.3032L9.25781 10.1519C10.0039 9.3667 10.0039 8.09424 9.25781 7.30957C8.86426 6.89502 8.34082 6.69922 7.8252 6.72266L8.38281 7.88232C8.44922 7.92383 8.51172 7.97412 8.56836 8.03418C8.93457 8.41895 8.93457 9.04248 8.56836 9.42676L7.47461 10.5786C7.1084 10.9629 6.5166 10.9629 6.15039 10.5786C5.99805 10.418 5.90918 10.2153 5.88379 10.0059C5.85254 9.74414 5.9209 9.47168 6.08984 9.25635L5.56934 8.34814Z"
							fill="#5484FF"
						/>
					</svg>
				)
			case CardType.CODEBOX:
				return (
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M6 0C2.68652 0 0 2.68652 0 6V10C0 13.3135 2.68652 16 6 16H10C13.3135 16 16 13.3135 16 10V6C16 2.68652 13.3135 0 10 0H6ZM6.66602 12H7.68457L9.26465 4H8.24609L6.66602 12ZM4.70703 8L6.35352 6.35352L5.64648 5.64648L3.29297 8L5.64648 10.3535L6.35352 9.64648L4.70703 8ZM11.293 8L9.64648 6.35352L10.3535 5.64648L12.707 8L10.3535 10.3535L9.64648 9.64648L11.293 8Z"
							fill="#878BA0"
						/>
					</svg>
				)
			case CardType.AI_CHAT:
				return (
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
						<path
							d="M7 6.5C6.17188 6.5 5.5 7.17139 5.5 8C5.5 8.82861 6.17188 9.5 7 9.5H9C9.82812 9.5 10.5 8.82861 10.5 8C10.5 7.17139 9.82812 6.5 9 6.5H7Z"
							fill="#F51D92"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M6 0C2.68652 0 0 2.68652 0 6V10C0 13.3135 2.68652 16 6 16H10C13.3135 16 16 13.3135 16 10V6C16 2.68652 13.3135 0 10 0H6ZM4.5 8C4.5 6.61914 5.61914 5.5 7 5.5H9C10.3809 5.5 11.5 6.61914 11.5 8C11.5 8.77002 11.1514 9.45898 10.6045 9.91748L11 11.5L8.27246 10.5H7C5.61914 10.5 4.5 9.38086 4.5 8Z"
							fill="#F51D92"
						/>
					</svg>
				)
			case CardType.AI_GIST:
				return (
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M6 0C2.68652 0 0 2.68652 0 6V10C0 13.3135 2.68652 16 6 16H10C13.3135 16 16 13.3135 16 10V6C16 2.68652 13.3135 0 10 0H6ZM5.00879 6.5249C5.08008 6.85889 5.1416 7.19189 5.19434 7.51709C5.2334 7.76025 5.26758 7.99854 5.29688 8.229C5.3291 8.47656 5.35645 8.71533 5.37891 8.94189L6.47656 10.2231L6.45996 9.91553C6.43945 9.57227 6.4082 9.1626 6.36035 8.70898C6.31641 8.28955 6.25781 7.83301 6.18164 7.35791C6.1416 7.11279 6.09668 6.86182 6.04688 6.60791C5.94141 6.07715 5.81152 5.5332 5.65137 5H4.60254C4.67871 5.23242 4.74902 5.46875 4.81348 5.70703C4.88574 5.97803 4.9502 6.25195 5.00879 6.5249ZM9.52344 10.2227L10.6211 8.94189C10.6445 8.71338 10.6719 8.47217 10.7041 8.22217C10.7334 7.99365 10.7676 7.75781 10.8066 7.51709C10.9395 6.69189 11.1289 5.81885 11.3975 5H10.3486C10.2549 5.31104 10.1719 5.62549 10.0977 5.93896C9.98438 6.42041 9.89355 6.89941 9.81934 7.35791C9.78516 7.56836 9.75488 7.77539 9.72754 7.97705C9.70508 8.14502 9.68555 8.30908 9.66699 8.46875C9.60352 9.01807 9.56445 9.5127 9.54004 9.91553L9.52344 10.2227ZM8.5 5V10.1338C8.79883 10.3066 9 10.6299 9 11C9 11.5522 8.55273 12 8 12C7.44727 12 7 11.5522 7 11C7 10.7744 7.07422 10.5664 7.2002 10.3989C7.28125 10.2915 7.38281 10.2012 7.5 10.1338V5H8.5Z"
							fill="#7E47F2"
						/>
					</svg>
				)
			default:
				return null
		}
	}, [type])

	return <Holder>{icon}</Holder>
}

const Holder = styled.div`
	background-color: transparent;
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;

	svg {
		position: relative;
		z-index: 1;
	}

	&:before {
		display: block;
		content: '';
		position: absolute;
		width: calc(100% - 2px);
		height: calc(100% - 2px);
		margin-top: 1px;
		margin-left: 1px;
		z-index: 0;
		border-radius: 999px;
		background-color: #fff;
	}
`
