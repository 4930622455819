import { FontStyles } from '@comps/typography/text'
import { getDateFromIndex } from '@root/utils/dates'
import { range } from '@utils/general'
import { addHours, format, isSameDay, isSameHour } from 'date-fns'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { SessionPlacerDimensions } from './sessionPlacer.types'

export const DayGrid: React.FC<{
	height: number
	vector: number[]
	hideTimes?: boolean
	dimensions: SessionPlacerDimensions
	displayVector: boolean[]
	presentedDatesIndexes?: number[]
}> = ({ height, displayVector, vector, hideTimes, dimensions, presentedDatesIndexes }) => {
	// const mouseMover = (e: any) => {
	// 	const column = Math.floor(e.clientX / dimensions.dayWidth)
	// 	const gap = column > 0 ? column * dimensions.gap : 0
	// 	setX(dimensions.padding + column * dimensions.dayWidth + gap)
	// }

	const hoursArray = useMemo(() => {
		if (!presentedDatesIndexes) throw new Error('no presented dates')
		const dict: Date[][] = []
		for (let k = 0; k < presentedDatesIndexes.length; k++) {
			const start = getDateFromIndex(presentedDatesIndexes[k])
			if (!start) throw new Error('no start date')

			const arr: Date[] = []
			let i = new Date(start)
			while (isSameDay(i, start)) {
				arr.push(i)
				i = addHours(i, 1)
			}
			dict.push(arr)
		}
		return dict
	}, [presentedDatesIndexes])

	const grid = useMemo(() => {
		return hoursArray.map((array, colIndex) => {
			return array.map((date, index, arr) => {
				const dstForward = array.findLastIndex((v, i, a) => (i > 0 ? isSameHour(v, a[i - 1]) : false))
				const dstBackward = array.findLastIndex((v, i, a) => (i > 0 ? v.getHours() - a[i - 1].getHours() == 2 : false))
				const shouldDisplayTime =
					(dstForward != -1 ? index >= dstForward : false) || (dstBackward != -1 ? index >= dstBackward : false) || colIndex == 0
				return (
					<Hour key={index} height={height * vector[index]} isLast={date.getHours() == 24}>
						{shouldDisplayTime && (
							<>
								<Time style={{ opacity: 1 }} isLast={date.getHours() == 24}>
									{format(date, 'HH:mm')}
									{dstForward && index == dstForward ? (
										<>
											<br />
											DST+1
										</>
									) : null}
									{dstBackward && index == dstBackward ? (
										<>
											<br />
											DST-1
										</>
									) : null}
								</Time>
								{index == arr.length - 1 ? <EndTime>24:00</EndTime> : null}
							</>
						)}
					</Hour>
				)
			})
		})
	}, [height, hoursArray, vector])

	const isMultiSpan = (presentedDatesIndexes?.length || 0) > 1
	return (
		<Holder
			padding={dimensions.padding}
			width={dimensions.dayWidth || 0}
			numberOfDays={presentedDatesIndexes?.length || 1}
			gap={dimensions.gap}
			isMultiSpan={isMultiSpan}
		>
			{presentedDatesIndexes?.map((d, dayIndex) => (
				<DayColumn isMultiSpan={isMultiSpan} key={d.toString()}>
					{grid[dayIndex]}
				</DayColumn>
			))}
		</Holder>
	)
}

const Hour = styled.div<{
	height: number
	isLast?: boolean
}>`
	position: relative;
	height: ${(p) => p.height}px;

	&:before {
		content: '';
		position: absolute;
		bottom: 0px;
		left: 0;
		width: 100%;
		height: 1px;
		opacity: 0.62;
		background-image: linear-gradient(to right, ${(p) => p.theme.border.subtle.r.hex} 40%, rgba(255, 255, 255, 0) 0%);
		background-position: bottom;
		background-size: 9px 4px;
		background-origin: 0px 0px;
		background-repeat: repeat-x;
	}

	&:last-child:before {
		bottom: 2px;
	}

	&:first-child:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background-image: linear-gradient(to right, ${(p) => p.theme.border.subtle.r.hex} 33.3%, rgba(255, 255, 255, 0) 0%);
		background-position: bottom;
		background-size: 7px 1px;
		background-origin: 0px 0px;
		background-repeat: repeat-x;
	}
`

const DayColumn = styled.div<{ isMultiSpan: boolean }>`
	display: flex;
	flex-direction: column;
	padding-bottom: 110px;

	${(p) =>
		p.isMultiSpan &&
		css`
			&:first-child {
				margin-left: -50px;
				width: calc(100% + 50px);
			}
		`}
`

const Holder = styled.div<{ padding: number; numberOfDays: number; gap: number; width: number; isMultiSpan: boolean }>`
	padding: 0 ${(p) => p.padding}px;
	width: 100%;
	align-items: stretch;
	position: relative;
	min-height: 100vh;
	display: grid;
	grid-template-columns: ${(p) => range(p.numberOfDays).map(() => `${p.width}px `)};
	grid-column-gap: ${(p) => p.gap}px;

	${(p) =>
		p.isMultiSpan &&
		css`
			margin-left: 50px;
		`}

	cursor: pointer;
	* {
		cursor: pointer;
	}
`

const Time = styled.div<{
	isLast?: boolean
	alignCenter?: boolean
	shouldHide?: boolean
}>`
	position: absolute;
	top: -10px;
	left: 0;
	${FontStyles.tiny};
	width: 48px;
	text-align: left;
	${(p) => p.theme.text.subtle.r.css('color')};
	background-color: #fff;
	z-index: 2;

	${(p) =>
		p.alignCenter &&
		css`
			left: 50%;
			transform: translateY(-50%);
		`}

	${(p) =>
		p.shouldHide &&
		css`
			display: none;
		`}

	${(p) =>
		p.isLast &&
		css`
			top: -20px;
		`}
`

const EndTime = styled(Time)`
	bottom: 5px;
	top: auto;
`
