import { CardChatMessageRuneObject, CardChatRuneObject, NotificationRuneObject, SharingInvitationRuneObject } from '@root/store/slices/runes.types'
import {
	ActivityPacketObject,
	BoardPacketObject,
	CardPacketObject,
	FragmentCompletablePacketObject,
	FragmentPlainTextPacketObject,
	FragmentUploadablePacketObject,
	LocalQueueObject,
	PacketObject,
	RoutinePacketObject,
	RoutineSchedulePacketObject,
	SessionPacketObject,
	SettingsPacketObject
} from '@root/store/slices/sync.tsx.packets.types'
import { SyncKey, SyncKeyTemplate } from '@root/store/slices/sync.utils'
import { UserProfileDetails } from '@root/store/slices/userProfiles.types'
import { DayIndex, ULID } from '@store/commonTypes'
import Dexie from 'dexie'

export type VitalsData = {
	id: '_'
	activitiesLastSync: number
}

export type LocalState = {
	id: string
	data: string
}

export enum SyncBoardStatus {
	NEVER_SYNCED,
	SYNCING_NOW,
	SYNCING_INTERRUPTED,
	NEEDS_SYNCING,
	FULLY_SYNCED,
}

export type SyncBoardEntity = {
	key: SyncKey
	at: number
	template?: SyncKeyTemplate
	status: SyncBoardStatus
}

export type SessionPacketObjectWithDaystamps = SessionPacketObject & { daystamps: DayIndex[] }

export type StoredUserProfileImage = {
	id: string
	data: Blob
}

export type ImageProcessingPayload = {
	id: string
	filepath: string
	fragmentId: string
	type:
	| {
		op: 'fetch-favicon'
	}
	| {
		op: 'fetch-card-image'
	}
	| {
		op: 'upload-card-image'
	}
	queuedAt: number
	sendAt: number
	status: ImageProcessingStatus
}

export type CriticalOp = {
	id: string
	op:
	| {
		type: 'fetch-sessions-for-activity'
		activityId: string
	}
	| {
		type: 'stream-activity-routines'
		activityId: string
	}
	| {
		type: 'stream-routine'
		routineId: string
	}
}

export type ImageProcessingStatus = 'needs-fetching' | 'fetched' | 'needs-upload'

export interface Databases {
	activityPackets: Dexie.Table<ActivityPacketObject, ULID>
	sessionPackets: Dexie.Table<DeepSearchable<SessionPacketObject>, ULID>
	cardPackets: Dexie.Table<CardPacketObject, ULID>
	fragmentPacketsCompletable: Dexie.Table<DeepSearchable<FragmentCompletablePacketObject>, ULID>
	fragmentPacketsPlainText: Dexie.Table<DeepSearchable<FragmentPlainTextPacketObject>, ULID>
	sharingInvitationRunes: Dexie.Table<SharingInvitationRuneObject, ULID>
	aiCardChatsRunes: Dexie.Table<CardChatRuneObject, ULID>
	aiCardChatsMessagesRunes: Dexie.Table<DeepSearchable<CardChatMessageRuneObject>, ULID>
	localState: Dexie.Table<LocalState, string>
	syncBoard: Dexie.Table<SyncBoardEntity, SyncKey>
	settings: Dexie.Table<SettingsPacketObject, ULID>
	userProfiles: Dexie.Table<UserProfileDetails, ULID>
	criticalOps: Dexie.Table<CriticalOp, ULID>
	userProfileImages: Dexie.Table<StoredUserProfileImage, ULID>
	imageProcessing: Dexie.Table<ImageProcessingPayload, ULID>
	queue: Dexie.Table<PacketObject<any>, ULID>
}

type DeepSearchable<T> = T & {
	clientOnlySearchTerms: string[]
}

export class DB extends Dexie implements Databases {
	activityPackets: Dexie.Table<DeepSearchable<ActivityPacketObject>, ULID>

	sessionPackets: Dexie.Table<DeepSearchable<SessionPacketObject>, ULID>

	boardPackets: Dexie.Table<BoardPacketObject, ULID>

	routinePackets: Dexie.Table<RoutinePacketObject, ULID>

	routineSchedulePackets: Dexie.Table<RoutineSchedulePacketObject, ULID>

	cardPackets: Dexie.Table<CardPacketObject, ULID>

	fragmentPacketsCompletable: Dexie.Table<DeepSearchable<FragmentCompletablePacketObject>, ULID>

	fragmentPacketsPlainText: Dexie.Table<DeepSearchable<FragmentPlainTextPacketObject>, ULID>

	fragmentPacketsUploadable: Dexie.Table<DeepSearchable<FragmentUploadablePacketObject>, ULID>

	criticalOps: Dexie.Table<CriticalOp, ULID>
	// vitalsPackets: Dexie.Table<VitalsPacketObject, ULID>

	sharingInvitationRunes: Dexie.Table<SharingInvitationRuneObject, ULID>

	notificationRunes: Dexie.Table<NotificationRuneObject, ULID>

	settings: Dexie.Table<SettingsPacketObject, ULID>

	localState: Dexie.Table<LocalState, string>

	syncBoard: Dexie.Table<SyncBoardEntity, SyncKey>

	userProfiles: Dexie.Table<UserProfileDetails, ULID>

	userProfileImages: Dexie.Table<StoredUserProfileImage, ULID>

	aiCardChatsRunes: Dexie.Table<CardChatRuneObject, ULID>

	imageProcessing: Dexie.Table<ImageProcessingPayload, ULID>

	aiCardChatsMessagesRunes: Dexie.Table<DeepSearchable<CardChatMessageRuneObject>, ULID>

	favIcons: Dexie.Table<StoredUserProfileImage, ULID>

	queue: Dexie.Table<LocalQueueObject, ULID>

	constructor() {
		super('db')

		this.version(1).stores({
			activityPackets: 'id, data.title',
			sessionPackets: 'id, *daystamps, data.activityId, *clientOnlySearchTerms',
			cardPackets: 'id, data.resourceId',
			boardPackets: 'id',
			settings: 'id',
			fragmentPacketsPlainText: 'id, data.resourceId, data.cardId, *clientOnlySearchTerms',
			fragmentPacketsCompletable: 'id, data.resourceId, data.cardId, *clientOnlySearchTerms',
			fragmentPacketsUploadable: 'id, data.resourceId, data.cardId, *clientOnlySearchTerms',
			//+clientOnlyGrantingResourceId
			queue: 'id, clientOnlySyncOrder',
			localState: 'id',
			vitalsPackets: 'id',
			routinePackets: 'id',
			routineSchedulePackets: 'id',
			sharingInvitationRunes: '&id, rune.resourceId, rune.forUserId',
			notificationRunes: 'id, *lastModifiedAt',
			userProfiles: 'id',
			criticalOps: 'id',
			userProfileImages: '&id',
			aiCardChatsRunes: '&id, rune.cardId',
			aiCardChatsMessagesRunes: '&id, rune.cardId, *clientOnlySearchTerms',
			favIcons: '&id',
			imageProcessing: 'id, queuedAt, sendAt, fragmentId, status',
			syncBoard: 'key, template, status, [template+status]',
		})

		this.activityPackets = this.table('activityPackets')
		this.sessionPackets = this.table('sessionPackets')
		this.queue = this.table('queue')
		// this.vitalsPackets = this.table('vitalsPackets')
		this.boardPackets = this.table('boardPackets')
		this.cardPackets = this.table('cardPackets')
		this.fragmentPacketsPlainText = this.table('fragmentPacketsPlainText')
		this.fragmentPacketsCompletable = this.table('fragmentPacketsCompletable')
		this.fragmentPacketsUploadable = this.table('fragmentPacketsUploadable')
		this.localState = this.table('localState')
		this.syncBoard = this.table('syncBoard')
		this.routinePackets = this.table('routinePackets')
		this.routineSchedulePackets = this.table('routineSchedulePackets')
		this.userProfiles = this.table('userProfiles')
		this.settings = this.table('settings')
		this.criticalOps = this.table('criticalOps')
		this.userProfileImages = this.table('userProfileImages')
		this.notificationRunes = this.table('notificationRunes')
		this.aiCardChatsMessagesRunes = this.table('aiCardChatsMessagesRunes')
		this.aiCardChatsRunes = this.table('aiCardChatsRunes')
		this.favIcons = this.table('favIcons')
		this.imageProcessing = this.table('imageProcessing')
		this.sharingInvitationRunes = this.table('sharingInvitationRunes')
	}

	public async dropAll() {
		await this.delete()
		try {
			await caches.delete('favicons')
			await caches.delete('card-images')
		} catch (error) {
			console.error('Failed to delete caches:', error)
		}
	}
}

export const Database = new DB()

export function getDatabase<T extends keyof Databases>(db: T): T {
	return db
}
