import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { Collection, ULID } from '@root/store/commonTypes'
import { GrantableResourceType } from '@root/store/slices/cards.types'
import { ulid } from 'ulid'

export const isElectron = !!process.env.REACT_APP_ELECTRON
export const isWeb = process.env.REACT_APP_WEB

export const NewID = ulid
export type Direction = 'forward' | 'backwards'

export const cancelAllHandlers = (handlers: Array<() => any>) => {
	return () => {
		handlers.forEach((cancel: any) => {
			cancel()
		})
	}
}
export function xorArraySum(array: number[]) {
	let checksum = 0
	for (let i = 0; i < array.length; i++) {
		checksum ^= array[i] + i
	}
	return checksum
}

export function clamp(value: number, upperLimit: number): number {
	if (value < 0) {
		return 0
	} else if (value > upperLimit) {
		return upperLimit
	} else {
		return value
	}
}

export type WithoutUndefined<T> = T extends undefined ? never : T

export function isULID(id: string): boolean {
	if (typeof id !== 'string' || id.length !== 26) {
		return false
	}

	// ULID is case insensitive, but make it uppercase for simplicity
	const lulid = id.toUpperCase()

	// Check the ULID format with regex
	const ULID_REGEX = /^[0-9A-HJKMNP-TV-Z]{26}$/
	if (!ULID_REGEX.test(lulid)) {
		return false
	}

	// Check the timestamp part of ULID
	const timestampStr = lulid.substring(0, 10)
	const timestamp = parseInt(timestampStr, 32)

	// JavaScript's Date.now() returns current timestamp in milliseconds
	const currentTimestamp = Math.floor(Date.now() / 1000)

	if (timestamp > currentTimestamp) {
		return false
	}

	return true
}

export function parseTemplate<T, K>(key: T, ...args: Array<string | undefined>): K {
	let k: any = key
	for (const [index, param] of args.entries()) {
		k = k.replace(`<${index}>`, param || '')
	}
	return k
}

export const debugSection = (isActive: boolean, phrase: string) => {
	if (isActive && process.env.NODE_ENV === 'development') {
		console.log(`- ${phrase}`)
		const debug = (...args: any) => {
			if (process.env.NODE_ENV === 'development') console.log(...args)
		}
		return debug
	}
	return undefined
}

export const clone = (object: any) => {
	return JSON.parse(JSON.stringify(object))
}

export const dbg = (...str: any[]) => {
	console.log(...str)
}

export const isNotLeftClick = (e: MouseEvent) => {
	return e.button !== 0
}
export const getDataAttribute = (e: any, attr: string) => {
	if (e.target.getAttribute(`data-${attr}`)) return e.target.getAttribute(`data-${attr}`)
	if (e.target.closest(`[data-${attr}]`)) return e.target.closest(`[data-${attr}]`).getAttribute(`data-${attr}`)
}

export const range = (potentialStart: number, potentialEnd?: number) => {
	let end = potentialEnd
	let start = potentialStart

	if (!end) {
		end = potentialStart
		start = 0
	}

	const array = []
	for (let i = start; i < end; i++) {
		array.push(i)
	}

	return array
}

export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
	const result: T[][] = []
	for (let i = 0; i < array.length; i += chunkSize) {
		result.push(array.slice(i, i + chunkSize))
	}
	return result
}

export const intersect = (a: number[], b: number[]) => {
	const dict: { [n: number]: number } = {}

	a.forEach((n) => {
		dict[n]++
	})
	b.forEach((n) => {
		dict[n]++
	})

	return Object.keys(dict).filter((k: any) => dict[k] >= 2)
}

export const overlaps = (a: number[], b: number[]) => {
	return intersect(a, b).length
}

export function isHTMLElement(x: unknown): x is HTMLElement {
	return x instanceof HTMLElement
}

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
	let l = array.length
	while (l--) {
		if (predicate(array[l], l, array)) return l
	}
	return -1
}

export function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

export function getNextIndexByIndex<C>(arr: C[], index: number) {
	if (arr.length === 0) {
		return undefined // Return undefined if the array is empty
	}

	// Calculate the next index by taking the modulus of the length
	const nextIndex = (index + 1) % arr.length
	return nextIndex
}

export function getPrevIndexByIndex<C>(arr: C[], index: number) {
	if (arr.length === 0) {
		return undefined // Return undefined if the array is empty
	}

	// Calculate the previous index by subtracting 1 and adding the length, then taking the modulus of the length
	const prevIndex = (index - 1 + arr.length) % arr.length
	return prevIndex
}

export function collectionInsert<T>(col: Collection<T>, id: ULID, payload: Partial<T>, override = true) {
	col.at[id] = {
		...(override ? {} : (col.at[id] as any)),
		...payload,
	}
	col.all = col.all.filter((e) => e !== id)
	col.all.push(id)
}

export function collectionDelete<T>(col: Collection<T>, id: ULID) {
	col.all = col.all.filter((e) => e !== id)
	if (col.at[id]) {
		delete col.at[id]
	}
}
export function arrayRemove<T>(arr: T[], elementToRemove: T): T[] {
	return arr.filter((element) => element !== elementToRemove)
}
export function arrayInsertUnique<T>(arr: T[], unique: T): T[] {
	if (arr.includes(unique)) {
		return arr
	} else {
		return [...arr, unique]
	}
}

export function collectionEach<T>(col: Collection<T>, cb: (elem: T) => void) {
	col.all.forEach((id) => {
		const elem = col.at[id]
		cb(elem)
	})
}

export function collectionMap<T, R>(col: Collection<T>, cb: (elem: T) => R) {
	return col.all.map((id) => {
		const elem = col.at[id]
		return cb(elem)
	})
}

export function convertResourceTypeToResource(type?: GrantableResourceType) {
	if (!type) {
		return 'Unknown type'
	}
	switch (type) {
		case GrantableResourceEnum.ACTIVITY:
			return 'Activity'
		case GrantableResourceEnum.BOARD:
			return 'Board'
		case GrantableResourceEnum.SESSION:
			return 'Session'
	}
}

export function convertCardTypeToCardTypeString(type?: CardType) {
	if (!type) {
		return 'Unknown type'
	}
	switch (type) {
		case CardType.AI_CHAT:
			return 'AI Chat'
		case CardType.CODEBOX:
			return 'Codebox'
		case CardType.COUNTDOWN:
			return 'Countdown'
		case CardType.LINK:
			return 'Link'
		case CardType.LINK_LIST:
			return 'Linklist'
		case CardType.NOTE:
			return 'Note'
		case CardType.TODO:
			return 'Tasks'
		case CardType.IMAGES:
			return 'Image'
		case CardType.AI_GIST:
			return 'AI Gist'
	}
}

export function isElementInputable(node: Element) {
	let iterator: any = node
	while (iterator != null) {
		if (
			iterator.nodeName === 'INPUT' ||
			iterator.nodeName === 'TEXTAREA' ||
			iterator.getAttribute?.('contenteditable') === 'true' ||
			iterator.classList?.contains?.('allow-selection')
		) {
			return true
		}
		iterator = iterator.parentNode
	}
	return false
}

export function safeAccess<T>(arr: T[], index: number): T | null {
	if (Array.isArray(arr) && index >= 0 && index < arr.length) {
		return arr[index]
	}
	return null
}

export function isWord(str: string) {
	// This regex checks if the string contains only word characters (a-z, A-Z, 0-9, and _)
	// and then ensures it's not a pure integer.
	const wordPattern = /^\w+$/
	return wordPattern.test(str) && isNaN(Number(str))
}

export function domainToFilepath(domain?: string): string {
	return (domain || '')
		.trim() // Remove leading/trailing spaces
		.toLowerCase() // Convert to lower case to avoid case sensitivity issues
		.replace(/[^a-z0-9]/gi, '-') // Replace any character that is not alphanumeric
		.replace(/-+/g, '-') // Replace multiple dashes with a single dash
		.replace(/^-|-$/g, '') // Remove leading and trailing dashes if any
}
