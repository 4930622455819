// source: services/gpt_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var entities_gpt_card_chat_v1_pb = require('../entities/gpt_card_chat_v1_pb.js');
goog.object.extend(proto, entities_gpt_card_chat_v1_pb);
goog.exportSymbol('proto.gpt.ContinueChatRequest', null, global);
goog.exportSymbol('proto.gpt.CreateNewChatRequest', null, global);
goog.exportSymbol('proto.gpt.CreateNewGistRequest', null, global);
goog.exportSymbol('proto.gpt.StreamedGPTChatResponse', null, global);
goog.exportSymbol('proto.gpt.StreamedGPTChatResponse.ResponseTypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gpt.CreateNewChatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gpt.CreateNewChatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gpt.CreateNewChatRequest.displayName = 'proto.gpt.CreateNewChatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gpt.CreateNewGistRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gpt.CreateNewGistRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gpt.CreateNewGistRequest.displayName = 'proto.gpt.CreateNewGistRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gpt.ContinueChatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gpt.ContinueChatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gpt.ContinueChatRequest.displayName = 'proto.gpt.ContinueChatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gpt.StreamedGPTChatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.gpt.StreamedGPTChatResponse.oneofGroups_);
};
goog.inherits(proto.gpt.StreamedGPTChatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gpt.StreamedGPTChatResponse.displayName = 'proto.gpt.StreamedGPTChatResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gpt.CreateNewChatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gpt.CreateNewChatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gpt.CreateNewChatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gpt.CreateNewChatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    persona: (f = msg.getPersona()) && entities_gpt_card_chat_v1_pb.GPTCardPersona.toObject(includeInstance, f),
    query: jspb.Message.getFieldWithDefault(msg, 4, ""),
    context: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gpt.CreateNewChatRequest}
 */
proto.gpt.CreateNewChatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gpt.CreateNewChatRequest;
  return proto.gpt.CreateNewChatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gpt.CreateNewChatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gpt.CreateNewChatRequest}
 */
proto.gpt.CreateNewChatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardId(value);
      break;
    case 3:
      var value = new entities_gpt_card_chat_v1_pb.GPTCardPersona;
      reader.readMessage(value,entities_gpt_card_chat_v1_pb.GPTCardPersona.deserializeBinaryFromReader);
      msg.setPersona(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gpt.CreateNewChatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gpt.CreateNewChatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gpt.CreateNewChatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gpt.CreateNewChatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPersona();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      entities_gpt_card_chat_v1_pb.GPTCardPersona.serializeBinaryToWriter
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContext();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string card_id = 2;
 * @return {string}
 */
proto.gpt.CreateNewChatRequest.prototype.getCardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gpt.CreateNewChatRequest} returns this
 */
proto.gpt.CreateNewChatRequest.prototype.setCardId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional gpt_card_chat_v1.GPTCardPersona persona = 3;
 * @return {?proto.gpt_card_chat_v1.GPTCardPersona}
 */
proto.gpt.CreateNewChatRequest.prototype.getPersona = function() {
  return /** @type{?proto.gpt_card_chat_v1.GPTCardPersona} */ (
    jspb.Message.getWrapperField(this, entities_gpt_card_chat_v1_pb.GPTCardPersona, 3));
};


/**
 * @param {?proto.gpt_card_chat_v1.GPTCardPersona|undefined} value
 * @return {!proto.gpt.CreateNewChatRequest} returns this
*/
proto.gpt.CreateNewChatRequest.prototype.setPersona = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gpt.CreateNewChatRequest} returns this
 */
proto.gpt.CreateNewChatRequest.prototype.clearPersona = function() {
  return this.setPersona(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gpt.CreateNewChatRequest.prototype.hasPersona = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string query = 4;
 * @return {string}
 */
proto.gpt.CreateNewChatRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.gpt.CreateNewChatRequest} returns this
 */
proto.gpt.CreateNewChatRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string context = 5;
 * @return {string}
 */
proto.gpt.CreateNewChatRequest.prototype.getContext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.gpt.CreateNewChatRequest} returns this
 */
proto.gpt.CreateNewChatRequest.prototype.setContext = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gpt.CreateNewGistRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gpt.CreateNewGistRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gpt.CreateNewGistRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gpt.CreateNewGistRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    persona: (f = msg.getPersona()) && entities_gpt_card_chat_v1_pb.GPTCardPersona.toObject(includeInstance, f),
    prompt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    context: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gpt.CreateNewGistRequest}
 */
proto.gpt.CreateNewGistRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gpt.CreateNewGistRequest;
  return proto.gpt.CreateNewGistRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gpt.CreateNewGistRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gpt.CreateNewGistRequest}
 */
proto.gpt.CreateNewGistRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardId(value);
      break;
    case 3:
      var value = new entities_gpt_card_chat_v1_pb.GPTCardPersona;
      reader.readMessage(value,entities_gpt_card_chat_v1_pb.GPTCardPersona.deserializeBinaryFromReader);
      msg.setPersona(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrompt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gpt.CreateNewGistRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gpt.CreateNewGistRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gpt.CreateNewGistRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gpt.CreateNewGistRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPersona();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      entities_gpt_card_chat_v1_pb.GPTCardPersona.serializeBinaryToWriter
    );
  }
  f = message.getPrompt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContext();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string card_id = 2;
 * @return {string}
 */
proto.gpt.CreateNewGistRequest.prototype.getCardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gpt.CreateNewGistRequest} returns this
 */
proto.gpt.CreateNewGistRequest.prototype.setCardId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional gpt_card_chat_v1.GPTCardPersona persona = 3;
 * @return {?proto.gpt_card_chat_v1.GPTCardPersona}
 */
proto.gpt.CreateNewGistRequest.prototype.getPersona = function() {
  return /** @type{?proto.gpt_card_chat_v1.GPTCardPersona} */ (
    jspb.Message.getWrapperField(this, entities_gpt_card_chat_v1_pb.GPTCardPersona, 3));
};


/**
 * @param {?proto.gpt_card_chat_v1.GPTCardPersona|undefined} value
 * @return {!proto.gpt.CreateNewGistRequest} returns this
*/
proto.gpt.CreateNewGistRequest.prototype.setPersona = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gpt.CreateNewGistRequest} returns this
 */
proto.gpt.CreateNewGistRequest.prototype.clearPersona = function() {
  return this.setPersona(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gpt.CreateNewGistRequest.prototype.hasPersona = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string prompt = 4;
 * @return {string}
 */
proto.gpt.CreateNewGistRequest.prototype.getPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.gpt.CreateNewGistRequest} returns this
 */
proto.gpt.CreateNewGistRequest.prototype.setPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string context = 5;
 * @return {string}
 */
proto.gpt.CreateNewGistRequest.prototype.getContext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.gpt.CreateNewGistRequest} returns this
 */
proto.gpt.CreateNewGistRequest.prototype.setContext = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gpt.ContinueChatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gpt.ContinueChatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gpt.ContinueChatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gpt.ContinueChatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    query: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gpt.ContinueChatRequest}
 */
proto.gpt.ContinueChatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gpt.ContinueChatRequest;
  return proto.gpt.ContinueChatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gpt.ContinueChatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gpt.ContinueChatRequest}
 */
proto.gpt.ContinueChatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gpt.ContinueChatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gpt.ContinueChatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gpt.ContinueChatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gpt.ContinueChatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string chat_id = 1;
 * @return {string}
 */
proto.gpt.ContinueChatRequest.prototype.getChatId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gpt.ContinueChatRequest} returns this
 */
proto.gpt.ContinueChatRequest.prototype.setChatId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string query = 3;
 * @return {string}
 */
proto.gpt.ContinueChatRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.gpt.ContinueChatRequest} returns this
 */
proto.gpt.ContinueChatRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.gpt.StreamedGPTChatResponse.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.gpt.StreamedGPTChatResponse.ResponseTypeCase = {
  RESPONSE_TYPE_NOT_SET: 0,
  STREAM_FINISHED: 3
};

/**
 * @return {proto.gpt.StreamedGPTChatResponse.ResponseTypeCase}
 */
proto.gpt.StreamedGPTChatResponse.prototype.getResponseTypeCase = function() {
  return /** @type {proto.gpt.StreamedGPTChatResponse.ResponseTypeCase} */(jspb.Message.computeOneofCase(this, proto.gpt.StreamedGPTChatResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gpt.StreamedGPTChatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.gpt.StreamedGPTChatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gpt.StreamedGPTChatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gpt.StreamedGPTChatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamFinished: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gpt.StreamedGPTChatResponse}
 */
proto.gpt.StreamedGPTChatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gpt.StreamedGPTChatResponse;
  return proto.gpt.StreamedGPTChatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gpt.StreamedGPTChatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gpt.StreamedGPTChatResponse}
 */
proto.gpt.StreamedGPTChatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreamFinished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gpt.StreamedGPTChatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gpt.StreamedGPTChatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gpt.StreamedGPTChatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gpt.StreamedGPTChatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool stream_finished = 3;
 * @return {boolean}
 */
proto.gpt.StreamedGPTChatResponse.prototype.getStreamFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.gpt.StreamedGPTChatResponse} returns this
 */
proto.gpt.StreamedGPTChatResponse.prototype.setStreamFinished = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.gpt.StreamedGPTChatResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.gpt.StreamedGPTChatResponse} returns this
 */
proto.gpt.StreamedGPTChatResponse.prototype.clearStreamFinished = function() {
  return jspb.Message.setOneofField(this, 3, proto.gpt.StreamedGPTChatResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gpt.StreamedGPTChatResponse.prototype.hasStreamFinished = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.gpt);
