import { FillingCircle } from '@comps/analytics/fillingCircle'
import { Panel } from '@comps/complex/panel'
import { Button } from '@comps/interactive/button'
import { Inset } from '@comps/layout/inset'
import { Spacer } from '@comps/layout/space'
import { Cell, HeaderCell, Row, Table } from '@comps/layout/table'
import { ActivityBubble } from '@comps/static/activityBubble'
import { Text } from '@comps/typography/text'
import { useOnClickOutside } from '@root/hooks/useOnClickOutside'
import { useRefTaker } from '@root/hooks/useRefTaker'
import { Collection } from '@root/store/commonTypes'
import { useActivities } from '@root/store/selectors/useActivities'
import { useActivityAnalytics } from '@root/store/selectors/useActivityAnalytics'
import { useSessionsFilter } from '@root/store/selectors/useSessionsFilter'
import { InstancedSessionData } from '@root/store/slices/sessionsTypes'
import { getDuration } from '@root/utils/dates'
import { endOfDay, startOfDay } from 'date-fns'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

const weeklyShareFraction = (seconds: number) => {
	return seconds / (24 * 3600)
}
export const CalendarDayAnalytics: React.FC<{ sessions?: Collection<InstancedSessionData>; date: Date }> = ({ sessions, date }) => {
	const [show, setShow] = useState(false)
	const [anchor, setAnchor] = useRefTaker()
	const activities = useActivities()

	useOnClickOutside(anchor, () => {
		setShow(false)
	})

	const limits = useMemo(() => {
		return [startOfDay(date).getTime(), endOfDay(date).getTime()]
	}, [date])

	const filteredSessions = useSessionsFilter(sessions, limits[0], limits[1], 'both', true)
	const { insights } = useActivityAnalytics(filteredSessions, 'day')

	return (
		<Positioner ref={setAnchor}>
			<Button
				onClick={() => {
					setShow((s) => !s)
				}}
				color="textualAccent"
			>
				<Inset padding="buttonSmall" equal align="center">
					<FillingCircle progress={weeklyShareFraction(insights.total.duration)} />
				</Inset>
			</Button>
			{show && (
				<Panel anchor={anchor}>
					<Inset direction="column" maxWidth="420px" align="stretch" padding="toast">
						{insights.total.duration == 0 ? (
							<Text level="label" noWrap>
								No sessions scheduled
							</Text>
						) : (
							<Table layout="minmax(160px, 2fr) 1fr 1fr" inToast>
								<Row>
									<HeaderCell>Name</HeaderCell>
									<HeaderCell align="right">DUR.</HeaderCell>
									<HeaderCell align="right">%</HeaderCell>
								</Row>
								{insights.all.map((e) => {
									const stats = insights.at[e]
									const activity = activities.at[e]
									return (
										<Row key={e}>
											<Cell>
												<ActivityBubble activity={activity} size="tiny" />
												<Spacer size={10} />
												<Text block level="label" noWrap>
													{activity.name}
												</Text>
											</Cell>
											<Cell align="right">
												<Text block level="label" noWrap>
													{getDuration(stats.duration)}
												</Text>
											</Cell>
											<Cell align="right">
												<Text block level="label" noWrap>
													{stats.percentOfWeek}%
												</Text>
											</Cell>
										</Row>
									)
								})}
							</Table>
						)}
					</Inset>
				</Panel>
			)}
		</Positioner>
	)
}

const Positioner = styled.div`
	position: relative;
	top: 3px;
	left: 8px;
`
