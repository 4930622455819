import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { Database } from '@root/database';
import { cardsActions } from '@root/store/slices/cards';
import { fragmentsActions } from '@root/store/slices/fragments';
import { cacheLocalImage } from '@root/workers/cacheEngine';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import { PlanNewCardSignature } from './cardGrid.useAddCard';

export const CardPaster: React.FC<{
    resourceId: string;
    resourceType: GrantableResourceEnum;
    planNewCard: PlanNewCardSignature
}> = ({ resourceId, resourceType, planNewCard }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        const callback = (event: any) => {
            const items = event.clipboardData?.items;
            if (items) {
                for (const item of items) {
                    if (item.type.startsWith('image/')) {
                        const blob = item.getAsFile();
                        const fileId = v4()

                        cacheLocalImage(`/card-images/${fileId}.jpg`, blob, 'card-images')
                        const layout = planNewCard(CardType.IMAGES)
                        const cardId = v4()
                        const fragmentId = v4()


                        dispatch(cardsActions.new({
                            id: cardId,
                            cardType: CardType.IMAGES,
                            resourceId,
                            resourceType,
                            layout: layout.layout,
                            verticallayout: {
                                order: layout.verticalPosition
                            }
                        }))

                        dispatch(fragmentsActions.fragmentUploadableNew({
                            id: fragmentId,
                            cardId: cardId,
                            fileInfo: {
                                id: fileId,
                                type: 'image'
                            }
                        }))

                        Database.imageProcessing.put({
                            id: fileId,
                            fragmentId: fragmentId,
                            filepath: fileId,
                            type: {
                                op: 'upload-card-image',
                            },
                            sendAt: new Date().getTime(),
                            queuedAt: (new Date()).getTime(),
                            status: 'needs-upload'
                        }, fileId)
                    }
                }
            }
        }
        document.addEventListener('paste', callback)
        return () => {
            document.removeEventListener('paste', callback)
        }
    }, [resourceId, resourceType, dispatch, planNewCard])
    return <></>
}