import { Flex } from '@comps/layout/flex';
import { Inset, InsetPaddingType } from '@comps/layout/inset';
import { Icon } from '@comps/static/icon';
import { FormField } from '@root/hooks/useForm.types';
import { useWatcherObserver } from '@root/hooks/useWatcher';
import { ULID } from '@root/store/commonTypes';
import { WatcherID } from '@root/store/slices/watchersTypes';
import React, { MouseEvent, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { v4 } from 'uuid';

import { Button, ButtonProps } from './button';
import { ButtonLoadingBar } from './buttonLoadingBar';

export type WatcherButtonAction = (watcherId: ULID, e: MouseEvent) => void
export type WatcherButtonProps = {
	insetPadding: InsetPaddingType
	watcherId?: WatcherID
	action?: WatcherButtonAction
} & ButtonProps

export const ReffedButton: React.ForwardRefRenderFunction<FormField, WatcherButtonProps> = (
	{ children, insetPadding, watcherId, action, ...buttonProps },
	ref
) => {
	const trackedId = useRef(watcherId || v4())
	const { state, context } = useWatcherObserver(trackedId.current)

	const withWatcher = useCallback(
		(e: MouseEvent) => {
			action?.(trackedId.current, e)
		},
		[action]
	)

	return (
		<Button {...(buttonProps.onClick == undefined ? { ...buttonProps, onClick: withWatcher } : { ...buttonProps })} ref={ref}>
			<Inset padding={insetPadding} justify="center">
				<Flex zStack>
					<Content show={!state} key="childs">
						{children}
					</Content>
					{state == 'done' ? (
						<Flex spacing={5} align="center" justify="center" grow key="successs">
							<Icon type="check" />
							Success
						</Flex>
					) : (
						<React.Fragment key="none-1"></React.Fragment>
					)}
					{state == 'fail' ? (
						<Flex spacing={5} align="center" justify="center" grow key="fail">
							<Icon type="xmark" size={13} />
							{context?.displayMessage || 'Failed'}
						</Flex>
					) : (
						<React.Fragment key="none"></React.Fragment>
					)}
					{state == 'started' ? (
						<ButtonLoadingBar padding={insetPadding} key="started" />
					) : (
						<React.Fragment key="none-2"></React.Fragment>
					)}
				</Flex>
			</Inset>
		</Button>
	)
}

export const WatcherButton = React.forwardRef(ReffedButton)

const Content = styled.div<{ show?: boolean }>`
	position: relative;
	opacity: 0;

	${(p) =>
		p.show &&
		css`
			opacity: 1;
		`}
`
