import styled from 'styled-components';

export const ExternalLink = styled.a`
	color: inherit;
	transition: 0.1s ease all;
	cursor: pointer !important;
	* {
		cursor: pointer !important;
	}

	&:hover {
		opacity: 0.65;
	}

	&:active {
		opacity: 0.45;
	}
`
