import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useActivityOrSession = () => {
   const location = useLocation()

   return useMemo(() => {
      if (location.pathname.includes('/activity')) return 'activity'
      if (location.pathname.includes('/session')) return 'session'
      return ''
   }, [location])
}