import { CardLayoutObject } from '@root/store/slices/cards.types';

import { CardSize, Matrix } from './cardGrid.types';
import { Position } from './grid.utils';

type Direction = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

function canPlaceCard(m: Matrix, x: number, y: number, cardSize: CardSize, origin: Direction): Position | null {
  const directionMap: { [direction: string]: { x: number; y: number } } = {
    'top-left': { x: 1, y: 1 },
    'top-right': { x: -1, y: 1 },
    'bottom-left': { x: 1, y: -1 },
    'bottom-right': { x: -1, y: -1 },
  };

  const d = directionMap[origin];

  for (let i = 0; i < cardSize.h; i++) {
    for (let j = 0; j < cardSize.w; j++) {
      const xi = x + j * d.x;
      const yi = y + i * d.y;

      if (xi < 0 || xi >= m[0].length || yi < 0 || yi >= m.length || m[yi][xi]) {
        return null;
      }
    }
  }

  let originX = x;
  let originY = y;

  if (origin === 'top-right' || origin === 'bottom-right') {
    originX = x - cardSize.w + 1;
  }

  if (origin === 'bottom-left' || origin === 'bottom-right') {
    originY = y - cardSize.h + 1;
  }

  return {
    x: originX,
    y: originY,
  };
}

export function findOptimalSpot(m: Matrix, cardSize: CardSize, rect: CardLayoutObject): Position | undefined {
  const centerRect = {
    x: rect.x + rect.w / 2,
    y: rect.y + rect.h / 2,
  };
  
  let minDistance = Infinity;
  let optimalPosition = undefined;
  
  for (let y = 0; y < m.length; y++) {
    for (let x = 0; x < m[0].length; x++) {
      const position = canPlaceCard(m, x, y, cardSize, 'top-left'); 
      if (position) {
        const centerCard = {
          x: position.x + cardSize.w / 2,
          y: position.y + cardSize.h / 2,
        };
        
        const distance = Math.sqrt(Math.pow(centerCard.x - centerRect.x, 2) + Math.pow(centerCard.y - centerRect.y, 2));
        
        if (distance < minDistance) {
          minDistance = distance;
          optimalPosition = position;
        }
      }
    }
  }
  
  if (optimalPosition) {
    return optimalPosition;
  } else {
    console.log('No suitable position found for the card.');
    return undefined;
  }
}