import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { cardsActions } from '@root/store/slices/cards';
import { CardLayoutObject } from '@root/store/slices/cards.types';
import { DraggingContext } from '@root/store/slices/interface.types';
import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { copyMatrix } from './cardGrid.commons';
import { CardID, Matrix } from './cardGrid.types';
import { ReturnCardLayouts } from './cardGrid.useAddCard';
import {
	getCardsUnder, getLayoutKey, removeCardAndCardsUnder, removeDraggedCard
} from './grid.utils';

type UpdatePayload = ReturnType<typeof cardsActions.update>['payload']
export const useCardBehavioursUtils = (
	m: React.MutableRefObject<Matrix>,
	placeCardInMatrix: ReturnCardLayouts,
	render: () => void
	// cardById?: React.MutableRefObject<undefined | { [k: string]: CardDatasourcePlusLayout }>
) => {
	const hoveredOverCell = useRef<any>(null)
	const previouslyHoveredOverCell = useRef<any>(null)
	const initialCell = useRef<any>(null)
	const initialMatrixState = useRef<any>(null)
	const dispatch = useDispatch()
	const affectedCards = useRef<UpdatePayload[]>([])

	// const validateGesture = useCallback(
	// 	(e: MouseEvent, context: DraggingContext, type: 'card-resize' | 'card-drag') => {
	// 		if (!e) return null
	// 		if (context.type !== type) return null

	// 		if (!cardById?.current) return null

	// 		const x = e.clientX
	// 		const y = e.clientY
	// 		const cardData = cardById.current[context.cardId]

	// 		return { x, y, cardData }
	// 	},
	// 	[cardById]
	// )

	const gestureBegan = useCallback(
		(layout: CardLayoutObject) => {
			initialMatrixState.current = copyMatrix(m.current)
			initialCell.current = getLayoutKey(layout)
		},
		[m]
	)

	const gestureFinished = useCallback(
		(
			context: DraggingContext,
			type: 'card-resize' | 'card-drag',
			changedCard: UpdatePayload,
			resourceId?: string,
			resourceType?: GrantableResourceEnum
		) => {
			if (context.type !== type) return null
			const cardsToChange: UpdatePayload[] = [changedCard, ...affectedCards.current]
			for (const cardUpdate of cardsToChange) {
				if (type == 'card-drag') {
					if (context.resourceId !== resourceId && context.resourceType !== resourceType) {
						if (cardUpdate.layout && resourceId && resourceType) {
							dispatch(
								cardsActions.move({
									id: context.cardId,
									layout: cardUpdate.layout,
									resourceId: resourceId,
									resourceType: resourceType,
								})
							)
							return
						}
					}
				}
				dispatch(cardsActions.update(cardUpdate))
			}
		},
		[dispatch]
	)

	const simulateMove = useCallback(
		(cardId: CardID, r: CardLayoutObject) => {
			// console.log('---------- START')
			// printMatrix(m.current)
			affectedCards.current = []

			removeDraggedCard(m.current, cardId)
			const { stack } = getCardsUnder(m.current, r)

			removeCardAndCardsUnder(m.current, stack, cardId)
			// console.log('---------- REMOVED')
			// printMatrix(m.current)
			// console.log('---------- BEEN UNDER')

			const { x, y, w, h } = r
			placeCardInMatrix(cardId, { h, w }, { x, y })
			const priorityQueue = [...stack]

			priorityQueue.forEach((c) => {
				const size = {
					w: c.rect.w,
					h: c.rect.h,
				}
				const layout = placeCardInMatrix(c.cardId, size, undefined, c.rect)
				if (!layout) {
					// throw new Error('Empty layout')
					return
				}
				affectedCards.current.push({
					id: c.cardId,
					layout: layout.layout,
				})
			})
			// console.log('---------- ALL ADDED')
			render()
		},
		[m, placeCardInMatrix, render]
	)
	const resetMove = useCallback(() => {
		if (!initialMatrixState.current) return
		m.current = copyMatrix(initialMatrixState.current)
		affectedCards.current = []
		render()
	}, [m, render])

	const simulateTheLayoutChange = useCallback(
		(id: string, layout: CardLayoutObject) => {
			if (!layout) return
			hoveredOverCell.current = getLayoutKey(layout)
			if (hoveredOverCell.current !== previouslyHoveredOverCell.current) {
				resetMove()
				if (hoveredOverCell.current != initialCell.current) {
					simulateMove(id, layout)
				}
				previouslyHoveredOverCell.current = hoveredOverCell.current
			}
		},
		[resetMove, simulateMove]
	)

	return { resetMove, simulateMove, gestureFinished, simulateTheLayoutChange, gestureBegan, affectedCards }
}
