import { clone } from 'lodash'
import { useMemo } from 'react'

import { Collection } from '../commonTypes'
import { InstancedSessionData, SessionsDisplayInclusiveness } from '../slices/sessionsTypes'

export function useSessionsFilter(
	sessions: Collection<InstancedSessionData> | undefined,
	startLimit: number,
	endLimit: number,
	inclusiveness?: SessionsDisplayInclusiveness,
	chop?: boolean
): Collection<InstancedSessionData> {
	return useMemo(() => {
		const newDictionary: any = {}
		if (!sessions) return { all: [], at: {} }

		for (const sessionId of sessions.all) {
			const session = sessions.at[sessionId]
			const sessionEnd = session.end.getTime()
			const sessionStart = session.start.getTime()

			if (startLimit) {
				if (sessionEnd <= startLimit) continue
				if (!inclusiveness || inclusiveness == 'end') {
					if (sessionStart < startLimit) {
						continue
					}
				}
			}

			if (endLimit) {
				if (sessionStart >= endLimit) continue
				if (!inclusiveness || inclusiveness == 'start') {
					if (sessionEnd > endLimit) {
						continue
					}
				}
			}

			if (chop) {
				const newSession = clone(session)
				if (session.start.getTime() < startLimit) {
					newSession.start = new Date(startLimit)
				}
				if (session.end.getTime() > endLimit) {
					newSession.end = new Date(endLimit + 1)
				}
				newDictionary[sessionId] = newSession
			} else {
				newDictionary[sessionId] = session
			}
		}
		return { at: newDictionary, all: Object.keys(newDictionary) }
	}, [startLimit, endLimit, inclusiveness, sessions, chop])
}
