import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { CardGrid } from '@comps/complex/cards/cardGrid'
import { DelayCardGrid } from '@comps/complex/cards/cardGrid.delayCardGrid'
import { CardList } from '@comps/complex/cards/cardList'
import styled from 'styled-components'
import { useSessionOutletContext } from './Sessions.ID.useSessionOutletContext'

export const SessionIDOutletSession: React.FC<{ forceCardList?: boolean }> = ({ forceCardList }) => {
	const { session } = useSessionOutletContext()
	if (!session) return <span>No session found</span>


	if (forceCardList) {
		return <CardList resourceId={session.id} resourceType={GrantableResourceEnum.SESSION} />
	}

	return (
		<Holder>
			<DelayCardGrid>
				<CardGrid resourceId={session.id} resourceType={GrantableResourceEnum.SESSION} />
			</DelayCardGrid>
		</Holder>
	)
}

const Holder = styled.div`
	width: calc(100%);
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 960px;
	overflow: visible;
	padding: 0;
	min-height: 100%;
	padding-top: 0px;
`
