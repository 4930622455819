import { DeleteMeta } from '@clepside/clepsidejs/lib/commons/core_pb'
import { ActivityPacketData, ActivityPacketMeta } from '@clepside/clepsidejs/lib/packets_v1/activities_pb'
import { BoardPacket } from '@clepside/clepsidejs/lib/packets_v1/boards_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { generatePacketMeta } from './persist.utils'
import { BoardPacketObject } from './sync.tsx.packets.types'

export function constructBoardPacket(readyToSendPacket: Packet, boardPacket: BoardPacketObject) {
    const tbsBoardPacket = new BoardPacket()
    const { type, data, deleted, id, meta } = boardPacket
    if (!data) throw new Error('Packet data is undefined')
    
    const { name, color, wallpaper  } = data

    tbsBoardPacket.setId(id)

    if (!type) {
        throw new Error('packet type not found')
    }

    if (deleted) {
        const deletedMeta = new DeleteMeta()
        deletedMeta.setAt(deleted.at)
        deletedMeta.setBy(deleted.by)
        deletedMeta.setIs(deleted.is)

        tbsBoardPacket.setDeleted(deletedMeta)
    }


    if (data) {
        const newData = new ActivityPacketData()
        const newMeta = new ActivityPacketMeta()


        if (name && meta.name) {
            newData.setName(name)
            newMeta.setName(generatePacketMeta(boardPacket, 'name'))
        }
        if (color && meta.color) {
            newData.setColor(color)
            newMeta.setColor(generatePacketMeta(boardPacket, 'color'))
        }
        if (wallpaper && meta.wallpaper) {
            newData.setWallpaper(wallpaper)
            newMeta.setWallpaper(generatePacketMeta(boardPacket, 'wallpaper'))
        }

        tbsBoardPacket.setData(newData)
        tbsBoardPacket.setMeta(newMeta)
        tbsBoardPacket.setType(type)
    }

    readyToSendPacket.setBoard(tbsBoardPacket)
}