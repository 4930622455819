/**
 * @fileoverview gRPC-Web generated client stub for gpt
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.1
// source: services/gpt_v1.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var entities_gpt_card_chat_v1_pb = require('../entities/gpt_card_chat_v1_pb.js')
const proto = {};
proto.gpt = require('./gpt_v1_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.gpt.GPTServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.gpt.GPTServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.gpt.CreateNewChatRequest,
 *   !proto.gpt.StreamedGPTChatResponse>}
 */
const methodDescriptor_GPTService_CreateNewChat = new grpc.web.MethodDescriptor(
  '/gpt.GPTService/CreateNewChat',
  grpc.web.MethodType.UNARY,
  proto.gpt.CreateNewChatRequest,
  proto.gpt.StreamedGPTChatResponse,
  /**
   * @param {!proto.gpt.CreateNewChatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gpt.StreamedGPTChatResponse.deserializeBinary
);


/**
 * @param {!proto.gpt.CreateNewChatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.gpt.StreamedGPTChatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.gpt.StreamedGPTChatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gpt.GPTServiceClient.prototype.createNewChat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gpt.GPTService/CreateNewChat',
      request,
      metadata || {},
      methodDescriptor_GPTService_CreateNewChat,
      callback);
};


/**
 * @param {!proto.gpt.CreateNewChatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.gpt.StreamedGPTChatResponse>}
 *     Promise that resolves to the response
 */
proto.gpt.GPTServicePromiseClient.prototype.createNewChat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gpt.GPTService/CreateNewChat',
      request,
      metadata || {},
      methodDescriptor_GPTService_CreateNewChat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.gpt.ContinueChatRequest,
 *   !proto.gpt.StreamedGPTChatResponse>}
 */
const methodDescriptor_GPTService_ContinueChat = new grpc.web.MethodDescriptor(
  '/gpt.GPTService/ContinueChat',
  grpc.web.MethodType.UNARY,
  proto.gpt.ContinueChatRequest,
  proto.gpt.StreamedGPTChatResponse,
  /**
   * @param {!proto.gpt.ContinueChatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gpt.StreamedGPTChatResponse.deserializeBinary
);


/**
 * @param {!proto.gpt.ContinueChatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.gpt.StreamedGPTChatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.gpt.StreamedGPTChatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gpt.GPTServiceClient.prototype.continueChat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gpt.GPTService/ContinueChat',
      request,
      metadata || {},
      methodDescriptor_GPTService_ContinueChat,
      callback);
};


/**
 * @param {!proto.gpt.ContinueChatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.gpt.StreamedGPTChatResponse>}
 *     Promise that resolves to the response
 */
proto.gpt.GPTServicePromiseClient.prototype.continueChat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gpt.GPTService/ContinueChat',
      request,
      metadata || {},
      methodDescriptor_GPTService_ContinueChat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.gpt.CreateNewGistRequest,
 *   !proto.gpt.StreamedGPTChatResponse>}
 */
const methodDescriptor_GPTService_CreateNewGist = new grpc.web.MethodDescriptor(
  '/gpt.GPTService/CreateNewGist',
  grpc.web.MethodType.UNARY,
  proto.gpt.CreateNewGistRequest,
  proto.gpt.StreamedGPTChatResponse,
  /**
   * @param {!proto.gpt.CreateNewGistRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gpt.StreamedGPTChatResponse.deserializeBinary
);


/**
 * @param {!proto.gpt.CreateNewGistRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.gpt.StreamedGPTChatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.gpt.StreamedGPTChatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gpt.GPTServiceClient.prototype.createNewGist =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gpt.GPTService/CreateNewGist',
      request,
      metadata || {},
      methodDescriptor_GPTService_CreateNewGist,
      callback);
};


/**
 * @param {!proto.gpt.CreateNewGistRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.gpt.StreamedGPTChatResponse>}
 *     Promise that resolves to the response
 */
proto.gpt.GPTServicePromiseClient.prototype.createNewGist =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gpt.GPTService/CreateNewGist',
      request,
      metadata || {},
      methodDescriptor_GPTService_CreateNewGist);
};


module.exports = proto.gpt;

