import { Button } from '@comps/interactive/button'
import { Inset } from '@comps/layout/inset'
import { Text } from '@comps/typography/text'
import { flowsActions } from '@root/store/slices/flows'
import { FlowID } from '@root/store/slices/flows.types'
import { AnimatePresence, useAnimate, usePresence } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { HorizontalScrollerPhaseDetails, useFlowPhase } from './useFlowPhase'

export const FlowsCardHorizontalScroller: React.FC<{
	phases: HorizontalScrollerPhaseDetails[]
	flowId: FlowID
}> = ({ phases, flowId }) => {
	const { viewIndex, navigationStack } = useFlowPhase(flowId, phases)
	const dispatch = useDispatch()
	const isInitialRendering = useRef(true)
	const previousPageIndex = useRef(viewIndex)

	useEffect(() => {
		isInitialRendering.current = false
		previousPageIndex.current = viewIndex
	}, [viewIndex])

	return (
		<Scroller>
			<AnimatePresence>
				<Header>
					{navigationStack.length > 1 && (
						<BackToHolder>
							<Button
								onClick={() => {
									dispatch(flowsActions.popView({ id: flowId }))
								}}
							>
								<Inset padding="buttonSmall">&larr;</Inset>
							</Button>
						</BackToHolder>
					)}
					<Text bold level="body">
						{phases[viewIndex].title}
					</Text>
					{phases[viewIndex].rightHeaderAction}
				</Header>
				<FlowCardHorizontalScrollerPage
					key={phases[viewIndex].key}
					flowId={flowId}
					phases={phases}
					isInitialRendering={isInitialRendering.current}
					previousPageIndex={previousPageIndex.current}
				>
					{phases[viewIndex]?.node}
				</FlowCardHorizontalScrollerPage>
			</AnimatePresence>
		</Scroller>
	)
}
const BackToHolder = styled.div`
	position: absolute;
	left: 14px;
`

const HEADER_HEIGHT = 54
const Header = styled.div`
	z-index: 10;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: ${HEADER_HEIGHT}px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 10px;
	position: relative;
	box-sizing: border-box;
`

const FlowCardHorizontalScrollerPage: React.FC<{
	children: React.ReactNode | React.ReactNode[]
	phases: HorizontalScrollerPhaseDetails[]
	flowId: FlowID
	isInitialRendering: boolean
	previousPageIndex: number
}> = ({ children, phases, flowId, isInitialRendering, previousPageIndex }) => {
	const [isPresent, safeToRemove] = usePresence()
	const [scope, animate] = useAnimate()
	const { viewIndex, phaseContext } = useFlowPhase(flowId, phases)
	const pageIndex = useRef(viewIndex)

	useEffect(() => {
		if (!safeToRemove) return

		if (!isPresent) {
			safeToRemove()
			// ;(async () => {
			// 	await animate(
			// 		'.page',
			// 		{
			// 			x: pageIndex.current >= viewIndex ? '100%' : '-100%',
			// 		},
			// 		Transitions.slide
			// 	)
			// 	safeToRemove()
			// })()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPresent])

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		// if (pageIndex.current == phaseIndex && isInitialRendering == true) return
	// 		;(async () => {
	// 			if (isInitialRendering) {
	// 				await animate(
	// 					'.page',
	// 					{
	// 						x: 0,
	// 						display: 'block',
	// 					},
	// 					{ duration: 0 }
	// 				)
	// 				return
	// 			}
	// 			await animate(
	// 				'.page',
	// 				{
	// 					x: pageIndex.current > previousPageIndex ? '100%' : '-100%',
	// 					display: 'block',
	// 				},
	// 				{ duration: 1 }
	// 			)
	// 			await sleep(50)
	// 			await animate(
	// 				'.page',
	// 				{
	// 					x: 0,
	// 				},
	// 				Transitions.slide
	// 			)
	// 		})()
	// 	}, 1)
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])
	return (
		<Animation ref={scope}>
			<Page className="page" height={phases[pageIndex.current].height}>
				{children}
			</Page>
		</Animation>
	)
}

const Animation = styled.div`
	display: contents;
`

const Page = styled.div<{ height: number }>`
	position: absolute;
	top: ${HEADER_HEIGHT}px;
	left: 0;
	width: 100%;
	padding: 24px;
	padding-top: 10px;

	${(p) =>
		p.height &&
		css`
			height: calc(${p.height}px - ${HEADER_HEIGHT}px);
		`}

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
`

const Scroller = styled.div`
	display: contents;
`
