import { FillingCircle } from '@comps/analytics/fillingCircle'
import { Panel } from '@comps/complex/panel'
import { Button } from '@comps/interactive/button'
import { Inset } from '@comps/layout/inset'
import { Spacer } from '@comps/layout/space'
import { Cell, HeaderCell, Row, Table } from '@comps/layout/table'
import { ActivityBubble } from '@comps/static/activityBubble'
import { Text } from '@comps/typography/text'
import { useOnClickOutside } from '@root/hooks/useOnClickOutside'
import { useRefTaker } from '@root/hooks/useRefTaker'
import { Collection } from '@root/store/commonTypes'
import { useActivities } from '@root/store/selectors/useActivities'
import { useActivityAnalytics } from '@root/store/selectors/useActivityAnalytics'
import { useSessionsFilter } from '@root/store/selectors/useSessionsFilter'
import { InstancedSessionData } from '@root/store/slices/sessionsTypes'
import { getDuration } from '@root/utils/dates'
import { endOfDay, startOfDay } from 'date-fns'
import { useMemo, useState } from 'react'

const weekSeconds = 24 * 7 * 60 * 60
const weeklyShareFraction = (seconds: number) => {
	return seconds / weekSeconds
}
const weeklyShare = (seconds: number) => {
	const percentage = (seconds / weekSeconds) * 100
	return Number(percentage.toFixed(1)) + '%'
}
export const CalendarFullWeekAnalytics: React.FC<{ sessions?: Collection<InstancedSessionData>; presentedDates: Date[] }> = ({
	sessions,
	presentedDates,
}) => {
	const [show, setShow] = useState(false)
	const [anchor, setAnchor] = useRefTaker()
	const activities = useActivities()

	useOnClickOutside(anchor, () => {
		setShow(false)
	})

	const limits = useMemo(() => {
		return [startOfDay(presentedDates[0]).getTime(), endOfDay(presentedDates[presentedDates.length - 1]).getTime()]
	}, [presentedDates])

	const filteredSessions = useSessionsFilter(sessions, limits[0], limits[1], 'both', true)
	const { insights } = useActivityAnalytics(filteredSessions, 'week')

	return (
		<div ref={setAnchor}>
			<Button
				onClick={() => {
					setShow((s) => !s)
				}}
				color="textualAccent"
			>
				<Inset padding="buttonSmall" align="center">
					<FillingCircle progress={weeklyShareFraction(insights.total.duration)} /> <Spacer size={8} />
					<Text block level="label">
						{weeklyShare(insights.total.duration)} Weekload
					</Text>
				</Inset>
			</Button>
			{show && (
				<Panel anchor={anchor}>
					<Inset direction="column" maxWidth="420px" align="stretch" padding="mediumCard">
						<Text level="tiny" bold>
							WEEKLY INSIGHTS
						</Text>
						{insights.total.duration == 0 ? (
							<Text color="darkTranslucencySubtle">You have no sessions for this week.</Text>
						) : (
							<>
								<Text color="darkTranslucencySubtle">
									You’ve scheduled{' '}
									<Text color="darkTranslucency">
										{getDuration(insights.total.duration, ['days'])}
									</Text>{' '}
									hours, with{' '}
									<Text color="darkTranslucency">
										{getDuration(weekSeconds - insights.total.duration, ['days'])}
									</Text>{' '}
									hours remaining to be scheduled.
								</Text>
								<Spacer size={20} vertical />
								<Table layout="auto min-content min-content min-content" inToast>
									<Row>
										<HeaderCell>Name</HeaderCell>
										<HeaderCell>Sessions</HeaderCell>
										<HeaderCell>Duration</HeaderCell>
										<HeaderCell>% of week</HeaderCell>
									</Row>
									{insights.all.map((e) => {
										const stats = insights.at[e]
										const activity = activities.at[e]
										return (
											<Row key={e}>
												<Cell>
													<ActivityBubble
														activity={activity}
														size="tiny"
													/>
													<Spacer size={10} />
													<Text noWrap level="label">
														{activity.name}
													</Text>
												</Cell>
												<Cell align="right">{stats.count}</Cell>
												<Cell>{getDuration(stats.duration)}</Cell>
												<Cell>{stats.percentOfWeek}%</Cell>
											</Row>
										)
									})}
								</Table>{' '}
							</>
						)}
					</Inset>
				</Panel>
			)}
		</div>
	)
}
