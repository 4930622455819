/**
 * @fileoverview gRPC-Web generated client stub for sync_v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.1
// source: services/sync_v1.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var unions_v1_sendable_pb = require('../unions_v1/sendable_pb.js')
const proto = {};
proto.sync_v1 = require('./sync_v1_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sync_v1.SyncServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sync_v1.SyncServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sync_v1.FetchActivitiesRequest,
 *   !proto.sync_v1.BatchedSendablesStream>}
 */
const methodDescriptor_SyncService_FetchActivities = new grpc.web.MethodDescriptor(
  '/sync_v1.SyncService/FetchActivities',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sync_v1.FetchActivitiesRequest,
  proto.sync_v1.BatchedSendablesStream,
  /**
   * @param {!proto.sync_v1.FetchActivitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sync_v1.BatchedSendablesStream.deserializeBinary
);


/**
 * @param {!proto.sync_v1.FetchActivitiesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServiceClient.prototype.fetchActivities =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchActivities',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchActivities);
};


/**
 * @param {!proto.sync_v1.FetchActivitiesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServicePromiseClient.prototype.fetchActivities =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchActivities',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchActivities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sync_v1.FetchBoardsRequest,
 *   !proto.sync_v1.BatchedSendablesStream>}
 */
const methodDescriptor_SyncService_FetchBoards = new grpc.web.MethodDescriptor(
  '/sync_v1.SyncService/FetchBoards',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sync_v1.FetchBoardsRequest,
  proto.sync_v1.BatchedSendablesStream,
  /**
   * @param {!proto.sync_v1.FetchBoardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sync_v1.BatchedSendablesStream.deserializeBinary
);


/**
 * @param {!proto.sync_v1.FetchBoardsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServiceClient.prototype.fetchBoards =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchBoards',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchBoards);
};


/**
 * @param {!proto.sync_v1.FetchBoardsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServicePromiseClient.prototype.fetchBoards =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchBoards',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchBoards);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sync_v1.FetchSessionsByActivityRequest,
 *   !proto.sync_v1.BatchedSendablesStream>}
 */
const methodDescriptor_SyncService_FetchSessionsByActivity = new grpc.web.MethodDescriptor(
  '/sync_v1.SyncService/FetchSessionsByActivity',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sync_v1.FetchSessionsByActivityRequest,
  proto.sync_v1.BatchedSendablesStream,
  /**
   * @param {!proto.sync_v1.FetchSessionsByActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sync_v1.BatchedSendablesStream.deserializeBinary
);


/**
 * @param {!proto.sync_v1.FetchSessionsByActivityRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServiceClient.prototype.fetchSessionsByActivity =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchSessionsByActivity',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchSessionsByActivity);
};


/**
 * @param {!proto.sync_v1.FetchSessionsByActivityRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServicePromiseClient.prototype.fetchSessionsByActivity =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchSessionsByActivity',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchSessionsByActivity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sync_v1.FetchNotificationsRequest,
 *   !proto.sync_v1.BatchedSendablesStream>}
 */
const methodDescriptor_SyncService_FetchNotifications = new grpc.web.MethodDescriptor(
  '/sync_v1.SyncService/FetchNotifications',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sync_v1.FetchNotificationsRequest,
  proto.sync_v1.BatchedSendablesStream,
  /**
   * @param {!proto.sync_v1.FetchNotificationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sync_v1.BatchedSendablesStream.deserializeBinary
);


/**
 * @param {!proto.sync_v1.FetchNotificationsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServiceClient.prototype.fetchNotifications =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchNotifications',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchNotifications);
};


/**
 * @param {!proto.sync_v1.FetchNotificationsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServicePromiseClient.prototype.fetchNotifications =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchNotifications',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchNotifications);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sync_v1.FetchSettingsRequest,
 *   !proto.sync_v1.BatchedSendablesStream>}
 */
const methodDescriptor_SyncService_FetchSettings = new grpc.web.MethodDescriptor(
  '/sync_v1.SyncService/FetchSettings',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sync_v1.FetchSettingsRequest,
  proto.sync_v1.BatchedSendablesStream,
  /**
   * @param {!proto.sync_v1.FetchSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sync_v1.BatchedSendablesStream.deserializeBinary
);


/**
 * @param {!proto.sync_v1.FetchSettingsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServiceClient.prototype.fetchSettings =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchSettings',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchSettings);
};


/**
 * @param {!proto.sync_v1.FetchSettingsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServicePromiseClient.prototype.fetchSettings =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchSettings',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sync_v1.FetchPartialActivitiesRequest,
 *   !proto.sync_v1.BatchedSendablesStream>}
 */
const methodDescriptor_SyncService_FetchPartialActivities = new grpc.web.MethodDescriptor(
  '/sync_v1.SyncService/FetchPartialActivities',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sync_v1.FetchPartialActivitiesRequest,
  proto.sync_v1.BatchedSendablesStream,
  /**
   * @param {!proto.sync_v1.FetchPartialActivitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sync_v1.BatchedSendablesStream.deserializeBinary
);


/**
 * @param {!proto.sync_v1.FetchPartialActivitiesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServiceClient.prototype.fetchPartialActivities =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchPartialActivities',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchPartialActivities);
};


/**
 * @param {!proto.sync_v1.FetchPartialActivitiesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServicePromiseClient.prototype.fetchPartialActivities =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchPartialActivities',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchPartialActivities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sync_v1.FetchIndividualSessionsRequest,
 *   !proto.sync_v1.BatchedSendablesStream>}
 */
const methodDescriptor_SyncService_FetchIndividualSessions = new grpc.web.MethodDescriptor(
  '/sync_v1.SyncService/FetchIndividualSessions',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sync_v1.FetchIndividualSessionsRequest,
  proto.sync_v1.BatchedSendablesStream,
  /**
   * @param {!proto.sync_v1.FetchIndividualSessionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sync_v1.BatchedSendablesStream.deserializeBinary
);


/**
 * @param {!proto.sync_v1.FetchIndividualSessionsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServiceClient.prototype.fetchIndividualSessions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchIndividualSessions',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchIndividualSessions);
};


/**
 * @param {!proto.sync_v1.FetchIndividualSessionsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.BatchedSendablesStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServicePromiseClient.prototype.fetchIndividualSessions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/FetchIndividualSessions',
      request,
      metadata || {},
      methodDescriptor_SyncService_FetchIndividualSessions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sync_v1.StreamActivityRequest,
 *   !proto.sync_v1.SendableStream>}
 */
const methodDescriptor_SyncService_StreamActivity = new grpc.web.MethodDescriptor(
  '/sync_v1.SyncService/StreamActivity',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sync_v1.StreamActivityRequest,
  proto.sync_v1.SendableStream,
  /**
   * @param {!proto.sync_v1.StreamActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sync_v1.SendableStream.deserializeBinary
);


/**
 * @param {!proto.sync_v1.StreamActivityRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.SendableStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServiceClient.prototype.streamActivity =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/StreamActivity',
      request,
      metadata || {},
      methodDescriptor_SyncService_StreamActivity);
};


/**
 * @param {!proto.sync_v1.StreamActivityRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.SendableStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServicePromiseClient.prototype.streamActivity =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/StreamActivity',
      request,
      metadata || {},
      methodDescriptor_SyncService_StreamActivity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sync_v1.StreamSessionRequest,
 *   !proto.sync_v1.SendableStream>}
 */
const methodDescriptor_SyncService_StreamSession = new grpc.web.MethodDescriptor(
  '/sync_v1.SyncService/StreamSession',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sync_v1.StreamSessionRequest,
  proto.sync_v1.SendableStream,
  /**
   * @param {!proto.sync_v1.StreamSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sync_v1.SendableStream.deserializeBinary
);


/**
 * @param {!proto.sync_v1.StreamSessionRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.SendableStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServiceClient.prototype.streamSession =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/StreamSession',
      request,
      metadata || {},
      methodDescriptor_SyncService_StreamSession);
};


/**
 * @param {!proto.sync_v1.StreamSessionRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.SendableStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServicePromiseClient.prototype.streamSession =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/StreamSession',
      request,
      metadata || {},
      methodDescriptor_SyncService_StreamSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sync_v1.StreamBoardRequest,
 *   !proto.sync_v1.SendableStream>}
 */
const methodDescriptor_SyncService_StreamBoard = new grpc.web.MethodDescriptor(
  '/sync_v1.SyncService/StreamBoard',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sync_v1.StreamBoardRequest,
  proto.sync_v1.SendableStream,
  /**
   * @param {!proto.sync_v1.StreamBoardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sync_v1.SendableStream.deserializeBinary
);


/**
 * @param {!proto.sync_v1.StreamBoardRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.SendableStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServiceClient.prototype.streamBoard =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/StreamBoard',
      request,
      metadata || {},
      methodDescriptor_SyncService_StreamBoard);
};


/**
 * @param {!proto.sync_v1.StreamBoardRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.SendableStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServicePromiseClient.prototype.streamBoard =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/StreamBoard',
      request,
      metadata || {},
      methodDescriptor_SyncService_StreamBoard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sync_v1.StreamRoutineRequest,
 *   !proto.sync_v1.SendableStream>}
 */
const methodDescriptor_SyncService_StreamRoutine = new grpc.web.MethodDescriptor(
  '/sync_v1.SyncService/StreamRoutine',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sync_v1.StreamRoutineRequest,
  proto.sync_v1.SendableStream,
  /**
   * @param {!proto.sync_v1.StreamRoutineRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sync_v1.SendableStream.deserializeBinary
);


/**
 * @param {!proto.sync_v1.StreamRoutineRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.SendableStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServiceClient.prototype.streamRoutine =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/StreamRoutine',
      request,
      metadata || {},
      methodDescriptor_SyncService_StreamRoutine);
};


/**
 * @param {!proto.sync_v1.StreamRoutineRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.SendableStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServicePromiseClient.prototype.streamRoutine =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/StreamRoutine',
      request,
      metadata || {},
      methodDescriptor_SyncService_StreamRoutine);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sync_v1.StreamCardRequest,
 *   !proto.sync_v1.SendableStream>}
 */
const methodDescriptor_SyncService_StreamCard = new grpc.web.MethodDescriptor(
  '/sync_v1.SyncService/StreamCard',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sync_v1.StreamCardRequest,
  proto.sync_v1.SendableStream,
  /**
   * @param {!proto.sync_v1.StreamCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sync_v1.SendableStream.deserializeBinary
);


/**
 * @param {!proto.sync_v1.StreamCardRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.SendableStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServiceClient.prototype.streamCard =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/StreamCard',
      request,
      metadata || {},
      methodDescriptor_SyncService_StreamCard);
};


/**
 * @param {!proto.sync_v1.StreamCardRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sync_v1.SendableStream>}
 *     The XHR Node Readable Stream
 */
proto.sync_v1.SyncServicePromiseClient.prototype.streamCard =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sync_v1.SyncService/StreamCard',
      request,
      metadata || {},
      methodDescriptor_SyncService_StreamCard);
};


module.exports = proto.sync_v1;

