import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Database } from '@root/database'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { put } from 'typed-redux-saga'
import { v4 } from 'uuid'
import { RootState } from '../'
import { cardsActions } from './cards'
import { deltaDelete } from './persist'
import { NewPacketDelta, RemovePacketDeltaIdOnly, UpdatePacketDelta } from './persist.utils'
import { InitialRoutinesState, NormalisedRoutineData, RoutineData, RoutineDataPlusSchedule, RoutineID } from './routines.types'
import { NormalisedRoutineScheduleData, RoutineScheduleID } from './routineSchedules.types'
import { sessionsActions } from './sessions'
import { CardPacketObject, RoutinePacketObject } from './sync.tsx.packets.types'

export const normalizeRoutinePacket = (id: string, s: RoutinePacketObject['data']) => {
	const normalizedRoutine: NormalisedRoutineData = {
        id,
        ...s
    }

	return normalizedRoutine
}

const routinesSlice = createSlice({
	name: 'routines',
	initialState: InitialRoutinesState,
	reducers: {
      new: (state, { payload }: PayloadAction<NewPacketDelta<PacketType.ROUTINE>>) => {},
		update: (state, { payload }: PayloadAction<UpdatePacketDelta<PacketType.ROUTINE>>) => {},
		delete: (state, { payload }: PayloadAction<RemovePacketDeltaIdOnly>) => {},
		loadAllToStore: (state, { payload }: PayloadAction) => {},
		createSession: (state, { payload }:  PayloadAction<{
			startTime: number,
			endTime: number,
			routineId: string,
			activityId: string,
			title?: string,
			description?: string,
		}>) => {},
		loadCards: (state, { payload }: PayloadAction<{ routineId: string }>) => {},
      normalizeAndStore: (state, { payload }: PayloadAction<{ objects: RoutinePacketObject[], fromSync?: boolean }>) => {
			// TO-DO
			// Add computed properties, etc..
			payload.objects?.forEach((routine) => {
				if (!routine.id) return

				if (routine.deleted?.is) {
                    if (routine.data.activityId) {
						state.allForActivityId[routine.data.activityId] = state.allForActivityId[routine.data.activityId]?.filter(id => id != routine.id)
                        state.all = state.all?.filter(id => id != routine.id)
                    }
                } else {
					const normalised = normalizeRoutinePacket(routine.id, routine.data)

					if (!normalised) {
						return
					}

					if (!state.all) {
						state.all = []
					}

                    if (!state.allForActivityId[normalised.activityId]) {
                        state.allForActivityId[normalised.activityId] = []
                    }
                    if (!state.allForActivityId[normalised.activityId].includes(normalised.id)) {
                        state.allForActivityId[normalised.activityId].push(normalised.id)
                    }
                    state.allForActivityId[normalised.activityId].sort((a, b) => a > b ? 1 : -1)

                    if (!state.all.includes(normalised.id)) {
                        state.all.push(normalised.id)
                    }
					
                    state.all.sort((a, b) => a > b ? 1 : -1)

					state.at[normalised.id] = {
						...(state.at[normalised.id] ? state.at[normalised.id] : {}),
						...normalised
					}
				}
			})
	},
    }
})

export const routinesSagas = {
	*delete({ payload: { id } }: ReturnType<typeof routinesActions.delete>) {
		yield deltaDelete({ type: PacketType.ROUTINE, delta: { id, shouldDelete: true } })
	},
	*loadCards({ payload: { routineId } }: ReturnType<typeof routinesActions.loadCards>) {
		yield put(cardsActions.loadCardsForResourceId({ resourceId: routineId }))
	},
	*createSession({ payload }: ReturnType<typeof routinesActions.createSession>) {
		// yield put(cardsActions.loadCardsForResourceId({ resourceId: routineId }))
		const sessionId = v4()

		yield put(sessionsActions.new({
			id: sessionId,
			end: payload.endTime,
			start: payload.startTime,
			activityId: payload.activityId
		}))

		const newResourceCardsPackets: CardPacketObject[] = yield Database.cardPackets.where('data.resourceId').equals(payload.routineId).toArray()

		const ids = []
		for (const card of newResourceCardsPackets) {
			if (!card.deleted.is) {
				ids.push(card.id)
			}
		}
		
		yield put(cardsActions.moveToNewParentResource({
			cardIds: ids,
			resourceId: sessionId,
			resourceType: GrantableResourceEnum.SESSION,
			deleteAfter: false
		}))
	},
	*loadAllToStore({ payload }: ReturnType<typeof routinesActions.loadAllToStore>): any {
		const routines: RoutinePacketObject[] = yield Database.routinePackets.toArray()

		yield put(routinesActions.normalizeAndStore({
			objects: routines,
			fromSync: false
		}))
		// yield put(cardsActions.loadCardsForResourceId({ resourceId: payload.resourceId }))
		// yield put(fragmentsActions.loadFragmentsForResourceId({ resourceId: payload.resourceId, resourceType: GrantableResourceEnum.ACTIVITY }))
	},
}

export const routinesActions = routinesSlice.actions
export const routinesReducers = routinesSlice.reducer


export function useRoutinesForActivityId(activityId: string | undefined) {
	const routines = useSelector((state: RootState) => state.routines) 
	const schedules = useSelector((state: RootState) => state.routinesSchedules) 
	
	return useMemo(() => {
		if (!activityId ) {
			return { 
				all: [],
				at: {}
			}
		}
		return {
			all: routines.allForActivityId[activityId] || [],
			at: routines.allForActivityId[activityId]?.reduce((acc, id) => {
				acc[id] = {
					...routines.at[id],
					schedules: {
						all: schedules.forRoutineId[id] || [],
						at: schedules.forRoutineId[id]?.reduce((rcc, sid) => {
							rcc[sid] = schedules.at[sid] 
							return rcc
						}, {} as { [scheduleId: RoutineScheduleID]: NormalisedRoutineScheduleData }) || {}
					}
				}
				return acc
			}, {} as { [routineId: RoutineID]: RoutineDataPlusSchedule }) || {}
		}
	}, [routines, activityId, schedules])
}

export type AllRoutinesState = {
    all: string[];
    at: {
        [routineId: RoutineID]: RoutineData;
    };
    schedules: {
        all: RoutineScheduleID[];
		forRoutineId: {
            [routineId: RoutineID]: RoutineScheduleID[];
        },
        at: {
            [scheduleId: RoutineScheduleID]: NormalisedRoutineScheduleData;
        };
    };
}

export function useRoutines(): AllRoutinesState {
	const routines = useSelector((state: RootState) => state.routines) 
	const schedules = useSelector((state: RootState) => state.routinesSchedules) 
	
	return useMemo(() => {
		return {
			all: routines.all || [],
			at: routines.all?.reduce((acc, id) => {
				acc[id] = {
					...routines.at[id],
				}
				return acc
			}, {} as { [routineId: RoutineID]: RoutineData }) || {},
			schedules: {
				all: schedules.all || [],
				forRoutineId: schedules.all?.reduce((rcc, sid) => {
					const schedule = schedules.at[sid] 
					const routineId = schedule.routineId
					
					if (!rcc[routineId]) {
						rcc[routineId] = []
					}

					rcc[routineId].push(sid)

					return rcc
				}, {} as { [routineId: RoutineID]: RoutineScheduleID[] }) || {},
				at: schedules.all?.reduce((rcc, sid) => {
					rcc[sid] = schedules.at[sid] 
					return rcc
				}, {} as { [scheduleId: RoutineScheduleID]: NormalisedRoutineScheduleData }) || {}
			}
		}
	}, [routines, schedules])
}