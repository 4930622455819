import { MaestroServiceClient } from '@clepside/clepsidejs/lib/services/maestro_v1_grpc_web_pb';
import {
    AcceptSharingInvitationRequest, AcceptSharingInvitationResponse, CreateSharingInvitationRequest,
    CreateSharingInvitationResponse
} from '@clepside/clepsidejs/lib/services/maestro_v1_pb';

import { getGRPCMeta } from './utils';

if (!process.env.REACT_APP_API_URL)
    throw new Error('No RAPURL')
const MaestroClient = new MaestroServiceClient(process.env.REACT_APP_API_URL)


export const MaestroGRPC = {
    invite: (request: CreateSharingInvitationRequest) => new Promise<CreateSharingInvitationResponse>((res, rej) => {
            (async () => {
                MaestroClient.createSharingInvitation(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    acceptInvite: (request: AcceptSharingInvitationRequest) => new Promise<AcceptSharingInvitationResponse>((res, rej) => {
            (async () => {
                MaestroClient.acceptSharingInvitation(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    // list: (request: ListCollaboratorsRequest) => new Promise<ListCollaboratorsResponse>((res, rej) => {
    //         (async () => {
    //             MaestroClient.listCollaborators(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
    //         })();
    //     }
    // )
}