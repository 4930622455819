import styled from 'styled-components';

export const InnerPage = styled.div`
	width: 100%;
	display: flex;
	background-color: #fff;
	height: 100vh;
	overflow: auto;
	flex-direction: column;
	align-items: stretch;
	padding: 60px 120px;
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
`
