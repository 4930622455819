import { useRef } from 'react';
import { Selector, useSelector } from 'react-redux';

export function useCachedSelector<TState, TSelected>(selector: Selector<TState, TSelected>): TSelected {
	const storeValue = useSelector(selector)
	const refValue = useRef<TSelected>(storeValue)

	if (storeValue !== null && storeValue !== undefined) {
		refValue.current = storeValue
	}

	return refValue.current
}
