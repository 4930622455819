import { RootState } from '@root/store';
import { WatcherID } from '@root/store/slices/watchersTypes';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ulid } from 'ulid';

// onSuccess?: () => void
export const useWatcher = () => {
    const state = useMemo(() => {
        return {
            watcherId: ulid()
        }
    }, [])

    return state
}

export function useWatcherObserver<T>(watcherId?: WatcherID, onSuccess?: (context: T) => void, onFailure?: () => void) {
    const watcher = useSelector((s: RootState) => watcherId ? s.watchers?.[watcherId] : null)

    const config = useMemo(() => {
        if (!watcherId) {
            return undefined
        }
        return {
            onSuccess,
            onFailure
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSuccess, onFailure])

    const hasTriggered = useRef(false)

    useEffect(() => {
        hasTriggered.current = false
    }, [watcherId])

    useEffect(() => {
        if (!watcherId) {
            hasTriggered.current = false
            return
        }

        if (watcher?.state == 'done') {
            if (hasTriggered.current == false) {
                config?.onSuccess?.(watcher?.context as any)
                hasTriggered.current = true
                return
            }
        }
        if (watcher?.state == 'fail') {
            if (hasTriggered.current == false) {
                config?.onFailure?.()
                hasTriggered.current = true
                return
            }
        }
        hasTriggered.current = false
    }, [watcher, config, watcherId])

    return { watcherId, state: watcher?.state, context: watcher?.context }
}