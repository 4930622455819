import { FieldSeparator } from '@comps/layout/fieldSeparator'
import { Inset } from '@comps/layout/inset'
import React from 'react'
import styled from 'styled-components'

export const FieldsList: React.FC<{ children: React.ReactNode[] }> = ({ children }) => {
	return (
		<Grid>
			{children.map((c, i, a) => {
				if (i % 2 === 1) return null
				return (
					<React.Fragment key={i}>
						<Cell>{children[i]}</Cell>
						<Cell>
							{' '}
							<Inset padding="medium" negative>
								{children[i + 1]}
							</Inset>
						</Cell>
						{i !== a.length - 2 ? (
							<SeparatorHolder>
								<FieldSeparator height="slim" />
							</SeparatorHolder>
						) : null}
					</React.Fragment>
				)
			})}
		</Grid>
	)
}

const Grid = styled.div`
	display: grid;
	grid-template-columns: 100px minmax(0, 1fr);
`

const SeparatorHolder = styled.div`
	grid-column: span 2;
`

const Cell = styled.div`
	padding: 10px 0px;
`
