import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@store/index';

import { Collection } from '../commonTypes';
import { ActivityData } from '../slices/activitiesTypes';

export const useActivities = (): Collection<ActivityData> => {
	const activitiesIndex = useSelector((state: RootState) => {
		return state.activities as { [key: string]: ActivityData }
	})

	return useMemo(() => {
		return {
			at: {
				...activitiesIndex,
			},
			all: Object.keys(activitiesIndex).sort((a, b) => (activitiesIndex[a].name > activitiesIndex[b].name ? 1 : -1)),
		}
	}, [activitiesIndex])
}

export const useActivity = (id?: string) => {
	const activities = useActivities()

	return useMemo(() => {
		if (!id) return undefined
		return activities.at?.[id]
	}, [activities, id])
}
