import { RoutineSchedulePeriod, RoutineScheduleTime } from '@clepside/clepsidejs/lib/packets_v1/routine_schedule_pb'
import { RoutineID } from './routines.types'
import { RoutineSchedulePacketObject } from './sync.tsx.packets.types'
import { LocalData } from './sync.types'

export type RoutineScheduleData = LocalData<RoutineSchedulePacketObject>
export type RoutineScheduleID = string
export type NormalisedRoutineScheduleData = Omit<Required<RoutineScheduleData>, 'period' | 'time'> & {
    period?: RoutineSchedulePeriod.AsObject
    time?: RoutineScheduleTime.AsObject
}

export type RoutinesScheduleState = {
    forRoutineId: {
        [key: RoutineID]: RoutineScheduleID[]
    },
    at: {
        [key: RoutineScheduleID]: NormalisedRoutineScheduleData
    },
    all: RoutineScheduleID[]
}

export const InitialRoutinesSchedulesState: RoutinesScheduleState = {
    at: {},
    all: [],
    forRoutineId: {},
}