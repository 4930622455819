import hotkeys from 'hotkeys-js'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

const hotkeysCache: any = {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
hotkeys.filter = (e: KeyboardEvent) => {
	const element = (e.target || e.srcElement) as HTMLDivElement
	const hasBackspaceOverrideParent = element.closest('.allow-backspace-override')

	if (hasBackspaceOverrideParent || element.classList.contains('allow-backspace-override')) {
		if (e.key == 'Backspace') {
			return false
		}
		// return false
		// 	for (const key of HotkeysSpecialSlateResponderKeys) {
		// 		if (e.key != key)
		// 			continue
		// 		if (FirstResponderCore.active[key] == true)
		// 			return true
		// 	}
		// 	return false
	}

	return true
}

export function useHotkey(scope?: string, keys?: string, actionCallback?: (keyboardEvent: KeyboardEvent) => void, keyup?: boolean) {
	const dispatch = useDispatch()
	const action = useCallback(
		(e: any) => {
			actionCallback?.(e)
		},
		[actionCallback]
	)

	useEffect(() => {
		if (!scope) return
		if (!keys) return
		if (!action) return

		if (!hotkeysCache[scope]) hotkeysCache[scope] = {}

		if (hotkeysCache[scope][keys]) {
			hotkeys.unbind(keys, scope, hotkeysCache[scope][keys])
			hotkeysCache[scope][keys] = undefined
		}

		hotkeysCache[scope][keys] = action

		hotkeys(keys, { scope, keyup: keyup ? true : false, keydown: keyup ? false : true }, action)
	}, [keys, scope, action, keyup, dispatch])
}
