import { motion } from 'framer-motion';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

export const CardStyle = css`
	overflow: hidden;
	background-color: #fff;
	box-sizing: border-box;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 6px 6px rgba(71, 77, 85, 0.05), 0px 25px 17px rgba(40, 46, 61, 0.04);
	border-radius: 15px;
	backface-visibility: hidden;
`

export const MotionCardContainer: React.FC<React.ComponentProps<typeof motion.div>> = ({ ...p }) => {
	const onClick = useCallback((e: any) => {
		e.stopPropagation()
	}, [])

	return (
		<MotionHolder onClick={onClick} {...p}>
			{p.children}
		</MotionHolder>
	)
}

export const CardContainer: React.FC<{ ancestor?: boolean; children?: React.ReactNode; translucent?: 'light'; className?: string }> = ({ ...p }) => {
	return <Holder {...p}>{p.children}</Holder>
}

// motion.div
const MotionHolder = styled(motion.div)`
	${CardStyle};
`

// motion.div
const Holder = styled.div<{ translucent?: 'light'; ancestor?: boolean }>`
	${CardStyle};

	${(p) =>
		p.translucent === 'light' &&
		css`
			background: rgba(255, 255, 255, 0.7);
			backdrop-filter: blur(50px);
		`}

	${(p) =>
		p.ancestor &&
		css`
			border-radius: 20px;
		`}
`
