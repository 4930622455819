import { Flex } from '@comps/layout/flex'
import { useWatcherObserver } from '@root/hooks/useWatcher'
import { WatcherID } from '@root/store/slices/watchersTypes'
import { capitalizeFirstLetter } from '@root/utils/text'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Label } from './label'

export const TextInputGroup: React.FC<{
	label?: string
	children: React.ReactNode
	optional?: boolean
	watcherId?: WatcherID
	error?: string
	stretch?: boolean
	isFromApp?: boolean
	rightSideMemo?: React.ReactNode
}> = ({ label, children, stretch, isFromApp, optional, watcherId, error, rightSideMemo }) => {
	const { state, context } = useWatcherObserver(watcherId)
	const [shownError, setShownError] = useState<undefined | string>(undefined)

	useEffect(() => {
		setShownError(error)
	}, [error])

	useEffect(() => {
		if (state == 'fail') {
			if (context?.type == 'error') {
				setShownError(context?.message)
			} else {
				setShownError('')
			}
		} else {
			setShownError('')
		}
	}, [state, context])

	return (
		<Holder stretch={stretch}>
			{label || rightSideMemo ? (
				<>
					<Flex justify="space-between" width="100%" align="stretch">
						{label ? <Label>{label}</Label> : null}
						{rightSideMemo}
					</Flex>
				</>
			) : null}
			{children}
			{shownError ? (
				<>
					<ErrorHolder isFromApp={isFromApp}>
						<Emoji>&#9888;&#65039;</Emoji>
						<ErrorBox>{capitalizeFirstLetter(shownError)}</ErrorBox>
					</ErrorHolder>
				</>
			) : (
				''
			)}
		</Holder>
	)
}

const Holder = styled.div<{ stretch?: boolean }>`
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	${p => p.stretch && css`
		align-self: stretch;
	`}
`

const ErrorHolder = styled.div<{ isFromApp?: boolean }>`
	display: flex;
	align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.025);
	padding: 10px;
	border-radius: 12px;

	${p => p.isFromApp && css`
		background-color: rgba(0, 0, 0, 0.3);
		flex-direction: row;
		align-items: center;
	`}
`

const ErrorBox = styled.div`
	padding: 0;
	border-radius: 3px;
	margin-top: 1px;
	text-align: left;
	margin-left: 10px;

	font-size: 13px;
	font-weight: 500;
	letter-spacing: -1px;
	display: block;
	letter-spacing: 0;
	color: currentColor;
	line-height: 16px;

`

const Emoji = styled.div`
	font-family: Arial, Helvetica, sans-serif;
	font-size: 15px;
	position: relative;
	top: -0.5px;
`
