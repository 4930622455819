import { RoutineTag } from '@comps/complex/routineTag'
import { Button } from '@comps/interactive/button'
import { CardContainer } from '@comps/layout/cardContainer'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { Spacer } from '@comps/layout/space'
import { ActivityBubble } from '@comps/static/activityBubble'
import { Logo } from '@comps/static/logo'
import { Text } from '@comps/typography/text'
import { RootState } from '@root/store'
import { useActivities } from '@root/store/selectors/useActivities'
import { interfaceActions } from '@root/store/slices/interface'
import { routinesActions, useRoutines } from '@root/store/slices/routines'
import { getScheduleDescription } from '@root/store/slices/routineSchedules.utils'
import { AnimatePresence } from 'framer-motion'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Modal } from './modal'

export const ScheduleRoutineModal = () => {
	const dispatch = useDispatch()
	const payload = useSelector((state: RootState) => state.interface.showScheduleForRoutine)
	const routines = useRoutines()
	const activities = useActivities()

	const schedule = useMemo(() => {
		if (!payload?.scheduleId) return undefined
		return routines.schedules.at[payload.scheduleId]
	}, [routines, payload])

	const routine = useMemo(() => {
		if (!payload?.routineId) return undefined
		return routines.at[payload.routineId]
	}, [routines, payload])

	const activity = useMemo(() => {
		if (!routine?.activityId) return undefined

		return activities.at[routine?.activityId]
	}, [routine, activities])

	const activityIcon = useMemo(() => {
		if (!activity) return undefined
		return {
			color: activity.color,
			icon: activity.icon,
		}
	}, [activity])

	const content = useMemo(() => {
		if (!schedule) return <Text>No schedule</Text>
		return (
			<Holder>
				<Flex direction="column" spacing={15}>
					<RoutineTag />
					<Flex spacing={15} align="center">
						{activityIcon && <ActivityBubble activity={activityIcon} size="medium" status={'inactiveLight'} nuanceAttr="s2" />}
						<Text level="title30">{routine?.name || activity?.name}</Text>
					</Flex>
				</Flex>
				<Text>
					{getScheduleDescription(schedule.period, schedule.time).map((c, i) => {
						return <span key={i} style={c.style}>{c.text}</span>
					})}
					<Spacer vertical size={50} />
				</Text>
				<Flex direction="column" align="stretch" spacing={8}>
					<FooterTop>
						{payload && routine && <Button inset="buttonMedium" color="accent" grow onClick={() => {
							dispatch(routinesActions.createSession({
								startTime: payload.startAt,
								endTime: payload.endAt,
								activityId: routine.activityId,
								title: routine.name,
								routineId: routine.id,
								description: routine.description
							}))
						}}>
							<Flex align="center" direction="column" grow>
								Create Session
							</Flex>
						</Button>}
						{activity && (
							<Link to={`/activities/${activity.id}/routines`}>
								<Button inset="buttonMedium" grow>
									<Flex align="center" direction="column" grow>
										Edit Routine
									</Flex>
								</Button>
							</Link>
						)}
					</FooterTop>
					<Button
						inset="buttonMedium"
						onClick={() => {
							dispatch(interfaceActions.hideScheduleRoutineModal())
						}}
					>
						<Flex align="center" direction="column" grow>
							Cancel
						</Flex>
					</Button>
				</Flex>
			</Holder>
		)
	}, [schedule, routine, activityIcon, payload, dispatch, activity])

	return (
		<AnimatePresence>
			{schedule ? (
				<Modal
					onBack={() => {
						dispatch(interfaceActions.hideScheduleRoutineModal())
					}}
					extraZ={10000}
				>
					<LogoHolder>
						<Logo size={32} />
					</LogoHolder>
					<Spanner>
						<Contents>
							<CardContainer>
								<Inset padding="biggerCard">{content}</Inset>
							</CardContainer>
						</Contents>
					</Spanner>
				</Modal>
			) : null}
		</AnimatePresence>
	)
}

const Holder = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 320px;
	row-gap: 24px;
`

const LogoHolder = styled.div`
	position: absolute;
	left: 50%;
	opacity: 0.2;
	top: 30px;
	transform: translateX(-50%);
`

const Spanner = styled.div`
	width: 100%;
	height: 100%;
`

const Contents = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`

const FooterTop = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 8px;
`
