import { TextInput } from '@comps/forms/textInput';
import { TextInputGroup } from '@comps/forms/textInputGroup';
import { WatcherButton, WatcherButtonAction } from '@comps/interactive/watcherButton';
import { Flex } from '@comps/layout/flex';
import { Inset } from '@comps/layout/inset';
import { Line } from '@comps/layout/line';
import { Spacer } from '@comps/layout/space';
import { Cell, HeaderCell, Row, Table } from '@comps/layout/table';
import { Text } from '@comps/typography/text';
import { useForm } from '@root/hooks/useForm';
import { useFormConfig } from '@root/hooks/useFormConfig';
import { useWatcher, useWatcherObserver } from '@root/hooks/useWatcher';
import { RootState } from '@root/store';
import { useUser } from '@root/store/selectors/useUser';
import { userInvitesActions } from '@root/store/slices/userInvites';
import { WatcherID } from '@root/store/slices/watchersTypes';
import { collectionMap, getDataAttribute } from '@root/utils/general';
import { active } from 'd3';
import { format } from 'date-fns';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const SettingsInvites = () => {
	const dispatch = useDispatch()
	const invites = useSelector((state: RootState) => state.userInvites)
	const { watcherId: inviteWatcherId } = useWatcher()
	const activeUser = useUser()
	const formConfig = useFormConfig(
		{
			fields: {
				email: {
					validate: 'required,email',
				},
			},
			onSubmit: (state, values) => {
				dispatch(userInvitesActions.inviteUser({ watcherId: inviteWatcherId, email: values.email }))
			},
		},
		[]
	)
	const { fields, injectFormField, submit, state: formState, errors } = useForm(formConfig)

	useWatcherObserver(inviteWatcherId, () => {
		formState.current.at.email.node.value = ''
		formState.current.at.email.node.dispatchEvent(new Event('keyup'))
	})

	useEffect(() => {
		dispatch(userInvitesActions.fetchPastInvites())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active])

	const handleRevokeAction = useCallback<WatcherButtonAction>((watcherId: WatcherID, e) => {
		const email = getDataAttribute(e, 'email')
		dispatch(userInvitesActions.revokeInvitee({ watcherId, email }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Flex direction="column">
			<Spacer size={12} vertical />
			<Text level="title60">Clepside Invites</Text>
			<Spacer size={50} vertical />
			<Flex direction="column" spacing={20} maxWidth="640px">
				<Text level="body" bold>
					From here you can give Clepside access to others.
				</Text>
				<Text level="body" color="subtle">
					We're currently throttling the number of users on the platform, this ensures we can focus on building while
					staying in close touch with you.
				</Text>
				<Text level="body" color="subtle">
					We'll increase the number of invites left as we progress through our journey building Clepside, keep close
					with us on <a href="https://reddit.com/r/clepside" target="_blank" rel="noopener noreferrer">r/Clepside</a>.
				</Text>
				<Text level="body" color="subtle">
					{!invites.invitesLeft ? (
						<Text>
							You have <b>no invites</b> left, invites are granted by the Clepside team
						</Text>
					) : (
						<Text>
							Number of invitations left: <b>{invites.invitesLeft}</b>
						</Text>
					)}
				</Text>
			</Flex>
			<Spacer size={30} vertical />
			<Line />
			<Spacer size={30} vertical />
			<TextInputGroup label="Invitee Email Address" optional error={errors?.email} watcherId={inviteWatcherId}>
				<Flex width="100%" spacing={10}>
					<TextInput
						inputStyle="block"
						placeholder="Email address"
						{...fields.email}
						ref={injectFormField('email', 'text')}
					/>
					<WatcherButton onClick={submit} color="accent" insetPadding="buttonMedium" watcherId={inviteWatcherId}>
						Invite to Clepside
					</WatcherButton>
				</Flex>
			</TextInputGroup>
			<Spacer size={30} vertical />
			<Line />
			<Spacer size={30} vertical />
			<Table layout="auto auto auto auto" fullWidth>
				<HeaderCell>Sent at</HeaderCell>
				<HeaderCell>Email</HeaderCell>
				<HeaderCell>Status</HeaderCell>
				<HeaderCell align="right">Actions</HeaderCell>
				{collectionMap(invites.invites, (i) => {
					return [
						<Row key={i.inviteeEmail}>
							<Cell>
								<Text>{format(new Date(i.sentAt), 'dd MMMM yyyy, HH:mm')}</Text>
							</Cell>
							<Cell>
								<Text>{i.inviteeEmail}</Text>
							</Cell>
							<Cell>
								<Text>{i.acceptedAt ? 'Accepted' : 'Sent'}</Text>
							</Cell>
							<Cell align="right">
								<Inset padding="buttonTiny" justify="center" negative>
									<WatcherButton
										insetPadding="buttonTiny"
										action={handleRevokeAction}
										data-email={i.inviteeEmail}
									>
										<Text>Revoke</Text>
									</WatcherButton>
								</Inset>
							</Cell>
						</Row>,
					]
				})}
			</Table>
		</Flex>
	)
}

// items: string[]
// structure: {
// 	[key: string]: {
// 		align?: 'right' | 'left'
// 		render: (id: string) => React.ReactElement
// 	}
// }
// layout: string
// inToast?: boolean
// type?: 'bordered' | 'highlighted'
