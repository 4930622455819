import { DayGrid } from '@comps/complex/planning/dayGrid'
import { Flex } from '@comps/layout/flex'
import { Spacer } from '@comps/layout/space'
import { useRunningSession } from '@hooks/useVitals'
import { useGlobalSelection } from '@root/hooks/useSelectable'
import { WeekDimensions } from '@root/hooks/useWeekDimensions.types'
import { ViewID } from '@root/store/slices/interface.types'
import { useRoutinesRecommandations } from '@root/store/slices/routines.recommendations'
import { ExtractOnlyExisting, SelectableTypes } from '@root/store/slices/selection.types'
import { Collection } from '@store/commonTypes'
import { InstancedSessionData } from '@store/slices/sessionsTypes'
import { range } from '@utils/general'
import { addDays, differenceInDays } from 'date-fns'
import { subDays } from 'date-fns/esm'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { SessionBlockLogic } from './sessionBlockLogic'
import { useDraggingSession } from './sessionPlacer.useDraggingSession'
import { useFreeIntervals } from './sessionPlacer.useFreeIntervals'
import { useHighlightedSession } from './sessionPlacer.useHighlightedSession'
import { useResizingSession } from './sessionPlacer.useResizingSession'
import { useSessionHighlightStart } from './sessionPlacer.useSessionHighlightStart'
import { TimeBar } from './timeBar'

//sessions: Collection<Session>, shownSessionsIds: string[]
const buildVector = () => {
	const vector: any[] = range(26).map((l, i) => {
		return 1.5
	})

	// shownSessionsIds.forEach((id) => {
	// 	const s = sessions.at[id]
	// 	const session = {
	// 		...s,
	// 		grid: {
	// 			at: differenceInMinutes(s.start, startOfDay(s.start)),
	// 			for: differenceInMinutes(s.end, s.start),
	// 		},
	// 	}

	// 	// const i = Math.floor(session.grid.at / 60)
	// 	// const j = Math.floor((session.grid.at + session.grid.for) / 60)

	// 	// if (differenceInMinutes(session.end, session.start) > 30) {
	// 	// 	vector[session.start.getHours()] = 1.3
	// 	// 	vector[session.end.getHours()] = 1.3
	// 	// }

	// 	// for (let k = i; k <= j; k++) {
	// 	// 	let newSize = 0.8
	// 	// 	// if (session.grid.for < 30) newSize = 1.7
	// 	// 	if (vector[k] < newSize) vector[k] = newSize
	// 	// }
	// })

	return vector
}

export const CalendarSessionsPlacer: React.FC<{
	sessions: Collection<InstancedSessionData>
	presentedDates: Date[]
	presentedDatesIndexes: number[]
	hideTimes?: boolean
	offsetLeft?: number
	dimensionsBasedOnWindowObject?: boolean
	onSelectionMade: (selection?: string) => void
	noPadding?: boolean
	selectionZoneId: ExtractOnlyExisting<ViewID, 'Plan' | 'Plan.Weekly'>
	container: any
	setContainer: any
	dimensions: WeekDimensions
	dragZoneId: string
}> = ({
	sessions,
	dimensionsBasedOnWindowObject,
	presentedDatesIndexes,
	offsetLeft,
	noPadding,
	hideTimes,
	presentedDates,
	onSelectionMade,
	selectionZoneId,
	container,
	setContainer,
	dimensions,
	dragZoneId,
}) => {
		const loadedDates = useMemo(() => {
			const length = presentedDates.length
			const start = subDays(presentedDates[0], length)
			const end = addDays(presentedDates[length - 1], length)
			const dates = []
			const count = differenceInDays(end, start)

			for (let i = 0; i < count; i++) {
				dates.push(addDays(start, i))
			}

			return dates
		}, [presentedDates])
		const { freeIntervals, freeIntervalsState } = useFreeIntervals(sessions, loadedDates)
		const { presentedSessionId } = useParams()

		useEffect(() => {
			setPresented(presentedSessionId)
		}, [presentedSessionId])

		const vector = useMemo(buildVector, [])
		const [presented, setPresented] = useState<string | undefined>(presentedSessionId)
		const previouslySelectedRef = useRef<any>(presentedSessionId)

		useEffect(() => {
			let newSelection = undefined
			if (presented) newSelection = presented
			if (newSelection !== previouslySelectedRef.current) {
				onSelectionMade?.(newSelection)
				previouslySelectedRef.current = newSelection
			}
		}, [onSelectionMade, presented])

		const { handleDragStart } = useSessionHighlightStart(presentedDatesIndexes, vector, container, dimensions)
		const { highlight } = useHighlightedSession(container, vector, presentedDatesIndexes, dimensions, sessions, dragZoneId, freeIntervals, offsetLeft)
		const activeSession = useRunningSession()
		const { selection } = useGlobalSelection()

		const selectedSession = useMemo(() => {
			if (selection.type == SelectableTypes.SESSION) {
				if (selection.all.length == 1) {
					return sessions.at[selection.all[0].id]
				}
			}
		}, [sessions, selection])

		const { draggedSession } = useDraggingSession(container, vector, presentedDatesIndexes, dimensions, dragZoneId, freeIntervals, selectedSession)
		const { resizedSession } = useResizingSession(container, vector, presentedDatesIndexes, dimensions, dragZoneId, freeIntervals, selectedSession)
		const dynamicSession = draggedSession || resizedSession

		const routinesRecommandations = useRoutinesRecommandations(freeIntervalsState, draggedSession, highlight?.info)

		const shownSessions = useMemo(() => {
			return sessions.all.filter((s) => {
				return sessions.at[s].daySpan.filter((index) => presentedDatesIndexes.includes(index)).length > 0
			})
		}, [sessions, presentedDatesIndexes])

		const displayVector = useMemo(() => {
			const dV = []
			let noChangeCount = 0

			for (let k = 1; k < vector.length; k++) {
				if (vector[k] === vector[k - 1] && vector[k] < 0.5) noChangeCount++

				if (noChangeCount % 2 === 1) dV[k] = false
				else dV[k] = true
			}

			dV[0] = dV[1]

			return dV
		}, [vector])

		const isActive = useCallback(
			(sId: any) => {
				return activeSession && 'id' in activeSession ? sId === (activeSession as any).id : false
			},
			[activeSession]
		)

		const handleDeselection = useCallback((e: any) => {
			if (!e.target.closest('[data-sid]')) {
				setPresented(undefined)
			}
		}, [])

		const handleSelection = useCallback((e: any) => {
			if (e.target.getAttribute('data-sid')) setPresented(e.target.getAttribute('data-sid'))
			if (e.target.closest('[data-sid]')) setPresented(e.target.closest('[data-sid]').getAttribute('data-sid'))
		}, [])

		const renderTimebar = useMemo(() => {
			return <TimeBar vector={vector} dayIndex={0} offsetLeft={offsetLeft} dimensions={dimensions} presentedDates={presentedDates} />
		}, [vector, dimensions, presentedDates, offsetLeft])

		// useWhatChanged([
		// 	sessions,
		// 	dimensionsBasedOnWindowObject,
		// 	offsetLeft,
		// 	noPadding,
		// 	hideTimes,
		// 	presentedDates,
		// 	onSelectionMade,
		// 	renderTimebar,
		// 	displayVector,
		// 	shownSessions,
		// 	draggedSession,
		// 	selectedSession,
		// 	highlight,
		// 	handleDragStart,
		// 	selected,
		// 	vector,
		// 	presentedDatesIndexes,
		// 	loadedDates,
		// 	container,
		// 	setContainer,
		// 	dragZoneId,
		// 	dimensions,
		// 	freeIntervals,
		// ])

		const grid = useMemo(() => {
			return (
				<DayGrid
					height={60}
					key="dayGrid"
					dimensions={dimensions}
					presentedDatesIndexes={presentedDatesIndexes}
					vector={vector}
					displayVector={displayVector}
					hideTimes={hideTimes}
				/>
			)
		}, [dimensions, presentedDatesIndexes, vector, displayVector, hideTimes])

		const dispatch = useDispatch()
		// const selection = useSelection(selectionZoneId)
		// const handleDeletion = useCallback(() => {
		// 	if (!selection) return
		// 	for (const sessionId of selection.all) {
		// 		dispatch(sessionsActions.delete({ id: sessionId }))
		// 	}
		// }, [selection, dispatch])

		// useHotkey(selectionZoneId, 'backspace', handleDeletion)

		return (
			<Flex direction="column" align="stretch" grow>
				<Spacer vertical size={15} />
				<Holder onMouseDown={handleDragStart} ref={setContainer}>
					{dynamicSession && selectedSession ? (
						<SessionBlockLogic
							activityId={selectedSession.activityId}
							vector={vector}
							key={dynamicSession.id + 'drag'}
							container={container}
							isDragged
							isResized={!!resizedSession}
							dimensions={dimensions}
							offsetLeft={offsetLeft}
							presentedDatesIndexes={presentedDatesIndexes}
							entityId={dynamicSession.id}
							start={dynamicSession.start}
							isRoutine={false}
							isPresented={presented == selectedSession.id}
							end={dynamicSession.end}
							isSelected={true}
							selectionZoneId={selectionZoneId}
						/>
					) : undefined}
					{grid}
					{highlight?.element}
					{shownSessions.map((id) => {
						if (container === null) return null
						const s = sessions.at[id]
						if (!s || !s.activityId) return null

						return (
							<SessionBlockLogic
								isActive={isActive(s.id)}
								activityId={s.activityId}
								title={s.title}
								description={s.description}
								isResized={!!resizedSession}
								entityId={s.id}
								actAsShadow={s.id === dynamicSession?.id && !dynamicSession.isACopy}
								key={s.id === dynamicSession?.id ? `shadow${s.id}` : s.id}
								offsetLeft={offsetLeft}
								start={s.start}
								end={s.end}
								isRoutine={false}
								selectionZoneId={selectionZoneId}
								presentedDatesIndexes={presentedDatesIndexes}
								vector={vector}
								dimensions={dimensions}
								container={container}
								isSelected={!!selection.all.find((i) => i.id == s.id)} // tbd
								isPresented={presented == s.id}
								onDeselect={handleDeselection}
								onSelect={handleSelection}
							/>
						)
					})}
					{routinesRecommandations?.map((recom) => {
						if (container === null) return null

						return (
							<SessionBlockLogic
								activityId={recom.activityId}
								isResized={!!resizedSession}
								isPresented={false}
								actAsShadow={false}
								key={recom.scheduleId + recom.scheduleOriginDaystamp}
								offsetLeft={offsetLeft}
								start={recom.localStartAt}
								isSelected={false} // tbd
								end={recom.localEndAt}
								entityId={recom.routineId}
								selectionZoneId={selectionZoneId}
								presentedDatesIndexes={presentedDatesIndexes}
								vector={vector}
								subentityId={recom.scheduleId}
								isRoutine={true}
								dimensions={dimensions}
								container={container}
							/>
						)
					})}
					{renderTimebar}
				</Holder>
			</Flex>
		)
	}

const Holder = styled.div`
	flex-grow: 1;
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	transform: translateX(0) translateY(0);
`
