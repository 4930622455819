import { TextInput } from '@comps/forms/textInput'
import { TextInputGroup } from '@comps/forms/textInputGroup'
import { Button } from '@comps/interactive/button'
import { Link } from '@comps/interactive/link'
import { WatcherButton } from '@comps/interactive/watcherButton'
import { Flex } from '@comps/layout/flex'
import { Inset, InsetSize } from '@comps/layout/inset'
import { Text } from '@comps/typography/text'
import { useForm } from '@root/hooks/useForm'
import { useFormConfig } from '@root/hooks/useFormConfig'
import { useWatcher } from '@root/hooks/useWatcher'
import { Medias } from '@root/medias'
import { grpcActions } from '@root/store/slices/grpc.DEPRECATED'
import { authActions } from '@slices/auth'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

export const Login: React.FC = () => {
	const dispatch = useDispatch()
	const theme = useTheme()
	const navigate = useNavigate()

	useEffect(() => {
		dispatch(grpcActions.initAuthClients())
	}, [dispatch])

	const { watcherId: loggingWatcherId } = useWatcher()
	const formConfig = useFormConfig(
		{
			fields: {
				email: {
					validate: 'required,email',
				},
				password: {
					validate: 'required',
				},
			},
			onSubmit: (state, values) => {
				dispatch(
					authActions.login({
						watcherId: loggingWatcherId,
						provider: {
							email: values.email,
							password: values.password,
						},
					})
				)
			},
		},
		[]
	)
	const { fields, injectFormField, submit } = useForm(formConfig)

	return (
		<CardContent expand>
			<Flex direction="column" spacing={25} align="stretch" width="100%">
				<Text level="title50" align="left">
					Login
				</Text>
				<TextInputGroup label="Email">
					<TextInput
						inputStyle="block"
						inputMode='email'
						placeholder="Email Address"
						autoFocus
						ref={injectFormField('email', 'text')}
						{...fields.email}
					/>
				</TextInputGroup>
				<TextInputGroup label="Password">
					<TextInput
						inputStyle="block"
						placeholder="Password"
						id="password"
						type="password"
						name="password"
						ref={injectFormField('password', 'text')}
						{...fields.password}
					/>
				</TextInputGroup>
				<Flex direction="column" width="100%">
					<Flex width="100%" justify="space-between" align="center">
						<Text>
							<Link to="/forgot">Forgot Password?</Link>
						</Text>
						<WatcherButton
							insetPadding="buttonMedium"
							onClick={submit}
							color="accent"
							watcherId={loggingWatcherId}
						>
							Login &rarr;
						</WatcherButton>
					</Flex>
				</Flex>
			</Flex>
			<Flex direction="column" spacing={25} width="100%">
				<Divider />
				<Flex align="stretch" direction="column" spacing={15}>
					<Text align="center">
						Clepside is currently in <strong>invite-only BETA.</strong> You can still register as we slowly roll
						out invitations.
					</Text>
					<Button
						color="clepside"
						onClick={() => {
							navigate('/signup')
						}}
						grow
					>
						<Inset padding="buttonMedium" align="center" justify="center">
							✨ Create a new account
						</Inset>
					</Button>
				</Flex>
			</Flex>
		</CardContent>
	)
}

const CardContent = styled(Inset)`
	max-width: 320px;
	width: 100%;
	${InsetSize.biggerCard};
	flex-direction: column;
	display: flex;
	justify-content: space-between;

	@media ${Medias.PHONE} {
		padding: 0;
		align-items: flex-start;

		* {
			text-align: left;
		}
	}
`
const Divider = styled.div`
	width: 100%;
	height: 1px;
	${(p) => p.theme.border.subtle.t1.css('background-color')};
`
