/**
 * @fileoverview gRPC-Web generated client stub for boy
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.1
// source: services/boy.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var commons_commons_pb = require('../commons/commons_pb.js')
const proto = {};
proto.boy = require('./boy_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.boy.BoyServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.boy.BoyServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.boy.BoyUserPlainRequest,
 *   !proto.commons.GenericJobReponse>}
 */
const methodDescriptor_BoyService_ActivateUser = new grpc.web.MethodDescriptor(
  '/boy.BoyService/ActivateUser',
  grpc.web.MethodType.UNARY,
  proto.boy.BoyUserPlainRequest,
  commons_commons_pb.GenericJobReponse,
  /**
   * @param {!proto.boy.BoyUserPlainRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  commons_commons_pb.GenericJobReponse.deserializeBinary
);


/**
 * @param {!proto.boy.BoyUserPlainRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commons.GenericJobReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commons.GenericJobReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.boy.BoyServiceClient.prototype.activateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/boy.BoyService/ActivateUser',
      request,
      metadata || {},
      methodDescriptor_BoyService_ActivateUser,
      callback);
};


/**
 * @param {!proto.boy.BoyUserPlainRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commons.GenericJobReponse>}
 *     Promise that resolves to the response
 */
proto.boy.BoyServicePromiseClient.prototype.activateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/boy.BoyService/ActivateUser',
      request,
      metadata || {},
      methodDescriptor_BoyService_ActivateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.boy.BoyUserPlainRequest,
 *   !proto.commons.GenericJobReponse>}
 */
const methodDescriptor_BoyService_DeleteUser = new grpc.web.MethodDescriptor(
  '/boy.BoyService/DeleteUser',
  grpc.web.MethodType.UNARY,
  proto.boy.BoyUserPlainRequest,
  commons_commons_pb.GenericJobReponse,
  /**
   * @param {!proto.boy.BoyUserPlainRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  commons_commons_pb.GenericJobReponse.deserializeBinary
);


/**
 * @param {!proto.boy.BoyUserPlainRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commons.GenericJobReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commons.GenericJobReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.boy.BoyServiceClient.prototype.deleteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/boy.BoyService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_BoyService_DeleteUser,
      callback);
};


/**
 * @param {!proto.boy.BoyUserPlainRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commons.GenericJobReponse>}
 *     Promise that resolves to the response
 */
proto.boy.BoyServicePromiseClient.prototype.deleteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/boy.BoyService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_BoyService_DeleteUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.boy.BoyUserPlainRequest,
 *   !proto.commons.GenericJobReponse>}
 */
const methodDescriptor_BoyService_InviteUser = new grpc.web.MethodDescriptor(
  '/boy.BoyService/InviteUser',
  grpc.web.MethodType.UNARY,
  proto.boy.BoyUserPlainRequest,
  commons_commons_pb.GenericJobReponse,
  /**
   * @param {!proto.boy.BoyUserPlainRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  commons_commons_pb.GenericJobReponse.deserializeBinary
);


/**
 * @param {!proto.boy.BoyUserPlainRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commons.GenericJobReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commons.GenericJobReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.boy.BoyServiceClient.prototype.inviteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/boy.BoyService/InviteUser',
      request,
      metadata || {},
      methodDescriptor_BoyService_InviteUser,
      callback);
};


/**
 * @param {!proto.boy.BoyUserPlainRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commons.GenericJobReponse>}
 *     Promise that resolves to the response
 */
proto.boy.BoyServicePromiseClient.prototype.inviteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/boy.BoyService/InviteUser',
      request,
      metadata || {},
      methodDescriptor_BoyService_InviteUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.boy.BoyUserPlainRequest,
 *   !proto.commons.GenericJobReponse>}
 */
const methodDescriptor_BoyService_GrantInvites = new grpc.web.MethodDescriptor(
  '/boy.BoyService/GrantInvites',
  grpc.web.MethodType.UNARY,
  proto.boy.BoyUserPlainRequest,
  commons_commons_pb.GenericJobReponse,
  /**
   * @param {!proto.boy.BoyUserPlainRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  commons_commons_pb.GenericJobReponse.deserializeBinary
);


/**
 * @param {!proto.boy.BoyUserPlainRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commons.GenericJobReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commons.GenericJobReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.boy.BoyServiceClient.prototype.grantInvites =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/boy.BoyService/GrantInvites',
      request,
      metadata || {},
      methodDescriptor_BoyService_GrantInvites,
      callback);
};


/**
 * @param {!proto.boy.BoyUserPlainRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commons.GenericJobReponse>}
 *     Promise that resolves to the response
 */
proto.boy.BoyServicePromiseClient.prototype.grantInvites =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/boy.BoyService/GrantInvites',
      request,
      metadata || {},
      methodDescriptor_BoyService_GrantInvites);
};


module.exports = proto.boy;

