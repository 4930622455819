import { CardContainer } from '@comps/layout/cardContainer'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { Spacer } from '@comps/layout/space'
import { ActivityBubble } from '@comps/static/activityBubble'
import { Icon } from '@comps/static/icon'
import { Text } from '@comps/typography/text'
import { useActiveSession } from '@root/hooks/useActiveSession'
import { useTime } from '@root/hooks/useTime'
import { useActivities } from '@root/store/selectors/useActivities'
import { ActivityColorKey, ActivityColors } from '@root/store/slices/activities.colors.types'
import { getDuration, getSessionPeriod } from '@root/utils/dates'
import { differenceInSeconds } from 'date-fns'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const TodayActiveNow = () => {
	const activities = useActivities()
	const now = useTime('second')
	const { activeSession } = useActiveSession()

	const activity = activeSession?.activityId ? activities.at[activeSession.activityId] : undefined

	if (!activeSession || !activity) return null

	return (
		<Link to={`/session/${activeSession.id}`}>
			<ColoredCardContainer ancestor color={activity.color}>
				<Inset padding="mediumCard" direction="column" spacing={10} align="stretch" expand>
					<Flex align="center" direction="row" grow>
						<Flex align="flex-start" grow>
							<ActivityBubble activity={activity} size="medium" nuanceAttr="t1" />
							<Spacer size={15} />
							<Flex direction="column" grow>
								<Spacer size={2} vertical />
								<Text level="title10" block>
									{activity.name}
								</Text>
								<Spacer size={12} vertical />
								<Flex align="baseline" justify="space-between" grow>
									<Text level="tiny" block>
										Active now &nbsp;•&nbsp;{' '}
										{getSessionPeriod(activeSession.start, activeSession.end)}
										&nbsp;•&nbsp; Left:{' '}
										{getDuration(differenceInSeconds(activeSession.end, now))}
									</Text>
								</Flex>
							</Flex>
						</Flex>
						<Icon type="arrow" />
					</Flex>
				</Inset>
			</ColoredCardContainer>
		</Link>
	)
}

const ColoredCardContainer = styled(CardContainer)<{ color: ActivityColorKey }>`
	${(p) => ActivityColors[p.color].active.background.r.css('background-color')};
	${(p) => ActivityColors[p.color].active.text.r.css('color')};
	position: relative;

	box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.66);
	& > * {
		position: relative;
		z-index: 1;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: 0.1s ease background-color;
		box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2);
		border-radius: 20px;
		${(p) => ActivityColors[p.color].active.background.r.css('background-color')};
	}

	&:hover {
		&:before {
			${(p) => ActivityColors[p.color].active.background.s1.css('background-color')};
		}
	}

	&:active {
		&:before {
			${(p) => ActivityColors[p.color].active.background.s2.css('background-color')};
		}
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(-130deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 90%);
		opacity: 0.2;
	}
`
