import { RoutineSchedulePeriod } from '@clepside/clepsidejs/lib/packets_v1/routine_schedule_pb'
import { Button } from '@comps/interactive/button'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { useCachedSelector } from '@root/hooks/useCachedSelector'
import { RootState } from '@root/store'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

export const FlowsRoutineScheduleDaily: React.FC = () => {
	const context = useCachedSelector((state: RootState) => state.flows.flowContexts['routine-schedule-editing'])

	const dispatch = useDispatch()
	
	if (!context) return <span>No context available. Inform Support</span>

	return (
		<React.Fragment>
            <Flex spacing={10} direction='column' align='stretch' grow>
                <Button color={getColor(context.schedule?.period, 'daily')} grow tabIndex={40} >
                    <Inset padding="buttonMedium" align="center" direction='column'>
                        Continue
                    </Inset>
                </Button>
            </Flex>
		</React.Fragment>
	)
}


function getColor(period: RoutineSchedulePeriod.AsObject | undefined, key: keyof RoutineSchedulePeriod.AsObject ) {
    if (period?.[key]) {
        return 'accent'
    }
    return 'subtleAccent'
}