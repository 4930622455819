// source: services/sync_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var unions_v1_sendable_pb = require('../unions_v1/sendable_pb.js');
goog.object.extend(proto, unions_v1_sendable_pb);
goog.exportSymbol('proto.sync_v1.BatchedSendablesStream', null, global);
goog.exportSymbol('proto.sync_v1.BatchedSendablesStream.ResponseTypeCase', null, global);
goog.exportSymbol('proto.sync_v1.BatchedSendablesStreamArray', null, global);
goog.exportSymbol('proto.sync_v1.FetchActivitiesRequest', null, global);
goog.exportSymbol('proto.sync_v1.FetchBoardsRequest', null, global);
goog.exportSymbol('proto.sync_v1.FetchIndividualSessionsRequest', null, global);
goog.exportSymbol('proto.sync_v1.FetchNotificationsRequest', null, global);
goog.exportSymbol('proto.sync_v1.FetchPartialActivitiesRequest', null, global);
goog.exportSymbol('proto.sync_v1.FetchSessionsByActivityRequest', null, global);
goog.exportSymbol('proto.sync_v1.FetchSettingsRequest', null, global);
goog.exportSymbol('proto.sync_v1.SendableStream', null, global);
goog.exportSymbol('proto.sync_v1.SendableStream.ResponseTypeCase', null, global);
goog.exportSymbol('proto.sync_v1.StreamActivityRequest', null, global);
goog.exportSymbol('proto.sync_v1.StreamActivitySyncType', null, global);
goog.exportSymbol('proto.sync_v1.StreamBoardRequest', null, global);
goog.exportSymbol('proto.sync_v1.StreamCardRequest', null, global);
goog.exportSymbol('proto.sync_v1.StreamRoutineRequest', null, global);
goog.exportSymbol('proto.sync_v1.StreamSessionRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.FetchActivitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sync_v1.FetchActivitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.FetchActivitiesRequest.displayName = 'proto.sync_v1.FetchActivitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.FetchBoardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sync_v1.FetchBoardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.FetchBoardsRequest.displayName = 'proto.sync_v1.FetchBoardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.FetchSessionsByActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sync_v1.FetchSessionsByActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.FetchSessionsByActivityRequest.displayName = 'proto.sync_v1.FetchSessionsByActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.FetchSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sync_v1.FetchSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.FetchSettingsRequest.displayName = 'proto.sync_v1.FetchSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.SendableStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sync_v1.SendableStream.oneofGroups_);
};
goog.inherits(proto.sync_v1.SendableStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.SendableStream.displayName = 'proto.sync_v1.SendableStream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.BatchedSendablesStreamArray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sync_v1.BatchedSendablesStreamArray.repeatedFields_, null);
};
goog.inherits(proto.sync_v1.BatchedSendablesStreamArray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.BatchedSendablesStreamArray.displayName = 'proto.sync_v1.BatchedSendablesStreamArray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.BatchedSendablesStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sync_v1.BatchedSendablesStream.oneofGroups_);
};
goog.inherits(proto.sync_v1.BatchedSendablesStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.BatchedSendablesStream.displayName = 'proto.sync_v1.BatchedSendablesStream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.FetchNotificationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sync_v1.FetchNotificationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.FetchNotificationsRequest.displayName = 'proto.sync_v1.FetchNotificationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.FetchIndividualSessionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sync_v1.FetchIndividualSessionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.FetchIndividualSessionsRequest.displayName = 'proto.sync_v1.FetchIndividualSessionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.FetchPartialActivitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sync_v1.FetchPartialActivitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.FetchPartialActivitiesRequest.displayName = 'proto.sync_v1.FetchPartialActivitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.StreamSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sync_v1.StreamSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.StreamSessionRequest.displayName = 'proto.sync_v1.StreamSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.StreamActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sync_v1.StreamActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.StreamActivityRequest.displayName = 'proto.sync_v1.StreamActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.StreamBoardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sync_v1.StreamBoardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.StreamBoardRequest.displayName = 'proto.sync_v1.StreamBoardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.StreamCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sync_v1.StreamCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.StreamCardRequest.displayName = 'proto.sync_v1.StreamCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sync_v1.StreamRoutineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sync_v1.StreamRoutineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sync_v1.StreamRoutineRequest.displayName = 'proto.sync_v1.StreamRoutineRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.FetchActivitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.FetchActivitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.FetchActivitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchActivitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastFetchedAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activitiesDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    checkDeletions: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.FetchActivitiesRequest}
 */
proto.sync_v1.FetchActivitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.FetchActivitiesRequest;
  return proto.sync_v1.FetchActivitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.FetchActivitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.FetchActivitiesRequest}
 */
proto.sync_v1.FetchActivitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastFetchedAt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActivitiesDeletionsLastFetchedAt(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckDeletions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.FetchActivitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.FetchActivitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.FetchActivitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchActivitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getActivitiesDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCheckDeletions();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 last_fetched_at = 1;
 * @return {number}
 */
proto.sync_v1.FetchActivitiesRequest.prototype.getLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.FetchActivitiesRequest} returns this
 */
proto.sync_v1.FetchActivitiesRequest.prototype.setLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 activities_deletions_last_fetched_at = 2;
 * @return {number}
 */
proto.sync_v1.FetchActivitiesRequest.prototype.getActivitiesDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.FetchActivitiesRequest} returns this
 */
proto.sync_v1.FetchActivitiesRequest.prototype.setActivitiesDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool check_deletions = 3;
 * @return {boolean}
 */
proto.sync_v1.FetchActivitiesRequest.prototype.getCheckDeletions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sync_v1.FetchActivitiesRequest} returns this
 */
proto.sync_v1.FetchActivitiesRequest.prototype.setCheckDeletions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.FetchBoardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.FetchBoardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.FetchBoardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchBoardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastFetchedAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    boardsDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    checkDeletions: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.FetchBoardsRequest}
 */
proto.sync_v1.FetchBoardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.FetchBoardsRequest;
  return proto.sync_v1.FetchBoardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.FetchBoardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.FetchBoardsRequest}
 */
proto.sync_v1.FetchBoardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastFetchedAt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBoardsDeletionsLastFetchedAt(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckDeletions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.FetchBoardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.FetchBoardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.FetchBoardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchBoardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBoardsDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCheckDeletions();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 last_fetched_at = 1;
 * @return {number}
 */
proto.sync_v1.FetchBoardsRequest.prototype.getLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.FetchBoardsRequest} returns this
 */
proto.sync_v1.FetchBoardsRequest.prototype.setLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 boards_deletions_last_fetched_at = 2;
 * @return {number}
 */
proto.sync_v1.FetchBoardsRequest.prototype.getBoardsDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.FetchBoardsRequest} returns this
 */
proto.sync_v1.FetchBoardsRequest.prototype.setBoardsDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool check_deletions = 3;
 * @return {boolean}
 */
proto.sync_v1.FetchBoardsRequest.prototype.getCheckDeletions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sync_v1.FetchBoardsRequest} returns this
 */
proto.sync_v1.FetchBoardsRequest.prototype.setCheckDeletions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.FetchSessionsByActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.FetchSessionsByActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.FetchSessionsByActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchSessionsByActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionsByActivityLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pastTimestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sessionsDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    checkDeletions: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.FetchSessionsByActivityRequest}
 */
proto.sync_v1.FetchSessionsByActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.FetchSessionsByActivityRequest;
  return proto.sync_v1.FetchSessionsByActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.FetchSessionsByActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.FetchSessionsByActivityRequest}
 */
proto.sync_v1.FetchSessionsByActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSessionsByActivityLastFetchedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPastTimestamp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSessionsDeletionsLastFetchedAt(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckDeletions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.FetchSessionsByActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.FetchSessionsByActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.FetchSessionsByActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchSessionsByActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionsByActivityLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPastTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSessionsDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCheckDeletions();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string activity_id = 1;
 * @return {string}
 */
proto.sync_v1.FetchSessionsByActivityRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sync_v1.FetchSessionsByActivityRequest} returns this
 */
proto.sync_v1.FetchSessionsByActivityRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 sessions_by_activity_last_fetched_at = 2;
 * @return {number}
 */
proto.sync_v1.FetchSessionsByActivityRequest.prototype.getSessionsByActivityLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.FetchSessionsByActivityRequest} returns this
 */
proto.sync_v1.FetchSessionsByActivityRequest.prototype.setSessionsByActivityLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 past_timestamp = 3;
 * @return {number}
 */
proto.sync_v1.FetchSessionsByActivityRequest.prototype.getPastTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.FetchSessionsByActivityRequest} returns this
 */
proto.sync_v1.FetchSessionsByActivityRequest.prototype.setPastTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 sessions_deletions_last_fetched_at = 4;
 * @return {number}
 */
proto.sync_v1.FetchSessionsByActivityRequest.prototype.getSessionsDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.FetchSessionsByActivityRequest} returns this
 */
proto.sync_v1.FetchSessionsByActivityRequest.prototype.setSessionsDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool check_deletions = 5;
 * @return {boolean}
 */
proto.sync_v1.FetchSessionsByActivityRequest.prototype.getCheckDeletions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sync_v1.FetchSessionsByActivityRequest} returns this
 */
proto.sync_v1.FetchSessionsByActivityRequest.prototype.setCheckDeletions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.FetchSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.FetchSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.FetchSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastFetchedAt: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.FetchSettingsRequest}
 */
proto.sync_v1.FetchSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.FetchSettingsRequest;
  return proto.sync_v1.FetchSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.FetchSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.FetchSettingsRequest}
 */
proto.sync_v1.FetchSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastFetchedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.FetchSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.FetchSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.FetchSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 last_fetched_at = 1;
 * @return {number}
 */
proto.sync_v1.FetchSettingsRequest.prototype.getLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.FetchSettingsRequest} returns this
 */
proto.sync_v1.FetchSettingsRequest.prototype.setLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sync_v1.SendableStream.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.sync_v1.SendableStream.ResponseTypeCase = {
  RESPONSE_TYPE_NOT_SET: 0,
  SENDABLE: 1,
  STREAM_FINISHED: 2
};

/**
 * @return {proto.sync_v1.SendableStream.ResponseTypeCase}
 */
proto.sync_v1.SendableStream.prototype.getResponseTypeCase = function() {
  return /** @type {proto.sync_v1.SendableStream.ResponseTypeCase} */(jspb.Message.computeOneofCase(this, proto.sync_v1.SendableStream.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.SendableStream.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.SendableStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.SendableStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.SendableStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendable: (f = msg.getSendable()) && unions_v1_sendable_pb.Sendable.toObject(includeInstance, f),
    streamFinished: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.SendableStream}
 */
proto.sync_v1.SendableStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.SendableStream;
  return proto.sync_v1.SendableStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.SendableStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.SendableStream}
 */
proto.sync_v1.SendableStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new unions_v1_sendable_pb.Sendable;
      reader.readMessage(value,unions_v1_sendable_pb.Sendable.deserializeBinaryFromReader);
      msg.setSendable(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreamFinished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.SendableStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.SendableStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.SendableStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.SendableStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      unions_v1_sendable_pb.Sendable.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional unions_v1.Sendable sendable = 1;
 * @return {?proto.unions_v1.Sendable}
 */
proto.sync_v1.SendableStream.prototype.getSendable = function() {
  return /** @type{?proto.unions_v1.Sendable} */ (
    jspb.Message.getWrapperField(this, unions_v1_sendable_pb.Sendable, 1));
};


/**
 * @param {?proto.unions_v1.Sendable|undefined} value
 * @return {!proto.sync_v1.SendableStream} returns this
*/
proto.sync_v1.SendableStream.prototype.setSendable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.sync_v1.SendableStream.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sync_v1.SendableStream} returns this
 */
proto.sync_v1.SendableStream.prototype.clearSendable = function() {
  return this.setSendable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sync_v1.SendableStream.prototype.hasSendable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool stream_finished = 2;
 * @return {boolean}
 */
proto.sync_v1.SendableStream.prototype.getStreamFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sync_v1.SendableStream} returns this
 */
proto.sync_v1.SendableStream.prototype.setStreamFinished = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.sync_v1.SendableStream.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sync_v1.SendableStream} returns this
 */
proto.sync_v1.SendableStream.prototype.clearStreamFinished = function() {
  return jspb.Message.setOneofField(this, 2, proto.sync_v1.SendableStream.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sync_v1.SendableStream.prototype.hasStreamFinished = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sync_v1.BatchedSendablesStreamArray.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.BatchedSendablesStreamArray.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.BatchedSendablesStreamArray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.BatchedSendablesStreamArray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.BatchedSendablesStreamArray.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendablesList: jspb.Message.toObjectList(msg.getSendablesList(),
    unions_v1_sendable_pb.Sendable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.BatchedSendablesStreamArray}
 */
proto.sync_v1.BatchedSendablesStreamArray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.BatchedSendablesStreamArray;
  return proto.sync_v1.BatchedSendablesStreamArray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.BatchedSendablesStreamArray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.BatchedSendablesStreamArray}
 */
proto.sync_v1.BatchedSendablesStreamArray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new unions_v1_sendable_pb.Sendable;
      reader.readMessage(value,unions_v1_sendable_pb.Sendable.deserializeBinaryFromReader);
      msg.addSendables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.BatchedSendablesStreamArray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.BatchedSendablesStreamArray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.BatchedSendablesStreamArray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.BatchedSendablesStreamArray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      unions_v1_sendable_pb.Sendable.serializeBinaryToWriter
    );
  }
};


/**
 * repeated unions_v1.Sendable sendables = 1;
 * @return {!Array<!proto.unions_v1.Sendable>}
 */
proto.sync_v1.BatchedSendablesStreamArray.prototype.getSendablesList = function() {
  return /** @type{!Array<!proto.unions_v1.Sendable>} */ (
    jspb.Message.getRepeatedWrapperField(this, unions_v1_sendable_pb.Sendable, 1));
};


/**
 * @param {!Array<!proto.unions_v1.Sendable>} value
 * @return {!proto.sync_v1.BatchedSendablesStreamArray} returns this
*/
proto.sync_v1.BatchedSendablesStreamArray.prototype.setSendablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.unions_v1.Sendable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unions_v1.Sendable}
 */
proto.sync_v1.BatchedSendablesStreamArray.prototype.addSendables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.unions_v1.Sendable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sync_v1.BatchedSendablesStreamArray} returns this
 */
proto.sync_v1.BatchedSendablesStreamArray.prototype.clearSendablesList = function() {
  return this.setSendablesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sync_v1.BatchedSendablesStream.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.sync_v1.BatchedSendablesStream.ResponseTypeCase = {
  RESPONSE_TYPE_NOT_SET: 0,
  SENDABLES: 1,
  STREAM_FINISHED: 2
};

/**
 * @return {proto.sync_v1.BatchedSendablesStream.ResponseTypeCase}
 */
proto.sync_v1.BatchedSendablesStream.prototype.getResponseTypeCase = function() {
  return /** @type {proto.sync_v1.BatchedSendablesStream.ResponseTypeCase} */(jspb.Message.computeOneofCase(this, proto.sync_v1.BatchedSendablesStream.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.BatchedSendablesStream.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.BatchedSendablesStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.BatchedSendablesStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.BatchedSendablesStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendables: (f = msg.getSendables()) && proto.sync_v1.BatchedSendablesStreamArray.toObject(includeInstance, f),
    streamFinished: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.BatchedSendablesStream}
 */
proto.sync_v1.BatchedSendablesStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.BatchedSendablesStream;
  return proto.sync_v1.BatchedSendablesStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.BatchedSendablesStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.BatchedSendablesStream}
 */
proto.sync_v1.BatchedSendablesStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sync_v1.BatchedSendablesStreamArray;
      reader.readMessage(value,proto.sync_v1.BatchedSendablesStreamArray.deserializeBinaryFromReader);
      msg.setSendables(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreamFinished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.BatchedSendablesStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.BatchedSendablesStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.BatchedSendablesStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.BatchedSendablesStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendables();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sync_v1.BatchedSendablesStreamArray.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional BatchedSendablesStreamArray sendables = 1;
 * @return {?proto.sync_v1.BatchedSendablesStreamArray}
 */
proto.sync_v1.BatchedSendablesStream.prototype.getSendables = function() {
  return /** @type{?proto.sync_v1.BatchedSendablesStreamArray} */ (
    jspb.Message.getWrapperField(this, proto.sync_v1.BatchedSendablesStreamArray, 1));
};


/**
 * @param {?proto.sync_v1.BatchedSendablesStreamArray|undefined} value
 * @return {!proto.sync_v1.BatchedSendablesStream} returns this
*/
proto.sync_v1.BatchedSendablesStream.prototype.setSendables = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.sync_v1.BatchedSendablesStream.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sync_v1.BatchedSendablesStream} returns this
 */
proto.sync_v1.BatchedSendablesStream.prototype.clearSendables = function() {
  return this.setSendables(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sync_v1.BatchedSendablesStream.prototype.hasSendables = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool stream_finished = 2;
 * @return {boolean}
 */
proto.sync_v1.BatchedSendablesStream.prototype.getStreamFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sync_v1.BatchedSendablesStream} returns this
 */
proto.sync_v1.BatchedSendablesStream.prototype.setStreamFinished = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.sync_v1.BatchedSendablesStream.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sync_v1.BatchedSendablesStream} returns this
 */
proto.sync_v1.BatchedSendablesStream.prototype.clearStreamFinished = function() {
  return jspb.Message.setOneofField(this, 2, proto.sync_v1.BatchedSendablesStream.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sync_v1.BatchedSendablesStream.prototype.hasStreamFinished = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.FetchNotificationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.FetchNotificationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.FetchNotificationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchNotificationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastFetchedAt: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.FetchNotificationsRequest}
 */
proto.sync_v1.FetchNotificationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.FetchNotificationsRequest;
  return proto.sync_v1.FetchNotificationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.FetchNotificationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.FetchNotificationsRequest}
 */
proto.sync_v1.FetchNotificationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastFetchedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.FetchNotificationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.FetchNotificationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.FetchNotificationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchNotificationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 last_fetched_at = 1;
 * @return {number}
 */
proto.sync_v1.FetchNotificationsRequest.prototype.getLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.FetchNotificationsRequest} returns this
 */
proto.sync_v1.FetchNotificationsRequest.prototype.setLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.FetchIndividualSessionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.FetchIndividualSessionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.FetchIndividualSessionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchIndividualSessionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    guestSessionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pastTimestamp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.FetchIndividualSessionsRequest}
 */
proto.sync_v1.FetchIndividualSessionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.FetchIndividualSessionsRequest;
  return proto.sync_v1.FetchIndividualSessionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.FetchIndividualSessionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.FetchIndividualSessionsRequest}
 */
proto.sync_v1.FetchIndividualSessionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGuestSessionsLastFetchedAt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPastTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.FetchIndividualSessionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.FetchIndividualSessionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.FetchIndividualSessionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchIndividualSessionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGuestSessionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPastTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 guest_sessions_last_fetched_at = 1;
 * @return {number}
 */
proto.sync_v1.FetchIndividualSessionsRequest.prototype.getGuestSessionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.FetchIndividualSessionsRequest} returns this
 */
proto.sync_v1.FetchIndividualSessionsRequest.prototype.setGuestSessionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 past_timestamp = 2;
 * @return {number}
 */
proto.sync_v1.FetchIndividualSessionsRequest.prototype.getPastTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.FetchIndividualSessionsRequest} returns this
 */
proto.sync_v1.FetchIndividualSessionsRequest.prototype.setPastTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.FetchPartialActivitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.FetchPartialActivitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.FetchPartialActivitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchPartialActivitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastFetchedAt: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.FetchPartialActivitiesRequest}
 */
proto.sync_v1.FetchPartialActivitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.FetchPartialActivitiesRequest;
  return proto.sync_v1.FetchPartialActivitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.FetchPartialActivitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.FetchPartialActivitiesRequest}
 */
proto.sync_v1.FetchPartialActivitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastFetchedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.FetchPartialActivitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.FetchPartialActivitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.FetchPartialActivitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.FetchPartialActivitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 last_fetched_at = 1;
 * @return {number}
 */
proto.sync_v1.FetchPartialActivitiesRequest.prototype.getLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.FetchPartialActivitiesRequest} returns this
 */
proto.sync_v1.FetchPartialActivitiesRequest.prototype.setLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.StreamSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.StreamSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.StreamSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.StreamSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    invitationsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cardsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    checkDeletions: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    cardsDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    routinesDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.StreamSessionRequest}
 */
proto.sync_v1.StreamSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.StreamSessionRequest;
  return proto.sync_v1.StreamSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.StreamSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.StreamSessionRequest}
 */
proto.sync_v1.StreamSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvitationsLastFetchedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCardsLastFetchedAt(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckDeletions(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCardsDeletionsLastFetchedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoutinesDeletionsLastFetchedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.StreamSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.StreamSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.StreamSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.StreamSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInvitationsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCardsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCheckDeletions();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCardsDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRoutinesDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.sync_v1.StreamSessionRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sync_v1.StreamSessionRequest} returns this
 */
proto.sync_v1.StreamSessionRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 invitations_last_fetched_at = 2;
 * @return {number}
 */
proto.sync_v1.StreamSessionRequest.prototype.getInvitationsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamSessionRequest} returns this
 */
proto.sync_v1.StreamSessionRequest.prototype.setInvitationsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 cards_last_fetched_at = 3;
 * @return {number}
 */
proto.sync_v1.StreamSessionRequest.prototype.getCardsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamSessionRequest} returns this
 */
proto.sync_v1.StreamSessionRequest.prototype.setCardsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool check_deletions = 4;
 * @return {boolean}
 */
proto.sync_v1.StreamSessionRequest.prototype.getCheckDeletions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sync_v1.StreamSessionRequest} returns this
 */
proto.sync_v1.StreamSessionRequest.prototype.setCheckDeletions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 cards_deletions_last_fetched_at = 5;
 * @return {number}
 */
proto.sync_v1.StreamSessionRequest.prototype.getCardsDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamSessionRequest} returns this
 */
proto.sync_v1.StreamSessionRequest.prototype.setCardsDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 routines_deletions_last_fetched_at = 6;
 * @return {number}
 */
proto.sync_v1.StreamSessionRequest.prototype.getRoutinesDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamSessionRequest} returns this
 */
proto.sync_v1.StreamSessionRequest.prototype.setRoutinesDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.StreamActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.StreamActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.StreamActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.StreamActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    invitationsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cardsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    routinesLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    checkDeletions: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    cardsDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    routinesDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    syncType: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.StreamActivityRequest}
 */
proto.sync_v1.StreamActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.StreamActivityRequest;
  return proto.sync_v1.StreamActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.StreamActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.StreamActivityRequest}
 */
proto.sync_v1.StreamActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvitationsLastFetchedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCardsLastFetchedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoutinesLastFetchedAt(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckDeletions(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCardsDeletionsLastFetchedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoutinesDeletionsLastFetchedAt(value);
      break;
    case 8:
      var value = /** @type {!proto.sync_v1.StreamActivitySyncType} */ (reader.readEnum());
      msg.setSyncType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.StreamActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.StreamActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.StreamActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.StreamActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInvitationsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCardsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRoutinesLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCheckDeletions();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCardsDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getRoutinesDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getSyncType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string activity_id = 1;
 * @return {string}
 */
proto.sync_v1.StreamActivityRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sync_v1.StreamActivityRequest} returns this
 */
proto.sync_v1.StreamActivityRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 invitations_last_fetched_at = 2;
 * @return {number}
 */
proto.sync_v1.StreamActivityRequest.prototype.getInvitationsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamActivityRequest} returns this
 */
proto.sync_v1.StreamActivityRequest.prototype.setInvitationsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 cards_last_fetched_at = 3;
 * @return {number}
 */
proto.sync_v1.StreamActivityRequest.prototype.getCardsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamActivityRequest} returns this
 */
proto.sync_v1.StreamActivityRequest.prototype.setCardsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 routines_last_fetched_at = 4;
 * @return {number}
 */
proto.sync_v1.StreamActivityRequest.prototype.getRoutinesLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamActivityRequest} returns this
 */
proto.sync_v1.StreamActivityRequest.prototype.setRoutinesLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool check_deletions = 5;
 * @return {boolean}
 */
proto.sync_v1.StreamActivityRequest.prototype.getCheckDeletions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sync_v1.StreamActivityRequest} returns this
 */
proto.sync_v1.StreamActivityRequest.prototype.setCheckDeletions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 cards_deletions_last_fetched_at = 6;
 * @return {number}
 */
proto.sync_v1.StreamActivityRequest.prototype.getCardsDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamActivityRequest} returns this
 */
proto.sync_v1.StreamActivityRequest.prototype.setCardsDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 routines_deletions_last_fetched_at = 7;
 * @return {number}
 */
proto.sync_v1.StreamActivityRequest.prototype.getRoutinesDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamActivityRequest} returns this
 */
proto.sync_v1.StreamActivityRequest.prototype.setRoutinesDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional StreamActivitySyncType sync_type = 8;
 * @return {!proto.sync_v1.StreamActivitySyncType}
 */
proto.sync_v1.StreamActivityRequest.prototype.getSyncType = function() {
  return /** @type {!proto.sync_v1.StreamActivitySyncType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.sync_v1.StreamActivitySyncType} value
 * @return {!proto.sync_v1.StreamActivityRequest} returns this
 */
proto.sync_v1.StreamActivityRequest.prototype.setSyncType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.StreamBoardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.StreamBoardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.StreamBoardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.StreamBoardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    boardId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    invitationsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cardsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    checkDeletions: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    cardsDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.StreamBoardRequest}
 */
proto.sync_v1.StreamBoardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.StreamBoardRequest;
  return proto.sync_v1.StreamBoardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.StreamBoardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.StreamBoardRequest}
 */
proto.sync_v1.StreamBoardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBoardId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvitationsLastFetchedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCardsLastFetchedAt(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckDeletions(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCardsDeletionsLastFetchedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.StreamBoardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.StreamBoardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.StreamBoardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.StreamBoardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBoardId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInvitationsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCardsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCheckDeletions();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCardsDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string board_id = 1;
 * @return {string}
 */
proto.sync_v1.StreamBoardRequest.prototype.getBoardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sync_v1.StreamBoardRequest} returns this
 */
proto.sync_v1.StreamBoardRequest.prototype.setBoardId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 invitations_last_fetched_at = 2;
 * @return {number}
 */
proto.sync_v1.StreamBoardRequest.prototype.getInvitationsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamBoardRequest} returns this
 */
proto.sync_v1.StreamBoardRequest.prototype.setInvitationsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 cards_last_fetched_at = 3;
 * @return {number}
 */
proto.sync_v1.StreamBoardRequest.prototype.getCardsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamBoardRequest} returns this
 */
proto.sync_v1.StreamBoardRequest.prototype.setCardsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool check_deletions = 4;
 * @return {boolean}
 */
proto.sync_v1.StreamBoardRequest.prototype.getCheckDeletions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sync_v1.StreamBoardRequest} returns this
 */
proto.sync_v1.StreamBoardRequest.prototype.setCheckDeletions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 cards_deletions_last_fetched_at = 5;
 * @return {number}
 */
proto.sync_v1.StreamBoardRequest.prototype.getCardsDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamBoardRequest} returns this
 */
proto.sync_v1.StreamBoardRequest.prototype.setCardsDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.StreamCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.StreamCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.StreamCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.StreamCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    checkDeletions: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    textFragmentsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    textFragmentsDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    completableFragmentsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    completableFragmentsDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    uploadableFragmentsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    uploadableFragmentsDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    aiCardChatsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    aiCardChatsMessagesLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.StreamCardRequest}
 */
proto.sync_v1.StreamCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.StreamCardRequest;
  return proto.sync_v1.StreamCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.StreamCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.StreamCardRequest}
 */
proto.sync_v1.StreamCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckDeletions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTextFragmentsLastFetchedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTextFragmentsDeletionsLastFetchedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletableFragmentsLastFetchedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletableFragmentsDeletionsLastFetchedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUploadableFragmentsLastFetchedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUploadableFragmentsDeletionsLastFetchedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAiCardChatsLastFetchedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAiCardChatsMessagesLastFetchedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.StreamCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.StreamCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.StreamCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.StreamCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCheckDeletions();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTextFragmentsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTextFragmentsDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCompletableFragmentsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCompletableFragmentsDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUploadableFragmentsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUploadableFragmentsDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAiCardChatsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getAiCardChatsMessagesLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional string card_id = 1;
 * @return {string}
 */
proto.sync_v1.StreamCardRequest.prototype.getCardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sync_v1.StreamCardRequest} returns this
 */
proto.sync_v1.StreamCardRequest.prototype.setCardId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool check_deletions = 2;
 * @return {boolean}
 */
proto.sync_v1.StreamCardRequest.prototype.getCheckDeletions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sync_v1.StreamCardRequest} returns this
 */
proto.sync_v1.StreamCardRequest.prototype.setCheckDeletions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 text_fragments_last_fetched_at = 3;
 * @return {number}
 */
proto.sync_v1.StreamCardRequest.prototype.getTextFragmentsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamCardRequest} returns this
 */
proto.sync_v1.StreamCardRequest.prototype.setTextFragmentsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 text_fragments_deletions_last_fetched_at = 4;
 * @return {number}
 */
proto.sync_v1.StreamCardRequest.prototype.getTextFragmentsDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamCardRequest} returns this
 */
proto.sync_v1.StreamCardRequest.prototype.setTextFragmentsDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 completable_fragments_last_fetched_at = 5;
 * @return {number}
 */
proto.sync_v1.StreamCardRequest.prototype.getCompletableFragmentsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamCardRequest} returns this
 */
proto.sync_v1.StreamCardRequest.prototype.setCompletableFragmentsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 completable_fragments_deletions_last_fetched_at = 6;
 * @return {number}
 */
proto.sync_v1.StreamCardRequest.prototype.getCompletableFragmentsDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamCardRequest} returns this
 */
proto.sync_v1.StreamCardRequest.prototype.setCompletableFragmentsDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 uploadable_fragments_last_fetched_at = 7;
 * @return {number}
 */
proto.sync_v1.StreamCardRequest.prototype.getUploadableFragmentsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamCardRequest} returns this
 */
proto.sync_v1.StreamCardRequest.prototype.setUploadableFragmentsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 uploadable_fragments_deletions_last_fetched_at = 8;
 * @return {number}
 */
proto.sync_v1.StreamCardRequest.prototype.getUploadableFragmentsDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamCardRequest} returns this
 */
proto.sync_v1.StreamCardRequest.prototype.setUploadableFragmentsDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 ai_card_chats_last_fetched_at = 9;
 * @return {number}
 */
proto.sync_v1.StreamCardRequest.prototype.getAiCardChatsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamCardRequest} returns this
 */
proto.sync_v1.StreamCardRequest.prototype.setAiCardChatsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 ai_card_chats_messages_last_fetched_at = 10;
 * @return {number}
 */
proto.sync_v1.StreamCardRequest.prototype.getAiCardChatsMessagesLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamCardRequest} returns this
 */
proto.sync_v1.StreamCardRequest.prototype.setAiCardChatsMessagesLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sync_v1.StreamRoutineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sync_v1.StreamRoutineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sync_v1.StreamRoutineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.StreamRoutineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    routineId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    schedulesLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cardsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    checkDeletions: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    schedulesDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cardsDeletionsLastFetchedAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sync_v1.StreamRoutineRequest}
 */
proto.sync_v1.StreamRoutineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sync_v1.StreamRoutineRequest;
  return proto.sync_v1.StreamRoutineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sync_v1.StreamRoutineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sync_v1.StreamRoutineRequest}
 */
proto.sync_v1.StreamRoutineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoutineId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSchedulesLastFetchedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCardsLastFetchedAt(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckDeletions(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSchedulesDeletionsLastFetchedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCardsDeletionsLastFetchedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sync_v1.StreamRoutineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sync_v1.StreamRoutineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sync_v1.StreamRoutineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sync_v1.StreamRoutineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoutineId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSchedulesLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCardsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCheckDeletions();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSchedulesDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCardsDeletionsLastFetchedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string routine_id = 1;
 * @return {string}
 */
proto.sync_v1.StreamRoutineRequest.prototype.getRoutineId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sync_v1.StreamRoutineRequest} returns this
 */
proto.sync_v1.StreamRoutineRequest.prototype.setRoutineId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 schedules_last_fetched_at = 2;
 * @return {number}
 */
proto.sync_v1.StreamRoutineRequest.prototype.getSchedulesLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamRoutineRequest} returns this
 */
proto.sync_v1.StreamRoutineRequest.prototype.setSchedulesLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 cards_last_fetched_at = 3;
 * @return {number}
 */
proto.sync_v1.StreamRoutineRequest.prototype.getCardsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamRoutineRequest} returns this
 */
proto.sync_v1.StreamRoutineRequest.prototype.setCardsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool check_deletions = 4;
 * @return {boolean}
 */
proto.sync_v1.StreamRoutineRequest.prototype.getCheckDeletions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sync_v1.StreamRoutineRequest} returns this
 */
proto.sync_v1.StreamRoutineRequest.prototype.setCheckDeletions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 schedules_deletions_last_fetched_at = 5;
 * @return {number}
 */
proto.sync_v1.StreamRoutineRequest.prototype.getSchedulesDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamRoutineRequest} returns this
 */
proto.sync_v1.StreamRoutineRequest.prototype.setSchedulesDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 cards_deletions_last_fetched_at = 6;
 * @return {number}
 */
proto.sync_v1.StreamRoutineRequest.prototype.getCardsDeletionsLastFetchedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sync_v1.StreamRoutineRequest} returns this
 */
proto.sync_v1.StreamRoutineRequest.prototype.setCardsDeletionsLastFetchedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.sync_v1.StreamActivitySyncType = {
  RUNETYPE_DO_NOT_USE: 0,
  FULL_SYNC: 1,
  ROUTINES_ONLY: 2,
  EVERYTHING_BUT_ROUTINES: 3
};

goog.object.extend(exports, proto.sync_v1);
