import { cardLayoutToCells, Position } from '@root/components/complex/cards/grid.utils';
import { DragDropCore, DragDropListener } from '@root/cores/dragDropCore';
import { useGridDimensions } from '@root/hooks/useGridDimensions';
import { CardLayoutObject } from '@root/store/slices/cards.types';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CardSizeInPixels, Matrix } from './cardGrid.types';
import { ReturnCardLayouts } from './cardGrid.useAddCard';
import { useCardBehavioursUtils } from './cardGrid.useCardBehavioursUtils';

export const useCardResize = (
	gridRef: HTMLDivElement,
	m: React.MutableRefObject<Matrix>,
	addCard: ReturnCardLayouts,
	render: () => void,
	container: HTMLElement,
	dragZoneId: string
	// cardById?: React.MutableRefObject<undefined | { [k: string]: CardDatasourcePlusLayout }>
) => {
	const dispatch = useDispatch()
	const { resetMove, gestureFinished, simulateMove, simulateTheLayoutChange, gestureBegan, affectedCards } = useCardBehavioursUtils(
		m,
		addCard,
		render
	)
	const { gridCellWidthRef } = useGridDimensions(dragZoneId)
	const [placeholder, setPlaceholder] = useState<
		| {
				layout: CardLayoutObject
		  }
		| undefined
	>()

	useEffect(() => {
		if (!gridRef) return

		const listener: DragDropListener = (phase, context, e: any) => {
			if (context.type !== 'card-resize') return
			// const gesture = validateGesture(e, context, 'card-resize')

			// if (!gesture) return
			// const { x, y, cardData } = gesture

			const scrollOffsetContainer = document.querySelector('.card-grid-scroll')
			const scrollOffsetX = scrollOffsetContainer ? scrollOffsetContainer.scrollLeft || 0 : 0
			const scrollOffsetY = scrollOffsetContainer ? scrollOffsetContainer.scrollTop || 0 : 0

			const mouseStart: Position = {
				x: context.startCoords.x + context.initalSize.width - context.bottomRightOffset.x + scrollOffsetX,
				y: context.startCoords.y + context.initalSize.height - context.bottomRightOffset.y + scrollOffsetY,
			}

			// console.log(context.startCoords.x, context.gridOffset.x, scrollOffsetX)
			const difference: Position = {
				x: e.clientX + scrollOffsetX - mouseStart.x,
				y: e.clientY + scrollOffsetY - mouseStart.y,
			}
			const newSize: CardSizeInPixels = {
				hPx: context.initalSize.height + difference.y,
				wPx: context.initalSize.width + difference.x,
			}

			const initialX = context.startCoords.x - context.gridOffset.x + scrollOffsetX
			const initialY = context.startCoords.y - context.gridOffset.y + scrollOffsetY

			// const newRect: CardRectInPx = { xPx: initialX, yPx: initialY, hPx: newSize.hPx, wPx: newSize.wPx }

			const newLayout = { ...cardLayoutToCells('resize', initialX, initialY, newSize, gridCellWidthRef.current, true) }

			// context.drawRect(newRect, 1)

			setPlaceholder({
				layout: newLayout,
			})

			if (phase == 'finished' || phase == 'interrupted') {
				setPlaceholder(undefined)
			}

			if (phase === 'start') {
				gestureBegan(newLayout)
			}

			simulateTheLayoutChange(context.cardId, newLayout)
			if (phase === 'finished') {
				gestureFinished(context, 'card-resize', {
					id: context.cardId,
					layout: newLayout,
				})
			}
		}

		const removeListener = DragDropCore.addListener(dragZoneId, listener)
		const unmount = DragDropCore.mountContainer(dragZoneId, 1, container)
		return () => {
			removeListener()
			unmount()
		}
	}, [
		gridRef,
		m,
		gestureFinished,
		gridCellWidthRef,
		dispatch,
		resetMove,
		// cardById,
		dragZoneId,
		container,
		simulateMove,
		affectedCards,
		gestureBegan,
		// validateGesture,
		simulateTheLayoutChange,
	])

	return useMemo(() => ({ placeholder }), [placeholder])
}
