import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import zxcvbn from 'zxcvbn';

const ReffedPasswordStrengthBar: React.ForwardRefRenderFunction<
	{
		setPassword: (pass: string) => void
	},
	any
> = ({}, ref) => {
	const [strength, setStrength] = useState(0)
	const lastPass = useRef<any>()

	React.useImperativeHandle(
		ref,
		() =>
			({
				setPassword(pass: string) {
					if (lastPass.current != pass) {
						lastPass.current = pass
						setStrength(zxcvbn(pass).score)
					}
				},
			} as any),
		[]
	)

	return (
		<Bar visible={strength > 0} ref={ref as any}>
			<Fill
				style={{
					width: `${strength * 25}%`,
					backgroundColor: strengthColor(strength),
				}}
			/>
		</Bar>
	)
}

const Bar = styled.div<{ visible?: boolean }>`
	width: 40px;
	height: 3px;
	border-radius: 999px;
	${(p) => p.theme.backgrounds.subtle.r.css('background-color')}
	overflow: hidden;
	opacity: 0;
	transition: 0.2s ease opacity;
	${(p) =>
		p.visible &&
		css`
			opacity: 1;
		`}
`

const Fill = styled.div`
	height: 100%;
	transition: 0.2s ease all;
`

export const PasswordStrengthBar = React.forwardRef(ReffedPasswordStrengthBar)

const strengthColor = (strength: number) => {
	switch (strength) {
		case 0:
			return '#ddd' // If no password entered
		case 1:
			return '#f00' // Weak
		case 2:
			return '#f90' // Okay
		case 3:
			return '#ff0' // Good
		case 4:
			return '#0f0' // Strong
		default:
			return '#ddd'
	}
}

export default PasswordStrengthBar
