/**
 * @fileoverview gRPC-Web generated client stub for user_invites_v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.1
// source: services/users_v1.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var entities_invitees_v1_pb = require('../entities/invitees_v1_pb.js')

var entities_user_profiles_v1_pb = require('../entities/user_profiles_v1_pb.js')
const proto = {};
proto.user_invites_v1 = require('./users_v1_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.user_invites_v1.UsersServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.user_invites_v1.UsersServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.RequestPasswordChangeRequest,
 *   !proto.user_invites_v1.RequestPasswordChangeResponse>}
 */
const methodDescriptor_UsersService_RequestPasswordChange = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/RequestPasswordChange',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.RequestPasswordChangeRequest,
  proto.user_invites_v1.RequestPasswordChangeResponse,
  /**
   * @param {!proto.user_invites_v1.RequestPasswordChangeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.RequestPasswordChangeResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.RequestPasswordChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.RequestPasswordChangeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.RequestPasswordChangeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.requestPasswordChange =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/RequestPasswordChange',
      request,
      metadata || {},
      methodDescriptor_UsersService_RequestPasswordChange,
      callback);
};


/**
 * @param {!proto.user_invites_v1.RequestPasswordChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.RequestPasswordChangeResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.requestPasswordChange =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/RequestPasswordChange',
      request,
      metadata || {},
      methodDescriptor_UsersService_RequestPasswordChange);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.SendVerificationEmailRequest,
 *   !proto.user_invites_v1.SendVerificationEmailResponse>}
 */
const methodDescriptor_UsersService_SendVerificationEmail = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/SendVerificationEmail',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.SendVerificationEmailRequest,
  proto.user_invites_v1.SendVerificationEmailResponse,
  /**
   * @param {!proto.user_invites_v1.SendVerificationEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.SendVerificationEmailResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.SendVerificationEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.SendVerificationEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.SendVerificationEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.sendVerificationEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/SendVerificationEmail',
      request,
      metadata || {},
      methodDescriptor_UsersService_SendVerificationEmail,
      callback);
};


/**
 * @param {!proto.user_invites_v1.SendVerificationEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.SendVerificationEmailResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.sendVerificationEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/SendVerificationEmail',
      request,
      metadata || {},
      methodDescriptor_UsersService_SendVerificationEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.ListInviteesRequest,
 *   !proto.user_invites_v1.ListInviteesResponse>}
 */
const methodDescriptor_UsersService_ListInvitees = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/ListInvitees',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.ListInviteesRequest,
  proto.user_invites_v1.ListInviteesResponse,
  /**
   * @param {!proto.user_invites_v1.ListInviteesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.ListInviteesResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.ListInviteesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.ListInviteesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.ListInviteesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.listInvitees =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/ListInvitees',
      request,
      metadata || {},
      methodDescriptor_UsersService_ListInvitees,
      callback);
};


/**
 * @param {!proto.user_invites_v1.ListInviteesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.ListInviteesResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.listInvitees =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/ListInvitees',
      request,
      metadata || {},
      methodDescriptor_UsersService_ListInvitees);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.RevokeInviteeRequest,
 *   !proto.user_invites_v1.RevokeInviteeResponse>}
 */
const methodDescriptor_UsersService_RevokeInvitee = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/RevokeInvitee',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.RevokeInviteeRequest,
  proto.user_invites_v1.RevokeInviteeResponse,
  /**
   * @param {!proto.user_invites_v1.RevokeInviteeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.RevokeInviteeResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.RevokeInviteeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.RevokeInviteeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.RevokeInviteeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.revokeInvitee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/RevokeInvitee',
      request,
      metadata || {},
      methodDescriptor_UsersService_RevokeInvitee,
      callback);
};


/**
 * @param {!proto.user_invites_v1.RevokeInviteeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.RevokeInviteeResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.revokeInvitee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/RevokeInvitee',
      request,
      metadata || {},
      methodDescriptor_UsersService_RevokeInvitee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.EnrollInBetaRequest,
 *   !proto.user_invites_v1.EnrollInBetaResponse>}
 */
const methodDescriptor_UsersService_EnrollInBeta = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/EnrollInBeta',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.EnrollInBetaRequest,
  proto.user_invites_v1.EnrollInBetaResponse,
  /**
   * @param {!proto.user_invites_v1.EnrollInBetaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.EnrollInBetaResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.EnrollInBetaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.EnrollInBetaResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.EnrollInBetaResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.enrollInBeta =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/EnrollInBeta',
      request,
      metadata || {},
      methodDescriptor_UsersService_EnrollInBeta,
      callback);
};


/**
 * @param {!proto.user_invites_v1.EnrollInBetaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.EnrollInBetaResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.enrollInBeta =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/EnrollInBeta',
      request,
      metadata || {},
      methodDescriptor_UsersService_EnrollInBeta);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.CheckBeenInvitedRequest,
 *   !proto.user_invites_v1.CheckBeenInvitedResponse>}
 */
const methodDescriptor_UsersService_CheckBeenInvited = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/CheckBeenInvited',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.CheckBeenInvitedRequest,
  proto.user_invites_v1.CheckBeenInvitedResponse,
  /**
   * @param {!proto.user_invites_v1.CheckBeenInvitedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.CheckBeenInvitedResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.CheckBeenInvitedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.CheckBeenInvitedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.CheckBeenInvitedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.checkBeenInvited =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/CheckBeenInvited',
      request,
      metadata || {},
      methodDescriptor_UsersService_CheckBeenInvited,
      callback);
};


/**
 * @param {!proto.user_invites_v1.CheckBeenInvitedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.CheckBeenInvitedResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.checkBeenInvited =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/CheckBeenInvited',
      request,
      metadata || {},
      methodDescriptor_UsersService_CheckBeenInvited);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.RequestAccountDeletionRequest,
 *   !proto.user_invites_v1.RequestAccountDeletionResponse>}
 */
const methodDescriptor_UsersService_RequestAccountDeletion = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/RequestAccountDeletion',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.RequestAccountDeletionRequest,
  proto.user_invites_v1.RequestAccountDeletionResponse,
  /**
   * @param {!proto.user_invites_v1.RequestAccountDeletionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.RequestAccountDeletionResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.RequestAccountDeletionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.RequestAccountDeletionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.RequestAccountDeletionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.requestAccountDeletion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/RequestAccountDeletion',
      request,
      metadata || {},
      methodDescriptor_UsersService_RequestAccountDeletion,
      callback);
};


/**
 * @param {!proto.user_invites_v1.RequestAccountDeletionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.RequestAccountDeletionResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.requestAccountDeletion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/RequestAccountDeletion',
      request,
      metadata || {},
      methodDescriptor_UsersService_RequestAccountDeletion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.AcceptInvitationRequest,
 *   !proto.user_invites_v1.AcceptInvitationResponse>}
 */
const methodDescriptor_UsersService_AcceptInvitation = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/AcceptInvitation',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.AcceptInvitationRequest,
  proto.user_invites_v1.AcceptInvitationResponse,
  /**
   * @param {!proto.user_invites_v1.AcceptInvitationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.AcceptInvitationResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.AcceptInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.AcceptInvitationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.AcceptInvitationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.acceptInvitation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/AcceptInvitation',
      request,
      metadata || {},
      methodDescriptor_UsersService_AcceptInvitation,
      callback);
};


/**
 * @param {!proto.user_invites_v1.AcceptInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.AcceptInvitationResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.acceptInvitation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/AcceptInvitation',
      request,
      metadata || {},
      methodDescriptor_UsersService_AcceptInvitation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.InviteUserRequest,
 *   !proto.user_invites_v1.InviteUserResponse>}
 */
const methodDescriptor_UsersService_InviteUser = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/InviteUser',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.InviteUserRequest,
  proto.user_invites_v1.InviteUserResponse,
  /**
   * @param {!proto.user_invites_v1.InviteUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.InviteUserResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.InviteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.InviteUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.InviteUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.inviteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/InviteUser',
      request,
      metadata || {},
      methodDescriptor_UsersService_InviteUser,
      callback);
};


/**
 * @param {!proto.user_invites_v1.InviteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.InviteUserResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.inviteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/InviteUser',
      request,
      metadata || {},
      methodDescriptor_UsersService_InviteUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.FetchProfilesRequest,
 *   !proto.user_invites_v1.FetchProfilesResponse>}
 */
const methodDescriptor_UsersService_FetchProfiles = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/FetchProfiles',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.FetchProfilesRequest,
  proto.user_invites_v1.FetchProfilesResponse,
  /**
   * @param {!proto.user_invites_v1.FetchProfilesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.FetchProfilesResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.FetchProfilesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.FetchProfilesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.FetchProfilesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.fetchProfiles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/FetchProfiles',
      request,
      metadata || {},
      methodDescriptor_UsersService_FetchProfiles,
      callback);
};


/**
 * @param {!proto.user_invites_v1.FetchProfilesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.FetchProfilesResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.fetchProfiles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/FetchProfiles',
      request,
      metadata || {},
      methodDescriptor_UsersService_FetchProfiles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.UpdateProfileRequest,
 *   !proto.user_invites_v1.UpdateProfileResponse>}
 */
const methodDescriptor_UsersService_UpdateProfile = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/UpdateProfile',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.UpdateProfileRequest,
  proto.user_invites_v1.UpdateProfileResponse,
  /**
   * @param {!proto.user_invites_v1.UpdateProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.UpdateProfileResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.UpdateProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.UpdateProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.UpdateProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.updateProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/UpdateProfile',
      request,
      metadata || {},
      methodDescriptor_UsersService_UpdateProfile,
      callback);
};


/**
 * @param {!proto.user_invites_v1.UpdateProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.UpdateProfileResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.updateProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/UpdateProfile',
      request,
      metadata || {},
      methodDescriptor_UsersService_UpdateProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.LinkToSubscriptionRequest,
 *   !proto.user_invites_v1.LinkToSubscriptionResponse>}
 */
const methodDescriptor_UsersService_LinkToSubscription = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/LinkToSubscription',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.LinkToSubscriptionRequest,
  proto.user_invites_v1.LinkToSubscriptionResponse,
  /**
   * @param {!proto.user_invites_v1.LinkToSubscriptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.LinkToSubscriptionResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.LinkToSubscriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.LinkToSubscriptionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.LinkToSubscriptionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.linkToSubscription =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/LinkToSubscription',
      request,
      metadata || {},
      methodDescriptor_UsersService_LinkToSubscription,
      callback);
};


/**
 * @param {!proto.user_invites_v1.LinkToSubscriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.LinkToSubscriptionResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.linkToSubscription =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/LinkToSubscription',
      request,
      metadata || {},
      methodDescriptor_UsersService_LinkToSubscription);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user_invites_v1.HasSyncedBeforeRequest,
 *   !proto.user_invites_v1.HasSyncedBeforeResponse>}
 */
const methodDescriptor_UsersService_HasSyncedBefore = new grpc.web.MethodDescriptor(
  '/user_invites_v1.UsersService/HasSyncedBefore',
  grpc.web.MethodType.UNARY,
  proto.user_invites_v1.HasSyncedBeforeRequest,
  proto.user_invites_v1.HasSyncedBeforeResponse,
  /**
   * @param {!proto.user_invites_v1.HasSyncedBeforeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user_invites_v1.HasSyncedBeforeResponse.deserializeBinary
);


/**
 * @param {!proto.user_invites_v1.HasSyncedBeforeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user_invites_v1.HasSyncedBeforeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user_invites_v1.HasSyncedBeforeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user_invites_v1.UsersServiceClient.prototype.hasSyncedBefore =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user_invites_v1.UsersService/HasSyncedBefore',
      request,
      metadata || {},
      methodDescriptor_UsersService_HasSyncedBefore,
      callback);
};


/**
 * @param {!proto.user_invites_v1.HasSyncedBeforeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user_invites_v1.HasSyncedBeforeResponse>}
 *     Promise that resolves to the response
 */
proto.user_invites_v1.UsersServicePromiseClient.prototype.hasSyncedBefore =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user_invites_v1.UsersService/HasSyncedBefore',
      request,
      metadata || {},
      methodDescriptor_UsersService_HasSyncedBefore);
};


module.exports = proto.user_invites_v1;

