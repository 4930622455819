import { useDialog } from '@comps/dialog'
import { FieldsList } from '@comps/forms/fieldsList'
import { Label } from '@comps/forms/label'
import { TextInput } from '@comps/forms/textInput'
import { Button } from '@comps/interactive/button'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { Spacer } from '@comps/layout/space'
import { Icon } from '@comps/static/icon'
import { useCachedSelector } from '@root/hooks/useCachedSelector'
import { useRefTaker } from '@root/hooks/useRefTaker'
import { RootState } from '@root/store'
import { ActivityColors } from '@root/store/slices/activities.colors.types'
import { boardsActions } from '@root/store/slices/boards'
import { flowsActions, flowsSpecialActionsPushView } from '@root/store/slices/flows'
import { NewID } from '@root/utils/general'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { useFlowExit } from './useFlowExit'

export const FlowsBoardOverviewHeaderAction: React.FC = () => {
	const context = useCachedSelector((state: RootState) => state.flows.flowContexts['create-or-edit-board'])
	const isUpdating = context?.id
	const { showDialog } = useDialog()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleDelete = useCallback(
		(id: string) => {
			dispatch(boardsActions.delete({ id }))
			navigate(-2)
			dispatch(flowsActions.exitFlows())
		},
		[dispatch, navigate]
	)

	const handleClick = useCallback(() => {
		if (!isUpdating) return

		const title = 'Confirm Board Deletion'
		const message = `Are you sure you want to delete ${context?.name || 'Unnamed Board'}?`

		showDialog(title, message, () => {
			handleDelete(isUpdating)
		})
	}, [showDialog, handleDelete, context, isUpdating])

	if (isUpdating) {
		return <RightSide>
			<Button
				key={'edit-link'}
				color="textualClepside"
				inset="buttonMedium"
				onClick={handleClick}
			>
				<Icon type='bin' />
			</Button>
		</RightSide>
	}
	return <></>
}
const RightSide = styled.div`
	position: absolute;
	top: 10px;
	right: 20px;
`
export const FlowsBoardOverview: React.FC = () => {
	const [input, setInput] = useRefTaker()
	const context = useCachedSelector((state: RootState) => state.flows.flowContexts['create-or-edit-board'])
	const isUpdating = context?.id
	const dispatch = useDispatch()
	const exit = useFlowExit()

	useEffect(() => {
		input?.focus()
	}, [input])

	const handleSubmit = useCallback(() => {
		if (!context) return

		if (context.id) {
			dispatch(
				boardsActions.update({
					id: context.id,
					name: context.name,
					color: context.color,
				})
			)
			exit()
		} else {
			const newBoardId = NewID()
			dispatch(
				boardsActions.new({
					id: newBoardId,
					name: context.name,
					color: context.color,
					wallpaper: 'currentColor',
				})
			)
			dispatch(flowsActions.cancelFlow({ id: 'create-or-edit-board' }))
		}
	}, [dispatch, context, exit])

	const handleCancel = useCallback(() => {
		dispatch(flowsActions.cancelFlow({ id: 'create-or-edit-board' }))
	}, [dispatch])

	if (!context) return <span>No context available. Inform Support</span>

	return (
		<React.Fragment>
			<Inset padding="tiny" align="stretch" direction="column">
				<FieldsList>
					<Label>Name</Label>
					<TextInput
						tabIndex={10}
						inputRef={setInput}
						grow
						onValueChange={(value) => {
							dispatch(
								flowsActions.updateFlow({
									flowType: 'create-or-edit-board',
									name: value,
								})
							)
						}}
						inputStyle="simple"
						defaultValue={context.name}
						placeholder="Enter a name"
						padding="medium"
						autoFocus
						parentHasNegativeMargins
					/>
					<Label>Color</Label>
					<Button
						color="textualAccent"
						onClick={() => {
							dispatch(flowsSpecialActionsPushView('create-or-edit-board', 'select-color'))
						}}
					>
						<Inset padding="medium">
							<Flex align="center">
								{context.color
									? ActivityColors[context.color]?.name || 'Select a color'
									: 'Select a color'}
							</Flex>
						</Inset>
					</Button>
				</FieldsList>
			</Inset>
			<Spacer vertical size={10} grow />
			<FooterGrid>
				<Button color="subtle" grow tabIndex={40} onClick={handleCancel}>
					<Inset padding="buttonMedium" align="center" justify="center">
						Cancel
					</Inset>
				</Button>
				<Button color="accent" grow tabIndex={40} cy={isUpdating ? 'update' : 'create'} onClick={handleSubmit}>
					<Inset padding="buttonMedium" align="center" justify="center">
						{isUpdating ? 'Update' : 'Create'}
					</Inset>
				</Button>
			</FooterGrid>
		</React.Fragment>
	)
}

const FooterGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
`
