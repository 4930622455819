import { UsersServiceClient } from '@clepside/clepsidejs/lib/services/users_v1_grpc_web_pb';
import {
    AcceptInvitationRequest, AcceptInvitationResponse, CheckBeenInvitedRequest,
    CheckBeenInvitedResponse, FetchProfilesRequest, FetchProfilesResponse, InviteUserRequest,
    InviteUserResponse, ListInviteesRequest, ListInviteesResponse, RequestAccountDeletionRequest,
    RequestAccountDeletionResponse, RequestPasswordChangeRequest, RequestPasswordChangeResponse,
    RevokeInviteeRequest, RevokeInviteeResponse, SendVerificationEmailRequest,
    SendVerificationEmailResponse, UpdateProfileRequest, UpdateProfileResponse
} from '@clepside/clepsidejs/lib/services/users_v1_pb';

import { getGRPCMeta } from './utils';

if (!process.env.REACT_APP_API_URL)
    throw new Error('No RAPURL')
const UsersClient = new UsersServiceClient(process.env.REACT_APP_API_URL)


export const UsersGRPC = {
    requestPasswordChange: (request: RequestPasswordChangeRequest) => new Promise<RequestPasswordChangeResponse>((res, rej) => {
            (async () => {
                UsersClient.requestPasswordChange(request, undefined, (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    updateProfile: (request: UpdateProfileRequest) => new Promise<UpdateProfileResponse>((res, rej) => {
            (async () => {
                UsersClient.updateProfile(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    requestAccountDeletion: (request: RequestAccountDeletionRequest) => new Promise<RequestAccountDeletionResponse>((res, rej) => {
            (async () => {
                UsersClient.requestAccountDeletion(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    fetchProfiles: (request: FetchProfilesRequest) => new Promise<FetchProfilesResponse>((res, rej) => {
            (async () => {
                UsersClient.fetchProfiles(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    sendVerificationEmail: (request: SendVerificationEmailRequest) => new Promise<SendVerificationEmailResponse>((res, rej) => {
            (async () => {
                UsersClient.sendVerificationEmail(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    fetchPastInvites: (request: ListInviteesRequest) => new Promise<ListInviteesResponse>((res, rej) => {
            (async () => {
                UsersClient.listInvitees(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    inviteUser: (request: InviteUserRequest) => new Promise<InviteUserResponse>((res, rej) => {
            (async () => {
                UsersClient.inviteUser(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    revokeInvitee: (request: RevokeInviteeRequest) => new Promise<RevokeInviteeResponse>((res, rej) => {
            (async () => {
                UsersClient.revokeInvitee(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    acceptInvitation: (request: AcceptInvitationRequest) => new Promise<AcceptInvitationResponse>((res, rej) => {
            (async () => {
                UsersClient.acceptInvitation(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    checkBeenInvited: (request: CheckBeenInvitedRequest) => new Promise<CheckBeenInvitedResponse>((res, rej) => {
            (async () => {
                UsersClient.checkBeenInvited(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    )
}