import { CalendarMonth } from '@comps/complex/calendarMonth';
import { Inset } from '@comps/layout/inset';
import { KeyScope } from '@comps/logical/keyScope';
import { useCachedSelector } from '@root/hooks/useCachedSelector';
import { RootState } from '@root/store';
import { flowsActions } from '@root/store/slices/flows';
import { getUnixTime } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';

export const FlowsSelectDate: React.FC = () => {
	const [date, setDate] = useState(new Date())
	const dispatch = useDispatch()
	const context = useCachedSelector((state: RootState) => state.flows.flowContexts['select-date'])
	const schedule = useSelector((state: RootState) => state.flows.flowContexts['routine-schedule-editing'])
	const handleDateChange = useCallback((d: Date) => {
		setDate(d)
	}, [])

	const handleDateSelect = useCallback(
		(d: Date) => {
			// onDateChange(d)
			// pushView?.('Plan.Details')
			// dispatch(
			//     flowsActions.startFlow({
			//         flowType: 'select-date',

			//         email: values.email,
			//     })
			// )
			if (context?.ancestor == 'routine-schedule-editing') {
				dispatch(
					flowsActions.updateFlow({
						flowType: 'routine-schedule-editing',
						schedule: {
							...(schedule?.schedule ? schedule.schedule : {} as any),
							startingOn: getUnixTime(d) * 1000
						}
					})
				)
			}
			dispatch(flowsActions.cancelFlow({ id: 'select-date' }))
		},
		[dispatch, schedule, context]
	)

	return (
		<React.Fragment>
			<KeyScope scope="Plan.SelectDate" />
			<Inset>
				<Styled>
					<CalendarMonth
						year='2024'
						monthIndex={3}
						onClick={handleDateSelect}
					/>
					{/* <ReactDatePicker
                        locale="en-gb"
                        customInput={input}
                        selected={date}
                        dateFormat="dd MMMM, yyyy"
                        open={true}
                        placeholderText={'Enter a date'}
                        onSelect={handleDateSelect}
                        onChange={handleDateChange}
                    /> */}
				</Styled>
			</Inset>
		</React.Fragment>
	)
}

const blink = keyframes`
    10% {
        opacity: 1;
    }
    55% {
        opacity: 0.3;
    }
`

const prefix = '.react-datepicker_'
const Styled = styled.div`
	.react-datepicker {
		position: relative;
	}

	.react-datepicker-popper {
		position: relative !important;
		transform: none !important;
	}

	${prefix}_day-names, ${prefix}_week {
		display: flex;
	}

	${prefix}_input-container {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: stretch;
	}

	${prefix}_day-name,${prefix}_day {
		width: ${100 / 7}%;
		text-align: center;
		color: #fff;
		text-align: center;
		box-sizing: border-box;
	}
	${prefix}_day {
		padding: 6px 0;
	}

	${prefix}_day--weekend {
		opacity: 0.5;
	}
	${prefix}_day-name {
		text-transform: uppercase;
		font-size: 9px;
		opacity: 0.5;
		margin-bottom: 10px;
	}
	${prefix}_navigation {
		background-image: url("data:image/svg+xml,%3Csvg width='6' height='12' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 1L1.5 7L7.5 13' strokeWidth='2' stroke='white'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		border: none;
		top: -2px;
		text-indent: -99999px;
		background-color: transparent;
		overflow: hidden;
		height: 20px;
		width: 40px;
		left: -10px;
		position: absolute;
		background-position: center center;
		&:active {
			opacity: 0.5;
		}
	}
	${prefix}_navigation--next {
		transform: rotateZ(180deg);
		position: absolute;
		top: -2px;
		right: -10px;
		left: auto;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}
	${prefix}_current-month {
		color: #fff;
		text-align: center;
		margin-bottom: 20px;
	}
	${prefix}_day--today {
		border: 1px solid rgba(255, 255, 255, 0.2);
		border-radius: 8px;
	}

	${prefix}_day--outside-month {
		opacity: 0.15;
	}

	${prefix}_day--selected {
		animation: ${blink} 1.2s linear infinite;
		border-radius: 8px;
		opacity: 1;
		${(p) => p.theme.backgrounds.subtle.t2.css('background-color')};
	}
`
