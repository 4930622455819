import { PreNuance } from '@root/styled'
import { css } from 'styled-components'

const canIUseP3 = (function supportsP3Colors() {
	const p3Test = 'color(display-p3 1 1 1 / 1)'
	const s = new Option().style
	s.color = p3Test
	return s.color == p3Test
})()

type P3Color = {
	r: number
	g: number
	b: number
	t: number
}

export interface NuanceConfig {
	color: string
	p3: string
	hex: string
	css: (property: CSSNuanceAttribute, prefix?: string, suffix?: string) => any
}

const wrapColor = (p3String: string): NuanceConfig => {
	return {
		color: canIUseP3 ? p3String : P3ToRGBA(p3String),
		p3: p3String,
		hex: P3ToRGBA(p3String),
		css: (property, prefix, suffix) => p3CSS(property, p3String, prefix, suffix),
	}
}

export const buildColorFromPreNuance = (nuance: PreNuance) => {
	if (nuance.alphaIntensity) {
		const translucentIntensity = nuance.alphaIntensity
		return {
			s3: wrapColor(setAlpha(nuance.color, translucentIntensity * 3)),
			s2: wrapColor(setAlpha(nuance.color, translucentIntensity * 2)),
			s1: wrapColor(setAlpha(nuance.color, translucentIntensity)),
			r: wrapColor(nuance.color),
			t1: wrapColor(setAlpha(nuance.color, translucentIntensity)),
			t2: wrapColor(setAlpha(nuance.color, translucentIntensity * 2)),
			t3: wrapColor(setAlpha(nuance.color, translucentIntensity * 3)),
		}
	} else {
		const intensity = nuance.intensity || 0.25
		return {
			s3: wrapColor(darkenOrLighten(nuance.color, intensity * 3)),
			s2: wrapColor(darkenOrLighten(nuance.color, intensity * 2)),
			s1: wrapColor(darkenOrLighten(nuance.color, intensity)),
			r: wrapColor(nuance.color),
			t1: wrapColor(darkenOrLighten(nuance.color, intensity, true)),
			t2: wrapColor(darkenOrLighten(nuance.color, intensity * 2, true)),
			t3: wrapColor(darkenOrLighten(nuance.color, intensity * 3, true)),
		}
	}
}

const parseP3Color = (color: string): P3Color => {
	if (!color)
		return {
			r: 0,
			g: 0,
			b: 0,
			t: 1,
		}
	const between = color.split(')')[0].split('display-p3 ')[1]
	const c: P3Color = {
		r: 0,
		g: 0,
		b: 0,
		t: 1,
	}
	if (between?.split('/')[1]) {
		c.t = parseFloat(between.split('/')[1].trim())
	}

	const restOfColors = between?.split('/')[0].trim().split(' ')
	if (!restOfColors?.[0]) throw new Error(`Bad vibes ${color}`)
	c.r = parseFloat(restOfColors[0])
	c.g = parseFloat(restOfColors[1])
	c.b = parseFloat(restOfColors[2])

	return c
}

const array: Array<keyof P3Color> = ['r', 'g', 'b']

const setAlpha = (colorString: string, intensity: number) => {
	const color = parseP3Color(colorString)
	const ratios = array.map((p) => color[p]) as [number, number, number]
	return buildP3(ratios, color.t + intensity)
}

const darkenOrLighten = (colorString: string, intensity: number, lighten?: boolean) => {
	const color: any = parseP3Color(colorString)

	// Check if the color is neutral
	const isNeutral = Math.abs(color.r - color.g) < 0.05 && Math.abs(color.g - color.b) < 0.05

	let ratios: any
	if (isNeutral) {
		// Uniformly adjust all components for neutral colors
		const offset = lighten ? intensity : -intensity
		ratios = {
			r: color.r + offset,
			g: color.g + offset,
			b: color.b + offset,
		}
	} else {
		// Find the maximum channel value and the other two components
		let maxChannel: any, other1: any, other2: any
		if (color.r > color.g && color.r > color.b) {
			maxChannel = 'r'
			other1 = 'g'
			other2 = 'b'
		} else if (color.g > color.r && color.g > color.b) {
			maxChannel = 'g'
			other1 = 'r'
			other2 = 'b'
		} else {
			maxChannel = 'b'
			other1 = 'r'
			other2 = 'g'
		}

		// Calculate the difference multiplier based on the dominance of the hue
		const avgOther = (color[other1] + color[other2]) / 2
		const differenceMultiplier = (color[maxChannel] - avgOther) / color[maxChannel]

		// Adjust each component based on whether we're darkening or lightening
		const offset = lighten ? intensity : -intensity
		ratios = {
			r: color.r + (maxChannel === 'r' ? offset * differenceMultiplier : offset),
			g: color.g + (maxChannel === 'g' ? offset * differenceMultiplier : offset),
			b: color.b + (maxChannel === 'b' ? offset * differenceMultiplier : offset),
		}
	}

	// Ensure that the ratios do not go negative or beyond the range [0, 1]
	for (const channel in ratios) {
		ratios[channel] = Math.min(Math.max(ratios[channel], 0), 1)
	}

	return buildP3([ratios.r, ratios.g, ratios.b], color.t)
}

const buildP3 = (arr: [number, number, number], trans: number) => {
	return `color(display-p3 ${arr[0].toFixed(3)} ${arr[1].toFixed(3)} ${arr[2].toFixed(3)} / ${trans.toFixed(3)})`
}

const P3ToRGBA = (colorString: string) => {
	const p3Color = parseP3Color(colorString)
	const round = (c: number) => Math.round(c * 255)

	return `rgba(${round(p3Color.r)}, ${round(p3Color.g)}, ${round(p3Color.b)}, ${p3Color.t})`
}

export type CSSNuanceAttribute =
	| 'background-color'
	| 'box-shadow'
	| 'border-top'
	| 'border-left'
	| 'border-color'
	| 'border'
	| 'border-right'
	| 'border-bottom'
	| 'color'
	| 'box-shadow-color'
	| 'border-bottom-color'
	| 'stroke'
	| 'fill'

export const p3CSS = (attr: CSSNuanceAttribute, colorString: string, prefix?: string, suffix?: string) => {
	return css`
		${attr}: ${prefix} ${P3ToRGBA(colorString)} ${suffix};
		${attr}: ${prefix} ${colorString} ${suffix};
	`
}
