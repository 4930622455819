import { ActivityHeader } from '@comps/complex/boards/activityHeader';
import { SessionHeader } from '@comps/complex/boards/sessionHeader';
import { BackButton } from '@comps/complex/button/backButton';
import { Button } from '@comps/interactive/button';
import { CompletionTimer } from '@comps/interactive/completionTimer';
import { NavigationStackLink } from '@comps/interactive/navigationStackLink';
import { SessionStatus } from '@comps/interactive/sessionStatus';
import { TimeLeftTimer } from '@comps/interactive/timeLeftTimer';
import { ActivityColoredText } from '@comps/layout/activityColoredText';
import { DiagonalArrowCircle } from '@comps/layout/diagonalArrowCircle';
import { Flex } from '@comps/layout/flex';
import { InfoLabel } from '@comps/layout/infoLabel';
import { Inset } from '@comps/layout/inset';
import { Spacer } from '@comps/layout/space';
import { Spread } from '@comps/layout/spread';
import { ActivityBubble } from '@comps/static/activityBubble';
import { Icon } from '@comps/static/icon';
import { Text } from '@comps/typography/text';
import { DARK_TRANSLUCENCY, narrowBottomBorder } from '@root/brand/css';
import { useSessionSwitchActiveResource } from '@root/hooks/useSessionSwitchActiveResource';
import { ActivityColorKey, ActivityColors } from '@root/store/slices/activities.colors.types';
import { ActivityData } from '@root/store/slices/activitiesTypes';
import { InstancedSessionData } from '@root/store/slices/sessionsTypes';
import { getSessionDuration } from '@root/utils/dates';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { TopBarSwitch } from './Sessions.ID.TopBar.Switch';

export const TopBar: React.FC<{ activity: ActivityData; session: InstancedSessionData }> = ({ activity, session }) => {
	const { isSession, isActivity } = useSessionSwitchActiveResource()
	const state = useMemo(() => {
		const now = new Date()
		if (now > session.end) {
			return 'past'
		}
		if (now < session.start) {
			return 'future'
		}
		return 'now'
	}, [session])

	return (
		<Holder>
			<TopRow>
				<Flex align="center">
					<BackButtonHolder className='noseldis'>
						<BackButton defaultBackTo="/calendar" onlyIcon color="darkTranslucentElevated" />
					</BackButtonHolder>
					<Spread items={3} spacing={35}>
						<Flex align="center">
							<Spacer size={20} />
							<ActivityBubble activity={activity} size="small" nuanceAttr="s3" skeu />
							<Spacer size={15} />
							<Text level="largerParagraphTitle" color="darkTranslucency">
								{activity.name}
							</Text>
						</Flex>
						{isSession ? (
							<InfoLabel label="Timeframe	">
								<Flex>
									{format(session.start, 'dd MMMM')}&nbsp;&nbsp;&middot;&nbsp;&nbsp;
									{format(session.start, 'HH:mm')}&nbsp;&rarr;&nbsp;
									{format(session.end, 'HH:mm')}
								</Flex>
							</InfoLabel>
						) : null}
					</Spread>
				</Flex>
				<Flex grow justify="flex-end" align="center">
					{isSession ? (
						<>
							{state == 'now' ? (
								<Spread items={4} spacing={35}>
									<InfoLabel label="Duration" rightAlign>
										<Text level="label">{getSessionDuration(session)}</Text>
									</InfoLabel>
									<InfoLabel label="Time Left" rightAlign>
										<Text level="label">
											<TimeLeftTimer
												since={session.start}
												til={session.end}
												after="Session Ended"
												skipSeconds
											/>
										</Text>
									</InfoLabel>
									<InfoLabel label="Progress" rightAlign>
										<Text level="label">
											<CompletionTimer from={session.start} til={session.end} />
										</Text>
									</InfoLabel>
									<Button color="darkTranslucentElevated">
										<ActivityColoredText color={activity.color}>
											<Inset padding="buttonSmall" align="center" spacing={8}>
												<Icon type="stop" />
												<Text block>Stop</Text>
											</Inset>
										</ActivityColoredText>
									</Button>
								</Spread>
							) : (
								<Spread items={2} spacing={35}>
									<InfoLabel label="Duration" rightAlign>
										<Text level="label">{getSessionDuration(session)}</Text>
									</InfoLabel>
									<InfoLabel label="Status" rightAlign>
										<Text level="label">
											<SessionStatus
												start={session.start.getTime()}
												end={session.end.getTime()}
											/>
										</Text>
									</InfoLabel>
								</Spread>
							)}
						</>
					) : null}
					{isActivity && (
						<NavigationStackLink to={`/activities/${activity.id}/cards`}>
							<Button color="darkTranslucentElevated">
								<OpenActivityButton colorId={activity.color}>
									<Inset padding="buttonSmall" align="center" spacing={8}>
										<Text block level="label">
											Open Activity
										</Text>
										{/* <Icon type="diagonalArrow" offsetY={1} /> */}
										<DiagonalArrowCircle />
									</Inset>
								</OpenActivityButton>
							</Button>
						</NavigationStackLink>
					)}
				</Flex>
				<TopBarSwitch activity={activity} session={session} center />
			</TopRow>
			<BottomRow isSession={isSession}>
				{isSession ? <SessionHeader session={session} /> : <ActivityHeader activityId={activity.id} />}
			</BottomRow>
		</Holder>
	)
}

export const OpenActivityButton = styled.span<{ colorId?: ActivityColorKey }>`
	${(p) =>
		p.colorId &&
		css`
			${ActivityColors[p.colorId].inactiveLight.background.s2.css('color')};
			.icon-button {
				${ActivityColors[p.colorId].inactiveLight.background.s2.css('background-color')};
				${ActivityColors[p.colorId].inactiveLight.text.r.css('color')};
			}
		`}
`

const BottomRow = styled.div<{ isSession: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 15px;
	align-self: stretch;

	${(p) =>
		p.isSession
			? css`
					flex-direction: row;
					justify-content: center;
			  `
			: css`
					flex-direction: column;
					align-items: stretch;
			  `}
`

const TopRow = styled.div`
	display: flex;
	position: relative;
	padding: 8px 15px;
	${narrowBottomBorder(15)};
`

const Holder = styled.div`
	width: 100%;
	padding: 0 10px;
	/* position: absolute; */
	top: 0;
	z-index: 1000000000;
	position: sticky;
	flex-shrink: 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	left: 0;
	border: 1px black;
	will-change: transform;
	overflow: hidden;
	display: flex;
	backface-visibility: hidden;
	transform-style: flat;
	${DARK_TRANSLUCENCY};

	&:after {
		position: absolute;
		content: '';
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;

		background-image: linear-gradient(to bottom, transparent 0%, transparent 51%, rgba(0, 0, 0, 0.7) 51%);
	}
	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 60%);
		opacity: 0.2;
	}
	box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.075);
`

const BackButtonHolder = styled.div`
	margin-left: -15px;
	margin-right: -20px;
`
