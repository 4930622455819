import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { Transitions } from '@root/brand/transitions'
import { useSelectable } from '@root/hooks/useSelectable'
import { useCardsSelectionConfig } from '@root/store/selectors/useCardsSelectionConfig'
import { SelectableTypes } from '@root/store/slices/selection.types'
import { isEqual } from 'lodash'
import { forwardRef, ForwardRefRenderFunction, useEffect, useRef, useState } from 'react'
import styled, { css, CSSProperties } from 'styled-components'

type CardPositionStruct = {
	x: number
	y: number
	width: number
	height: number
}

type Props = {
	children: any
	cardId: string
	keepOnlyForEvent?: boolean
	resourceId: string
	isResized?: boolean
	isDragged?: boolean
	resourceType: GrantableResourceEnum
	directStyles?: CSSProperties
	coverWholeCard?: boolean
	className: string
	animate: any
	calculatedRect?: { w: number; x: number; y: number; h: number }
}
const CardPositionerWithoutRef: ForwardRefRenderFunction<any, Props> = (
	{ children, keepOnlyForEvent, className, isResized, isDragged, resourceId, cardId, resourceType, coverWholeCard, animate, calculatedRect },
	ref
) => {
	const { template } = useCardsSelectionConfig(resourceId, resourceType)
	const { select, selectedClassName } = useSelectable(SelectableTypes.CARD, cardId, template, resourceId)

	const skipSelection = useRef(false)
	const beenResized = useRef(false)
	const beenDragged = useRef(false)
	const [fadeIn, setFadeIn] = useState<
		| {
				initialRect: any
		  }
		| undefined
	>(undefined)

	const lastRect = useRef<any>(undefined)
	useEffect(() => {
		if (!calculatedRect) return
		setFadeIn((s) => {
			if (s === undefined) {
				lastRect.current = calculatedRect
				animate(
					'.card',
					{
						x: calculatedRect.x,
						y: calculatedRect.y,
						width: calculatedRect.w,
						height: calculatedRect.h,
						opacity: 1,
						display: 'block',
					},
					{
						duration: 0,
					}
				)
				return { initialRect: calculatedRect }
			}
			return s
		})
	}, [calculatedRect, animate])

	useEffect(() => {
		if (!calculatedRect) return
		if (!fadeIn) return
		if (isResized) {
			beenResized.current = true
			return
		}
		if (isDragged) {
			beenDragged.current = true
			return
		}

		if (beenDragged.current || beenResized.current || !isEqual(lastRect.current, calculatedRect)) {
			animate(
				'.card',
				{
					x: calculatedRect.x,
					y: calculatedRect.y,
					width: calculatedRect.w,
					height: calculatedRect.h,
					opacity: 1,
					display: 'block',
				},
				{
					...Transitions.cardRelated,
				}
			)
			lastRect.current = calculatedRect
		}
	}, [animate, fadeIn, isDragged, isResized, calculatedRect])

	return (
		<Element
			tabIndex={20}
			onClick={(e) => {
				if (skipSelection.current) {
					skipSelection.current = false
					return
				}
				e.stopPropagation()
				select(e)
			}}
			onMouseDown={(e) => {
				if ((e.target as any).classList.contains('draggable-block-menu') || (e.target as any)?.closest('.draggable-block-menu')) {
					skipSelection.current = true
				}
			}}
			fadeIn={!!fadeIn}
			className={`${className} ${selectedClassName} card`}
			keepOnlyForEvent={keepOnlyForEvent}
			ref={ref}
			data-id={cardId}
			directStyles={fadeIn?.initialRect}
			coverWholeCard={coverWholeCard}
		>
			<Transitioner fadeIn={!!fadeIn}>
				{/* <div className="frame-top" />
				<div className="frame-bottom" />
				<div className="frame-left" />
				<div className="frame-right" /> */}
				{children}
			</Transitioner>
		</Element>
	)
}

export const CardPositioner = forwardRef(CardPositionerWithoutRef)

const Transitioner = styled.div.attrs((p: any) => ({
	fadeIn: p.fadeIn,
}))`
	transition: 0.7s ease opacity, 0.7s ease transform;
	will-change: opacity, transform;
	position: absolute;
	width: 100%;
	height: 100%;
	transform: translateY(20px);
	opacity: 0;

	${(p) =>
		p.fadeIn &&
		css`
			opacity: 1;
			transform: translateY(0px);
		`}
`

const Element = styled.div.attrs((p: any) => ({
	keepOnlyForEvent: p.keepOnlyForEvent,
	directStyles: p.directStyles,
	coverWholeCard: p.coverWholeCard,
	fadeIn: p.fadeIn,
}))`
	display: block;
	align-items: center;
	justify-content: center;
	position: absolute;
	border-radius: ${(p) => p.theme.statics.borderRadius.medium};
	border: none;
	z-index: 1;
	will-change: transform;

	${(p) =>
		p.directStyles &&
		css`
			width: ${p.directStyles.w}px;
			height: ${p.directStyles.h}px;
			transform: translateX(${p.directStyles.x}px) translateY(${p.directStyles.y}px);
		`}

	.content {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 100000002;
	}

	${(p) =>
		p.coverWholeCard &&
		css`
			.frame-top {
				height: 100%;
				/* background-color: pink; */
			}
		`}
	${(p) =>
		p.keepOnlyForEvent &&
		css`
			opacity: 0;
		`}

	&.selected {
		z-index: 100000000;

		.show-only-on-hover {
			display: flex;
			opacity: 1;
			transform: translateY(0) translateX(0);
		}
	}

	.hover-transitions {
		transition: 0.1s ease all;
	}
`

// .frame-top,
// .frame-bottom,
// .frame-left,
// .frame-right {
// 	width: 100%;
// 	height: 20px;
// 	top: 0;
// 	left: 0;
// 	position: absolute;
// 	z-index: 100000003;
// }
// .frame-bottom {
// 	bottom: 0;
// 	top: auto;
// }
// .frame-left,
// .frame-right {
// 	width: 20px;
// 	height: 100%;
// 	top: 0;
// 	left: 0;
// 	position: absolute;
// 	z-index: 2;
// }
// .frame-right {
// 	left: auto;
// 	right: 0;
// }
