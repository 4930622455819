import { GenericServerResponse } from '@clepside/clepsidejs/lib/commons/commons_pb';
import {
    AcceptInvitationRequest, CheckBeenInvitedRequest, InviteUserRequest, InviteUserResponse,
    ListInviteesRequest, ListInviteesResponse, RevokeInviteeRequest
} from '@clepside/clepsidejs/lib/services/users_v1_pb';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsersGRPC } from '@root/grpc/grpcUsers';
import { collectionInsert } from '@root/utils/general';
import { error } from 'console';
import { RpcError, StatusCode } from 'grpc-web';
import { delay } from 'redux-saga/effects';
import { put, take } from 'typed-redux-saga';

import { authActions } from './auth';
import { LocalUserDetails } from './auth.types';
import { AuthClient } from './authClient';
import { State } from './userInvites.types';
import { watcherActions } from './watchers';
import { WatcherID } from './watchersTypes';

const userInvitesSlice = createSlice({
	name: 'userInvites',
	initialState: State,
	reducers: {
		fetchPastInvites: (state, { payload }: PayloadAction) => {},
		storeInvites: (state, { payload }: PayloadAction<{ invites: ListInviteesResponse.AsObject['inviteesList'], invitesLeft: number }>) => {
			state.invitesLeft = payload.invitesLeft
			state.invites = { all: [], at: {} }
			payload.invites.map((i) => {
				collectionInsert(state.invites, i.inviteeEmail, i)
			})
		},
		inviteUser: (state, { payload }: PayloadAction<{ watcherId: WatcherID, email:string }>) => {},
		revokeInvitee: (state, { payload }: PayloadAction<{ watcherId: WatcherID, email:string }>) => {},
		acceptInvitation: (state, { payload }: PayloadAction<{ watcherId: WatcherID }>) => {},
		checkBeenInvited: (state, { payload }: PayloadAction<{ watcherId: WatcherID }>) => {},
	},
})

export const userInvitesSagas = {
	*fetchPastInvites({ payload }: ReturnType<typeof userInvitesSlice.actions.inviteUser>): any {
		if (!AuthClient.currentUser) {
			while (true) {
				const settingStateAction: { payload: LocalUserDetails } = yield take(authActions.setState)
				if (settingStateAction?.payload?.isFromLocal == false) {
					break
				}
			}
		}
		const pastInvitesResponse: ListInviteesResponse = yield UsersGRPC.fetchPastInvites(new ListInviteesRequest())
		const pastInvites = pastInvitesResponse.toObject()

		yield put(userInvitesActions.storeInvites({ 
			invites: pastInvites.inviteesList,
			invitesLeft: pastInvites.availableInvites
		}))
	},
	*inviteUser({ payload }: ReturnType<typeof userInvitesSlice.actions.inviteUser>): any {
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))

		try {
			const request = new InviteUserRequest()
			request.setEmail(payload.email)
			const rawResponse: InviteUserResponse = yield UsersGRPC.inviteUser(request)

			yield put(userInvitesActions.fetchPastInvites())
			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
		} catch (e) { 
			const err = e as RpcError
			console.error('Failed to invite user', err)
			if (err.code == StatusCode.RESOURCE_EXHAUSTED) {
				yield put(watcherActions.fail({ watchers: [payload.watcherId], context: {
					type: 'error',
					end: 'client',
					message: 'No invites left to send'
				} }))
			} else {
				yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
			}
		}
	},
	*revokeInvitee({ payload }: ReturnType<typeof userInvitesSlice.actions.revokeInvitee>): any {
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))

		try {
			const request = new RevokeInviteeRequest()
			request.setInviteeEmail(payload.email)
			const rawResponse: GenericServerResponse = yield UsersGRPC.revokeInvitee(request)
			const { response } = rawResponse.toObject()
        
			yield put(userInvitesActions.fetchPastInvites())
			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
		} catch (e) { 
			console.error(e)
			yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
		}
	},
	*acceptInvitation({ payload }: ReturnType<typeof userInvitesSlice.actions.acceptInvitation>): any {
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))

		try {
			const request = new AcceptInvitationRequest()
			const rawResponse: GenericServerResponse = yield UsersGRPC.acceptInvitation(request)
			const { response } = rawResponse.toObject()

			yield AuthClient.currentUser?.getIdTokenResult(true)
			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
			yield delay(500)
			window.location.reload()
		} catch (e) { 
			console.error(e)
			yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
		}
	},
	*checkBeenInvited({ payload }: ReturnType<typeof userInvitesSlice.actions.acceptInvitation>): any {
		yield put(watcherActions.start({ watchers: [payload.watcherId], isFetcher: true }))

		try {
			const request = new CheckBeenInvitedRequest()
			const rawResponse: GenericServerResponse = yield UsersGRPC.checkBeenInvited(request)
			const { response } = rawResponse.toObject()
			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
		} catch (e) { 
			console.log('Error', e)
			const err = e as RpcError
			if (err.code == StatusCode.NOT_FOUND) {
				yield put(watcherActions.fail({ watchers: [payload.watcherId], context: {
					type: 'error',
					end: 'client',
					message: 'no-invite',
					displayMessage: 'No invite found'
				} }))
			} else {
				yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
			}
		}
	},
}

export const userInvitesActions = userInvitesSlice.actions
export const userInvitesReducers = userInvitesSlice.reducer