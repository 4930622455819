import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { FragmentCompletablePacketMeta } from '@clepside/clepsidejs/lib/packets_v1/fragments_completable_pb'
import { FragmentPlainTextPacketMeta } from '@clepside/clepsidejs/lib/packets_v1/fragments_plain_text_pb'
import { FragmentUploadablePacketMeta } from '@clepside/clepsidejs/lib/packets_v1/fragments_uploadable_pb'
import { Database } from '@db/index'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { put } from 'redux-saga/effects'
import {
	FragmentCompletableData,
	FragmentCompletableNormalisedData,
	FragmentPlainTextData,
	FragmentPlainTextNormalisedData,
	FragmentPlainTextSpecifics,
	fragmentsState,
	FragmentUploadableData,
	FragmentUploadableNormalisedData
} from './fragments.types'
import { deltaBatchDelete, deltaCreate, deltaUpdate } from './persist'
import { mergePackets, NewPacketDelta, UpdatePacketDelta } from './persist.utils'
import { FragmentWithComputedLocalProps, GenericFragmentPacketObject } from './sync.tsx.packets.types'
import { ValidPacketType } from './sync.types'
import { watcherActions } from './watchers'

export const BLANK_LEXICAL_CONTENT = {
	text: {
		children: [],
		direction: null,
		format: '',
		type: 'line-node',
		syncedAt: -1,
		indent: 0,
		position: 1,
		version: 1,
		level: 'paragraph',
		kind: 'text'
	},
	todo: {
		children: [],
		direction: null,
		format: '',
		type: 'line-node',
		syncedAt: -1,
		indent: 0,
		position: 1,
		version: 1,
		level: 'paragraph',
		kind: 'todo',
		isChecked: false
	}
}

export const normalizeFragmentPacket = (id: string, s: ({
	type: PacketType.FRAGMENT_COMPLETABLE,
	data: FragmentCompletableData
	meta: FragmentCompletablePacketMeta
} | {
	type: PacketType.FRAGMENT_PLAIN_TEXT,
	data: FragmentPlainTextData,
	meta: FragmentPlainTextPacketMeta
} | {
	type: PacketType.FRAGMENT_UPLOADABLE,
	data: FragmentUploadableData,
	meta: FragmentUploadablePacketMeta
}) & { lastModifiedAt: number, clientOnlyLastUpdateAt: number }) => {
	switch (s.type) {
		case PacketType.FRAGMENT_PLAIN_TEXT:
			try { 
				let content: FragmentPlainTextSpecifics = {
					level: 'paragraph',
					text: JSON.stringify(BLANK_LEXICAL_CONTENT.text),
					type: 'NOTE'
				} 
				try { if (s.data.contents) { 
					content = JSON.parse(s.data.contents) 
				} } catch (e) {
					console.error('Failed to parse the data contents of the fragment packet', e)
				}

				const noteFragment: FragmentPlainTextNormalisedData = {
					id,
					cardId: s.data.cardId,
					position: s.data.position,
					specifics: content,
					lastModifiedAt: s.lastModifiedAt,
					type: PacketType.FRAGMENT_PLAIN_TEXT as const,
					clientOnlyLastUpdateAt: s.clientOnlyLastUpdateAt
				}
				return noteFragment
			} catch (e) {
				console.error('Failed to normalise the PlainTextFragment', e)
			}
			return undefined
		case PacketType.FRAGMENT_COMPLETABLE:
			const title = s.data.title || JSON.stringify(BLANK_LEXICAL_CONTENT.todo)
			
			const todoFragment: FragmentCompletableNormalisedData = {
				id,
				cardId: s.data.cardId,
				position: s.data.position,
				title,
				lastModifiedAt: s.lastModifiedAt,
				isCompleted: s.data.isCompleted,
				type: PacketType.FRAGMENT_COMPLETABLE as const,
				clientOnlyLastUpdateAt: s.clientOnlyLastUpdateAt
			}
			return todoFragment
		case PacketType.FRAGMENT_UPLOADABLE:
			const uploadableFragment: FragmentUploadableNormalisedData = {
				id,
				cardId: s.data.cardId,
				fileId: s.data.fileInfo.id,
				position: s.data.position,
				type: PacketType.FRAGMENT_UPLOADABLE as const,
				fileType: s.data.fileInfo.type,
				lastModifiedAt: s.lastModifiedAt,
				clientOnlyLastUpdateAt: s.clientOnlyLastUpdateAt
			}
			return uploadableFragment
	}
}

const fragmentsSlice = createSlice({
	name: 'fragments',
	initialState: fragmentsState,
	reducers: {
		loadFragmentsForCardId(state, { payload }: PayloadAction<{ cardId: string }>) {},
		fragmentPlainTextNew: (state, { payload }: PayloadAction<NewPacketDelta<PacketType.FRAGMENT_PLAIN_TEXT> & { id?: string }>) => {},
		fragmentPlainTextUpdate: (state, { payload }: PayloadAction<UpdatePacketDelta<PacketType.FRAGMENT_PLAIN_TEXT>>) => {},
		fragmentUploadableNew: (state, { payload }: PayloadAction<NewPacketDelta<PacketType.FRAGMENT_UPLOADABLE> & { id?: string }>) => {},
		fragmentUploadableUpdate: (state, { payload }: PayloadAction<UpdatePacketDelta<PacketType.FRAGMENT_UPLOADABLE>>) => {},
		fragmentCompletableNew: (state, { payload }: PayloadAction<NewPacketDelta<PacketType.FRAGMENT_COMPLETABLE> & { id?: string }>) => {},
		fragmentCompletableUpdate: (state, { payload }: PayloadAction<UpdatePacketDelta<PacketType.FRAGMENT_COMPLETABLE>>) => {},
		delete: (state, { payload }: PayloadAction<{ type: ValidPacketType, ids: string[] }>) => {},
		normalizeAndStore: (state, { payload }: PayloadAction<{ objects: FragmentWithComputedLocalProps<GenericFragmentPacketObject>[], fromSync?: boolean }>) => {
			// TO-DO
			// Add computed properties, etc..
			payload.objects?.forEach((frag) => {
				if (!frag.id) return

				if (frag.deleted?.is) {
                    if (frag.data.cardId) {
                        state.byCard[frag.data.cardId] = state.byCard[frag.data.cardId]?.filter(fragId => fragId != frag.id)
						state.byActivity[frag.data.cardId] = state.byActivity[frag.data.cardId]?.filter(fragId => fragId != frag.id)
                    }
                } else {
					// const lastUpdateTimestamp = 
					const normalised = normalizeFragmentPacket(frag.id, {
						type: frag.type,
						data: frag.data as any,
						meta: frag.meta as any,
						lastModifiedAt: frag.lastModifiedAt,
						clientOnlyLastUpdateAt: frag.clientOnlyLastUpdateAt
					})
					

					if (!normalised) {
						return
					}

					if (!state.byCard[frag.data.cardId]) {
						state.byCard[frag.data.cardId] = []
					}
					state.byCard[frag.data.cardId] = state.byCard[frag.data.cardId]?.filter(fragId => fragId != frag.id)
					state.byCard[frag.data.cardId].push(frag.id)
					state.byCard[frag.data.cardId].sort((a, b) => a > b ? 1 : -1)

					state.at[frag.id] = {
						...(state.at[frag.id] ? state.at[frag.id] : {}),
						...normalised
					}
				}
			})
		},
	},
})

export const fragmentsSagas = {
	*loadFragmentsForCardId({ payload }: ReturnType<typeof fragmentsSlice.actions.loadFragmentsForCardId>): any {
		const textPackets: GenericFragmentPacketObject[] = yield Database.fragmentPacketsPlainText.where('data.cardId').equals(payload.cardId).toArray()
		const completablePackets: GenericFragmentPacketObject[] = yield Database.fragmentPacketsCompletable.where('data.cardId').equals(payload.cardId).toArray()
		const uploadablePackets: GenericFragmentPacketObject[] = yield Database.fragmentPacketsUploadable.where('data.cardId').equals(payload.cardId).toArray()
		// Shouldn't these be merged with the updates pending in the QUEUE???????????

		yield put(watcherActions.start({ watchers: [`Load.FragmentsForCardId${payload.cardId}`] }))

		const allPackets = [...textPackets, ...completablePackets, ...uploadablePackets]
		
		const keys = allPackets.map(f => f.id)
		const items: any = yield Database.queue.bulkGet(keys)
		const queuedPackets = (items).reduce((acc: any, packet: any) => { 
			if (packet)
				acc[packet.id] = packet
			return acc
		}, {} as any)

		const realPackets: any[] = []
		for (const packet of allPackets) {
			const realPacket = mergePackets(packet, queuedPackets[packet.id])
			realPackets.push(realPacket)
		}
		
		yield put(
			fragmentsActions.normalizeAndStore({ objects:  realPackets })
		)

		yield put(watcherActions.done({ watchers: [`Load.FragmentsForCardId${payload.cardId}`] }))
	},
	// Notes
	*fragmentUploadableNew({ payload: delta }: ReturnType<typeof fragmentsSlice.actions.fragmentUploadableNew>) {
		yield deltaCreate({ type: PacketType.FRAGMENT_UPLOADABLE, delta: delta, id: delta.id })
	},
	*fragmentUploadableUpdate({ payload: delta }: ReturnType<typeof fragmentsSlice.actions.fragmentUploadableUpdate>) {
		yield deltaUpdate({ type: PacketType.FRAGMENT_UPLOADABLE, delta: delta })
	},
	*fragmentPlainTextNew({ payload: delta }: ReturnType<typeof fragmentsSlice.actions.fragmentPlainTextNew>) {
		yield deltaCreate({ type: PacketType.FRAGMENT_PLAIN_TEXT, delta: delta, id: delta.id })
	},
	*fragmentPlainTextUpdate({ payload: delta }: ReturnType<typeof fragmentsSlice.actions.fragmentPlainTextUpdate>) {
		yield deltaUpdate({ type: PacketType.FRAGMENT_PLAIN_TEXT, delta: delta })
	},
	// Todos
	*fragmentCompletableNew({ payload: delta }: ReturnType<typeof fragmentsSlice.actions.fragmentCompletableNew>) {
		yield deltaCreate({ type: PacketType.FRAGMENT_COMPLETABLE, delta: delta, id: delta.id })
	},
	*fragmentCompletableUpdate({ payload: delta }: ReturnType<typeof fragmentsSlice.actions.fragmentCompletableUpdate>) {
		yield deltaUpdate({ type: PacketType.FRAGMENT_COMPLETABLE, delta: delta })
	},
	*delete({ payload: { type, ids } }: ReturnType<typeof fragmentsSlice.actions.delete>) {
		if (ids.length)
			yield deltaBatchDelete({ type, deltas: ids.map((id) => ({ 
				id, shouldDelete: true
			})) }) 
	}
}

export const fragmentsActions = fragmentsSlice.actions
export const fragmentsReducers = fragmentsSlice.reducer
