import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { CardLayout } from '@clepside/clepsidejs/lib/packets_v1/cards_pb';

import { CardPacketObject } from './sync.tsx.packets.types';
import { LocalData } from './sync.types';

export type GrantableResourceType = GrantableResourceEnum
export type CardLayoutObject = CardLayout.AsObject
export type CardData = LocalData<CardPacketObject> & { fragmentsReady: boolean, lastModifiedAt: number }


interface CardsState {
	atId: {
		[id: string]: CardData & { markdownForCard?: string }
	},
	forResourceId: {
		[id: string]: string[]
	}
}

export const cardsState: CardsState = {
	atId: {},
	forResourceId: {}
}
