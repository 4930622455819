import { useMemo } from 'react';

import { InstancedSessionData } from '../slices/sessionsTypes';
import { useActivities } from './useActivities';

export const useActivityForSession = (session?: InstancedSessionData) => {
	const activities = useActivities()

	return useMemo(() => {
		if (!session?.activityId) return undefined
		return activities.at[session.activityId]
	}, [activities, session])
}
