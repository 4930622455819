/**
 * @fileoverview gRPC-Web generated client stub for persist
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.1
// source: services/persist.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var unions_v1_packets_pb = require('../unions_v1/packets_pb.js')
const proto = {};
proto.persist = require('./persist_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.persist.PersistServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.persist.PersistServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.persist.PersistRequest,
 *   !proto.persist.PersistResponse>}
 */
const methodDescriptor_PersistService_Persist = new grpc.web.MethodDescriptor(
  '/persist.PersistService/Persist',
  grpc.web.MethodType.UNARY,
  proto.persist.PersistRequest,
  proto.persist.PersistResponse,
  /**
   * @param {!proto.persist.PersistRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.persist.PersistResponse.deserializeBinary
);


/**
 * @param {!proto.persist.PersistRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.persist.PersistResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.persist.PersistResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.persist.PersistServiceClient.prototype.persist =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/persist.PersistService/Persist',
      request,
      metadata || {},
      methodDescriptor_PersistService_Persist,
      callback);
};


/**
 * @param {!proto.persist.PersistRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.persist.PersistResponse>}
 *     Promise that resolves to the response
 */
proto.persist.PersistServicePromiseClient.prototype.persist =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/persist.PersistService/Persist',
      request,
      metadata || {},
      methodDescriptor_PersistService_Persist);
};


module.exports = proto.persist;

