import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { Button } from '@comps/interactive/button'
import { NavigationStackLink } from '@comps/interactive/navigationStackLink'
import { SessionStatus } from '@comps/interactive/sessionStatus'
import { Flex } from '@comps/layout/flex'
import { Spacer } from '@comps/layout/space'
import { ActivityBubble } from '@comps/static/activityBubble'
import { Icon } from '@comps/static/icon'
import { Text } from '@comps/typography/text'
import { DARK_TRANSLUCENCY, narrowBottomBorder } from '@root/brand/css'
import { useSessionSwitchActiveResource } from '@root/hooks/useSessionSwitchActiveResource'
import { ActivityData } from '@root/store/slices/activitiesTypes'
import { InstancedSessionData } from '@root/store/slices/sessionsTypes'
import { TopBarSwitch } from '@root/views/Sessions.ID.TopBar.Switch'
import styled from 'styled-components'
import { AddCardMultiButton } from '../addCardMultiButton'

export const SelectedSessionTopBar: React.FC<{ activity: ActivityData; session: InstancedSessionData }> = ({ activity, session }) => {
	const { isSession } = useSessionSwitchActiveResource()

	return (
		<Bar>
			<TopPart>
				<Flex justify="space-between">
					<LogoFlex>
						<ActivityBubble size="small" activity={activity} />
						<Spacer size={15} />
						<Flex align="baseline">
							<Text level="largerParagraphTitle" bold color="darkTranslucency">
								{session.title || activity.name}
							</Text>
							<Spacer size={15} />
							<Text level="label" color="darkTranslucencySubtle">
								<SessionStatus start={session.start.getTime()} end={session.end.getTime()} />
							</Text>
						</Flex>
					</LogoFlex>
				</Flex>
				<Flex align="center" spacing={10}>
					<TopBarSwitch activity={activity} session={session} />
					{/* {isSession && <AddCardMultiButton resourceId={session.id} resourceType={GrantableResourceEnum.SESSION} />}
					{isActivity && <AddCardMultiButton resourceId={activity.id} resourceType={GrantableResourceEnum.ACTIVITY} />} */}
					{/* <Link to={`/session/${session.id}`}> */}
					<Flex align="center">
						{/* {isSession && (
								<Button
									color="darkTranslucentElevated"
									inset="buttonMedium"
									onClick={() => {
										// dispatch(InterfaceActions.SET_ADD_CARD(session.id))
									}}
								>
									<Text level="label" block>
										Edit
									</Text>
								</Button>
							)}
							<Spacer size={5} /> */}
						<NavigationStackLink to={`/session/${session.id}/${isSession ? 'session' : 'activity'}`} className="open-arrow">
							<Button color="darkTranslucentElevated" inset="buttonMedium">
								<Text block level="label">
									Expand
								</Text>
								<Spacer size={10} />
								<Icon type="expand" />
							</Button>
						</NavigationStackLink>
					</Flex>
					{/* </Link> */}
				</Flex>
			</TopPart>
			<Flex justify="center" grow>
				<AddCardMultiButton resourceId={isSession ? session.id : activity.id} resourceType={isSession ? GrantableResourceEnum.SESSION : GrantableResourceEnum.ACTIVITY} />
			</Flex>
		</Bar>
	)
}

const Bar = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: calc(100%);
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding: 15px 24px 5px 24px;
	justify-content: space-between;
	z-index: 10;
	/* box-shadow: 0 0.5px 0 0px rgba(0, 0, 0, 0.75); */
	background-color: #fff;
	${(p) => p.theme.text.regular.r.css('color')};
	border-bottom: none;
	flex-direction: column;
	align-items: stretch;
	${DARK_TRANSLUCENCY};
	z-index: 6000000;
`

const TopPart = styled.div`
	position: relative;
	justify-content: space-between;
	display: flex;
	align-items: center;
	padding-bottom: 10px;
	margin-bottom: 5px;
	${narrowBottomBorder(0)};

	@media (max-width: 1300px) {
		span.session-status {
			display: none;
		}
	}
`

const LogoFlex = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	left: -3px;
`
