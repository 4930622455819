import { gridPadding } from '@comps/complex/cards/cardGrid.commons'
import { Size } from '@comps/complex/cards/grid.utils'
import { useLayoutEffect, useRef, useState } from 'react'

export const useGridDimensions = (dragZoneId: string, shouldRerender?: boolean) => {
	const gridDimensionsRef = useRef<Size>({
		width: 0,
		height: 0,
	})
	const [gridDimensions, setGridDimensions] = useState<Size>({
		width: 0,
		height: 0,
	})
	const gridCellWidthRef = useRef(0)
	const [gridCellWidth, setGridCellWidth] = useState(0)

	useLayoutEffect(() => {
		const onResize = (newSize: { width: number; height: number }) => {
			const grid = document.querySelector(`.drag-grid-${dragZoneId}`)
			// const newSize = {
			// 	width: grid?.getBoundingClientRect()?.width || 0,
			// 	height: grid?.getBoundingClientRect()?.width || 0,
			// }
			const newCellWidth = (newSize.width - 11 * gridPadding) / 12

			gridDimensionsRef.current = newSize
			gridCellWidthRef.current = newCellWidth

			if (shouldRerender) {
				setGridCellWidth(newCellWidth)
				setGridDimensions(newSize)
			}
		}

		const grid = document.querySelector(`.drag-grid-${dragZoneId}`)

		if (!grid) return

		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				const { height, width } = entry.contentRect
				onResize({ width, height })
			})
		})

		resizeObserver.observe(grid)

		return () => {
			resizeObserver.unobserve(grid)
		}
		// onResize()
		// window.addEventListener('resize', onResize)
		// return () => {
		// 	window.removeEventListener('resize', onResize)
		// }
	}, [dragZoneId, shouldRerender])

	return {
		gridDimensionsRef,
		gridCellWidthRef,
		gridDimensions,
		gridCellWidth,
	}
}
