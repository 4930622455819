import { BackButton } from '@comps/complex/button/backButton'
import { CalendarSessionsPlacer } from '@comps/complex/planning/sessionPlacer'
import { SmartBorder } from '@comps/complex/smartBorder'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { Separator } from '@comps/layout/separator'
import { Spacer } from '@comps/layout/space'
import { KeyScope } from '@comps/logical/keyScope'
import { Text } from '@comps/typography/text'
import { useTime } from '@hooks/useTime'
import { useDayIndexes } from '@root/hooks/useDayIndexes'
import { usePagePresence } from '@root/hooks/usePagePresence'
import { useStartOfWeek } from '@root/hooks/useStartOfWeek'
import { useSessionsPlacerDimensions } from '@root/hooks/useWeekDimensions'
import { useMergedSessions, useSessions } from '@root/store/selectors/useSessions'
import { useSessionsSource } from '@root/store/selectors/useSessionsSource'
import { selectionActions } from '@root/store/slices/selection'
import { SelectableTypes } from '@root/store/slices/selection.types'
import { SessionDisplayConditions } from '@root/store/slices/sessionsTypes'
import { getDataAttribute, range } from '@utils/general'
import { add, endOfWeek, format, getWeek, isSameDay, startOfWeek, sub } from 'date-fns'
import { MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { CalendarDayAnalytics } from './Calendar.DayAnalytics'
import { CalendarDayNavigationButtons } from './Calendar.dayNavigationButtons'
import { CalendarFullWeekAnalytics } from './CalendarFullWeek.analytics'
import { CalenderFullWeekGrid } from './CalendarFullWeek.grid'

export const CalendarFullWeek: React.FC = () => {
	const date = useTime()
	const [presentedDate, setPresentedDate] = useState(new Date(date))
	const numberOfDaysShown = useMemo(() => 7, [])
	const weeksSessions: any = useSessions('this-week')

	const sessionsConfig = useMemo(() => {
		return {
			start: startOfWeek(presentedDate).getTime(),
			end: endOfWeek(presentedDate).getTime(),
			inclusive: 'both',
		} as SessionDisplayConditions
	}, [presentedDate])
	useSessionsSource(sessionsConfig, 'for-week-<0>', `${sessionsConfig.start}`)
	const sessionsForPresentedDate = useSessions('for-week-<0>', `${sessionsConfig.start}`)
	const sessions = useMergedSessions(weeksSessions, sessionsForPresentedDate)

	const dispatch = useDispatch()
	const getStartOfWeek = useStartOfWeek()
	const presentedDates: Date[] = useMemo(() => {
		if (numberOfDaysShown === 7) {
			const startDate = getStartOfWeek(presentedDate)
			return range(7).map((i, j) => add(startDate, { days: j }))
		} else {
			throw new Error('Date range not prepared')
		}
	}, [numberOfDaysShown, getStartOfWeek, presentedDate])
	const selectedSession = useRef<string | undefined>()
	const handleSelectionMade = useCallback(
		(id?: string) => {
			if (id) {
				dispatch(
					selectionActions.select({
						// context: {
						// 	type: SelectableTypes.SESSION,
						// },
						ids: [
							{
								itemType: SelectableTypes.SESSION,
								id: id,
							},
						],
						scope: 'Plan.Weekly',
						scopeTemplate: 'Plan.Weekly',
					})
				)
			}
			selectedSession.current = id
		},
		[dispatch]
	)
	// const dispatch = useDispatch()
	const { show } = usePagePresence()
	const presentedDatesIndexes = useDayIndexes(presentedDates)
	const { dimensions, container, setContainer, dragZoneId } = useSessionsPlacerDimensions(presentedDatesIndexes, false, true)

	const handleDayNavigationClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
		(e) => {
			const direction = getDataAttribute(e, 'direction')
			if (direction == 'left') {
				setPresentedDate((d) => sub(d, { weeks: 1 }))
			}
			if (direction == 'reset') {
				setPresentedDate(new Date())
			}
			if (direction == 'right') {
				setPresentedDate((d) => add(d, { weeks: 1 }))
			}
		},
		[setPresentedDate]
	)

	return (
		<Holder present={show}>
			<ContentContainer id="SCROLL-CONTAINER">
				<KeyScope scope="Plan.Weekly" />
				<Header>
					<ButtonsBar>
						<Flex align="center" grow>
							<Inset negativeX padding="buttonMedium" shrink>
								<BackButton defaultBackTo="/calendar" color="textualAccent" />
							</Inset>
							<Separator vertical margin={20} size={10} color="subtle" />
							<Text noWrap level="label">
								Week {getWeek(presentedDate)} of {format(presentedDates[0], 'yyyy')}
							</Text>
						</Flex>
						<Flex align="center">
							<CalendarFullWeekAnalytics sessions={sessions} presentedDates={presentedDates} />
							<Spacer size={15} />
							<CalendarDayNavigationButtons forDate={presentedDate} onDayNavigationClicked={handleDayNavigationClick} />
						</Flex>
					</ButtonsBar>
					<CalenderFullWeekGrid numberOfDaysShown={numberOfDaysShown} dimensions={dimensions}>
						{presentedDates?.map((d) => {
							const isToday = isSameDay(new Date(date), d)
							return (
								<Flex direction="column" key={d.toString()}>
									<Flex>
										<Text level="title30" marginBottom={8} block>
											{isToday ? 'Today' : format(d, 'd MMM')}
										</Text>
										<CalendarDayAnalytics sessions={sessions} date={d} />
									</Flex>
									<Spacer size={5} vertical />
									{isToday ? (
										<Flex align="center">
											<ActiveDot />
											<Text bold level="label">
												{format(d, 'EEEE')}
											</Text>
										</Flex>
									) : (
										<Text color="subtle" level="label">
											{format(d, 'EEEE')}
										</Text>
									)}
								</Flex>
							)
						})}
					</CalenderFullWeekGrid>
					<SmartBorder />
				</Header>
				<CalendarSessionsPlacer
					dimensionsBasedOnWindowObject
					hideTimes
					selectionZoneId="Plan.Weekly"
					offsetLeft={50}
					setContainer={setContainer}
					container={container}
					dimensions={dimensions}
					dragZoneId={dragZoneId}
					sessions={sessions}
					presentedDates={presentedDates}
					presentedDatesIndexes={presentedDatesIndexes}
					onSelectionMade={handleSelectionMade}
				/>
			</ContentContainer>
		</Holder>
	)
}
//{/* <ActiveDot /> */}

const ButtonsBar = styled.div`
	display: flex;
	margin: 10px 0 20px 0;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`

const Header = styled.div`
	display: flex;
	position: sticky;
	flex-direction: column;
	top: 0px;
	left: 0;
	width: 100%;
	z-index: 100;
	padding: 0 30px 30px 30px;
	backdrop-filter: blur(44px);
	box-sizing: border-box;
	border-bottom: none;
	background: rgba(255, 255, 255, 0.7);
`

const ContentContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
`
const Holder = styled.div<{ present?: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: block;
	overflow: hidden;
	z-index: 10000;
	will-change: transform;
	background-color: #fff;
	transform: scale(1.08);
	opacity: 0;
	transition: ease transform 0.29s, ease opacity 0.29s;

	${(p) =>
		p.present &&
		css`
			transform: scale(1);
			opacity: 1;
		`}
`

const ActiveDot = styled.div`
	width: 6px;
	height: 6px;
	border: 2px solid #fff;
	border-radius: 999px;
	margin-top: 5px;
	margin-right: 6px;
	position: relative;
	top: -2px;
	${(p) => p.theme.backgrounds.clepside.r.css('background-color')};
	${(p) => p.theme.backgrounds.clepside.r.css('box-shadow', '0 0 0px 1px')};
`
