import { Collection } from '../commonTypes';
import { CardChatMessageRuneObject, CardChatRuneObject } from './runes.types';

interface State {
    atCardId: {
        [id: string]: {
            streamedMessageId?: string
            messages: Collection<CardChatMessageRuneObject['rune']>
            chats: Collection<CardChatRuneObject['rune']>
            allForChatId: {
                [chatId: string]: string[]
            }
        }
    }   
}

export const InitialAIChatCardState: State = {
    atCardId: {}
}
