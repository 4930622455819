import React from 'react';
import styled, { css } from 'styled-components';

export const CircleArrow: React.FC<{ direction?: 'down' | 'right' | 'up-right' | 'bottom' }> = ({ direction }) => {
	return (
		<Holder>
			<SVG width="6" height="4" viewBox="0 0 6 4" fill="none" dir={direction}>
				<path d="M1 0.5L3 2.5L5 0.5" stroke="currentColor" strokeLinecap="round" />
			</SVG>
		</Holder>
	)
}

const SVG = styled.svg<{ dir: React.ComponentProps<typeof CircleArrow>['direction'] }>`
	transform: translateY(0.75px);
	${(p) =>
		p.dir === 'right' &&
		css`
			transform: translateY(0px) translateX(0.75px) rotateZ(-90deg);
		`}
	${(p) =>
		p.dir === 'bottom' &&
		css`
			transform: translateY(0.75px) translateX(0px) rotateZ(0deg);
		`}
	${(p) =>
		p.dir === 'up-right' &&
		css`
			transform: translateY(-0.35px) translateX(0.35px) rotateZ(-135deg);
		`}
`

const Holder = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 999px;
	${(p) => p.theme.border.subtle.r.css('border', '1px solid')};
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 4px;
	margin-right: -1px;
`
