import { Transitions } from '@brand/transitions'
import { ViewID } from '@root/store/slices/interface.types'
import { ExtractOnlyExisting } from '@root/store/slices/selection.types'
import { useActivities } from '@store/selectors/useActivities'
import { getSessionPeriod } from '@utils/dates'
import { getISODay } from 'date-fns'
import { motion } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import styled, { css } from 'styled-components'
import { SessionBlock } from './sessionBlock'
import { useSessionDragStart } from './sessionBlockLogic.useSessionDragStart'
import { SessionPlacerDimensions } from './sessionPlacer.types'
import { useSessionResizer } from './sessionPlacer.useSessionResizer'
import { getCalendarRepresentation } from './sessionPlacer.utils'

export const SessionBlockLogic: React.FC<{
	activityId: string
	entityId: string
	subentityId?: string
	title?: string
	description?: string
	start: Date
	offsetLeft?: number
	isDragged?: boolean
	isResized?: boolean
	actAsShadow?: boolean
	presentedDatesIndexes: number[]
	isPresented: boolean
	isSelected?: boolean
	isActive?: boolean
	dimensions: SessionPlacerDimensions
	container: HTMLDivElement
	end: Date
	vector: number[]
	onDeselect?: (e: any) => void
	onSelect?: (e: any) => void
	isRoutine: boolean
	selectionZoneId: ExtractOnlyExisting<ViewID, 'Plan' | 'Plan.Weekly'>
}> = ({
	activityId,
	isDragged,
	start,
	end,
	vector,
	title,
	description,
	dimensions,
	entityId,
	actAsShadow,
	presentedDatesIndexes,
	container,
	isResized,
	offsetLeft,
	isSelected,
	onDeselect,
	isRoutine,
	onSelect,
	isPresented,
	subentityId,
	isActive,
	selectionZoneId,
}) => {
		const [session, setSession] = useState({
			start,
			end,
		})
		const { handleDragStart } = useSessionDragStart(session, presentedDatesIndexes, vector, container, dimensions)
		const { handleResizingStarted } = useSessionResizer(session, presentedDatesIndexes, vector, container, dimensions)
		const activities = useActivities()
		const dispatch = useDispatch()
		// const { handleResizingStarted, resizingDirection } = useSessionResizer(
		// 	presentedDatesIndexes,
		// 	vector,
		// 	sessionId,
		// 	useCallback(
		// 		(direction, date) => {
		// 			setSession((s) => ({
		// 				...s,
		// 				[direction]: date,
		// 			}))
		// 		},
		// 		[setSession]
		// 	),
		// 	useCallback(
		// 		(direction, date) => {
		// 			dispatch(
		// 				sessionsActions.update({
		// 					id: sessionId,
		// 					[direction]: date.getTime(),
		// 				})
		// 			)
		// 		},
		// 		[dispatch, sessionId] //dispatch, sessionId
		// 	),
		// 	container,
		// 	dimensions
		// )

		const handleSelection = useCallback(
			(e: any) => {
				onSelect?.(e)
			},
			[onSelect]
		)

		const activity = useMemo(() => {
			return activities.at[activityId]
		}, [activities, activityId])

		useEffect(() => {
			setSession({
				start,
				end,
			})
		}, [start, end])

		const navigate = useNavigate()
		// const handleContextMenu = useCallback(
		// 	(e: any) => {
		// 		e.preventDefault()
		// 		navigate(`/calendar?plan=${sessionId}`)
		// 	},
		// 	[navigate, sessionId]
		// )

		const config = useMemo(
			() => ({
				representation: getCalendarRepresentation(vector, dimensions, presentedDatesIndexes, session.start, session.end),
				period: getSessionPeriod(session.start, session.end),
			}),
			[session, vector, dimensions, presentedDatesIndexes]
		)
		if (!activity) return null
		// if (!sessionId) return null

		return (
			<>
				{actAsShadow
					? config.representation.map((p, i) => {
						return (
							<SessionShadow
								key={'shadow' + entityId + i}
								color={activity?.color}
								split={p.split}
								offsetLeft={offsetLeft}
								initial={{
									height: p.height,
									y: p.y,
									x: p.x,
								}}
								animate={{
									height: p.height,
									y: p.y,
									x: p.x,
								}}
								transition={Transitions.opacityOnly}
								count={presentedDatesIndexes.length}
								column={getISODay(session.start) + i}
							/>
						)
					})
					: config.representation.map((p, i) => (
						<SessionBlock
							period={config.period}
							key={entityId + i}
							activity={activity}
							isRoutine={isRoutine}
							// sessionId={sessionId}
							isActive={isActive}
							offsetLeft={offsetLeft}
							isDragged={isDragged}
							startAt={p.startTime}
							subentityId={subentityId}
							endAt={p.endTime}
							isResized={isResized}
							title={title}
							description={description}
							isPresented={isPresented}
							minutesSpan={p.duration}
							isSelected={isSelected}
							// onContextMenu={handleContextMenu}
							onResizingStarted={handleResizingStarted}
							// resizingDirection={resizingDirection}
							onDeselect={onDeselect}
							onDragStart={handleDragStart}
							onSelect={handleSelection}
							selectionZoneId={selectionZoneId}
							split={p.split}
							height={p.height}
							entityId={entityId}
							y={p.y}
							width={dimensions.dayWidth || 0}
							x={p.x}
							count={presentedDatesIndexes.length}
						/>
					))}
			</>
		)
	}

const radius = '24px'
//motiov.div
const SessionShadow = styled(motion.div).attrs((p: any) => ({
	split: p.split,
	count: p.count,
	column: p.column,
	offsetLeft: p.offsetLeft,
}))`
	background-color: rgba(0, 0, 0, 0.033);
	padding: 10px 10px;
	box-sizing: border-box;
	border-radius: ${radius};
	top: 0;
	position: absolute;
	display: block;
	z-index: 3;
	border: none;
	left: ${(p) => p.offsetLeft}px;

	${(p) =>
		p.count &&
		css`
			width: calc((100% - 55px) / ${p.count} - 3px);
		`}

	${(p) => {
		if (p.split === 'bottom')
			return css`
				border-radius: ${radius} ${radius} 0 0;
			`
		if (p.split === 'top')
			return css`
				border-radius: 0 0 ${radius} ${radius};
			`
		if (p.split === 'both')
			return css`
				border-radius: 0 0 0 0;
			`
	}}
`
