import { Collection } from '../commonTypes';
import { SharingInvitationRuneObject } from './runes.types';

export type SharingInvitationData = SharingInvitationRuneObject['rune']

export type IState = {
    forActivity: {
        [id: string]: Collection<SharingInvitationData>
    }
}
export const State: IState = {
    forActivity: {}
}