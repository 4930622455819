class NavigationStack {
	private stack: string[]

	private maxLength: number

	constructor() {
		this.stack = []
		this.maxLength = 200
	}

	push(path: string): void {
		if (this.stack.length >= this.maxLength) {
			this.stack.shift()
		}
		this.stack.push(path)
	}

	pop(): string | null {
		return this.stack.pop() || null
	}

	peek(): string | null {
		return this.stack[this.stack.length - 1] || null
	}
}

const NavigationStackCore = new NavigationStack()
export default NavigationStackCore
