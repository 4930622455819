import { ActivityBubbleSize, ActivityImageSource } from '@store/slices/activitiesTypes';
import React from 'react';
import styled, { css } from 'styled-components';

export type IconName = keyof typeof ActivityImageSource

export const ActivityImage: React.FC<{
	name: IconName
	size?: ActivityBubbleSize
	icon?: boolean
	noOutset?: boolean
}> = ({ name, size = 'small', noOutset }) => {
	// const setCanvas = useCanvasImage(ICON[name])
	// const [canvas, setCanvas] = useRefTaker()
	// const id = useRef(NewID())
	// const { unmountCanvas, mountCanvas } = useImagesWorker()

	// useEffect(() => {
	// 	if (!id.current) return
	// 	const ID = id.current
	// 	mountCanvas(ID, canvas, `/images/icons/${name}.png`, {
	// 		width: 72,
	// 		height: 72,
	// 	})
	// 	return () => {
	// 		unmountCanvas(ID)
	// 	}
	// }, [canvas, name, mountCanvas, unmountCanvas])

	return (
		// <ImageOutset noOutset={noOutset} size={size}>
		<ImageHolder size={size} name={name} className={'activity-image'} />
	)
}

const ImageOutset = styled.div<{ size: ActivityBubbleSize; noOutset?: boolean }>`
	${({ size, noOutset }) => {
		if (noOutset) return css``
		switch (size) {
			case 'small':
				return css`
					margin: 0px;
					width: 100%;
					height: 100%;
				`
			case 'medium':
				return css`
					margin: -10px -10px -10px -10px;
				`
			case 'large':
				return css`
					margin: -10px -10px -10px -10px;
				`
		}
	}}
`

const ImageHolder = styled.div<{ size: ActivityBubbleSize; name: string }>`
	width: 50px;
	height: 50px;
	background-image: url('/images/icons/${(p) => p.name}.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;

	${({ size }) => {
		switch (size) {
			case 'small':
				return css`
					position: relative;
					width: 32px;
					height: 32px;
					top: 0px;
				`
			case 'medium':
				return css`
					width: 36px;
					height: 36px;
					position: relative;
					/* top: 2px; */
					top: 0px;
				`
			case 'large':
				return css`
					width: 56px;
					height: 56px;
					position: relative;
					/* top: 2px; */
				`
			case 'tiny':
				return css`
					width: 26px;
					height: 26px;
					position: relative;
					top: 0.5px;
				`
		}
	}}
`
