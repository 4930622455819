import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb';
import {
    BatchedSendablesStream, FetchActivitiesRequest, FetchSessionsByActivityRequest, SendableStream,
    StreamActivityRequest, StreamActivitySyncType
} from '@clepside/clepsidejs/lib/services/sync_v1_pb';
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb';
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Logger } from '@root/cores/logger';
import { Database } from '@root/database';
import { SyncGRPC } from '@root/grpc/grpcSync';
import { sub } from 'date-fns';
import { call, cancelled, put, takeEvery } from 'redux-saga/effects';

import { activitiesActions } from './activities';
import { syncActions } from './sync';
import { SyncCards } from './sync.cards';
import { SyncRoutines } from './sync.routines';
import { SyncSessions } from './sync.sessions';
import { SyncSharingInvitations } from './sync.sharingInvitations';
import {
    processBatchedSendablesStream, processResourceComprehensiveStream
} from './sync.tsx.handlers';
import { ActivityPacketObject, PacketObject } from './sync.tsx.packets.types';
import {
    ResourceComprehensiveStreamingConfig,
    getLastSyncedAt, getPacketObjectFromRemoteObject
} from './sync.types';
import { getSyncKeyFromTemplate, markSyncingAsStarted, reachSyncKeyTemplate } from './sync.utils';

export class SyncActivities {
    public static runeOrPacket = 'packet' as const

    public static type = PacketType.ACTIVITY as const

    static fromSendables(res: Sendable.AsObject[] | undefined): PacketObject<any>[] {
        return res?.map((r) => getPacketObjectFromRemoteObject(r?.packet?.activity))
            .filter((f): f is PacketObject<any> => f !== undefined) || []
    }


    static placeInStore(objects: ActivityPacketObject[], fromSync?: boolean) {
        return activitiesActions.store({ objects, fromSync })
    }

    static fromPacket(res: Packet.AsObject | undefined) {
        return getPacketObjectFromRemoteObject(res?.activity)
    }

    static database = Database.activityPackets

    static inTransaction(packet: ActivityPacketObject) {
        if (packet?.deleted?.is != true) {
            Database.criticalOps.add({
                id: packet.id + '.sessions',
                op: {
                    type: 'fetch-sessions-for-activity',
                    activityId: packet.id
                }
            })
            Database.criticalOps.add({
                id: packet.id + '.routines',
                op: {
                    type: 'stream-activity-routines',
                    activityId: packet.id
                }
            })
        }
    }
    
    static *afterPersist() {
        yield put(syncActions.doCriticalOps())
    }
    

    static otherDatabaseLocks = [Database.criticalOps]
}

const syncActivitiesSlice = createSlice({
    name: 'sync.activities',
    initialState: {},
    reducers: {
        fetchActivities: () => {},
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fetchSessionsForActivity: (_, {}: PayloadAction<{ activityId: string }>) => {},
        streamActivity: (_, {}: PayloadAction<{ activityId: string, fetchRoutines: boolean }>) => {},
    },
})


export const syncActivitiesActions = syncActivitiesSlice.actions

export const syncActivitiesSagas = {
    *fetchSessionsForActivity({ payload: { activityId } }: ReturnType<typeof syncActivitiesSlice.actions.fetchSessionsForActivity>): any {
        const req = new FetchSessionsByActivityRequest()
        
        
        const syncKey = getSyncKeyFromTemplate('Activities/<0>/Sessions', activityId)
        const lastSyncAt = yield getLastSyncedAt(syncKey)

        req.setSessionsByActivityLastFetchedAt(lastSyncAt)
        req.setActivityId(activityId)
        req.setPastTimestamp(sub(new Date(), { weeks: 2 }).getTime())

        
        yield markSyncingAsStarted(syncKey)
        try {
            const channel = yield SyncGRPC.constructSessionsByActivityStreamingChannel(req)
            const receiver = function* (response: BatchedSendablesStream) {
                yield call(processBatchedSendablesStream, response, syncKey, 'Activities/<0>/Sessions', SyncSessions)
            }
            yield takeEvery(channel, receiver)
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        }   
	},
	*fetchActivities({}: ReturnType<typeof syncActivitiesSlice.actions.fetchActivities>): any {
        const req = new FetchActivitiesRequest()
		
		const lastSyncAt = yield getLastSyncedAt('Activities')
        req.setLastFetchedAt(lastSyncAt)
        yield markSyncingAsStarted('Activities')

        try {
            const channel = yield SyncGRPC.constructActivitiesFetchingChannel(req)
            const receiver = function* (response: BatchedSendablesStream) {
                yield call(processBatchedSendablesStream, response, 'Activities', reachSyncKeyTemplate('Activities'), SyncActivities)
            }
			yield takeEvery(channel, receiver)
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        } finally {}
	},
	*streamActivity({ payload }: ReturnType<typeof syncActivitiesSlice.actions.streamActivity>): any {
        const request = new StreamActivityRequest()
		request.setActivityId(payload.activityId)

        const streamActivityResources: ResourceComprehensiveStreamingConfig<any>[] = []

        if (!payload.fetchRoutines) {
            request.setSyncType(StreamActivitySyncType.EVERYTHING_BUT_ROUTINES)
            streamActivityResources.push(new ResourceComprehensiveStreamingConfig(
                SyncSharingInvitations, 
                (num: number) => request.setInvitationsLastFetchedAt(num),
                'Activities/<0>/Invitations', 
                payload.activityId))
            streamActivityResources.push(new ResourceComprehensiveStreamingConfig(
                SyncCards,
                (num: number) => request.setCardsLastFetchedAt(num),
                'Activities/<0>/Cards',
                payload.activityId,
            ))
        } else {
            request.setSyncType(StreamActivitySyncType.ROUTINES_ONLY)
            streamActivityResources.push(new ResourceComprehensiveStreamingConfig(
                SyncRoutines,
                (num: number) => request.setRoutinesLastFetchedAt(num),
                'Activities/<0>/Routines',
                payload.activityId,
            ))
        }

        for (const resource of streamActivityResources) {
            const lastSyncedAt = yield getLastSyncedAt(resource.lmat_key)
            yield markSyncingAsStarted(resource.lmat_key)
            resource.prepareRequest(lastSyncedAt)
        }
		
        try {
            const channel = yield SyncGRPC.constructActivitiesStreamingChannel(request)
            const receiver = function* (response: SendableStream) {
                yield call(processResourceComprehensiveStream, response, 'Activities', streamActivityResources)
            }
			Logger.sync.debug(`[Activities]${payload.fetchRoutines ? '[Routines-only]' : '[Everything-bR]'} Streaming...`)
            try {
                yield takeEvery(channel, receiver)
            } finally {
                if (yield cancelled()) {
                    console.log('Saga was cancelled!');
                }
            }
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        }
	},
}