import styled from 'styled-components';

export const DynamicIcon: React.FC<{
	type: 'clepsydre' | 'pie'
	progress: number
}> = ({ progress, type }) => {
	if (type == 'clepsydre') {
		return (
			<Rect>
				<Fill progress={progress} />
			</Rect>
		)
	}

	return (
		<Circle>
			<CircleFill progress={progress} />
		</Circle>
	)
}

const CircleFill = styled.div<{ progress: number }>`
	margin: 1px;
	width: calc(100% - 2px);
	height: calc(100% - 2px);
	background: conic-gradient(#fff ${(p) => p.progress * 100}%, rgba(0, 0, 0, 0) 0 100%);
	position: relative;
	display: flex;
	justify-content: center;
	border-radius: 999px;
	align-items: center;
	overflow: hidden;
`

const Circle = styled.div`
	width: 11px;
	height: 11px;
	border-radius: 999px;
	border: 1px solid #fff;
`

const Rect = styled.div`
	width: 7px;
	height: 11px;
	border: 1px solid white;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`

const Fill = styled.div<{ progress: number }>`
	margin: 1px;
	width: calc(100% - 2px);
	height: calc((${(p) => p.progress} * (100% - 2px)));
	background-color: white;
	border-radius: 1px;
`
