import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { SendableStream, StreamRoutineRequest } from '@clepside/clepsidejs/lib/services/sync_v1_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Logger } from '@root/cores/logger'
import { Database } from '@root/database'
import { SyncGRPC } from '@root/grpc/grpcSync'
import { call } from 'redux-saga/effects'
import { takeEvery } from 'typed-redux-saga'
import { routinesActions } from './routines'
import { SyncCards } from './sync.cards'
import { SyncRoutineSchedules } from './sync.routineSchedules'
import { processResourceComprehensiveStream } from './sync.tsx.handlers'
import { PacketObject, RoutinePacketObject } from './sync.tsx.packets.types'
import { getLastSyncedAt, getPacketObjectFromRemoteObject, ResourceComprehensiveStreamingConfig } from './sync.types'
import { markSyncingAsStarted } from './sync.utils'

export class SyncRoutines {
    public static runeOrPacket = 'packet' as const

    public static type = PacketType.ROUTINE as const

    static fromSendables(res: Sendable.AsObject[] | undefined): PacketObject<any>[] {
        return res?.map((r) => getPacketObjectFromRemoteObject(r?.packet?.routine))
            .filter((f): f is PacketObject<any> => f !== undefined) || []
    }

    static placeInStore(objects: RoutinePacketObject[], fromSync?: boolean) {
        return routinesActions.normalizeAndStore({ 
            objects: objects as RoutinePacketObject[],
            fromSync
        })
    }

    static prepareForStorage(object: RoutinePacketObject): RoutinePacketObject {
        try {
            return {
                ...object,
            }
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    static fromPacket(res: Packet.AsObject | undefined) {
        return getPacketObjectFromRemoteObject(res?.routine)
    }

    static inTransaction(packet: RoutinePacketObject) {
        Database.criticalOps.add({
            id: packet.id + '.routines',
            op: {
                type: 'stream-routine',
                routineId: packet.id
            }
        })
    }

    static otherDatabaseLocks = [Database.criticalOps]
    
    static database = Database.routinePackets
}
const syncRoutinesSlice = createSlice({
    name: 'sync.routines',
    initialState: {},
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        streamRoutine:  (_, {}: PayloadAction<{ routineId: string }>) => {},
    },
})


export const syncRoutinesSagas = {
	*streamRoutine({ payload }: ReturnType<typeof syncRoutinesSlice.actions.streamRoutine>): any {
        const request = new StreamRoutineRequest()
		request.setRoutineId(payload.routineId)

        const streamRoutineResources: ResourceComprehensiveStreamingConfig<any>[] = []
        streamRoutineResources.push(new ResourceComprehensiveStreamingConfig(
            SyncRoutineSchedules,
            (num: number) => request.setSchedulesLastFetchedAt(num),
            'Routines/<0>/Schedules',
            payload.routineId,
        ))
        streamRoutineResources.push(new ResourceComprehensiveStreamingConfig(
            SyncCards,
            (num: number) => request.setCardsLastFetchedAt(num),
            'Routines/<0>/Cards',
            payload.routineId,
        ))

        for (const resource of streamRoutineResources) {
            const lastSyncedAt = yield getLastSyncedAt(resource.lmat_key)
            yield markSyncingAsStarted(resource.lmat_key)
            resource.prepareRequest(lastSyncedAt)
        }
		
        try {
            const channel = yield SyncGRPC.constructRoutinesStreamingChannel(request)
            const receiver = function* (response: SendableStream) {
                yield call(processResourceComprehensiveStream, response, 'Routines', streamRoutineResources)
            }
			Logger.sync.debug('[Routines] Streaming...')
            try {
                yield takeEvery(channel, receiver)
            } finally {
            }
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        }
	},
}

export const syncRoutinesActions = syncRoutinesSlice.actions

