import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@root/store';
import { useCards } from '@root/store/selectors/useCards';

export function useDraggedCard(cardId?: string) {
	const draggingContext = useSelector((state: RootState) => state.interface.dragging)
	const cards = useCards()
	const [element, setElement] = useState<Element | null>(null)

	useEffect(() => {
		if (draggingContext?.type == 'card-drag') {
			const node = document.querySelector(`.card[data-id="${draggingContext.cardId}"]`)
			setElement((e) => {
				if (e !== node) return node
				return e
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cards, draggingContext])

	return useMemo(() => {
		if (draggingContext?.type == 'card-drag') {
			return {
				context: {
					...draggingContext,
					element,
				},
				isDragged: cardId ? cardId == draggingContext.cardId : false,
				isResized: false,
			}
		} else if (draggingContext?.type == 'card-resize') {
			return {
				context: {
					...draggingContext,
					element,
				},
				isDragged: false,
				isResized: cardId ? cardId == draggingContext.cardId : false,
			}
		}
		return { isDragged: false, context: undefined }
	}, [element, draggingContext, cardId])
}
