import { FetchDone } from '@comps/complex/fetch.done';
import { FetchFail } from '@comps/complex/fetch.fail';
import { Fetcher } from '@comps/complex/fetcher';
import { Button } from '@comps/interactive/button';
import { WatcherButton } from '@comps/interactive/watcherButton';
import { Flex } from '@comps/layout/flex';
import { Inset } from '@comps/layout/inset';
import { Spacer } from '@comps/layout/space';
import { Logo } from '@comps/static/logo';
import { Text } from '@comps/typography/text';
import BackgroundImage from '@res/loginbg.jpg';
import { authActions } from '@root/store/slices/auth';
import { userInvitesActions } from '@root/store/slices/userInvites';
import { WatcherID } from '@root/store/slices/watchersTypes';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import styled, { useTheme } from 'styled-components';

export const Uninvited: React.FC = () => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const fetch = useCallback(
		(wid: WatcherID) => {
			dispatch(userInvitesActions.checkBeenInvited({ watcherId: wid }))
		},
		[dispatch]
	)

	const acceptInvitation = useCallback(
		(wid: WatcherID) => {
			dispatch(userInvitesActions.acceptInvitation({ watcherId: wid }))
		},
		[dispatch]
	)

	return (
		<Body>
			<Inset expand stretch padding="page">
				<Flex direction="column" width="100%" align="center" justify="space-between" minHeight="100%">
					<Flex direction="column">
						<Spacer size={20} />
						<Flex align="center" justify="flex-end">
							<Logo size={38} color={theme.backgrounds.clepside.r.hex} />
						</Flex>
					</Flex>
					<Flex direction="column" grow justify="center">
						<Fetcher onMount={fetch}>
							<FetchDone>
								<Flex direction="column" align="center" spacing={20}>
									<Text level="title70">Your Clepside invitation is here</Text>
									<Text level="body">Accept your invitation by clicking the button below:</Text>
									<WatcherButton
										color="accent"
										action={acceptInvitation}
										insetPadding="buttonSmall"
									>
										Accept
									</WatcherButton>
								</Flex>
							</FetchDone>
							<FetchFail for="no-invites">
								<Flex grow direction="column" justify="center" spacing={40}>
									<Text level="title70">We're still in BETA 🚀</Text>
									<Flex direction="column" maxWidth="420px" spacing={20}>
										<Text level="body">
											If you've got to see this it means you have not yet been
											invited to Clepside at this moment.
										</Text>
										<Text level="body">
											Since Clepside is still in BETA, we have to control our influx
											of users in order to provide a good experience for everyone.
										</Text>
										<Text level="body">
											We're onboarding more people on a daily basis and there are
											high chances of getting an invite by simply hanging with us on
											r/Clepside and by participating in our release journey. It
											will be worth it.
										</Text>
										<Text level="body">
											We build at an extremely fast pace. We take all the feedback
											to heart. Please keep an eye on us.
										</Text>
										<Flex direction="column">
											<Text level="body">The Clepside Team</Text>
										</Flex>
									</Flex>
									<Flex spacing={10}>
										<WatcherButton
											color="accent"
											insetPadding="buttonSmall"
											action={(watcherId) => {
												dispatch(
													userInvitesActions.checkBeenInvited({
														watcherId,
													})
												)
												navigate('/login')
											}}
										>
											Re-check for invite
										</WatcherButton>
										<Button
											color="textualAccent"
											onClick={() => {
												dispatch(authActions.logout())
												navigate('/login')
											}}
										>
											<Inset>Logout</Inset>
										</Button>
									</Flex>
								</Flex>
							</FetchFail>
							<FetchFail />
						</Fetcher>
					</Flex>
				</Flex>
			</Inset>
		</Body>
	)
}

const Body = styled.div`
	width: 100vw;
	height: 100vh;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	background-image: url('${BackgroundImage}');
	background-position: center center;
	background-size: cover;
	display: flex;
`
