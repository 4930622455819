import { Inset } from '@comps/layout/inset'
import { arrow, autoUpdate, FloatingArrow, offset, shift, useFloating } from '@floating-ui/react'
import React, { useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

export const Panel: React.FC<{
	children?: React.ReactNode
	anchor: HTMLDivElement
	passedIsOpen?: boolean
	placement?: 'left' | 'bottom' | 'right' | 'top'
	padding?: React.ComponentProps<typeof Inset>['padding']
}> = ({ children, anchor, placement, passedIsOpen = false, padding = 'tiny' }) => {
	const arrowRef = useRef(null)
	const [isOpen, setIsOpen] = useState(false)

	const { refs, isPositioned, floatingStyles, context } = useFloating({
		placement: placement,
		elements: {
			reference: anchor,
		},
		middleware: [
			shift({
				padding: 15,
			}),
			offset(20),
			arrow({
				element: arrowRef,
			}),
		],
		whileElementsMounted: autoUpdate,
	}) as any

	const radius = useMemo(() => {
		return 20
	}, [])

	return (
		<Dropdown radius={radius} show={isPositioned} ref={refs.setFloating} style={floatingStyles}>
			<FloatingArrow ref={arrowRef} context={context} />
			{children}
		</Dropdown>
	)
}

const Dropdown = styled.div<{ radius: number; show: boolean }>`
	& > svg {
		${(p) => p.theme.toast.background.r.css('fill')};
	}
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
	border-radius: ${(p) => p.radius}px;
	z-index: 100000000;
	${(p) => p.theme.standards.white.r.css('color')}
	${(p) => p.theme.toast.background.r.css('background-color')}
`
