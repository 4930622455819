import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';


interface StyledProps {
	grow?: boolean
	shrink?: boolean
	basis?: React.CSSProperties['flexBasis']
	align?: React.CSSProperties['alignItems']
	alignSelf?: React.CSSProperties['alignSelf']
	direction?: React.CSSProperties['flexDirection']
	justify?: React.CSSProperties['justifyContent']
	justifySelf?: React.CSSProperties['justifySelf']
	position?: React.CSSProperties['position']
	maxWidth?: React.CSSProperties['maxWidth']
	width?: React.CSSProperties['width']
	height?: React.CSSProperties['height']
	minWidth?: React.CSSProperties['minWidth']
	minHeight?: React.CSSProperties['minHeight']
	zStack?: boolean
}

interface Props extends StyledProps {
	children?: React.ReactNode
	spacing?: number
}

export const Flex: React.FC<Props> = ({ children, spacing, zStack, ...styledProps }) => {
	const zStackContent = useMemo<any>(() => {
		if (!zStack || !Array.isArray(children)) {
			return <Holder {...styledProps}>{children}</Holder>
		}

		return (
			<ZStackParent {...styledProps}>
				{' '}
				{children.map((child, index) => {
					return React.cloneElement(child, {
						style: {
							zIndex: index,
							position: index > 0 ? 'absolute' : 'relative',
							width: '100%',
							height: '100%',
						},
					})
				})}{' '}
			</ZStackParent>
		)
	}, [children, styledProps, zStack])

	const content = useMemo<any>(() => {
		return <Holder {...styledProps} spacing={spacing}>{children}</Holder>
	}, [styledProps, children, spacing])

	return zStack ? zStackContent : content
}

const ZStackParent = styled.div<StyledProps>`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
`

const Holder = styled.div<StyledProps & { spacing?: number }>`
	display: flex;
	justify-content: ${({ justify }) => justify || 'flex-start'};
	flex-direction: ${({ direction }) => direction || 'row'};
	align-items: ${({ align }) => align || 'flex-start'};
	flex-grow: ${({ grow }) => (grow ? 1 : 0)};
	flex-basis: ${({ basis }) => basis || 'initial'};
	flex-shrink: ${({ shrink }) => (shrink ? 1 : 0)};
	position: ${({ position }) => position || 'initial'};
	max-width: ${({ maxWidth }) => maxWidth || 'auto'};
	width: ${({ width }) => width || 'auto'};
	height: ${({ height }) => height || 'auto'};
	min-width: ${({ minWidth }) => minWidth || 'auto'};
	min-height: ${({ minHeight }) => minHeight || 'auto'};
	${({ alignSelf }) =>
		alignSelf &&
		css`
			align-self: ${alignSelf};
		`};
	${({ justifySelf }) =>
		justifySelf &&
		css`
			justify-self: ${justifySelf};
		`};

	${({ spacing }) =>
		spacing &&
		css`
			column-gap: ${spacing}px;
			row-gap: ${spacing}px;
		`};
`
