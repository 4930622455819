/* eslint-disable react-hooks/exhaustive-deps */
import { throttle } from '@utils/timers/throttle'
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { ViewID } from '../store/slices/interface.types'
import { UtilsListsGetIndexInBounds } from '../utils/lists'
import { useHotkey } from './useHotkey'

export function useListNavigator(
	scope: ViewID,
	items: string[],
	value?: string,
	onSelect?: (item: string) => void,
	onChange?: (index?: number) => void
) {
	const lastBlankEnterFilteringValue = useRef<string | undefined>(undefined)
	const enterThrottle = useRef(false)
	const [highlighted, setHighlighted] = useState<number | undefined>(undefined)
	const itemCount = useRef(0)
	useEffect(() => {
		itemCount.current = items?.length || 0
	}, [items])

	useEffect(() => {
		setHighlighted(undefined)
	}, [items])

	useEffect(() => {
		if (!value) return
		setHighlighted(undefined)
	}, [value])

	useEffect(() => {
		return () => {
			setHighlighted(undefined)
		}
	}, [])

	useEffect(() => {
		enterThrottle.current = false
	}, [highlighted])

	const enter = useCallback(() => {
		if (enterThrottle.current === false) {
			enterThrottle.current = true
			setTimeout(() => {
				enterThrottle.current = false
			}, 200)
			if (highlighted === undefined) {
				if (lastBlankEnterFilteringValue.current !== value) {
					lastBlankEnterFilteringValue.current = value
				}
				return
			}
			onSelect?.(items[highlighted])
		}
	}, [items, onSelect, value, highlighted])
	const previous = useCallback(
		throttle((e: any) => {
			e.preventDefault()
			setHighlighted((i) => {
				const newI = UtilsListsGetIndexInBounds.goingUp(itemCount.current, i)
				onChange?.(newI)
				return newI
			})
		}, 50),
		[setHighlighted, onChange]
	)
	const next = useCallback(
		throttle((e: any) => {
			e.preventDefault()
			setHighlighted((i) => {
				const newI = UtilsListsGetIndexInBounds.goingDown(itemCount.current, i)
				onChange?.(newI)
				return newI
			})
		}, 50),
		[setHighlighted, onChange]
	)

	useHotkey(scope, 'up', previous)
	useHotkey(scope, 'down', next)
	useHotkey(scope, 'enter', enter)

	const onMouseIn = useCallback<MouseEventHandler<HTMLButtonElement>>(
		(e) => {
			const element = e.currentTarget
			const list = element.parentNode
			const index = Array.prototype.indexOf.call(list?.children, element) / 2
			setHighlighted(index)
		},
		[setHighlighted]
	)
	const onMouseClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
		(e) => {
			e.stopPropagation()
			const element = e.currentTarget
			const list = element.parentNode
			const index = Array.prototype.indexOf.call(list?.children, element) / 2
			setHighlighted(index)
			onSelect?.(items[index])
		},
		[onSelect, items]
	)
	const onMouseOut = useCallback(() => {
		setHighlighted(undefined)
	}, [setHighlighted])
	return {
		onMouseIn,
		onMouseOut,
		onMouseClick,
		highlighted,
	}
}
