import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { sagas } from './sagas'
import { activitiesReducers } from './slices/activities'
import { aiChatCardReducers } from './slices/aiChatCard'
import { authReducers } from './slices/auth'
import { billingReducers } from './slices/billing'
import { boardsReducers } from './slices/boards'
// import { boardsReducers } from './slices/boards'
import { cardsReducers } from './slices/cards'
import { errorToastsReducers } from './slices/errorToasts'
import { flowsReducers } from './slices/flows'
import { fragmentsReducers } from './slices/fragments'
import { imagesReducers } from './slices/imagesStore'
import { interfaceReducers } from './slices/interface'
import { notificationsReducers } from './slices/notifications'
import { routinesReducers } from './slices/routines'
import { routinesSchedulesReducers } from './slices/routineSchedules'
import { selectionReducers } from './slices/selection'
import { sessionsReducers } from './slices/sessions'
import { sharingReducers } from './slices/sharing'
import { syncReducers } from './slices/sync'
// import { sessionsReducers } from './slices/sessions'
import { timeReducers } from './slices/time'
import { userInvitesReducers } from './slices/userInvites'
import { userProfilesReducers } from './slices/userProfiles'
// import { vitalsReducers } from './slices/vitals';
import { watchReducers } from './slices/watch'
import { watcherReducers } from './slices/watchers'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

export const store = configureStore({
	reducer: {
		watch: watchReducers,
		activities: activitiesReducers,
		sharing: sharingReducers,
		sessions: sessionsReducers,
		boards: boardsReducers,
		cards: cardsReducers,
		selection: selectionReducers,
		fragments: fragmentsReducers,
		// vitals: vitalsReducers,
		interface: interfaceReducers,
		routines: routinesReducers,
		routinesSchedules: routinesSchedulesReducers,
		images: imagesReducers,
		flows: flowsReducers,
		auth: authReducers,
		billing: billingReducers,
		sync: syncReducers,
		time: timeReducers,
		aiChatCard: aiChatCardReducers,
		notifications: notificationsReducers,
		userInvites: userInvitesReducers,
		userProfiles: userProfilesReducers,
		watchers: watcherReducers,
		errorToasts: errorToastsReducers,
	},
	middleware: (getDefaultMiddleware) => [
		...getDefaultMiddleware({
			thunk: false,
		}),
		...middlewares,
	],
	devTools:
		process.env.NODE_ENV !== 'production'
			? {
					actionsDenylist: 'time/secondChange',
					maxAge: 1000,
			  }
			: false,
})

try {
	sagaMiddleware.run(sagas)
} catch (e) {
	console.log('saga error', e)
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
