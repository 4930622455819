

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 } from 'uuid'
import { RootState } from '../'
import { ImageID, imagesState, ImageStateStatusType } from './imagesStore.types'

const imagesSlice = createSlice({
	name: 'images',
	initialState: imagesState,
	reducers: {
		presentImage(state, { payload: { imageId } }: PayloadAction<{ imageId: ImageID }>) {
			state.visibleImages.push(imageId)
		},
		dismissImage(state, { payload: { imageId } }: PayloadAction<{ imageId: ImageID }>) {
			state.visibleImages = state.visibleImages.filter((f => f != imageId))

			if (state.loadingState[imageId]) {
				delete state.loadingState[imageId]
			}
		},
		markImageAs(state, { payload: { imageId, status } }: PayloadAction<{ imageId: ImageID, status: ImageStateStatusType }>) {
			if (state.visibleImages.includes(imageId)) {
				state.loadingState[imageId] = {
					id: v4(),
					status,
				}
			}
		},
	},
})

export const imagesActions = imagesSlice.actions
export const imagesReducers = imagesSlice.reducer


export function useImageTracking(imageId: undefined | string) {
	const dispatch = useDispatch()

	useEffect(() => {
		if (imageId) {
			dispatch(imagesActions.presentImage({ imageId }))
			return () => {
				dispatch(imagesActions.dismissImage({ imageId }))
			}
		}
	}, [imageId, dispatch])

	const imagesStatus = useSelector((state: RootState) => state.images.loadingState)

	return useMemo(() => {
		if (imageId) {
			if (imagesStatus[imageId]) {
				return imagesStatus[imageId]
			}
		}
		return undefined
	}, [imagesStatus, imageId])
}