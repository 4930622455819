import { useCallback, useState } from 'react'
import isEmail from 'validator/lib/isEmail'
import isStrongPassword from 'validator/lib/isStrongPassword'
import zxcvbn from 'zxcvbn'
import { FormErrors } from './useForm'
import { FormState } from './useForm.types'

export function useFormValidation<C>() {
	const [errors, setErrors] = useState<FormErrors<C>>()

	const validate = useCallback(async (state: FormState<C>) => {
		state.all.forEach((k) => {
			const key = k as string
			state.at[k].errors = []
			const errArr = state.at[k].errors

			state.at[k].rules?.split(',').forEach((r) => {
				const value = state.at[k].value
				const [rule, param] = r.split(':')
				const intParam = parseInt(param)

				switch (rule) {
					case 'required':
						if (!value) {
							errArr.push(`${key} is required`)
						}
						return
					case 'min':
						if (!value?.length) {
							errArr.push(`${key} is required`)
							return
						}
						if (value.length < intParam) {
							errArr.push(`${key} should be min. ${intParam} characters.`)
						}
						return
					case 'email':
						if (!isEmail(value || '')) {
							errArr.push(`${key} doesn't really look right.`)
						}
						return
					case 'password':
						if (zxcvbn(value || '').score < 3) {
							errArr.push(
								'Use lowercase and uppercase letters, symbols and numbers. Please try to set a stronger password.'
							)
						}
						return
					case 'matches':
						if (value != state.at[param as keyof C].value) {
							errArr.push(
								'Password does not match'
							)
						}
						return
					case 'max':
						if (!((value?.length || 0) <= intParam == true)) {
							errArr.push(`${key} should be max. ${intParam} characters.`)
						}
						return
				}
			})
		})

		const errorCount = state.all.reduce((acc, k) => {
			console.log('Error: ', state.at[k].errors)
			return acc + state.at[k].errors.length
		}, 0)

		setErrors(
			state.all.reduce((acc, k) => {
				const errArr = state.at[k].errors[0]

				if (errArr) {
					acc[k] = errArr
				}

				return acc
			}, {} as any)
		)

		state.all.forEach((k) => {
			const node = state.at[k]?.node
			node?.classList?.remove('error')
		})

		if (errorCount === 0) {
			return true
		} else {
			// await sleep(16);

			// state.all.forEach(k => {
			//     const node = state.at[k].node
			//     const hasErrors = state.at[k].errors.length

			//     if (node && hasErrors) {
			//         node.classList.add('error')
			//     }
			// })

			state.all.reduce((acc, k) => {
				if (acc == true) {
					return true
				}

				const node = state.at[k].node
				const hasErrors = state.at[k].errors.length

				if (node && hasErrors) {
					state.at[k].node.focus()
					return true
				}

				return false
			}, false as any)

			return false
		}
	}, [])

	return { validate, errors }
}
