import { ExternalLink } from '@comps/externalLink'
import { CardStyle } from '@comps/layout/cardContainer'
import { SimplePage } from '@comps/layout/simplePage'
import { Spacer } from '@comps/layout/space'
import { Logo } from '@comps/static/logo'
import { Text } from '@comps/typography/text'
import { useFromApp } from '@root/hooks/useFromApp'
import { Medias } from '@root/medias'
import { Outlet } from 'react-router'
import styled, { css, useTheme } from 'styled-components'
import { LoginOutletContextType } from './Sessions.ID.useSessionOutletContext'

export const AccountMain: React.FC = () => {
	const theme = useTheme()
	const isFromAppState = useFromApp()
	const { isFromApp } = isFromAppState

	return (
		<SimplePage center>
			<PageHolder>
				<Image />
				<ContainerHolder>
					<ExternalLink href={isFromAppState ? undefined : `${process.env.REACT_APP_SITE_URL}`}>
						<LogoHolder>
							<Logo size={38} color={theme.backgrounds.clepside.r.hex} />
							<Spacer size={15} className="gone-on-mobile" />
							<LogoText level="title10" className="gone-on-mobile">Clepside</LogoText>
						</LogoHolder>
					</ExternalLink>
					<Container isFromApp={isFromApp}>
						<Outlet context={isFromAppState satisfies LoginOutletContextType} />
					</Container>
				</ContainerHolder>
			</PageHolder>
		</SimplePage>
	)
}

const LogoText = styled(Text)`
	${(p) => p.theme.text.regular.r.css('color')};
`

const LogoHolder = styled.div`
	display: flex;
	flex-direction: row;
	padding-left: 10px;
	margin-bottom: 20px;
	align-items: center;
	@media ${Medias.PHONE} {
		padding-left: 0;
		padding-bottom: 10px;

		.gone-on-mobile {
			opacity: 0;
		}
	}
`

const ContainerHolder = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`

const Container = styled.div<{ isFromApp: boolean }>`
	${CardStyle};
	min-height: 600px;
	display: flex;
	flex-direction: column;
	${(p) => p.theme.text.regular.r.css('color')};

	@media ${Medias.PHONE} {
		min-height: 0;
		width: 100%;
		box-shadow: none;
		align-items: flex-start;
		border-radius: 0;
		flex-grow: 1;
		overflow: visible;
	}

	${(p) => p.isFromApp && css`
		background-color: transparent;
	`};
`

const Image = styled.div`
	width: 500px;
	height: 500px;
	margin: 100px;
	margin-right: 100px;
	margin-left: 0px;
	border-radius: 60px;
	background-color: rgba(0, 0, 0, 0.2);

	@media ${Medias.PHONE} {
		display: none;
	}
`

const PageHolder = styled.div`
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	min-height: 100%;

	@media ${Medias.PHONE} {
		width: 100%;
		align-items: stretch;
		flex-direction: column;
		justify-content: flex-start;
	}
`

