import { Flex } from '@comps/layout/flex';
import { FontStyles, Text } from '@comps/typography/text';
import { getSessionPeriod } from '@root/utils/dates';
import { Direction } from '@root/utils/general';
import { formatDuration, intervalToDuration } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { SessionPlacerDimensions } from './sessionPlacer.types';
import { getCalendarRepresentation } from './sessionPlacer.utils';

type SessionHighlightButtonPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

export const SessionHighlight: React.FC<{
	vector: number[]
	dimensions: SessionPlacerDimensions
	presentedDatesIndexes: number[]
	start: Date
	buttonLabel: string
	end: Date
	direction: Direction
	buttonSide: React.MutableRefObject<'left' | 'right'>
	offsetLeft?: number
	goToPlan: () => void
}> = ({ vector, offsetLeft, start, buttonLabel, end, direction, dimensions, presentedDatesIndexes, goToPlan, buttonSide }) => {
	const array = getCalendarRepresentation(vector, dimensions, presentedDatesIndexes, start, end)
	const [hovered, setHovered] = useState(false)
	const [show, setShow] = useState(false)
	const [side, setSide] = useState<'left' | 'right'>('left')
	const timeout = useRef<any>(undefined)

	const handleButtonEnter = useCallback(() => {
		setHovered(true)
	}, [])

	const handleButtonLeave = useCallback(() => {
		setHovered(false)
	}, [])

	useEffect(() => {
		setSide((s) => {
			if (buttonSide.current !== s) {
				setShow(false)
				setHovered(false)
			}
			return s
		})
		if (timeout.current) clearTimeout(timeout.current)

		timeout.current = setTimeout(() => {
			setShow(true)
			setSide(buttonSide.current)
			timeout.current = null
		}, 16)
	}, [start, end, setSide, buttonSide, setShow])

	if (!start || !end) return null

	return (
		<>
			{array.map((s, i) => {
				let showFloater = false
				if (i == 0 && direction === 'backwards') showFloater = true
				if (i == array.length - 1 && direction === 'forward') showFloater = true

				return (
					<Highlighter
						key={'highlighter' + i}
						style={{
							top: `${s.y}px`,
							left: `${s.x + (offsetLeft || 0)}px`,
							height: `${s.height}px`,
							width: `${
								presentedDatesIndexes.length > 1
									? dimensions.dayWidth
									: dimensions.dayWidth - (offsetLeft || 0)
							}px`,
						}}
					>
						<Flex direction="column" align="center">
							<Text level="tiny" bold>
								{/* Added the and and */}
								{start && end && getSessionPeriod(start, end)}
							</Text>
							<Text level="tiny" bold>
								{start &&
									end &&
									formatDuration(
										intervalToDuration({
											start: start,
											end: end,
										})
									)}
							</Text>
						</Flex>

						{showFloater && (
							<Floater
								key={direction + side}
								show={show}
								buttonSide={side}
								direction={direction}
								isHovered={hovered}
								onMouseEnter={handleButtonEnter}
								onMouseLeave={handleButtonLeave}
								onMouseUp={goToPlan}
							>
								{buttonLabel}
							</Floater>
						)}
					</Highlighter>
				)
			})}
		</>
	)
}

const Floater = styled.div<{ show?: boolean; isHovered?: boolean; direction: Direction; buttonSide: 'left' | 'right' }>`
	position: absolute;
	display: block;
	${FontStyles.tiny};
	text-transform: uppercase;
	padding: 0 15px;
	border-radius: 12px;
	height: 25px;
	line-height: 20px;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
	border: 1px solid rgba(0, 0, 0, 0.1);
	${(p) => p.theme.text.regular.r.css('color')}
	${(p) => p.theme.backgrounds.normal.r.css('background-color')}
	z-index: 100;
	margin-top: -1px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transform: translateY(0);

	${(p) =>
		p.show &&
		css`
			opacity: 1;
			${(() => {
				switch (p.direction) {
					case 'backwards':
						return css`
							top: 0;
							transform: translateY(-50%);
						`
					case 'forward':
						return css`
							bottom: 0;
							transform: translateY(50%);
						`
				}
			})()}
		`}

	${(p) => {
		switch (p.buttonSide) {
			case 'left':
				return css`
					left: 30px;
				`
			case 'right':
				return css`
					right: 30px;
				`
		}
	}}

	${(p) =>
		p.isHovered &&
		css`
			border: 1px solid rgba(0, 0, 0, 0.1);
			${p.theme.backgrounds.accent.r.css('background-color')};
			${p.theme.standards.white.r.css('color')};
		`}
`

const Highlighter = styled.div`
	border-radius: 10px;
	position: absolute;
	padding: 10px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;

	${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
	${(p) => p.theme.text.regular.r.css('color')};
`
