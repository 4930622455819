import { useRefTaker } from '@hooks/useRefTaker'
import { useMotionValue } from 'framer-motion'
import { useEffect } from 'react'

export const useScrollProgress = (from: number, threshold: number) => {
	const progress = useMotionValue(0)
	const [ref, setRef] = useRefTaker()

	useEffect(() => {
		let target: any = window
		target = ref?.closest('#SCROLL-CONTAINER')
		if (!target) target = window

		const updateProgress = () => {
			const s = target == window ? target.scrollY : target.scrollTop

			if (s < from) {
				progress.set(0)
				return
			} else if (s >= from + threshold) {
				progress.set(1)
				return
			}

			const diff = s - from
			progress.set(diff / threshold)
		}

		target?.addEventListener('scroll', updateProgress)
		return () => {
			target?.removeEventListener('scroll', updateProgress)
		}
	}, [from, threshold, progress, ref])

	return { progress, setRef }
}
