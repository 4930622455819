import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Medias } from '@root/medias';
import { RootState } from '@root/store';

export const ErrorToastsStack: React.FC = () => {
    const notifications = useSelector((state: RootState) => state.errorToasts)

    return <NotificationsHolder>
    {
        notifications.all.map((t) => {
            const notification = notifications.at[t]
            return <Toast>{notification.message}</Toast>
        })
    }
    </NotificationsHolder>
}

const Toast = styled.div`
    background-color: #000;
    padding: 20px;
    border-radius: ${p => p.theme.statics.borderRadius.medium};
    width: 100%;
    box-sizing: border-box;
    color: #FFF;
    box-shadow: 0 3px 4px rgba(0,0,0,0.2); 
`
const NotificationsHolder = styled.div`
    display: flex;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 400px;
    box-sizing: border-box;

    @media ${Medias.PHONE} {
        width: calc(100% - 40px);
        right: auto;
        left: auto;
        margin-left: 20px;
        margin-right: 20px;
    }
`