import { Modal } from '@comps/modals/modal'
import { Logo } from '@comps/static/logo'
import { RootState } from '@root/store'
import { flowsActions } from '@root/store/slices/flows'
import { FlowID } from '@root/store/slices/flows.types'
import { sleep } from '@root/utils/general'
import { AnimatePresence, useAnimate, usePresence } from 'framer-motion'
import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { constructFlowPages, FlowCard } from './FlowCard'
import { FlowsCardHorizontalScroller } from './FlowsCard.HorizontalScroller'

export const FlowsController = () => {
	const selector = useSelector((state: RootState) => state.flows)
	const state = useRef<{ [id in FlowID]?: AnimationPhase }>({})
	const [scope, animate] = useAnimate()
	const [isPresent, safeToRemove] = usePresence()

	useEffect(() => {
		if (!safeToRemove) return

		if (!isPresent) {
			// eslint-disable-next-line @typescript-eslint/no-extra-semi
			; (async () => {
				setTimeout(() => {
					safeToRemove()
				}, 400)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPresent])

	const flow = useMemo(() => {
		const activeFlows = selector.activeFlows.map((id) => {
			let title = 'New Flow'
			const context = selector.flowContexts[id]

			switch (context?.flowType) {
				case 'create-or-edit-activity':
					title = context.id ? 'Edit Activity' : 'New Activity'
					break
				case 'routine-schedule-editing':
					title = 'Routine Schedule Editing'
					break
				case 'create-or-edit-board':
					title = context.id ? 'Edit Board' : 'New Board'
					break
				case 'plan-session':
					title = 'Plan Session'
					break
				case 'share':
					title = 'Activity Members'
					break
				case 'share-select-role':
					title = 'Select Role'
					break
			}

			const phases = constructFlowPages(id, title)
			if (!phases) throw new Error('failed to construct pages for this flow id')

			return {
				id,
				card: (
					<FlowCard key={id} className={id} phases={phases} flowId={id}>
						<FlowsCardHorizontalScroller phases={phases} flowId={id} />
					</FlowCard>
				),
			}
		})

		selector.activeFlows.forEach((id) => {
			if (!state.current[id]) state.current[id] = 'center-stage'
		})

		return activeFlows
	}, [selector])

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-extra-semi
		; (async () => {
			await sleep(5)
			for (const [i, { id }] of flow.entries()) {
				if (state.current[id] == 'transitioning') continue

				if (i == flow.length - 2 && state.current[id] !== 'back-stage-1') {
					state.current[id] = 'transitioning'
					await animate(`.${id}`, {
						scale: 0.85,
						opacity: 0.5,
						boxShadow: '0px 10px 25px rgba(20, 30, 40, 0.125)',
					})
					state.current[id] = 'back-stage-1'
				}
				if (i == flow.length - 1 && state.current[id] !== 'center-stage') {
					state.current[id] = 'transitioning'
					await animate(`.${id}`, {
						scale: 1,
						opacity: 1,
						backgroundColor: 'white',
						y: 0,
						boxShadow: '0px 20px 70px rgba(20, 30, 40, 0.2)',
					})
					state.current[id] = 'center-stage'
				}
			}
		})()
	}, [flow, animate])
	const dispatch = useDispatch()

	return (
		<Modal
			onBack={() => {
				dispatch(flowsActions.exitFlows())
			}}
		>
			<LogoHolder>
				<Logo size={32} />
			</LogoHolder>
			<Spanner>
				<Contents ref={scope}>
					<AnimatePresence>{selector.activeFlows.length ? flow.map((f) => f.card) : null}</AnimatePresence>
				</Contents>
			</Spanner>
		</Modal>
	)
}

export type AnimationPhase = 'center-stage' | 'back-stage-1' | 'back-stage-2' | 'transitioning'

const LogoHolder = styled.div`
	position: absolute;
	left: 50%;
	opacity: 0.2;
	top: 30px;
	transform: translateX(-50%);
`
const Spanner = styled.div`
	width: 100%;
	height: 100%;
`

const Contents = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`
