import { FieldsList } from '@comps/forms/fieldsList'
import { Label } from '@comps/forms/label'
import { TextInput } from '@comps/forms/textInput'
import { Button } from '@comps/interactive/button'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { Spacer } from '@comps/layout/space'
import { ActivityBubble } from '@comps/static/activityBubble'
import { Text } from '@comps/typography/text'
import { useCachedSelector } from '@root/hooks/useCachedSelector'
import { useRefTaker } from '@root/hooks/useRefTaker'
import { RootState } from '@root/store'
import { activitiesActions } from '@root/store/slices/activities'
import { ActivityColors } from '@root/store/slices/activities.colors.types'
import { flowsActions, flowsSpecialActionsPushView } from '@root/store/slices/flows'
import { NewID } from '@root/utils/general'
import { capitalizeFirstLetter } from '@root/utils/text'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { useFlowExit } from './useFlowExit'

export const FlowsActivityCreateUpdate: React.FC = () => {
	const [input, setInput] = useRefTaker()
	const context = useCachedSelector((state: RootState) => state.flows.flowContexts['create-or-edit-activity'])
	const isUpdating = context?.id
	const dispatch = useDispatch()
	const exit = useFlowExit()

	useEffect(() => {
		input?.focus()
	}, [input])

	const handleSubmit = useCallback(() => {
		if (!context) return

		if (context.id) {
			dispatch(
				activitiesActions.update({
					id: context.id,
					name: context.name,
					icon: context.icon,
					color: context.color,
				})
			)
			exit()
		} else {
			const newActivityId = NewID()
			dispatch(
				activitiesActions.new({
					id: newActivityId,
					name: context.name,
					icon: context.icon,
					color: context.color,
					wallpaper: 'currentColor',
				})
			)
			if (context.ancestor == 'plan-session') {
				dispatch(
					flowsActions.updateFlow({
						flowType: 'plan-session',
						createdActivityId: newActivityId,
					})
				)
			}
			dispatch(flowsActions.cancelFlow({ id: 'create-or-edit-activity' }))
		}
	}, [dispatch, context, exit])

	const handleCancel = useCallback(() => {
		dispatch(flowsActions.cancelFlow({ id: 'create-or-edit-activity' }))
	}, [dispatch])

	if (!context) return <span>No context available. Inform Support</span>

	return (
		<React.Fragment>
			<Preview>
				<Flex align="center" justify="center" grow width="100%">
					<ActivityBubbleOutset shouldPushLeftward={!!context?.name}>
						{context.icon ? (
							<ActivityBubble
								activity={{
									icon: context.icon || 'alarm',
									color: context.color || 'brilliantAzure',
								}}
								size="medium"
							/>
						) : null}
					</ActivityBubbleOutset>
					{context?.name && (
						<>
							<Spacer size={15} />
							<Text level="body" noWrap>
								{context.name}
							</Text>
						</>
					)}
				</Flex>
			</Preview>
			<Inset padding="tiny" align="stretch" direction="column">
				<FieldsList>
					<Label>Name</Label>
					<TextInput
						tabIndex={10}
						inputRef={setInput}
						grow
						onValueChange={(value) => {
							dispatch(
								flowsActions.updateFlow({
									flowType: 'create-or-edit-activity',
									name: value,
								})
							)
							// onNameChange(value)
						}}
						inputStyle="simple"
						defaultValue={context.name}
						placeholder="Enter a name"
						padding="medium"
						autoFocus
						parentHasNegativeMargins
					/>
					<Label>Icon</Label>
					<Button
						color="textualAccent"
						tabIndex={20}
						onClick={() => {
							dispatch(flowsSpecialActionsPushView('create-or-edit-activity', 'select-icon'))
						}}
					>
						<Inset padding="medium">
							<Flex align="center">
								{/* {context.icon && (
										<>
											<ActivityImage name={context.icon} />
											<Spacer size={10} />
										</>
									)} */}
								{context.icon ? capitalizeFirstLetter(context.icon) : 'Select an icon'}
							</Flex>
						</Inset>
					</Button>
					<Label>Color</Label>
					<Button
						color="textualAccent"
						onClick={() => {
							dispatch(flowsSpecialActionsPushView('create-or-edit-activity', 'select-color'))
						}}
					>
						<Inset padding="medium">
							<Flex align="center">
								{/* {context.color && (
										<>
											<ColorHolder>
												<ActivityColorBubble
													color={context.color}
													size="tiny"
												/>
											</ColorHolder>
											<Spacer size={10} />
										</>
									)} */}
								{context.color
									? ActivityColors[context.color]?.name || 'Select a color'
									: 'Select a color'}
							</Flex>
						</Inset>
					</Button>
				</FieldsList>
			</Inset>
			<Spacer vertical size={10} />
			<FooterGrid>
				<Button color="subtle" grow tabIndex={40} onClick={handleCancel}>
					<Inset padding="buttonMedium" align="center" justify="center">
						Cancel
					</Inset>
				</Button>
				<Button color="accent" grow tabIndex={40} cy={isUpdating ? 'update' : 'create'} onClick={handleSubmit}>
					<Inset padding="buttonMedium" align="center" justify="center">
						{isUpdating ? 'Update' : 'Create'}
					</Inset>
				</Button>
			</FooterGrid>
		</React.Fragment>
	)
}

const FooterGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
`

const Preview = styled.div`
	display: flex;
	padding: 0 30px;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
	height: 90px;
	border-radius: 13px;
	margin: -10px;
	margin-bottom: 10px;
`

const ActivityBubbleOutset = styled.div<{ shouldPushLeftward?: boolean }>`
	flex-shrink: 0;
	${(p) =>
		p.shouldPushLeftward &&
		css`
			margin-left: -5px;
		`}
`
