import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb';
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb';
import { Database, SessionPacketObjectWithDaystamps } from '@root/database';
import { getDaySpan } from '@root/utils/dates';

import { PacketObject, SessionPacketObject, SettingsPacketObject } from './sync.tsx.packets.types';
import {
    getPacketObjectFromRemoteObject
} from './sync.types';
import { settingsActions } from './settings';

export class SyncSettings {
    public static runeOrPacket = 'packet' as const

    public static type = PacketType.SETTINGS as const

    static fromSendables(res: Sendable.AsObject[] | undefined): PacketObject<any>[] {
        return res?.map((r) => getPacketObjectFromRemoteObject(r?.packet?.settings))
            .filter((f): f is PacketObject<any> => f !== undefined) || []
    }

    static fromPacket(res: Packet.AsObject | undefined) {
        return getPacketObjectFromRemoteObject(res?.settings)
    }
    
    static placeInStore(objects: SettingsPacketObject[], fromSync?: boolean) {
        return settingsActions.store({ object: objects[0], fromSync: fromSync })
    }

    static prepareForStorage(object: SettingsPacketObject): SettingsPacketObject {
        return {
            ...object
        }
    }


    static database = Database.settings
}