import React from 'react';
import styled, { css } from 'styled-components';

import { Nuance } from '@root/styled';
import {
    ActivityBubbleSize, ActivityColorState, ActivityData
} from '@store/slices/activitiesTypes';

import { ActivityColorBubble } from './activityColorBubble';
import { ActivityImage } from './activityImage';

export const ActivityBubble: React.FC<{
	size?: ActivityBubbleSize
	status?: ActivityColorState
	skeu?: boolean
	nuanceAttr?: keyof Nuance
	activity: Pick<ActivityData, 'icon' | 'color'>
}> = ({ size = 'small', activity, status, skeu = false, nuanceAttr }) => {
	return (
		<Holder skeu={skeu} className="activity-holder">
			<ActivityColorBubble status={status} color={activity.color} size={size} nuanceAttr={nuanceAttr} />
			<ImageHolder>
				<ActivityImage name={activity.icon} size={size} />
			</ImageHolder>
		</Holder>
	)
}

const Holder = styled.div<{ skeu?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;

	${(p) =>
		p.skeu &&
		css`
			.color-bubble {
				box-shadow: 0px 10px 7px rgba(0, 0, 0, 0.05), 0px 3px 3px 2px rgba(0, 0, 0, 0.05),
					inset 0px 1px 2px 0px rgba(255, 255, 255, 0.2);
				overflow: hidden;
				position: relative;

				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					display: block;
					opacity: 0.3;
					background: linear-gradient(176deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 80%);
				}
			}
		`}
`

const ImageHolder = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`
