import styled, { css } from 'styled-components';

import { Text } from '@comps/typography/text';

export const InfoLabel: React.FC<{
	label: string
	children: any
	rightAlign?: boolean
}> = ({ label, children, rightAlign }) => {
	return (
		<Holder rightAlign={rightAlign}>
			<Label level="tiny">{label}</Label>
			<Value>{children}</Value>
		</Holder>
	)
}

const Label = styled(Text)`
	text-transform: uppercase;
	${(p) => p.theme.text.darkTranslucencySubtle.r.css('color')};
`
const Value = styled.div`
	${(p) => p.theme.text.darkTranslucency.r.css('color')};
	margin-top: -4px;

	white-space: nowrap;
`
const Holder = styled.div<{ rightAlign?: boolean }>`
	display: flex;
	flex-direction: column;

	${(p) =>
		p.rightAlign &&
		css`
			justify-content: flex-end;

			${Value}, ${Label} {
				display: flex;
				justify-content: flex-end;
				text-align: right;
			}
		`}
`
