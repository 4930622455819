import { useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { Button } from '@comps/interactive/button';
import { Flex } from '@comps/layout/flex';
import { SimplePage } from '@comps/layout/simplePage';
import { Text } from '@comps/typography/text';

import { AuthHandlerEmailActivation } from './Account.AuthHandler.emailActivation';
import { AuthHandlerResetPassword } from './Account.AuthHandler.resetPassword';

export const AuthHandlerRouter: React.FC = () => {
	const [queryParams] = useSearchParams()
	const mode = useRef(queryParams.get('mode'))

	switch (mode.current) {
		case 'resetPassword':
			return <AuthHandlerResetPassword />
		// case 'recoverEmail':
		// 	return <span>Not implemented</span>
		case 'verifyEmail':
			return <AuthHandlerEmailActivation />
		default:
			return (
				<SimplePage center>
					<Flex grow align="center" justify="center" minHeight="100%" direction="column" spacing={30}>
						<Text level="title70">404. Page not found.</Text>
						<Text level="body" align="center">
							The page you're looking for does not exist.
						</Text>
						<Link to="/">
							<Button inset="buttonMedium" color="accent">
								Take me back
							</Button>
						</Link>
					</Flex>
				</SimplePage>
			)
	}
}
