import styled, { css } from 'styled-components';

export const CanvasBackground: React.FC = () => {
	// const setCanvas = useCanvasImage(wallpaper)
	// const { unmountCanvas, mountCanvas } = useImagesWorker()
	// const [canvas, setCanvas] = useRefTaker()
	// const [hasDrawn, setHasDrawn] = useState(false)

	// useEffect(() => {
	// 	if (!canvas) return
	// 	const { width, height } = canvas.getBoundingClientRect()
	// 	const success = mountCanvas(
	// 		'today-background',
	// 		canvas,
	// 		'/images/background.jpg',
	// 		{
	// 			width,
	// 			height,
	// 		},
	// 		() => {
	// 			setHasDrawn(true)
	// 		}
	// 	)
	// 	return () => {
	// 		unmountCanvas('today-background')
	// 	}
	// }, [canvas, mountCanvas, unmountCanvas])

	return <CanvasHolder>{/* <Canvas ref={setCanvas} hasDrawn={hasDrawn} /> */}</CanvasHolder>
}

const Canvas = styled.canvas<{ hasDrawn?: boolean }>`
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 0;
	transition: 0.6s ease opacity;
	transition-delay: 0.3s;
	will-change: opacity;

	${(p) =>
		p.hasDrawn &&
		css`
			opacity: 1;
		`}
`

const CanvasHolder = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: -1;
	background-size: cover;
	background-color: #000;
	background-image: url('/images/background.jpg');

	/* &:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-color: transparent;
		backdrop-filter: blur(50px);
	} */
`
