import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { TextInput } from '@comps/forms/textInput'
import { TextInputIconWrapper } from '@comps/forms/textInputIconWrapper'
import { Flex } from '@comps/layout/flex'
import { List } from '@comps/layout/list'
import { ListHolder } from '@comps/layout/listHolder'
import { Spacer } from '@comps/layout/space'
import { KeyScope } from '@comps/logical/keyScope'
import { ActivityColorBubble } from '@comps/static/activityColorBubble'
import { Text } from '@comps/typography/text'
import { useRefTaker } from '@hooks/useRefTaker'
import { ActivityColorKey, ActivityColors } from '@root/store/slices/activities.colors.types'
import { flowsActions } from '@root/store/slices/flows'

export const FlowsSelectColor: React.FC<{ forEntity: 'session' | 'activity' }> = ({ forEntity }) => {
	const [fieldRef, setFieldRef] = useRefTaker()
	const [filterValue, setFilterValue] = useState('')
	const dispatch = useDispatch()

	const colors = useMemo(() => {
		return Object.keys(ActivityColors) as Array<ActivityColorKey>
	}, [])

	const memoedListDictionary = useMemo(() => {
		return colors.reduce((acc, key) => {
			acc[key] = (
				<Flex align="center">
					<Spacer size={8} />
					<ActivityColorBubble color={key} size="tiny" />
					<Spacer size={15} />
					<Text>{ActivityColors[key].name}</Text>
				</Flex>
			)
			return acc
		}, {} as any)
	}, [colors])

	const filteredList = useMemo(() => {
		return colors.filter((key) => {
			let shouldBePresent = false
			ActivityColors[key].tags.forEach((keyword: string) => {
				if (keyword.includes(filterValue)) shouldBePresent = true
			})
			if (key.includes(filterValue)) shouldBePresent = true
			return shouldBePresent
		})
	}, [colors, filterValue])

	// useEffect(() => {
	// 	if (viewState === 'done') {
	// 		fieldRef?.focus()
	// 	}
	// }, [viewState, fieldRef])

	const renderItem = useCallback(
		(item: string) => {
			return memoedListDictionary[item]
		},
		[memoedListDictionary]
	)

	const handleSelection = useCallback(
		(item: string) => {
			dispatch(
				flowsActions.updateFlow({
					flowType: forEntity == 'activity' ? 'create-or-edit-activity' : 'create-or-edit-board',
					navigationStack: ['overview'],
					color: item as ActivityColorKey,
				})
			)
		},
		[dispatch, forEntity]
	)

	return (
		<React.Fragment>
			<KeyScope scope="Activities.NewActivity.SelectColor" />
			<TextInputIconWrapper icon="search">
				<TextInput
					hasIcon
					inputStyle="block"
					autoFocus
					inputRef={setFieldRef}
					placeholder="Filter through colors..."
					onValueChange={(value) => setFilterValue(value)}
				/>
			</TextInputIconWrapper>
			<ListHolder>
				<List
					scope="Activities.NewActivity.SelectColor"
					items={filteredList}
					renderItem={renderItem}
					borderOffsetLeft={42}
					borderOffsetRight={10}
					value={filterValue}
					onSelect={handleSelection}
					padding="medium"
				/>
			</ListHolder>
		</React.Fragment>
	)
}
