import { useMemo } from 'react'
import styled from 'styled-components'

import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { InstancedSessionData } from '@root/store/slices/sessionsTypes'
import { formatDate } from '@root/utils/dates'

import { AddCardMultiButton } from '../addCardMultiButton'

export const SessionHeader: React.FC<{ session: InstancedSessionData }> = ({ session }) => {
	const formatted = useMemo(() => {
		return {
			start: formatDate(session.start),
			end: formatDate(session.end),
		}
	}, [session])
	return <AddCardMultiButton resourceId={session.id} resourceType={GrantableResourceEnum.SESSION} />
}

// {/* <Spacer size={20} vertical /> */}
// {/* <Separator expand color="onDarkTranslucency" />
// <Spacer size={10} vertical />
// <Flex justify="space-between" align="center">
// 	<Flex>
// 		<Icon type="activity-board-medium" offsetY={1.5} />
// 		<Spacer size={10} />
// 		<b>Session board </b>&nbsp;has been last edited&nbsp;<b>23 days ago.</b>
// 	</Flex>
// </Flex> */}
// const SessionHeaderInfo = styled.div`
// 	padding: 15px 30px;
// 	border-radius: ${(p) => p.theme.statics.borderRadius.medium};
// 	backdrop-filter: blur(180px);
// 	position: sticky;
// 	top: 0px;
// 	${(p) => p.theme.backgrounds.darkTranslucency.r.css('background-color')};
// 	color: white;
// 	display: flex;
// 	box-sizing: border-box;
// 	flex-direction: column;
// 	align-items: stretch;
// 	justify-content: space-between;
// 	z-index: 10;
// 	${GridStyles};
// `

{
	/* <InfoLabel label="Date">
						<Flex>
							<Icon type="date" offsetY={4} marginRight={5} /> 
							<Text level="label">
								{formatted.start !== formatted.end ? (
									<span>
										{formatted.start}
										&nbsp;&nbsp;&middot;&nbsp;&nbsp;
										{formatted.end}
									</span>
								) : (
									formatted.start
								)}
							</Text>
						</Flex>
					</InfoLabel>
					<InfoLabel label="Period">
						<Text level="label">{getSessionPeriod(session.start, session.end)}</Text>
					</InfoLabel> */
}
