import { DraggingContext } from '@root/store/slices/interface.types'
import { isNotLeftClick } from '@root/utils/general'

export type DraggingPhase = 'start' | 'dragged' | 'interrupted' | 'finished'
export type DragDropListener = (type: DraggingPhase, context: DraggingContext, e?: MouseEvent) => void

type Point = {
	x: number
	y: number
}

function isInsideContainer(container: HTMLElement, point: Point): boolean {
	const rect = container.getBoundingClientRect()
	const scrollX = window.scrollX
	const scrollY = window.scrollY

	return point.x >= rect.left + scrollX && point.x <= rect.right + scrollX && point.y >= rect.top + scrollY && point.y <= rect.bottom + scrollY
}

type DragDropContainerConfig = {
	id: string
	priority: number
	container: HTMLElement
}

type ContainerSTATE = 'semi-active' | 'active' | 'inactive'

class DragDropCoreClass {
	private isDragging?: DraggingContext = undefined

	private listeners: {
		[zone: string]: Array<DragDropListener>
	} = {}

	private callbacks: {
		onAnyDragStart?: (context?: DraggingContext) => void
		onAnyDragEnd?: () => void
	} = {}

	private lastEvent: MouseEvent | undefined = undefined

	private handlers: undefined | any

	private globalCallbacksRegistered = false

	private stateForContainer: { [id: string]: ContainerSTATE } = {
		['document']: 'inactive',
	}

	private activeContainer: DragDropContainerConfig | undefined = undefined

	private containers: DragDropContainerConfig[] = [
		{
			id: 'document',
			priority: 0,
			container: document.body,
		},
	]

	setActiveContainer(container: DragDropContainerConfig, e: any) {
		if (this.activeContainer !== container) {
			if (this.activeContainer?.id) {
				this.stateForContainer[this.activeContainer.id] = 'inactive'
				this.forEachListener(this.activeContainer.id, (l: any) => {
					l('interrupted', this.isDragging, e)
				})
			}

			this.activeContainer = container
			this.stateForContainer[this.activeContainer.id] = 'active'

			this.forEachListener(this.activeContainer.id, (l: any) => {
				l('start', this.isDragging, e)
			})
		}
	}

	constructor() {
		this.handlers = {
			mousemove: (e: any) => {
				// we inform of the start...

				const point = { x: e.clientX, y: e.clientY }

				const highestContainer: {
					priority: number
					container: DragDropContainerConfig
				} = {
					priority: this.containers[0].priority,
					container: this.containers[0],
				}

				for (const container of this.containers) {
					if (isInsideContainer(container.container, point)) {
						if (highestContainer.priority < container.priority) {
							highestContainer.container = container
							highestContainer.priority = container.priority
						}
					}
				}

				this.setActiveContainer(highestContainer.container, e)
				// const point = { x: event.clientX, y: event.clientY };
				// if (isInsideContainer(container, point)) {
				//   console.log('Mouse is inside the container');
				// } else {
				//   console.log('Mouse is outside the container');
				// }
				if (this.activeContainer) {
					this.forEachListener(this.activeContainer.id, (l: any) => {
						l('dragged', this.isDragging, e)
					})
				}

				// const zone = this.getZone().id
				// console.log(zone)

				// this.forEachListener(zone, (l: any) => {
				// 	// if (!this.hasBeenInformedBefore[zone + l.toString()]) {
				// 	// 	this.hasBeenInformedBefore[zone + l.toString()] = true
				// 	// 	l('start', this.isDragging, e)
				// 	// }
				// 	l('dragged', this.isDragging, e)
				// })

				// if (this.pastZone !== zone) {
				// 	this.forEachListener(this.pastZone, (l: any) => {
				// 		delete this.hasBeenInformedBefore[this.pastZone + l.toString()]
				// 		if (this.lastEvent) {
				// 			l('interrupted', this.isDragging, this.lastEvent)
				// 		}
				// 	})
				// }

				// if (e) this.lastEvent = e
				// this.pastZone = zone
			},
			mouseup: (e: MouseEvent) => {
				if (!this.activeContainer?.id) return
				if (isNotLeftClick(e)) return
				this.forEachListener(this.activeContainer?.id, (l: any) => {
					l('finished', this.isDragging, e)
				})
				e.stopPropagation()
				this.callbacks.onAnyDragEnd?.()
				this.isDragging = undefined
				this.lastEvent = undefined
				document.removeEventListener('mousemove', this.handlers.mousemove)
				document.removeEventListener('mouseup', this.handlers.mouseup)
			},
		}
	}

	registerGlobalCallbacks(start: (context?: DraggingContext) => void, end: () => void) {
		if (this.globalCallbacksRegistered == false) {
			this.callbacks.onAnyDragStart = start
			this.callbacks.onAnyDragEnd = end
			this.globalCallbacksRegistered = true
		}
	}

	mountContainer(id: string, priority: number, container: HTMLElement) {
		if (this.containers.find((l) => l.id === id)) return () => { }
		this.containers.push({
			id,
			priority,
			container,
		})

		return () => {
			this.containers = this.containers.filter((c) => c.id !== id)
		}
	}

	addListener(forZone: string, callback: DragDropListener) {
		if (!this.listeners[forZone]) this.listeners[forZone] = []
		if (!this.listeners[forZone].includes(callback)) {
			this.listeners[forZone].push(callback)
		}

		return () => {
			this.listeners[forZone] = this.listeners[forZone].filter((cb) => cb !== callback)
		}
	}

	forEachListener(inZone: string, exe: any) {
		if (!this.listeners[inZone]) return
		for (const listener of this.listeners[inZone]) {
			exe(listener)
		}
	}

	startMoving(e: MouseEvent, context?: DraggingContext) {
		if (this.isDragging) return
		this.isDragging = context
		this.callbacks.onAnyDragStart?.(context)

		// Here
		const highestContainer: {
			priority: number
			container: DragDropContainerConfig
		} = {
			priority: this.containers[0].priority,
			container: this.containers[0],
		}

		const point = { x: e.clientX, y: e.clientY }

		for (const container of this.containers) {
			if (isInsideContainer(container.container, point)) {
				if (highestContainer.priority < container.priority) {
					highestContainer.container = container
					highestContainer.priority = container.priority
				}
			}
		}

		this.setActiveContainer(highestContainer.container, e)
		// const point = { x: event.clientX, y: event.clientY };
		// if (isInsideContainer(container, point)) {
		//   console.log('Mouse is inside the container');
		// } else {
		//   console.log('Mouse is outside the container');
		// }

		if (this.activeContainer)
			this.forEachListener(this.activeContainer.id, (l: any) => {
				l('start', this.isDragging, e)
			})

		// TIL heree--------------------------

		document.addEventListener('mousemove', this.handlers.mousemove)
		document.addEventListener('mouseup', this.handlers.mouseup)
	}
}

export const DragDropCore = new DragDropCoreClass()
