import { Button } from '@comps/interactive/button'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { Spacer } from '@comps/layout/space'
import { Logo } from '@comps/static/logo'
import { Text } from '@comps/typography/text'
import BackgroundImage from '@res/loginbg.jpg'
import { useBackToApp } from '@root/hooks/useBackToApp'
import { useFromApp } from '@root/hooks/useFromApp'
import { Medias } from '@root/medias'
import { useUser } from '@root/store/selectors/useUser'
import { authActions } from '@root/store/slices/auth'
import { interfaceActions } from '@root/store/slices/interface'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import styled, { css, useTheme } from 'styled-components'

export const ActivateEmail: React.FC<{ email?: string }> = ({ email }) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const user = useUser()
	const { isFromApp } = useFromApp()
	const backToButton = useBackToApp(isFromApp, 'Take me to Login')
	return (
		<Overlay>
			<Body>
				<Inset full padding="page">
					<Flex direction="column" width="100%" align="center" justify="space-between" minHeight="100%">
						{isFromApp ? null : <Flex direction="column">
							<Spacer size={20} />
							<Flex align="center" justify="flex-end">
								<Logo size={38} color={theme.backgrounds.clepside.r.p3} />
							</Flex>
						</Flex>}
						<Holder isFromApp={isFromApp}>
							<Text level="title70">We've sent you an activation email</Text>
							<Text level="largerParagraphBody" color={isFromApp ? 'proSubtleText' : undefined}>
								Please check your email found below and click the Clepside activation
								link. This allow us to confirm that the email belongs to you.
							</Text>
							<EmailBox isFromApp={isFromApp}>{user ? user.email : email}</EmailBox>
							{/* <Flex spacing={10}> */}
							{/* <Button color="accent">
									<Inset padding="buttonMedium">Resend activation mail</Inset>
								</Button> */}
							{user ? (
								<>
									{isFromApp ? backToButton :
										<Button
											color="subtle"
											onClick={() => {
												dispatch(authActions.logout())
												navigate('/login')
											}}
										>
											<Inset padding="buttonMedium">Logout</Inset>
										</Button>
									}
								</>
							) : (
								<Button
									color="subtle"
									onClick={() => {
										dispatch(
											interfaceActions.needsEmailActivation({
												email: undefined,
											})
										)
										navigate('/login')
									}}
								>
									<Inset padding="buttonMedium">Go to Login</Inset>
								</Button>
							)}
							{/* </Flex> */}
						</Holder>
					</Flex>
				</Inset>
			</Body>
		</Overlay>
	)
}

const Holder = styled.div<{ isFromApp?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	row-gap: 20px;

	${p => p.isFromApp && css`
		color: white;
	`}

	@media ${Medias.PHONE} {
		width: 100%;
		align-items: flex-start;
		justify-content: flex-start;
		padding-top: 50px;
		flex-grow: 1;
		row-gap: 20px;
	}
`

const EmailBox = styled.div<{ isFromApp?: boolean }>`
	padding: 20px;
	font-size: 32px;
	/* ${(p) => p.theme.standards.shade.s1.css('background-color')} */
	border-radius: 20px;
	word-break: break-all;
	line-height: 48px;


	@media ${Medias.PHONE} {
		font-size: 20px;
		line-height: 24px;
		font-weight: 600;
		letter-spacing: -0.2px;
	}
	${p => p.isFromApp && css`
		background-color: rgba(255, 255, 255, 0.075);
		letter-spacing: 0px !important;
	`}
`

const Body = styled.div`
	width: 100vw;
	height: 100vh;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	background-image: url('${BackgroundImage}');
	background-position: center center;
	background-size: cover;
	display: flex;

	@media ${Medias.PHONE} {
		background-image: none;
	}
`

const Overlay = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 100000000000000;
`
