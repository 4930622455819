import { useEffect, useRef } from 'react'
import { useTheme } from 'styled-components'

export const useDayNavigationBorderToggle = (containerRef: any) => {
	const holder = useRef<any>()
	const theme = useTheme()

	useEffect(() => {
		if (!containerRef) return
		let flipper = false
		const scrollFunction = () => {
			const scrollTop = containerRef.scrollTop
			if (!holder.current) return
			if (scrollTop <= 1 && flipper === false) {
				holder.current.style.borderBottomColor = 'transparent'
				flipper = true
			} else {
				holder.current.style.borderBottomColor = theme.border.subtle.r
				flipper = false
			}
		}
		containerRef.addEventListener('scroll', scrollFunction)
		return () => {
			containerRef.removeEventListener('scroll', scrollFunction)
		}
	}, [containerRef, theme])

	return holder
}
