import { selectionActions } from '@root/store/slices/selection'
import { SelectionItemContext } from '@root/store/slices/selection.types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { v4 } from 'uuid'

enum DraggableSize {
	TINY,
	SMALL,
	NORMAL,
}

export const DraggableSelectable: React.FC<{
	context: SelectionItemContext
	id: string
	onPointerDown?: any
	multi?: boolean
	isSelected: boolean
}> = ({ context, id, multi = false, onPointerDown, isSelected }) => {
	const dispatch = useDispatch()

	const handleSelection = useCallback(
		(e: any) => {
			e.preventDefault()
			e.stopPropagation()
			dispatch(
				selectionActions.select({
					ids: [context],
					append: multi,
					// scopeTemplate: 'Calendar',
					// scope: getDragZoneFromTemplate('Plan'),
				})
			)
		},
		[dispatch, context, multi]
	)

	const patternId = useRef(v4())
	const ref = useRef(null)
	const [size, setSize] = useState<DraggableSize | undefined>(undefined)

	// useEffect(() => {
	// 	const box = ref?.getBoundingClientRect()
	// 	if (box) {
	// 	}
	// }, [ref])

	useEffect(() => {
		const observeTarget = ref.current
		if (!observeTarget) return

		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				const height = entry.contentRect.height

				if (height < 65) {
					setSize(DraggableSize.TINY)
				} else if (height < 200) {
					setSize(DraggableSize.SMALL)
				} else {
					setSize(DraggableSize.NORMAL)
				}
			})
		})

		resizeObserver.observe(observeTarget)

		return () => {
			resizeObserver.unobserve(observeTarget)
		}
	}, [ref])

	return (
		<Holder onClick={handleSelection} isSelected={isSelected} ref={ref} size={size} onPointerDown={isSelected ? onPointerDown : undefined}>
			{size != undefined && (
				<>
					<div className="circle">
						<div className="filler" />
					</div>
					{size != DraggableSize.TINY && (
						<div className="drag-area">
							<svg xmlns="http://www.w3.org/2000/svg" className="pattern">
								<defs>
									<pattern id={patternId.current} patternUnits="userSpaceOnUse" width="36" height="36" patternTransform="scale(0.33)">
										<circle cx="2.5" cy="2.5" r="2.5" fill="currentColor" />
										<circle cx="2.5" cy="20.5" r="2.5" fill="currentColor" />
										<circle cx="11.5" cy="29.5" r="2.5" fill="currentColor" />
										<circle cx="11.5" cy="11.5" r="2.5" fill="currentColor" />
										<circle cx="20.5" cy="2.5" r="2.5" fill="currentColor" />
										<circle cx="20.5" cy="20.5" r="2.5" fill="currentColor" />
										<circle cx="29.5" cy="29.5" r="2.5" fill="currentColor" />
										<circle cx="29.5" cy="11.5" r="2.5" fill="currentColor" />
									</pattern>
								</defs>
								<rect fill={`url(#${patternId.current})`} width="100%" height="100%" />
							</svg>
						</div>
					)}
					{size == DraggableSize.NORMAL && (
						<div className="circle">
							<div className="filler" />
						</div>
					)}
				</>
			)}
		</Holder>
	)
}

const Holder = styled.div<{ isSelected?: boolean; size: DraggableSize | undefined }>`
	position: absolute;
	right: 0;
	top: 0;
	width: 46px;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 14px;
	box-sizing: border-box;
	flex-direction: column;
	z-index: 1000;
	justify-content: flex-start;

	&:hover {
		${(p) =>
		!p.isSelected &&
		css`
				.circle {
					background-color: currentColor;
				}
			`}
	}

	${(p) =>
		p.size == DraggableSize.TINY &&
		css`
			align-items: center;
			justify-content: center;
		`}

	${(p) =>
		p.isSelected &&
		css`
			cursor: grab !important;

			* {
				cursor: grab !important;
			}
		`}

	.drag-area {
		align-self: stretch;
		/* flex-grow: 1; */
		/* flex-shrink: 1; */
		width: auto;
		flex-basis: 0;
		margin: 10px 5px;
		position: relative;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		height: auto;
		transform: 0.3s ease opacity;
		opacity: 0 !important;

		${(p) =>
		p.isSelected &&
		css`
				opacity: 1 !important;
			`}

		${(p) =>
		p.size == DraggableSize.SMALL &&
		css`
				margin-bottom: 0;
			`}

		svg {
			display: block;
			flex-grow: 1;
			flex-basis: 0;
			width: auto;
			height: auto;
		}
	}
	.filler {
		width: 10px;
		height: 10px;
		opacity: 0;
		margin-left: -5px;
		margin-top: -5px;
		background-color: currentColor;
		position: absolute;
		top: 50%;
		overflow: hidden;
		backface-visibility: hidden;
		left: 50%;
		transition: transform 0.2s ease, opacity 0.2s ease;
		border-radius: 9999px;
		transform: scale(0.2);
	}

	.circle {
		width: 14px;
		height: 14px;
		border-radius: 999px;
		position: relative;
		border: 1px solid currentColor;
		flex-shrink: 0;
	}

	.circle,
	.drag-area {
		opacity: 0.15;
	}

	${(p) =>
		p.isSelected &&
		css`
			.circle,
			.drag-area {
				opacity: 1;
			}

			.filler {
				transform: scale(1);
				opacity: 1;
			}
		`}
`
