import { CardLayoutObject } from '@root/store/slices/cards.types'
import { clamp } from '@root/utils/general'

import { findBoundaries, gridCellHeight, gridPadding } from './cardGrid.commons'
import { CardID, CardSize, CardSizeInPixels, Matrix } from './cardGrid.types'

export type Size = {
	width: number
	height: number
}
export type Position = {
	y: number
	x: number
}

export type CardPosition = {
	x: number
	y: number
}

export const cardLayoutToCells = (
	op: 'drag' | 'resize',
	x: number,
	y: number,
	size: CardSizeInPixels,
	gridCellWidth: number,
	topup?: boolean
): CardPosition & CardSize => {
	const topCell = Math.round(y / (gridCellHeight + gridPadding))
	const leftCell = Math.round(x / (gridCellWidth + gridPadding))
	const funct = topup ? Math.ceil : Math.round

	const h = funct(size.hPx / (gridCellHeight + gridPadding))
	const w = funct(size.wPx / (gridCellWidth + gridPadding))

	let width = w
	let left = leftCell

	if (left < 0) {
		left = 0
	}

	if (left + w > 12) {
		if (op == 'drag') {
			left = 12 - w
		}
		if (op == 'resize') {
			width = 12 - left
		}
	}

	return {
		y: clamp(topCell, Number.MAX_SAFE_INTEGER),
		x: left,
		w: width,
		h,
	}
}

export const cellsString = (arr: [number, number]) => {
	return `${arr[0]}:${arr[1]}`
}

export const getLayoutKey = (l: CardLayoutObject) => {
	return `${l.x}.${l.y}.${l.w}.${l.h}`
}

export const getCardsUnder = (
	m: Matrix,
	r: CardLayoutObject
): {
	stack: Array<{
		cardId: string
		rect: CardLayoutObject
	}>
} => {
	const cards: {
		[cardId: string]: {
			cardId: string
			rect: CardLayoutObject
		}
	} = {}

	for (let i = r.y; i < r.y + r.h && i < m.length; i++) {
		for (let j = r.x; j < r.x + r.w && j < m[0].length; j++) {
			if (m[i][j] && !cards[m[i][j]]) {
				const layout = findBoundaries(m, i, j)
				if (!layout) continue

				cards[m[i][j]] = {
					cardId: m[i][j],
					rect: {
						x: layout.x,
						y: layout.y,
						w: layout.w,
						h: layout.h,
					},
				}
			}
		}
	}

	return {
		stack: Object.keys(cards).map((c) => cards[c]),
	}
}

export const removeDraggedCard = (m: Matrix, cardId: CardID) => {
	for (let i = 0; i < m.length; i++) {
		for (let j = 0; j < m[0].length; j++) {
			if (m[i][j] === cardId) {
				m[i][j] = ''
			}
		}
	}
}

export const removeCardAndCardsUnder = (m: Matrix, stack: { cardId: CardID }[], cardId: CardID) => {
	const stackIds = stack.map((c) => c.cardId)
	for (let i = 0; i < m.length; i++) {
		for (let j = 0; j < m[0].length; j++) {
			if (stackIds.includes(m[i][j]) || m[i][j] === cardId) {
				m[i][j] = ''
			}
		}
	}
}
