import { addMinutes, compareAsc, differenceInMinutes, isAfter, isBefore, isWithinInterval } from 'date-fns'

type DateInterval = [Date, Date];

export class TimeIntervals {
    private static binarySearchIndex(target: Date, intervals: DateInterval[], matchClosedInterval: boolean): number {
        let low = 0;
        let high = intervals.length - 1;
        while (low <= high) {
            const mid = low + Math.floor((high - low) / 2);
            const [start, end] = intervals[mid];

            if (matchClosedInterval) {
                if (compareAsc(start, target) <= 0 && compareAsc(target, end) <= 0) {
                    return mid;
                }
            } else {
                if (compareAsc(start, target) < 0 && compareAsc(target, end) < 0) {
                    return mid;
                }
            }

            if (compareAsc(start, target) > 0) {
                high = mid - 1;
            } else {
                low = mid + 1;
            }
        }
        return -1;
    }

    static intervalFitsInside(f: Date[], t: Date[]): boolean {
      if (isAfter(f[0], t[0]) && isBefore(f[1], t[1])) {
        return true;
      }
      return false;
    }
  
    static findClosestFeasibleInterval(intervals: DateInterval[], start: Date, end: Date): DateInterval | null {
      const durationInMinutes = Math.floor(differenceInMinutes(end, start));
  
      const idealFit: DateInterval[] = [
          [start, end]
      ];
  
      const potentialStartFits = intervals.map((interval): DateInterval => {
          return [interval[0], addMinutes(interval[0], durationInMinutes)];
      });
  
      const potentialEndFits = intervals.map((interval): DateInterval => {
          return [addMinutes(interval[1], -durationInMinutes), interval[1]];
      });
  
      const allPotentialFits = [...idealFit, ...potentialStartFits, ...potentialEndFits];
      
      const feasibleFits = allPotentialFits.filter(potential => 
          intervals.some(interval => isWithinInterval(potential[0], { start: interval[0], end: interval[1] }) && isWithinInterval(potential[1], { start: interval[0], end: interval[1] }))
      );
  
      if (feasibleFits.length === 0) {
          return null;
      }
  
      feasibleFits.sort((a, b) => {
          const diffA = Math.abs(differenceInMinutes(a[0], start));
          const diffB = Math.abs(differenceInMinutes(b[0], start));
          return diffA - diffB;
      });
  
      return feasibleFits[0];
  }

    private static wiggleBinarySearchIndex(target: Date, intervals: DateInterval[], matchClosedInterval: boolean, wiggleMinutes = 60): number {
        let low = 0;
        let high = intervals.length - 1;
        
        while (low <= high) {
            const mid = low + Math.floor((high - low) / 2);
            const [start, end] = intervals[mid];

            const startWithWiggle = addMinutes(start, -wiggleMinutes);
            const endWithWiggle = addMinutes(end, wiggleMinutes);

            if (
                (compareAsc(start, target) <= 0 && compareAsc(target, end) <= 0) ||
                (!matchClosedInterval && compareAsc(start, target) < 0 && compareAsc(target, end) < 0)
            ) {
                return mid;
            } else if (compareAsc(startWithWiggle, target) <= 0 && compareAsc(target, endWithWiggle) <= 0) {
                if (low === mid || high === mid || 
                    (compareAsc(target, intervals[mid - 1][1]) >= 0 && compareAsc(target, intervals[mid + 1][0]) <= 0)) {
                    return mid;
                }
            }

            if (compareAsc(end, target) < 0) {
                low = mid + 1;
            } else {
                high = mid - 1;
            }
        }

        return -1;
    }

    static binarySearch(target: Date, intervals: DateInterval[], matchClosedInterval: boolean, wiggleMinutes?: number): DateInterval | null {
        const index = wiggleMinutes !== undefined
            ? this.wiggleBinarySearchIndex(target, intervals, matchClosedInterval, wiggleMinutes)
            : this.binarySearchIndex(target, intervals, matchClosedInterval);
        if (index !== -1) {
            return intervals[index];
        }
        return null;
    }

    static returnIntervalsIncluding(freeIntervals: DateInterval[], interval: DateInterval): DateInterval[] {
        const intervals = [...freeIntervals, interval];
        intervals.sort((a, b) => compareAsc(a[0], b[0]));

        const mergedIntervals: DateInterval[] = [];

        for (const current of intervals) {
            if (mergedIntervals.length === 0 || compareAsc(mergedIntervals[mergedIntervals.length - 1][1], current[0]) < 0) {
                mergedIntervals.push(current);
            } else {
                const latestEnd = new Date(Math.max(mergedIntervals[mergedIntervals.length - 1][1].getTime(), current[1].getTime()));
                mergedIntervals[mergedIntervals.length - 1][1] = latestEnd;
            }
        }

        return mergedIntervals;
    }

    static dateFitsInside(date: Date, interval: DateInterval): boolean {
        return compareAsc(interval[0], date) <= 0 && compareAsc(date, interval[1]) <= 0;
    }

    static intersectIntervals(interval1: DateInterval, interval2: DateInterval): DateInterval | null {
        const startMax = new Date(Math.max(interval1[0].getTime(), interval2[0].getTime()));
        const endMin = new Date(Math.min(interval1[1].getTime(), interval2[1].getTime()));

        if (compareAsc(startMax, endMin) < 0) {
            return [startMax, endMin];
        } else {
            return null;
        }
    }

    static returnIntervalsExcluding(freeIntervals: DateInterval[], interval: DateInterval): DateInterval[] {
        const resultIntervals: DateInterval[] = []
        
        for (const currentInterval of freeIntervals) {
          if (isBefore(currentInterval[1], interval[0]) || isAfter(currentInterval[0], interval[1])) {
            resultIntervals.push(currentInterval)
            continue
          }
    
          if (isBefore(currentInterval[0], interval[0])) {
            resultIntervals.push([currentInterval[0], interval[0]])
          }
    
          if (isAfter(currentInterval[1], interval[1])) {
            resultIntervals.push([interval[1], currentInterval[1]])
          }
        }
        
        return resultIntervals
      }

      static returnOverlappingIntervals(intervals: DateInterval[], newInterval: DateInterval): DateInterval[] {
        const output: DateInterval[] = [];
        let i = 0;
    
        while (i < intervals.length) {
          const currentInterval = intervals[i];
          
          // Check if there is an overlap
          if (currentInterval[1] >= newInterval[0] && currentInterval[0] <= newInterval[1]) {
            
            // Compute the intersection of the current interval with the new interval
            const overlap: DateInterval = [new Date(Math.max(currentInterval[0].getTime(), newInterval[0].getTime())), 
                           new Date(Math.min(currentInterval[1].getTime(), newInterval[1].getTime()))];
            
            output.push(overlap);
          }
    
          // If the current interval is already ahead of the new interval, no need to check the remaining intervals
          if (currentInterval[0] > newInterval[1])
            break;
            
          i++;
          
        }
        
        return output
    }
    // static getFreeIntervals(sessions: Session[], datesArray: UTCDateInRegion[]): UTCDateInRegionInterval[] {
    //     const freeIntervals: UTCDateInRegionInterval[] = [];
    //     const sortedSessions = sessions.sort((a, b) => Number(a.utcStart) - Number(b.utcEnd));
        
    //     if (datesArray.length === 0) return [];
    //     let currentStart = startOfDay(datesArray[0]);
    //     const overallEnd = endOfDay(datesArray[datesArray.length - 1]);

    //     if (sessions.length === 0) {
    //         return [[currentStart, overallEnd]];
    //     }
        
    //     for (const s of sortedSessions) {
    //         if (s.utcStart.valueOf() > currentStart.valueOf()) {
    //             freeIntervals.push([currentStart, s.utcStart]);
    //         }
    //         currentStart = s.utcEnd;
    //     }
        
    //     if (currentStart.valueOf() < overallEnd.valueOf()) {
    //         freeIntervals.push([currentStart, overallEnd]);
    //     }
        
    //     return freeIntervals;
    // }
}