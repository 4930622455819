import { GrantableResourceEnum, GrantTypeEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { AcceptSharingInvitationRequest, CreateSharingInvitationRequest } from '@clepside/clepsidejs/lib/services/maestro_v1_pb'
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MaestroGRPC } from '@root/grpc/grpcMaestro'
import { collectionInsert } from '@root/utils/general'
import { put } from 'typed-redux-saga'
import { SharingInvitationData, State } from './sharing.types'
import { syncActivitiesActions } from './sync.activities'
import { userProfilesActions } from './userProfiles'
import { watcherActions } from './watchers'
import { WatcherID } from './watchersTypes'

const sharingSlice = createSlice({
	name: 'sharing',
	initialState: State,
	reducers: {
		store: (state, { payload }: PayloadAction<{ objects: SharingInvitationData[], fromSync?: boolean }>) => {
			payload.objects.forEach((obj) => {
				if (!obj)
					return
				const { resourceId, resourceType, id } = obj
				if (resourceType === GrantableResourceEnum.ACTIVITY) {
					if (!state.forActivity[resourceId]) {
						state.forActivity[resourceId] = { all: [], at: {} }
					}
					collectionInsert(state.forActivity[resourceId], id, obj)
				} else {
					// throw new Error('Wooops!')
				}
				
			})
		},
		createSharingInvitation: (state, { payload }: PayloadAction<{ watcherId: WatcherID, email: string, resourceId: string, role: GrantTypeEnum }>) => {},
		acceptSharingInvitation: (state, { payload }: PayloadAction<{ watcherId: WatcherID, invitationId: string }>) => {},
	},
})

export const sharingSagas = {
	*store({ payload }: ReturnType<typeof sharingSlice.actions.store>) {
		for (const object of payload.objects) {
			if (!object) continue
			if (object.forUserId !== 'clepside') {
				yield put(userProfilesActions.loadProfileIntoStore({ uuid: object.forUserId }))
			}

			if (object.forUserId !== 'clepside') {
				yield put(userProfilesActions.loadProfileIntoStore({ uuid: object.fromUserId }))
			}
		}
	},
	*createSharingInvitation({ payload }: ReturnType<typeof sharingSlice.actions.createSharingInvitation>) {
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))
        
        const request = new CreateSharingInvitationRequest()
        request.setEmail(payload.email)
		request.setResourceId(payload.resourceId)
		request.setEntity(GrantableResourceEnum.ACTIVITY)
		request.setRole(payload.role)
        
		try {
            yield MaestroGRPC.invite(request)
			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
		} catch (e) { 
			console.error(e)
			yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
		}
	},
	*acceptSharingInvitation({ payload }: ReturnType<typeof sharingSlice.actions.acceptSharingInvitation>): any {
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))
        
        const request = new AcceptSharingInvitationRequest()
        request.setInvitationId(payload.invitationId)
		        
		try {
            const response = yield MaestroGRPC.acceptInvite(request)
			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
			yield put(syncActivitiesActions.fetchActivities())
		} catch (e) { 
			console.error('Accepting the invitation failed', e)
			yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
		}
	},
}

export const sharingActions = sharingSlice.actions
export const sharingReducers = sharingSlice.reducer