// source: packets_v1/routine_schedule.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var entities_access_grant_v1_pb = require('../entities/access_grant_v1_pb.js');
goog.object.extend(proto, entities_access_grant_v1_pb);
var commons_core_pb = require('../commons/core_pb.js');
goog.object.extend(proto, commons_core_pb);
goog.exportSymbol('proto.packets_v1.DailyRoutineSchedulePeriod', null, global);
goog.exportSymbol('proto.packets_v1.MarshalledRoutineSchedulePacket', null, global);
goog.exportSymbol('proto.packets_v1.MarshalledRoutineSchedulePacketData', null, global);
goog.exportSymbol('proto.packets_v1.MonthlyRoutineSchedulePeriod', null, global);
goog.exportSymbol('proto.packets_v1.MonthlyRoutineSchedulePeriodDates', null, global);
goog.exportSymbol('proto.packets_v1.MonthlyRoutineSchedulePeriodType', null, global);
goog.exportSymbol('proto.packets_v1.MonthlyRoutineSchedulePeriodType.TypeCase', null, global);
goog.exportSymbol('proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday', null, global);
goog.exportSymbol('proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays', null, global);
goog.exportSymbol('proto.packets_v1.RoutineSchedulePacket', null, global);
goog.exportSymbol('proto.packets_v1.RoutineSchedulePacketData', null, global);
goog.exportSymbol('proto.packets_v1.RoutineSchedulePacketMeta', null, global);
goog.exportSymbol('proto.packets_v1.RoutineSchedulePeriod', null, global);
goog.exportSymbol('proto.packets_v1.RoutineSchedulePeriod.TypeCase', null, global);
goog.exportSymbol('proto.packets_v1.RoutineScheduleTime', null, global);
goog.exportSymbol('proto.packets_v1.WeekdayName', null, global);
goog.exportSymbol('proto.packets_v1.WeeklyRoutineSchedulePeriod', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.DailyRoutineSchedulePeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.DailyRoutineSchedulePeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.DailyRoutineSchedulePeriod.displayName = 'proto.packets_v1.DailyRoutineSchedulePeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.packets_v1.WeeklyRoutineSchedulePeriod.repeatedFields_, null);
};
goog.inherits(proto.packets_v1.WeeklyRoutineSchedulePeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.WeeklyRoutineSchedulePeriod.displayName = 'proto.packets_v1.WeeklyRoutineSchedulePeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.displayName = 'proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.repeatedFields_, null);
};
goog.inherits(proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.displayName = 'proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodDates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.packets_v1.MonthlyRoutineSchedulePeriodDates.repeatedFields_, null);
};
goog.inherits(proto.packets_v1.MonthlyRoutineSchedulePeriodDates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.MonthlyRoutineSchedulePeriodDates.displayName = 'proto.packets_v1.MonthlyRoutineSchedulePeriodDates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.packets_v1.MonthlyRoutineSchedulePeriodType.oneofGroups_);
};
goog.inherits(proto.packets_v1.MonthlyRoutineSchedulePeriodType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.MonthlyRoutineSchedulePeriodType.displayName = 'proto.packets_v1.MonthlyRoutineSchedulePeriodType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.MonthlyRoutineSchedulePeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.MonthlyRoutineSchedulePeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.MonthlyRoutineSchedulePeriod.displayName = 'proto.packets_v1.MonthlyRoutineSchedulePeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.RoutineSchedulePeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.packets_v1.RoutineSchedulePeriod.oneofGroups_);
};
goog.inherits(proto.packets_v1.RoutineSchedulePeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.RoutineSchedulePeriod.displayName = 'proto.packets_v1.RoutineSchedulePeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.RoutineScheduleTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.RoutineScheduleTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.RoutineScheduleTime.displayName = 'proto.packets_v1.RoutineScheduleTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.RoutineSchedulePacketData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.RoutineSchedulePacketData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.RoutineSchedulePacketData.displayName = 'proto.packets_v1.RoutineSchedulePacketData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.RoutineSchedulePacketMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.RoutineSchedulePacketMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.RoutineSchedulePacketMeta.displayName = 'proto.packets_v1.RoutineSchedulePacketMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.RoutineSchedulePacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.RoutineSchedulePacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.RoutineSchedulePacket.displayName = 'proto.packets_v1.RoutineSchedulePacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.MarshalledRoutineSchedulePacketData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.MarshalledRoutineSchedulePacketData.displayName = 'proto.packets_v1.MarshalledRoutineSchedulePacketData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.MarshalledRoutineSchedulePacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.MarshalledRoutineSchedulePacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.MarshalledRoutineSchedulePacket.displayName = 'proto.packets_v1.MarshalledRoutineSchedulePacket';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.DailyRoutineSchedulePeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.DailyRoutineSchedulePeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.DailyRoutineSchedulePeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.DailyRoutineSchedulePeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    repeatsEvery: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.DailyRoutineSchedulePeriod}
 */
proto.packets_v1.DailyRoutineSchedulePeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.DailyRoutineSchedulePeriod;
  return proto.packets_v1.DailyRoutineSchedulePeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.DailyRoutineSchedulePeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.DailyRoutineSchedulePeriod}
 */
proto.packets_v1.DailyRoutineSchedulePeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRepeatsEvery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.DailyRoutineSchedulePeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.DailyRoutineSchedulePeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.DailyRoutineSchedulePeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.DailyRoutineSchedulePeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRepeatsEvery();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 repeats_every = 1;
 * @return {number}
 */
proto.packets_v1.DailyRoutineSchedulePeriod.prototype.getRepeatsEvery = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.DailyRoutineSchedulePeriod} returns this
 */
proto.packets_v1.DailyRoutineSchedulePeriod.prototype.setRepeatsEvery = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.WeeklyRoutineSchedulePeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.WeeklyRoutineSchedulePeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    repeatsEvery: jspb.Message.getFieldWithDefault(msg, 1, 0),
    onWeekdaysList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.WeeklyRoutineSchedulePeriod}
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.WeeklyRoutineSchedulePeriod;
  return proto.packets_v1.WeeklyRoutineSchedulePeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.WeeklyRoutineSchedulePeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.WeeklyRoutineSchedulePeriod}
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRepeatsEvery(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.packets_v1.WeekdayName>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOnWeekdays(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.WeeklyRoutineSchedulePeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.WeeklyRoutineSchedulePeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRepeatsEvery();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOnWeekdaysList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 repeats_every = 1;
 * @return {number}
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.prototype.getRepeatsEvery = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.WeeklyRoutineSchedulePeriod} returns this
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.prototype.setRepeatsEvery = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated WeekdayName on_weekdays = 2;
 * @return {!Array<!proto.packets_v1.WeekdayName>}
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.prototype.getOnWeekdaysList = function() {
  return /** @type {!Array<!proto.packets_v1.WeekdayName>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.packets_v1.WeekdayName>} value
 * @return {!proto.packets_v1.WeeklyRoutineSchedulePeriod} returns this
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.prototype.setOnWeekdaysList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.packets_v1.WeekdayName} value
 * @param {number=} opt_index
 * @return {!proto.packets_v1.WeeklyRoutineSchedulePeriod} returns this
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.prototype.addOnWeekdays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.packets_v1.WeeklyRoutineSchedulePeriod} returns this
 */
proto.packets_v1.WeeklyRoutineSchedulePeriod.prototype.clearOnWeekdaysList = function() {
  return this.setOnWeekdaysList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.toObject = function(includeInstance, msg) {
  var f, obj = {
    nth: jspb.Message.getFieldWithDefault(msg, 1, 0),
    onWeekday: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday;
  return proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNth(value);
      break;
    case 2:
      var value = /** @type {!proto.packets_v1.WeekdayName} */ (reader.readEnum());
      msg.setOnWeekday(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOnWeekday();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 nth = 1;
 * @return {number}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.prototype.getNth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday} returns this
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.prototype.setNth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional WeekdayName on_weekday = 2;
 * @return {!proto.packets_v1.WeekdayName}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.prototype.getOnWeekday = function() {
  return /** @type {!proto.packets_v1.WeekdayName} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.packets_v1.WeekdayName} value
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday} returns this
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.prototype.setOnWeekday = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.toObject = function(includeInstance, msg) {
  var f, obj = {
    weekdayItemList: jspb.Message.toObjectList(msg.getWeekdayItemList(),
    proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays;
  return proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday;
      reader.readMessage(value,proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.deserializeBinaryFromReader);
      msg.addWeekdayItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeekdayItemList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MonthlyRoutineSchedulePeriodWeekday weekday_item = 1;
 * @return {!Array<!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday>}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.prototype.getWeekdayItemList = function() {
  return /** @type{!Array<!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday, 1));
};


/**
 * @param {!Array<!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday>} value
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays} returns this
*/
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.prototype.setWeekdayItemList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday=} opt_value
 * @param {number=} opt_index
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.prototype.addWeekdayItem = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.packets_v1.MonthlyRoutineSchedulePeriodWeekday, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays} returns this
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.prototype.clearWeekdayItemList = function() {
  return this.setWeekdayItemList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodDates.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodDates.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.MonthlyRoutineSchedulePeriodDates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodDates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodDates.toObject = function(includeInstance, msg) {
  var f, obj = {
    onDatesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodDates}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodDates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.MonthlyRoutineSchedulePeriodDates;
  return proto.packets_v1.MonthlyRoutineSchedulePeriodDates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodDates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodDates}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodDates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOnDates(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodDates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.MonthlyRoutineSchedulePeriodDates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodDates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodDates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOnDatesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 on_dates = 1;
 * @return {!Array<number>}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodDates.prototype.getOnDatesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodDates} returns this
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodDates.prototype.setOnDatesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodDates} returns this
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodDates.prototype.addOnDates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodDates} returns this
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodDates.prototype.clearOnDatesList = function() {
  return this.setOnDatesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.TypeCase = {
  TYPE_NOT_SET: 0,
  ON_DATES: 1,
  ON_WEEKDAYS: 2
};

/**
 * @return {proto.packets_v1.MonthlyRoutineSchedulePeriodType.TypeCase}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.prototype.getTypeCase = function() {
  return /** @type {proto.packets_v1.MonthlyRoutineSchedulePeriodType.TypeCase} */(jspb.Message.computeOneofCase(this, proto.packets_v1.MonthlyRoutineSchedulePeriodType.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.MonthlyRoutineSchedulePeriodType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.toObject = function(includeInstance, msg) {
  var f, obj = {
    onDates: (f = msg.getOnDates()) && proto.packets_v1.MonthlyRoutineSchedulePeriodDates.toObject(includeInstance, f),
    onWeekdays: (f = msg.getOnWeekdays()) && proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodType}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.MonthlyRoutineSchedulePeriodType;
  return proto.packets_v1.MonthlyRoutineSchedulePeriodType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodType}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.packets_v1.MonthlyRoutineSchedulePeriodDates;
      reader.readMessage(value,proto.packets_v1.MonthlyRoutineSchedulePeriodDates.deserializeBinaryFromReader);
      msg.setOnDates(value);
      break;
    case 2:
      var value = new proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays;
      reader.readMessage(value,proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.deserializeBinaryFromReader);
      msg.setOnWeekdays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.MonthlyRoutineSchedulePeriodType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriodType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOnDates();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.packets_v1.MonthlyRoutineSchedulePeriodDates.serializeBinaryToWriter
    );
  }
  f = message.getOnWeekdays();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays.serializeBinaryToWriter
    );
  }
};


/**
 * optional MonthlyRoutineSchedulePeriodDates on_dates = 1;
 * @return {?proto.packets_v1.MonthlyRoutineSchedulePeriodDates}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.prototype.getOnDates = function() {
  return /** @type{?proto.packets_v1.MonthlyRoutineSchedulePeriodDates} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.MonthlyRoutineSchedulePeriodDates, 1));
};


/**
 * @param {?proto.packets_v1.MonthlyRoutineSchedulePeriodDates|undefined} value
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodType} returns this
*/
proto.packets_v1.MonthlyRoutineSchedulePeriodType.prototype.setOnDates = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.packets_v1.MonthlyRoutineSchedulePeriodType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodType} returns this
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.prototype.clearOnDates = function() {
  return this.setOnDates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.prototype.hasOnDates = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MonthlyRoutineSchedulePeriodWeekdays on_weekdays = 2;
 * @return {?proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.prototype.getOnWeekdays = function() {
  return /** @type{?proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays, 2));
};


/**
 * @param {?proto.packets_v1.MonthlyRoutineSchedulePeriodWeekdays|undefined} value
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodType} returns this
*/
proto.packets_v1.MonthlyRoutineSchedulePeriodType.prototype.setOnWeekdays = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.packets_v1.MonthlyRoutineSchedulePeriodType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriodType} returns this
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.prototype.clearOnWeekdays = function() {
  return this.setOnWeekdays(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriodType.prototype.hasOnWeekdays = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.MonthlyRoutineSchedulePeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MonthlyRoutineSchedulePeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    repeatsEvery: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: (f = msg.getType()) && proto.packets_v1.MonthlyRoutineSchedulePeriodType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriod}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.MonthlyRoutineSchedulePeriod;
  return proto.packets_v1.MonthlyRoutineSchedulePeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriod}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRepeatsEvery(value);
      break;
    case 2:
      var value = new proto.packets_v1.MonthlyRoutineSchedulePeriodType;
      reader.readMessage(value,proto.packets_v1.MonthlyRoutineSchedulePeriodType.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.MonthlyRoutineSchedulePeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.MonthlyRoutineSchedulePeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MonthlyRoutineSchedulePeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRepeatsEvery();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.packets_v1.MonthlyRoutineSchedulePeriodType.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 repeats_every = 1;
 * @return {number}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriod.prototype.getRepeatsEvery = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriod} returns this
 */
proto.packets_v1.MonthlyRoutineSchedulePeriod.prototype.setRepeatsEvery = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional MonthlyRoutineSchedulePeriodType type = 2;
 * @return {?proto.packets_v1.MonthlyRoutineSchedulePeriodType}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriod.prototype.getType = function() {
  return /** @type{?proto.packets_v1.MonthlyRoutineSchedulePeriodType} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.MonthlyRoutineSchedulePeriodType, 2));
};


/**
 * @param {?proto.packets_v1.MonthlyRoutineSchedulePeriodType|undefined} value
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriod} returns this
*/
proto.packets_v1.MonthlyRoutineSchedulePeriod.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.MonthlyRoutineSchedulePeriod} returns this
 */
proto.packets_v1.MonthlyRoutineSchedulePeriod.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.MonthlyRoutineSchedulePeriod.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.packets_v1.RoutineSchedulePeriod.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.packets_v1.RoutineSchedulePeriod.TypeCase = {
  TYPE_NOT_SET: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3
};

/**
 * @return {proto.packets_v1.RoutineSchedulePeriod.TypeCase}
 */
proto.packets_v1.RoutineSchedulePeriod.prototype.getTypeCase = function() {
  return /** @type {proto.packets_v1.RoutineSchedulePeriod.TypeCase} */(jspb.Message.computeOneofCase(this, proto.packets_v1.RoutineSchedulePeriod.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.RoutineSchedulePeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.RoutineSchedulePeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.RoutineSchedulePeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutineSchedulePeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    daily: (f = msg.getDaily()) && proto.packets_v1.DailyRoutineSchedulePeriod.toObject(includeInstance, f),
    weekly: (f = msg.getWeekly()) && proto.packets_v1.WeeklyRoutineSchedulePeriod.toObject(includeInstance, f),
    monthly: (f = msg.getMonthly()) && proto.packets_v1.MonthlyRoutineSchedulePeriod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.RoutineSchedulePeriod}
 */
proto.packets_v1.RoutineSchedulePeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.RoutineSchedulePeriod;
  return proto.packets_v1.RoutineSchedulePeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.RoutineSchedulePeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.RoutineSchedulePeriod}
 */
proto.packets_v1.RoutineSchedulePeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.packets_v1.DailyRoutineSchedulePeriod;
      reader.readMessage(value,proto.packets_v1.DailyRoutineSchedulePeriod.deserializeBinaryFromReader);
      msg.setDaily(value);
      break;
    case 2:
      var value = new proto.packets_v1.WeeklyRoutineSchedulePeriod;
      reader.readMessage(value,proto.packets_v1.WeeklyRoutineSchedulePeriod.deserializeBinaryFromReader);
      msg.setWeekly(value);
      break;
    case 3:
      var value = new proto.packets_v1.MonthlyRoutineSchedulePeriod;
      reader.readMessage(value,proto.packets_v1.MonthlyRoutineSchedulePeriod.deserializeBinaryFromReader);
      msg.setMonthly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.RoutineSchedulePeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.RoutineSchedulePeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.RoutineSchedulePeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutineSchedulePeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDaily();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.packets_v1.DailyRoutineSchedulePeriod.serializeBinaryToWriter
    );
  }
  f = message.getWeekly();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.packets_v1.WeeklyRoutineSchedulePeriod.serializeBinaryToWriter
    );
  }
  f = message.getMonthly();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.packets_v1.MonthlyRoutineSchedulePeriod.serializeBinaryToWriter
    );
  }
};


/**
 * optional DailyRoutineSchedulePeriod daily = 1;
 * @return {?proto.packets_v1.DailyRoutineSchedulePeriod}
 */
proto.packets_v1.RoutineSchedulePeriod.prototype.getDaily = function() {
  return /** @type{?proto.packets_v1.DailyRoutineSchedulePeriod} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.DailyRoutineSchedulePeriod, 1));
};


/**
 * @param {?proto.packets_v1.DailyRoutineSchedulePeriod|undefined} value
 * @return {!proto.packets_v1.RoutineSchedulePeriod} returns this
*/
proto.packets_v1.RoutineSchedulePeriod.prototype.setDaily = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.packets_v1.RoutineSchedulePeriod.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutineSchedulePeriod} returns this
 */
proto.packets_v1.RoutineSchedulePeriod.prototype.clearDaily = function() {
  return this.setDaily(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutineSchedulePeriod.prototype.hasDaily = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WeeklyRoutineSchedulePeriod weekly = 2;
 * @return {?proto.packets_v1.WeeklyRoutineSchedulePeriod}
 */
proto.packets_v1.RoutineSchedulePeriod.prototype.getWeekly = function() {
  return /** @type{?proto.packets_v1.WeeklyRoutineSchedulePeriod} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.WeeklyRoutineSchedulePeriod, 2));
};


/**
 * @param {?proto.packets_v1.WeeklyRoutineSchedulePeriod|undefined} value
 * @return {!proto.packets_v1.RoutineSchedulePeriod} returns this
*/
proto.packets_v1.RoutineSchedulePeriod.prototype.setWeekly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.packets_v1.RoutineSchedulePeriod.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutineSchedulePeriod} returns this
 */
proto.packets_v1.RoutineSchedulePeriod.prototype.clearWeekly = function() {
  return this.setWeekly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutineSchedulePeriod.prototype.hasWeekly = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MonthlyRoutineSchedulePeriod monthly = 3;
 * @return {?proto.packets_v1.MonthlyRoutineSchedulePeriod}
 */
proto.packets_v1.RoutineSchedulePeriod.prototype.getMonthly = function() {
  return /** @type{?proto.packets_v1.MonthlyRoutineSchedulePeriod} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.MonthlyRoutineSchedulePeriod, 3));
};


/**
 * @param {?proto.packets_v1.MonthlyRoutineSchedulePeriod|undefined} value
 * @return {!proto.packets_v1.RoutineSchedulePeriod} returns this
*/
proto.packets_v1.RoutineSchedulePeriod.prototype.setMonthly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.packets_v1.RoutineSchedulePeriod.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutineSchedulePeriod} returns this
 */
proto.packets_v1.RoutineSchedulePeriod.prototype.clearMonthly = function() {
  return this.setMonthly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutineSchedulePeriod.prototype.hasMonthly = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.RoutineScheduleTime.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.RoutineScheduleTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.RoutineScheduleTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutineScheduleTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    duration: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minDuration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    idealTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    neverBefore: jspb.Message.getFieldWithDefault(msg, 4, 0),
    neverAfter: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.RoutineScheduleTime}
 */
proto.packets_v1.RoutineScheduleTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.RoutineScheduleTime;
  return proto.packets_v1.RoutineScheduleTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.RoutineScheduleTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.RoutineScheduleTime}
 */
proto.packets_v1.RoutineScheduleTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinDuration(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIdealTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNeverBefore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNeverAfter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.RoutineScheduleTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.RoutineScheduleTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.RoutineScheduleTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutineScheduleTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMinDuration();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getIdealTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getNeverBefore();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNeverAfter();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 duration = 1;
 * @return {number}
 */
proto.packets_v1.RoutineScheduleTime.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutineScheduleTime} returns this
 */
proto.packets_v1.RoutineScheduleTime.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 min_duration = 2;
 * @return {number}
 */
proto.packets_v1.RoutineScheduleTime.prototype.getMinDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutineScheduleTime} returns this
 */
proto.packets_v1.RoutineScheduleTime.prototype.setMinDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 ideal_time = 3;
 * @return {number}
 */
proto.packets_v1.RoutineScheduleTime.prototype.getIdealTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutineScheduleTime} returns this
 */
proto.packets_v1.RoutineScheduleTime.prototype.setIdealTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 never_before = 4;
 * @return {number}
 */
proto.packets_v1.RoutineScheduleTime.prototype.getNeverBefore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutineScheduleTime} returns this
 */
proto.packets_v1.RoutineScheduleTime.prototype.setNeverBefore = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 never_after = 5;
 * @return {number}
 */
proto.packets_v1.RoutineScheduleTime.prototype.getNeverAfter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutineScheduleTime} returns this
 */
proto.packets_v1.RoutineScheduleTime.prototype.setNeverAfter = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.RoutineSchedulePacketData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.RoutineSchedulePacketData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutineSchedulePacketData.toObject = function(includeInstance, msg) {
  var f, obj = {
    routineId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    period: (f = msg.getPeriod()) && proto.packets_v1.RoutineSchedulePeriod.toObject(includeInstance, f),
    time: (f = msg.getTime()) && proto.packets_v1.RoutineScheduleTime.toObject(includeInstance, f),
    startingOn: jspb.Message.getFieldWithDefault(msg, 4, 0),
    priority: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.RoutineSchedulePacketData}
 */
proto.packets_v1.RoutineSchedulePacketData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.RoutineSchedulePacketData;
  return proto.packets_v1.RoutineSchedulePacketData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.RoutineSchedulePacketData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.RoutineSchedulePacketData}
 */
proto.packets_v1.RoutineSchedulePacketData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoutineId(value);
      break;
    case 2:
      var value = new proto.packets_v1.RoutineSchedulePeriod;
      reader.readMessage(value,proto.packets_v1.RoutineSchedulePeriod.deserializeBinaryFromReader);
      msg.setPeriod(value);
      break;
    case 3:
      var value = new proto.packets_v1.RoutineScheduleTime;
      reader.readMessage(value,proto.packets_v1.RoutineScheduleTime.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartingOn(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.RoutineSchedulePacketData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.RoutineSchedulePacketData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutineSchedulePacketData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoutineId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPeriod();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.packets_v1.RoutineSchedulePeriod.serializeBinaryToWriter
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.packets_v1.RoutineScheduleTime.serializeBinaryToWriter
    );
  }
  f = message.getStartingOn();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string routine_id = 1;
 * @return {string}
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.getRoutineId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.RoutineSchedulePacketData} returns this
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.setRoutineId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RoutineSchedulePeriod period = 2;
 * @return {?proto.packets_v1.RoutineSchedulePeriod}
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.getPeriod = function() {
  return /** @type{?proto.packets_v1.RoutineSchedulePeriod} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.RoutineSchedulePeriod, 2));
};


/**
 * @param {?proto.packets_v1.RoutineSchedulePeriod|undefined} value
 * @return {!proto.packets_v1.RoutineSchedulePacketData} returns this
*/
proto.packets_v1.RoutineSchedulePacketData.prototype.setPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutineSchedulePacketData} returns this
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.clearPeriod = function() {
  return this.setPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.hasPeriod = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RoutineScheduleTime time = 3;
 * @return {?proto.packets_v1.RoutineScheduleTime}
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.getTime = function() {
  return /** @type{?proto.packets_v1.RoutineScheduleTime} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.RoutineScheduleTime, 3));
};


/**
 * @param {?proto.packets_v1.RoutineScheduleTime|undefined} value
 * @return {!proto.packets_v1.RoutineSchedulePacketData} returns this
*/
proto.packets_v1.RoutineSchedulePacketData.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutineSchedulePacketData} returns this
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.hasTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 starting_on = 4;
 * @return {number}
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.getStartingOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutineSchedulePacketData} returns this
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.setStartingOn = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 priority = 5;
 * @return {number}
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutineSchedulePacketData} returns this
 */
proto.packets_v1.RoutineSchedulePacketData.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.RoutineSchedulePacketMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.RoutineSchedulePacketMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutineSchedulePacketMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    period: (f = msg.getPeriod()) && commons_core_pb.Meta.toObject(includeInstance, f),
    time: (f = msg.getTime()) && commons_core_pb.Meta.toObject(includeInstance, f),
    priority: (f = msg.getPriority()) && commons_core_pb.Meta.toObject(includeInstance, f),
    startingOn: (f = msg.getStartingOn()) && commons_core_pb.Meta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.RoutineSchedulePacketMeta}
 */
proto.packets_v1.RoutineSchedulePacketMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.RoutineSchedulePacketMeta;
  return proto.packets_v1.RoutineSchedulePacketMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.RoutineSchedulePacketMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.RoutineSchedulePacketMeta}
 */
proto.packets_v1.RoutineSchedulePacketMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setPeriod(value);
      break;
    case 2:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 3:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setPriority(value);
      break;
    case 5:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setStartingOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.RoutineSchedulePacketMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.RoutineSchedulePacketMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutineSchedulePacketMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeriod();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPriority();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getStartingOn();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
};


/**
 * optional commons.Meta period = 1;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.getPeriod = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 1));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.RoutineSchedulePacketMeta} returns this
*/
proto.packets_v1.RoutineSchedulePacketMeta.prototype.setPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutineSchedulePacketMeta} returns this
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.clearPeriod = function() {
  return this.setPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.hasPeriod = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional commons.Meta time = 2;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.getTime = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 2));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.RoutineSchedulePacketMeta} returns this
*/
proto.packets_v1.RoutineSchedulePacketMeta.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutineSchedulePacketMeta} returns this
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional commons.Meta priority = 3;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.getPriority = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 3));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.RoutineSchedulePacketMeta} returns this
*/
proto.packets_v1.RoutineSchedulePacketMeta.prototype.setPriority = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutineSchedulePacketMeta} returns this
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.clearPriority = function() {
  return this.setPriority(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.hasPriority = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional commons.Meta starting_on = 5;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.getStartingOn = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 5));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.RoutineSchedulePacketMeta} returns this
*/
proto.packets_v1.RoutineSchedulePacketMeta.prototype.setStartingOn = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutineSchedulePacketMeta} returns this
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.clearStartingOn = function() {
  return this.setStartingOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutineSchedulePacketMeta.prototype.hasStartingOn = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.RoutineSchedulePacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.RoutineSchedulePacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutineSchedulePacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deleted: (f = msg.getDeleted()) && commons_core_pb.DeleteMeta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.packets_v1.RoutineSchedulePacketData.toObject(includeInstance, f),
    meta: (f = msg.getMeta()) && proto.packets_v1.RoutineSchedulePacketMeta.toObject(includeInstance, f),
    lastModifiedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cloudedAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.RoutineSchedulePacket}
 */
proto.packets_v1.RoutineSchedulePacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.RoutineSchedulePacket;
  return proto.packets_v1.RoutineSchedulePacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.RoutineSchedulePacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.RoutineSchedulePacket}
 */
proto.packets_v1.RoutineSchedulePacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.commons.PacketType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new commons_core_pb.DeleteMeta;
      reader.readMessage(value,commons_core_pb.DeleteMeta.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 4:
      var value = new proto.packets_v1.RoutineSchedulePacketData;
      reader.readMessage(value,proto.packets_v1.RoutineSchedulePacketData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = new proto.packets_v1.RoutineSchedulePacketMeta;
      reader.readMessage(value,proto.packets_v1.RoutineSchedulePacketMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModifiedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloudedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.RoutineSchedulePacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.RoutineSchedulePacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutineSchedulePacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.DeleteMeta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.packets_v1.RoutineSchedulePacketData.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.packets_v1.RoutineSchedulePacketMeta.serializeBinaryToWriter
    );
  }
  f = message.getLastModifiedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCloudedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.RoutineSchedulePacket} returns this
 */
proto.packets_v1.RoutineSchedulePacket.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional commons.PacketType type = 2;
 * @return {!proto.commons.PacketType}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.getType = function() {
  return /** @type {!proto.commons.PacketType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commons.PacketType} value
 * @return {!proto.packets_v1.RoutineSchedulePacket} returns this
 */
proto.packets_v1.RoutineSchedulePacket.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional commons.DeleteMeta deleted = 3;
 * @return {?proto.commons.DeleteMeta}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.getDeleted = function() {
  return /** @type{?proto.commons.DeleteMeta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.DeleteMeta, 3));
};


/**
 * @param {?proto.commons.DeleteMeta|undefined} value
 * @return {!proto.packets_v1.RoutineSchedulePacket} returns this
*/
proto.packets_v1.RoutineSchedulePacket.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutineSchedulePacket} returns this
 */
proto.packets_v1.RoutineSchedulePacket.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RoutineSchedulePacketData data = 4;
 * @return {?proto.packets_v1.RoutineSchedulePacketData}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.getData = function() {
  return /** @type{?proto.packets_v1.RoutineSchedulePacketData} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.RoutineSchedulePacketData, 4));
};


/**
 * @param {?proto.packets_v1.RoutineSchedulePacketData|undefined} value
 * @return {!proto.packets_v1.RoutineSchedulePacket} returns this
*/
proto.packets_v1.RoutineSchedulePacket.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutineSchedulePacket} returns this
 */
proto.packets_v1.RoutineSchedulePacket.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RoutineSchedulePacketMeta meta = 5;
 * @return {?proto.packets_v1.RoutineSchedulePacketMeta}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.getMeta = function() {
  return /** @type{?proto.packets_v1.RoutineSchedulePacketMeta} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.RoutineSchedulePacketMeta, 5));
};


/**
 * @param {?proto.packets_v1.RoutineSchedulePacketMeta|undefined} value
 * @return {!proto.packets_v1.RoutineSchedulePacket} returns this
*/
proto.packets_v1.RoutineSchedulePacket.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutineSchedulePacket} returns this
 */
proto.packets_v1.RoutineSchedulePacket.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 last_modified_at = 6;
 * @return {number}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.getLastModifiedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutineSchedulePacket} returns this
 */
proto.packets_v1.RoutineSchedulePacket.prototype.setLastModifiedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutineSchedulePacket} returns this
 */
proto.packets_v1.RoutineSchedulePacket.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 clouded_at = 8;
 * @return {number}
 */
proto.packets_v1.RoutineSchedulePacket.prototype.getCloudedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutineSchedulePacket} returns this
 */
proto.packets_v1.RoutineSchedulePacket.prototype.setCloudedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.MarshalledRoutineSchedulePacketData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.MarshalledRoutineSchedulePacketData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.toObject = function(includeInstance, msg) {
  var f, obj = {
    routineId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    period: msg.getPeriod_asB64(),
    time: msg.getTime_asB64(),
    startingOn: jspb.Message.getFieldWithDefault(msg, 4, 0),
    priority: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacketData}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.MarshalledRoutineSchedulePacketData;
  return proto.packets_v1.MarshalledRoutineSchedulePacketData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.MarshalledRoutineSchedulePacketData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacketData}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoutineId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPeriod(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartingOn(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.MarshalledRoutineSchedulePacketData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.MarshalledRoutineSchedulePacketData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoutineId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPeriod_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getTime_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getStartingOn();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string routine_id = 1;
 * @return {string}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.getRoutineId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacketData} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.setRoutineId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes period = 2;
 * @return {string}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.getPeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes period = 2;
 * This is a type-conversion wrapper around `getPeriod()`
 * @return {string}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.getPeriod_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPeriod()));
};


/**
 * optional bytes period = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPeriod()`
 * @return {!Uint8Array}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.getPeriod_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPeriod()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacketData} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes time = 3;
 * @return {string}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes time = 3;
 * This is a type-conversion wrapper around `getTime()`
 * @return {string}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.getTime_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTime()));
};


/**
 * optional bytes time = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTime()`
 * @return {!Uint8Array}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.getTime_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTime()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacketData} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.setTime = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional int64 starting_on = 4;
 * @return {number}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.getStartingOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacketData} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.setStartingOn = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 priority = 5;
 * @return {number}
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacketData} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacketData.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.MarshalledRoutineSchedulePacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.MarshalledRoutineSchedulePacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deleted: (f = msg.getDeleted()) && commons_core_pb.DeleteMeta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.packets_v1.MarshalledRoutineSchedulePacketData.toObject(includeInstance, f),
    meta: (f = msg.getMeta()) && proto.packets_v1.RoutineSchedulePacketMeta.toObject(includeInstance, f),
    lastModifiedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cloudedAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.MarshalledRoutineSchedulePacket;
  return proto.packets_v1.MarshalledRoutineSchedulePacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.MarshalledRoutineSchedulePacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.commons.PacketType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new commons_core_pb.DeleteMeta;
      reader.readMessage(value,commons_core_pb.DeleteMeta.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 4:
      var value = new proto.packets_v1.MarshalledRoutineSchedulePacketData;
      reader.readMessage(value,proto.packets_v1.MarshalledRoutineSchedulePacketData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = new proto.packets_v1.RoutineSchedulePacketMeta;
      reader.readMessage(value,proto.packets_v1.RoutineSchedulePacketMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModifiedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloudedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.MarshalledRoutineSchedulePacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.MarshalledRoutineSchedulePacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.DeleteMeta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.packets_v1.MarshalledRoutineSchedulePacketData.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.packets_v1.RoutineSchedulePacketMeta.serializeBinaryToWriter
    );
  }
  f = message.getLastModifiedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCloudedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional commons.PacketType type = 2;
 * @return {!proto.commons.PacketType}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.getType = function() {
  return /** @type {!proto.commons.PacketType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commons.PacketType} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional commons.DeleteMeta deleted = 3;
 * @return {?proto.commons.DeleteMeta}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.getDeleted = function() {
  return /** @type{?proto.commons.DeleteMeta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.DeleteMeta, 3));
};


/**
 * @param {?proto.commons.DeleteMeta|undefined} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket} returns this
*/
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MarshalledRoutineSchedulePacketData data = 4;
 * @return {?proto.packets_v1.MarshalledRoutineSchedulePacketData}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.getData = function() {
  return /** @type{?proto.packets_v1.MarshalledRoutineSchedulePacketData} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.MarshalledRoutineSchedulePacketData, 4));
};


/**
 * @param {?proto.packets_v1.MarshalledRoutineSchedulePacketData|undefined} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket} returns this
*/
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RoutineSchedulePacketMeta meta = 5;
 * @return {?proto.packets_v1.RoutineSchedulePacketMeta}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.getMeta = function() {
  return /** @type{?proto.packets_v1.RoutineSchedulePacketMeta} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.RoutineSchedulePacketMeta, 5));
};


/**
 * @param {?proto.packets_v1.RoutineSchedulePacketMeta|undefined} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket} returns this
*/
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 last_modified_at = 6;
 * @return {number}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.getLastModifiedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.setLastModifiedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 clouded_at = 8;
 * @return {number}
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.getCloudedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.MarshalledRoutineSchedulePacket} returns this
 */
proto.packets_v1.MarshalledRoutineSchedulePacket.prototype.setCloudedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * @enum {number}
 */
proto.packets_v1.WeekdayName = {
  WEEKDAYNAME_DO_NOT_USE: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
};

goog.object.extend(exports, proto.packets_v1);
