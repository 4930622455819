import { useIsDarkMode } from '@brand/colors'
import { Transitions } from '@brand/transitions'
import { ActivityBubble } from '@comps/static/activityBubble'
import { Icon } from '@comps/static/icon'
import { FontStyles, Text } from '@comps/typography/text'
import { useRefTaker } from '@root/hooks/useRefTaker'
import { useSelectable } from '@root/hooks/useSelectable'
import { ActivityColorKey, ActivityColors } from '@root/store/slices/activities.colors.types'
import { ActivityData } from '@root/store/slices/activitiesTypes'
import { ViewID } from '@root/store/slices/interface.types'
import { ExtractOnlyExisting, SelectableTypes, SelectionItemContext } from '@root/store/slices/selection.types'
import React, { ReactElement, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { DraggableSelectable } from '../draggableSelectable'
import { SessionBlockFins } from './sessionBlock.fins'
import { SessionBlockRoutineMaker } from './sessionBlock.routineMaker'

type SplitType = 'top' | 'bottom' | 'both'

type SessionSize = 'gone' | 'tiny' | 'small' | 'normal'
export const SessionBlock: React.FC<{
	minutesSpan: number
	period?: ReactElement
	activity: ActivityData
	onDeselect?: (e: any) => void
	onSelect?: (e: any) => void
	isSelected?: boolean
	startAt: number
	endAt: number
	isActive?: boolean
	title?: string
	description?: string
	isDragged?: boolean
	split?: SplitType
	count: number
	// navigateTo?: string
	height: number
	width: number
	y: number
	x: number
	offsetLeft?: number
	isResized?: boolean
	onRef?: (ref: any) => void
	onContextMenu?: (e: any) => void
	isMoving?: boolean
	onResizingStarted: (e: any, direction: 'bottom' | 'top') => void
	onDragStart?: (e: any) => void
	onDragEnd?: (e: any) => void
	resizingDirection?: 'start' | 'end'
	selectionZoneId: ExtractOnlyExisting<ViewID, 'Plan' | 'Plan.Weekly'>
	isRoutine: boolean
	isPresented: boolean
	entityId: string
	subentityId?: string
}> = ({
	minutesSpan,
	period,
	isSelected,
	onDeselect,
	entityId,
	onSelect,
	activity,
	isPresented,
	title, description,
	isActive,
	isDragged,
	split,
	startAt,
	endAt,
	onRef,
	height,
	offsetLeft,
	isResized,
	width,
	isRoutine,
	y,
	x,
	count,
	onContextMenu,
	onResizingStarted,
	resizingDirection,
	// sessionId,
	onDragEnd,
	onDragStart,
	subentityId,
	selectionZoneId,
}) => {
		const isDarkMode = useIsDarkMode()
		// const [ref, setRef] = useRefTaker()

		// useOnClickOutside(ref, onDeselect)

		// useEffect(() => {
		// 	onRef?.(ref)
		// }, [onRef, ref])

		const [sessionRef, setSessionRef] = useRefTaker()
		const handleMouseMove = useCallback((e: any) => { }, [])

		const size = useMemo<SessionSize>(() => {
			if (minutesSpan <= 10) return 'gone'
			if (minutesSpan < 30) return 'tiny'
			if (minutesSpan < 50) return 'small'
			return 'normal'
		}, [minutesSpan])

		const content = useMemo(() => {
			switch (size) {
				case 'gone': {
					return (
						<Contents key="contents">
							<SessionBlockFins onResizingStarted={onResizingStarted} resizingDirection={resizingDirection} isSelected={isSelected} key="fins" />
							{/* <NavigationStackLink to={`/session/${sessionId}/session`} className="open-arrow">
							<ShortcutHolder color={activity.color} className="shortcut-holder">
								<DiagonalArrowCircle />
							</ShortcutHolder>
						</NavigationStackLink> */}
							{isRoutine && <SessionBlockRoutineMaker start={startAt} end={endAt} scheduleId={subentityId} routineId={entityId} />}
						</Contents>
					)
				}
				case 'tiny': {
					return (
						<Contents key="contents">
							<div key="header" className="header">
								<div key="image" className="icon-holder">
									<ActivityBubble activity={activity} status={isPresented ? 'active' : 'inactiveLight'} nuanceAttr="s2" />
								</div>
								<div className="right-side">
									<TitleRow>
										<Text level="tiny" bold hex={'currentColor'} block noWrap>{title || activity.name}</Text>
										{/* <Period hex={'currentColor'} level="tiny" noWrap block>
											{period}
										</Period> */}
									</TitleRow>
								</div>
								{/* <NavigationStackLink to={`/session/${sessionId}/session`} className="open-arrow">
								<ShortcutHolder color={activity.color} className="shortcut-holder">
									<DiagonalArrowCircle />
								</ShortcutHolder>
							</NavigationStackLink> */}
							</div>
							{!isRoutine && (
								<SessionBlockFins
									onResizingStarted={onResizingStarted}
									resizingDirection={resizingDirection}
									isSelected={isSelected}
									key="fins"
								/>
							)}
							{isRoutine && <SessionBlockRoutineMaker start={startAt} end={endAt} scheduleId={subentityId} routineId={entityId} />}
						</Contents>
					)
				}
				case 'small': {
					return (
						<Contents key="contents">
							<div key="header" className="header">
								<div key="image" className="icon-holder">
									<ActivityBubble activity={activity} status={isPresented ? 'active' : 'inactiveLight'} nuanceAttr="s2" />
								</div>
								<div className="right-side">
									<TitleRow>
										<Text level="body" bold hex={'currentColor'} block noWrap>
											{title || activity.name}
										</Text>
										{/* <Period hex={'currentColor'} level="tiny" noWrap block>
										{period}
									</Period> */}
									</TitleRow>
								</div>
								{/* <NavigationStackLink to={`/session/${sessionId}/session`} className="open-arrow">
								<ShortcutHolder color={activity.color} className="shortcut-holder">
									<DiagonalArrowCircle />
								</ShortcutHolder>
							</NavigationStackLink> */}
							</div>
							{!isRoutine && (
								<SessionBlockFins
									onResizingStarted={onResizingStarted}
									resizingDirection={resizingDirection}
									isSelected={isSelected}
									key="fins"
								/>
							)}
							{isRoutine && <SessionBlockRoutineMaker start={startAt} end={endAt} scheduleId={subentityId} routineId={entityId} />}
						</Contents>
					)
				}
				default: {
					return (
						<Contents key="contents">
							<div key="header" className="header">
								<div key="image" className="icon-holder">
									<ActivityBubble activity={activity} status={isPresented ? 'active' : 'inactiveLight'} nuanceAttr="s2" size="small" />
								</div>
								<div className="right-side">
									<TitleRow>
										{isRoutine ? (
											<>
												<Text level="bodyTitle" bold hex={'currentColor'} block noWrap>
													{title || activity.name}
												</Text>
												<RecurrentArrowHolder>
													<Icon type="recurrent" />
												</RecurrentArrowHolder>
											</>
										) : (
											<Text level="bodyTitle" bold hex={'currentColor'} block noWrap>
												{title || activity.name}
											</Text>
										)}
									</TitleRow>
									<Period hex={'currentColor'} level="tiny" noWrap>
										{period}
									</Period>
									<Description>
										{description}
									</Description>
								</div>
								{/* <NavigationStackLink to={`/session/${sessionId}/session`} className="open-arrow">
								<ShortcutHolder color={activity.color} className="shortcut-holder">
									<DiagonalArrowCircle />
								</ShortcutHolder>
							</NavigationStackLink> */}
							</div>
							{!isRoutine && <SessionBlockFins onResizingStarted={onResizingStarted} isSelected={isSelected} key="fins" />}
							{isRoutine && <SessionBlockRoutineMaker start={startAt} end={endAt} scheduleId={subentityId} routineId={entityId} />}
						</Contents>
					)
				}
			}
		}, [size, activity, period, isPresented, title, description, isSelected, onResizingStarted, resizingDirection, entityId, isRoutine, startAt, endAt, subentityId])

		const handleOnClick = useCallback(
			(e: any) => {
				onSelect?.(e)
			},
			[onSelect]
		)

		const handleMouseUp = useCallback(
			(e: any) => {
				onDragEnd?.(e)
			},
			[onDragEnd]
		)

		const handleDragStart = useCallback(
			(e: any) => {
				if (isSelected) {
					e.stopPropagation()
					onDragStart?.(e)
				}
			},
			[isSelected, onDragStart]
		)

		const draggable = useMemo(() => {
			return <BigPeriod>{period}</BigPeriod>
		}, [period])

		const { selectedClassName } = useSelectable(SelectableTypes.SESSION, entityId, selectionZoneId)

		const cornerRadius = useMemo(() => {
			const bound = (sessionRef as any)?.getBoundingClientRect()
			const h = bound?.height
			const stdRad = 24

			if (h && h < 120) {
				const minRadius = 2
				const progress = h / 120.0

				return (stdRad - minRadius) * progress + minRadius
			}
			return stdRad
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [x, y, height, sessionRef])

		const ctx = useMemo<SelectionItemContext>(() => {
			return {
				itemType: SelectableTypes.SESSION,
				id: entityId,
				// dayindex: 0,
			}
		}, [entityId])

		return (
			<Block
				ref={setSessionRef}
				isDarkMode={isDarkMode}
				color={activity.color}
				split={split}
				data-sid={isRoutine ? null : entityId}
				data-no-event-making
				size={size}
				onClick={isRoutine ? undefined : handleOnClick}
				isResized={isDragged && isResized}
				onMouseMove={handleMouseMove}
				onContextMenu={onContextMenu}
				offsetLeft={offsetLeft}
				// onMouseDown={}
				onMouseUp={handleMouseUp}
				// key={sessionId}
				isActive={isActive}
				isDragged={isDragged}
				isSelected={isSelected}
				cornerRadius={cornerRadius}
				isPresented={isPresented}
				className={`session-block ${selectedClassName} noseldis`}
				// initial={{
				// 	height,
				// 	y,
				// 	x: (offsetLeft || 0) + x,
				// 	width: width - (offsetLeft || 0),
				// }}
				// animate={{
				// 	height,
				// 	y,
				// 	width: width - (offsetLeft || 0),
				// 	x: (offsetLeft || 0) + x,
				// }}
				height={height}
				isRoutine={isRoutine}
				width={count != 1 ? width : width - (offsetLeft || 0)}
				y={y}
				x={(offsetLeft || 0) + x}
				transition={Transitions.opacityOnly}
				count={count}
			>
				{isDragged ? draggable : content}
				{isDragged && isResized ? <span className="dragging-invisible-flaps" /> : null}
				{isPresented && (
					<ArrowHolder>
						<Icon type="arrow" strokeWidth={2} />
					</ArrowHolder>
				)}
				{isRoutine ? null : <DraggableSelectable onPointerDown={handleDragStart} context={ctx} id={entityId} isSelected={isSelected || false} />}
			</Block>
		)
	}

const ArrowHolder = styled.div`
	position: absolute;
	right: -40px;
	top: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform-origin: center center;
	transform: rotateZ(180deg) translateY(50%);
	opacity: 1;
	${(p) => p.theme.text.regular.r.css('color')};
`

const radius = '24px'
const Block = styled.div<{
	split: SplitType
	isResized: boolean
	isRoutine: boolean
	isSelected: boolean
	isDragged: boolean
	isActive: boolean
	isPresented: boolean
	isDarkMode: boolean
	x: number
	y: number
	width: number
	cornerRadius: number
	height: number
	size: SessionSize
	color: ActivityColorKey
}>`
	padding: 16px;
	box-sizing: border-box;
	border-radius: ${(p) => p.cornerRadius}px;
	top: 0;
	position: absolute;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	z-index: 10;
	margin: 0;
	border: 1px solid rgba(0, 0, 0, 0.033) !important;
	margin: -1px !important;

	${(p) =>
		p.isDragged &&
		css`
			* {
				cursor: grabbing !important;
			}
		`}
	&.selected {
		&:before {
			position: absolute;
			top: -3px;
			left: -3px;
			width: calc(100% + 6px);
			height: calc(100% + 6px);
			${({ color, isPresented }) =>
		isPresented
			? ActivityColors[color]?.active.background.r.css('box-shadow', '0px 0px 0px 1px ')
			: ActivityColors[color]?.inactiveLight.background.r.css('box-shadow', '0px 0px 0px 1px ')};
			content: '';
			border-radius: ${(p) => p.cornerRadius + 1}px;
			display: block;
		}

		${(p) => {
		if (p.split === 'bottom')
			return css`
					&:before {
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}
				`
		if (p.split === 'top')
			return css`
					&:before {
						border-top-left-radius: 0;
						border-top-right-radius: 0;
					}
				`
		if (p.split === 'both')
			return css`
					&:before {
						border-radius: 0;
					}
				`
	}}
	}

	${(p) =>
		p.isRoutine
			? css`
					${p.theme.backgrounds.routine.r.css('background-color')};
					${p.theme.text.subtle.r.css('color')};
			  `
			: css`
					${({ color, isDarkMode }: { color: ActivityColorKey; isDarkMode: boolean }) =>
					isDarkMode
						? ActivityColors[color]?.inactiveDark.background.r.css('background-color')
						: ActivityColors[color]?.inactiveLight.background.r.css('background-color')};

					${({ color, isDarkMode }: { color: ActivityColorKey; isDarkMode: boolean }) =>
					isDarkMode
						? ActivityColors[color]?.inactiveDark.text.r.css('background-color')
						: ActivityColors[color]?.inactiveLight.text.r.css('color')};
			  `}

	transition: 0.3s ease box-shadow;

	${(p) =>
		(p.isSelected || p.isDragged) &&
		css`
			z-index: 200;
			/* ${({ color }: { color: ActivityColorKey; isDarkMode: boolean }) => ActivityColors[color]?.active.background.r.css('background-color')}; */
			/* ${({ color }) => ActivityColors[color]?.active.text.r.css('color')}; */
			/* ${p.theme.backgrounds.normal.r.css('box-shadow', 'inset 0px 0px 0px 2px rgba(255,255,255,0.1)')}; */
		`}

	${(p) =>
		p.isPresented &&
		css`
			z-index: 100;
			${({ color }: { color: ActivityColorKey; isDarkMode: boolean }) => ActivityColors[color]?.active.background.r.css('background-color')};
			${({ color }) => ActivityColors[color]?.active.text.r.css('color')};
			/* box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15); */
			/* ${p.theme.backgrounds.normal.r.css('box-shadow', 'inset 0px 0px 0px 2px rgba(255,255,255,0.1)')}; */
			/* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); */
		`}

	${(p) =>
		p.isResized &&
		css`
			&.session-block {
				cursor: row-resize !important;

				* {
					cursor: row-resize !important;
				}

				.dragging-invisible-flaps {
					&:before,
					&:after {
						content: '';
						display: block;
						position: absolute;
						width: 100%;
						height: 30px;
						top: -20px;
						left: 0;
						background: transparent;
					}

					&:after {
						top: auto;
						bottom: -20px;
					}
				}
			}
		`}

	${(p) => css`
		width: ${p.width}px;
		height: ${p.height}px;
		left: ${p.x}px;
		top: ${p.y}px;
		transform: translateZ(0);
		will-change: transform;
	`}

	${(p) => {
		const commonStyles = css`
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			height: 1px;
			width: 100%;
			background-color: rgba(255, 255, 255, 0.1);
		`
		if (p.split === 'bottom')
			return css`
				border-radius: ${radius} ${radius} 0 0;
				&:before {
					${commonStyles}
					bottom: 0;
					top: auto;
				}
			`
		if (p.split === 'top')
			return css`
				border-radius: 0 0 ${radius} ${radius};
				&:before {
					${commonStyles}
					top: 0;
					bottom: auto;
				}
			`
		if (p.split === 'both')
			return css`
				border-radius: 0 0 0 0;
				&:before {
					${commonStyles}
					top: 0;
					bottom: auto;
				}
				&:after {
					${commonStyles}
					bottom: 0;
					top: auto;
				}
			`
	}}

	${(p) =>
		p.isActive &&
		css`
			${({ color }: { color: keyof typeof ActivityColors }) => ActivityColors[color]?.active.background.r.css('background-color')};
		`}

		
	.open-arrow {
		display: none;
	}

	&:hover .open-arrow {
		display: block;
	}
	/* ${(p) =>
		p.size === 'small' &&
		css`
			padding: 0 9px;
			padding-top: 1px;
			align-items: center;
		`}
	 */

	${(p) =>
		p.size === 'tiny' &&
		css`
			padding: 0;
			padding-top: 1px;
			align-items: center;
		`}

	.icon-holder {
		width: 20px;
		height: 20px;
		display: flex;
		flex-shrink: 0;
		flex-direction: column;
		position: relative;
		border-radius: 99px;
		top: -4px;
		left: -4px;

		${(p) => {
		switch (p.size) {
			case 'tiny':
				return css`
						width: 24px;
						height: 14px;
					`
			case 'small':
				return css`
						width: 24px;
						height: 24px;
					`
			case 'normal':
				return css`
						width: 32px;
						height: 32px;
					`
		}
	}}

		.activity-holder,
			.activity-bubble {
			width: 100%;
			height: 100%;
			${(p) =>
		p.isRoutine &&
		css`
					filter: grayscale(100%);
					opacity: 0.6;
				`}
		}
	}

	.header {
		position: sticky;
		top: 155px;
		left: 0;
		width: 100%;
		flex-shrink: 0;
		display: flex;
		bottom: auto;
		right: auto;
	}

	.right-side {
		display: flex;
		flex-direction: column;
		margin-left: 0px;
		margin-top: -1px;
		flex-basis: 0;
		flex-grow: 1;
		min-width: 0%;
		padding-right: 25px;

		${(p) =>
		p.isRoutine &&
		css`
				opacity: 1;
			`}

		& > *:last-child {
			margin-top: 1px;
		}
	}

	.icon-holder {
		margin-right: 10px;
	}
	${(p) =>
		p.size === 'small' &&
		css`
			align-items: center;

			.right-side {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				flex-grow: 1;
				align-items: baseline;
				margin-top: -4px;
				flex-basis: 0;
				flex-grow: 1;
				min-width: 0%;
			}

			.icon-holder {
				top: 0;
				left: -4px;
				margin-right: 18px;
			}

			.header {
				display: contents;
			}

			.shortcut-holder {
				position: relative;
				top: 0;
				left: 0;
				margin-right: -20px;
			}
		`}
	${(p) =>
		p.size === 'tiny' &&
		css`
			align-items: center;

			.right-side {
				display: flex;
				// this is missing padding
				padding-right: 44px;
				flex-direction: row;
				justify-content: space-between;
				flex-grow: 1;
				align-items: center;
				margin-top: -4px;
				flex-basis: 0;
				flex-grow: 1;
				min-width: 0%;
			}

			.icon-holder {
				top: 0;
				left: 14px;

				width: 16px;
				height: 16px;

				margin-right: 43px;
			}

			.header {
				display: contents;
			}
			.tiny-activity-name {
				${FontStyles.tiny};
				color: currentColor;
				/* text-transform: uppercase; */
				font-weight: 600;
			}
			.shortcut-holder {
				position: relative;
				top: 0;
				left: 0;
				margin-right: 0px;
			}
		`}
	${(p) =>
		p.size === 'gone' &&
		css`
			padding: 0;
			.right-side {
				display: none;
			}

			.fin {
				display: flex !important;
			}
			.shortcut-holder {
				margin-top: -1px;
			}
		`}
` as any

const BigPeriod = styled.div`
	font-size: 12px;
	text-align: center;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

// const ShortcutHolder = styled.div<{ color: keyof typeof ActivityColors }>`
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	width: 54px;
// 	height: 54px;
// 	position: absolute;
// 	right: -20px;
// 	top: -20px;
// 	transition: 0.15s ease background-color, 0.15s ease color;

// 	&.shortcut-holder,
// 	&.shortcut-holder * {
// 		cursor: default !important;
// 	}

// 	.icon-button {
// 		${(p) => ActivityColors[p.color]?.active.background.s1.css('background-color')};
// 		${(p) => ActivityColors[p.color]?.active.text.r.css('color')};
// 	}

// 	&:hover .icon-button {
// 		${(p) => ActivityColors[p.color]?.active.background.s2.css('background-color')};
// 		${(p) => ActivityColors[p.color]?.active.text.r.css('color')};
// 	}

// 	&:active .icon-button {
// 		${(p) => ActivityColors[p.color]?.active.background.s3.css('background-color')};
// 		${(p) => ActivityColors[p.color]?.active.text.r.css('color')};
// 	}
// `

const TitleRow = styled.div`
    width: 100%;
    overflow: hidden;
    flex-grow: 1;
	flex-shrink: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
	word-break: break-all;
    color: currentColor;
    display: block;
	align-items: center;
`

const Period = styled(Text)`
	opacity: 0.6;
	margin: 2px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const Contents = styled.div`
	display: contents;
	border-radius: inherit;
`

const Description = styled.div`
	font-size: 12px;
	line-height: 18px;
	opacity: 0.6;
`

const RecurrentArrowHolder = styled.div`
	display: inline-flex;
	margin-left: 5px;
	position: relative;
	top: 1.5px;
`