import { SettingsItemYesNo } from '@comps/complex/settingsItemYesNo';
import { Flex } from '@comps/layout/flex';
import { Spacer } from '@comps/layout/space';
import { Text } from '@comps/typography/text';

export const SettingsAppearence = () => {
	return (
		<Flex direction="column">
			<Spacer size={12} vertical />
			<Text level="title60">Appearence</Text>
			<Spacer size={50} vertical />
			<SettingsItemYesNo
				title="Smart Dark Mode"
				subtitle="Dynamically adapt the Clepside interface to match the UI settings."
				rightside=""
			/>
		</Flex>
	)
}
