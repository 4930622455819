import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useSessionSwitchActiveResource = () => {
    const location = useLocation();
    
    return useMemo(() => {
        const pathParts = location.pathname.split('/');
        if (pathParts[pathParts.length - 1] === 'session') {
            return { isSession: true, isActivity: false }
        }
        return { isSession: false, isActivity: true }
    }, [location])
}