import { getNextIndexByIndex } from '@root/utils/general'
import { useCallback, useMemo, useRef } from 'react'
import { FormField, FormState, ListenerOp } from './useForm.types'
import { FormConfig, FormValues } from './useFormConfig'
import { useFormValidation } from './useFormValidation'

interface FieldsPotFieldConfig {
	initialValue: string
}

export interface FieldsPotConfig {
	[key: string]: FieldsPotFieldConfig
}

export type FormErrors<C> = {
	[key in keyof C]: string
}

//
export function useForm<C>(config: FormConfig<C>) {
	const state = useRef<FormState<C>>({
		at: (Object.keys(config.fields) as any).reduce((acc: FormState<C>['at'], v: keyof C, i: number) => {
			if (!acc[v]) {
				acc[v] = {} as any
			}
			acc[v].rules = config.fields[v].validate
			acc[v].index = i
			acc[v].errors = []
			acc[v].value = config.fields[v].defaultValue || ''
			return acc
		}, {} as FormState<C>['at']),
		all: Object.keys(config.fields),
	} as any)
	const { validate, errors } = useFormValidation<C>()

	const submitForm = useCallback(async () => {
		const values = state.current.all.reduce((acc, label) => {
			const node = state.current.at[label]
			acc[label] = node.value || ''
			return acc
		}, {} as FormValues<C>)

		if (await validate(state.current)) {
			config.onSubmit?.(state.current, values)
		}
	}, [config, validate])

	const goToNextField = useCallback(() => {
		const currentFieldIndex = state.current.all.findIndex((k) => state.current.at[k].node == document.activeElement)
		const nextFieldIndex = getNextIndexByIndex(state.current.all, currentFieldIndex)

		if (currentFieldIndex == state.current.all.length - 1) {
			submitForm()
			return
		}

		if (nextFieldIndex) {
			const nextFieldId = state.current.all[nextFieldIndex]
			const nextField = state.current.at[nextFieldId]
			nextField.node.focus()
		}
	}, [submitForm])

	// const debouncedGoToNextField = useMemo(() => {
	// 	return debounce(goToNextField, 300)
	// }, [goToNextField]);

	// useEffect(() => {
	// 	return () => {
	// 		(debouncedGoToNextField as any)?.cancel()
	// 	}
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])

	// | 'update'
	const saveRef = useCallback(
		(type: 'text', name: keyof C, node: FormField | null) => {
			// , index: number, rules?: string
			if (!node) return

			const textListener = (e: KeyboardEvent) => {
				e.stopImmediatePropagation()

				if (e.key === 'Enter') {
					e.preventDefault()
					goToNextField()
					return
				}

				if ('target' in e) {
					if (state.current.at[name]) {
						state.current.at[name].value = (e.target as any).value
					}
				}

				config.onUpdate?.(state.current)
			}
			const changeListener = (e: Event) => {
				if ('target' in e) {
					if (state.current.at[name]) {
						state.current.at[name].value = (e.target as any).value
					}
				}

				config.onUpdate?.(state.current)
			}

			let listeners: { [key: ListenerOp]: any } = {}
			switch (type) {
				case 'text':
					node.addEventListener('keyup', textListener)
					node.addEventListener('change', changeListener)
					listeners = {
						keyup: textListener,
						change: changeListener,
					}
					break
				// case 'update':
				// 	node.addEventListener('update', textListener)
				// 	listeners = [textListener]
			}

			if (!(Object.keys(listeners).length > 0)) throw new Error('Listener has no events')

			state.current = {
				...state.current,
				at: {
					...state.current.at,
					[name]: {
						...(state.current.at[name] || {}),
						listeners,
						node,
					},
				},
				all: state.current.all.includes(name) ? state.current.all : [...state.current.all, name],
			}

			state.current.all.sort((a: keyof C, b: keyof C) => {
				if (state.current.at[a].index > state.current.at[b].index) return 1
				return -1
			})
		},
		[config, goToNextField]
	)

	const injectFormField = useCallback(
		(name: keyof C, type: 'text') => {
			return (node: HTMLFormElement | undefined | null) => {
				if (!node) return

				const listeners = state.current?.at?.[name]?.listeners
				if (listeners && Object.keys(listeners).length > 0) {
					for (const [key, op] of Object.entries(listeners)) {
						state.current.at[name].node.removeEventListener(key, op)
					}
				}

				saveRef(type, name, node as any)
			}
		},
		[saveRef]
	)

	// const focus = useCallback(
	// 	(name: keyof C) => {
	// 		if (state.current.all.indexOf(name) >= 0) setFocusedIndex(state.current.all.indexOf(name))
	// 	},
	// 	[setFocusedIndex]
	// )

	// useEffect(() => {
	// 	if (focusedIndex !== undefined) {
	// 		state.current.at[state.current.all[focusedIndex]].node?.focus?.()
	// 	} else (document.activeElement as any)?.blur()
	// }, [focusedIndex])

	// useHotkey(
	// 	'Activities.NewActivity.Overview',
	// 	'up',
	// 	useCallback(() => {
	// 		setFocusedIndex((i) => {
	// 			return UtilsListsGetIndexInBounds.goingUp(state.current.all.length, i)
	// 		})
	// 	}, [setFocusedIndex])
	// )

	// useHotkey(
	// 	'Activities.NewActivity.Overview',
	// 	'down',
	// 	useCallback(() => {
	// 		setFocusedIndex((i) => {
	// 			return UtilsListsGetIndexInBounds.goingDown(state.current.all.length, i)
	// 		})
	// 	}, [setFocusedIndex])
	// )

	// const moveToNextField = useCallback(() => {
	// 	setFocusedIndex((i) => {
	// 		return UtilsListsGetIndexInBounds.goingDown(state.current.all.length, i)
	// 	})
	// }, [setFocusedIndex])

	// const fieldProps = useMemo(() => {
	// 	return {
	// 		moveToNextField,
	// 		takeFocus: focus,
	// 	}
	// }, [moveToNextField, focus])

	const fields = useMemo(() => {
		return (Object.keys(config.fields) as any).reduce((acc: { [id in keyof C]: any }, k: keyof C) => {
			acc[k] = {
				name: k,
				id: k,
				defaultValue: config.fields?.[k]?.defaultValue,
				key: k,
				errors: errors?.[k],
			}
			return acc
		}, {})
	}, [config, errors])

	return {
		saveRef,
		validate,
		submit: submitForm,
		focus,
		injectFormField,
		state,
		// fieldProps,
		fields,
		errors,
	}
}
