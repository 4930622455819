import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useUser } from '@root/store/selectors/useUser'
import { syncBoardsActions } from '@root/store/slices/sync.boards'

export const useBoardStreaming = (id?: string) => {
	const user = useUser()
	const dispatch = useDispatch()

	useEffect(() => {
		if (!user?.uid) {
			if (user?.isFromLocal) {
				return
			}
		}

		if (id && user?.isFromLocal === false) {
			dispatch(syncBoardsActions.streamBoard({ boardId: id }))
		}
	}, [dispatch, id, user])
}
