import { Bouncer } from '@comps/static/bouncer';
import { Text } from '@comps/typography/text';
import { useWatcher, useWatcherObserver } from '@root/hooks/useWatcher';
import { RootState } from '@root/store';
import { watcherActions } from '@root/store/slices/watchers';
import { WatcherContext, WatcherID, WatcherState } from '@root/store/slices/watchersTypes';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FetchDone } from './fetch.done';
import { FetchFail } from './fetch.fail';

export const Fetcher: React.FC<{ onMount: (watcherId: WatcherID) => void; children: React.ReactElement[] }> = ({ onMount, children }) => {
	const { watcherId } = useWatcher()
	const { state: observerWatcher, context: observerWatcherContext } = useWatcherObserver(watcherId)
	const user = useSelector((state: RootState) => state.auth.user)
	const dispatch = useDispatch()
	const [watcher, setWatcher] = useState<WatcherState | undefined>(undefined)
	const [watcherContext, setWatcherContext] = useState<WatcherContext | undefined>(undefined)

	useEffect(() => {
		if (observerWatcher) {
			setWatcher(observerWatcher)
		}
	}, [observerWatcher])

	useEffect(() => {
		if (observerWatcherContext) {
			setWatcherContext(observerWatcherContext)
		}
	}, [observerWatcherContext])

	useEffect(() => {
		if (user?.isFromLocal) {
			return
		}
		onMount(watcherId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, onMount])

	useEffect(() => {
		return () => {
			console.log('Ready for deletion')
			// Unmount the watcher
			dispatch(watcherActions.readyForDeletion({ watchers: [watcherId] }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const contextErrorContent = useMemo(
		() =>
			children.find((c) => {
				if (c.type == FetchFail && c.props.for == watcherContext?.message) {
					return true
				}
			}),
		[children, watcherContext]
	)

	const generalErrorContent = useMemo(
		() =>
			children.find((c) => {
				if (c.type == FetchFail) {
					return true
				}
			}),
		[children]
	)

	const doneContent = useMemo(
		() =>
			children.find((c) => {
				if (c.type == FetchDone) {
					return true
				}
			}),
		[children]
	)

	if (!watcher || watcher == 'started') {
		return <Bouncer />
	}

	if (watcher == 'fail') {
		if (contextErrorContent) {
			return contextErrorContent
		}

		if (generalErrorContent) {
			return generalErrorContent
		}

		return <Text>Failed but couldn't find a matching FetcherFail children</Text>

		// if (watcherContext?.message == 'no-invite') {
		//     return <Text>No invite</Text>
		// }

		// return
	}

	return doneContent || <Text>Couldn't find the done state.</Text>
}
