import grpcWeb from 'grpc-web'

import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'

export class PlainInterceptor implements grpcWeb.UnaryInterceptor<any, any> {
	async intercept(
		request: grpcWeb.Request<any, any>,
		invoker: (request: grpcWeb.Request<any, any>) => Promise<grpcWeb.UnaryResponse<any, any>>
	) {
		return invoker(request).then((response) => {
			return response
		})
	}
}


export class GenericUnaryInterceptor implements grpcWeb.UnaryInterceptor<Packet, any> {
	token: string

	constructor(token: string) {
		this.token = token
	}

	async intercept(
		request: grpcWeb.Request<Packet, any>,
		invoker: (request: grpcWeb.Request<Packet, any>) => Promise<grpcWeb.UnaryResponse<Packet, any>>
	) {
		if (!this.token)
			return new Promise<any>((succ, fail) => {
				fail()
			})

		const meta = request.getMetadata()

		meta.Authorization = 'Bearer ' + this.token

		return invoker(request).then((response) => {
			return response
		})
	}
}

export class GenericStreamInterceptor implements grpcWeb.StreamInterceptor<Packet, any> {
	token?: string

	constructor(token: string) {
		this.token = token
	}

	intercept(
		request: grpcWeb.Request<Packet, any>,
		invoker: (request: grpcWeb.Request<Packet, any>) => grpcWeb.ClientReadableStream<any>
	) {
		class InterceptedStream implements grpcWeb.ClientReadableStream<any> {
			stream: grpcWeb.ClientReadableStream<any>

			constructor(stream: grpcWeb.ClientReadableStream<any>) {
				this.stream = stream
			}

			on(eventType: string, callback: any) {
				if (eventType === 'data') {
					const cb = (response: any) => {
						callback(response)
					}
					this.stream.on(eventType, cb)
				} else if (eventType === 'error') {
					this.stream.on('error', callback)
				} else if (eventType === 'metadata') {
					this.stream.on('metadata', callback)
				} else if (eventType === 'status') {
					this.stream.on('status', callback)
				} else if (eventType === 'end') {
					this.stream.on('end', callback)
				}
				return this
			}

			removeListener() {}

			cancel() {}
		}
		if (!this.token) return undefined as any

		const meta = request.getMetadata()
		meta.Authorization = 'Bearer ' + this.token

		return new InterceptedStream(invoker(request))
	}
}
