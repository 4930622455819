import { InsetSize } from '@comps/layout/inset'
import { FontStyles } from '@comps/typography/text'
import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useRef } from 'react'
import styled, { css, keyframes } from 'styled-components'

type InputStyle = 'simple' | 'block'
type InputColor = 'normal' | 'toast'

type Props = Omit<React.ComponentProps<'input'>, 'ref'> & {
	onValueChange?: (value: string) => void
	padding?: keyof typeof InsetSize
	inputRef?: any
	forceValue?: string
	centerAlign?: boolean
	stickToModalTop?: boolean
	floatToModalTop?: boolean
	isFromApp?: boolean
	grow?: boolean
	parentHasNegativeMargins?: boolean
	inputStyle?: InputStyle
	hasIcon?: boolean
	color?: InputColor
}

export const ReffedTextInput: React.ForwardRefRenderFunction<undefined, Props> = (
	{
		onValueChange,
		hasIcon,
		parentHasNegativeMargins,
		centerAlign,
		padding = 'tiny',
		isFromApp = false,
		forceValue,
		grow,
		inputStyle = 'simple',
		autoFocus,
		color = 'normal',
		...p
	},
	ref
) => {
	const currentRef = useRef<any>()

	const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
		(e) => {
			onValueChange?.(e.currentTarget.value)
			// setValue(e.currentTarget.value)
		},
		[onValueChange]
	)

	useEffect(() => {
		if (p.defaultValue) {
			const event = new CustomEvent('change', {
				bubbles: true,
				cancelable: true,
			})
			currentRef.current.dispatchEvent(event)
		}
	}, [p.defaultValue, onValueChange])

	useEffect(() => {
		if (!ref) return
		if (typeof ref == 'function') ref?.(currentRef.current)
		else if (typeof ref == 'object') (ref as any).current = currentRef.current
	}, [currentRef, ref])

	useEffect(() => {
		if (autoFocus) {
			currentRef.current?.focus()
		}
	}, [currentRef, autoFocus])

	const extraProps = useMemo(() => {
		if (p.inputMode == 'email') {
			return {
				autocomplete: 'off',
				autocorrect: 'off',
				spellcheck: 'false',
				autocapitalize: 'off'
			}
		}
		return {}
	}, [p.inputMode])

	return (
		<Input
			padding={padding}
			parentHasNegativeMargins={parentHasNegativeMargins}
			type="text"
			// value={value}
			isFromApp={isFromApp}
			inputStyle={inputStyle}
			inputColor={color}
			name={p.placeholder}
			key={p.placeholder}
			onChange={handleChange}
			inputMode={p.inputMode}
			hasIcon={hasIcon}
			{...p}
			grow={grow}
			ref={currentRef}
			autoFocus={autoFocus}
			{...extraProps}
		/>
	)
}
export const TextInput = React.forwardRef(ReffedTextInput)

const shakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(4px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(8px, 0, 0);
  }
`

export const InputBlockStyles = css`
	${FontStyles.body};
	border: none;
	display: block;
	border-radius: 12px;
	${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
	${(p) => p.theme.text.regular.r.css('color')};
	${InsetSize.buttonMedium};
	width: 100%;
	line-height: 1.5em;
	box-sizing: border-box;
	margin: -1px;
	border-radius: 12px;
	cursor: text !important;

	&:focus {
		${(p) => p.theme.backgrounds.subtle.s1.css('background-color')};
	}

	&.error {
		animation: ${shakeAnimation} 1.4s ease-in-out both;
	}

	&::placeholder {
		${(p) => p.theme.text.subtle.r.css('color')};
	}
`

const Input = styled.input<{
	padding: keyof typeof InsetSize
	stickToModalTop?: boolean
	floatToModalTop?: boolean
	centerAlign?: boolean
	parentHasNegativeMargins?: boolean
	inputStyle?: InputStyle
	hasIcon?: boolean
	isFromApp?: boolean
	grow?: boolean
	inputColor: InputColor
}>`
	border: none;
	${(p) => InsetSize[p.padding]}
	${FontStyles.body};

	display: block;
	border-radius: 12px;
	flex-grow: 0;
	background-color: transparent;
	${(p) => p.theme.text.regular.r.css('color')};
	box-sizing: border-box;
	margin: 0;
	box-shadow: none !important;
	border: none !important;
	cursor: text !important;

	${(p) =>
		p.floatToModalTop &&
		css`
			padding: 10px 10px;
			${p.theme.backgrounds.subtle.t1.css('background-color')};
			flex-grow: 0;
			margin-bottom: 20px;
			margin: -2px -2px 20px -2px;
		`}

	${(p) =>
		p.stickToModalTop &&
		css`
			padding: 20px 20px;
			flex-grow: 0;
			${p.theme.border.subtle.r.css('border-bottom', '1px solid')};
			border-radius: 0;
			width: 100%;
		`};

	${(p) =>
		p.centerAlign &&
		css`
			text-align: center;
		`}
	${(p) =>
		p.grow &&
		css`
			flex-grow: 1;
		`}

	&:focus {
		${(p) => p.theme.backgrounds.subtle.r.css('background-color')}
	}

	&::placeholder {
		${(p) => p.theme.text.subtle.r.css('color')};
	}

	${(p) =>
		p.inputStyle === 'block' &&
		css`
			${InputBlockStyles};
		`}

	${(p) =>
		p.inputColor == 'toast' &&
		css`
			${p.theme.backgrounds.dark.t1.css('background-color')};
			color: #fff;

			&:focus {
				${p.theme.backgrounds.dark.t2.css('background-color')};
			}
		`}

	${(p) =>
		p.parentHasNegativeMargins &&
		css`
			width: 0;
		`}

	${(p) =>
		p.hasIcon &&
		css`
			padding-left: 40px;
		`}

	${(p) => p.isFromApp && css`
		padding: 15px 20px;
		background-color: rgba(255,255,255,0.075);
		
		border-radius: 15px;
		font-size: 15px !important;
		font-weight: 500 !important;
		color: white !important;

		&:focus {
			background-color: rgba(255, 255, 255, 0.125) !important;
			
		}
	`}

	&::placeholder {
		${p => p.theme.text.proSubtleText.r.css('color')};
	}
`
