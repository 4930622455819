import { RootState } from '@root/store';
import { useActivities } from '@root/store/selectors/useActivities';
import { useBoards } from '@root/store/selectors/useBoards';
import { flowsActions } from '@root/store/slices/flows';
import { AllPotentialFlows, FlowID } from '@root/store/slices/flows.types';
import { useRoutines } from '@root/store/slices/routines';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useSearchParams } from 'react-router-dom';

import { useFlowExit } from './useFlowExit';

export const FlowsRouterHandler: React.FC = () => {
	const [qp, setQp] = useSearchParams()
	const dispatch = useDispatch()
	const activities = useActivities()
	const routines = useRoutines()
	const boards = useBoards()

	const activeFlows = useSelector((state: RootState) => state.flows.activeFlows)
	const previousFlows = useRef(activeFlows)
	const exit = useFlowExit()
	// const location = useLocation()
	const sharingActivitiesUrl = useMatch('/activities/:activityId/*')
	const sharingActivityId = sharingActivitiesUrl?.params.activityId
	const lastHandledFlow = useRef<any>()
	useEffect(() => {
		if (activeFlows != previousFlows.current) {
			if (activeFlows.length == 0) {
				exit()
			}
			previousFlows.current = activeFlows
		}
	}, [activeFlows, exit])

	useEffect(() => {
		const flow = qp.get('flow') as FlowID | null

		if (!flow) {
			dispatch(flowsActions.exitFlows())
			return
		}
		if (!AllPotentialFlows.includes(flow)) {
			console.error('Flow not recognized.')
			return
		}

		if (flow == lastHandledFlow.current) return

		if (activeFlows.includes(flow)) return

		switch (flow) {
			case 'plan-session':
				const start = qp.get('start')
				const end = qp.get('end')
				dispatch(
					flowsActions.startFlow({
						flowType: 'plan-session',
						start: start || undefined,
						end: end || undefined,
					})
				)
				break
			case 'create-or-edit-activity':
				const activityId = qp.get('id')
				dispatch(
					flowsActions.startFlow({
						flowType: 'create-or-edit-activity',
						activityData: activityId ? activities.at[activityId] || undefined : undefined,
					})
				)
				break
			case 'routine-schedule-editing':
				const routineScheduleId = qp.get('id')
				dispatch(
					flowsActions.startFlow({
						flowType: 'routine-schedule-editing',
						routineScheduleData: routineScheduleId ? routines.schedules.at[routineScheduleId] || undefined : undefined,
					})
				)
				break
			case 'create-or-edit-board':
				const boardId = qp.get('id')
				dispatch(
					flowsActions.startFlow({
						flowType: 'create-or-edit-board',
						boardData: boardId ? boards.at[boardId] || undefined : undefined,
					})
				)
				break
			case 'share':
				if (sharingActivityId)
					dispatch(
						flowsActions.startFlow({
							flowType: 'share',
							activityId: sharingActivityId,
						})
					)
		}
		lastHandledFlow.current = flow
	}, [qp, setQp, routines, dispatch, activeFlows, activities, boards, sharingActivityId])

	return null
}
