// eslint-disable-next-line import/no-extraneous-dependencies
import { stemmer } from 'stemmer';

export function ReturnSearchableTerms(text: string) {
	const words = new Set()
	const segmenter = new Intl.Segmenter('en', { granularity: 'word' })
	for (const { segment, isWordLike } of segmenter.segment(text)) {
		if (isWordLike) {
			let word = segment.toLowerCase()
			word = stemmer(word)
			words.add(word)
		}
	}
	return Array.from(words)
}
