import { Route, Routes, useLocation } from 'react-router';

import { Router } from './router';
import { useUser } from './store/selectors/useUser';
import { AuthHandlerRouter } from './views/Account.AuthHandler.router';
import { ConditionallyActivateEmail } from './views/Account.ConditionallyActivateEmail';
import { ActivateEmail } from './views/Account.EmailActivation';
import { Forgot } from './views/Account.Forgot';
import { Login } from './views/Account.Login';
import { AccountMain } from './views/Account.Main';
import { Signup } from './views/Account.Signup';
import { Uninvited } from './views/Account.Uninvited';

export const AppTunnel: React.FC = () => {
	const user = useUser()
	const location = useLocation()

	if (location.pathname == '/auth-callback') return <AuthHandlerRouter />

	if (!user) {
		return (
			<>
				<ConditionallyActivateEmail />
				<Routes key="logged-out-routers">
					<Route path="/signup" element={<Signup key="signup" />} />
					<Route path="*" element={<AccountMain />}>
						<Route path="forgot" element={<Forgot />} />
						<Route path="*" element={<Login />} />
					</Route>
				</Routes>
			</>
		)
	}

	if (user.emailActivated == false) {
		return <ActivateEmail />
	}

	if (user.hasBeenInvited == false) {
		return <Uninvited />
	}

	return (
		<Routes key="logged-in-routers">
			<Route path="*" element={<Router />} />
		</Routes>
	)
}
