import { Button } from '@comps/interactive/button'
import { WatcherButton, WatcherButtonAction } from '@comps/interactive/watcherButton'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { boyActions } from '@root/store/slices/boy'
import { getDataAttribute } from '@root/utils/general'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'

export const BoyPanel: React.FC = () => {
    const [showMenu, setShowMenu] = useState(false)
    const dispatch = useDispatch()
    const [showUI, setShowUI] = useState(false)

    const activateUser = useCallback<WatcherButtonAction>((watcherId, e) => {
        const index = getDataAttribute(e, 'id')
        dispatch(boyActions.activateUser({ watcherId, index: parseInt(index) }))
    }, [dispatch])

    const deleteUser = useCallback<WatcherButtonAction>((watcherId, e) => {
        const index = getDataAttribute(e, 'id')
        dispatch(boyActions.deleteUser({ watcherId, index: parseInt(index) }))
    }, [dispatch])

    const inviteUser = useCallback<WatcherButtonAction>((watcherId, e) => {
        const index = getDataAttribute(e, 'id')
        dispatch(boyActions.inviteUser({ watcherId, index: parseInt(index) }))
    }, [dispatch])

    const grantInvites = useCallback<WatcherButtonAction>((watcherId, e) => {
        const index = getDataAttribute(e, 'id')
        dispatch(boyActions.grantInvites({ watcherId, index: parseInt(index) }))
    }, [dispatch])

    return <Floater showUI={showUI} onMouseEnter={() => setShowUI(true)} onMouseLeave={() => { setShowUI(false) }}>
        <Flex direction='column'>
            { showMenu && <Menu spacing={5}>
                <WatcherButton action={activateUser} insetPadding='buttonSmall' color="dark" data-id={1}>
                    User #1 • Activate
                </WatcherButton>
                <WatcherButton action={inviteUser} insetPadding='buttonSmall' color="dark" data-id={1}>
                    User #1 • Invite Himself
                </WatcherButton>
                <WatcherButton action={grantInvites} insetPadding='buttonSmall' color="dark" data-id={1}>
                    User #1 • Grant Invites 
                </WatcherButton>
                <WatcherButton action={deleteUser} insetPadding='buttonSmall' color="dark" data-id={1}>
                    User #1 • Delete 
                </WatcherButton>
                <hr/>
                <WatcherButton action={activateUser} insetPadding='buttonSmall' color="dark" data-id={2}>
                    User #2 • Activate
                </WatcherButton>
                <WatcherButton action={deleteUser} insetPadding='buttonSmall' color="dark" data-id={2}>
                    User #2 • Delete 
                </WatcherButton>
            </Menu>}
            <Button
                color="dark"
                onClick={() => {
                    setShowMenu(s => !s)
                    // dispatch(InterfaceActions.SET_ADD_CARD(session.id))
                }}
            >
                <DevInset>/dev</DevInset>
            </Button>
        </Flex>
    </Floater>
}

const Menu = styled(Flex)`
    padding: 10px;
    background-color: rgba(0,0,0,0.3);
    border-radius: 16px;
    margin-bottom: 20px;
    flex-direction: column;
`

const DevInset = styled.div`
    padding: 0 10px;
`

const Floater = styled.div<{ showUI: boolean }>`
    position: fixed;
    left: 1px;
    bottom: 1px;
    z-index: 9999999999999;
    opacity: 0;

    ${p => p.showUI && css`
        opacity: 1;
    `}
`