import { getUnixTime, subDays } from 'date-fns';
import { put } from 'redux-saga/effects';

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Database } from '@root/database';
import { collectionInsert } from '@root/utils/general';

import { State } from './notifications.types';
import { NotificationRuneObject } from './runes.types';

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState: State,
	reducers: {
		store: (state, { payload }: PayloadAction<{ objects: NotificationRuneObject[], fromSync?: boolean }>) => {
			payload.objects.forEach(el => {
				const id = el.rune?.id
				if (id) {
					collectionInsert(state, id, el.rune)
				}
			});
			
		},
		placeInStoreLatestNotifications: (state, { payload }: PayloadAction) => {}
	},
})

export const notificationsSagas = {
	*placeInStoreLatestNotifications({ payload }: ReturnType<typeof notificationsSlice.actions.placeInStoreLatestNotifications>) {
		const latest: NotificationRuneObject[] = yield Database.notificationRunes.where('lastModifiedAt').above(getUnixTime(subDays(new Date(), 10))).toArray()
		yield put(notificationsActions.store({ objects: latest }))
	}
}

export const notificationsActions = notificationsSlice.actions
export const notificationsReducers = notificationsSlice.reducer