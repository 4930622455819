import { initializeApp } from 'firebase/app';

const firebaseConfig = process.env.REACT_APP_FIREBASE_CONFIG && process.env.REACT_APP_FIREBASE_CONFIG !== '<no value>' ? 
	JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG) : 
	{
		apiKey: 'AIzaSyDvZ6y_T7IOe6BYTwRUY8aFFLBkCBuH8T8',
		projectId: 'demo-clepside-local',
	}

initializeApp(firebaseConfig)

const EXPORTING_JUST_TO_HAVE_THE_FILE_EXECUTED = {}
export default EXPORTING_JUST_TO_HAVE_THE_FILE_EXECUTED