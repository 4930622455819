import { ThemeBackbone } from '@root/styled';
import { motion } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';

export const ListItem: React.FC<{
	children: any
	hovered?: boolean
	preHovered?: boolean
	onMouseIn: (e: any) => void
	onMouseOut: (e: any) => void
	onMouseClick: (e: any) => void
	noBorder?: boolean
	borderOffsetLeft?: number
	borderColor?: keyof ThemeBackbone<any>['border']
	borderOffsetRight?: number
	backgroundColor?: keyof ThemeBackbone<any>['backgrounds']
	type?: 'normal' | 'toast'
}> = ({
	children,
	preHovered,
	borderOffsetLeft = 0,
	borderOffsetRight = 0,
	borderColor,
	hovered,
	onMouseIn,
	onMouseOut,
	onMouseClick,
	backgroundColor,
	noBorder = false,
	type,
}) => {
	return (
		// <Inset padding="tiny" negativeX>
		<>
			<Item
				onMouseEnter={onMouseIn}
				onMouseLeave={onMouseOut}
				onClick={onMouseClick}
				hovered={hovered}
				type={type}
				backgroundColor={backgroundColor}
			>
				{children}
			</Item>
			{noBorder ? null : (
				<Border
					borderColor={borderColor}
					preHovered={preHovered}
					hovered={hovered}
					type={type}
					color="dark"
					borderOffsetLeft={borderOffsetLeft}
					borderOffsetRight={borderOffsetRight}
				/>
			)}
		</>
	)
}

const Border = styled.div<{
	hovered?: boolean
	preHovered?: boolean
	borderColor?: keyof ThemeBackbone<any>['border']
	type?: 'normal' | 'toast'
	borderOffsetLeft?: number
	borderOffsetRight?: number
}>`
	margin-left: ${(p) => p.borderOffsetLeft}px;
	margin-right: ${(p) => p.borderOffsetRight}px;
	${(p) => p.theme.border[p.borderColor || 'subtle'].t1.css('background-color')};

	flex-shrink: 0;
	height: 1px;
	bottom: 0;

	${(p) =>
		(p.hovered || p.preHovered) &&
		css`
			background-color: transparent;
		`}

	${(p) =>
		p.type === 'toast' &&
		css`
			background-color: rgba(255, 255, 255, 0.05);
		`}
`

const Item = styled(motion.div)<{
	hovered?: boolean
	backgroundColor?: keyof ThemeBackbone<any>['backgrounds']
	type?: 'normal' | 'toast'
}>`
	border-radius: 8px;
	position: relative;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 10px 10px;
	margin: 0 -10px;
	border-radius: 10px;

	${(p) => p.hovered && p.theme.backgrounds[p.backgroundColor || 'normal'].s1.css('background-color')};

	&:active {
		${(p) => p.theme.backgrounds[p.backgroundColor || 'normal'].s2.css('background-color')};
	}
`
