import { useOutletContext } from 'react-router';

import { ActivityData } from '@root/store/slices/activitiesTypes';
import { InstancedSessionData } from '@root/store/slices/sessionsTypes';

export type SessionOutletContextType = {
    activity: ActivityData | undefined,
    session: InstancedSessionData | undefined
};

export type LoginOutletContextType = {
  isFromApp: boolean
};


export function useAuthOutletContext() {
  return useOutletContext<LoginOutletContextType>();
}

export function useSessionOutletContext() {
  return useOutletContext<SessionOutletContextType>();
}