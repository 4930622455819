import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { IconName } from '@comps/static/activityImage'
import { Database } from '@db/index'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
/* eslint-disable @typescript-eslint/no-unused-vars */
import { put } from 'redux-saga/effects'
import { ULID } from '../commonTypes'
import { ActivityColorKey } from './activities.colors.types'
import { activitiesState } from './activitiesTypes'
import { cardsActions } from './cards'
import { deltaCreate, deltaDelete, deltaUpdate } from './persist'
import { NewPacketDelta, RemovePacketDeltaIdOnly, UpdatePacketDelta } from './persist.utils'
import { SharingInvitationRuneObject } from './runes.types'
import { sharingActions } from './sharing'
import { SyncActivities } from './sync.activities'
import { ActivityPacketObject } from './sync.tsx.packets.types'

const activitiesSlice = createSlice({
	name: 'activities',
	initialState: activitiesState,
	reducers: {
		initialize() {},
		new: (state, { payload }: PayloadAction<NewPacketDelta<PacketType.ACTIVITY> & { id?: ULID } >) => {},
		update: (state, { payload }: PayloadAction<UpdatePacketDelta<PacketType.ACTIVITY>>) => {},
		delete: (state, { payload }: PayloadAction<RemovePacketDeltaIdOnly>) => {},
		loadToStore: (state, { payload }: PayloadAction<{ resourceId: string }>) => {},
		store: (state, { payload }: PayloadAction<{ objects: ActivityPacketObject[], fromSync?: boolean }>) => {
			// TO-DO
			// Add computed properties, etc..
			payload.objects.forEach((p) => {
				if (!p.id) return
				if (p.deleted?.is) {
					delete state[p.id]
				} else {
					state[p.id] = {
						id: p.id,
						...p.data,
						color: p.data.color as ActivityColorKey,
						icon: p.data.icon as IconName
					}
				}
			})
		},
	},
})

export const activitiesSagas = {
	*initialize() {
		const activitiesPackets: ActivityPacketObject[] =
			yield Database.activityPackets.toArray()
		yield put(SyncActivities.placeInStore(activitiesPackets))
	},
	*loadToStore({ payload }: ReturnType<typeof activitiesSlice.actions.loadToStore>): any {
		const invitations: SharingInvitationRuneObject[] = yield Database.sharingInvitationRunes.where('rune.resourceId').equals(payload.resourceId).toArray()

		yield put(sharingActions.store({ objects: invitations.map(i => i.rune) }))
		yield put(cardsActions.loadCardsForResourceId({ resourceId: payload.resourceId }))
		// yield put(fragmentsActions.loadFragmentsForResourceId({ resourceId: payload.resourceId, resourceType: GrantableResourceEnum.ACTIVITY }))
	},
	*store({ payload }: ReturnType<typeof activitiesActions.store>) {
		// if (payload.fromSync) {
		// 	for (const activityPacket of payload.objects) {
		// 		if (!activityPacket?.data || activityPacket?.deleted?.is)
		// 			continue

		// 		// When new activities are received from sync, let's fetch their sessions!
		// 		// yield put(syncSessionsActions.fetchSessionsNeedingFetching())
		// 	}
		// }
	},
	*new({ payload: delta }: ReturnType<typeof activitiesActions.new>) {
		yield deltaCreate({ type: PacketType.ACTIVITY, delta, id: delta.id })
	},
	*update({ payload: delta }: ReturnType<typeof activitiesActions.update>) {
		yield deltaUpdate({ type: PacketType.ACTIVITY, delta })
	},
	*delete({ payload: { id } }: ReturnType<typeof activitiesActions.delete>) {
		yield deltaDelete({ type: PacketType.ACTIVITY, delta: { id, shouldDelete: true } })
	}
}

// yield put(syncSessionsActions.fetchSessions())
export const activitiesActions = activitiesSlice.actions
export const activitiesReducers = activitiesSlice.reducer
