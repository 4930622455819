import { PacketType, RuneType } from '@clepside/clepsidejs/lib/commons/core_pb';
// import { LineNode } from '@comps/complex/cards/lexicalEditor.lineNode';
// import { $insertNodeFromFragment } from '@comps/complex/cards/lexicalEditor.MountFragmentsPlugin';
// import { $recusivelyExportMarkdown } from '@comps/complex/cards/lexicalEditor.utils';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createHeadlessEditor } from '@lexical/headless';

import { normalizeFragmentPacket } from './fragments';
import { FragmentPlainTextSpecifics } from './fragments.types';
import { ReturnSearchableTerms } from './persist.search.returnSearchableTerms';
import { RuneObject } from './runes.types';
import { PacketObject } from './sync.tsx.packets.types';

async function processLexicalContent(normalised: any) {
    // if (normalised) {
    //     const searchTerms = new Promise((resolve, reject) => {
    //         try {
    //             const editor = createHeadlessEditor({
    //                 nodes: [
    //                     LineNode
    //                 ],
    //                 onError: () => {},
    //             });

    //             editor.update(() => {
    //                 try {
    //                     const node = $insertNodeFromFragment(normalised)
    //                     if (node) {
    //                         const str = $recusivelyExportMarkdown(node, '')
    //                         resolve(ReturnSearchableTerms(str))
    //                     }
    //                 } catch (e) {
    //                     reject(e)
    //                 }
    //             })
    //         } catch (e) {
    //             reject(e)
    //         }
    //     }) as any
    //     const terms = await searchTerms
    //     return terms
    // }
    return []
}
export const getSearchableTermsFromPacketOrRune = async (packet?: PacketObject<any>, rune?: RuneObject<any>) => {
    if (packet) {
        if (packet.type == PacketType.FRAGMENT_PLAIN_TEXT) {
            const normalised = normalizeFragmentPacket(packet.id, packet as any) as any
            const specifics = normalised.specifics as FragmentPlainTextSpecifics
            if (specifics.type == 'NOTE') {
                const terms = await processLexicalContent(normalised)  
                return terms
            }
            if (specifics.type == 'CODEBOX') {
                const terms = await ReturnSearchableTerms(specifics.code)
                return terms
            }
            if (specifics.type == 'LINK') {
                const terms = await ReturnSearchableTerms(specifics.link)
                return terms
            }
        }
        if (packet.type == PacketType.FRAGMENT_COMPLETABLE) {
            const normalised = normalizeFragmentPacket(packet.id, packet as any)
            const terms = await processLexicalContent(normalised)             
            return terms
        }
    }
    if (rune) {
        if (rune.type == RuneType.GPT_CARD_MESSAGE) {
            const terms = await ReturnSearchableTerms(rune.rune.body)
            return terms
        }
        return undefined
    }
    return undefined
}