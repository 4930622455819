import { call, takeEvery } from 'typed-redux-saga';

import { RuneType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { NotificationRune } from '@clepside/clepsidejs/lib/runes_v1/notification_pb';
import {
    BatchedSendablesStream, FetchNotificationsRequest
} from '@clepside/clepsidejs/lib/services/sync_v1_pb';
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb';
import { createSlice } from '@reduxjs/toolkit';
import { Database } from '@root/database';
import { SyncGRPC } from '@root/grpc/grpcSync';
import { STREAM_ENDED } from '@root/grpc/utils';

import { notificationsActions } from './notifications';
import { NotificationRuneObject, RuneObject } from './runes.types';
import { processBatchedSendablesStream } from './sync.tsx.handlers';
import { getLastSyncedAt, getRuneObjectFromRemoteObject } from './sync.types';
import { markSyncingAsStarted, reachSyncKeyTemplate } from './sync.utils';

export class SyncNotifications {
    public static runeOrPacket = 'rune' as const

    public static type = RuneType.NOTIFICATION as const

    static fromSendables(res: Sendable.AsObject[] | undefined): RuneObject<any>[] {
        return res?.map((r) => getRuneObjectFromRemoteObject(r?.rune?.notification))
            .filter((f): f is RuneObject<any> => f !== undefined) || []
    }

    static placeInStore(objects: NotificationRuneObject[], fromSync?: boolean) {
        return notificationsActions.store({ objects, fromSync })
    }
    
    static fromRune(res: NotificationRune.AsObject | undefined) {
        return getRuneObjectFromRemoteObject(res)
    }

    static database = Database.notificationRunes
}

const syncNotificationsSlice = createSlice({
    name: 'sync.notifications',
    initialState: {},
    reducers: {
        fetchNotifications: () => {},
    },
})


export const syncNotificationsActions = syncNotificationsSlice.actions

export const syncNotificationsSagas = {
	*fetchNotifications({}: ReturnType<typeof syncNotificationsSlice.actions.fetchNotifications>): any {
        const req = new FetchNotificationsRequest()
		
		const lastSyncAt = yield getLastSyncedAt('Notifications')
        yield markSyncingAsStarted('Notifications')
        req.setLastFetchedAt(lastSyncAt)

        try {
            const channel = yield SyncGRPC.constructNotificationsStreamingChannel(req)
            const receiver = function* (response: BatchedSendablesStream) {
                yield call(processBatchedSendablesStream, response, 'Notifications', reachSyncKeyTemplate('Notifications'), SyncNotifications)
            }
			yield takeEvery(channel, receiver)
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        }
	}
}