import React from 'react';
import styled from 'styled-components';

import { range } from '@utils/general';

interface Props {
	items: number
	spacing: number
	children?: React.ReactNode
}

export const Spread: React.FC<Props> = ({ children, ...props }) => {
	return <Holder {...props}>{children}</Holder>
}

const Holder = styled.div<Props>`
	display: grid;
	grid-template-columns: ${(p) => range(p.items).map(() => 'auto ')};
	grid-column-gap: ${(p) => p.spacing}px;
	align-items: center;
`
