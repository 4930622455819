import { WatchServiceClient } from '@clepside/clepsidejs/lib/services/watch_v1_grpc_web_pb'
import { WatchRequest, WatchResponse } from '@clepside/clepsidejs/lib/services/watch_v1_pb'
import { ClientReadableStream, RpcError } from 'grpc-web'
import { EventChannel, eventChannel } from 'redux-saga'
import { getGRPCMeta } from './utils'

if (!process.env.REACT_APP_API_URL)
    throw new Error('No RAPURL')
const WatchClient = new WatchServiceClient(process.env.REACT_APP_API_URL)

export const WatchGPRC = {
    constructStreamChannel: (request: WatchRequest) => new Promise<EventChannel<WatchResponse | 'END'>>((res, rej) => {
            (async () => {
                try {
                    const meta = await getGRPCMeta()
                    const stream = WatchClient.watch(request, meta)
                    const channel = eventChannel<WatchResponse | 'END'>(mountWatchStreamListeners(stream))
                    res(channel)
                } catch (e) {
                    console.error('Failed to start the stream: ', e)
                    rej(e)
                }
            })();
        }
    ),
}

function mountWatchStreamListeners(stream: ClientReadableStream<WatchResponse | 'END' | 'INTERRUPTED'>) {
    return (emitter: any) => {
        const onData = (d: any) => {
            emitter(d)
        }
        const onError = (err: RpcError) => {
            // Timeout should be handled here.. but seems impossible?
            // Browser seems to get irrelevant interrupts?
            if (err.code == 2) {
                // console.log('Watch interrupted')
                emitter('INTERRUPTED')
            } else {
                console.error('Watch stream error: ', err)
                emitter('END')
            }
        }

        const onEnd = () => {
            emitter('END')
        }

        stream.on('data', onData)
        stream.on('error', onError)
        stream.on('end', onEnd)
        stream.on('metadata', (e) => {})
        stream.on('status', (e) => {
            // console.warn('Watch status changed:', e)
        })

        return () => {
            stream?.removeListener('data', onData)
            stream?.removeListener('error', onError)
            stream?.removeListener('end', onEnd)
            stream?.removeListener('metadata', onEnd)
            stream?.removeListener('status', onEnd)
        }
    }
}
