import { FontStyles } from '@comps/typography/text';
import React, { MouseEventHandler, ReactNode, useCallback } from 'react';
import styled, { css } from 'styled-components';

export const Table: React.FC<{
	children: React.ReactNode[]
	layout: string
	inToast?: boolean
	fullWidth?: boolean
	gap?: string
	onClick?: (id: string) => void
	type?: 'bordered' | 'highlighted'
}> = ({ children, onClick, layout, gap, inToast, type = 'highlighted', fullWidth }) => {
	const handleClick = useCallback<MouseEventHandler>(
		(e) => {
			const rowId = (e.target as any)?.closest('.row')?.getAttribute?.('data-id')
			if (rowId) onClick?.(rowId)
		},
		[onClick]
	)

	return (
		// inToast={inToast}
		<Grid layout={layout} type={type} fullWidth={fullWidth} onClick={handleClick} interactive={!!onClick} inToast={inToast} gap={gap}>
			{children}
		</Grid>
	)
}

export const HeaderCell = styled.div<{ align?: 'right' | 'left' }>`
	${FontStyles.tiny};
	white-space: nowrap;
	text-transform: uppercase;

	letter-spacing: 0.05px;
	margin-bottom: 0px;
	color: ${(p) => p.theme.text.subtle.t1.color};
	padding-bottom: 5px;

	${(p) =>
		p.align === 'right' &&
		css`
			text-align: right;
		`}
`

export const Cell: React.FC<{ children: ReactNode; align?: 'right' | 'left'; noBorder?: boolean; noPaddingLeft?: boolean }> = ({
	children,
	align,
	noBorder,
	noPaddingLeft,
}) => {
	return (
		<CellElement className="cell" align={align} noBorder={noBorder} noPaddingLeft={noPaddingLeft}>
			{children}
		</CellElement>
	)
}
export const CellElement = styled.div<{ align?: 'right' | 'left'; noBorder?: boolean; noPaddingLeft?: boolean }>`
	padding: 10px 0;
	${(p) => p.theme.border.subtle.r.css('border-bottom')};

	${(p) =>
		p.align === 'right' &&
		css`
			text-align: right;
			justify-content: flex-end;
		`}

	${(p) =>
		p.noBorder &&
		css`
			border-color: transparent !important;
		`}
	
	${(p) =>
		p.noPaddingLeft &&
		css`
			padding-left: 0 !important;
		`}
`

export const Row = styled.div`
	display: contents;

	&:last-child .cell {
		border-bottom: none;
	}

	.cell {
		display: flex;
		align-items: center;
	}
`

const Grid = styled.div<{
	layout: string
	inToast?: boolean
	type: 'bordered' | 'highlighted'
	fullWidth?: boolean
	interactive?: boolean
	gap?: string
}>`
	display: grid;
	grid-template-columns: ${(p) => p.layout};
	column-gap: ${(p) => p.gap || '10px'};
	${(p) =>
		p.interactive &&
		css`
			.row:hover .cell {
				${p.theme.backgrounds.subtle.s1.css('background-color', undefined, ' !important')};
			}
			.row:active .cell {
				${p.theme.backgrounds.subtle.s3.css('background-color', undefined, ' !important')};
			}
		`}
	${(p) =>
		p.fullWidth &&
		css`
			width: 100%;
		`}
	${(p) =>
		p.type === 'bordered' &&
		css`
			${Row} {
				.cell {
					border-bottom: 1px solid ${p.theme.border.subtle.t1.color};
					margin: 0 -5px;
					padding: 13px 5px;

					&:last-child {
						padding-right: 15px;
						margin-right: -15px;
					}

					&:first-child {
						padding-left: 15px;
						margin-left: -15px;
					}
				}

				&:hover,
				&:active {
					.cell {
						&:last-child {
							border-radius: 0 12px 12px 0 !important;
						}

						&:first-child {
							border-radius: 12px 0 0 12px !important;
						}
					}
				}
			}
			${Row}:last-child {
				.cell {
					border-bottom-color: transparent;
				}
			}
		`}
	${(p) =>
		p.type === 'highlighted' &&
		css`
			.cell {
				margin: 0 -5px;
				padding-left: 5px;
				padding-right: 5px;

				&:last-child {
					padding-right: 15px;
					margin-right: -15px;
					border-radius: 0 12px 12px 0;
				}

				&:first-child {
					padding-left: 15px;
					margin-left: -15px;
					border-radius: 12px 0 0 12px;
				}
			}
			${Row}:nth-child(even) {
				.cell {
					background-color: ${p.inToast ? p.theme.toast.background.t1.color : p.theme.backgrounds.subtle.r.color};
				}
			}
		`}
`
