import { differenceInSeconds } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { Spread } from '@comps/layout/spread';
import { useTime } from '@root/hooks/useTime';
import { getDuration } from '@root/utils/dates';

import { DynamicIcon } from './dynamicIcon';

export const CompletionTimer: React.FC<{
	from: Date
	til: Date
}> = ({ from, til }) => {
	const [duration, setDuration] = useState<string>()
	const [progress, setProgress] = useState<number>()
	const now = useTime('minute')

	useEffect(() => {
		const fullDuration = differenceInSeconds(til, from)
		const currentDuration = differenceInSeconds(now, from)
		let ratio = currentDuration / fullDuration
		ratio = ratio > 1 ? 1 : ratio < 0 ? 0 : ratio
		setProgress(ratio)
		const ratioFormatted = parseFloat(parseFloat(getDuration(ratio)).toFixed(2))

		setDuration(Math.floor(ratioFormatted * 100) + '%')
	}, [from, now, til])

	return (
		<Spread items={2} spacing={5}>
			<span>{duration}</span>
			<DynamicIcon type="pie" progress={progress || 0} />
		</Spread>
	)
}
