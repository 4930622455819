import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { useRefTaker } from '@hooks/useRefTaker';
import { useDragZoneWithPreciseId } from '@root/hooks/useDragZone';
import { CardLayoutObject } from '@root/store/slices/cards.types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { buildMatrix, copyMatrix, findBoundaries } from './cardGrid.commons';
import { CardDatasource, Matrix } from './cardGrid.types';
import { getSizeForCardType, useCardGridAddCard, useCardPlanner } from './cardGrid.useAddCard';
import { useCardDrag } from './cardGrid.useCardDrag';
import { useCardResize } from './cardGrid.useCardResize';

const matrixToLayouts = (m: Matrix) => {
	const cards: { [id: string]: CardLayoutObject } = {}
	const cardsSeenBefore: { [cardId: string]: boolean } = {}
	for (let i = 0; i < m.length; i++) {
		for (let j = 0; j < m[0].length; j++) {
			if (!m[i][j]) continue
			if (!cardsSeenBefore[m[i][j]]) {
				cardsSeenBefore[m[i][j]] = true
				const layout = findBoundaries(m, i, j)

				if (layout) {
					cards[m[i][j]] = {
						y: layout.y,
						x: layout.x,
						w: layout.w,
						h: layout.h,
					}
				}
			}
		}
	}
	return cards
}

export const useCardMatrix = (cardsDatasource: CardDatasource[], resourceId: string, resourceType: GrantableResourceEnum) => {
	const m = useRef(buildMatrix(10, 12))
	const [renderedMatrix, setRenderedMatrix] = useState(copyMatrix(m.current))
	const render = useCallback(() => {
		setRenderedMatrix(copyMatrix(m.current))
	}, [])

	const { placeCardInMatrix } = useCardGridAddCard(m, cardsDatasource)
	const { planNewCard } = useCardPlanner(m, cardsDatasource)
	const { container, setContainer, dragZoneId } = useDragZoneWithPreciseId('board')

	const [gridRef, setGridRef] = useRefTaker()
	const { placeholder: dragPlaceholder } = useCardDrag(gridRef, m, placeCardInMatrix, render, container, dragZoneId, resourceId, resourceType)
	const { placeholder: resizePlaceholder } = useCardResize(gridRef, m, placeCardInMatrix, render, container, dragZoneId)
	const placeholder = dragPlaceholder || resizePlaceholder

	const { maxRow, tallness }: { maxRow: number; tallness: number } = useMemo(() => {
		const rows = cardsDatasource.map(function (o) {
			const layout = o.gridLayout
			if (layout) {
				return layout.pos.y + layout.size.h
			}
			return 0
		})
		if (placeholder) {
			rows.push(placeholder.layout.y + placeholder.layout.h)
		}

		let row = Math.max(...rows, 0)

		if (row < 10) row = 10

		return { maxRow: row, tallness: row + 10 }
	}, [cardsDatasource, placeholder])

	useEffect(() => {
		m.current = buildMatrix(tallness, 12)
		
		const sorted = cardsDatasource.sort((a, b) => {
				return a.verticalLayout > b.verticalLayout ? 1 : -1
		})
		const unsupported: Array<CardType> = [
			CardType.AI_CHAT,
			CardType.AI_GIST,
			CardType.CODEBOX,
			CardType.COUNTDOWN,
			CardType.LINK_LIST,
			CardType.CARDTYPE_DO_NOT_USE,
		]
		const filteredOut = sorted.filter((c) => {
			if (unsupported.includes(c.type)) { return false}
			return true
		})
		filteredOut.forEach((c) => {

			const gridPos = c.gridLayout
			if (gridPos) {
				placeCardInMatrix(c.id, gridPos.size, gridPos.pos)
			} else {
				placeCardInMatrix(c.id, getSizeForCardType(c.type))
			}
			// 
		})
		render()
	}, [cardsDatasource, tallness, placeCardInMatrix, render])

	const cards = useMemo(() => {
		if (!renderedMatrix) return []
		const renderedLayouts = matrixToLayouts(renderedMatrix)
		return cardsDatasource.map((c) => {
			return { ...c, layout: renderedLayouts[c.id] }
		})
	}, [renderedMatrix, cardsDatasource])

	// useEffect(() => {
	// 	cardById.current = cards.reduce((acc, c) => {
	// 		acc[c.id] = c
	// 		return acc
	// 	}, {} as { [cardId: string]: CardDatasourcePlusLayout })
	// }, [cards])

	return { setContainer, cards, setGridRef, tallness, maxRow, planNewCard, dragZoneId, placeholder }
}
