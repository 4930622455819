import { differenceInSeconds, isBefore } from 'date-fns';
import { useMemo } from 'react';

import { useTime } from '@root/hooks/useTime';

import { InstancedSessionData } from '../slices/sessionsTypes';

export const useLastSession = (ofActivityId?: string): InstancedSessionData | undefined => {
	const now = useTime()
	const sessions: any = useMemo(
		() =>
			({
				at: {},
				all: [],
			} as any),
		[]
	)

	return useMemo(() => {
		if (!ofActivityId) return undefined
		let distance = Number.MAX_SAFE_INTEGER
		let lastSession: InstancedSessionData | undefined = undefined

		sessions.all.forEach((sId: string) => {
			const s = sessions.at[sId]
			if (s.activityId === ofActivityId) {
				if (isBefore(s.end, now) && differenceInSeconds(now, s.end) < distance) {
					distance = differenceInSeconds(now, s.end)
					lastSession = s
				}
			}
		})

		return lastSession
	}, [sessions, now, ofActivityId])
}
