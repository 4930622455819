import { ULID } from '../commonTypes';

export type WatcherState = 'done' | 'fail' | 'started'
export type WatcherID = ULID

export interface WatcherContext {
    type: 'error',
    end?: 'client' | 'server',
    toast?: boolean
    message?: string
    displayMessage?: string
}

export interface IWatchers {
    [key: WatcherID]: {
        state?: WatcherState
        isFetcher?: boolean
        context?: WatcherContext
    }
}

export const State: IWatchers = {}
