import NavigationStackCore from '@root/Router.NavigationStackCore'
import { useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'

export const NavigationStackLink: React.FC<{ to: string; className?: string; children: React.ReactNode | React.ReactNode[] }> = ({
	children,
	to,
	className,
}) => {
	const onMouseDown = useCallback((e: any) => e.stopPropagation(), [])
	const location = useLocation()
	const onClick = useCallback(
		(e: any) => {
			e.stopPropagation()
			NavigationStackCore.push(location.pathname + location.search)
		},
		[location]
	)

	return (
		<Link to={to} className={className} onMouseDown={onMouseDown} onClick={onClick}>
			{children}
		</Link>
	)
}
