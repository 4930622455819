import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

function getFromApp() {
	const queryParams = new URLSearchParams(location.search);
	const fromApp = queryParams.get('from-app');

	return !!fromApp
}

// eslint-disable-next-line
export var IS_FROM_APP: boolean | null  = null
export function useFromApp() {

	const location = useLocation();
	const navigate = useNavigate();
 
	useEffect(() => {
		if (IS_FROM_APP) {
			const queryParams = new URLSearchParams(location.search);
			if (queryParams.has('from-app')) {
				queryParams.delete('from-app');

				navigate({
					pathname: location.pathname,
					search: `?${queryParams.toString()}`, 
				}, { replace: true }); 
			}
		}
	}, [location, navigate])
	
	return useMemo(() => {
		if (IS_FROM_APP) return { isFromApp: true }
		if (getFromApp()) {
			IS_FROM_APP = true
			return { isFromApp: true }
		}
		return { isFromApp: false }
	}, [])
 }