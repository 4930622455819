import { Text } from '@comps/typography/text';
import { useRefTaker } from '@hooks/useRefTaker';
import { useTime } from '@hooks/useTime';
import { CoordsAndWidth } from '@root/store/commonTypes';
import { format, isSameDay } from 'date-fns';
import React, { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';

import { SessionPlacerDimensions } from './sessionPlacer.types';
import { calculateCalendarCoordinates, getXOffsetforDayIndex } from './sessionPlacer.utils';

export const TimeBar: React.FC<{
	vector: number[]
	dayIndex: number
	presentedDates: Date[]
	dimensions: SessionPlacerDimensions
	offsetLeft?: number
}> = ({ vector, dimensions, presentedDates, offsetLeft }) => {
	const time = useTime('minute')
	const [ref, setRef] = useRefTaker()

	const coords = useMemo((): CoordsAndWidth => {
		let x = -5
		for (let i = 0; i < presentedDates.length; i++) {
			if (isSameDay(presentedDates[i], new Date(time))) {
				const offsetIfNeeded = presentedDates.length > 1 ? offsetLeft || 0 : 0
				x = getXOffsetforDayIndex(dimensions, i) - 4 + offsetIfNeeded
			}
		}

		return {
			y: calculateCalendarCoordinates(new Date(time), vector),
			x,
			width: dimensions.dayWidth + 8,
		}
	}, [vector, dimensions, time, offsetLeft, presentedDates])

	const timeString = useMemo(() => {
		return format(new Date(time), 'kk:mm')
	}, [time])

	const shouldShow = useMemo(() => {
		for (const day of presentedDates) {
			if (isSameDay(day, time)) return true
		}
		return false
	}, [time, presentedDates])

	if (!shouldShow) return null

	const timeSplit = timeString.split(':')

	return (
		<Line coords={coords} ref={setRef} className="time-bar">
			<CurrentTime level="tiny">
				<Dot />
				{timeSplit[0]}
				<Blinker>:</Blinker>
				{timeSplit[1]}
			</CurrentTime>
		</Line>
	)
}
const blinkAnimation = keyframes`
  0% { opacity: 1; transform: scale(1.1);}
  50% { opacity: 0.5; transform: scale(0.9); }
  100% { opacity: 1; transform: scale(1.1);}
`

const Dot = styled.div`
	width: 3px;
	height: 3px;
	margin-left: 2px;
	margin-right: 3px;
	flex-shrink: 0;
	border-radius: 999px;
	/* ${(p) => p.theme.backgrounds.clepside.r.css('background-color')}; */
	background-color: #fff;
	animation: ${blinkAnimation} 2.3s infinite ease-in;
`
const Blinker = styled.span`
	animation: ${blinkAnimation} 2.3s infinite ease-in;
	display: inline-block;
	margin: 0 0.5px;
	position: relative;
	top: -1px;
`

const Line = styled.div<{ coords: CoordsAndWidth }>`
	position: absolute;
	height: 2px;
	border-radius: 9999px;
	width: ${(p) => p.coords.width - 10}px;
	margin-top: -1px;
	z-index: 1000;
	margin-left: 5px;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
	top: ${(p) => p.coords.y}px;
	left: ${(p) => p.coords.x}px;
	pointer-events: none;
	${(p) => p.theme.text.regular.r.css('background-color')};
`

const CurrentTime = styled(Text)`
	height: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -16px;
	left: 0px;
	opacity: 1;
	border-radius: 5px 5px 0 0;
	padding: 2px 3px;
	${(p) => p.theme.text.regular.r.css('background-color')};
	color: #fff;
`
