import { usePresence } from 'framer-motion';
import { useEffect, useState } from 'react';

export const usePagePresence = () => {
	const [show, setShow] = useState(false)
	const [isPresent, safeToRemove] = usePresence()

	useEffect(() => {
		setTimeout(() => {
			setShow(true)
		}, 0)
	}, [])

	useEffect(() => {
		if (!safeToRemove) return
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions

		if (!isPresent) {
			setShow(false)
			setTimeout(safeToRemove, 400)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPresent])

	return { show }
}
