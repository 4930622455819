import { FlowID } from '@root/store/slices/flows.types'
import { motion, useAnimate, usePresence } from 'framer-motion'
import { MouseEventHandler, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { FlowsActivityCreateUpdate } from './Flows.Activity.CreateUpdate'
import { FlowsActivitySelectIcon } from './Flows.Activity.SelectIcon'
import { FlowsBoardOverview, FlowsBoardOverviewHeaderAction } from './Flows.Board.Overview'
import { FlowPlanSelectActivity } from './Flows.Plan.SelectActivity'
import { FlowsRoutineScheduleDaily } from './Flows.RoutineSchedule.Daily'
import { FlowsRoutineScheduleEditing } from './Flows.RoutineSchedule.Editing'
import { FlowsSelectColor } from './Flows.SelectColor'
import { FlowsSelectDate } from './Flows.SelectDate'
import { FlowsSharing } from './Flows.Sharing'
import { FlowsSharingSelectRole } from './Flows.Sharing.SelectRole'
import { HorizontalScrollerPhaseDetails, useFlowPhase } from './useFlowPhase'

export function constructFlowPages<K extends FlowID>(id: K, title: string): Array<HorizontalScrollerPhaseDetails> | undefined {
	switch (id) {
		case 'plan-session':
			return [
				{
					key: 'select-activity',
					node: <FlowPlanSelectActivity key="plan-select-activity" />,
					title,
					width: 350,
					height: 540,
				},
			]
		case 'create-or-edit-activity':
			return [
				{
					key: 'overview',
					title,
					node: <FlowsActivityCreateUpdate key="create" />,
					width: 350,
					height: 370,
				},
				{
					key: 'select-color',
					title: 'Select Activity Color',
					node: <FlowsSelectColor forEntity="activity" key="select-color" />,
					width: 350,
					height: 540,
				},
				{
					key: 'select-icon',
					title: 'Select Activity Color',
					node: <FlowsActivitySelectIcon key="select-icon" />,
					width: 350,
					height: 540,
				},
			]
		case 'routine-schedule-editing':
			return [
				{
					key: 'routine-select-period',
					title: 'Update Schedule',
					node: <FlowsRoutineScheduleEditing key="edit-routine-schedule" />,
					width: 320,
					height: 290,
				},
				{
					key: 'routine-period-daily',
					title: 'Repeat Daily',
					node: <FlowsRoutineScheduleDaily key="routine-period-daily" />,
					width: 320,
					height: 200,
				}
			]
		case 'select-date':
			return [
				{
					key: 'select-date',
					title: 'Select a date',
					node: <FlowsSelectDate key="select-date" />,
					width: 350,
					height: 350,
				}
			]
		case 'create-or-edit-board':
			return [
				{
					key: 'overview',
					title,
					node: <FlowsBoardOverview key="create" />,
					rightHeaderAction: <FlowsBoardOverviewHeaderAction />,
					width: 350,
					height: 260,
				},
				{
					key: 'select-color',
					title: 'Select Activity Color',
					node: <FlowsSelectColor forEntity="session" key="select-color" />,
					width: 350,
					height: 540,
				},
			]
		case 'share':
			return [
				{
					key: 'share-manage',
					title,
					node: <FlowsSharing key="sharing-manage" />,
					width: 400,
					height: 630,
				},
			]
		case 'share-select-role':
			return [
				{
					key: 'share-select-role',
					title,
					node: <FlowsSharingSelectRole key="sharing-select-role" />,
					width: 320,
					height: 400,
				},
			]
	}
}
export const FlowCard: React.FC<{
	className: string
	phases: HorizontalScrollerPhaseDetails[]
	flowId: FlowID
	children: React.ReactNode
}> = ({ className, phases, flowId, children }) => {
	const [scope, animate] = useAnimate()
	const [isPresent, safeToRemove] = usePresence()
	const { viewIndex } = useFlowPhase(flowId, phases)

	useEffect(() => {
		if (!safeToRemove) return

		if (!isPresent) {
			; (async () => {
				await animate('.card', {
					opacity: 0,
					y: 20,
				})
				safeToRemove()
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPresent])

	useEffect(() => {
		; (async () => {
			await animate(
				'.card',
				{
					opacity: 0,
					y: 20,
					display: 'flex',
				},
				{ duration: 0 }
			)
			await animate('.card', {
				opacity: 1,
				y: 0,
			})
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const preventClick = useCallback<MouseEventHandler>((e) => {
		e.stopPropagation()
	}, [])

	return (
		<Holder ref={scope}>
			<FlowCardElement
				onClick={preventClick}
				className={`${className} card`}
				width={phases[viewIndex].width}
				height={phases[viewIndex].height}
				initial={{
					width: phases[viewIndex].width,
					height: phases[viewIndex].height,
				}}
				animate={{
					width: phases[viewIndex].width,
					height: phases[viewIndex].height,
				}}
			>
				{children}
			</FlowCardElement>
		</Holder>
	)
}
export const FlowCardElement = styled(motion.div) <{ width: number; height: number }>`
	background-color: #fff;
	border-radius: 24px;
	box-shadow: 0px 20px 70px rgba(20, 30, 40, 0.2);
	position: absolute;
	display: none;
	flex-direction: column;
	align-items: stretch;
	width: ${(p) => p.width}px;
	height: ${(p) => p.height}px;
	transform-origin: center center;
	overflow: hidden;
`

const Holder = styled.div`
	display: contents;
`
