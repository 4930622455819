import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { Icon } from '@comps/static/icon'
import { FontStyles } from '@comps/typography/text'
import { DragDropCore, DragDropListener } from '@root/cores/dragDropCore'
import { useDraggedCard } from '@root/cores/useDragAndDropContextSelectors'
import { useSelectable } from '@root/hooks/useSelectable'
import { useCardsSelectionConfig } from '@root/store/selectors/useCardsSelectionConfig'
import { CardLayoutObject } from '@root/store/slices/cards.types'
import { SelectableTypes } from '@root/store/slices/selection.types'
import { useAnimate } from 'framer-motion'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Card } from './card'
import { CardInterfaceElementActiveStyles, CardInterfaceElementHoveredStyles, CardInterfaceElementStyles } from './cardGrid.commons'
import { CardDatasourcePlusLayout, CardRectInPx } from './cardGrid.types'
import { getStylesForCard } from './cardGrid.utils'
import { CardPositioner } from './cardPositioner'
import { useGridCardBehaviours } from './gridCard.useGridCardBehaviours'

export const GridCard: React.ForwardRefRenderFunction<
	any,
	{
		resourceId: string
		resourceType: GrantableResourceEnum
		card: CardDatasourcePlusLayout
		dragZoneId: string
		index: number
		releasedAt?: CardLayoutObject
		isBeingDragged?: boolean
		placeholder?: boolean
		gridCellWidth?: number
	}
> = ({ card, resourceId, index, dragZoneId, resourceType, gridCellWidth }, ref) => {
	const lastLayout = useRef<any>(undefined)

	const [show, setShow] = useState(false)

	const showTimer = useRef<any>(null)
	useEffect(() => {
		showTimer.current = setTimeout(() => {
			setShow(true)
		}, 70 * index)
		return () => {
			if (showTimer.current) {
				clearTimeout(showTimer.current)
				showTimer.current = null
			}
		}
	}, [index])

	const calculatedRect = useMemo(() => {
		if (show == false) return undefined
		if (!gridCellWidth) return undefined

		if (!lastLayout.current && !card.layout) {
			return undefined
		}
		if (!lastLayout.current) {
			lastLayout.current = card.layout
		}

		return getStylesForCard(card.layout || lastLayout.current, gridCellWidth)
	}, [card, gridCellWidth, show])

	const [scope, animate] = useAnimate()
	const initialMountDone = useRef(false)

	const { handleResizeStart } = useGridCardBehaviours(resourceId, resourceType)

	const { isDragged, isResized } = useDraggedCard(card.id)
	const { template } = useCardsSelectionConfig(resourceId, resourceType)
	const { isSelected, selectedClassName, anotherOfKind } = useSelectable(SelectableTypes.CARD, card.id, template, resourceId)

	useEffect(() => {
		if (!isDragged) return

		const listener: DragDropListener = (phase, context, e: any) => {
			if (context.type !== 'card-drag') return

			const scrollOffsetContainer = document.querySelector('.card-grid-scroll')
			const scrollOffsetX = scrollOffsetContainer ? scrollOffsetContainer.scrollLeft || 0 : 0
			const scrollOffsetY = scrollOffsetContainer ? scrollOffsetContainer.scrollTop || 0 : 0

			const originX = e.clientX - context.topLeftOffset.x
			const originY = e.clientY - context.topLeftOffset.y

			const x = originX - context.gridOffset.x + scrollOffsetX
			const y = originY - context.gridOffset.y + scrollOffsetY

			const rect: CardRectInPx = {
				xPx: x,
				yPx: y,
				wPx: context.initalSize.width,
				hPx: context.initalSize.height,
			}
			let opacity = 1

			if (phase === 'interrupted') {
				opacity = 0
			}

			try {
				animate(
					'.card',
					{
						x: rect.xPx,
						y: rect.yPx,
						width: rect.wPx,
						height: rect.hPx,
						opacity,
					},
					{
						duration: 0,
					}
				)
			} catch (k) {
				console.error('failed to animate the card continously', k)
			}
		}

		const removeListener = DragDropCore.addListener(dragZoneId, listener)
		return () => {
			removeListener()
		}
	}, [dragZoneId, isDragged, animate, card.id])

	useEffect(() => {
		if (!isResized) return

		const listener: DragDropListener = (phase, context, e: any) => {
			if (context.type !== 'card-resize') return

			const scrollOffsetContainer = document.querySelector('.card-grid-scroll')
			const scrollOffsetX = scrollOffsetContainer ? scrollOffsetContainer.scrollLeft || 0 : 0
			const scrollOffsetY = scrollOffsetContainer ? scrollOffsetContainer.scrollTop || 0 : 0

			const originX = context.startCoords.x - context.gridOffset.x
			const originY = context.startCoords.y - context.gridOffset.y

			const width = e.clientX - context.startCoords.x
			const height = e.clientY - context.startCoords.y

			const rect: CardRectInPx = {
				xPx: originX + scrollOffsetX,
				yPx: originY + scrollOffsetY,
				wPx: width,
				hPx: height,
			}
			const opacity = 1

			// if (phase === 'interrupted') {
			// 	opacity = 0
			// }

			try {
				animate(
					'.card',
					{
						x: rect.xPx,
						y: rect.yPx,
						width: rect.wPx,
						height: rect.hPx,
						opacity,
					},
					{
						duration: 0,
					}
				)
			} catch (k) {
				console.error('failed to animate the card', k)
			}
		}

		const removeListener = DragDropCore.addListener(dragZoneId, listener)
		return () => {
			removeListener()
		}
	}, [dragZoneId, isResized, animate, card.id])

	useEffect(() => {
		return () => {
			// if (animateTimer.current) clearInterval(animateTimer.current)
			if (showTimer.current) clearInterval(showTimer.current)
		}
	}, [])

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-extra-semi
		;(async () => {
			if (isDragged) {
				await animate(
					'.card',
					{
						opacity: 0,
					},
					{
						duration: 0,
					}
				)
				return
			}
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const shouldDisableCard = useMemo(() => {
		if (anotherOfKind && !isSelected) return true
		if (isDragged) return true
		if (isResized) return true
		return false
	}, [anotherOfKind, isSelected, isDragged, isResized])

	return (
		<React.Fragment key={card.id}>
			<div ref={scope} className="moz-lexical-selection-fix">
				<CardPositioner
					key={card.id}
					coverWholeCard={shouldDisableCard}
					cardId={card.id}
					className={'board-card'}
					resourceId={resourceId}
					animate={animate}
					calculatedRect={calculatedRect}
					isDragged={isResized}
					isResized={isDragged}
					resourceType={resourceType}
				>
					<Holder>
						<Card cardId={card.id} resourceId={resourceId} resourceType={resourceType} />
					</Holder>
					{isSelected ? (
						<>
							{/* <Positioner onPointerDown={handleDragStart} className="bottom" onClick={(e) => e.stopPropagation()}>
								<div className="background hover-transitions">
									<Icon type="grab-area" />
								</div>
							</Positioner> */}
							<ResizeButton onClick={(e) => e.stopPropagation()} onPointerDown={handleResizeStart}>
								<div className="mask hover-transitions">
									<Icon type="resize" size={7} />
								</div>
							</ResizeButton>
						</>
					) : null}
				</CardPositioner>
			</div>
		</React.Fragment>
	)
}

const ResizeButton = styled.div`
	width: 56px;
	height: 56px;
	position: absolute;
	bottom: -36px;
	right: -36px;
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	${(p) => p.theme.border.subtle.r.css('color')};
	z-index: 10000;

	&:hover,
	&:hover * {
		cursor: nwse-resize !important;
	}

	.mask {
		width: 23px;
		height: 23px;
		border-radius: 99px;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		right: 16px;
		bottom: 16px;
		${CardInterfaceElementStyles};
		border-radius: 100%;
		${(p) => p.theme.backgrounds.darkTranslucency.r.css('background-color')};
	}

	&:hover .mask {
		${CardInterfaceElementHoveredStyles};
		/* ${(p) => p.theme.backgrounds.normal.s1.css('background-color')}; */
	}
	&:active .mask {
		${CardInterfaceElementActiveStyles};
		/* ${(p) => p.theme.backgrounds.normal.s1.css('background-color')}; */
	}
`

const Positioner = styled.div`
	position: absolute;
	left: 50%;
	bottom: -30px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	padding: 4px 10px;
	${FontStyles.tiny};

	width: 100px;
	margin-left: -50px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;

	.background {
		padding: 0px 9px 1px 9px;
		${CardInterfaceElementStyles};
	}

	&:hover,
	&:hover * {
		.background {
			${CardInterfaceElementHoveredStyles};
		}
		cursor: grab !important;
		cursor: -moz-grab !important;
		cursor: -webkit-grab !important;
	}

	&:active,
	&:active * {
		.background {
			${CardInterfaceElementActiveStyles};
		}
		cursor: grabbing !important;
		cursor: -moz-grabbing !important;
		cursor: -webkit-grabbing !important;
	}
`

const Holder = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
`

// <!-- html:before {
// 	position: fixed;
// 	top: -1px;
// 	left: 0;
// 	width: 100vw;
// 	height: 1px;
// 	flex-shrink: 0;
// 	z-index: 1000000000;
// 	background: transparent;
// 	content: '';
// 	display: block;
// 	box-shadow: 1px 1px 0px 0px rgba(210, 210, 210, 1);
// } -->

// const { setContainer, container, dragZoneId: fragmentDragZoneId } = useDragZoneWithPreciseId(`fragments-dragging.${card.id}`)

// useEffect(() => {
// 	if (!selectionState) return
// 	const unmount = DragDropCore.mountContainer(fragmentDragZoneId, 2, container)
// 	return () => {
// 		unmount()
// 	}
// }, [container, fragmentDragZoneId, card, selectionState])
