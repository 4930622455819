import { CardType, PacketType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { CardLayout } from '@clepside/clepsidejs/lib/packets_v1/cards_pb';
import { Database } from '@db/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewID } from '@root/utils/general';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, put } from 'redux-saga/effects';
import { v4 } from 'uuid';

import { aiChatCardActions } from './aiChatCard';
import { CardData, cardsState, GrantableResourceType } from './cards.types';
import { fragmentsActions } from './fragments';
import {
    FragmentPlainText_Codebox_Specifics, FragmentPlainText_Note_Specifics
} from './fragments.types';
import { deltaCreate, deltaDelete, deltaUpdate } from './persist';
import { NewPacketDelta, RemovePacketDeltaIdOnly, UpdatePacketDelta } from './persist.utils';
import { selectionActions } from './selection';
import { SyncCards, syncCardsActions } from './sync.cards';
import {
    CardPacketObject, FragmentCompletablePacketObject, FragmentPlainTextPacketObject
} from './sync.tsx.packets.types';

export const normalizeCardPacket = (id: string, s: CardData, lamt: number) => {
	const normalizedCard: CardData = {
		id,
		resourceId: s.resourceId,
		resourceType: s.resourceType,
		layout: s.layout,
		verticallayout: s.verticallayout,
		cardType: s.cardType,
		fragmentsReady: false,
		lastModifiedAt: lamt
		// fragmentsReady will be overwritten

	}
	return normalizedCard
}

export type MoveToNewParentResourcePayload = { cardIds: string[], resourceId: string, resourceType: GrantableResourceType, deleteAfter: boolean }
const cardsSlice = createSlice({
	name: 'cards',
	initialState: cardsState,
	reducers: {
		loadCardsForResourceId(state, { payload }: PayloadAction<{ resourceId: string }>) {},
		new: (state, { payload }: PayloadAction<NewPacketDelta<PacketType.CARD> & { id?: string }> ) => {},
		update: (state, { payload }: PayloadAction<Omit<UpdatePacketDelta<PacketType.CARD>, 'resourceId' | 'resourceType'>>) => {},
		move: (state, { payload }: PayloadAction<{ id: string, resourceId: string, resourceType: GrantableResourceType, layout: CardLayout.AsObject }>) => {},
		delete: (state, { payload }: PayloadAction<RemovePacketDeltaIdOnly>) => {},
		moveToNewParentResource: (state, { payload }: PayloadAction<MoveToNewParentResourcePayload>) => {
			
		},
		// allFragmentsLoaded: (state, { payload }: PayloadAction<{ forCardId: string }>) => {
		// 	if (state.atId[payload.forCardId]) {
		// 		state.atId[payload.forCardId].fragmentsReady = true
		// 	}
		// },
		saveMarkdownForCard: (state, { payload }: PayloadAction<{ forCardId: string, markdown: string }>) => {
			if (state.atId[payload.forCardId]) {
				state.atId[payload.forCardId].markdownForCard = payload.markdown
			}
		},
		normalizeAndStore: (state, { payload }: PayloadAction<{ objects: CardPacketObject[], fromSync?: boolean }>) => {
			// TO-DO
			// Add computed properties, etc..
			payload.objects.forEach((p) => {
				if (!p.id) return
				// if (!p.data?.layout) return
				if (p.deleted?.is) delete state.atId[p.id]
				else {
					const fragmentsAlreadyLoaded = state.atId[p.id]?.fragmentsReady
					state.atId[p.id] = {
						...(state.atId[p.id] ? state.atId[p.id] : {}),
						// Broken?
						...normalizeCardPacket(p.id, p.data as any, p.lastModifiedAt),
						fragmentsReady: fragmentsAlreadyLoaded ? true : false
					}
					
					if (!state.forResourceId[p.data.resourceId])
						state.forResourceId[p.data.resourceId] = []

					if (!state.forResourceId[p.data.resourceId].includes(p.id)) {
						state.forResourceId[p.data.resourceId].push(p.id)
					}
				}
			})
		},
	},
})

export const cardsSagas = {
	*loadCardsForResourceId({ payload }: ReturnType<typeof cardsSlice.actions.loadCardsForResourceId>) {
		const packets: CardPacketObject[] = yield Database.cardPackets.where('data.resourceId').equals(payload.resourceId).toArray()
		yield put(SyncCards.placeInStore(packets))
		
		for (const packet of packets) {
			yield put(fragmentsActions.loadFragmentsForCardId({ cardId: packet.id }))
			yield put(aiChatCardActions.loadMessagesAndChats({ cardId: packet.id }))
		}
	},
	*moveToNewParentResource({ payload }: ReturnType<typeof cardsActions.moveToNewParentResource>) {
		const newResourceCardsPackets: CardPacketObject[] = yield Database.cardPackets.where('data.resourceId').equals(payload.resourceId).toArray()

		let max = 10000
		for (const card of newResourceCardsPackets) {
			if (card.data.verticallayout && card.data.verticallayout?.order > max) {
				max = card.data.verticallayout?.order
			}
		}
		const toDelete = []
		for (const pastId of payload.cardIds) {
			const cardPacket: CardPacketObject = yield Database.cardPackets.get(pastId)
			const textPackets: FragmentPlainTextPacketObject[] = yield Database.fragmentPacketsPlainText.where('data.cardId').equals(pastId).toArray()
			const complatablePackets: FragmentCompletablePacketObject[] = yield Database.fragmentPacketsCompletable.where('data.cardId').equals(pastId).toArray()

			const cardId = v4()

			max += 10000
			yield put(cardsActions.new({
				id: cardId,
				resourceId: payload.resourceId,
				resourceType: payload.resourceType,
				cardType: cardPacket.data.cardType,
				// layout: cardPacket.data.layout,
				layout: undefined as any,
				verticallayout: {
					order: max
				}
			}))

			for (const fragment of textPackets) {
				yield put(fragmentsActions.fragmentPlainTextNew({
					cardId,
					position: fragment.data.position,
					contents: fragment.data.contents
				}))
			}
			
			for (const fragment of complatablePackets) {
				yield put(fragmentsActions.fragmentCompletableNew({
						cardId,
						position: fragment.data.position,
						title: fragment.data.title,
						isCompleted: fragment.data.isCompleted
					}))
			}
			if (payload.deleteAfter) {	
				toDelete.push(pastId)
			}
		}

		yield delay(150)
		
		for (const id of toDelete) {
			yield put(cardsActions.delete({
				id
			}))
		}

		yield put(selectionActions.reset())
	},
	*normalizeAndStore({ payload }: ReturnType<typeof cardsActions.normalizeAndStore>) {
		if (payload.fromSync) {
		for (const card of payload.objects) {
			yield put(syncCardsActions.streamCard({ cardId: card.id }))
		}
	}
	},
	*new({ payload: delta }: ReturnType<typeof cardsActions.new>) {
		const cardId = delta.id || NewID()
		yield deltaCreate({ type: PacketType.CARD, delta, id: cardId })
		
		if (delta.cardType == CardType.NOTE) {
			const initialFragments: FragmentPlainText_Note_Specifics[] = [
				{
					type: 'NOTE',
					level: 'paragraph',
					text: ''
				}
			]

			for (const fragment of initialFragments) {
				yield deltaCreate({ type: PacketType.FRAGMENT_PLAIN_TEXT, delta: {
					position: 1,
					cardId,
					contents: JSON.stringify(fragment)
					}
				})
			}
		} else if (delta.cardType == CardType.CODEBOX) {
			const initialFragment: FragmentPlainText_Codebox_Specifics = {
				type: 'CODEBOX',
				code: ''
			}

			yield deltaCreate({ type: PacketType.FRAGMENT_PLAIN_TEXT, delta: {
				position: 1,
				cardId,
				contents: JSON.stringify(initialFragment)
				}
			})
		}
	},
	*update({ payload: delta }: ReturnType<typeof cardsActions.update>) {
		yield deltaUpdate({ type: PacketType.CARD, delta })
	},
	*move({ payload: delta }: ReturnType<typeof cardsActions.move>) {
		yield deltaUpdate({ type: PacketType.CARD, delta })
	},
	*delete({ payload: { id } }: ReturnType<typeof cardsActions.delete>) {
		yield deltaDelete({ type: PacketType.CARD, delta: { id, shouldDelete: true } })
	}
}

export const cardsActions = cardsSlice.actions
export const cardsReducers = cardsSlice.reducer
