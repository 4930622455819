import { Button } from '@comps/interactive/button'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { Spacer } from '@comps/layout/space'
import { CircleArrow } from '@comps/static/circleArrow'
import { Text } from '@comps/typography/text'
import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

export const DatePeriodButton: React.FC<{
	period: [Date, Date]
}> = ({ period }) => {
	const [, setQp] = useSearchParams()
	return (
		<Holder>
			<Inset padding="medium" negativeX>
				<Button
					onClick={() => {
						setQp({
							'pick-date': 'true',
						})
					}}
					color="textualSubtle"
				>
					<Inset padding="medium">
						<Flex position="relative">
							<Flex direction="column" align="flex-end">
								<Text color="action">Past Month</Text>
								<Text color="regular">
									{format(period[0], 'd MMM')}&nbsp;&rarr;&nbsp;{format(period[1], 'd MMM')}
								</Text>
							</Flex>
							<Spacer size={25} />
							<IconHolder>
								<CircleArrow direction="bottom" />
							</IconHolder>
						</Flex>
					</Inset>
				</Button>
			</Inset>
		</Holder>
	)
}

const Holder = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin: -20px 0;
`

const IconHolder = styled.div`
	position: absolute;
	right: 0;
	top: 1px;
`
