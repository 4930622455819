

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addHours } from 'date-fns'
import { FlowID, FlowQueryContext, FlowRequiredContext, FlowsArchitecture, flowsState } from './flows.types'

const flowsSlice = createSlice({
	name: 'flows',
	initialState: flowsState,
	reducers: {
		startFlow: (state, { payload }: PayloadAction<FlowQueryContext>) => {
			if (state.activeFlows.includes(payload.flowType))
				return
            state.activeFlows.push(payload.flowType)
			switch (payload.flowType) {
				case 'plan-session':
					state.flowContexts[payload.flowType] = {
						flowType: 'plan-session',
						start: payload.start || `${addHours((new Date()).getTime(), 1)}`,
						end: payload.end || `${(new Date()).getTime()}`,
						navigationStack: ['select-activity'],
						createdActivityId: payload.createdActivityId
					}		
					break;
				case 'create-or-edit-activity':
					state.flowContexts[payload.flowType] = {
						flowType: 'create-or-edit-activity',
						id: payload.activityData?.id || undefined,
						name: payload.activityData?.name || '',
						color: payload.activityData?.color || 'denimBlueberry',
						icon: payload.activityData?.icon || 'doggo',
						wallpaper: 'currentColor',
						navigationStack: ['overview'],
						ancestor: payload.ancestor
					}
					break;
				case 'routine-schedule-editing':
					console.log('gregrere', payload)
					state.flowContexts[payload.flowType] = {
						flowType: 'routine-schedule-editing',
						schedule: payload.routineScheduleData,
						navigationStack: ['routine-select-period'],
					}
					break;
				case 'create-or-edit-board':
					state.flowContexts[payload.flowType] = {
						flowType: 'create-or-edit-board',
						id: payload.boardData?.id || '',	
						name: payload.boardData?.name || '',	
						color: payload.boardData?.color || 'denimBlueberry',
						wallpaper: 'currentColor',
						navigationStack: ['overview']
					}
					break
				case 'share':
					state.flowContexts[payload.flowType] = {
						flowType: 'share',
						activityId: payload.activityId,
						navigationStack: ['share-manage']
					}
					break
				case 'share-select-role':
					state.flowContexts[payload.flowType] = {
						flowType: 'share-select-role',
						activityId: payload.activityId,
						email: payload.email,
						navigationStack: ['share-select-role']
					}
					break
				case 'select-date':
					state.flowContexts[payload.flowType] = {
						flowType: 'select-date',
						date: payload.date,
						ancestor: payload.ancestor,
						navigationStack: ['select-date']
					}
					break
				default:
					throw new Error('Unimplemented flow in startFlow')	
			}
            
        },
		popView: (state, { payload }: PayloadAction<{ id: FlowID }>) => {
			const s = state.flowContexts[payload.id]
			if (s) {
				if (!s.navigationStack)
					return
				const newPhases = [...s.navigationStack]
				newPhases.pop()
				s.navigationStack = newPhases as any
			}
		},
		pushView: (state, { payload }: PayloadAction<{ id: FlowID, phase: typeof FlowsArchitecture[FlowID][0] }>) => {
			const s = state.flowContexts[payload.id]
			if (s) {
				if (!s.navigationStack)
					s.navigationStack = [] as any
				(s.navigationStack as any).push(payload.phase)
			}
		},
		updateFlow: (state, { payload }: PayloadAction<Partial<FlowRequiredContext> & { flowType: FlowID }>) => {
			state.flowContexts[payload.flowType] = {
				...(state.flowContexts[payload.flowType] || {}),
				...(payload as any)
			} 
		},
		cancelFlow: (state, { payload }: PayloadAction<{ id: FlowID }>) => {
			state.activeFlows = state.activeFlows.filter((flowId) => flowId !== payload.id)
			delete state.flowContexts[payload.id]
		},
		exitFlows: (state, { payload }: PayloadAction) => {
            state.activeFlows = []
            state.flowContexts = {}
        },
	},
})

export const flowsActions = flowsSlice.actions
export const flowsSpecialActionsPushView = function<K extends FlowID>(id: K, phase: typeof FlowsArchitecture[K][0]) {
	return flowsActions.pushView({ id, phase })
}
export const flowsReducers = flowsSlice.reducer
