import { useMemo } from 'react';

import { ActivityData } from '@root/store/slices/activitiesTypes';

export const useActivitiesStatsInPeriod = (period: [Date, Date]) => {
	// const activities = useActivities()
	// const sessions = useSessionsBetweenDates(period)

	const statistics = useMemo(() => {
		const stats: {
			[activityId: string]: {
				activity: ActivityData
				count: number
				duration: number
			}
		} = {}

		// sessions.all.forEach((sId: string) => {
		// 	const session = sessions.at[sId]
		// 	const duration = differenceInSeconds(session.end, session.start)

		// 	if (!stats[session.activityId])
		// 		stats[session.activityId] = {
		// 			activity: activities.at[session.activityId],
		// 			count: 0,
		// 			duration: 0,
		// 		}

		// 	stats[session.activityId].count += 1
		// 	stats[session.activityId].duration += duration
		// })
		return stats
	}, [])

	return statistics
}
