import { css } from 'styled-components';

export const DARK_TRANSLUCENCY = css`
	backdrop-filter: blur(90px);
	-webkit-backdrop-filter: blur(90px);
	${(p) => p.theme.backgrounds.darkTranslucency.r.css('background-color')};
`

export const narrowBottomBorder = (margin: number) => css`
	&:before {
		content: '';
		width: calc(100% - ${margin * 2}px);
		left: ${margin}px;
		position: absolute;
		${(p) => p.theme.border.onDarkTranslucency.s1.css('background-color')};
		display: block;
		height: 1px;
		bottom: 0;
		opacity: 0.4;
	}
`
