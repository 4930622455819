import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { BatchedSendablesStream, FetchBoardsRequest, SendableStream, StreamBoardRequest } from '@clepside/clepsidejs/lib/services/sync_v1_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Logger } from '@root/cores/logger'
import { Database } from '@root/database'
import { SyncGRPC } from '@root/grpc/grpcSync'
import { STREAM_ENDED } from '@root/grpc/utils'
import { call, takeEvery } from 'redux-saga/effects'
import { boardsActions } from './boards'
import { SyncCards } from './sync.cards'
import { SyncSharingInvitations } from './sync.sharingInvitations'
import { processBatchedSendablesStream, processResourceComprehensiveStream } from './sync.tsx.handlers'
import { BoardPacketObject, PacketObject } from './sync.tsx.packets.types'
import { getLastSyncedAt, getPacketObjectFromRemoteObject, ResourceComprehensiveStreamingConfig } from './sync.types'
import { markSyncingAsStarted, reachSyncKeyTemplate } from './sync.utils'

export class SyncBoards {
    public static runeOrPacket = 'packet' as const

    public static type = PacketType.BOARD as const

    static fromSendables(res: Sendable.AsObject[] | undefined): PacketObject<any>[] {
        return res?.map((r) => getPacketObjectFromRemoteObject(r?.packet?.board))
            .filter((f): f is PacketObject<any> => f !== undefined) || []
    }


    static placeInStore(objects: BoardPacketObject[], fromSync?: boolean) {
        return boardsActions.store({ objects, fromSync })
    }

    static fromPacket(res: Packet.AsObject | undefined) {
        return getPacketObjectFromRemoteObject(res?.board)
    }

    static database = Database.boardPackets
}

const syncBoardsSlice = createSlice({
    name: 'sync.boards',
    initialState: {},
    reducers: {
        fetchBoards: () => {},
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        streamBoard: (_, {}: PayloadAction<{ boardId: string }>) => {},
    },
})


export const syncBoardsActions = syncBoardsSlice.actions

export const syncBoardsSagas = {
	*fetchBoards({}: ReturnType<typeof syncBoardsSlice.actions.fetchBoards>): any {
        const req = new FetchBoardsRequest()
		
		const lastSyncAt = yield getLastSyncedAt('Boards')
        req.setLastFetchedAt(lastSyncAt)
        yield markSyncingAsStarted('Boards')

        try {
            const channel = yield SyncGRPC.constructBoardsFetchingChannel(req)
            const receiver = function* (response: BatchedSendablesStream) {
                yield call(processBatchedSendablesStream, response, 'Boards', reachSyncKeyTemplate('Boards'), SyncBoards)
            }
			yield takeEvery(channel, receiver)
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        }
	},
	*streamBoard({ payload }: ReturnType<typeof syncBoardsSlice.actions.streamBoard>): any {
        const request = new StreamBoardRequest()
		request.setBoardId(payload.boardId)

        const streamBoardResources = [
            new ResourceComprehensiveStreamingConfig(
                SyncSharingInvitations, 
                (num: number) => request.setInvitationsLastFetchedAt(num),
                'Boards/<0>/Invitations', 
                payload.boardId),
            new ResourceComprehensiveStreamingConfig(
                SyncCards,
                (num: number) => request.setCardsLastFetchedAt(num),
                'Boards/<0>/Cards',
                payload.boardId,
            ),
        ]

        for (const resource of streamBoardResources) {
            const lastSyncedAt = yield getLastSyncedAt(resource.lmat_key)
            yield markSyncingAsStarted(resource.lmat_key)
            resource.prepareRequest(lastSyncedAt)
        }
		
        try {
            const channel = yield SyncGRPC.constructBoardStreamingChannel(request)
            const receiver = function* (response: SendableStream) {
                yield call(processResourceComprehensiveStream, response, 'Boards', streamBoardResources)
            }
			Logger.sync.debug('[Boards] Starting...')
			yield takeEvery(channel, receiver)
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        }
	},
}