import { ActivityID } from './activitiesTypes'
import { NormalisedRoutineScheduleData, RoutineScheduleID } from './routineSchedules.types'
import { RoutinePacketObject } from './sync.tsx.packets.types'
import { LocalData } from './sync.types'

export type RoutineData = LocalData<RoutinePacketObject>
export type RoutineDataPlusSchedule = LocalData<RoutinePacketObject> & {
    schedules: {
        all: RoutineScheduleID[],
        at: {
            [key: RoutineScheduleID]: NormalisedRoutineScheduleData
        }
    }
}
export type NormalisedRoutineData = RoutineData
// & {}

export type RoutineID = string

export type RoutinesState = {
    allForActivityId: {
        [key: ActivityID]: RoutineID[]
    },
    at: {
        [key: RoutineID]: NormalisedRoutineData
    },
    all: RoutineID[]
}

export const InitialRoutinesState: RoutinesState = {
    at: {},
    all: [],
    allForActivityId: {},
}