import { motion } from 'framer-motion';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Themes } from '@root/brand/colors';
import { Transitions } from '@root/brand/transitions';

export const DynamicSidebar: React.FC<{ dark?: boolean; children: any; larger?: boolean }> = ({ dark, children, larger }) => {
	const variants = useMemo(() => {
		return {
			text: {
				dark: { color: Themes.light.standards.white.r.hex },
				light: { color: Themes.light.text.regular.r.hex },
			},
		}
	}, [])

	return (
		<Holder initial={dark ? 'dark' : 'light'} animate={dark ? 'dark' : 'light'} color={dark ? 'dark' : 'light'} larger={larger}>
			<Background key="background">
				<Cover key="light" variants={LightBackgroundCoverVariants} appearence="light" transition={Transitions.smooth} />
				<Cover key="dark" variants={DarkBackgroundCoverVariants} appearence="dark" transition={Transitions.smooth} />
			</Background>

			<ColorChanger variants={variants.text} transition={Transitions.smooth}>
				<Content>{children}</Content>
			</ColorChanger>
		</Holder>
	)
}

const ColorChanger = styled(motion.div)`
	display: contents;
`

const Content = styled.div`
	position: relative;
	z-index: 1;
	flex-grow: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
`

const Holder = styled(motion.div)`
	position: sticky;
	left: 0;
	overflow-anchor: none;
	top: 0;
	height: 100vh;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	padding: 20px 10px;
	box-sizing: border-box;
	width: 250px;
	z-index: 1000000;

	${(p: any) =>
		p.larger &&
		css`
			width: 300px;
		`}

	.button-logic {
		.button-frame {
			&:focus {
				position: relative;

				&:before {
					position: absolute;
					top: -1.5px;
					left: -1.5px;
					width: calc(100% + 3px);
					height: calc(100% + 3px);
					content: '';
					display: block;
					border-radius: 12.5px;
				}
			}
		}

		&:hover {
			.button-frame {
				${(p) =>
					p.color == 'dark'
						? css`
								background-color: rgba(255, 255, 255, 0.03);
						  `
						: css`
								background-color: rgba(10, 20, 30, 0.033);
						  `}
			}
		}

		&:active,
		&.active,
		&:active:hover,
		&.active:hover {
			.button-frame {
				${(p) =>
					p.color == 'dark'
						? css`
								${p.theme.backgrounds.darkTranslucencyShaded.r.css('background-color')};
						  `
						: css`
								background-color: rgba(10, 20, 30, 0.066);
						  `}
			}
		}
	}
` as any

const LightBackgroundCoverVariants = {
	light: { opacity: 1 },
	dark: { opacity: 0 },
}
const DarkBackgroundCoverVariants = {
	dark: { opacity: 1 },
	light: { opacity: 0 },
}

const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	transform: translateZ(0);
	will-change: transform;
	backface-visibility: hidden;
	backdrop-filter: blur(90px);
	-webkit-backdrop-filter: blur(90px);
`

const Cover = styled(motion.div)<{ appearence?: 'light' | 'dark' }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	will-change: opacity;

	&:after,
	&:before {
		position: absolute;
		top: 0;
		right: 0;
		height: 100vh;
		width: 1px;
		display: block;
		content: '';
		background-repeat: no-repeat;
		background-size: 1px 100%;
	}
	&:after {
		right: -1px;
	}

	&:before {
		right: 0px;
	}

	${(p) =>
		p.appearence === 'light'
			? css`
					${p.theme.backgrounds.lightTranslucency.r.css('background-color')};
					&:before {
						background-color: rgba(0, 0, 0, 0.05);
					}
					&:after {
						background-color: rgba(255, 255, 255, 0);
					}
			  `
			: css`
					${p.theme.backgrounds.darkTranslucency.r.css('background-color')};
					&:after {
						background-color: rgba(255, 255, 255, 0.1);
					}
					&:before {
						background-color: rgba(0, 0, 0, 0.3);
					}
			  `}
`
