import React from 'react'
import styled, { css } from 'styled-components'

type SeparatorHeight = 'slim' | 'medium'
export const FieldSeparator: React.FC<{
	height?: SeparatorHeight
	hide?: boolean
}> = ({ height = 'medium', hide = false }) => {
	return (
		<Holder height={height} hide={hide}>
			<Border />
		</Holder>
	)
}

const Holder = styled.div<{ height: SeparatorHeight; hide: boolean }>`
	width: 100%;
	height: 41px;
	display: flex;
	align-items: center;

	${(p) =>
		p.hide &&
		css`
			opacity: 0;
		`}

	${(p) => {
		switch (p.height) {
			case 'slim':
				return css`
					height: 1px;
				`
			default:
				return css`
					height: 41px;
				`
		}
	}}
`

const Border = styled.div`
	width: 100%;
	height: 1px;
	${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
`
