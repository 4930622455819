import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

import { RootState } from '../';
import { errorToastsActions } from './errorToasts';
import { State, WatcherContext, WatcherID } from './watchersTypes';

const watchersSlice = createSlice({
	name: 'watchers',
	initialState: State,
	reducers: {
		start: (state, { payload }: PayloadAction<{ watchers: WatcherID[], isFetcher?: boolean }>) => {
            payload.watchers.map((id) => {
                state[id] = { state: 'started', isFetcher: payload.isFetcher }
            })
        },
		done: (state, { payload }: PayloadAction<{ watchers: WatcherID[], message?: string, context?: any }>) => {
			payload.watchers.map((id) => {
                state[id] = {
                    ...state[id],
                    state: 'done',
                    context: payload.context
                }
            })
		},
        fail: (state, { payload }: PayloadAction<{ watchers: WatcherID[], type?: 'client' | 'server', message?: string, context?: WatcherContext }>) => {
			payload.watchers.map((id) => {
                state[id] = { 
                    ...state[id],
                    state: 'fail',
                    context: payload.context
                }
            })
		},
		readyForDeletion: (state, { payload }: PayloadAction<{ watchers: WatcherID[], forceDelete?: boolean }>) => {
            payload.watchers.map((id) => {
                // if (!payload.forceDelete) {
                //     if (state[id]?.isFetcher) {
                //         return
                //     }
                // }

                delete state[id]
            })
		},
	},
})


export const watchersSagas = {
	*done({ payload }: ReturnType<typeof watchersSlice.actions.start>) {
        yield delay(1000)

        const nonFetchers: string[] = []
        for (const watcherId of payload.watchers) {
            const watcherState = yield* select((state: RootState) => state.watchers[watcherId])
            if (!watcherState?.isFetcher) {
                nonFetchers.push(watcherId)
            }
        }

        if (nonFetchers.length) {
            yield put(watcherActions.readyForDeletion({
                watchers: nonFetchers
            }))   
        }
	},
    *fail({ payload }: ReturnType<typeof watchersSlice.actions.fail>) {
        if (payload.message || payload.type) {
            yield put(errorToastsActions.push({
                message: payload.message,
                type: payload.type,
                id: payload.watchers[0], 
                context: payload.context,
            }))
        }
        yield delay(1500)
        

        // FETCHERS WERE SOMETHING THAT WAS DISPLAYING SOME COMPONENTS..
        // SOLVED IT BY CACHING!

        // const nonFetchers: string[] = []
        // for (const watcherId of payload.watchers) {
        //     const watcherState = yield* select((state: RootState) => state.watchers[watcherId])
        //     if (!watcherState?.isFetcher) {
        //         nonFetchers.push(watcherId)
        //     }
        // }

        yield put(watcherActions.readyForDeletion({
            watchers: payload.watchers
        }))   
    }
}

export const watcherActions = watchersSlice.actions
export const watcherReducers = watchersSlice.reducer
