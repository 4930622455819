import {
    BoardPacketData as ClepsideJSBoardPacketData
} from '@clepside/clepsidejs/lib/packets_v1/boards_pb';

import { Collection } from '../commonTypes';
import { ActivityColorKey } from './activities.colors.types';
import { BoardPacketObject } from './sync.tsx.packets.types';
import { LocalData } from './sync.types';

export type BoardRemoteData = Required<ClepsideJSBoardPacketData.AsObject>
export type BoardData = Omit<LocalData<BoardPacketObject>, 'color'> & {
	color: ActivityColorKey
}
export type StoredBoard = BoardData & { id: string }

export const boardsState: Collection<BoardData> = {
	at: {},
	all: []
}
