import * as d3 from 'd3';
import { differenceInSeconds } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { DatePeriodButton } from '@comps/complex/datePeriodButton';
import { Flex } from '@comps/layout/flex';
import { PageInset } from '@comps/layout/pageInset';
import { Spacer } from '@comps/layout/space';
import { Text } from '@comps/typography/text';
import { useActivitiesStatsInPeriod } from '@root/hooks/analytics/useActivitiesStatsInPeriod';
import { useD3TooltipBuilder } from '@root/hooks/useD3TooltipBuilder';
import { useRefTaker } from '@root/hooks/useRefTaker';
import { useTimeCurrentWeek } from '@root/store/selectors/useTimeCurrentWeek';
import { ActivityColors } from '@root/store/slices/activities.colors.types';
import { getDuration } from '@root/utils/dates';

import { ChartFrame, ChartHolder } from './Activity.ID.Insights.chart3d';

export const Insights = () => {
	const week = useTimeCurrentWeek()
	const [ref, setRef] = useRefTaker()
	const stats = useActivitiesStatsInPeriod(week)
	const { tooltip, enableTooltip } = useD3TooltipBuilder<string>(
		useCallback(
			(hover) => {
				return (
					<Flex direction="column">
						<Text color="toast.text">{stats[hover.values[0]].activity.name}</Text>
						<Text color="toast.textSubtle">
							Count:&nbsp;
							<Text color="toast.text">{stats[hover.values[0]].count}</Text>
						</Text>
						<Text color="toast.textSubtle">
							Duration:
							<Text color="toast.text">{getDuration(stats[hover.values[0]].duration)}</Text>
						</Text>
					</Flex>
				)
			},
			[stats]
		)
	)

	const data = useMemo(() => {
		return Object.keys(stats).map((aId) => {
			return { id: aId, value: stats[aId].duration }
		})
	}, [stats])

	useEffect(() => {
		if (!ref) return

		const width = ref.getBoundingClientRect().width
		const height = ref.getBoundingClientRect().height

		// Size scale for countries
		const size = d3
			.scaleLinear()
			.domain([0, differenceInSeconds(week[1], week[0])])
			.range([30, 320]) // circle will be between 7 and 55 px wide

		const svg = d3.select(ref).append('svg').attr('width', width).attr('height', height)

		// Initialize the circle: all located at the center of the svg area
		const node = svg
			.append('g')
			.selectAll('circle')
			.data(data)
			.enter()
			.append('circle')
			.attr('class', 'node')
			.attr('r', function (d) {
				return size(d.value)
			})
			.attr('cx', width / 2)
			.attr('cy', height / 2)
			.style('fill', function (d: any): any {
				if ((ActivityColors as any)[stats[d.id].activity?.color]?.active?.background)
					return (ActivityColors as any)[stats[d.id].activity?.color].active.background
				return 'transparent'
			})

		const icons = svg
			.append('g')
			.selectAll('icons')
			.data(data)
			.enter()
			.append('svg:image')
			.attr('x', width / 2)
			.attr('y', height / 2)
			.attr('width', function (d) {
				return size(d.value)
			})
			.attr('height', function (d) {
				return size(d.value)
			})
			.attr('xlink:href', function (d) {
				return `/static/activities/${stats?.[d.id]?.activity?.icon}.png`
			})

		// Features of the forces applied to the nodes:
		const simulation = d3
			.forceSimulation()
			.force(
				'center',
				d3
					.forceCenter()
					.x(width / 2)
					.y(height / 2)
			) // Attraction to the center of the svg area
			.force('charge', d3.forceManyBody().strength(0.1)) // Nodes are attracted one each other of value is > 0
			.force(
				'collide',
				d3
					.forceCollide()
					.strength(0.2)
					.radius(function (d: any) {
						return size(d.value) + 3
					})
					.iterations(1)
			) // Force that avoids circle overlapping

		// Apply these forces to the nodes and update their positions.
		// Once the force algorithm is happy with positions ('alpha' value is low enough), simulations will stop.
		simulation.nodes(data as any).on('tick', function (): any {
			icons.attr('x', function (d: any) {
				return d.x - size(d.value) / 2
			}).attr('y', function (d: any) {
				return d.y - size(d.value) / 2
			})
			node.attr('cx', function (d: any) {
				return d.x
			}).attr('cy', function (d: any) {
				return d.y
			})
		})

		enableTooltip(svg, width, height, (setHover, event) => {
			const x0 = size.invert(d3.pointer(event)[0])
			const i = d3.bisector(function (d: any) {
				return d.value
			})

			const coords: {
				values: [string, number]
				radius: number
				x: number
				y: number
			}[] = []
			node.each(function (k: any, j) {
				const c = this as any
				const broom = d3.select(c)
				const radius = Math.round(size(k.value)) * 2
				coords.push({
					values: [k.id, k.value],
					radius,
					x: Math.round(parseInt(broom.attr('cx')) - radius / 2),
					y: Math.round(parseInt(broom.attr('cy')) - radius / 2),
				})
			})
			// const d0 = data[i - 1]
			// const d1 = data[i]
			// const d = x0 - d0.value > d1.value - x0 ? d1 : d0

			const cx = d3.pointer(event)[0]
			const cy = d3.pointer(event)[1]

			let inside: any = undefined
			coords.forEach((c) => {
				if (c.x < cx && c.x + c.radius > cx) {
					if (c.y < cy && c.y + c.radius > cy) {
						inside = c
					}
				}
			})

			if (inside) {
				setHover({
					values: inside.values,
					coords: [inside.x + inside.radius, inside.y + inside.radius],
				})
			} else {
				setHover(undefined)
			}
		})

		return () => {
			svg.remove()
		}
	}, [ref, week, stats, data, enableTooltip])

	// const tableStructure = useMemo(() => {
	// 	if (!stats) return undefined
	// 	return {
	// 		Activity: {
	// 			render: (id: string) => (
	// 				<Flex align="center" key={`activity-${id}`}>
	// 					{stats[id]?.activity && <ActivityBubble activity={stats[id]?.activity} size="tiny" />}
	// 					<Spacer size={8} />
	// 					<Text>{stats[id]?.activity?.name}</Text>
	// 				</Flex>
	// 			),
	// 		},
	// 		Count: {
	// 			render: (id: string) => <Text>{stats[id]?.count}</Text>,
	// 		},
	// 		Duration: {
	// 			render: (id: string) => <Text>{getDuration(stats[id]?.duration)}</Text>,
	// 		},
	// 		Share: {
	// 			render: (id: string) => <Text>100%</Text>,
	// 		},
	// 	}
	// }, [stats])
	// if (!tableStructure) return null

	return (
		<PageInset larger>
			{Object.keys(stats).length ? (
				<>
					<Flex justify="space-between" align="center">
						<Text level="title70">Current Week</Text>
						<DatePeriodButton period={week} />
					</Flex>
					<Spacer size={20} vertical />
					<Text level="largerParagraphBody" color="toast.textSubtle">
						Here’s the way you spent your time for the past week.
					</Text>
					<Flex>
						<Flex grow basis="50%" shrink>
							<ChartHolder>
								<ChartFrame ref={setRef} />
								{tooltip}
							</ChartHolder>
						</Flex>
						<Spacer size={50} />
						<Flex direction="column" basis="50%" grow align="stretch" shrink>
							<Spacer size={80} vertical />
							<Text>Time spent analysis:</Text>
							<Spacer size={15} vertical />
							{/* <Table
								items={Object.keys(stats).sort((a, b) => {
									return stats[a].duration > stats[b].duration ? -1 : 1
								})}
								layout="auto min-content min-content min-content"
								structure={tableStructure}
							/> */}
						</Flex>
					</Flex>
				</>
			) : null}
		</PageInset>
	)
}
