import { useCardsByResourceId } from '@selectors/useCards'
import { createRef, useMemo } from 'react'

import { CardDatasource } from './cardGrid.types'

export const useCardDatasource = (resourceId: string) => {
	const cardsSource = useCardsByResourceId(resourceId)
	const cards: CardDatasource[] = useMemo(() => {
		return cardsSource.all.map((cardId, i) => {
			const card = cardsSource.at[cardId]
			let gridLayout: CardDatasource['gridLayout'] | undefined = undefined

			if (card.layout) {
				const { x, y, h, w } = card.layout
				gridLayout = {
					size: { h, w },
					pos: { y, x }
				}
			}
			return {
				id: card.id,
				order: i,
				type: card.cardType,
				gridLayout,
				verticalLayout: card.verticallayout?.order || 10000,
				lastModifiedAt: card.lastModifiedAt,
				ref: createRef<HTMLDivElement>(),
			}
		})
	}, [cardsSource])

	return { cards }
}
