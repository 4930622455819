import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { Database } from '@db/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDayIndex } from '@root/utils/dates';
import { delay } from 'redux-saga/effects';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, put, select } from 'typed-redux-saga';

import { RootState } from '../';
import { deltaCreate, deltaDelete, deltaUpdate } from './persist';
import { NewPacketDelta, RemovePacketDeltaIdOnly, UpdatePacketDelta } from './persist.utils';
import {
	DayIndex, SessionsDisplayScope, SessionsDisplayScopeKeyTemplate, sessionsState
} from './sessionsTypes';
import { canSessionFit, normalizeSessionPacket } from './sessionsUtils';
import { SessionPacketObject } from './sync.tsx.packets.types';

const scopeCounter: { [scopeKey: string]: number } = {}
const sessionsSlice = createSlice({
	name: 'sessions',
	initialState: sessionsState,
	reducers: {
		refresh: (state, { payload }: PayloadAction) => {
			return state
		},
        new: (state, { payload }: PayloadAction<NewPacketDelta<PacketType.SESSION> & { id?: string } >) => {},
		update: (state, { payload }: PayloadAction<UpdatePacketDelta<PacketType.SESSION>>) => {},
		delete: (state, { payload }: PayloadAction<RemovePacketDeltaIdOnly>) => {},
		loadToStore: (state, { payload }: PayloadAction<{ resourceId: string }>) => {},
		setActiveScope: (state, { payload }: PayloadAction<{ scopeKey: string, scope: Omit<SessionsDisplayScope, 'scopeKey'> }>) => {
			if (!scopeCounter[payload.scopeKey])
				scopeCounter[payload.scopeKey] = 0
			scopeCounter[payload.scopeKey]++
			state.activeScopes[payload.scopeKey] = payload.scope
		},
		scheduleScopeCancelling: (state, { payload }: PayloadAction<{ scopeKey: string }>) => {},
		cancelActiveScope: (state, { payload }: PayloadAction<{ scopeKey: string }>) => {
			if (!scopeCounter[payload.scopeKey])
				return
			scopeCounter[payload.scopeKey]--
			if (!scopeCounter[payload.scopeKey])
				delete state.activeScopes[payload.scopeKey]
			
		},
		move: (
			state,
			{
				payload,
			}: PayloadAction<{
				id: string
				to: {
					start: DayIndex
					end: DayIndex
				}
			}>
		) => {},
		normalizeAndStoreIfMatchesActiveScope: (state, { payload }: PayloadAction<{ objects: SessionPacketObject[], fromSync?: boolean }>) => {
			// const newState: SessionsState = {
			// 	at: {},
			// 	activeScopes: state.activeScopes,
			// 	forScope: {}
			// }

			const validateConditions = (object: SessionPacketObject) => {
				const sessionStart = object.data.start
				const sessionEnd = object.data.end
				
				let passedConditions = false
				for (const scopeUniqueMountId of Object.keys(state.activeScopes) as SessionsDisplayScopeKeyTemplate[]) {
					const scope = state.activeScopes[scopeUniqueMountId]
					if (!scope) continue
					const scopeKey = scope.key as SessionsDisplayScopeKeyTemplate
					if (!scopeKey) continue


					if (scope.id == object.id) {
						if (!state.forScope[scopeKey]) {
							state.forScope[scopeKey] = []
						}

						if (!state.forScope[scopeKey]?.includes(object.id))
							state.forScope[scopeKey]?.push(object.id)

						passedConditions = true
						return true
					}
					
					const between = scope.between
					if (between) {
						if (between.activityId && object.data.activityId !== between.activityId ) 
							continue

						const startLimit = between.start
						const endLimit = between.end

						if (startLimit) {
							if (sessionEnd <= startLimit)
								continue
							if (!between.inclusive || between.inclusive == 'end') {	
								if (sessionStart < startLimit) {
									continue
								}
							}
						} 

						if (endLimit) {
							if (sessionStart >= endLimit)
								continue
							if (!between.inclusive || between.inclusive == 'start') {	
								if (sessionEnd > endLimit) {
									continue
								}
							}
						} 
					}

					if (!state.forScope[scopeKey]) {
						state.forScope[scopeKey] = []
					}

					if (!state.forScope[scopeKey]?.includes(object.id))
						state.forScope[scopeKey]?.push(object.id)

					passedConditions = true
				}

				return passedConditions
			}

			for (const object of payload.objects) {
				if (!object.id)
					continue
				if (object?.deleted?.is || !object?.data?.activityId) {
					delete state.at[object.id]
					for (const scope of Object.keys(state.forScope)) {
						const obj = state.forScope[scope]
						if (obj.includes(object.id)) {
							state.forScope[scope] = state.forScope[scope].filter(id => id !== object.id)
						}
					}
					continue
				}
				if (validateConditions(object)) {
					state.at[object.id] = {
						...state.at[object.id],
						...normalizeSessionPacket(object.id, object.data),
					}
				}
			}
		
			// if (!deepEqual(state.at, state.at)) {
			// 	state.at = newState.at
			// }
			// if (!deepEqual(state.forScope, newState.forScope)) {
			// 	state.forScope = newState.forScope
			// }
		},
	},
})

export const sessionsSagas = {
	*refresh(): any {
		yield delay(16)
		const activeScopes = yield* select((state: RootState) => state.sessions.activeScopes)
		const sessions: { [sessionId: string]: SessionPacketObject } = {}
		const activeQueries = []
		const activeScopeKey = Object.keys(activeScopes) as SessionsDisplayScopeKeyTemplate[]

		if (activeScopeKey.length == 0) {
			return
		}

		for (const scopeKey of activeScopeKey) {
			const scope = activeScopes[scopeKey]
			if (!scope) continue
			let query: any = Database.sessionPackets
			if (scope.id) {
				query = query.where('id').equals(scope.id).limit(1)
			} else if (scope.between) {
				if (scope.between.end && scope.between.start) {
					query = query.where('daystamps')
						.between(
							getDayIndex(new Date(scope.between.start)) - 1,
							getDayIndex(new Date(scope.between.end)) + 1
						)
				} else if (scope.between.end) {
					query = query.where('daystamps')
						.belowOrEqual(
							getDayIndex(new Date(scope.between.end))
						)
				} else if (scope.between.start) {
					query = query.where('daystamps')
						.aboveOrEqual(
							getDayIndex(new Date(scope.between.start))
						)
				}
				if (scope.between.activityId) {
					query = query.and((t: any) => t.data.activityId == scope.between?.activityId)
				}
				if (scope.between.limit) {
					query = query.limit(1)
				}
			} else {
				console.error('trying to query sessions without id, nor between')
				continue
			}
			activeQueries.push(query.toArray())
		}		
		const sessionBatches = yield all(activeQueries)
		for (const batch of sessionBatches) {
			for (const session of batch) {
				sessions[session.id] = session
			}
		}

		yield put(sessionsSlice.actions.normalizeAndStoreIfMatchesActiveScope({
			objects: Object.values(sessions)
		}))
	},
	*move({ payload, type }: ReturnType<typeof sessionsSlice.actions.move>) {
		const canSessionBeMoved: boolean = yield canSessionFit(payload.id, payload.to)
		if (canSessionBeMoved)
			yield put(
				sessionsActions.update({
					id: payload.id,
					start: payload.to.start,
					end: payload.to.end,
				})
			)
		// Wtf did I do here??
		// --- Written before:
		// Trigger a refresh anyway...
		// else
		// 	yield put(
		// 		sessionsActions.put({
		// 			id: payload.id,
		// 			data: {},
		// 		})
		// 	)
	},
	*setActiveScope({ payload: delta }: ReturnType<typeof sessionsActions.setActiveScope>) {
		yield put(sessionsActions.refresh())
	},
	*cancelActiveScope({ payload: delta }: ReturnType<typeof sessionsActions.cancelActiveScope>) {
		yield put(sessionsActions.refresh())
	},
	*new({ payload: delta }: ReturnType<typeof sessionsActions.new>) {
		yield deltaCreate({ type: PacketType.SESSION, delta, id: delta.id })
	},
	*update({ payload: delta }: ReturnType<typeof sessionsActions.update>) {
		yield deltaUpdate({ type: PacketType.SESSION, delta })
	},
	*scheduleScopeCancelling({ payload }: ReturnType<typeof sessionsActions.scheduleScopeCancelling>) {
		yield delay(2000)
		yield put(sessionsActions.cancelActiveScope(payload))
	},
	*delete({ payload: { id } }: ReturnType<typeof sessionsActions.delete>) {
		yield deltaDelete({ type: PacketType.SESSION, delta: { id, shouldDelete: true } })
	}
}

export const sessionsActions = sessionsSlice.actions
export const sessionsReducers = sessionsSlice.reducer
