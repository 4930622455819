/**
 * @fileoverview gRPC-Web generated client stub for filer
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.1
// source: services/filer_v1.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.filer = require('./filer_v1_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.filer.FilerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.filer.FilerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.filer.UploadAvatarRequest,
 *   !proto.filer.UploadAvatarResponse>}
 */
const methodDescriptor_FilerService_UploadAvatar = new grpc.web.MethodDescriptor(
  '/filer.FilerService/UploadAvatar',
  grpc.web.MethodType.UNARY,
  proto.filer.UploadAvatarRequest,
  proto.filer.UploadAvatarResponse,
  /**
   * @param {!proto.filer.UploadAvatarRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filer.UploadAvatarResponse.deserializeBinary
);


/**
 * @param {!proto.filer.UploadAvatarRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.filer.UploadAvatarResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.filer.UploadAvatarResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.filer.FilerServiceClient.prototype.uploadAvatar =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/filer.FilerService/UploadAvatar',
      request,
      metadata || {},
      methodDescriptor_FilerService_UploadAvatar,
      callback);
};


/**
 * @param {!proto.filer.UploadAvatarRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.filer.UploadAvatarResponse>}
 *     Promise that resolves to the response
 */
proto.filer.FilerServicePromiseClient.prototype.uploadAvatar =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/filer.FilerService/UploadAvatar',
      request,
      metadata || {},
      methodDescriptor_FilerService_UploadAvatar);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.filer.FetchAvatarRequest,
 *   !proto.filer.FetchAvatarResponse>}
 */
const methodDescriptor_FilerService_FetchAvatar = new grpc.web.MethodDescriptor(
  '/filer.FilerService/FetchAvatar',
  grpc.web.MethodType.UNARY,
  proto.filer.FetchAvatarRequest,
  proto.filer.FetchAvatarResponse,
  /**
   * @param {!proto.filer.FetchAvatarRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filer.FetchAvatarResponse.deserializeBinary
);


/**
 * @param {!proto.filer.FetchAvatarRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.filer.FetchAvatarResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.filer.FetchAvatarResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.filer.FilerServiceClient.prototype.fetchAvatar =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/filer.FilerService/FetchAvatar',
      request,
      metadata || {},
      methodDescriptor_FilerService_FetchAvatar,
      callback);
};


/**
 * @param {!proto.filer.FetchAvatarRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.filer.FetchAvatarResponse>}
 *     Promise that resolves to the response
 */
proto.filer.FilerServicePromiseClient.prototype.fetchAvatar =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/filer.FilerService/FetchAvatar',
      request,
      metadata || {},
      methodDescriptor_FilerService_FetchAvatar);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.filer.FetchFavIconRequest,
 *   !proto.filer.FetchFavIconResponse>}
 */
const methodDescriptor_FilerService_FetchFavicon = new grpc.web.MethodDescriptor(
  '/filer.FilerService/FetchFavicon',
  grpc.web.MethodType.UNARY,
  proto.filer.FetchFavIconRequest,
  proto.filer.FetchFavIconResponse,
  /**
   * @param {!proto.filer.FetchFavIconRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filer.FetchFavIconResponse.deserializeBinary
);


/**
 * @param {!proto.filer.FetchFavIconRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.filer.FetchFavIconResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.filer.FetchFavIconResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.filer.FilerServiceClient.prototype.fetchFavicon =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/filer.FilerService/FetchFavicon',
      request,
      metadata || {},
      methodDescriptor_FilerService_FetchFavicon,
      callback);
};


/**
 * @param {!proto.filer.FetchFavIconRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.filer.FetchFavIconResponse>}
 *     Promise that resolves to the response
 */
proto.filer.FilerServicePromiseClient.prototype.fetchFavicon =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/filer.FilerService/FetchFavicon',
      request,
      metadata || {},
      methodDescriptor_FilerService_FetchFavicon);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.filer.GetCardFilePresignedDownloadURLRequest,
 *   !proto.filer.GetCardFilePresignedDownloadURLResponse>}
 */
const methodDescriptor_FilerService_GetCardFilePresignedDownloadURL = new grpc.web.MethodDescriptor(
  '/filer.FilerService/GetCardFilePresignedDownloadURL',
  grpc.web.MethodType.UNARY,
  proto.filer.GetCardFilePresignedDownloadURLRequest,
  proto.filer.GetCardFilePresignedDownloadURLResponse,
  /**
   * @param {!proto.filer.GetCardFilePresignedDownloadURLRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filer.GetCardFilePresignedDownloadURLResponse.deserializeBinary
);


/**
 * @param {!proto.filer.GetCardFilePresignedDownloadURLRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.filer.GetCardFilePresignedDownloadURLResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.filer.GetCardFilePresignedDownloadURLResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.filer.FilerServiceClient.prototype.getCardFilePresignedDownloadURL =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/filer.FilerService/GetCardFilePresignedDownloadURL',
      request,
      metadata || {},
      methodDescriptor_FilerService_GetCardFilePresignedDownloadURL,
      callback);
};


/**
 * @param {!proto.filer.GetCardFilePresignedDownloadURLRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.filer.GetCardFilePresignedDownloadURLResponse>}
 *     Promise that resolves to the response
 */
proto.filer.FilerServicePromiseClient.prototype.getCardFilePresignedDownloadURL =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/filer.FilerService/GetCardFilePresignedDownloadURL',
      request,
      metadata || {},
      methodDescriptor_FilerService_GetCardFilePresignedDownloadURL);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.filer.ProcessCardFileUploadRequest,
 *   !proto.filer.ProcessCardFileUploadResponse>}
 */
const methodDescriptor_FilerService_ProcessCardFileUpload = new grpc.web.MethodDescriptor(
  '/filer.FilerService/ProcessCardFileUpload',
  grpc.web.MethodType.UNARY,
  proto.filer.ProcessCardFileUploadRequest,
  proto.filer.ProcessCardFileUploadResponse,
  /**
   * @param {!proto.filer.ProcessCardFileUploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filer.ProcessCardFileUploadResponse.deserializeBinary
);


/**
 * @param {!proto.filer.ProcessCardFileUploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.filer.ProcessCardFileUploadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.filer.ProcessCardFileUploadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.filer.FilerServiceClient.prototype.processCardFileUpload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/filer.FilerService/ProcessCardFileUpload',
      request,
      metadata || {},
      methodDescriptor_FilerService_ProcessCardFileUpload,
      callback);
};


/**
 * @param {!proto.filer.ProcessCardFileUploadRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.filer.ProcessCardFileUploadResponse>}
 *     Promise that resolves to the response
 */
proto.filer.FilerServicePromiseClient.prototype.processCardFileUpload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/filer.FilerService/ProcessCardFileUpload',
      request,
      metadata || {},
      methodDescriptor_FilerService_ProcessCardFileUpload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.filer.SetCardFileUploadStatusRequest,
 *   !proto.filer.SetCardFileUploadStatusResponse>}
 */
const methodDescriptor_FilerService_SetCardFileUploadStatus = new grpc.web.MethodDescriptor(
  '/filer.FilerService/SetCardFileUploadStatus',
  grpc.web.MethodType.UNARY,
  proto.filer.SetCardFileUploadStatusRequest,
  proto.filer.SetCardFileUploadStatusResponse,
  /**
   * @param {!proto.filer.SetCardFileUploadStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filer.SetCardFileUploadStatusResponse.deserializeBinary
);


/**
 * @param {!proto.filer.SetCardFileUploadStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.filer.SetCardFileUploadStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.filer.SetCardFileUploadStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.filer.FilerServiceClient.prototype.setCardFileUploadStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/filer.FilerService/SetCardFileUploadStatus',
      request,
      metadata || {},
      methodDescriptor_FilerService_SetCardFileUploadStatus,
      callback);
};


/**
 * @param {!proto.filer.SetCardFileUploadStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.filer.SetCardFileUploadStatusResponse>}
 *     Promise that resolves to the response
 */
proto.filer.FilerServicePromiseClient.prototype.setCardFileUploadStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/filer.FilerService/SetCardFileUploadStatus',
      request,
      metadata || {},
      methodDescriptor_FilerService_SetCardFileUploadStatus);
};


module.exports = proto.filer;

