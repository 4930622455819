import { format, startOfYear } from 'date-fns';
// import { sessionsRelevanceActions } from '@root/store/slices/sessionsRelevance'
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useParams } from 'react-router';
import styled, { css } from 'styled-components';

import { ActivityBackground } from '@comps/activityBackground';
import { useActivityStreaming } from '@root/hooks/useActivityStreaming';
import { usePagePresence } from '@root/hooks/usePagePresence';
import { useTime } from '@root/hooks/useTime';
import { useSessionsSource } from '@root/store/selectors/useSessionsSource';
import { activitiesActions } from '@root/store/slices/activities';
import { SessionDisplayConditions } from '@root/store/slices/sessionsTypes';

import { ActivitySidebar } from './Activity.sidebar';

export const Activity = () => {
	const { id } = useParams()
	const dispatch = useDispatch()
	const time = useTime()
	const { show } = usePagePresence()
	useActivityStreaming(id)

	const sessionsConfig = useMemo(() => {
		return {
			activityId: id,
			start: startOfYear(time).getTime(),
			inclusive: 'both',
		} as SessionDisplayConditions
	}, [time, id])

	useSessionsSource(sessionsConfig, 'for-activity-<0>-year-<1>', id, format(time, 'yyyy'))

	useEffect(() => {
		if (!id) {
			return
		}

		dispatch(activitiesActions.loadToStore({ resourceId: id }))
	}, [dispatch, id])

	// useEffect(() => {
	// 	if (id) {
	// 		dispatch(sessionsRelevanceActions.setLastOfActivity({ activityId: id, date: time.getTime() }))
	// 		dispatch(sessionsRelevanceActions.setOfActivity({ activityId: id }))
	// 	}
	// }, [dispatch, id, time])

	return (
		<Holder present={show}>
			<ActivitySidebar />
			<OutletHolder>
				<Outlet />
			</OutletHolder>
			{/* <CanvasBackground key="canvas" /> */}
			<ActivityBackground activityId={id} />
		</Holder>
	)
}

const OutletHolder = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(100% - 300px);
	position: relative;
	z-index: 1;
`

const Holder = styled.div<{ present?: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	overflow: hidden;
	z-index: 10000;
	will-change: transform;
	background-color: #fff;
	transform: scale(1.05);
	opacity: 0;
	transform-style: flat;
	backface-visibility: hidden;
	transition: ease transform 0.29s, ease opacity 0.29s;

	${(p) =>
		p.present &&
		css`
			transform: scale(1);
			opacity: 1;
		`}
`
