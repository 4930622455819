import { useDragAndDropHover } from '@root/cores/useDragAndDropHover';
import { useRefTaker } from '@root/hooks/useRefTaker';
import { Nuance } from '@root/styled';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';

type LinkType = 'normal' | 'underlined' | 'hoverUnderlined'
export const Link: React.FC<
	React.ComponentProps<typeof NavLink> & {
		children: any
		nuance?: Nuance
		type?: LinkType
		newTab?: boolean
		colorless?: boolean
	}
> = ({ children, colorless, to, newTab, type = 'normal', ...p }) => {
	const match = useLocation()
	const theme = useTheme()
	const nuance = colorless ? undefined : p.nuance || theme.text.action
	const isActive = to && match.pathname.includes(to.toString())
	const [button, setButton] = useRefTaker()
	useDragAndDropHover(button, true)

	return (
		<LinkCover to={to} {...p} nuance={nuance} type={type} ref={setButton} nocolor={colorless ? 'true' : undefined} {
			...(newTab ? {
				target: '_blank',
				rel: 'noopener noreferrer'
			} : {})
		}>
			{typeof children !== 'string' ? React.cloneElement(children, { isActive }) : children}
		</LinkCover>
	)
}

const LinkCover = styled(NavLink) <{ nuance?: Nuance; type: LinkType; nocolor?: string }>`
	text-decoration: none;
	display: inline-flex;
	cursor: default;
	flex-direction: column;
	align-items: stretch;

	/* height: 16px; */
	/* padding: 2px 0;
	margin: -2px 0; */
	${(p) => p.theme.text.action.r.css('color')};
	${(p) =>
		p.nocolor &&
		css`
			color: currentColor;
		`}

	border-bottom: 1px solid transparent;
	${(p) =>
		p.type == 'underlined' &&
		css`
			${p.theme.text.action.r.css('border-bottom-color')};
		`}

	&:hover {
		${(p) =>
		(p.type == 'hoverUnderlined' || p.type == 'underlined') &&
		css`
				${p.theme.text.action.r.css('border-bottom', '1px solid')};
			`}
	}

	${(p) =>
		p.nuance
			? css`
					${p.nuance.r.css('color')};
					/* ${p.nuance.r.css('border-color')}; */

					&:hover {
						${p.nuance.s1.css('color')};
						${(p.type == 'hoverUnderlined' || p.type == 'underlined') &&
				css`
							${p.nuance.s1.css('border-color')}
						`}
					}
					&:active {
						${p.nuance.s2.css('color')};
						${(p.type == 'hoverUnderlined' || p.type == 'underlined') &&
				css`
							${p.nuance.s2.css('border-color')}
						`}
					}
			  `
			: ''};
	/* &.active .button-frame {
		cursor: default;
	} */
`
