import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb';
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb';
import { Database } from '@root/database';
import { domainToFilepath } from '@root/utils/general';
import { put } from 'typed-redux-saga';

import { fragmentsActions } from './fragments';
import { syncActions } from './sync';
import {
    FragmentUploadablePacketObject, FragmentWithComputedLocalProps, PacketObject
} from './sync.tsx.packets.types';
import { getPacketObjectFromRemoteObject } from './sync.types';

export class SyncFragmentsUploadable {
    public static runeOrPacket = 'packet' as const

    public static type = PacketType.FRAGMENT_UPLOADABLE as const

    static fromSendables(res: Sendable.AsObject[] | undefined): PacketObject<any>[] {
        return res?.map((r) => getPacketObjectFromRemoteObject(r?.packet?.fragmentUploadable))
            .filter((f): f is PacketObject<any> => f !== undefined) || []
    }

    static placeInStore(objects: FragmentUploadablePacketObject[], fromSync?: boolean) {
        return fragmentsActions.normalizeAndStore({ 
            objects: objects as FragmentWithComputedLocalProps<FragmentUploadablePacketObject>[],
            fromSync
        })
    }

    static prepareForStorage(object: FragmentUploadablePacketObject): FragmentWithComputedLocalProps<FragmentUploadablePacketObject> {
        try {
            let lastUpdatedAt = object.lastModifiedAt
            for (const key of Object.keys(object.meta)) {
                const at = (object.meta as any)?.[key]?.at
                if (at && at > lastUpdatedAt) {
                    lastUpdatedAt = at
                }
            }

            return {
                ...object,
                clientOnlyLastUpdateAt: lastUpdatedAt
            }
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    static fromPacket(res: Packet.AsObject | undefined) {
        return getPacketObjectFromRemoteObject(res?.fragmentUploadable)
    }
    
    static async inTransaction(packet: FragmentUploadablePacketObject) {
        if (packet?.type == PacketType.FRAGMENT_UPLOADABLE) {
            const info = packet?.data.fileInfo

            if (info && info.id && info.type) {
                const alreadyExists = await Database.imageProcessing.get(info.id)
                if (alreadyExists) return
                
                if (info.type == 'image') {
                    Database.imageProcessing.add({
                        id: info.id,
                        filepath: domainToFilepath(info.id),
                        fragmentId: packet.id,
                        type: {
                            op: 'fetch-card-image',
                        },
                        sendAt: new Date().getTime(),
                        queuedAt: new Date().getTime(),
                        status: 'needs-fetching'
                    })
                }
            }
		}
    }

    
    static *afterPersist() {
        yield put(syncActions.doCriticalOps())
    }

    static database = Database.fragmentPacketsUploadable
    
    static otherDatabaseLocks = [Database.imageProcessing]
}
