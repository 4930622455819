import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { Database } from '@db/index'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
/* eslint-disable @typescript-eslint/no-unused-vars */
import { put } from 'redux-saga/effects'
import { ULID } from '../commonTypes'
import { ActivityColorKey } from './activities.colors.types'
import { BoardData, boardsState, StoredBoard } from './boardsTypes'
import { cardsActions } from './cards'
import { deltaCreate, deltaDelete, deltaUpdate } from './persist'
import { NewPacketDelta, RemovePacketDeltaIdOnly, UpdatePacketDelta } from './persist.utils'
import { SharingInvitationRuneObject } from './runes.types'
import { sharingActions } from './sharing'
import { SyncBoards } from './sync.boards'
import { BoardPacketObject } from './sync.tsx.packets.types'

export const normalizeBoardPacket = (id: string, s: BoardData) => {
	const normalizedBoard: StoredBoard = {
		id,
		name: s.name,
		color: s.color,
		wallpaper: s.wallpaper || 'currentColor'
	}
	return normalizedBoard
}

const boardsSlice = createSlice({
	name: 'boards',
	initialState: boardsState,
	reducers: {
		initialize() {},
		new: (state, { payload }: PayloadAction<NewPacketDelta<PacketType.BOARD> & { id?: ULID } >) => {},
		update: (state, { payload }: PayloadAction<UpdatePacketDelta<PacketType.BOARD>>) => {},
		delete: (state, { payload }: PayloadAction<RemovePacketDeltaIdOnly>) => {},
		loadToStore: (state, { payload }: PayloadAction<{ resourceId: string }>) => {},
		store: (state, { payload }: PayloadAction<{ objects: BoardPacketObject[], fromSync?: boolean }>) => {
			payload.objects.forEach((p) => {
				if (!p.id) return
				if (p.deleted?.is) {
					delete state.at[p.id]
					state.all = state.all.filter(id => id !== p.id)
				} else {
					state.at[p.id] = {
						id: p.id,
						...p.data,
						color: p.data.color as ActivityColorKey,
					}
					if (!state.all.includes(p.id))
						state.all = [...state.all, p.id]
				}
			})
		},
	},
})

export const boardsSagas = {
	*initialize() {
		const boardsPackets: BoardPacketObject[] = yield Database.boardPackets.toArray()
		yield put(SyncBoards.placeInStore(boardsPackets))
	},
	*loadToStore({ payload }: ReturnType<typeof boardsSlice.actions.loadToStore>): any {
		const invitations: SharingInvitationRuneObject[] = yield Database.sharingInvitationRunes.where('rune.resourceId').equals(payload.resourceId).toArray()

		yield put(sharingActions.store({ objects: invitations.map(i => i.rune) }))
		yield put(cardsActions.loadCardsForResourceId({ resourceId: payload.resourceId }))
	},
	*store({ payload }: ReturnType<typeof boardsActions.store>) {
		if (payload.fromSync) {
			for (const boardPacket of payload.objects) {
				if (!boardPacket?.data || boardPacket?.deleted?.is)
					continue
			}
		}
	},
	*new({ payload: delta }: ReturnType<typeof boardsActions.new>) {
		yield deltaCreate({ type: PacketType.BOARD, delta, id: delta.id })
	},
	*update({ payload: delta }: ReturnType<typeof boardsActions.update>) {
		yield deltaUpdate({ type: PacketType.BOARD, delta })
	},
	*delete({ payload: { id } }: ReturnType<typeof boardsActions.delete>) {
		yield deltaDelete({ type: PacketType.BOARD, delta: { id, shouldDelete: true } })
	}
}

export const boardsActions = boardsSlice.actions
export const boardsReducers = boardsSlice.reducer
