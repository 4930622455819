import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useHotkey } from './hooks/useHotkey';
import { useHotkeysActiveScope } from './hooks/useHotkeysActiveScope';
import { isElementInputable } from './utils/general';

export const useGlobalSearchHotkey = () => {
	const activeScope = useHotkeysActiveScope()
	const [qp, setQp] = useSearchParams()

	const callback = useCallback(() => {
		const node = document.activeElement
		if (node && isElementInputable(node)) {
			return
		}

		setQp({
			...qp,
			search: '',
		})
	}, [setQp, qp])

	useHotkey(activeScope, 'alt+s', callback)
}
