import CJSCore from '@clepside/clepsidejs/lib/commons/core_pb'
import { SessionPacket, SessionPacketData, SessionPacketMeta } from '@clepside/clepsidejs/lib/packets_v1/sessions_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { generatePacketMeta } from './persist.utils'
import { SessionPacketObject } from './sync.tsx.packets.types'

export function constructSessionPacket(readyToSendPacket: Packet, sessionPacket: SessionPacketObject) {
	const tbsSessionPacket = new SessionPacket()
	const { data, deleted, type, id, meta } = sessionPacket
	if (!data) throw new Error('Packet data is undefined')

	const { activityId, start, end } = data

	tbsSessionPacket.setId(id)

	if (!type) {
		throw new Error('packet type not found')
	}

	if (deleted) {
		const deletedMeta = new CJSCore.DeleteMeta()
		deletedMeta.setAt(deleted.at)
		deletedMeta.setBy(deleted.by)
		deletedMeta.setIs(deleted.is)
		tbsSessionPacket.setDeleted(deletedMeta)
	}

	if (data) {
		const newData = new SessionPacketData()
		const newMeta = new SessionPacketMeta()

		if (activityId) {
			newData.setActivityId(activityId)
		}

		if (start && meta.start) {
			newData.setStart(start)
			newMeta.setStart(generatePacketMeta(sessionPacket, 'start'))
		}

		if (end && meta.end) {
			newData.setEnd(end)
			newMeta.setEnd(generatePacketMeta(sessionPacket, 'end'))
		}

		tbsSessionPacket.setData(newData)
		tbsSessionPacket.setMeta(newMeta)
		tbsSessionPacket.setType(type)
	}

	readyToSendPacket.setSession(tbsSessionPacket)
}
