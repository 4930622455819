import { UploadAvatarRequest } from '@clepside/clepsidejs/lib/services/filer_v1_pb';
import { PersonCircle } from '@comps/complex/personCircle';
import { SettingsItem } from '@comps/complex/settingsItem';
import { TextInput } from '@comps/forms/textInput';
import { TextInputGroup } from '@comps/forms/textInputGroup';
import { WatcherButton } from '@comps/interactive/watcherButton';
import { Flex } from '@comps/layout/flex';
import { Inset } from '@comps/layout/inset';
import { Spacer } from '@comps/layout/space';
import { Text } from '@comps/typography/text';
import { FilerGrpc } from '@root/grpc/grpcFiler';
import { useActiveUserProfile } from '@root/hooks/useActiveUserProfile';
import { useForm } from '@root/hooks/useForm';
import { useFormConfig } from '@root/hooks/useFormConfig';
import { useWatcher } from '@root/hooks/useWatcher';
import { userProfilesActions } from '@root/store/slices/userProfiles';
import { watcherActions } from '@root/store/slices/watchers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

const drawImageCover = (ctx: CanvasRenderingContext2D, img: HTMLImageElement, width: number, height: number) => {
	const imgAspectRatio = img.width / img.height
	const canvasAspectRatio = width / height

	let renderWidth, renderHeight, startX, startY

	// Image's aspect ratio is greater than canvas's aspect ratio
	if (imgAspectRatio > canvasAspectRatio) {
		renderHeight = height
		renderWidth = img.width * (height / img.height)
		startX = (width - renderWidth) / 2
		startY = 0
	} else {
		renderWidth = width
		renderHeight = img.height * (width / img.width)
		startX = 0
		startY = (height - renderHeight) / 2
	}

	ctx.drawImage(img, startX, startY, renderWidth, renderHeight)
}
const readFileAndDrawOnCanvas = (file: File, canvas: HTMLCanvasElement): Promise<void> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()

		reader.onload = (event) => {
			const img = new Image()

			img.onload = () => {
				const ctx = canvas.getContext('2d')
				if (ctx) {
					drawImageCover(ctx, img, canvas.width, canvas.height)
					resolve() // Resolve the promise when drawing is done
				} else {
					reject(new Error('Failed to get canvas context'))
				}
			}

			img.onerror = () => {
				reject(new Error('Failed to load image'))
			}

			img.src = event.target?.result as string
		}

		reader.onerror = () => {
			reject(new Error('Failed to read file'))
		}

		reader.readAsDataURL(file)
	})
}
const canvasToUint8Array = async (canvas: HTMLCanvasElement, mimeType = 'image/jpeg', quality = 0.85): Promise<Uint8Array> => {
	return new Promise((resolve, reject) => {
		canvas.toBlob(
			(blob) => {
				if (blob) {
					const reader = new FileReader()
					reader.onloadend = () => {
						resolve(new Uint8Array(reader.result as ArrayBuffer))
					}
					reader.onerror = (err) => {
						reject(err)
					}
					reader.readAsArrayBuffer(blob)
				} else {
					reject(new Error('Failed to create blob from canvas'))
				}
			},
			mimeType,
			quality
		)
	})
}

// Create a custom readable stream to track progress
function createProgressStream(inputData: Uint8Array, onProgress: (sent: number, total: number) => void): ReadableStream<Uint8Array> {
	let totalSent = 0

	return new ReadableStream({
		start(controller: ReadableStreamDefaultController<Uint8Array>) {
			controller.enqueue(inputData)
			controller.close()
		},
		pull(controller: ReadableStreamDefaultController<Uint8Array>) {
			totalSent += inputData.length
			onProgress(totalSent, inputData.length)
		},
	})
}

export const SettingsProfile = () => {
	const profile = useActiveUserProfile()
	const fileInputRef = useRef<HTMLInputElement>(null)
	const dispatch = useDispatch()
	const { watcherId } = useWatcher()
	const { watcherId: updateDisplayNameWatcherId } = useWatcher()
	const [displayName, setDisplayName] = useState<string>('')

	const handleButtonClick = () => {
		fileInputRef.current?.click()
	}

	const formConfig = useFormConfig(
		{
			fields: {
				displayName: {
					defaultValue: profile?.displayName,
					validate: '',
				},
			},
			onSubmit: (state, values) => {
				dispatch(
					userProfilesActions.updateProfile({
						profile: {
							displayName: values.displayName,
						},
						watcherId: updateDisplayNameWatcherId,
					})
				)
			},
		},
		[profile?.displayName]
	)
	const { fields, injectFormField, submit, state: formState, errors } = useForm(formConfig)

	const actualDisplayName = useRef(profile?.displayName)

	useEffect(() => {
		actualDisplayName.current = profile?.displayName
	}, [profile])

	const handleFileChange = useCallback(
		async (event: any) => {
			dispatch(watcherActions.start({ watchers: [watcherId] }))
			const file = event.target.files?.[0]
			if (!file) return
			const canvas = document.getElementById('myCanvas') as HTMLCanvasElement
			await readFileAndDrawOnCanvas(file, canvas)

			let jpegDataArray: any = null
			try {
				jpegDataArray = await canvasToUint8Array(canvas)
			} catch (error) {
				console.error('Error converting canvas to Uint8Array:', error)
			}

			const request = new UploadAvatarRequest()
			request.setImageData(jpegDataArray)

			try {
				const response = await FilerGrpc.uploadAvatar(request)
				const uploadURL = response.getUrl()
				const checksumStr = response.getChecksum()

				await fetch(uploadURL, {
					method: 'PUT',
					body: jpegDataArray,
					headers: {
						'Content-Type': 'image/jpeg',
						'x-goog-content-sha256': checksumStr,
					},
				})

				dispatch(
					userProfilesActions.updateProfile({
						profile: {
							photoUrl: `avatar/${v4()}.jpeg`,
						},
						watcherId,
					})
				)
			} catch (e) {
				dispatch(watcherActions.fail({ watchers: [watcherId] }))
				console.error(e)
			}
		},
		[dispatch, watcherId]
	)

	return (
		<Flex direction="column">
			<Spacer size={12} vertical />
			<Text level="title60">Profile</Text>
			<Spacer size={50} vertical />
			<SettingsItem
				title="Display Name"
				subtitle="This will be displayed along with your username in most situations."
				rightside=""
			>
				<TextInputGroup label="Display Name" optional error={errors?.displayName} watcherId={updateDisplayNameWatcherId}>
					<Flex width="100%" spacing={10}>
						<TextInput
							inputStyle="block"
							placeholder="Enter a Display Name"
							{...fields.displayName}
							ref={injectFormField('displayName', 'text')}
							onValueChange={(val) => {
								setDisplayName(val)
							}}
						/>
						{displayName !== actualDisplayName.current && (
							<WatcherButton
								onClick={submit}
								color="accent"
								insetPadding="buttonMedium"
								watcherId={updateDisplayNameWatcherId}
							>
								Update
							</WatcherButton>
						)}
					</Flex>
				</TextInputGroup>
				{/* 
				<TextInput inputStyle="block" placeholder="Enter a Display Name" defaultValue={profile?.displayName} />
				{
					<WatcherButton onClick={handleButtonClick} color="accent" watcherId={watcherId} insetPadding="buttonSmall">
						Save
					</WatcherButton>
				} */}
			</SettingsItem>
			<SettingsItem
				title="Profile Photo"
				subtitle="The profile photo can be seen by other people your share activities, sessions and boards with."
				rightside=""
			>
				<Flex direction="column">
					<PersonCircle type="person" id={profile?.id} size="large" />
					<Spacer size={10} vertical />
					<WatcherButton onClick={handleButtonClick} color="accent" watcherId={watcherId} insetPadding="buttonSmall">
						<Inset>Upload a File</Inset>
					</WatcherButton>
					<canvas id="myCanvas" width="256" height="256"></canvas>
				</Flex>
				<input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} />
			</SettingsItem>
		</Flex>
	)
}

// Debug:
// // Convert Uint8Array to a Blob
// const blob = new Blob([jpegDataArray], { type: 'image/jpeg' })

// // Generate a URL for the blob
// const url = URL.createObjectURL(blob)

// // Create an anchor element
// const a = document.createElement('a')
// a.href = url
// a.download = 'downloaded_image.jpg' // Name of the downloaded file
// a.textContent = 'Download JPEG'

// // Append the anchor to the body (or any other visible DOM element)
// document.body.appendChild(a)

// // Optionally: Click the link automatically to start the download
// a.click()
