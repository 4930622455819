import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { parseTemplate } from '@root/utils/general';

import { sessionsActions } from '../slices/sessions';
import { SessionDisplayConditions, SessionsDisplayScopeKeyTemplate } from '../slices/sessionsTypes';

export function useSessionsSource(
	between: SessionDisplayConditions,
	scopeKeyTemplate: SessionsDisplayScopeKeyTemplate,
	...params: (string | undefined)[]
) {
	const dispatch = useDispatch()
	const scopeKey = useMemo(() => {
		return parseTemplate(scopeKeyTemplate, ...params) as string
	}, [scopeKeyTemplate, params])

	useEffect(() => {
		if (!between) return
		dispatch(
			sessionsActions.setActiveScope({
				scopeKey: scopeKey,
				scope: {
					key: scopeKey,
					between,
				},
			})
		)

		return () => {
			dispatch(sessionsActions.scheduleScopeCancelling({ scopeKey: scopeKey }))
		}
	}, [dispatch, scopeKey, between])

	return null
}
