import { Flex } from '@comps/layout/flex'
import { PageHeader } from '@comps/layout/pageHeader'
import { PageInset } from '@comps/layout/pageInset'
import { useBoards } from '@root/store/selectors/useBoards'
import { boardsActions } from '@root/store/slices/boards'
import { FlowID } from '@root/store/slices/flows.types'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { BoardButton } from './Boards.boardButton'

// import { boardsActions } from '@root/store/slices/boards';

export const Boards: React.FC = () => {
	const dispatch = useDispatch()
	const [qp, setQp] = useSearchParams()
	const handleNewBoardClick = useCallback(() => {
		setQp({
			flow: 'create-or-edit-board' as FlowID,
		})
	}, [setQp])
	const boards = useBoards()

	useEffect(() => {
		dispatch(boardsActions.initialize())
	}, [dispatch])

	return (
		<PageInset larger>
			<Flex direction="column" align="stretch" grow>
				<PageHeader title="Boards" mainAction={{ action: handleNewBoardClick, label: 'Create a Board' }} />
				{/* <Text>Boards</Text>
					<Button color="subtleAccent" inset="buttonMedium" onClick={handleNewBoardClick}>
						Create a Board
					</Button>
				</PageHeader> */}
				<Flex justify="flex-start" align="flex-start" maxWidth="530px" spacing={30}>
					{boards.all.map((bid) => {
						const board = boards.at[bid]
						return <BoardButton boardName={board.name} key={bid} boardColor={board.color} boardId={bid} />
					})}
				</Flex>
			</Flex>
		</PageInset>
	)
}
