import { BatchedSendablesStream, SendableStream } from '@clepside/clepsidejs/lib/services/sync_v1_pb'
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb'
import { Logger } from '@root/cores/logger'
import { STREAM_ENDED } from '@root/grpc/utils'
import { put } from 'redux-saga/effects'
import { imageProcessingActions } from './imageProcessing'
import { mergePacketsToLocalTransaction } from './persist.transactionMergeLocal'
import { mergeRunesToLocalTransaction } from './runes.transactionMergeLocal'
import { RuneObject } from './runes.types'
import { PacketObject } from './sync.tsx.packets.types'
import { ResourceComprehensiveStreamingConfig, Syncable } from './sync.types'
import { incrementSyncTimestamp, markSyncingAsInterrupted, SyncKey, SyncKeyTemplate } from './sync.utils'

export function* processBatchedSendablesStream(response: BatchedSendablesStream, key: SyncKey, keyTemplate: SyncKeyTemplate, config: Syncable): any {
	const object = response?.toObject?.()
	if (!object) return
	const sendables = object.sendables

	if (object.streamFinished) {
		yield incrementSyncTimestamp(keyTemplate, key)
		Logger.sync.debug(`[${key}] Closed`)
		return
	}

	let matched = false
	if (sendables) {
		if (sendables.sendablesList.length == 0) {
			matched = true
		}

		matched = yield processSendablesFromConfig(config, key, sendables.sendablesList, true, `[${keyTemplate}]`)
	}

	if (!matched) {
		Logger.sync.debug(`${key}] ⚠️ Unhandled Response.`, object)
	}
}

export function* processResourceComprehensiveStream(
	response: SendableStream,
	operationId: string,
	configs: ResourceComprehensiveStreamingConfig<any>[]
): any {
	try {
		const object = response?.toObject?.()
		if (!object) return

		const sendable = object.sendable
		const hasFinished = object.streamFinished

		if (hasFinished && hasFinished) {
			for (const config of configs) {
				// Logger.sync.debug(`[ResourceSync][${operationId}][Sync${config.lmat_template}] Finished`)
				yield incrementSyncTimestamp(config.lmat_template, config.lmat_key)
				Logger.sync.debug(`[ResourceSync][${operationId}][Sync${config.lmat_template}] «Stamp${name}»++`)
			}
			return
		}

		if (!sendable) {
			throw new Error('no sendable streamed')
		}

		let matched = false

		for (const config of configs) {
			const success = yield processSendablesFromConfig(config.syncable, config.lmat_key, [sendable], true, `[Sync${config.lmat_template}]`)

			if (success) {
				matched = true
				break
			}
		}

		if (!matched) {
			Logger.sync.error(`[ResourceSync][${operationId}] ⚠️ Unhandled Response`, object)
		}
	} catch (e) {
		console.error('resourceComprehensiveStream has been interrupted', e)
		for (const config of configs) {
			markSyncingAsInterrupted(config.lmat_key)
		}
	}
}

export type SendableProcessingConfig =
	| {
			rop: 'packet'
			extract: (res: Sendable.AsObject | undefined) => PacketObject<any> | undefined
	  }
	| {
			rop: 'rune'
			extract: (res: Sendable.AsObject | undefined) => RuneObject<any> | undefined
	  }

export function* processSendablesFromConfig(
	config: Syncable,
	key: SyncKey,
	sendables: Sendable.AsObject[],
	receivedFromSync?: boolean,
	logPrefix = ''
): any {
	if (config.runeOrPacket == 'packet') {
		const packets = config.fromSendables(sendables)
		if (!packets || packets.length == 0) {
			return
		}
		Logger.sync.debug(`${logPrefix}[Sync${key}] Received Sendable: Packet`, packets)
		const packetObjects = yield mergePacketsToLocalTransaction(packets, config, key, true)
		if (!packetObjects) {
			return
		}
		yield put(config.placeInStore(packetObjects, receivedFromSync))
		return true
	} else if (config.runeOrPacket == 'rune') {
		const runes = config.fromSendables(sendables)
		if (!runes || runes.length == 0) {
			return
		}
		Logger.sync.debug(`${logPrefix}[Sync${key}] Received Sendable Rune: `, runes)
		const runeObjects = yield mergeRunesToLocalTransaction(runes, config, key)

		if (!runeObjects) {
			return
		}
		yield put(config.placeInStore(runeObjects, receivedFromSync))
		return true
	}
	return false
}
