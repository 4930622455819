

import { getDaySpan } from '@root/utils/dates'
import { DayIndex } from '../commonTypes'
import { NormalisedSessionData, SessionData } from './sessionsTypes'

export const normalizeSessionPacket = (id: string, s: SessionData) => {
	const normalizedSession: Partial<NormalisedSessionData> = {
		id,
	}

	if (s.end) normalizedSession.end = s.end
	if (s.start) normalizedSession.start = s.start
	if (s.activityId) normalizedSession.activityId = s.activityId
	if (s.description) normalizedSession.description = s.description
	if (s.title) normalizedSession.title = s.title

	if (normalizedSession.start && normalizedSession.end)
		normalizedSession.daySpan = getDaySpan(new Date(s.start), new Date(s.end))
	return normalizedSession as NormalisedSessionData
}

export function* canSessionFit(id: string, to: { start: DayIndex; end: DayIndex }) {
	// const dayIndexesSessionTouches = getDaySpan(fromUnixTime(to.start), fromUnixTime(to.end))
	// if (!dayIndexesSessionTouches?.length) return false
	// const sessionsInThoseDays: SessionPacketObject[] =
	// 	dayIndexesSessionTouches.length === 1
	// 		? yield Database.sessionPackets.where('data.daystampsList').equals(dayIndexesSessionTouches[0]).toArray()
	// 		: yield Database.sessionPackets
	// 				.where('data.daystampsList')
	// 				.between(dayIndexesSessionTouches[0], dayIndexesSessionTouches[dayIndexesSessionTouches.length - 1])
	// 				.toArray()

	// const affectedSessions: StoredSession[] = sessionsInThoseDays
	// 	.filter((p) => !p.deleted?.is)
	// 	.map((packet) => normalizeSessionPacket(packet.id, packet.data))

	// for (const s of affectedSessions) {
	// 	// If we're comparing the same session as the one we're moving..
	// 	if (s.id === id) continue
	// 	if (s.end > to.start) {
	// 		if (s.start < to.end) {
	// 			return false
	// 		}
	// 	}
	// }

	return true
}
