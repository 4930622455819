import {
	createContext, FunctionComponent, ReactNode, useCallback, useContext, useState
} from 'react';
import styled from 'styled-components';

import { Button } from './interactive/button';
import { Text } from './typography/text';

interface IDialogContext {
	showDialog: (title: string, description: string, callback: () => void) => void
	hideDialog: () => void
}

interface IDialogProviderProps {
	children: ReactNode
}

const DialogContext = createContext<IDialogContext | undefined>(undefined)

const DialogProvider: FunctionComponent<IDialogProviderProps> = ({ children }) => {
	const [isVisible, setIsVisible] = useState(false)
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [callback, setCallback] = useState<() => void>(() => {})

	const showDialog = useCallback((newTitle: string, newDescription: string, newCallback: () => void) => {
		setTitle(newTitle)
		setDescription(newDescription)
		setCallback(() => newCallback)
		setIsVisible(true)
	}, [])

	const hideDialog = useCallback(() => {
		setIsVisible(false)
		setTitle('')
		setDescription('')
		setCallback(() => {})
	}, [])

	const handleConfirm = useCallback(() => {
		callback()
		hideDialog()
	}, [callback, hideDialog])

	return (
		<DialogContext.Provider value={{ showDialog, hideDialog }}>
			{children}
			{isVisible && (
				<Holder>
					<Content>
						<Text level="title70">{title}</Text>
						<Text>{description}</Text>
						<ButtonRow>
							<Button onClick={handleConfirm} inset="buttonMedium" color="clepside">
								Confirm
							</Button>
							<Button onClick={hideDialog} inset="buttonMedium" color="darkTranslucentElevated">
								Cancel
							</Button>
						</ButtonRow>
					</Content>
				</Holder>
			)}
		</DialogContext.Provider>
	)
}

const ButtonRow = styled.div`
	display: flex;
	align-items: center;
	column-gap: 10px;
`
const Content = styled.div`
	display: flex;
	flex-direction: column;
	color: #fff;
	align-items: center;
	row-gap: 20px;
	max-width: 500px;
	text-align: center;
	transform: translateY(-20px);
`

const Holder = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 200000000000;
	backdrop-filter: blur(40px);
`

const useDialog = () => {
	const context = useContext(DialogContext)
	if (!context) {
		throw new Error('useDialog must be used within a DialogProvider')
	}
	return context
}

export { DialogProvider, useDialog };

