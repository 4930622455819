import { TextInput } from '@comps/forms/textInput'
import { Button } from '@comps/interactive/button'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { List } from '@comps/layout/list'
import { ListHolder } from '@comps/layout/listHolder'
import { Spacer } from '@comps/layout/space'
import { KeyScope } from '@comps/logical/keyScope'
import { ActivityBubble } from '@comps/static/activityBubble'
import { ActivityBubbleDimensions } from '@comps/static/activityColorBubble'
import { Icon } from '@comps/static/icon'
import { Text } from '@comps/typography/text'
import { useRefTaker } from '@hooks/useRefTaker'
import { useCachedSelector } from '@root/hooks/useCachedSelector'
import { RootState } from '@root/store'
import { flowsActions } from '@root/store/slices/flows'
import { sessionsActions } from '@root/store/slices/sessions'
import { useActivities } from '@store/selectors/useActivities'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useFlowExit } from './useFlowExit'

export const FlowPlanSelectActivity = () => {
	const [fieldRef, setFieldRef] = useRefTaker()
	const [filterValue, setFilterValue] = useState('')
	const dispatch = useDispatch()
	const context = useCachedSelector((state: RootState) => state.flows.flowContexts['plan-session'])
	const exit = useFlowExit()

	const activities = useActivities()

	const allRows = useMemo(() => {
		return ['create-activity', ...activities.all]
	}, [activities])

	const memoedListDictionary = useMemo(() => {
		return allRows.reduce((acc, id) => {
			if (id == 'create-activity') {
				acc[id] = (
					<Flex align="center" grow>
						<BlankBubble>
							<Icon type="plus" />
						</BlankBubble>
						<Spacer size={15} />
						<Text color="action">Create a new Activity</Text>
					</Flex>
				)
				return acc
			}
			acc[id] = (
				<Flex align="center" grow>
					<ActivityBubble activity={activities.at[id]} />
					<Spacer size={15} />
					<Text>{activities.at[id].name}</Text>
				</Flex>
			)
			return acc
		}, {} as any)
	}, [activities, allRows])

	const filteredList = useMemo(() => {
		if (!filterValue) return allRows
		return allRows.filter((key) => {
			if (key == 'create-activity') return true
			return activities.at[key].name.toLowerCase().includes(filterValue.toLowerCase())
		})
	}, [filterValue, allRows, activities])

	useEffect(() => {
		fieldRef?.focus()
	}, [fieldRef])

	const renderItem = useCallback(
		(item: string) => {
			return memoedListDictionary[item]
		},
		[memoedListDictionary]
	)

	const handleSelection = useCallback(
		(key: string) => {
			if (key == 'create-activity') {
				dispatch(
					flowsActions.startFlow({
						flowType: 'create-or-edit-activity',
						ancestor: 'plan-session',
					})
				)
			} else {
				if (!context) return
				dispatch(
					sessionsActions.new({
						start: new Date(context.start).getTime(),
						end: new Date(context.end).getTime(),
						activityId: key,
					})
				)
				exit()
			}
		},
		[dispatch, context, exit]
	)

	useEffect(() => {
		if (context?.createdActivityId) {
			dispatch(
				sessionsActions.new({
					start: new Date(context.start).getTime(),
					end: new Date(context.end).getTime(),
					activityId: context.createdActivityId,
				})
			)
			exit()
		}
	}, [context, dispatch, exit])

	return (
		<>
			<KeyScope scope="Plan.SelectActivity" unmountScope='Plan' />
			<TextInput
				inputRef={setFieldRef}
				inputStyle="block"
				placeholder={context?.start ? 'Select activity for timeframe' : 'Filter through activties...'}
				onValueChange={(value) => setFilterValue(value)}
				stickToModalTop
				autoFocus
			/>
			<ListHolder>
				<List
					minHeight={1000}
					scope="Plan.SelectActivity"
					items={filteredList}
					renderItem={renderItem}
					borderOffsetLeft={45}
					value={filterValue}
					onSelect={handleSelection}
				/>
			</ListHolder>
			<Button color="subtle" onClick={exit}>
				<Inset align="center" justify="center" padding="buttonMedium">
					Cancel
				</Inset>
			</Button>
		</>
	)
}

const BlankBubble = styled.div`
	width: ${ActivityBubbleDimensions.small}px;
	height: ${ActivityBubbleDimensions.small}px;
	${(p) => p.theme.backgrounds.accent.r.css('color')};
	${(p) => p.theme.backgrounds.subtleAccent.r.css('background-color')};
	border-radius: 999px;
	display: flex;
	align-items: center;
	justify-content: center;
`
