import { SmartBorder } from '@comps/complex/smartBorder'
import { Button } from '@comps/interactive/button'
import { Flex } from '@comps/layout/flex'
import { Spacer } from '@comps/layout/space'
import { Icon } from '@comps/static/icon'
import { FontStyles, Text } from '@comps/typography/text'
import { useStartOfWeek } from '@hooks/useStartOfWeek'
import { Collection } from '@root/store/commonTypes'
import { InstancedSessionData } from '@root/store/slices/sessionsTypes'
import { range } from '@utils/general'
import { add, format, getWeek, isSameDay, isSameYear, isToday, isTomorrow, isYesterday } from 'date-fns'
import React, { MouseEventHandler, useMemo } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { CalendarDayAnalytics } from './Calendar.DayAnalytics'
import { CalendarDayNavigationButtons } from './Calendar.dayNavigationButtons'
import { useDayNavigationBorderToggle } from './Calendar.useDayNavigationBorderToggle'

const newLevel = {
	level: '1',
}
export const CalendarDayNavigation: React.FC<{
	sessions: Collection<InstancedSessionData>
	forDate: Date
	containerRef: any
	setPresentedDate: (d: Date) => void
	onDayNavigationClicked: MouseEventHandler<HTMLButtonElement>
}> = ({ forDate, sessions, containerRef, setPresentedDate, onDayNavigationClicked }) => {
	const holder = useDayNavigationBorderToggle(containerRef)
	const startOfWeek = useStartOfWeek()
	const [, setQp] = useSearchParams()

	const labels = useMemo(() => {
		const shouldShowYear = isSameYear(forDate, new Date()) ? '' : ', yyyy'

		const bigLabel = () => {
			// if (isToday(forDate)) return 'Today'
			// if (isTomorrow(forDate)) return 'Tomorrow'
			// if (isYesterday(forDate)) return 'Yesterday'
			return format(forDate, shouldShowYear ? `d MMM${shouldShowYear}` : 'd MMMM')
		}

		const shouldShortenWeeklyButton = () => {
			if (isToday(forDate)) return true
			if (isTomorrow(forDate)) return true
			if (isYesterday(forDate)) return true
			return false
		}

		const smallLabel = () => {
			if (isToday(forDate) || isTomorrow(forDate) || isYesterday(forDate)) return format(forDate, `EEEE, d MMM${shouldShowYear}`)
			return format(forDate, `d MMM${shouldShowYear}`)
		}

		return {
			bigLabel: bigLabel(),
			smallLabel: smallLabel(),
			shouldShortenWeeklyButton: shouldShortenWeeklyButton(),
		}
	}, [forDate])

	const dayBlocks = useMemo(() => {
		const weekStart = startOfWeek(forDate)
		const weekDates = range(7).map((i) => {
			return add(weekStart, { days: i })
		}, [])

		return weekDates.map((t, i) => {
			return (
				<DayButton
					key={format(t, 'EE')}
					color="activable"
					onClick={() => {
						setPresentedDate(t)
					}}
					isActive={isSameDay(t, forDate)}
					isToday={isSameDay(t, new Date())}
				>
					{format(t, 'EE').substring(0, 2)}
				</DayButton>
			)
		})
	}, [forDate, startOfWeek, setPresentedDate])

	return (
		<Holder ref={holder}>
			<Flex justify="space-between" align="center">
				<Flex align="baseline">
					<Text key={labels.bigLabel} level="title70">
						{labels.bigLabel}
					</Text>
					<CalendarDayAnalytics sessions={sessions} date={forDate} />
				</Flex>
				<CalendarDayNavigationButtons forDate={forDate} onDayNavigationClicked={onDayNavigationClicked} />
			</Flex>
			<DaysSection>
				<DayHolders>{dayBlocks}</DayHolders>
				{/* <Button
					inset="buttonTiny"
					color="textual"
					onClick={() => {
						setQp({ 'full-week': 'true' })
					}}
				>
					<Text>
						<Flex align="center">
							{format(forDate, 'EEEE')}
							<CircleArrow direction="right" />
						</Flex>
					</Text>
				</Button> */}
				<Flex justify="flex-end">
					{/* <Inset negativeX padding="buttonSmall"> */}
					<Link to="/calendar/week" state={newLevel}>
						<Button inset="buttonSmall" color="textualAccent">
							<Text level="label" block>
								{/* {labels.shouldShortenWeeklyButton
									? `${format(forDate, 'd MMM')}, Week ${getWeek(forDate)}`
									: `W${getWeek(forDate)}`} */}
								{`Week ${getWeek(forDate)}`}
							</Text>
							<Spacer size={5} />
							<Icon type="diagonalArrow" offsetY={1} />
						</Button>
					</Link>
					{/* </Inset> */}
				</Flex>
			</DaysSection>
			<SmartBorder />
		</Holder>
	)
}

const DaysSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin-bottom: 5px;
	margin-top: 15px;
`

const DayHolders = styled.div`
	padding: 0px;
	margin-left: -1px;
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-column-gap: 1px;
`

const DayButton = styled(Button) <{ isToday?: boolean; isActive?: boolean }>`
	display: flex;
	align-items: center;
	margin: 0 0px;
	width: 27px;
	justify-content: center;
	text-align: center;
	position: relative;
	box-sizing: border-box;
	line-height: 20px;
	text-transform: uppercase;

	${FontStyles.tiny};
	${(p) => p.theme.text.subtle.r.css('color', '', ' !important')};

	&:hover {
		${(p) => p.theme.text.regular.r.css('color', '', ' !important')};
	}
	&:active {
		color: #fff !important;
	}

	${(p) =>
		p.isActive &&
		css`
			z-index: 1;
			${p.theme.backgrounds.subtle.s2.css('background-color', undefined, '!important')};
			${p.theme.text.regular.r.css('color', undefined, '!important')};

			font-weight: 600;
			&:hover,
			&:active {
				color: #fff !important;
			}
		`}

	${(p) =>
		p.isToday &&
		css`
			position: relative;
		`}

	${(p) =>
		p.isToday &&
		p.isActive &&
		css`
			position: relative;
			/* ${p.theme.backgrounds.clepside.r.css('background-color', undefined, '!important')}; */

			&:hover,
			&:active {
				color: #fff !important;
			}
		`}
`

const Holder = styled.div`
	display: flex;
	flex-direction: column;
	position: sticky;
	top: 0;
	padding: 20px 0px 20px 0px;
	z-index: 100;
	width: 20vw;
	min-width: 300px;
	background-color: #fff;
`
