import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { ActivityPacket } from '@clepside/clepsidejs/lib/packets_v1/activities_pb'
import { BoardPacket } from '@clepside/clepsidejs/lib/packets_v1/boards_pb'
// import { BoardPacket } from '@clepside/clepsidejs/lib/packets_v1/boards_pb'
import { CardPacket } from '@clepside/clepsidejs/lib/packets_v1/cards_pb'
import { FragmentCompletablePacket } from '@clepside/clepsidejs/lib/packets_v1/fragments_completable_pb'
import { FragmentPlainTextPacket } from '@clepside/clepsidejs/lib/packets_v1/fragments_plain_text_pb'
import { FragmentUploadablePacket } from '@clepside/clepsidejs/lib/packets_v1/fragments_uploadable_pb'
import { RoutinePacket } from '@clepside/clepsidejs/lib/packets_v1/routine_pb'
import { RoutineSchedulePacket } from '@clepside/clepsidejs/lib/packets_v1/routine_schedule_pb'
import { SessionPacket } from '@clepside/clepsidejs/lib/packets_v1/sessions_pb'
import { SettingsPacket } from '@clepside/clepsidejs/lib/packets_v1/settings_pb'
// import { SessionPacket } from '@clepside/clepsidejs/lib/packets_v1/sessions_pb'
import { ActivityData } from './activitiesTypes'
import { BoardData } from './boardsTypes'
import { CardData } from './cards.types'
import { FragmentCompletableData, FragmentPlainTextData, FragmentUploadableData } from './fragments.types'
import { RoutineData } from './routines.types'
import { RoutineScheduleData } from './routineSchedules.types'
import { SessionData } from './sessionsTypes'
import { SettingsData } from './settings.types'
// import { VitalsPacket } from '@clepside/clepsidejs/lib/packets_v1/vitals_pb'
import { ValidPacketType } from './sync.types'

type AdaptedForLocal<K extends keyof PacketTypesMapping> = Required<Omit<PacketTypesMapping[K]['remote'], 'type'>> & { type: K }

export type ActivityPacketObject = AdaptedForLocal<PacketType.ACTIVITY>
export type SessionPacketObject = AdaptedForLocal<PacketType.SESSION>
export type BoardPacketObject = AdaptedForLocal<PacketType.BOARD>
export type CardPacketObject = AdaptedForLocal<PacketType.CARD>
export type SettingsPacketObject = AdaptedForLocal<PacketType.SETTINGS>
export type RoutinePacketObject = AdaptedForLocal<PacketType.ROUTINE>
export type RoutineSchedulePacketObject = AdaptedForLocal<PacketType.ROUTINE_SCHEDULE>

export type FragmentCompletablePacketObject = AdaptedForLocal<PacketType.FRAGMENT_COMPLETABLE>
export type FragmentPlainTextPacketObject = AdaptedForLocal<PacketType.FRAGMENT_PLAIN_TEXT>
export type FragmentUploadablePacketObject = AdaptedForLocal<PacketType.FRAGMENT_UPLOADABLE>
export type FragmentWithComputedLocalProps<T extends FragmentCompletablePacketObject | FragmentPlainTextPacketObject | FragmentUploadablePacketObject> = T & {
	clientOnlyLastUpdateAt: number
}
// export type VitalsPacketObject = AdaptedForLocal<PacketType.VITALS>

export type GenericFragmentPacketObject = FragmentCompletablePacketObject | FragmentPlainTextPacketObject | FragmentUploadablePacketObject

export type PacketTypesMapping = {
	[PacketType.ACTIVITY]: {
		type: PacketType.ACTIVITY
		remote: ActivityPacket.AsObject
		local: ActivityPacketObject
		stateData: ActivityData
	}
	[PacketType.SESSION]: {
		type: PacketType.SESSION
		remote: SessionPacket.AsObject
		local: SessionPacketObject
		stateData: SessionData
	}
	[PacketType.BOARD]: {
		type: PacketType.BOARD
		remote: BoardPacket.AsObject
		local: BoardPacketObject
		stateData: BoardData
	}
	[PacketType.CARD]: {
		type: PacketType.CARD
		remote: CardPacket.AsObject
		local: CardPacketObject
		stateData: CardData
	}
	[PacketType.SETTINGS]: {
		type: PacketType.SETTINGS,
		remote: SettingsPacket.AsObject,
		local: SettingsPacketObject,
		stateData: SettingsData
	}
	[PacketType.ROUTINE]: {
		type: PacketType.ROUTINE,
		remote: RoutinePacket.AsObject,
		local: RoutinePacketObject,
		stateData: RoutineData
	}
	[PacketType.ROUTINE_SCHEDULE]: {
		type: PacketType.ROUTINE_SCHEDULE,
		remote: RoutineSchedulePacket.AsObject,
		local: RoutineSchedulePacketObject,
		stateData: RoutineScheduleData
	}
	// [PacketType.VITALS]: {
	//     remote: VitalsPacket.AsObject,
	//     local: VitalsPacketObject,
	// }
	[PacketType.FRAGMENT_COMPLETABLE]: {
		type: PacketType.FRAGMENT_COMPLETABLE
		remote: FragmentCompletablePacket.AsObject
		local: FragmentCompletablePacketObject
		stateData: FragmentCompletableData
	}
	[PacketType.FRAGMENT_PLAIN_TEXT]: {
		type: PacketType.FRAGMENT_PLAIN_TEXT
		remote: FragmentPlainTextPacket.AsObject
		local: FragmentPlainTextPacketObject
		stateData: FragmentPlainTextData
	}
	[PacketType.FRAGMENT_UPLOADABLE]: {
		type: PacketType.FRAGMENT_UPLOADABLE
		remote: FragmentUploadablePacket.AsObject
		local: FragmentUploadablePacketObject
		stateData: FragmentUploadableData
	}
}

export function convertRemotePacketObjectToPacketObject<T extends keyof PacketTypesMapping>(
	packet: PacketTypesMapping[T]['remote']
): PacketTypesMapping[T]['local'] {
	return packet as any
}

export type RemotePacketObject = Exclude<PacketTypesMapping[keyof PacketTypesMapping], null>['remote']
export type PacketObject<K extends keyof PacketTypesMapping> = Extract<
	Exclude<PacketTypesMapping[keyof PacketTypesMapping], null>,
	{ type: K }
>['local']
export type PacketStateData<K extends keyof PacketTypesMapping> = Extract<
	Exclude<PacketTypesMapping[keyof PacketTypesMapping], null>,
	{ type: K }
>['stateData']
export type LocalQueueObject = PacketObject<any> & EnqueueingExtras
export type EnqueueingExtras = {
	clientOnlyEnqueuedAt: number
	clientOnlySendAfter: number
	// clientOnlyGrantingResourceId: string
	clientOnlySyncOrder: number
}
export type StoringExtras = {
	clientOnlySearchTerms: string[]
}

export function getIdFromLocalQueueObject(obj: LocalQueueObject) {
	if ('id' in obj)
		return obj.id
	if ('userId' in obj)
		return obj.userId
	throw new Error('no id')
}
export function getIdFromPacket(obj: PacketObject<any>) {
	if ('id' in obj)
		return obj.id
	if ('userId' in obj)
		return obj.userId
	throw new Error('no id')
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CheckPacketTypes = {
	[K in ValidPacketType]: PacketTypesMapping[K]
}

// export type IState = {}
export const State: any = {}
