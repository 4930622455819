import BackgroundImage from '@res/loginbg.jpg'
import { Medias } from '@root/medias'
import styled, { css } from 'styled-components'
import { Inset } from './inset'

export const SimplePage: React.FC<{ children: any; isFromApp?: boolean, center?: boolean }> = ({ children, isFromApp, center }) => {
	return (
		<Body isFromApp={isFromApp}>
			<Inset expand stretch padding="page" align={center ? 'center' : undefined} direction="column">
				{children}
			</Inset>
		</Body>
	)
}

const Body = styled.div<{ center?: boolean, isFromApp?: boolean }>`
	width: 100vw;
	height: 100vh;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	background-image: url('${BackgroundImage}');
	background-position: center center;
	background-size: cover;
	display: flex;

	${p => p.isFromApp && css`
		${p.theme.backgrounds.pro.r.css('background-color')};
	`}

	${(p) =>
		p.center &&
		css`
			align-items: center;
		`}

	@media ${Medias.PHONE} {
		background-image: none;
		overflow: auto;
	}
`
