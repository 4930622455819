import { Button } from '@comps/interactive/button'
import { Inset } from '@comps/layout/inset'
import { useMemo } from 'react'

export function useBackToApp(show: boolean, text: string) {
   return useMemo(() => {
      if (!show) return null
      return <Button color='pro' onClick={() => {
         const deepLink = process.env.REACT_APP_IOS_SCHEME + '://do-nothing';
         window.location.href = deepLink;
      }}>
         <Inset padding='buttonMedium'>
            {text}
         </Inset>
      </Button>
   }, [show, text])
}