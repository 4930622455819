import { useTime } from '@root/hooks/useTime'
import { endOfWeek, startOfWeek } from 'date-fns'
import { useMemo } from 'react'

export const useTimeCurrentWeek = () => {
	const time = useTime()

	return useMemo((): [Date, Date] => {
		return [startOfWeek(time), endOfWeek(time)]
	}, [time])
}
