import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb';
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb';
import { Database } from '@root/database';
import { domainToFilepath } from '@root/utils/general';
import { put } from 'redux-saga/effects';

import { fragmentsActions } from './fragments';
import { FragmentPlainTextSpecifics } from './fragments.types';
import { syncActions } from './sync';
import {
    FragmentPlainTextPacketObject, FragmentWithComputedLocalProps, PacketObject
} from './sync.tsx.packets.types';
import { getPacketObjectFromRemoteObject } from './sync.types';

export class SyncFragmentsPlainText {
    public static runeOrPacket = 'packet' as const

    public static type = PacketType.FRAGMENT_PLAIN_TEXT as const

    static fromSendables(res: Sendable.AsObject[] | undefined): PacketObject<any>[] {
        return res?.map((r) => getPacketObjectFromRemoteObject(r?.packet?.fragmentPlainText))
            .filter((f): f is PacketObject<any> => f !== undefined) || []
    }

    static placeInStore(objects: FragmentPlainTextPacketObject[], fromSync?: boolean) {
        return fragmentsActions.normalizeAndStore({ 
            objects: objects as FragmentWithComputedLocalProps<FragmentPlainTextPacketObject>[],
            fromSync
        })
    }

    static inTransaction(packet: FragmentPlainTextPacketObject) {
        if (packet?.type == PacketType.FRAGMENT_PLAIN_TEXT) {
            const content = JSON.parse(packet.data.contents) as FragmentPlainTextSpecifics

            if (content.type == 'LINK') {
                if (!content.link) return
			    Database.imageProcessing.add({
                    id: content.link,
                    filepath: domainToFilepath(content.link),
                    fragmentId: packet.id,
                    type: {
                        op: 'fetch-favicon',
                    },
                    sendAt: new Date().getTime(),
                    queuedAt: new Date().getTime(),
                    status: 'needs-fetching'
                })
            }
		}
    }

    static prepareForStorage(object: FragmentPlainTextPacketObject): FragmentWithComputedLocalProps<FragmentPlainTextPacketObject> {
        try {
            let lastUpdatedAt = object.lastModifiedAt
            for (const key of Object.keys(object.meta)) {
                const at = (object.meta as any)?.[key]?.at
                if (at && at > lastUpdatedAt) {
                    lastUpdatedAt = at
                }
            }

            return {
                ...object,
                clientOnlyLastUpdateAt: lastUpdatedAt
            }
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    static fromPacket(res: Packet.AsObject | undefined) {
        return getPacketObjectFromRemoteObject(res?.fragmentPlainText)
    }
    
    static *afterPersist() {
        yield put(syncActions.doCriticalOps())
    }
    
    static database = Database.fragmentPacketsPlainText

    static otherDatabaseLocks = [Database.imageProcessing]
}

export const SyncFragments = [SyncFragmentsPlainText]