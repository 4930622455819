import hotkeys from 'hotkeys-js';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Logger } from './cores/logger';
import { useHotkeysActiveScope } from './hooks/useHotkeysActiveScope';
import { interfaceActions } from './store/slices/interface';

let timeout: any = undefined
const KEY = 18
export function useDynamicHotkeys() {
	const dispatch = useDispatch()
	const scope = useHotkeysActiveScope()

	const bindCMDReleased = useCallback(() => {
		if (hotkeys.getPressedKeyCodes().includes(KEY)) return
		clearTimeout(timeout)
		timeout = undefined
		dispatch(interfaceActions.hideKeyTips())
	}, [dispatch])

	const bindCMDPressed = useCallback(() => {
		if (hotkeys.getPressedKeyCodes().includes(KEY)) {
			if (hotkeys.getPressedKeyCodes().length >= 2) {
				clearTimeout(timeout)
				timeout = undefined
				dispatch(interfaceActions.hideKeyTips())
			} else {
				clearTimeout(timeout)
				timeout = undefined
				timeout = setTimeout(() => {
					dispatch(interfaceActions.showKeyTips())
				}, 50)
			}
		}
	}, [dispatch])

	const rebindKeys = useCallback(
		(s: string) => {
			hotkeys.unbind('*', s, bindCMDPressed)
			hotkeys.unbind('*', s, bindCMDReleased)
			hotkeys(
				'*',
				{
					scope: s,
					keyup: true,
					keydown: false,
				},
				bindCMDReleased
			)
			hotkeys(
				'*',
				{
					scope: s,
					keyup: false,
					keydown: true,
				},
				bindCMDPressed
			)
		},
		[bindCMDPressed, bindCMDReleased]
	)

	useEffect(() => {
		if (scope) {
			Logger.hotkeys.debug('Binding scope: ', scope)
			hotkeys.setScope(scope)
			rebindKeys(scope)
		} else {
			Logger.hotkeys.debug('Releasing scopes')
			hotkeys.setScope('all')
		}
	}, [scope, dispatch, rebindKeys])
}
