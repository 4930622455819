import { NormalisedSessionData } from './sessionsTypes';

export const timeSliceState: {
	date: number
	minutesStamp: number
	secondsStamp: number
	upcomingSession?: NormalisedSessionData
} = { 
	date: new Date().getTime(),
	minutesStamp: new Date().getTime(),
	secondsStamp: new Date().getTime(),
	upcomingSession: undefined
}
