import { LexicalTypeLineNodeLevel } from '@clepside/clepsidejs/cards/lexical/transferTypes'
import { Button } from '@comps/interactive/button'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { Separator } from '@comps/layout/separator'
import { Spacer } from '@comps/layout/space'
import { Tooltip } from '@comps/layout/tooltip'
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import {
	LexicalLineNodeChaserAction,
	LexicalLineNodeChaserCommands,
	LexicalLineNodeChaserContext,
	LexicalLineNodeChaserContextPayload,
	LexicalLineNodeChaserState
} from './card.lexical.lineNodeChaserContext'

const reducer = (state: LexicalLineNodeChaserState, action: LexicalLineNodeChaserAction): LexicalLineNodeChaserState => {
	switch (action.type) {
		case 'changed':
			return { selection: action.selection ? { ...action.selection } : undefined }
		default:
			return state
	}
}

export const LexicalLineNodeChaser: React.FC<{
	children: any
}> = ({ children }) => {
	const [isSelectionCollapsed] = useState(false)
	const holderRef = useRef<HTMLDivElement | null>(null)
	const floaterRef = useRef<HTMLDivElement | null>(null)
	const [state, dispatch] = useReducer(reducer, { selection: undefined })
	const selectedLexicalResponder = useRef<LexicalLineNodeChaserCommands>(undefined)

	const levels = useMemo(() => {
		return Object.keys(LineNodeLevelToLabel) as Array<LexicalTypeLineNodeLevel>
	}, [])

	const contextMemo = useMemo<LexicalLineNodeChaserContextPayload>(() => {
		return {
			ref: selectedLexicalResponder,
			dispatch,
		}
	}, [dispatch, selectedLexicalResponder])

	useEffect(() => {
		const floater = floaterRef.current

		if (state.selection == undefined) {
			if (floater) {
				floater.style.display = 'none'
			}
		} else {
			const card = document.querySelector(`.card-${state.selection.holdingCardId}`)
			const rect = card?.getBoundingClientRect()

			if (rect && floater) {
				floater.style.display = 'flex'
				const leftSide = rect.left - LEXICAL_FLOATER_WIDTH - LEXICAL_FLOATER_PADDING
				const rightSide = rect.left + rect.width + LEXICAL_FLOATER_PADDING

				if (leftSide < 40) {
					floater.style.left = `${rightSide}px`
				} else {
					floater.style.left = `${leftSide}px`
				}
				floater.style.top = `${rect.top}px`
			}
		}
	}, [state])

	return (
		<Holder ref={holderRef}>
			<LexicalLineNodeChaserContext.Provider value={contextMemo}>{children}</LexicalLineNodeChaserContext.Provider>
			{createPortal(
				<LexicalFloater
					ref={floaterRef}
					tabIndex={0}
					className="fragment-chaser do-not-lose-highlights"
					onMouseDown={(e) => {
						e.preventDefault()
						e.stopPropagation()
					}}
				>
					<Inset padding="card" align="stretch" expandWidth>
						<Flex direction="column" align="stretch" grow>
							{!isSelectionCollapsed && (
								<>
									<TextStyleButtons>
										<Button
											inset="buttonSmall"
											color="tooltipRaised"
											className="bold"
											onClick={() => {
												selectedLexicalResponder.current?.setFormat('bold')
												// selectedLexicalResponder.current?.setLevel('paragraph')
											}}
										>
											B
										</Button>
										<Button
											inset="buttonSmall"
											color="tooltipRaised"
											className="italic"
											onClick={() => {
												selectedLexicalResponder.current?.setFormat('italic')
												// editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
											}}
										>
											i
										</Button>
										<Button
											inset="buttonSmall"
											color="tooltipRaised"
											onClick={() => {
												selectedLexicalResponder.current?.setFormat('underline')
												// editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'highlighted')
											}}
										>
											h
										</Button>
									</TextStyleButtons>
									{/* <Spacer size={10} vertical />
								<Button inset="buttonSmall" color="accent" grow>
									Add Link
								</Button> */}
									<Spacer size={20} vertical />
									<Separator color="tooltip" stretch expand />
								</>
							)}
							{levels.map((elem, i, arr) => {
								return (
									<React.Fragment key={elem}>
										<TooltipButton
											inset="buttonSmall"
											outset="buttonSmall"
											color="tooltip"
											className={`${elem}`}
											onClick={() => {
												selectedLexicalResponder.current?.setLevel(elem)
											}}
										>
											{LineNodeLevelToLabel[elem]}
										</TooltipButton>
										{arr.length - 1 == i ? <></> : <Separator color="tooltip" stretch expand />}
									</React.Fragment>
								)
							})}
						</Flex>
					</Inset>
				</LexicalFloater>,
				window.document.body
			)}
		</Holder>
	)
}

const LEXICAL_FLOATER_WIDTH = 180
const LEXICAL_FLOATER_PADDING = 20

const LexicalFloater = styled(Tooltip)`
	width: ${LEXICAL_FLOATER_WIDTH}px;
	align-items: stretch;
`

export const LineNodeLevelToLabel: { [key in LexicalTypeLineNodeLevel]: string } = {
	'heading-1': 'Title',
	'heading-2': 'Subtitle',
	'heading-3': 'Heading',
	paragraph: 'Paragraph',
	'unordered-list': '1. Bullet List',
	'ordered-list': '• Ordered List',
	'make-to-do': 'Todo',
}

const TooltipButton = styled(Button)`
	&.heading-1 {
		font-weight: bold;
		font-size: 19px;
		line-height: 28px;
	}
	&.heading-2 {
		font-weight: bold;
		font-size: 17px;
		line-height: 24px;
	}
	&.heading-3 {
		font-weight: bold;
		font-size: 12px;
		line-height: 24px;
		text-transform: uppercase;
	}
	line-height: 22px;
`

const Holder = styled.div`
	display: contents;
`

const TextStyleButtons = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 5px;

	button div {
		font-family: Georgia, 'Times New Roman', Times, serif !important;
		text-align: center !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	button div.bold {
		font-weight: bold;
	}
	button div.italic {
		font-style: italic;
	}
`
