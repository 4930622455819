import { motion } from 'framer-motion';
import styled from 'styled-components';

import { useScrollProgress } from '@root/hooks/useScrollProgress';

export const SmartBorder: React.FC = () => {
	const { progress, setRef } = useScrollProgress(0, 20)
	return <Border ref={setRef} style={{ opacity: progress }} />
}

const Border = styled(motion.div)`
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.075);
	${(p) => p.theme.border.subtle.t1.css('background-color')};
`
