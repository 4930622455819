import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { IconName } from '@comps/static/activityImage'
import { ActivityColorKey } from './activities.colors.types'
import { NewPacketDelta } from './persist.utils'
import { ActivityPacketObject } from './sync.tsx.packets.types'
import { LocalData } from './sync.types'

export type ActivityData = Omit<LocalData<ActivityPacketObject>, 'icon'> & {
	icon: IconName
	color: ActivityColorKey
}
export type ActivityDataSeed = NewPacketDelta<PacketType.ACTIVITY>


export type ActivityChangeable = Omit<LocalData<ActivityPacketObject>, 'icon'> & {
	icon: IconName
}
interface ActivitiesState {
	[id: string]: ActivityData
}

export const activitiesState: ActivitiesState = {}

export type ActivityColorState = 'inactiveLight' | 'inactiveDark' | 'active'

export const ActivityImageSource = Object.freeze({
	coffee: {
		keywords: ['coffee', 'cup', 'morning', 'energy'],
	},
	cocktail: {
		keywords: ['party', 'drink', 'fun'],
	},
	clipper: {
		keywords: ['haircut', 'hair', 'barber'],
	},
	music: {
		keywords: ['music', 'listen', 'audio', 'song'],
	},
	macbook: {
		keywords: ['work', 'computer', 'digital'],
	},
	message: {
		keywords: ['chat', 'talk', 'write', 'sms'],
	},
	coffeeToGo: {
		keywords: ['coffee', 'energy', 'drink'],
	},
	doggo: {
		keywords: ['dog', 'walk', 'puppy'],
	},
	earth: {
		keywords: ['globe', 'sphere', 'world'],
	},
	spa: {
		keywords: ['relax', 'massage', 'beauty'],
	},
	dice: {
		keywords: ['dice', 'game', 'luck'],
	},
	videocall: {
		keywords: ['video', 'call', 'chat'],
	},
	fire: {
		keywords: ['burn', 'energy', 'workout'],
	},
	groceries: {
		keywords: ['food', 'shop', 'ingredients'],
	},
	ipad: {
		keywords: ['tablet', 'study', 'sketch'],
	},
	heart: {
		keywords: ['love', 'chill', 'together'],
	},
	lipstick: {
		keywords: ['lipstick', 'gloss', 'eyeliner'],
	},
	iphone: {
		keywords: ['phone', 'call', 'emails'],
	},
	meditate: {
		keywords: ['relax', 'contemplate', 'think'],
	},
	moon: {
		keywords: ['night', 'sleep', 'dream'],
	},
	alarm: {
		keywords: ['wakeup', 'clock', 'raise-up'],
	},
	barbell: {
		keywords: ['gym', 'workout', 'fitness'],
	},
	// bath: {
	// 	color: '#9D18DC',
	// 	keywords: ['chill', 'relax', 'bubbles'],
	// },
	party: {
		keywords: ['party', 'fun', 'celebrate'],
	},
	// pasta: {
	// 	color: '#AD4747',
	// 	keywords: ['food', 'eat', 'dinner'],
	// },
	phone: {
		keywords: ['call', 'phone', 'voice'],
	},
	cards: {
		keywords: ['games', 'cards', 'social'],
	},
	drive: {
		keywords: ['trip', 'car', 'commute'],
	},
	cleaning: {
		keywords: ['house', 'chores', 'vacuum'],
	},
	plane: {
		keywords: ['air', 'fly', 'travel'],
	},
	popcorn: {
		keywords: ['movies', 'theatre', 'snack'],
	},
	shower: {
		keywords: ['routine', 'habbit', 'clean'],
	},
	relax: {
		keywords: ['chill', 'massage', 'meditate'],
	},
	suitcase: {
		keywords: ['work', 'travel', 'baggage'],
	},
	run: {
		keywords: ['jogg', 'workout', 'hiit'],
	},
	salad: {
		keywords: ['food', 'eat', 'healthy'],
	},
	sun: {
		keywords: ['sun', 'wakeup', 'morning'],
	},
	walk: {
		keywords: ['hike', 'healthy', 'chill'],
	},
	sleep: {
		keywords: ['sleep', 'night', 'dream'],
	},
})
export type ActivityBubbleSize = 'small' | 'medium' | 'large' | 'tiny'

export type ActivityID = string