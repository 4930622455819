// source: packets_v1/routine.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var entities_access_grant_v1_pb = require('../entities/access_grant_v1_pb.js');
goog.object.extend(proto, entities_access_grant_v1_pb);
var commons_core_pb = require('../commons/core_pb.js');
goog.object.extend(proto, commons_core_pb);
goog.exportSymbol('proto.packets_v1.RoutinePacket', null, global);
goog.exportSymbol('proto.packets_v1.RoutinePacketData', null, global);
goog.exportSymbol('proto.packets_v1.RoutinePacketMeta', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.RoutinePacketData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.RoutinePacketData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.RoutinePacketData.displayName = 'proto.packets_v1.RoutinePacketData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.RoutinePacketMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.RoutinePacketMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.RoutinePacketMeta.displayName = 'proto.packets_v1.RoutinePacketMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.RoutinePacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.RoutinePacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.RoutinePacket.displayName = 'proto.packets_v1.RoutinePacket';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.RoutinePacketData.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.RoutinePacketData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.RoutinePacketData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutinePacketData.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.RoutinePacketData}
 */
proto.packets_v1.RoutinePacketData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.RoutinePacketData;
  return proto.packets_v1.RoutinePacketData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.RoutinePacketData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.RoutinePacketData}
 */
proto.packets_v1.RoutinePacketData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.RoutinePacketData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.RoutinePacketData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.RoutinePacketData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutinePacketData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.packets_v1.RoutinePacketData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.RoutinePacketData} returns this
 */
proto.packets_v1.RoutinePacketData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string activity_id = 2;
 * @return {string}
 */
proto.packets_v1.RoutinePacketData.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.RoutinePacketData} returns this
 */
proto.packets_v1.RoutinePacketData.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.packets_v1.RoutinePacketData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.RoutinePacketData} returns this
 */
proto.packets_v1.RoutinePacketData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.RoutinePacketMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.RoutinePacketMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.RoutinePacketMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutinePacketMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && commons_core_pb.Meta.toObject(includeInstance, f),
    activityId: (f = msg.getActivityId()) && commons_core_pb.Meta.toObject(includeInstance, f),
    schedules: (f = msg.getSchedules()) && commons_core_pb.Meta.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && commons_core_pb.Meta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.RoutinePacketMeta}
 */
proto.packets_v1.RoutinePacketMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.RoutinePacketMeta;
  return proto.packets_v1.RoutinePacketMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.RoutinePacketMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.RoutinePacketMeta}
 */
proto.packets_v1.RoutinePacketMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setActivityId(value);
      break;
    case 3:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setSchedules(value);
      break;
    case 4:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.RoutinePacketMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.RoutinePacketMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.RoutinePacketMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutinePacketMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getActivityId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getSchedules();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
};


/**
 * optional commons.Meta name = 1;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.RoutinePacketMeta.prototype.getName = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 1));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.RoutinePacketMeta} returns this
*/
proto.packets_v1.RoutinePacketMeta.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutinePacketMeta} returns this
 */
proto.packets_v1.RoutinePacketMeta.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutinePacketMeta.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional commons.Meta activity_id = 2;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.RoutinePacketMeta.prototype.getActivityId = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 2));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.RoutinePacketMeta} returns this
*/
proto.packets_v1.RoutinePacketMeta.prototype.setActivityId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutinePacketMeta} returns this
 */
proto.packets_v1.RoutinePacketMeta.prototype.clearActivityId = function() {
  return this.setActivityId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutinePacketMeta.prototype.hasActivityId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional commons.Meta schedules = 3;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.RoutinePacketMeta.prototype.getSchedules = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 3));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.RoutinePacketMeta} returns this
*/
proto.packets_v1.RoutinePacketMeta.prototype.setSchedules = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutinePacketMeta} returns this
 */
proto.packets_v1.RoutinePacketMeta.prototype.clearSchedules = function() {
  return this.setSchedules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutinePacketMeta.prototype.hasSchedules = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional commons.Meta description = 4;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.RoutinePacketMeta.prototype.getDescription = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 4));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.RoutinePacketMeta} returns this
*/
proto.packets_v1.RoutinePacketMeta.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutinePacketMeta} returns this
 */
proto.packets_v1.RoutinePacketMeta.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutinePacketMeta.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.RoutinePacket.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.RoutinePacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.RoutinePacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutinePacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deleted: (f = msg.getDeleted()) && commons_core_pb.DeleteMeta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.packets_v1.RoutinePacketData.toObject(includeInstance, f),
    meta: (f = msg.getMeta()) && proto.packets_v1.RoutinePacketMeta.toObject(includeInstance, f),
    lastModifiedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cloudedAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.RoutinePacket}
 */
proto.packets_v1.RoutinePacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.RoutinePacket;
  return proto.packets_v1.RoutinePacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.RoutinePacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.RoutinePacket}
 */
proto.packets_v1.RoutinePacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.commons.PacketType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new commons_core_pb.DeleteMeta;
      reader.readMessage(value,commons_core_pb.DeleteMeta.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 4:
      var value = new proto.packets_v1.RoutinePacketData;
      reader.readMessage(value,proto.packets_v1.RoutinePacketData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = new proto.packets_v1.RoutinePacketMeta;
      reader.readMessage(value,proto.packets_v1.RoutinePacketMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModifiedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloudedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.RoutinePacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.RoutinePacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.RoutinePacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.RoutinePacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.DeleteMeta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.packets_v1.RoutinePacketData.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.packets_v1.RoutinePacketMeta.serializeBinaryToWriter
    );
  }
  f = message.getLastModifiedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCloudedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.packets_v1.RoutinePacket.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.RoutinePacket} returns this
 */
proto.packets_v1.RoutinePacket.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional commons.PacketType type = 2;
 * @return {!proto.commons.PacketType}
 */
proto.packets_v1.RoutinePacket.prototype.getType = function() {
  return /** @type {!proto.commons.PacketType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commons.PacketType} value
 * @return {!proto.packets_v1.RoutinePacket} returns this
 */
proto.packets_v1.RoutinePacket.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional commons.DeleteMeta deleted = 3;
 * @return {?proto.commons.DeleteMeta}
 */
proto.packets_v1.RoutinePacket.prototype.getDeleted = function() {
  return /** @type{?proto.commons.DeleteMeta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.DeleteMeta, 3));
};


/**
 * @param {?proto.commons.DeleteMeta|undefined} value
 * @return {!proto.packets_v1.RoutinePacket} returns this
*/
proto.packets_v1.RoutinePacket.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutinePacket} returns this
 */
proto.packets_v1.RoutinePacket.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutinePacket.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RoutinePacketData data = 4;
 * @return {?proto.packets_v1.RoutinePacketData}
 */
proto.packets_v1.RoutinePacket.prototype.getData = function() {
  return /** @type{?proto.packets_v1.RoutinePacketData} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.RoutinePacketData, 4));
};


/**
 * @param {?proto.packets_v1.RoutinePacketData|undefined} value
 * @return {!proto.packets_v1.RoutinePacket} returns this
*/
proto.packets_v1.RoutinePacket.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutinePacket} returns this
 */
proto.packets_v1.RoutinePacket.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutinePacket.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RoutinePacketMeta meta = 5;
 * @return {?proto.packets_v1.RoutinePacketMeta}
 */
proto.packets_v1.RoutinePacket.prototype.getMeta = function() {
  return /** @type{?proto.packets_v1.RoutinePacketMeta} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.RoutinePacketMeta, 5));
};


/**
 * @param {?proto.packets_v1.RoutinePacketMeta|undefined} value
 * @return {!proto.packets_v1.RoutinePacket} returns this
*/
proto.packets_v1.RoutinePacket.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.RoutinePacket} returns this
 */
proto.packets_v1.RoutinePacket.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.RoutinePacket.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 last_modified_at = 6;
 * @return {number}
 */
proto.packets_v1.RoutinePacket.prototype.getLastModifiedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutinePacket} returns this
 */
proto.packets_v1.RoutinePacket.prototype.setLastModifiedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.packets_v1.RoutinePacket.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutinePacket} returns this
 */
proto.packets_v1.RoutinePacket.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 clouded_at = 8;
 * @return {number}
 */
proto.packets_v1.RoutinePacket.prototype.getCloudedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.RoutinePacket} returns this
 */
proto.packets_v1.RoutinePacket.prototype.setCloudedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


goog.object.extend(exports, proto.packets_v1);
