import { WatchRequest, WatchResponse } from '@clepside/clepsidejs/lib/services/watch_v1_pb';
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Logger } from '@root/cores/logger';
import { WatchGPRC } from '@root/grpc/grpcWatch';
import { EventChannel } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';

import { mergePacketsToLocalTransaction } from './persist.transactionMergeLocal';
import { mergeRunesToLocalTransaction } from './runes.transactionMergeLocal';
import { AllSyncables } from './sync.types';
import { State } from './watch.types';
import { select } from 'typed-redux-saga';
import { RootState } from '..';

const watchSlice = createSlice({
	name: 'watch',
	initialState: State,
	reducers: {
		startWatching: (state, { payload }: PayloadAction) => {
            state.isWatching = true
        },
        restartStream: (state, { payload }: PayloadAction) => {},
	},
})

let channel: EventChannel<WatchResponse> | null = null

export const watchSagas = {
	*startWatching({ payload }: ReturnType<typeof watchSlice.actions.startWatching>): any {
        const request = new WatchRequest()

        const clientId = yield select((state: RootState) => state.auth.clientId)
        request.setClientId(clientId)
        
        if (channel != null) {
            Logger.watch.debug('Tried to re-watch, but already watching.')
            return
        }

        try {
            channel = yield WatchGPRC.constructStreamChannel(request)
            Logger.watch.info('Starting to await packets.')
            if (channel != null) {
                yield takeEvery(channel, receiveLiveSendable)
            }
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        }
	},
    *restartStream({ payload }: ReturnType<typeof watchSlice.actions.restartStream>): any {
        if (channel == null) {
            Logger.watch.info('Tried to restart, but channel is null.')
            return
        }
        channel.close()
        channel = null
        yield put(watchActions.startWatching())
    }
}

export function* ReceiveSendable(sendable?: Sendable.AsObject): any {
    if (!sendable) return
    for (const config of AllSyncables) {
        if (config.runeOrPacket == 'packet') {
            const packet = config.fromSendables([sendable])?.[0]
            if (!packet) continue
            const packetObjects = yield mergePacketsToLocalTransaction([packet], config, undefined, true)
            Logger.watch.debug('Received Sendable packet: ', packetObjects[0])
            yield put(config.placeInStore(packetObjects))
        } else if (config.runeOrPacket == 'rune') {
            const rune = config.fromSendables([sendable])?.[0]
            if (!rune) continue
            Logger.watch.debug('Received Sendable rune: ', rune)
            const runeObjects = yield mergeRunesToLocalTransaction([rune], config)
            yield put(config.placeInStore(runeObjects))
        }
    }
}

function* receiveLiveSendable(response: WatchResponse | 'END' | 'INTERRUPTED'): any {
    try {
        if (response == 'END') {
            return
        }
        if (response == 'INTERRUPTED') {
            yield put(watchActions.restartStream())
            return
        }
        const responseObject = response.toObject()
        const { streamTimedOut, streamFinished } = responseObject

        if (streamTimedOut) {
            Logger.watch.debug('Channel timed out.')
            yield put(watchActions.restartStream())
            return
        }

        if (streamFinished) {
            Logger.watch.debug('Stream finished gracefully.')
            return 
        }

        yield ReceiveSendable(responseObject.sendable)
    } catch (e) {
        console.error('Failed to receive sendable: ', e)
    }
}

export const watchActions = watchSlice.actions
export const watchReducers = watchSlice.reducer
