import { TextInput } from '@comps/forms/textInput';
import { TextInputIconWrapper } from '@comps/forms/textInputIconWrapper';
import { Flex } from '@comps/layout/flex';
import { List } from '@comps/layout/list';
import { ListHolder } from '@comps/layout/listHolder';
import { Spacer } from '@comps/layout/space';
import { KeyScope } from '@comps/logical/keyScope';
import { ActivityImage, IconName } from '@comps/static/activityImage';
import { Text } from '@comps/typography/text';
import { useRefTaker } from '@hooks/useRefTaker';
import { flowsActions } from '@root/store/slices/flows';
import { capitalizeFirstLetter } from '@root/utils/text';
import { ActivityImageSource } from '@store/slices/activitiesTypes';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

export const FlowsActivitySelectIcon: React.FC = () => {
	const [fieldRef, setFieldRef] = useRefTaker()
	const [filterValue, setFilterValue] = useState('')
	const dispatch = useDispatch()

	const icons = useMemo(() => {
		return Object.keys(ActivityImageSource) as Array<keyof typeof ActivityImageSource>
	}, [])

	const memoedListDictionary = useMemo(() => {
		return icons.reduce((acc, key) => {
			acc[key] = (
				<Flex align="center" grow>
					<NegativeY>
						<ActivityImage noOutset name={key} size="medium" />
					</NegativeY>

					<Spacer size={15} />
					<Text>{capitalizeFirstLetter(key)}</Text>
				</Flex>
			)
			return acc
		}, {} as any)
	}, [icons])

	const filteredList = useMemo(() => {
		return icons.filter((iconKey) => {
			let shouldBePresent = false
			ActivityImageSource[iconKey].keywords.forEach((keyword) => {
				if (keyword.includes(filterValue)) shouldBePresent = true
			})
			return shouldBePresent
		})
	}, [icons, filterValue])

	const handleSelection = useCallback(
		(item: IconName) => {
			dispatch(
				flowsActions.updateFlow({
					flowType: 'create-or-edit-activity',
					navigationStack: ['overview'],
					icon: item,
				})
			)
			// onIconSelected(item)
			// popView?.()
		},
		[dispatch]
	)

	const renderItem = useCallback(
		(item: string) => {
			return memoedListDictionary[item]
		},
		[memoedListDictionary]
	)

	return (
		<React.Fragment>
			<KeyScope scope="Activities.NewActivity.SelectIcon" />
			<TextInputIconWrapper icon="search">
				<TextInput
					inputStyle="block"
					inputRef={setFieldRef}
					autoFocus
					placeholder="Filter through icons..."
					hasIcon
					onValueChange={(value) => setFilterValue(value)}
				/>
			</TextInputIconWrapper>
			<ListHolder>
				<List
					scope="Activities.NewActivity.SelectIcon"
					items={filteredList}
					renderItem={renderItem}
					borderOffsetLeft={56}
					borderOffsetRight={10}
					value={filterValue}
					onSelect={handleSelection as any}
					padding="medium"
				/>
			</ListHolder>
		</React.Fragment>
	)
}

const NegativeY = styled.div`
	margin: -3px 0;
	margin-right: -6px;
`
