import { RuneType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { GPTCardChatMessageRune } from '@clepside/clepsidejs/lib/runes_v1/gpt_card_chat_pb';
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb';
import { Database } from '@root/database';

import { aiChatCardActions } from './aiChatCard';
import { CardChatMessageRuneObject, RuneObject } from './runes.types';
import { getRuneObjectFromRemoteObject } from './sync.types';

export class SyncAICardChatsMessages {
    public static runeOrPacket = 'rune' as const

    public static type = RuneType.GPT_CARD_MESSAGE as const

    static fromSendables(res: Sendable.AsObject[] | undefined): RuneObject<any>[] {
        return res?.map((r) => getRuneObjectFromRemoteObject(r?.rune?.cardChatMessage))
            .filter((f): f is RuneObject<any> => f !== undefined) || []
    }

    static placeInStore(objects: CardChatMessageRuneObject[], fromSync?: boolean) {
        return aiChatCardActions.storeMessages({ objects, fromSync })
    }
    
    static fromRune(res: GPTCardChatMessageRune.AsObject | undefined) {
        return getRuneObjectFromRemoteObject(res)
    }

    static database = Database.aiCardChatsMessagesRunes
}
