import { Spacer } from '@comps/layout/space'
import { KeyScope } from '@comps/logical/keyScope'
import { Text } from '@comps/typography/text'
import { useTime } from '@root/hooks/useTime'
import { capitalizeFirstLetter } from '@root/utils/text'
import { format } from 'date-fns'
import { useMemo } from 'react'
import styled from 'styled-components'
import { TodayActiveNow } from './today/todayActiveNow'
import { TodayHasSessionsLeftCard } from './today/todayHasSessionsLeft'
import { TodayNoSessionsLeftCard } from './today/todayNoSessionsLeft'
import { TodayPlanWeek } from './today/todayPlanWeek'

export const Today: React.FC = () => {
	const now = useTime('date')

	const cards = useMemo(() => {
		return [
			<TodayActiveNow key="today-active-now" />,
			<TodayHasSessionsLeftCard key="today-has-sessions-left-card" />,
			<TodayNoSessionsLeftCard key="today-no-sessions-left-card" />,
			<TodayPlanWeek key="today-plan-week" />,
		]
	}, [])

	return (
		<Scroller className="scroller">
			<KeyScope scope="Today" />
			<Holder>
				<Text color="white" level="largerParagraphTitle">
					{format(now, 'dd MMMM, yyyy')}
				</Text>
				<Spacer size={20} vertical />
				<Text color="white" level="title80">
					It's {capitalizeFirstLetter(format(now, 'EEEE'))}
				</Text>
				<Spacer size={60} vertical />
				<CardHolder numberOfCards={cards.length || 0}>{cards}</CardHolder>
			</Holder>
		</Scroller>
	)
}

const Scroller = styled.div`
	position: relative;
	width: 100%;
	overflow: auto;
	height: 100%;
	-webkit-overflow-scrolling: touch;
`

const CardHolder = styled.div<{ numberOfCards: number }>`
	display: grid;
	grid-template-rows: repeat(400px, ${(p) => p.numberOfCards});
	grid-row-gap: 20px;
`

const Holder = styled.div`
	width: 100%;
	margin: 80px auto;
	max-width: 480px;
	margin-left: 150px;
	margin-top: 80px;
	padding-top: 45vh;
	display: flex;
	flex-direction: column;
`
