import { ActivityBackground } from '@comps/activityBackground';
import dog from '@res/dog.jpg';
import { useActivityOrSession } from '@root/hooks/useActivityOrSession';
import { useActivityStreaming } from '@root/hooks/useActivityStreaming';
import { usePagePresence } from '@root/hooks/usePagePresence';
import { useActivityForSession } from '@root/store/selectors/useActivityForSession';
import { useSessionWithID } from '@root/store/selectors/useSessionWithID';
import { useUser } from '@root/store/selectors/useUser';
import { activitiesActions } from '@root/store/slices/activities';
import { syncSessionsActions } from '@root/store/slices/sync.sessions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useParams } from 'react-router';
import styled, { css } from 'styled-components';

import { TopBar } from './Sessions.ID.TopBar';
import { SessionOutletContextType } from './Sessions.ID.useSessionOutletContext';

export const Session: React.FC = () => {
	const { id } = useParams()
	const session = useSessionWithID(id)
	const activity = useActivityForSession(session)
	const { show } = usePagePresence()
	const dispatch = useDispatch()
	const user = useUser()
	const place = useActivityOrSession()
	useActivityStreaming(activity?.id)

	useEffect(() => {
		if (!user?.uid) {
			if (user?.isFromLocal) {
				return
			}
		}

		if (id && user?.isFromLocal === false) {
			dispatch(syncSessionsActions.streamSession({ sessionId: id }))
		}
	}, [dispatch, id, user])

	useEffect(() => {
		if (!activity) {
			return
		}
		dispatch(activitiesActions.loadToStore({ resourceId: activity.id }))
	}, [dispatch, activity])

	useEffect(() => {
		if (!id) {
			return
		}
		dispatch(activitiesActions.loadToStore({ resourceId: id }))
	}, [dispatch, id])

	return (
		<Holder present={show}>
			{session && activity && <TopBar session={session} activity={activity} />}
			<ActivityBackground activityId={activity?.id} blurred={place == 'activity'} />
			<Scroller className="grid-behaviour-container card-grid-scroll">
				<GridContainer>
					<Outlet context={{ activity, session } satisfies SessionOutletContextType} />
				</GridContainer>
			</Scroller>
		</Holder>
	)
}

const Scroller = styled.div`
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: auto;
`

const GridContainer = styled.div`
	padding: 60px;
	width: 100%;
	min-width: 960px;
	margin-bottom: 100px;
	box-sizing: border-box;
	margin: 0 auto;
`

const Holder = styled.div<{ present?: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	z-index: 10000000;
	/* background-size: cover; */
	/* background: url(${dog}); */
	/* background-position: center center; */
	background-color: black;
	-webkit-overflow-scrolling: touch;
	display: flex;
	flex-direction: column;
	/* transform: translateY(30px); */
	opacity: 0;
	backface-visibility: hidden;
	transform-style: flat;
	will-change: transform;
	/* transition: ease transform 2s, ease opacity 2s; */

	transform: translateY(-50px);
	opacity: 0;
	transition: ease transform 0.29s, ease opacity 0.29s;

	${(p) =>
		p.present &&
		css`
			transform: translateY(0);
			opacity: 1;
		`}
`

{
	/* <Button
									onClick={() => {
										navigate(-1)
									}}
									color="darkTranslucentElevated"
									round={19}
									touchOffset={30}
								>
									<Inset padding="buttonSmall" align="center">
										<IconFlip>
											<Icon type="arrow" size={5} offsetY={-2} offsetX={1} />
										</IconFlip>
									</Inset>
								</Button> */
}
