import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb';
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb';
import { Database } from '@root/database';

import { fragmentsActions } from './fragments';
import {
    FragmentCompletablePacketObject, FragmentWithComputedLocalProps, PacketObject
} from './sync.tsx.packets.types';
import { getPacketObjectFromRemoteObject } from './sync.types';

export class SyncFragmentsCompletable {
    public static runeOrPacket = 'packet' as const

    public static type = PacketType.FRAGMENT_COMPLETABLE as const

    static fromSendables(res: Sendable.AsObject[] | undefined): PacketObject<any>[] {
        return res?.map((r) => getPacketObjectFromRemoteObject(r?.packet?.fragmentCompletable))
            .filter((f): f is PacketObject<any> => f !== undefined) || []
    }

    static placeInStore(objects: FragmentCompletablePacketObject[], fromSync?: boolean) {
        return fragmentsActions.normalizeAndStore({ objects: objects as FragmentWithComputedLocalProps<FragmentCompletablePacketObject>[], fromSync })
    }

    static prepareForStorage(object: FragmentCompletablePacketObject): FragmentWithComputedLocalProps<FragmentCompletablePacketObject> {
        try {
            let lastUpdatedAt = object.lastModifiedAt
            for (const key of Object.keys(object.meta)) {
                const at = (object.meta as any)?.[key]?.at
                if (at && at > lastUpdatedAt) {
                    lastUpdatedAt = at
                }
            }
            return {
                ...object,
                clientOnlyLastUpdateAt: lastUpdatedAt
            }
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    static fromPacket(res: Packet.AsObject | undefined) {
        return getPacketObjectFromRemoteObject(res?.fragmentCompletable)
    }
    
    static database = Database.fragmentPacketsCompletable
}