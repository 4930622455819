export type ImageStateStatusType = 'fetching' | 'fetched'
export type ImageStateStatus = {
    id: string,
    status: ImageStateStatusType
}
export type ImageID = string

export const imagesState: {
	loadingState: {
        [key: ImageID]: ImageStateStatus
    },
	visibleImages: ImageID[],
} = {
	loadingState: {},
    visibleImages: []
}
