import { DragDropCore, DraggingPhase } from '@root/cores/dragDropCore';
import { DraggingContext } from '@root/store/slices/interface.types';
import { sessionsActions } from '@root/store/slices/sessions';
import { InstancedSessionData } from '@root/store/slices/sessionsTypes';
import { DateInterval, intervalFitsInside } from '@root/utils/dateIntervals';
import { roundToNearestMinuteInUTCs } from '@root/utils/dates';
import { addMinutes, subMinutes } from 'date-fns';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SessionPlacerDimensions } from './sessionPlacer.types';
import { getCursorDateFromCoords } from './sessionPlacer.utils';

export const useResizingSession = (
	container: HTMLElement,
	vector: number[],
	shownDatesIndex: number[],
	dimensions: SessionPlacerDimensions,
	dragZoneId: string,
	freeIntervals: MutableRefObject<Array<[Date, Date]>>,
	selectedSession?: InstancedSessionData
) => {
	const dispatch = useDispatch()
	const [session, setSession] = useState<
		| undefined
		| {
			id: string
			start: Date
			end: Date
			isACopy?: boolean
		}
	>(undefined)
	const intervals = useRef<Array<DateInterval>>([])
	const cachedFreeIntervals = useRef<Array<DateInterval>>([])

	const calculateDateFromCoords = useCallback(
		(e: any): Date | undefined => {
			return getCursorDateFromCoords(container, e.clientX, e.clientY - container.getBoundingClientRect().top, shownDatesIndex, vector, dimensions)
		},
		[container, dimensions, shownDatesIndex, vector]
	)

	const doesIntervalFitInside = useCallback((start: Date, end: Date): boolean => {
		return intervals.current.some((interval) => intervalFitsInside([start, end], interval))
	}, [])

	// const moveSessionIfEndPhase = useCallback(
	// 	(phase: DraggingPhase, start: Date, end: Date, id: string, shouldCopy?: boolean) => {
	// 		if (phase === 'finished') {
	// 			dispatch(
	// 				sessionsActions.move({
	// 					id,
	// 					to: { start: start.getTime(), end: end.getTime() },
	// 				})
	// 			)
	// 			setTimeout(() => {
	// 				setSession(undefined)
	// 			}, 32)
	// 		} else {
	// 			setSession({
	// 				id,
	// 				isACopy: shouldCopy,
	// 				start: start,
	// 				end: end,
	// 			})
	// 		}
	// 	},
	// 	[dispatch]
	// )
	const resultInterval = useRef<DateInterval | null>(null)

	const findIntervalForDate = useCallback(
		(inputDate: Date, start: Date, end: Date, direction?: 'before' | 'after', boundaryDate?: Date) => {
			const init: DateInterval = [start, end]

			// Create a copy of the freeIntervals and merge with the [start, end] interval
			const mergedIntervals = [...freeIntervals.current, init]
			mergedIntervals.sort((a, b) => (a[0].getTime() < b[0].getTime() ? -1 : 1))

			const optimizedIntervals: DateInterval[] = []
			for (const interval of mergedIntervals) {
				if (!optimizedIntervals.length || optimizedIntervals[optimizedIntervals.length - 1][1].getTime() < interval[0].getTime()) {
					optimizedIntervals.push(interval)
				} else {
					// Merge overlapping intervals
					optimizedIntervals[optimizedIntervals.length - 1][1] = new Date(
						Math.max(optimizedIntervals[optimizedIntervals.length - 1][1].getTime(), interval[1].getTime())
					)
				}
			}

			// Search for the interval that contains inputDate
			for (const interval of optimizedIntervals) {
				if (interval[0] <= inputDate && interval[1] >= inputDate) {
					// If boundaryDate is provided, modify the interval based on direction
					if (boundaryDate && direction === 'before' && boundaryDate > interval[0]) {
						resultInterval.current = [interval[0], boundaryDate]
					} else if (boundaryDate && direction === 'after' && boundaryDate < interval[1]) {
						resultInterval.current = [boundaryDate, interval[1]]
					} else {
						resultInterval.current = interval
					}
					break
				}
			}
		},
		[freeIntervals]
	)
	// const findIntervalForDate = useCallback(
	// 	(inputDate: Date, start: Date, end: Date, direction?: 'before' | 'after', boundaryDate?: Date) => {
	// 		const init: DateInterval = [start, end]

	// 		// Since the freeIntervals are already sorted, merge with the init interval without needing to sort
	// 		const mergedIntervals = [...freeIntervals.current]

	// 		// Insert the new interval at the appropriate position to maintain sorted order
	// 		let inserted = false
	// 		for (let i = 0; i < mergedIntervals.length; i++) {
	// 			if (mergedIntervals[i][0] > init[0]) {
	// 				mergedIntervals.splice(i, 0, init)
	// 				inserted = true
	// 				break
	// 			}
	// 		}
	// 		if (!inserted) {
	// 			mergedIntervals.push(init)
	// 		}

	// 		const optimizedIntervals: DateInterval[] = []
	// 		for (const interval of mergedIntervals) {
	// 			const lastInterval = optimizedIntervals[optimizedIntervals.length - 1]
	// 			if (!lastInterval || lastInterval[1].getTime() < interval[0].getTime()) {
	// 				optimizedIntervals.push(interval)
	// 			} else {
	// 				// Merge overlapping intervals
	// 				lastInterval[1] = new Date(Math.max(lastInterval[1].getTime(), interval[1].getTime()))
	// 			}
	// 		}

	// 		// Search for the interval that contains inputDate
	// 		for (const interval of optimizedIntervals) {
	// 			if (interval[0] <= inputDate && interval[1] >= inputDate) {
	// 				if (boundaryDate && direction === 'before' && boundaryDate > interval[0]) {
	// 					resultInterval.current = [interval[0], boundaryDate]
	// 				} else if (boundaryDate && direction === 'after' && boundaryDate < interval[1]) {
	// 					resultInterval.current = [boundaryDate, interval[1]]
	// 				} else {
	// 					resultInterval.current = interval
	// 				}
	// 				break
	// 			}
	// 		}
	// 	},
	// 	[freeIntervals]
	// )

	const getBoundedDate = useCallback(
		(date: Date) => {
			if (resultInterval.current) {
				if (date < resultInterval.current[0]) {
					return resultInterval.current[0]
				} else if (date > resultInterval.current[1]) {
					return resultInterval.current[1]
				}
			}

			return date
		},
		[resultInterval]
	)

	useEffect(() => {
		if (!selectedSession) return
		if (!container) return

		const listener = (phase: DraggingPhase, context: DraggingContext, e: any) => {
			if (context.type !== 'session-resize') return

			const { sessionId, direction } = context

			if (phase === 'interrupted') {
				setSession(undefined)
				return
			}

			const parsedDate = calculateDateFromCoords(e)
			if (!parsedDate) {
				setSession(undefined)
				return
			}

			const date = roundToNearestMinuteInUTCs(parsedDate, 5)

			if (phase == 'start') {
				findIntervalForDate(
					date,
					new Date(context.start),
					new Date(context.end),
					context.direction == 'bottom' ? 'after' : 'before',
					context.direction == 'bottom' ? addMinutes(new Date(context.start), 10) : subMinutes(new Date(context.end), 10)
				)
				return
			}

			const boundedDate = getBoundedDate(date)

			if (phase == 'finished') {
				if (phase === 'finished') {
					dispatch(
						sessionsActions.update({
							id: sessionId,
							...(context.direction == 'bottom' ? { end: boundedDate.getTime() } : { start: boundedDate.getTime() }),
						})
					)
					setTimeout(() => {
						setSession(undefined)
					}, 100)
				}
				return
			}

			if (!date) return
			if (context.direction == 'bottom') {
				setSession({
					id: sessionId,
					start: new Date(context.start),
					end: boundedDate,
				})
			} else {
				setSession({
					id: sessionId,
					start: boundedDate,
					end: new Date(context.end),
				})
			}
		}

		const removeListener = DragDropCore.addListener(dragZoneId, listener)
		const unmount = DragDropCore.mountContainer(dragZoneId, 1, container)
		return () => {
			removeListener()
			unmount()
		}
	}, [
		container,
		findIntervalForDate,
		freeIntervals,
		getBoundedDate,
		selectedSession,
		dispatch,
		shownDatesIndex,
		dimensions,
		vector,
		dragZoneId,
		calculateDateFromCoords,

		doesIntervalFitInside,
	])

	return { resizedSession: session }
}
