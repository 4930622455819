import { Icon } from '@comps/static/icon';
import { ActivityColorKey, ActivityColors } from '@root/store/slices/activities.colors.types';
import styled, { css } from 'styled-components';

type BoardIconType = 'small' | 'normal'

export const BoardIcon: React.FC<{ boardName: string; boardColor: ActivityColorKey; size?: BoardIconType }> = ({
	boardName,
	boardColor,
	size = 'normal',
}) => {
	return (
		<Rectangle boardColor={boardColor} size={size}>
			<BannerIcon boardColor={boardColor} type="banner" size={size == 'small' ? 15 : 23} />
			{size == 'normal' ? <IconText boardColor={boardColor}>{boardName.substring(0, 2)}</IconText> : null}
		</Rectangle>
	)
}

const BannerIcon = styled(Icon).attrs((p: any) => ({ boardColor: p.boardColor } as any))`
	position: absolute !important;
	right: 2px;
	${(p) => (ActivityColors as any)[p.boardColor]?.active.background.r.css('color')};
`

const IconText = styled.span<{ boardColor: ActivityColorKey }>`
	position: absolute;
	display: block;
	color: black;
	font-weight: 500;
	font-size: 14px;
	bottom: 4px;
	left: 6px;
	${(p) => ActivityColors[p.boardColor]?.active.background.r.css('color')};
`
const Rectangle = styled.div<{ boardColor: ActivityColorKey; size: BoardIconType }>`
	width: 40px;
	height: 42px;
	border-radius: 12px;
	position: relative;
	${(p) => ActivityColors[p.boardColor]?.active.background.r.css('box-shadow', 'inset 0px 0px 0px 2px', ', 0px 4px 6px rgba(0, 0, 0, 0.05)')};
	/* ${(p) => ActivityColors[p.boardColor]?.inactiveLight.background.r.css('background-color')}; */
	background-color: #fff;
	overflow: hidden;

	${(p) =>
		p.size == 'small'
			? css`
					width: 19px;
					height: 19px;
					border-radius: 6px;
					background-color: transparent;
					${ActivityColors[p.boardColor]?.active.background.r.css('background-color')};
					box-shadow: inset 0px 0px 0px 1.5px #fff;

					${BannerIcon} {
						right: 0.5px;
						top: 1.5px;
						color: #fff;
					}
			  `
			: css`
					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						z-index: 10;
						height: 100%;
						display: block;
						opacity: 0.5;
						background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 50%);
					}
			  `}
`
