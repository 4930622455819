import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { useArrowWatcherAddCard } from '@root/hooks/useArrowAddCard';
import { useCardPaste } from '@root/hooks/useCardPaste';
import { useGridDimensions } from '@root/hooks/useGridDimensions';
import { useHotkey } from '@root/hooks/useHotkey';
import { useSelection } from '@root/hooks/useSelectable';
import { RootState } from '@root/store';
import { useCardsSelectionConfig } from '@root/store/selectors/useCardsSelectionConfig';
import { cardsActions } from '@root/store/slices/cards';
import { isElementInputable } from '@root/utils/general';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { LexicalLineNodeChaser } from './card.lexical.lineNodeChaser';
import { CardDroppablePlace } from './cardDroppablePlace';
import { GridCard } from './cardGrid.gridCard';
import { CardGridPlaceholder } from './cardGrid.placeholder';
import { useCardDatasource } from './cardGrid.useCardDatasource';
import { useCardMatrix } from './cardGrid.useCardMatrix';
import { CardPaster } from './cardPaster';

export const CardGrid: React.FC<{ resourceId: string; resourceType: GrantableResourceEnum; marginTop?: string }> = ({
	resourceId,
	resourceType,
	marginTop,
}) => {
	const { cards: cardsWithoutLayout } = useCardDatasource(resourceId)
	const { cards, setContainer, setGridRef, placeholder, planNewCard, dragZoneId, tallness } = useCardMatrix(
		cardsWithoutLayout,
		resourceId,
		resourceType
	)
	// const { context: draggingCardContext } = useDraggedCard()
	const dispatch = useDispatch()
	const { template } = useCardsSelectionConfig(resourceId, resourceType)
	const selection = useSelection(template)
	const { gridCellWidth } = useGridDimensions(dragZoneId, true)
	const draggingContext = useSelector((state: RootState) => state.interface.dragging)
	useCardPaste()

	const draggingLayout = useMemo(() => {
		if (draggingContext?.type == 'card-drag') {
			return draggingContext.initialLayout
		}
		return undefined
	}, [draggingContext])

	useArrowWatcherAddCard(
		resourceId,
		resourceType,
		useCallback(
			(ctx: { cardType: CardType }) => {
				const layout = planNewCard(ctx.cardType)
				if (!layout) throw new Error('Failed to find space for the new card')

				if (!resourceId) {
					throw new Error('Resource does not exist.')
				}

				dispatch(
					cardsActions.new({
						cardType: ctx.cardType,
						resourceId,
						resourceType,
						layout: layout.layout,
						verticallayout: {
							order: layout.verticalPosition
						}
					})
				)
			},
			[dispatch, resourceId, resourceType, planNewCard]
		)
	)

	const allCards = useMemo(() => {
		const array = []

		for (const [i, c] of cards.entries()) {
			array.push(
				<GridCard
					resourceId={resourceId}
					gridCellWidth={gridCellWidth}
					resourceType={resourceType}
					dragZoneId={dragZoneId}
					card={c}
					index={i}
					key={c.id}
				/>
			)
		}
		return array
	}, [cards, resourceId, resourceType, dragZoneId, gridCellWidth])

	const handleEsc = useCallback(() => {
		const activeElement = document.activeElement as any
		activeElement?.blur()
	}, [])

	const handleDeletion = useCallback(() => {
		const activeElement: any = document.activeElement
		if (isElementInputable(activeElement)) {
			return
		}

		if (!selection) return

		for (const cardId of selection.all) {
			dispatch(cardsActions.delete({ id: cardId.id }))
		}
	}, [selection, dispatch])

	const selectionConfig = useCardsSelectionConfig(resourceId, resourceType)
	useHotkey(selectionConfig.key, 'esc', handleEsc)
	useHotkey(selectionConfig.key, 'backspace', handleDeletion)

	return (
		// <GridHolder>
		<LexicalLineNodeChaser>
			<CardDroppablePlace resourceId={resourceId} resourceType={resourceType}/>
			<CardPaster resourceId={resourceId} resourceType={resourceType} planNewCard={planNewCard} />
			<Grid
				className={`board-grid drag-grid-${dragZoneId}`}
				ref={(ref) => {
					setGridRef(ref)
					const container = ref?.closest('.grid-behaviour-container') || ref
					setContainer(container)
				}}
				height={tallness * gridCellWidth}
				marginTop={marginTop}
			>
				{placeholder && (
					<CardGridPlaceholder
						beingMovedToANewBoard={(placeholder as any).movingToANewBoard}
						layout={placeholder.layout}
						gridCellWidth={gridCellWidth}
					/>
				)}
				{allCards}
				{/* <CardWrapper>
				<Text>This board looks empty. </Text>
				<Text>Add a context card to get started.</Text>
				<AddCardMultiButton
					resourceId={resourceId}
					resourceType={resourceType}
					planNewCard={planNewCard} />
			</CardWrapper> */}
			</Grid>
		</LexicalLineNodeChaser>
		// </GridHolder>
	)
}

export const GridStyles = css`
	width: calc(100%);
	min-width: 960px;
	max-width: 2048px;
`

const Grid = styled.div<{ marginTop?: string; height: number }>`
	display: block;
	position: relative;
	flex-grow: 1;
	flex-shrink: 0;
	z-index: 100;

	${GridStyles};

	${(p) =>
		p.marginTop &&
		css`
			margin-top: ${p.marginTop};
		`}
	${(p) =>
		p.height &&
		css`
			height: ${p.height}px;
		`} /* background-color: yellow; */
`

// useEffect(() => {
// 	if (!draggingCardContext || !draggingCardContext.cardId) return

// 	const listener: DragDropListener = (phase, context, e: any) => {
// 		if (context.type !== 'card-drag') return
// 		if (phase == 'finished') {
// 			if (context.resourceId !== resourceId || context.resourceType !== resourceType) {
// 				console.log('Moving card:', {
// 					id: context.cardId,
// 					resourceId,
// 					resourceType,
// 				})
// 				// dispatch(
// 				// 	cardsActions.move({
// 				// 		id: context.cardId,
// 				// 		resourceId,
// 				// 		resourceType,
// 				// 	})
// 				// )
// 			}
// 		}
// 	}

// 	const removeListener = DragDropCore.addListener(dragZoneId, listener)
// 	return () => {
// 		removeListener()
// 	}
// }, [dragZoneId, draggingCardContext, resourceId, dispatch, resourceType])
