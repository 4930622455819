import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { Position } from '@root/components/complex/cards/grid.utils';
import { DragDropCore } from '@root/cores/dragDropCore';
import { useCards } from '@root/store/selectors/useCards';
import { isNotLeftClick } from '@root/utils/general';
import { MouseEventHandler, useCallback } from 'react';

export const useGridCardBehaviours = (resourceId: string, resourceType: GrantableResourceEnum) => {
	const cards = useCards()
	// const drawRect = useCallback(
	// 	(rect: CardRectInPx, opacity: number) => {
	// 		try {
	// 		animate('.card', {
	// 			x: rect.xPx,
	// 			y: rect.yPx,
	// 			width: rect.wPx,
	// 			height: rect.hPx,
	// 			opacity,
	// 		}, {
	// 			duration: 0
	// 		})
	// 	} catch (e) {}
	// 	},
	// 	[animate]
	// )

	const startBehaviour = useCallback(
		(e: any, type: 'card-drag' | 'card-resize') => {
			if (isNotLeftClick(e)) return
			e.stopPropagation()

			let cardId = undefined
			const element: HTMLElement = e.target.closest('.board-card')
			if (element.getAttribute('data-id')) cardId = element.getAttribute('data-id')
			if (element.closest('[data-id]')) cardId = element.closest('[data-id]')?.getAttribute('data-id')
			const rect = element.getBoundingClientRect()
			const grid = e.target.closest('.board-grid')
			const gridRect = grid.getBoundingClientRect()

			const topLeftOffset: Position = { x: e.clientX - rect.left, y: e.clientY - rect.top }
			const bottomRightOffset: Position = { x: rect.width - (e.clientX - rect.left), y: rect.height - (e.clientY - rect.top) }

			const scrollOffsetContainer = document.querySelector('.card-grid-scroll')
			const scrollOffsetX = scrollOffsetContainer ? scrollOffsetContainer.scrollLeft || 0 : 0
			const scrollOffsetY = scrollOffsetContainer ? scrollOffsetContainer.scrollTop || 0 : 0

			if (!cardId) return

			DragDropCore.startMoving(e, {
				type,
				cardId: cardId,
				// element,
				gridOffset: { x: gridRect.x + scrollOffsetX, y: gridRect.y + scrollOffsetY },
				startCoords: {
					x: rect.left,
					y: rect.top,
				},
				initialLayout: cards.at[cardId].layout,
				resourceId,
				resourceType,
				bottomRightOffset,
				topLeftOffset,
				initalSize: { width: rect.width, height: rect.height },
				// drawRect: drawRect,
				// realeaseDrag: () => {},
			})
		},
		[resourceId, resourceType, cards]
	)

	const handleDragStart = useCallback<MouseEventHandler<HTMLDivElement>>(
		(e) => {
			startBehaviour(e, 'card-drag')
		},
		[startBehaviour]
	)

	const handleResizeStart = useCallback<MouseEventHandler<HTMLDivElement>>(
		(e) => {
			startBehaviour(e, 'card-resize')
		},
		[startBehaviour]
	)

	return {
		handleDragStart,
		handleResizeStart,
	}
}
