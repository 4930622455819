import { useMemo } from 'react';

import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb';;
import { GrantableResourceType } from '@root/store/slices/cards.types';
import { watcherActions } from '@root/store/slices/watchers';

import { useWatcherObserver } from './useWatcher';

const getWatcherId = (a: string, b: GrantableResourceType) => `${a}-${b}-add-card-action`

export function useArrowAddCard(resourceId: string, resourceType: GrantableResourceType) {
    const watcherId = getWatcherId(resourceId, resourceType)
    
    return useMemo(() => {
        if (!watcherId) {
            console.error('Watcher ID for Arrow is empty.')
            return {
                action: undefined
            }
        }

        return {
            action: (cardType: CardType) => watcherActions.done({
                watchers: [watcherId],
                context: { cardType }
            })
        } 
    }, [watcherId])
}

export function useArrowWatcherAddCard(resourceId: string, resourceType: GrantableResourceType, callback: (ctx: { cardType: CardType }) => void) {
    const watcherId = getWatcherId(resourceId, resourceType)
    useWatcherObserver(watcherId, callback)
}