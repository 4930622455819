import { DragDropCore, DragDropListener } from '@root/cores/dragDropCore';
import { cardsActions } from '@root/store/slices/cards';
import { DraggableZone } from '@root/store/slices/selection.types';
import { Point, Rectangle } from '@root/utils/point';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { v4 } from 'uuid';

import { CardDatasource, CardID } from './cardGrid.types';

type FinaliseAtPayload = { indicatorId: string, cardId: string, position: number }
export const useCardListCardDragging = (dragZoneId: DraggableZone, container: any, cards: CardDatasource[]) => {
	const coords = useRef<any>()
	const indicatorsRef = useRef<Array<{ id: string, rect: Rectangle, tba: number }>>([])
	const [indicators, setIndicators] = useState<Array<{ id: string, top: number, tba: number }>>([])
	const [hovered, setHovered] = useState<string | undefined>()
	const [isDragging, setIsDragging] = useState(false)
	const cardIdToPosition = useRef<{ [cardId: CardID]: number }>({})
	const finaliseAt = useRef<FinaliseAtPayload | undefined>()
	const dispatch = useDispatch()
	
	useEffect(() => {
		cards.forEach(c => {
			cardIdToPosition.current[c.id] = c.verticalLayout
		})
	}, [cards])

	useEffect(() => {
		const listener: DragDropListener = (phase, context, e: any) => {
			const parent = document.querySelector('.card-grid-scroll')
			if (!parent) return
			const parentRect = parent.getBoundingClientRect()
			if (context.type !== 'card-drag') return

			if (phase === 'start') {
				setIsDragging(true)
				const cardElems = parent?.querySelectorAll('.board-card')
				const getCoords = (card: any): Rectangle | undefined => {
					if (!card) return undefined
					const c = card.getBoundingClientRect()
					const cX = c.x + parent.scrollTop - parentRect.x
					const cY = c.y + parent.scrollTop - parentRect.y
					return {
						x: cX,
						y: cY,
						height: c.height,
						width: c.width,
					}
				}
				if (cardElems) {
					const offset = 10
					const lineSize = 3
					const  halfline = lineSize / 2
					const arr: {
						id: string
						indicatorY: number
						rectangle: Rectangle,
						posTBA: number
						// newPos: number
					}[] = []
					for (let i = 0; i < cardElems?.length - 1; i++) {
						const ca = cardElems[i]
						const cb = cardElems[i + 1]
						const caid = ca.getAttribute('data-id')
						const cbid = cb.getAttribute('data-id')
						const a = getCoords(ca)
						const b = getCoords(cb)

						if (i == 0 && a) {
							if (caid == context.cardId) continue
							if (!caid) continue

							const tba = cardIdToPosition.current[caid] / 2
							if (!tba) continue

							const indicatorY = a.y - halfline - offset
							arr.push({ 
								id: v4(),
								indicatorY,
								posTBA: tba,
								rectangle: {
									...a,
									x: a.x,
									height: 3,
									y: indicatorY - context.gridOffset.y + offset
								}  
							})
						}

						if (i == cardElems?.length - 2 && b) {
							if (cbid == context.cardId) continue
							const indicatorY = b.y  + b.height - halfline + offset

							if (!cbid) continue

							const tba = cardIdToPosition.current[cbid] + 10000
							if (!tba) continue

							arr.push({ 
								id: v4(),
								indicatorY, 
								posTBA: tba,
								rectangle: {
									...b,
									x: b.x,
									height: 3,
									y: indicatorY - context.gridOffset.y + offset
								} 
							})
						}

						if (a && b) {
							if (caid == context.cardId || cbid == context.cardId) {
								continue
							}
							if (!cbid) continue
							if (!caid) continue

							const tba = (cardIdToPosition.current[cbid] + cardIdToPosition.current[caid]) / 2
							if (!tba) continue

							const indicatorY = ((a.y + a.height + b.y) / 2) - halfline
							arr.push({
								id: v4(),
								indicatorY,
								posTBA: tba,
								rectangle: {
									...a,
									x: a.x,
									height: 3,
									y: indicatorY - context.gridOffset.y + offset
								} 
							})
							continue
						}
					}

					const all: { id: string, top: number, tba: number }[] = []
					const rects: Array<{ id: string, rect: Rectangle, tba: number }> = []

					for (const a of arr) {
						rects.push({ id: a.id, rect: a.rectangle, tba: a.posTBA })
						all.push({ id: a.id, top: a.indicatorY, tba: a.posTBA })
					}

					indicatorsRef.current = rects
					setIndicators(all)
				}
				// document.querySelector('.card-grid-scroll')
				// const cards =
				return
			}

			const scrollOffsetX = parent ? parent.scrollLeft || 0 : 0
			const scrollOffsetY = parent ? parent.scrollTop || 0 : 0

			const originX = e.clientX 
			// - context.topLeftOffset.x
			const originY = e.clientY 
			// - context.topLeftOffset.y

			const x = originX - context.gridOffset.x + scrollOffsetX
			const y = originY - context.gridOffset.y + scrollOffsetY

			const p = new Point(x, y)

			let finalise: FinaliseAtPayload | undefined  = undefined
			for (const i of indicatorsRef.current) {
				const dist =  p.calcDistanceToRect(i.rect)
				if (dist < 20) {
					finalise = {
						indicatorId: i.id,
						cardId: context.cardId,
						position: i.tba
					}
				}
			}

			if (phase === 'finished') {
				setIsDragging(false)
				if (finaliseAt.current?.cardId) {
					dispatch(cardsActions.update({
						id: finaliseAt.current.cardId,
						verticallayout: {
							order: finaliseAt.current.position
						}
					}))
					finaliseAt.current = undefined
				}
				setHovered(undefined)
				
				setIndicators([])
				return
			} else {
				if (finalise?.indicatorId) {
					setHovered(finalise.indicatorId)
					finaliseAt.current = finalise
				}
			}
		}

		const removeListener = DragDropCore.addListener(dragZoneId, listener)
		const unmount = DragDropCore.mountContainer(dragZoneId, 1, container)
		return () => {
			removeListener()
			unmount()
		}
	}, [dragZoneId, dispatch, indicatorsRef, container])

	return useMemo(() => {
		return { 
			indicators: <>
				{indicators.map((i) => <Indicator y={i.top} key={i.id} selected={i.id == hovered} />)}
			</>,
			isDragging
		}
	}, [indicators, hovered, isDragging])
}

const Indicator = styled.div<{ y: number, selected: boolean }>`
	${(p) => p.theme.backgrounds.clepside.r.css('background-color')};
	height: 3px;
	position: absolute;
	top: ${(p) => p.y}px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 4px;
	max-width: 500px;
	width: 100%;

	${p => p.selected && css`
		&:before {
			position: absolute;
			top: -1.5px;
			left: -1.5px;
			width: calc(100% + 3px);
			height: calc(100% + 3px);
			content: '';
			display: block;
			border-radius: 25.6px;
			${p.theme.backgrounds.clepside.r.css('box-shadow', '0px 0px 0px 1.5px')};
			z-index: 0;
		}
	`}
`
