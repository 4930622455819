import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { CardGrid } from '@comps/complex/cards/cardGrid';
import { DelayCardGrid } from '@comps/complex/cards/cardGrid.delayCardGrid';
import { CardList } from '@comps/complex/cards/cardList';
import { useActivityStreaming } from '@root/hooks/useActivityStreaming';
import styled from 'styled-components';

import { useSessionOutletContext } from './Sessions.ID.useSessionOutletContext';

export const SessionIDOutletActivity: React.FC<{ forceCardList?: boolean }> = ({ forceCardList }) => {
	const { activity } = useSessionOutletContext()
	useActivityStreaming(activity?.id)
	if (!activity) return <span>No activity found</span>

	if (forceCardList) {
		return <CardList resourceId={activity.id} resourceType={GrantableResourceEnum.ACTIVITY} />
	}

	return (
		<Holder>
			<DelayCardGrid>
				<CardGrid resourceId={activity.id} resourceType={GrantableResourceEnum.ACTIVITY} />
			</DelayCardGrid>
		</Holder>
	)
}

const Holder = styled.div`
	width: calc(100%);
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 960px;
	overflow: visible;
	padding: 0px;
	min-height: 100%;
	padding-top: 0px;
`
