import { Text } from '@comps/typography/text';
import { useListNavigator } from '@hooks/useListNavigator';
import { ThemeBackbone } from '@root/styled';
import { ViewID } from '@store/slices/interface.types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { EqualInsetSize, InsetPaddingType } from './inset';
import { ListItem } from './list.item';

export const List: React.FC<{
	scope: ViewID
	items: string[]
	renderItem: (item: string) => React.ReactElement
	value?: string
	onSelect: (item: string) => void
	type?: 'normal' | 'toast'
	padding?: InsetPaddingType
	prependItem?: React.ReactElement
	appendItem?: React.ReactElement
	borderColor?: keyof ThemeBackbone<any>['border']
	backgroundColor?: keyof ThemeBackbone<any>['backgrounds']
	borderOffsetLeft?: number
	borderOffsetRight?: number
	fill?: boolean
	minHeight?: number
}> = ({
	scope,
	fill,
	items,
	borderOffsetLeft,
	borderOffsetRight,
	value,
	renderItem,
	onSelect,
	borderColor,
	backgroundColor,
	type = 'normal',
	padding,
	minHeight,
	prependItem,
	appendItem,
}) => {
	const filteredItems = useMemo(() => {
		return [...(prependItem ? ['prependedRow'] : []), ...items, ...(appendItem ? ['appendedRow'] : [])]
	}, [items, appendItem, prependItem])
	const { onMouseIn, onMouseOut, onMouseClick, highlighted } = useListNavigator(scope, filteredItems, value, onSelect)

	const renderedItems = useMemo(() => {
		return [
			...(prependItem
				? [
						{
							key: 'prependedRow',
							node: (
								<ListItem
									type={type}
									borderOffsetLeft={borderOffsetLeft}
									borderOffsetRight={borderOffsetRight}
									onMouseIn={onMouseIn}
									onMouseOut={onMouseOut}
									onMouseClick={onMouseClick}
									borderColor={borderColor}
									backgroundColor={backgroundColor}
								>
									{prependItem}
								</ListItem>
							),
						},
				  ]
				: []),
			...items.map((item, i, arr) => ({
				key: item,
				node: (
					<ListItem
						type={type}
						borderOffsetLeft={borderOffsetLeft}
						borderOffsetRight={borderOffsetRight}
						onMouseIn={onMouseIn}
						onMouseOut={onMouseOut}
						onMouseClick={onMouseClick}
						borderColor={borderColor}
						backgroundColor={backgroundColor}
						noBorder={i === arr.length - 1 && !appendItem}
					>
						{renderItem(item)}
					</ListItem>
				),
			})),
			...(appendItem
				? [
						{
							key: 'appendedRow',
							node: (
								<ListItem
									borderOffsetLeft={borderOffsetLeft}
									borderOffsetRight={borderOffsetRight}
									type={type}
									onMouseIn={onMouseIn}
									onMouseOut={onMouseOut}
									onMouseClick={onMouseClick}
									noBorder={true}
									backgroundColor={backgroundColor}
									borderColor={borderColor}
								>
									{appendItem}
								</ListItem>
							),
						},
				  ]
				: []),
		]
	}, [
		prependItem,
		type,
		borderColor,
		onMouseIn,
		onMouseOut,
		borderOffsetLeft,
		borderOffsetRight,
		onMouseClick,
		items,
		appendItem,
		renderItem,
		backgroundColor,
	])

	const nodes = useMemo(() => {
		const n = renderedItems.map((item, index) => {
			if (highlighted === undefined) return item.node
			if (index === highlighted - 1)
				return React.cloneElement(item.node, {
					preHovered: true,
				})
			if (index === highlighted)
				return React.cloneElement(item.node, {
					hovered: true,
				})
			return item.node
		})

		return renderedItems.map((item, index) => <React.Fragment key={item.key}>{n[index]}</React.Fragment>)
	}, [renderedItems, highlighted])

	const [shouldShowNoMatching, setShouldShowNoMatching] = useState(false)

	const timeout = useRef<any>(null)
	useEffect(() => {
		if (renderedItems.length == 0) {
			if (timeout.current) {
				clearTimeout(timeout.current)
				timeout.current = undefined
			}
			timeout.current = setTimeout(() => {
				setShouldShowNoMatching(true)
			}, 1000)
		} else {
			if (timeout.current) {
				clearTimeout(timeout.current)
				timeout.current = undefined
			}
			setShouldShowNoMatching(false)
		}
	}, [renderedItems, value])

	useEffect(() => {
		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current)
				timeout.current = undefined
			}
		}
	}, [])

	if (shouldShowNoMatching)
		return (
			<NoItemsFound>
				<TextInset>
					<Text>There are no items matching this filter.</Text>
				</TextInset>
			</NoItemsFound>
		)

	return (
		<Holder padding={padding} expand={fill} minHeight={minHeight}>
			{nodes}
		</Holder>
	)
}

const TextInset = styled.div`
	position: relative;
	top: -15px;
`

const NoItemsFound = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
`

const Holder = styled.div<{ expand?: boolean; padding?: InsetPaddingType; minHeight?: number }>`
	display: flex;
	align-items: stretch;
	flex-direction: column;
	margin: 0;
	width: 100%;
	flex-shrink: 0;
	box-sizing: border-box;
	padding: 10px 0;
	padding-bottom: 25px;

	${(p) =>
		p.minHeight &&
		css`
			min-height: ${p.minHeight}px;
		`}

	${(p) =>
		p.padding &&
		css`
			${EqualInsetSize[p.padding]}
		`}

	${(p) =>
		p.expand &&
		css`
			flex-basis: 0;
			flex-grow: 1;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
		`};
`
