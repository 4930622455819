import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@root/store';
import { useUser } from '@root/store/selectors/useUser';
import { userProfilesActions } from '@root/store/slices/userProfiles';

export const useActiveUserProfile = () => {
	const activeUser = useUser()
	const dispatch = useDispatch()
	const profile = useSelector((state: RootState) => (activeUser?.uid ? state.userProfiles.profiles[activeUser.uid] : undefined))

	useEffect(() => {
		const id = activeUser?.uid
		if (!id) {
			return undefined
		}
		dispatch(
			userProfilesActions.loadProfileIntoStore({
				uuid: id,
			})
		)
		return undefined
	}, [activeUser, dispatch])

	return profile
}
