// source: packets_v1/activities.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var entities_access_grant_v1_pb = require('../entities/access_grant_v1_pb.js');
goog.object.extend(proto, entities_access_grant_v1_pb);
var commons_core_pb = require('../commons/core_pb.js');
goog.object.extend(proto, commons_core_pb);
goog.exportSymbol('proto.packets_v1.ActivityPacket', null, global);
goog.exportSymbol('proto.packets_v1.ActivityPacketData', null, global);
goog.exportSymbol('proto.packets_v1.ActivityPacketMeta', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.ActivityPacketData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.ActivityPacketData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.ActivityPacketData.displayName = 'proto.packets_v1.ActivityPacketData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.ActivityPacketMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.ActivityPacketMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.ActivityPacketMeta.displayName = 'proto.packets_v1.ActivityPacketMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.ActivityPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.ActivityPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.ActivityPacket.displayName = 'proto.packets_v1.ActivityPacket';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.ActivityPacketData.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.ActivityPacketData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.ActivityPacketData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.ActivityPacketData.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    color: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    role: jspb.Message.getFieldWithDefault(msg, 4, 0),
    wallpaper: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.ActivityPacketData}
 */
proto.packets_v1.ActivityPacketData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.ActivityPacketData;
  return proto.packets_v1.ActivityPacketData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.ActivityPacketData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.ActivityPacketData}
 */
proto.packets_v1.ActivityPacketData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 4:
      var value = /** @type {!proto.access_grant_v1.GrantTypeEnum} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWallpaper(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.ActivityPacketData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.ActivityPacketData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.ActivityPacketData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.ActivityPacketData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getWallpaper();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.packets_v1.ActivityPacketData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.ActivityPacketData} returns this
 */
proto.packets_v1.ActivityPacketData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.packets_v1.ActivityPacketData.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.ActivityPacketData} returns this
 */
proto.packets_v1.ActivityPacketData.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.packets_v1.ActivityPacketData.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.ActivityPacketData} returns this
 */
proto.packets_v1.ActivityPacketData.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional access_grant_v1.GrantTypeEnum role = 4;
 * @return {!proto.access_grant_v1.GrantTypeEnum}
 */
proto.packets_v1.ActivityPacketData.prototype.getRole = function() {
  return /** @type {!proto.access_grant_v1.GrantTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.access_grant_v1.GrantTypeEnum} value
 * @return {!proto.packets_v1.ActivityPacketData} returns this
 */
proto.packets_v1.ActivityPacketData.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string wallpaper = 5;
 * @return {string}
 */
proto.packets_v1.ActivityPacketData.prototype.getWallpaper = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.ActivityPacketData} returns this
 */
proto.packets_v1.ActivityPacketData.prototype.setWallpaper = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.ActivityPacketMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.ActivityPacketMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.ActivityPacketMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.ActivityPacketMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && commons_core_pb.Meta.toObject(includeInstance, f),
    color: (f = msg.getColor()) && commons_core_pb.Meta.toObject(includeInstance, f),
    icon: (f = msg.getIcon()) && commons_core_pb.Meta.toObject(includeInstance, f),
    role: (f = msg.getRole()) && commons_core_pb.Meta.toObject(includeInstance, f),
    wallpaper: (f = msg.getWallpaper()) && commons_core_pb.Meta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.ActivityPacketMeta}
 */
proto.packets_v1.ActivityPacketMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.ActivityPacketMeta;
  return proto.packets_v1.ActivityPacketMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.ActivityPacketMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.ActivityPacketMeta}
 */
proto.packets_v1.ActivityPacketMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setColor(value);
      break;
    case 3:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setIcon(value);
      break;
    case 4:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 5:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setWallpaper(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.ActivityPacketMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.ActivityPacketMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.ActivityPacketMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.ActivityPacketMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getColor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getIcon();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getWallpaper();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
};


/**
 * optional commons.Meta name = 1;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.ActivityPacketMeta.prototype.getName = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 1));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.ActivityPacketMeta} returns this
*/
proto.packets_v1.ActivityPacketMeta.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.ActivityPacketMeta} returns this
 */
proto.packets_v1.ActivityPacketMeta.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.ActivityPacketMeta.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional commons.Meta color = 2;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.ActivityPacketMeta.prototype.getColor = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 2));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.ActivityPacketMeta} returns this
*/
proto.packets_v1.ActivityPacketMeta.prototype.setColor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.ActivityPacketMeta} returns this
 */
proto.packets_v1.ActivityPacketMeta.prototype.clearColor = function() {
  return this.setColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.ActivityPacketMeta.prototype.hasColor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional commons.Meta icon = 3;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.ActivityPacketMeta.prototype.getIcon = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 3));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.ActivityPacketMeta} returns this
*/
proto.packets_v1.ActivityPacketMeta.prototype.setIcon = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.ActivityPacketMeta} returns this
 */
proto.packets_v1.ActivityPacketMeta.prototype.clearIcon = function() {
  return this.setIcon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.ActivityPacketMeta.prototype.hasIcon = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional commons.Meta role = 4;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.ActivityPacketMeta.prototype.getRole = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 4));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.ActivityPacketMeta} returns this
*/
proto.packets_v1.ActivityPacketMeta.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.ActivityPacketMeta} returns this
 */
proto.packets_v1.ActivityPacketMeta.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.ActivityPacketMeta.prototype.hasRole = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional commons.Meta wallpaper = 5;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.ActivityPacketMeta.prototype.getWallpaper = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 5));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.ActivityPacketMeta} returns this
*/
proto.packets_v1.ActivityPacketMeta.prototype.setWallpaper = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.ActivityPacketMeta} returns this
 */
proto.packets_v1.ActivityPacketMeta.prototype.clearWallpaper = function() {
  return this.setWallpaper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.ActivityPacketMeta.prototype.hasWallpaper = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.ActivityPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.ActivityPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.ActivityPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.ActivityPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deleted: (f = msg.getDeleted()) && commons_core_pb.DeleteMeta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.packets_v1.ActivityPacketData.toObject(includeInstance, f),
    meta: (f = msg.getMeta()) && proto.packets_v1.ActivityPacketMeta.toObject(includeInstance, f),
    lastModifiedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cloudedAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.ActivityPacket}
 */
proto.packets_v1.ActivityPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.ActivityPacket;
  return proto.packets_v1.ActivityPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.ActivityPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.ActivityPacket}
 */
proto.packets_v1.ActivityPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.commons.PacketType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new commons_core_pb.DeleteMeta;
      reader.readMessage(value,commons_core_pb.DeleteMeta.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 4:
      var value = new proto.packets_v1.ActivityPacketData;
      reader.readMessage(value,proto.packets_v1.ActivityPacketData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = new proto.packets_v1.ActivityPacketMeta;
      reader.readMessage(value,proto.packets_v1.ActivityPacketMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModifiedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloudedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.ActivityPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.ActivityPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.ActivityPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.ActivityPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.DeleteMeta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.packets_v1.ActivityPacketData.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.packets_v1.ActivityPacketMeta.serializeBinaryToWriter
    );
  }
  f = message.getLastModifiedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCloudedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.packets_v1.ActivityPacket.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.ActivityPacket} returns this
 */
proto.packets_v1.ActivityPacket.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional commons.PacketType type = 2;
 * @return {!proto.commons.PacketType}
 */
proto.packets_v1.ActivityPacket.prototype.getType = function() {
  return /** @type {!proto.commons.PacketType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commons.PacketType} value
 * @return {!proto.packets_v1.ActivityPacket} returns this
 */
proto.packets_v1.ActivityPacket.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional commons.DeleteMeta deleted = 3;
 * @return {?proto.commons.DeleteMeta}
 */
proto.packets_v1.ActivityPacket.prototype.getDeleted = function() {
  return /** @type{?proto.commons.DeleteMeta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.DeleteMeta, 3));
};


/**
 * @param {?proto.commons.DeleteMeta|undefined} value
 * @return {!proto.packets_v1.ActivityPacket} returns this
*/
proto.packets_v1.ActivityPacket.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.ActivityPacket} returns this
 */
proto.packets_v1.ActivityPacket.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.ActivityPacket.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ActivityPacketData data = 4;
 * @return {?proto.packets_v1.ActivityPacketData}
 */
proto.packets_v1.ActivityPacket.prototype.getData = function() {
  return /** @type{?proto.packets_v1.ActivityPacketData} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.ActivityPacketData, 4));
};


/**
 * @param {?proto.packets_v1.ActivityPacketData|undefined} value
 * @return {!proto.packets_v1.ActivityPacket} returns this
*/
proto.packets_v1.ActivityPacket.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.ActivityPacket} returns this
 */
proto.packets_v1.ActivityPacket.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.ActivityPacket.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ActivityPacketMeta meta = 5;
 * @return {?proto.packets_v1.ActivityPacketMeta}
 */
proto.packets_v1.ActivityPacket.prototype.getMeta = function() {
  return /** @type{?proto.packets_v1.ActivityPacketMeta} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.ActivityPacketMeta, 5));
};


/**
 * @param {?proto.packets_v1.ActivityPacketMeta|undefined} value
 * @return {!proto.packets_v1.ActivityPacket} returns this
*/
proto.packets_v1.ActivityPacket.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.ActivityPacket} returns this
 */
proto.packets_v1.ActivityPacket.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.ActivityPacket.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 last_modified_at = 6;
 * @return {number}
 */
proto.packets_v1.ActivityPacket.prototype.getLastModifiedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.ActivityPacket} returns this
 */
proto.packets_v1.ActivityPacket.prototype.setLastModifiedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.packets_v1.ActivityPacket.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.ActivityPacket} returns this
 */
proto.packets_v1.ActivityPacket.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 clouded_at = 8;
 * @return {number}
 */
proto.packets_v1.ActivityPacket.prototype.getCloudedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.ActivityPacket} returns this
 */
proto.packets_v1.ActivityPacket.prototype.setCloudedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


goog.object.extend(exports, proto.packets_v1);
