import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ulid } from 'ulid';

import { RootState } from '@root/store';

import { DragDropCore, DragDropListener } from './dragDropCore';

export const useDragAndDropHover = (target: HTMLElement, supports: boolean | undefined) => {
	const isDragging = useSelector((state: RootState) => state.interface.dragging)
	const timer = useRef<any>(null)
	const dragZoneId = useRef<any>(ulid())

	const click = useCallback(() => {
		if (!supports) {
			return
		}
		target.click()
	}, [target, supports])

	// useEffect(() => {
	// 	if (!supports) {
	// 		return
	// 	}

	// 	const enter = () => {
	// 		console.log('inside')

	// 	}
	// 	const leave = () => {
	// if (timer.current) {
	// 	clearTimeout(timer.current)
	// 	timer.current = null
	// }
	// 	}

	// 	if (target) {
	// 		if (isDragging) {
	// 			console.log(target)
	// 			target.addEventListener('pointerenter', enter)
	// 			target.addEventListener('dragenter', enter)
	// 			// target.addEventListener('mousemove', enter)
	// 			target.addEventListener('pointerleave', leave)

	// 			return () => {
	// 				target.removeEventListener('pointerenter', enter)
	// 				// target.removeEventListener('dragenter', enter)
	// 				target.removeEventListener('pointerleave', leave)
	// 			}
	// 		} else {
	// 			target.removeEventListener('pointerenter', enter)
	// 			// target.removeEventListener('dragenter', enter)
	// 			target.removeEventListener('pointerleave', leave)
	// 		}
	// 	}
	// }, [isDragging, click, supports, target])

	useEffect(() => {
		if (!target || !supports) return

		const listener: DragDropListener = (phase, context, e: any) => {
			if (phase === 'start') {
				if (timer.current) {
					clearTimeout(timer.current)
					timer.current = null
				}
				timer.current = setTimeout(click, 450)
			} else if (phase === 'interrupted') {
				if (timer.current) {
					clearTimeout(timer.current)
					timer.current = null
				}
			}
		}

		const removeListener = DragDropCore.addListener(dragZoneId.current, listener)
		const unmount = DragDropCore.mountContainer(dragZoneId.current, 1, target)
		return () => {
			removeListener()
			unmount()
		}
	}, [target, click, supports])
}
