import { useHotkeysActiveScope } from '@root/hooks/useHotkeysActiveScope';
import { RootState } from '@root/store';
import { HotkeysMap } from '@root/store/slices/interface.types';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Transitions } from '../brand/transitions';
import { Text } from './typography/text';

const modalVariants = {
	showModal: { opacity: 1 },
	hideModal: { opacity: 0 },
}

export const HotkeyTipsOverlay: React.FC = () => {
	const shouldShow = useSelector((state: RootState) => state.interface?.showKeytips)
	const activeScope = useHotkeysActiveScope()

	const tips = useMemo(() => {
		if (!activeScope) return

		return (
			HotkeysMap?.[activeScope]?.map((t) => {
				return (
					<Row key={t.keys}>
						{t.keys.split('+').map((k) => (
							<Key key={k}>{k}</Key>
						))}
						<Line />
						<Text level="label">{t.description}</Text>
					</Row>
				)
			}) || []
		)
	}, [activeScope])

	return (
		<AnimatePresence>
			{shouldShow && (
				<Panel
					initial="hideModal"
					animate="showModal"
					exit="hideModal"
					variants={modalVariants}
					transition={Transitions.sessionFins}
				>
					<Text level="label">HOTKEYS</Text>
					<TipsHolder>{tips}</TipsHolder>
				</Panel>
			)}
		</AnimatePresence>
	)
}

const Panel = styled(motion.div)`
	position: fixed;
	bottom: 40px;
	border-radius: 12px;
	right: 40px;
	color: #fff;
	${(p) => p.theme.backgrounds.dark.r.css('background-color')};
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	z-index: 100000;
`

const Row = styled.div`
	display: flex;
	align-items: center;
`

const TipsHolder = styled.div`
	padding: 20px;
	border-radius: 20px;
`

const Key = styled.div`
	display: flex;
	text-transform: uppercase;
	padding: 4px 7px;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 0.2px;
	border-radius: 6px;
	margin-right: 7px;
	background-color: rgba(255, 255, 255, 0.1);
`
const Line = styled.div`
	height: 1px;
	background-color: rgba(0, 0, 0, 0.06);
	width: 10px;
	flex-shrink: 0;
	margin: 0px 15px 0 8px;
`
