const smooth = {
	duration: 0.4,
}
const relaxed = {
	type: 'spring' as const,
	damping: 80,
	stiffness: 1020,
}
const cardRelated = {
	type: 'spring' as const,
	damping: 17,
	stiffness: 100,
	mass: 1,
}
const tooltip = {
	type: 'spring' as const,
	damping: 27,
	stiffness: 360,
	mass: 1,
}
const slide = {
	type: 'spring' as const,
	damping: 80,
	stiffness: 320,
}
const toast = {
	type: 'spring',
	damping: 220,
	stiffness: 2000,
}

const linear = {
	type: 'spring' as const,
	damping: 220,
	stiffness: 2000,
}

export const Transitions = {
	tooltip,
	cardRelated,
	linear,
	fullscreen: {
		showModal: {
			opacity: 1,
			scale: 1,
		},
		hideModal: {
			opacity: 0,
			scale: 0.95,
		},
	},
	easey: {
		scale: {
			duration: 0.3,
		},
		opacity: {
			duration: 0.3,
		},
	},
	superChill: {
		opacity: {
			duration: 0.23,
		},
	},
	smooth: smooth,
	slide: slide,
	relaxed: {
		x: relaxed,
		y: relaxed,
		height: relaxed,
		width: relaxed,
		opacity: {
			duration: 0.25,
		},
		scale: {
			duration: 0.3,
		},
	},
	sessionFins: {
		y: relaxed,
		opacity: {
			duration: 0.15,
		},
	},
	toast: {
		x: toast,
		height: toast,
		width: toast,
		opacity: {
			duration: 0.3,
		},
	},
	instant: {
		duration: 0,
	},
	opacityOnly: {
		top: {
			duration: 0,
		},
		y: {
			duration: 0,
		},
		x: {
			duration: 0,
		},
		height: {
			duration: 0,
		},
		width: {
			duration: 0,
		},
		opacity: {
			duration: 0.35,
		},
	},
}
