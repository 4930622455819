import { TextInput } from '@comps/forms/textInput'
import { TextInputGroup } from '@comps/forms/textInputGroup'
import { Button } from '@comps/interactive/button'
import { WatcherButton } from '@comps/interactive/watcherButton'
import { CardStyle } from '@comps/layout/cardContainer'
import { Flex } from '@comps/layout/flex'
import { Inset, InsetSize } from '@comps/layout/inset'
import { SimplePage } from '@comps/layout/simplePage'
import { SimplePageLogo } from '@comps/layout/simplePageLogo'
import { Spacer } from '@comps/layout/space'
import PasswordStrengthBar from '@comps/passwordStrengthBar'
import { Logo } from '@comps/static/logo'
import { Text } from '@comps/typography/text'
import { useForm } from '@root/hooks/useForm'
import { useFormConfig } from '@root/hooks/useFormConfig'
import { useWatcher, useWatcherObserver } from '@root/hooks/useWatcher'
import { Medias } from '@root/medias'
import { authActions } from '@root/store/slices/auth'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

type SetPasswordFlowState = 'initial' | 'success' | 'wrong-code'
export const AuthHandlerResetPassword: React.FC = () => {
	const dispatch = useDispatch()
	const [flowState, setFlowState] = useState<SetPasswordFlowState>('initial')
	const [queryParams] = useSearchParams()
	const strengthBar = useRef<any>()
	const { watcherId: setNewPasswordWatcherId } = useWatcher()
	const { state: watcher } = useWatcherObserver(setNewPasswordWatcherId)
	const theme = useTheme()

	const formConfig = useFormConfig(
		{
			fields: {
				password: {
					validate: 'required,password',
				},
				confirmPassword: {
					validate: 'required,password,matches:password',
				},
			},
			onUpdate: (state) => {
				const bar = strengthBar.current
				bar?.setPassword(state.at.password.value || '')
			},
			onSubmit: (state, values) => {
				const actionCode = queryParams.get('oobCode')
				const value = values.password

				if (value) {
					if (actionCode) dispatch(authActions.authHandleResetPassword({ actionCode, watcherId: setNewPasswordWatcherId, newPassword: value }))
					else setFlowState('wrong-code')
				}
			},
		},
		[]
	)
	const { fields, injectFormField, submit, errors } = useForm(formConfig)

	useEffect(() => {
		if (watcher == 'done') {
			setFlowState('success')
			setTimeout(() => {
				const to = process.env.REACT_APP_APP_URL
				if (to) {
					window.location.replace(to)
				}
			}, 1000);
		} else if (watcher == 'fail') {
			setFlowState('wrong-code')
		}
	}, [watcher])

	const passwordRightSideMemo = useMemo(() => {
		return (
			<Flex direction="column" justify="flex-end">
				<PasswordStrengthBar ref={strengthBar} />
				<Spacer size={5} equal />
			</Flex>
		)
	}, [])


	if (flowState == 'wrong-code')
		return (
			<SimplePage center>
				<Flex grow align="center" justify="center" minHeight="100%" direction="column" spacing={30} maxWidth="400px">
					<SimplePageLogo larger />
					<Text level="title70">Bad Activation Code</Text>
					<Text level="body" align="center">
						This email has either been activated before or the provided activation code is not valid at all.
					</Text>
					<Link to="/">
						<Button inset="buttonMedium" color="accent">
							Take me back
						</Button>
					</Link>
				</Flex>
			</SimplePage>
		)

	return (
		<SimplePage center>
			<ContainerHolder>
				<LogoHolder>
					<Logo size={38} color={theme.backgrounds.clepside.r.hex} />
					<Spacer size={15} className="gone-on-mobile" />
					<LogoText level="title10" className="gone-on-mobile">Clepside</LogoText>
				</LogoHolder>
				<CardContent>
					<Flex direction="column" spacing={25} width="100%" grow align="stretch">
						<Text level="title50">New Password</Text>
						<Text>
							In order to get access to your account, we'll need you to provide a new password that
							will be assigned to it.
						</Text>
						<TextInputGroup
							label="Password"
							rightSideMemo={passwordRightSideMemo}
							error={errors?.password}
						>
							<TextInput
								inputStyle="block"
								placeholder="**********"
								{...fields.password}
								type="password"
								ref={injectFormField('password', 'text')}
							/>
						</TextInputGroup>
						<TextInputGroup label="Confirm Password" error={errors?.confirmPassword}>
							<TextInput
								inputStyle="block"
								placeholder="**********"
								{...fields.confirmPassword}
								type="password"
								ref={injectFormField('confirmPassword', 'text')}
							/>
						</TextInputGroup>
						<Spacer size={50} vertical className='gone-on-mobile' />
						<Flex direction="column" align="stretch" width="100%">
							<Spacer size={40} />
							<WatcherButton
								watcherId={setNewPasswordWatcherId}
								onClick={submit}
								color="accent"
								grow
								insetPadding="buttonMedium"
							>
								Set new password
							</WatcherButton>
						</Flex>
					</Flex>
				</CardContent>
				<div />
				{/* <Flex width='100%' maxWidth='340px'>
						<Button grow color='dark'>
							<Inset expandWidth padding='buttonMedium' justify='center' align='center' spacing={5}>
								<Icon type='apple' />
								Apple Login
							</Inset>
						</Button>
						<Spacer size={10} />
						<Button grow color='dark'>
							<Inset expandWidth padding='buttonMedium' justify='center' align='center' spacing={5}>
								<Icon type='google' />
								Google Login
							</Inset>
						</Button>
					</Flex> */}
			</ContainerHolder>
		</SimplePage>
	)

	// if (state == 'wrong-code')
	// 	return (
	// 		<SimplePage center>
	// 			<SimplePageLogo larger />
	// 			<Flex grow align="center" justify="center" minHeight="100%" direction="column" spacing={30} maxWidth="400px">
	// 				<Text level="title70">Bad Activation Code</Text>
	// 				<Text level="body" align="center">
	// 					This email has either been activated before or the provided activation code is not valid at all.
	// 				</Text>
	// 				<Link to="/">
	// 					<Button inset="buttonMedium" color="accent">
	// 						Take me back
	// 					</Button>
	// 				</Link>
	// 			</Flex>
	// 		</SimplePage>
	// 	)

	// return (
	// 	<SimplePage center>
	// 		<SimplePageLogo larger />
	// 		<Flex grow align="center" justify="center" minHeight="100%">
	// 			<Bouncer size={42} />
	// 		</Flex>
	// 	</SimplePage>
	// )
}

const ContainerHolder = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;

	@media ${Medias.PHONE} {
		justify-content: flex-start;
	}
`
const LogoText = styled(Text)`
	${(p) => p.theme.text.regular.r.css('color')};
`

const LogoHolder = styled.div`
	display: flex;
	flex-direction: row;
	padding-left: 10px;
	margin-bottom: 20px;
	justify-content: flex-start;
	align-items: center;

	@media ${Medias.PHONE} {
		padding-left: 0;
		padding-bottom: 10px;
		align-self: stretch;

		.gone-on-mobile {
			opacity: 0;
		}
	}
`

const CardContent = styled(Inset)`
	max-width: 320px;
	width: 100%;

	${CardStyle};
	${InsetSize.biggerCard};
	flex-direction: column;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-grow: 0;

	* {
		text-align: left;
	}

	button {
		* {
			text-align: center;about:blank#blocked
		}
	}

	@media ${Medias.PHONE} {
		background-color: transparent;
		box-shadow: none;
		border-radius: 0;
		padding: 0;
		overflow: visible;
		align-items: flex-start;

		.gone-on-mobile {
			display: none;
		}
	}
`
