// source: packets_v1/sessions.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var commons_core_pb = require('../commons/core_pb.js');
goog.object.extend(proto, commons_core_pb);
goog.exportSymbol('proto.packets_v1.SessionPacket', null, global);
goog.exportSymbol('proto.packets_v1.SessionPacketData', null, global);
goog.exportSymbol('proto.packets_v1.SessionPacketMeta', null, global);
goog.exportSymbol('proto.packets_v1.SessionPacketRoutineLink', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.SessionPacketRoutineLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.SessionPacketRoutineLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.SessionPacketRoutineLink.displayName = 'proto.packets_v1.SessionPacketRoutineLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.SessionPacketData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.SessionPacketData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.SessionPacketData.displayName = 'proto.packets_v1.SessionPacketData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.SessionPacketMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.SessionPacketMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.SessionPacketMeta.displayName = 'proto.packets_v1.SessionPacketMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.SessionPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.SessionPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.SessionPacket.displayName = 'proto.packets_v1.SessionPacket';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.SessionPacketRoutineLink.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.SessionPacketRoutineLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.SessionPacketRoutineLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SessionPacketRoutineLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    routineId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scheduleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scheduleDaystamp: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.SessionPacketRoutineLink}
 */
proto.packets_v1.SessionPacketRoutineLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.SessionPacketRoutineLink;
  return proto.packets_v1.SessionPacketRoutineLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.SessionPacketRoutineLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.SessionPacketRoutineLink}
 */
proto.packets_v1.SessionPacketRoutineLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoutineId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduleId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduleDaystamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.SessionPacketRoutineLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.SessionPacketRoutineLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.SessionPacketRoutineLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SessionPacketRoutineLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoutineId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScheduleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScheduleDaystamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string routine_id = 1;
 * @return {string}
 */
proto.packets_v1.SessionPacketRoutineLink.prototype.getRoutineId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.SessionPacketRoutineLink} returns this
 */
proto.packets_v1.SessionPacketRoutineLink.prototype.setRoutineId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string schedule_id = 2;
 * @return {string}
 */
proto.packets_v1.SessionPacketRoutineLink.prototype.getScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.SessionPacketRoutineLink} returns this
 */
proto.packets_v1.SessionPacketRoutineLink.prototype.setScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 schedule_daystamp = 3;
 * @return {number}
 */
proto.packets_v1.SessionPacketRoutineLink.prototype.getScheduleDaystamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.SessionPacketRoutineLink} returns this
 */
proto.packets_v1.SessionPacketRoutineLink.prototype.setScheduleDaystamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.SessionPacketData.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.SessionPacketData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.SessionPacketData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SessionPacketData.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    start: jspb.Message.getFieldWithDefault(msg, 2, 0),
    end: jspb.Message.getFieldWithDefault(msg, 3, 0),
    routineId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    title: jspb.Message.getFieldWithDefault(msg, 9, ""),
    routine: (f = msg.getRoutine()) && proto.packets_v1.SessionPacketRoutineLink.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.SessionPacketData}
 */
proto.packets_v1.SessionPacketData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.SessionPacketData;
  return proto.packets_v1.SessionPacketData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.SessionPacketData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.SessionPacketData}
 */
proto.packets_v1.SessionPacketData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoutineId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 10:
      var value = new proto.packets_v1.SessionPacketRoutineLink;
      reader.readMessage(value,proto.packets_v1.SessionPacketRoutineLink.deserializeBinaryFromReader);
      msg.setRoutine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.SessionPacketData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.SessionPacketData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.SessionPacketData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SessionPacketData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRoutineId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRoutine();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.packets_v1.SessionPacketRoutineLink.serializeBinaryToWriter
    );
  }
};


/**
 * optional string activity_id = 1;
 * @return {string}
 */
proto.packets_v1.SessionPacketData.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.SessionPacketData} returns this
 */
proto.packets_v1.SessionPacketData.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start = 2;
 * @return {number}
 */
proto.packets_v1.SessionPacketData.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.SessionPacketData} returns this
 */
proto.packets_v1.SessionPacketData.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end = 3;
 * @return {number}
 */
proto.packets_v1.SessionPacketData.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.SessionPacketData} returns this
 */
proto.packets_v1.SessionPacketData.prototype.setEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string routine_id = 4;
 * @return {string}
 */
proto.packets_v1.SessionPacketData.prototype.getRoutineId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.SessionPacketData} returns this
 */
proto.packets_v1.SessionPacketData.prototype.setRoutineId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.packets_v1.SessionPacketData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.SessionPacketData} returns this
 */
proto.packets_v1.SessionPacketData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string title = 9;
 * @return {string}
 */
proto.packets_v1.SessionPacketData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.SessionPacketData} returns this
 */
proto.packets_v1.SessionPacketData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional SessionPacketRoutineLink routine = 10;
 * @return {?proto.packets_v1.SessionPacketRoutineLink}
 */
proto.packets_v1.SessionPacketData.prototype.getRoutine = function() {
  return /** @type{?proto.packets_v1.SessionPacketRoutineLink} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.SessionPacketRoutineLink, 10));
};


/**
 * @param {?proto.packets_v1.SessionPacketRoutineLink|undefined} value
 * @return {!proto.packets_v1.SessionPacketData} returns this
*/
proto.packets_v1.SessionPacketData.prototype.setRoutine = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SessionPacketData} returns this
 */
proto.packets_v1.SessionPacketData.prototype.clearRoutine = function() {
  return this.setRoutine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SessionPacketData.prototype.hasRoutine = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.SessionPacketMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.SessionPacketMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.SessionPacketMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SessionPacketMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && commons_core_pb.Meta.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && commons_core_pb.Meta.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && commons_core_pb.Meta.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && commons_core_pb.Meta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.SessionPacketMeta}
 */
proto.packets_v1.SessionPacketMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.SessionPacketMeta;
  return proto.packets_v1.SessionPacketMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.SessionPacketMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.SessionPacketMeta}
 */
proto.packets_v1.SessionPacketMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 3:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.SessionPacketMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.SessionPacketMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.SessionPacketMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SessionPacketMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
};


/**
 * optional commons.Meta start = 1;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.SessionPacketMeta.prototype.getStart = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 1));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.SessionPacketMeta} returns this
*/
proto.packets_v1.SessionPacketMeta.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SessionPacketMeta} returns this
 */
proto.packets_v1.SessionPacketMeta.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SessionPacketMeta.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional commons.Meta end = 2;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.SessionPacketMeta.prototype.getEnd = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 2));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.SessionPacketMeta} returns this
*/
proto.packets_v1.SessionPacketMeta.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SessionPacketMeta} returns this
 */
proto.packets_v1.SessionPacketMeta.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SessionPacketMeta.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional commons.Meta description = 3;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.SessionPacketMeta.prototype.getDescription = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 3));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.SessionPacketMeta} returns this
*/
proto.packets_v1.SessionPacketMeta.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SessionPacketMeta} returns this
 */
proto.packets_v1.SessionPacketMeta.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SessionPacketMeta.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional commons.Meta title = 4;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.SessionPacketMeta.prototype.getTitle = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 4));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.SessionPacketMeta} returns this
*/
proto.packets_v1.SessionPacketMeta.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SessionPacketMeta} returns this
 */
proto.packets_v1.SessionPacketMeta.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SessionPacketMeta.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.SessionPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.SessionPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.SessionPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SessionPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deleted: (f = msg.getDeleted()) && commons_core_pb.DeleteMeta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.packets_v1.SessionPacketData.toObject(includeInstance, f),
    meta: (f = msg.getMeta()) && proto.packets_v1.SessionPacketMeta.toObject(includeInstance, f),
    lastModifiedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cloudedAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.SessionPacket}
 */
proto.packets_v1.SessionPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.SessionPacket;
  return proto.packets_v1.SessionPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.SessionPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.SessionPacket}
 */
proto.packets_v1.SessionPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.commons.PacketType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new commons_core_pb.DeleteMeta;
      reader.readMessage(value,commons_core_pb.DeleteMeta.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 4:
      var value = new proto.packets_v1.SessionPacketData;
      reader.readMessage(value,proto.packets_v1.SessionPacketData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = new proto.packets_v1.SessionPacketMeta;
      reader.readMessage(value,proto.packets_v1.SessionPacketMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModifiedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloudedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.SessionPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.SessionPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.SessionPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SessionPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.DeleteMeta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.packets_v1.SessionPacketData.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.packets_v1.SessionPacketMeta.serializeBinaryToWriter
    );
  }
  f = message.getLastModifiedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCloudedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.packets_v1.SessionPacket.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.SessionPacket} returns this
 */
proto.packets_v1.SessionPacket.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional commons.PacketType type = 2;
 * @return {!proto.commons.PacketType}
 */
proto.packets_v1.SessionPacket.prototype.getType = function() {
  return /** @type {!proto.commons.PacketType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commons.PacketType} value
 * @return {!proto.packets_v1.SessionPacket} returns this
 */
proto.packets_v1.SessionPacket.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional commons.DeleteMeta deleted = 3;
 * @return {?proto.commons.DeleteMeta}
 */
proto.packets_v1.SessionPacket.prototype.getDeleted = function() {
  return /** @type{?proto.commons.DeleteMeta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.DeleteMeta, 3));
};


/**
 * @param {?proto.commons.DeleteMeta|undefined} value
 * @return {!proto.packets_v1.SessionPacket} returns this
*/
proto.packets_v1.SessionPacket.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SessionPacket} returns this
 */
proto.packets_v1.SessionPacket.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SessionPacket.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SessionPacketData data = 4;
 * @return {?proto.packets_v1.SessionPacketData}
 */
proto.packets_v1.SessionPacket.prototype.getData = function() {
  return /** @type{?proto.packets_v1.SessionPacketData} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.SessionPacketData, 4));
};


/**
 * @param {?proto.packets_v1.SessionPacketData|undefined} value
 * @return {!proto.packets_v1.SessionPacket} returns this
*/
proto.packets_v1.SessionPacket.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SessionPacket} returns this
 */
proto.packets_v1.SessionPacket.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SessionPacket.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SessionPacketMeta meta = 5;
 * @return {?proto.packets_v1.SessionPacketMeta}
 */
proto.packets_v1.SessionPacket.prototype.getMeta = function() {
  return /** @type{?proto.packets_v1.SessionPacketMeta} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.SessionPacketMeta, 5));
};


/**
 * @param {?proto.packets_v1.SessionPacketMeta|undefined} value
 * @return {!proto.packets_v1.SessionPacket} returns this
*/
proto.packets_v1.SessionPacket.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SessionPacket} returns this
 */
proto.packets_v1.SessionPacket.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SessionPacket.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 last_modified_at = 6;
 * @return {number}
 */
proto.packets_v1.SessionPacket.prototype.getLastModifiedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.SessionPacket} returns this
 */
proto.packets_v1.SessionPacket.prototype.setLastModifiedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.packets_v1.SessionPacket.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.SessionPacket} returns this
 */
proto.packets_v1.SessionPacket.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 clouded_at = 8;
 * @return {number}
 */
proto.packets_v1.SessionPacket.prototype.getCloudedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.SessionPacket} returns this
 */
proto.packets_v1.SessionPacket.prototype.setCloudedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


goog.object.extend(exports, proto.packets_v1);
