// source: packets_v1/fragments_uploadable.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var commons_core_pb = require('../commons/core_pb.js');
goog.object.extend(proto, commons_core_pb);
goog.exportSymbol('proto.packets_v1.FileInfo', null, global);
goog.exportSymbol('proto.packets_v1.FragmentUploadablePacket', null, global);
goog.exportSymbol('proto.packets_v1.FragmentUploadablePacketData', null, global);
goog.exportSymbol('proto.packets_v1.FragmentUploadablePacketMeta', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.FileInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.FileInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.FileInfo.displayName = 'proto.packets_v1.FileInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.FragmentUploadablePacketData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.FragmentUploadablePacketData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.FragmentUploadablePacketData.displayName = 'proto.packets_v1.FragmentUploadablePacketData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.FragmentUploadablePacketMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.FragmentUploadablePacketMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.FragmentUploadablePacketMeta.displayName = 'proto.packets_v1.FragmentUploadablePacketMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.FragmentUploadablePacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.FragmentUploadablePacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.FragmentUploadablePacket.displayName = 'proto.packets_v1.FragmentUploadablePacket';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.FileInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.FileInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.FileInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FileInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.FileInfo}
 */
proto.packets_v1.FileInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.FileInfo;
  return proto.packets_v1.FileInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.FileInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.FileInfo}
 */
proto.packets_v1.FileInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.FileInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.FileInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.FileInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FileInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.packets_v1.FileInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.FileInfo} returns this
 */
proto.packets_v1.FileInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.packets_v1.FileInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.FileInfo} returns this
 */
proto.packets_v1.FileInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.FragmentUploadablePacketData.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.FragmentUploadablePacketData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.FragmentUploadablePacketData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FragmentUploadablePacketData.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileInfo: (f = msg.getFileInfo()) && proto.packets_v1.FileInfo.toObject(includeInstance, f),
    position: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.FragmentUploadablePacketData}
 */
proto.packets_v1.FragmentUploadablePacketData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.FragmentUploadablePacketData;
  return proto.packets_v1.FragmentUploadablePacketData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.FragmentUploadablePacketData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.FragmentUploadablePacketData}
 */
proto.packets_v1.FragmentUploadablePacketData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardId(value);
      break;
    case 2:
      var value = new proto.packets_v1.FileInfo;
      reader.readMessage(value,proto.packets_v1.FileInfo.deserializeBinaryFromReader);
      msg.setFileInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.FragmentUploadablePacketData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.FragmentUploadablePacketData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.FragmentUploadablePacketData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FragmentUploadablePacketData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.packets_v1.FileInfo.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string card_id = 1;
 * @return {string}
 */
proto.packets_v1.FragmentUploadablePacketData.prototype.getCardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.FragmentUploadablePacketData} returns this
 */
proto.packets_v1.FragmentUploadablePacketData.prototype.setCardId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FileInfo file_info = 2;
 * @return {?proto.packets_v1.FileInfo}
 */
proto.packets_v1.FragmentUploadablePacketData.prototype.getFileInfo = function() {
  return /** @type{?proto.packets_v1.FileInfo} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.FileInfo, 2));
};


/**
 * @param {?proto.packets_v1.FileInfo|undefined} value
 * @return {!proto.packets_v1.FragmentUploadablePacketData} returns this
*/
proto.packets_v1.FragmentUploadablePacketData.prototype.setFileInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.FragmentUploadablePacketData} returns this
 */
proto.packets_v1.FragmentUploadablePacketData.prototype.clearFileInfo = function() {
  return this.setFileInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.FragmentUploadablePacketData.prototype.hasFileInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double position = 3;
 * @return {number}
 */
proto.packets_v1.FragmentUploadablePacketData.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.FragmentUploadablePacketData} returns this
 */
proto.packets_v1.FragmentUploadablePacketData.prototype.setPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.FragmentUploadablePacketMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.FragmentUploadablePacketMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.FragmentUploadablePacketMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FragmentUploadablePacketMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileInfo: (f = msg.getFileInfo()) && commons_core_pb.Meta.toObject(includeInstance, f),
    position: (f = msg.getPosition()) && commons_core_pb.Meta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.FragmentUploadablePacketMeta}
 */
proto.packets_v1.FragmentUploadablePacketMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.FragmentUploadablePacketMeta;
  return proto.packets_v1.FragmentUploadablePacketMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.FragmentUploadablePacketMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.FragmentUploadablePacketMeta}
 */
proto.packets_v1.FragmentUploadablePacketMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setFileInfo(value);
      break;
    case 2:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.FragmentUploadablePacketMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.FragmentUploadablePacketMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.FragmentUploadablePacketMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FragmentUploadablePacketMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
};


/**
 * optional commons.Meta file_info = 1;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.FragmentUploadablePacketMeta.prototype.getFileInfo = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 1));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.FragmentUploadablePacketMeta} returns this
*/
proto.packets_v1.FragmentUploadablePacketMeta.prototype.setFileInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.FragmentUploadablePacketMeta} returns this
 */
proto.packets_v1.FragmentUploadablePacketMeta.prototype.clearFileInfo = function() {
  return this.setFileInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.FragmentUploadablePacketMeta.prototype.hasFileInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional commons.Meta position = 2;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.FragmentUploadablePacketMeta.prototype.getPosition = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 2));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.FragmentUploadablePacketMeta} returns this
*/
proto.packets_v1.FragmentUploadablePacketMeta.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.FragmentUploadablePacketMeta} returns this
 */
proto.packets_v1.FragmentUploadablePacketMeta.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.FragmentUploadablePacketMeta.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.FragmentUploadablePacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.FragmentUploadablePacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FragmentUploadablePacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deleted: (f = msg.getDeleted()) && commons_core_pb.DeleteMeta.toObject(includeInstance, f),
    uploadedAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    uploadPercentage: jspb.Message.getFieldWithDefault(msg, 5, 0),
    processedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    data: (f = msg.getData()) && proto.packets_v1.FragmentUploadablePacketData.toObject(includeInstance, f),
    meta: (f = msg.getMeta()) && proto.packets_v1.FragmentUploadablePacketMeta.toObject(includeInstance, f),
    lastModifiedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    cloudedAt: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.FragmentUploadablePacket}
 */
proto.packets_v1.FragmentUploadablePacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.FragmentUploadablePacket;
  return proto.packets_v1.FragmentUploadablePacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.FragmentUploadablePacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.FragmentUploadablePacket}
 */
proto.packets_v1.FragmentUploadablePacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.commons.PacketType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new commons_core_pb.DeleteMeta;
      reader.readMessage(value,commons_core_pb.DeleteMeta.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUploadedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUploadPercentage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProcessedAt(value);
      break;
    case 7:
      var value = new proto.packets_v1.FragmentUploadablePacketData;
      reader.readMessage(value,proto.packets_v1.FragmentUploadablePacketData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 8:
      var value = new proto.packets_v1.FragmentUploadablePacketMeta;
      reader.readMessage(value,proto.packets_v1.FragmentUploadablePacketMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModifiedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloudedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.FragmentUploadablePacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.FragmentUploadablePacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FragmentUploadablePacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.DeleteMeta.serializeBinaryToWriter
    );
  }
  f = message.getUploadedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUploadPercentage();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getProcessedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.packets_v1.FragmentUploadablePacketData.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.packets_v1.FragmentUploadablePacketMeta.serializeBinaryToWriter
    );
  }
  f = message.getLastModifiedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getCloudedAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
 */
proto.packets_v1.FragmentUploadablePacket.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional commons.PacketType type = 2;
 * @return {!proto.commons.PacketType}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.getType = function() {
  return /** @type {!proto.commons.PacketType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commons.PacketType} value
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
 */
proto.packets_v1.FragmentUploadablePacket.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional commons.DeleteMeta deleted = 3;
 * @return {?proto.commons.DeleteMeta}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.getDeleted = function() {
  return /** @type{?proto.commons.DeleteMeta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.DeleteMeta, 3));
};


/**
 * @param {?proto.commons.DeleteMeta|undefined} value
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
*/
proto.packets_v1.FragmentUploadablePacket.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
 */
proto.packets_v1.FragmentUploadablePacket.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 uploaded_at = 4;
 * @return {number}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.getUploadedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
 */
proto.packets_v1.FragmentUploadablePacket.prototype.setUploadedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 upload_percentage = 5;
 * @return {number}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.getUploadPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
 */
proto.packets_v1.FragmentUploadablePacket.prototype.setUploadPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 processed_at = 6;
 * @return {number}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.getProcessedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
 */
proto.packets_v1.FragmentUploadablePacket.prototype.setProcessedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional FragmentUploadablePacketData data = 7;
 * @return {?proto.packets_v1.FragmentUploadablePacketData}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.getData = function() {
  return /** @type{?proto.packets_v1.FragmentUploadablePacketData} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.FragmentUploadablePacketData, 7));
};


/**
 * @param {?proto.packets_v1.FragmentUploadablePacketData|undefined} value
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
*/
proto.packets_v1.FragmentUploadablePacket.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
 */
proto.packets_v1.FragmentUploadablePacket.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.hasData = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional FragmentUploadablePacketMeta meta = 8;
 * @return {?proto.packets_v1.FragmentUploadablePacketMeta}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.getMeta = function() {
  return /** @type{?proto.packets_v1.FragmentUploadablePacketMeta} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.FragmentUploadablePacketMeta, 8));
};


/**
 * @param {?proto.packets_v1.FragmentUploadablePacketMeta|undefined} value
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
*/
proto.packets_v1.FragmentUploadablePacket.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
 */
proto.packets_v1.FragmentUploadablePacket.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 last_modified_at = 9;
 * @return {number}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.getLastModifiedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
 */
proto.packets_v1.FragmentUploadablePacket.prototype.setLastModifiedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 created_at = 10;
 * @return {number}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
 */
proto.packets_v1.FragmentUploadablePacket.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 clouded_at = 11;
 * @return {number}
 */
proto.packets_v1.FragmentUploadablePacket.prototype.getCloudedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.FragmentUploadablePacket} returns this
 */
proto.packets_v1.FragmentUploadablePacket.prototype.setCloudedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


goog.object.extend(exports, proto.packets_v1);
