import { Themes } from '@brand/colors';
import { Transitions } from '@brand/transitions';
import { ButtonColor } from '@comps/interactive/button';
import { NavigationButton } from '@comps/interactive/navigationButton';
import { Icon } from '@comps/static/icon';
import { Text } from '@comps/typography/text';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import styled from 'styled-components';

export const SidebarLink: React.FC<{
	to?: string
	icon?: React.ComponentProps<typeof Icon>['type']
	text?: string
	color?: ButtonColor
	larger?: boolean
	newTab?: boolean
	keepSelection?: boolean
	onClick?: () => void
	children?: React.ReactNode
}> = ({ to, icon, newTab, text, color, onClick, larger, children }) => {
	const variants = useMemo(() => {
		return {
			dark: { color: Themes.light.text.accentSemi.r.hex },
			light: { color: Themes.light.text.accentSemi.r.hex },
		}
	}, [])

	const contents = useMemo(() => {
		return children ? (
			<>{children}</>
		) : (
			<>
				{icon && (
					<IconHolder>
						<ColorChanger variants={variants} transition={Transitions.smooth}>
							<Icon type={icon} />
						</ColorChanger>
					</IconHolder>
				)}
				<TextHolder>
					<Text level="label" block>
						{text}
					</Text>
				</TextHolder>
			</>
		)
	}, [children, text, icon, variants])

	if (to)
		return (
			<NavigationButton to={to} color={color} larger={larger} keepSelection newTab={newTab}>
				{contents}
			</NavigationButton>
		)
	return (
		<NavigationButton onClick={onClick} color={color} larger={larger} keepSelection newTab={newTab}>
			{contents}
		</NavigationButton>
	)
}

const TextHolder = styled.div`
	position: relative;
	top: 1px;
`

const ColorChanger = styled(motion.div)``

const IconHolder = styled.div`
	width: 14px;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;	
	margin-right: 10px;
	${(p) => p.theme.text.action.r.css('color')};
	position: relative;
	top: 2px;
`
