import { ActivityColorKey, ActivityColors } from '@root/store/slices/activities.colors.types';
import { Nuance } from '@root/styled';
import { ActivityBubbleSize, ActivityColorState } from '@store/slices/activitiesTypes';
import React from 'react';
import styled, { css } from 'styled-components';

export const ActivityColorBubble: React.FC<{
	size: ActivityBubbleSize | 'tiny'
	color: ActivityColorKey
	status?: ActivityColorState
	nuanceAttr?: keyof Nuance
}> = ({ color, size = 'small', status = 'active', nuanceAttr }) => {
	return <Color activityColor={color} size={size} status={status} className="color-bubble activity-bubble" nuanceAttr={nuanceAttr} />
}

export const ActivityBubbleDimensions: {
	[key in ActivityBubbleSize]: number
} = {
	tiny: 24,
	small: 36,
	medium: 42,
	large: 76,
}

export const getActivityBubbleSizeCSS = (size: ActivityBubbleSize) => {
	switch (size) {
		case 'tiny':
			return css`
				width: ${ActivityBubbleDimensions.tiny}px;
				height: ${ActivityBubbleDimensions.tiny}px;
				position: relative;
				top: 0.5px;
			`
		case 'small':
			return css`
				width: ${ActivityBubbleDimensions.small}px;
				height: ${ActivityBubbleDimensions.small}px;
			`
		case 'medium':
			return css`
				width: ${ActivityBubbleDimensions.medium}px;
				height: ${ActivityBubbleDimensions.medium}px;
			`
		case 'large':
			return css`
				width: ${ActivityBubbleDimensions.large}px;
				height: ${ActivityBubbleDimensions.large}px;
			`
	}
}

const Color = styled.div<{
	activityColor: ActivityColorKey
	size: ActivityBubbleSize
	status: ActivityColorState
	nuanceAttr?: keyof Nuance
}>`
	border-radius: 999px;
	${({ size }) => getActivityBubbleSizeCSS(size)}
	${({ activityColor, status, nuanceAttr }) => ActivityColors[activityColor]?.[status].background[nuanceAttr || 'r'].css('background-color')};

	box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.05), inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1);
`
