import { differenceInSeconds } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { Spread } from '@comps/layout/spread';
import { useTime } from '@root/hooks/useTime';
import { getDuration } from '@root/utils/dates';

import { DynamicIcon } from './dynamicIcon';

export const TimeLeftTimer: React.FC<{
	til: Date
	since: Date
	after: string
	skipSeconds?: boolean
}> = ({ til, after, since, skipSeconds }) => {
	const [duration, setDuration] = useState<string>('-')
	const [progress, setProgress] = useState<number>()
	const now = useTime('minute')

	useEffect(() => {
		const diff = differenceInSeconds(til, now)
		const total = differenceInSeconds(til, since)

		const p = Math.floor((diff / total) * 100) / 100
		setProgress(p)

		if (diff > 0) {
			setDuration(getDuration(Math.abs(diff), skipSeconds ? ['seconds'] : undefined))
		} else if (after) {
			setDuration(after)
		}
	}, [setDuration, after, since, skipSeconds, til, now])

	return (
		<Spread items={2} spacing={6}>
			{duration == after ? (
				<span>{duration}</span>
			) : (
				<>
					<span>{duration}</span>
					<DynamicIcon type="clepsydre" progress={progress || 0} />
				</>
			)}
		</Spread>
	)
}
