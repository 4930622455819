import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { CardPosition } from '@root/components/complex/cards/grid.utils'
import { CardLayoutObject } from '@root/store/slices/cards.types'
import React, { useCallback } from 'react'
import { fill } from './cardGrid.commons'
import { CardDatasource, CardID, CardSize, Matrix } from './cardGrid.types'
import { findOptimalSpot } from './cardGrid.useAddCardRadialFinding'

const findNextVerticalPosition = (cards: CardDatasource[]): number => {
	let verticalPos = 10000
	let maxPos = 0
	cards.forEach(c => {
		if (c.verticalLayout > maxPos) {
			maxPos = c.verticalLayout
		}
	})
	if (maxPos != 0) {
		verticalPos = maxPos + 10000
	}

	return verticalPos
}

const findOpenSpace = (m: Matrix, cardSize: CardSize, id: CardID): undefined | CardPosition => {
	for (let i = 0; i < m.length; i++) {
		for (let j = 0; j < m[0].length; j++) {
			if (m[i][j]) {
				continue
			}
			if (m[i][j] === id) {
				continue
				// return undefined
			}
			let allGood = true
			for (let k = i; k < i + cardSize.h; k++) {
				if (k > m.length - 1) {
					allGood = false
					break
				}
				for (let l = j; l < j + cardSize.w; l++) {
					if (l > m[0].length - 1) {
						allGood = false
						break
					}
					if (m[k][l]) {
						allGood = false
					}
				}
			}

			if (allGood) {
				return {
					y: i,
					x: j,
				}
			}
		}
	}
	return undefined
}

const doesCardAlreadyExist = (m: Matrix, cardId: CardID) => {
	for (let i = 0; i < m.length; i++) {
		for (let j = 0; j < m[0].length; j++) {
			if (m[i][j] === cardId) {
				return true
			}
		}
	}
	return false
}

export type ReturnCardLayouts = (cardId: CardID, cardSize: CardSize, cardPosition?: CardPosition, aroundRect?: CardLayoutObject) =>
	{ layout: CardLayoutObject } | null
// remove this
export const useCardGridAddCard = (m: React.MutableRefObject<Matrix>, cards: CardDatasource[]) => {
	const placeCardInMatrix = useCallback<ReturnCardLayouts>(
		(cardId, cardSize, cardPosition, aroundRect) => {
			// printMatrix(m.current)
			if (doesCardAlreadyExist(m.current, cardId)) {
				return null
			}
			let pos = cardPosition

			if (cardPosition == undefined) {
				if (aroundRect) {
					pos = findOptimalSpot(m.current, cardSize, aroundRect)
				} else {
					pos = findOpenSpace(m.current, cardSize, cardId)
				}
			}
			// const verticalPos = findNextVerticalPosition(cards)
			if (pos) {
				if (pos.x === undefined && pos.y === undefined) throw new Error('No space')
				const layout: CardLayoutObject = {
					...pos,
					...cardSize,
				}
				m.current = fill(m.current, layout, cardId)
				return { layout }
			}
			return null
		},
		[m]
	)

	return { placeCardInMatrix }
}

export function getSizeForCardType(cardType?: CardType): CardSize {
	switch (cardType) {
		case CardType.LINK:
			return { w: 3, h: 1 }
		default:
			return { w: 4, h: 4 }
	}
}

export type PlanNewCardSignature = (cardType: CardType, cardSize?: CardSize) => { layout: CardLayoutObject, verticalPosition: number }
export const useCardPlanner = (m: React.MutableRefObject<Matrix>, cards: CardDatasource[]) => {
	const planNewCard = useCallback<PlanNewCardSignature>((cardType, cardSize) => {
		const size = getSizeForCardType(cardType)
		const h = cardSize?.h || size.h
		const w = cardSize?.w || size.w
		const pos = findOpenSpace(
			m.current,
			{
				w,
				h,
			},
			'ANY'
		)

		const verticalPos = findNextVerticalPosition(cards)
		if (!pos) throw new Error('Failed to add card')

		return {
			layout: {
				...{ w, h },
				...pos,
			},
			verticalPosition: verticalPos
		}
	},
		[m, cards]
	)

	return { planNewCard }
}
