import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { UserProfile } from '@clepside/clepsidejs/lib/entities/user_profiles_v1_pb';
import { RootState } from '@store/index';

import { Collection } from '../commonTypes';
import { SharingInvitationData } from '../slices/sharing.types';

export const useActivitiesSharing = (
	activityId?: string
): {
	invitations: Collection<SharingInvitationData>
} => {
	const invitations = useSelector((state: RootState) => {
		return activityId ? state.sharing.forActivity?.[activityId] : undefined
	})

	return useMemo(() => {
		return {
			invitations: invitations || { all: [], at: {} },
		}
	}, [invitations])
}

export const useActivityCollaborators = (
	activityId?: string
): {
	collaborators: Collection<{
		invitation: SharingInvitationData
		profile: UserProfile.AsObject
	}>
} => {
	const invitations = useSelector((state: RootState) => {
		return activityId ? state.sharing.forActivity?.[activityId] : undefined
	})
	const profiles = useSelector((state: RootState) => {
		return state.userProfiles.profiles
	})

	return useMemo(() => {
		const acc: Collection<{
			invitation: SharingInvitationData
			profile: UserProfile.AsObject
		}> = { at: {}, all: [] }
		const allInvitations = invitations?.all || []

		for (const invitationId of allInvitations) {
			const invitation = invitations?.at[invitationId]
			if (!invitation) continue

			if (invitation.acceptedAt && !invitation.revokedAt && !invitation.rejectedAt) {
				acc.all.push(invitation.forUserId)
				acc.at[invitation.forUserId] = {
					invitation: invitation,
					profile: profiles[invitation.forUserId],
				}
			}
		}

		return {
			collaborators: acc,
		}
	}, [invitations, profiles])
}
