import CJSCore from '@clepside/clepsidejs/lib/commons/core_pb'
import { RoutinePacket, RoutinePacketData, RoutinePacketMeta } from '@clepside/clepsidejs/lib/packets_v1/routine_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { RoutinePacketObject } from './sync.tsx.packets.types'

export function constructRoutinePacket(readyToSendPacket: Packet, fragPacket: RoutinePacketObject) {
    const routinePacket = new RoutinePacket()
    const { data, deleted, id, type, meta } = fragPacket
    if (!data) throw new Error('Packet data is undefined')
    
    // const { cardId, fileInfo } = data
    
    routinePacket.setId(id)

    if (!type) {
        throw new Error('packet type not found')
    }

    if (deleted) {
        const deletedMeta = new CJSCore.DeleteMeta()
        deletedMeta.setAt(deleted.at)
        deletedMeta.setBy(deleted.by)
        deletedMeta.setIs(deleted.is)
        routinePacket.setDeleted(deletedMeta)
    }
    if (data) {
        const newData = new RoutinePacketData()
        const newMeta = new RoutinePacketMeta()

    //     if (cardId) { 
    //         newData.setCardId(cardId)
    //     }

    //     if (fileInfo && meta.fileInfo) { 
    //         const info = new FileInfo()
    //         info.setId(fileInfo.id)
    //         info.setType(fileInfo.type)

    //         newData.setFileInfo(info) 
    //         newMeta.setFileInfo(generatePacketMeta(fragPacket, 'fileInfo'))
    //     }

        routinePacket.setData(newData)
        routinePacket.setMeta(newMeta)
        routinePacket.setType(type)
    }

    readyToSendPacket.setRoutine(routinePacket)
}