import { interfaceActions } from '@slices/interface'
import { ViewID } from '@slices/interface.types'
import React, { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

export const KeyScope: React.FC<{
	scope?: ViewID
	unmountScope?: ViewID
}> = ({ scope, unmountScope }) => {
	const dispatch = useDispatch()

	useLayoutEffect(() => {
		if (scope) {
			dispatch(interfaceActions.setScope({ scope: scope }))
			return () => {
				if (unmountScope) {
					dispatch(interfaceActions.setScope({ scope: unmountScope }))
				}
			}
		}
		return () => {
			if (unmountScope) {
				dispatch(interfaceActions.setScope({ scope: unmountScope }))
			}
		}
	}, [dispatch, scope, unmountScope])

	return null
}
