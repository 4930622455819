import { useCachedSelector } from '@root/hooks/useCachedSelector'
import { RootState } from '@root/store'
import { FlowID, FlowsArchitecture } from '@root/store/slices/flows.types'
import { last } from 'lodash'
import { ReactNode, useMemo } from 'react'

export type HorizontalScrollerPhaseDetails = {
	key: (typeof FlowsArchitecture)[FlowID][0]
	node: ReactNode
	rightHeaderAction?: ReactNode
	title: string
	width: number
	height: number
}

export const useFlowPhase = (flowId: FlowID, phases: HorizontalScrollerPhaseDetails[]) => {
	const activeFlow = useCachedSelector((state: RootState) => state.flows.flowContexts[flowId])
	const activeViewId = last(activeFlow?.navigationStack)

	const viewIndex = useMemo(() => {
		const index = phases.findIndex((p) => p.key == last(activeFlow?.navigationStack))
		return index >= 0 ? index : 0
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phases, activeFlow])

	return useMemo(
		() => ({
			phaseContext: activeFlow,
			activeViewId: activeViewId,
			navigationStack: activeFlow?.navigationStack || [],
			viewIndex,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activeFlow, viewIndex]
	)
}
