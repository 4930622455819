import { AnimatePresence } from 'framer-motion'
import React from 'react'

import { SessionBlockFin } from './sessionBlock.fin'

export const SessionBlockFins: React.FC<{
	resizingDirection?: 'end' | 'start'
	isSelected?: boolean
	onResizingStarted: (e: any, dir: 'top' | 'bottom') => void
}> = ({ resizingDirection, isSelected, onResizingStarted }) => {
	return (
		<AnimatePresence>
			{(resizingDirection === 'start' || resizingDirection === undefined) && isSelected ? (
				<SessionBlockFin direction="top" key="top" onResizingStarted={onResizingStarted} />
			) : null}
			{(resizingDirection === 'end' || resizingDirection === undefined) && isSelected ? (
				<SessionBlockFin direction="bottom" key="bottom" onResizingStarted={onResizingStarted} />
			) : null}
		</AnimatePresence>
	)
}
