import { BoyPanel } from '@comps/boy/boyPanel'
import { ErrorToastsStack } from '@comps/complex/errorToastsStack'
import { SmartThemeProvider } from '@comps/complex/smartThemeProvider'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled, { createGlobalStyle } from 'styled-components'
import { AppTunnel } from './App.tunnel'
import { authActions } from './store/slices/auth'

const App: React.FC = () => {
	const dispatch = useDispatch()
	const mounted = useRef(false)

	useEffect(() => {
		if (mounted.current == true) {
			return
		}
		dispatch(authActions.startWatcher())
		mounted.current = true
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<SmartThemeProvider key="logged-in">
			<Container>
				<GlobalStyles />
				<ErrorToastsStack />
				<AppTunnel />
				{process.env.ENV == 'development' ? <BoyPanel /> : null}
			</Container>
		</SmartThemeProvider>
	)
}

const GlobalStyles = createGlobalStyle`
  *::-webkit-scrollbar {
    display: none
  }
`

const Container = styled.div`
	display: contents;
	${(p) => p.theme.text.regular.r.css('color')};
`
export default App
