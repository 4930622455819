import { useEffect } from 'react'

export function useOnClickOutside(ref: any, outside?: any) {
	useEffect(() => {
		const listener = (event: any) => {
			// Do nothing if clicking ref's element or descendent elements

			if (
				!ref ||
				ref.contains(event.target) ||
				event.target.getAttribute('data-sid') ||
				event.target.getAttribute('data-session-viewbox') ||
				event.target.closest('[data-session-viewbox]')
			) {
				return
			}

			outside?.(event)
		}

		document.addEventListener('mousedown', listener)
		return () => {
			document.removeEventListener('mousedown', listener)
		}
	}, [ref, outside])
}
