import { Icon } from '@comps/static/icon'
import { InstancedSessionData } from '@store/slices/sessionsTypes'
import { addDays, addSeconds, differenceInSeconds, eachDayOfInterval, endOfDay, format, isSameYear, isValid, parse, startOfDay } from 'date-fns'

const indexFormat = (d: Date) => parseInt(format(d, 'yyyyMMdd'))
export const getDateFromIndex = (index: number): Date | undefined => {
	const date = parse(`${index}`, 'yyyyMMdd', startOfDay(new Date()))
	if (isValid(date)) return date
	return undefined
}

export const getDayIndex = (date: Date) => {
	return indexFormat(date)
}

export function secondsPerDateInRange(startDate: Date, endDate: Date): Record<string, number> {
	const days = eachDayOfInterval({ start: startDate, end: endDate })
	const results: Record<string, number> = {}

	if (days.length > 1) {
		days.forEach((day, index) => {
			if (index === 0) {
				// If it's the first date
				results[day.toISOString()] = differenceInSeconds(endOfDay(day), startDate)
			} else if (index === days.length - 1) {
				// If it's the last date
				results[day.toISOString()] = differenceInSeconds(endDate, day)
			} else {
				// For all other dates in between
				results[day.toISOString()] = 86400
			}
		})
	} else if (days.length == 1) {
		const day = days[0]
		results[day.toISOString()] = differenceInSeconds(endDate, startDate)
	}

	return results
}

export const getDaySpan = (start: Date, end?: Date) => {
	const s = indexFormat(start)
	if (!end) return [s]
	const e = indexFormat(end)
	if (s === e) return [s]
	const arr = []
	for (let i = s; i < e; i++) arr.push(i)
	return [...arr, e]
}
export const generateDayIndexes = (start: Date, end?: Date) => getDaySpan(start, end)

export const getMinutesIndex = (date: Date) => {
	return Math.floor(Math.floor(date.getTime() / 1000) / 60)
}

export const getSecondsIndex = (date: Date) => {
	return Math.floor(date.getTime() / 1000)
}

export const formatDate = (date: Date) => {
	if (isSameYear(date, new Date())) return format(date, 'd MMMM')
	return format(date, 'd MMMM, yyyy')
}

export const getUTCDate = () => {
	const now = new Date()
	return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));
}

export const getSessionPeriod = (start: Date, end: Date, withDuration?: boolean) => {
	return <span>
		<span>{format(start, 'HH:mm')}</span>&nbsp;<Icon type="session-arrow"/>&nbsp;
		<span>{format(end, 'HH:mm')}</span>
		<span>{withDuration ? `• ${getDuration(differenceInSeconds(end, start))}` : ''}</span>
	</span>
}

export const getSessionDuration = (session: InstancedSessionData) => {
	return `${getDuration(differenceInSeconds(session.end, session.start))}`
}

export const getSessionCompletion = (session: InstancedSessionData) => {
	const start = session.start.getTime()
	const end = start - session.end.getTime()
	const now = new Date().getTime()

	if (now < start) return '0%'
	if (now > end) return '100%'
	return `${(now * 100) / end}%`
}

export const getUTCTimeFromUnix = (unix: number) => {
	const d = new Date(unix * 1000)
	const h = d.getUTCHours()
	const m = d.getUTCMinutes()

	let hh = `${h}`
	let mm = `${m}`

	if (h < 10) {
		hh = `0${hh}`
	}
	if (m < 10) {
		mm = `0${mm}`
	}

	return `${hh}:${mm}`
}

export const getDuration = (secs: number, skip?: string[]) => {
	const days = Math.floor(secs / (3600 * 24))
	let hours = Math.floor((secs % (3600 * 24)) / 3600)
	const minutes = Math.floor((secs % 3600) / 60)
	const seconds = secs % 60

	const prefixes = {
		h: {
			short: 'h',
			long: {
				singular: 'hour',
				plural: 'hours',
			},
		},
		d: {
			short: 'd',
			long: {
				singular: 'day',
				plural: 'days',
			},
		},
		m: {
			short: 'm',
			long: {
				singular: 'minute',
				plural: 'minutes',
			},
		},
		s: {
			short: 's',
			long: {
				singular: 'second',
				plural: 'seconds',
			},
		},
	}

	const withSuffix = (amount: number, type: 'h' | 'm' | 's' | 'd') => {
		if (!amount) return ''
		if (type === 's' && skip?.includes('seconds')) return ''
		return `${amount}${prefixes[type].short}`
	}
	const shouldSkipSeconds = skip?.includes('seconds')

	if (skip?.includes('days')) {
		hours = Math.floor(secs / 3600)
		return `${withSuffix(hours, 'h')} ${withSuffix(minutes, 'm')}${shouldSkipSeconds ? '' : ' ' + withSuffix(seconds, 's')} `
	} else {
		return `${withSuffix(days, 'd')} ${withSuffix(hours, 'h')} ${withSuffix(minutes, 'm')}${
			shouldSkipSeconds ? '' : ' ' + withSuffix(seconds, 's')
		} `
	}
}

export type Period = [Date, Date]

export function roundToNearestMinuteInUTCs(date: Date, interval: number) {
	const roundedMinutes = Math.floor(date.getMinutes() / interval) * interval
	const d = new Date(date)
	d.setUTCMinutes(roundedMinutes)
	return d
}


type Units = 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds';

export function formattedDuration(totalSeconds: number, abbreviated = true, skip: Units[] = [], round = false): string {
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const totalHours = Math.floor(totalMinutes / 60);
    const hours = totalHours % 24;
    const totalDays = Math.floor(totalHours / 24);
    const days = totalDays % 7;
    const weeks = Math.floor(totalDays / 7);
    const months = Math.floor(totalDays / 30);

    const parts: string[] = [];
    const dynamicSkip: Units[] = [...skip];

    if (round) {
        if (abbreviated) {
            if (months > 0) {
                dynamicSkip.push('days', 'hours', 'minutes', 'seconds');
            } else if (days > 0) {
                dynamicSkip.push('minutes', 'seconds');
            } else if (hours > 0) {
                dynamicSkip.push('minutes', 'seconds');
            }
        } else {
            if (months > 0) {
                dynamicSkip.push('weeks', 'days', 'hours', 'minutes', 'seconds');
            } else if (days > 0) {
                dynamicSkip.push('weeks', 'hours', 'minutes', 'seconds');
            } else if (hours > 0) {
                dynamicSkip.push('minutes', 'seconds');
            }
        }
    }

    let k = 0;
    function appendPart(unitValue: number, unitKey: Units, singularName: string, pluralName: string, shortName: string) {
        if (unitValue > 0) {
            if (!dynamicSkip.includes(unitKey) || k === 0) {
                parts.push(abbreviated ? `${unitValue}${shortName}` : `${unitValue} ${unitValue === 1 ? singularName : pluralName}`);
            }
            k += 1;
        }
    }

    appendPart(months, 'months', 'month', 'months', 'mo');
    appendPart(weeks, 'weeks', 'week', 'weeks', 'w');
    appendPart(days, 'days', 'day', 'days', 'd');
    appendPart(hours, 'hours', 'hour', 'hours', 'h');
    appendPart(minutes, 'minutes', 'minute', 'minutes', 'm');
    appendPart(seconds, 'seconds', 'second', 'seconds', 's');

    return parts.join(abbreviated ? ' ' : ', ');
}

export function setHourMinuteSecond(currentDate: Date, totalSeconds: number): Date {
	const cd = startOfDay(currentDate)
	const days = Math.floor(totalSeconds / (3600 * 24));
    const restSeconds = totalSeconds % (3600 * 24);

    const dateWithDays = addDays(cd, days);
    
    // Add the remaining seconds to the adjusted date
    return addSeconds(dateWithDays, restSeconds);
}