import { Button } from '@comps/interactive/button'
import { Link } from '@comps/interactive/link'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { Spacer } from '@comps/layout/space'
import { Text } from '@comps/typography/text'
import { useUser } from '@selectors/useUser'
import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

export const SettingsSidebar = () => {
	const user = useUser()
	const [, setQp] = useSearchParams()

	const renderedButtons = useMemo(() => {
		const prefix = '/settings'
		// 'Plus',
		//'Profile', 'Appearence', 'Notifications',
		return ['Account', 'Invites'].map((k) => {
			// if (k === 'Plus')
			// 	return (
			// 		<Button
			// 			key={k}
			// 			color="textual"
			// 			grow
			// 			onClick={() => {
			// 				setQp({ plus: 'try' })
			// 			}}
			// 		>
			// 			<Inset padding="larger">Join Clepside+</Inset>
			// 		</Button>
			// 	)
			return (
				<Link to={`${prefix}/${k.toLowerCase()}`} key={k}>
					<Button color="textual" grow>
						<Inset padding="larger">{k}</Inset>
					</Button>
				</Link>
			)
		})
	}, [])

	return (
		<>
			<Text level="title70">Settings</Text>
			<Spacer vertical size={40} />
			<Flex direction="column" align="stretch" grow>
				{renderedButtons}
			</Flex>
		</>
	)
}

const Plus = styled.div`
	font-size: 9px;
	border-radius: 7px;
	padding: 0 5px;
	text-transform: uppercase;
	font-weight: 600;
	margin-left: 5px;
	${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
	${(p) => p.theme.text.action.r.css('color')};
`
