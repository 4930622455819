import { SelectedSession } from '@comps/complex/planning/selectedSession'
import { SelectionToast } from '@comps/complex/selectionToast'
import { DialogProvider } from '@comps/dialog'
import { HotkeyTipsOverlay } from '@comps/hotkeyTipsOverlay'
import { ScheduleRoutineModal } from '@comps/modals/scheduleRoutine'
import { Session } from '@root/views/Sessions.ID'
import { activitiesActions } from '@store/slices/activities'
import { Sidebar } from '@views/_sidebar'
import { Activities } from '@views/Activities'
import { Insights } from '@views/Insights'
import { Today } from '@views/Today'
import { endOfWeek, startOfWeek } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import React, { MouseEventHandler, useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { CanvasBackground } from './CanvasBackground'
import { Logger } from './cores/logger'
import { useDragAndDropContextMount } from './cores/useDragAndDropContextMount'
import { FlowsControllerBouncer } from './flows/FlowsControllerBouncer'
import { FlowsRouterHandler } from './flows/FlowsRouterHandler'
import { useTime } from './hooks/useTime'
import { useDynamicHotkeys } from './Router.useDynamicHotkeys'
import { useGlobalSearchHotkey } from './router.useGlobalSearchHotkey'
import { useSessionsSource } from './store/selectors/useSessionsSource'
import { useUser } from './store/selectors/useUser'
import { imageProcessingActions } from './store/slices/imageProcessing'
import { notificationsActions } from './store/slices/notifications'
import { persistActions } from './store/slices/persist'
import { routinesActions } from './store/slices/routines'
import { routinesSchedulesActions } from './store/slices/routineSchedules'
import { selectionActions } from './store/slices/selection'
import { SessionDisplayConditions } from './store/slices/sessionsTypes'
import { syncActions } from './store/slices/sync'
import { syncNotificationsActions } from './store/slices/sync.notifications'
import { timeActions } from './store/slices/time'
import { watchActions } from './store/slices/watch'
// import { ImagesWorkerProvider } from './useImagesWorker';
import { Activity } from './views/Activity.ID'
import { ActivityIDCards } from './views/Activity.ID.cards'
import { ActivityInsights } from './views/Activity.ID.Insights'
import { ActivityMore } from './views/Activity.ID.More'
import { ActivityRoutines } from './views/Activity.ID.Routines'
import { RoutineIdCards } from './views/Activity.ID.Routines.RID.Cards'
import { ActivitySessions } from './views/Activity.ID.Sessions'
import { Board } from './views/Board'
import { Boards } from './views/Boards'
import { Calendar } from './views/Calendar'
import { CalendarFullWeek } from './views/CalendarFullWeek'
import { SessionIDOutletActivity } from './views/Sessions.ID.OutletActivity'
import { SessionIDOutletSession } from './views/Sessions.ID.OutletSession'
import { Settings } from './views/Settings'
import { useCacheEngine } from './workers/cacheEngine'

const Boom = {
	blurStart: {
		x: 0,
		display: 'flex',
	},
	blurEnd: {
		x: 0,
		display: 'flex',
	},
}

const Wrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return (
		<Root key="same" variants={Boom} initial="blurStart" animate="blurEnd" exit="blurStart" transition={{ duration: 0.3 }}>
			<Sidebar key="bar" dark={location.pathname.includes('today')} />
			<RoutesHolder>
				<Outlet />
			</RoutesHolder>
			{/* <Cover
				variants={Boom}
				transition={{
					opacity: {
						duration: 5,
					},
				}}
			/> */}
		</Root>
	)
}
export const Router: React.FC = () => {
	const location = useLocation()
	const dispatch = useDispatch()
	const user = useUser()
	const [qp] = useSearchParams()
	// const { dragDropCounter } = useDragDropTooltip()
	const userRef = useRef<string | null>(null)
	const hasInitialised = useRef<boolean>(false)
	const time = useTime('date')

	// We fetch the sessions to make sure they are not removed when moving around
	const sessionsConfig = useMemo(() => {
		return {
			start: startOfWeek(time).getTime(),
			end: endOfWeek(time).getTime(),
			inclusive: 'both',
		} as SessionDisplayConditions
	}, [time])
	useSessionsSource(sessionsConfig, 'this-week')
	useGlobalSearchHotkey()
	useDynamicHotkeys()
	useDragAndDropContextMount()
	useCacheEngine()

	useEffect(() => {
		dispatch(routinesActions.loadAllToStore())
		dispatch(routinesSchedulesActions.loadAllToStore())
	}, [dispatch])

	useEffect(() => {
		if (!user?.uid) {
			userRef.current = null
			return
		}

		if (user.uid === userRef.current) {
			return
		}

		if (hasInitialised.current == false) {
			hasInitialised.current = true
			dispatch(timeActions.start())
			dispatch(activitiesActions.initialize())
			dispatch(notificationsActions.placeInStoreLatestNotifications())
		}

		if (user?.isFromLocal === false) {
			userRef.current = user.uid
			Logger.auth.debug('[Router] Received user details.')
			dispatch(watchActions.startWatching())
			dispatch(syncNotificationsActions.fetchNotifications())
			dispatch(syncActions.fetchUpdates())
			dispatch(persistActions.pendingPackets())
			// yield put(imageProcessingActions.processImages())
			dispatch(imageProcessingActions.processImages())
			dispatch(syncActions.loadActivitiesSyncStatus())
		}

		return () => {
			dispatch(syncActions.clearActivitiesSyncStatus())
			timeActions.end()
		}
	}, [dispatch, user])

	const mainRouterKey = useMemo(() => {
		const s: any = location.state
		if (location.pathname.includes('calendar/week')) return '1'
		if (location.pathname.includes('/activities/') && !location.pathname.includes('new') && !location.pathname.includes('edit')) return '1'
		if (location.pathname.includes('/session/')) {
			if (location.pathname.includes('/calendar/session/')) return '0'
			return '1'
		}
		if (location.pathname.includes('/board/')) return '1'
		return '0'
	}, [location])

	const previousRouterKey = useRef<any>()
	useEffect(() => {
		const win = window as any
		if (previousRouterKey.current !== mainRouterKey) {
			win.animateCards = 'please'
			previousRouterKey.current = mainRouterKey
		} else if (win.animateCards == 'done') {
			win.animateCards = 'no'
		}
	}, [mainRouterKey, location.pathname])

	const handleClickAnywhere = useCallback<MouseEventHandler<HTMLDivElement>>(
		(e) => {
			if ((e.target as any)?.closest?.('.noseldis')) return
			dispatch(selectionActions.reset())
		},
		[dispatch]
	)

	return (
		// <ImagesWorkerProvider>
		<React.Fragment>
			<DialogProvider>
				<AllRoutes key="main" onClick={handleClickAnywhere}>
					<CanvasBackground key="canvas" />
					<HotkeyTipsOverlay />
					<AnimatePresence>
						<Routes location={location} key={mainRouterKey}>
							<Route path="/activities/:id/*" element={<Activity />}>
								<Route path="cards" element={<ActivityIDCards />} />
								<Route path="insights" element={<ActivityInsights />} />
								<Route path="sessions" element={<ActivitySessions />} />
								<Route path="more" element={<ActivityMore />} />
								<Route path="routines" element={<ActivityRoutines />} />
								<Route path="*" element={<Navigate replace to="cards" />} />
							</Route>
							<Route path="/activities/:id/routines/:rid/cards" element={<RoutineIdCards />} />
							<Route path="/board/:id" element={<Board />} />
							<Route path="/calendar/week" element={<CalendarFullWeek />} />
							<Route path="/session/:id" element={<Session />}>
								<Route path="session" element={<SessionIDOutletSession />} />
								<Route path="activity" element={<SessionIDOutletActivity />} />
								<Route path="*" element={<Navigate replace to="session" />} />
								<Route path="" element={<Navigate replace to="session" />} />
							</Route>
							<Route element={<Wrapper />}>
								<Route path="/activities/*" element={<Activities />} />
								<Route path="/activity-brief/*" element={<Activities />} />
								<Route path="/calendar" element={<Calendar />} key="calendar">
									<Route path="session/:presentedSessionId" element={<SelectedSession key="session-id" />}>
										<Route path="session" element={<SessionIDOutletSession forceCardList />} />
										<Route path="activity" element={<SessionIDOutletActivity forceCardList />} />
										<Route path="*" element={<Navigate replace to={`session${location.search}`} />} />
										<Route path="" element={<Navigate replace to={`session${location.search}`} />} />
									</Route>
								</Route>
								<Route path="/insights/*" element={<Insights />} />
								<Route path="/settings/*" element={<Settings />} />
								<Route path="/boards/*" element={<Boards />} />
								<Route path="/today" element={<Today />} />
								<Route path="*" element={<Navigate replace to="/today" />} />
							</Route>
						</Routes>
					</AnimatePresence>
					<ScheduleRoutineModal />
					{qp.get('flow') && <FlowsRouterHandler />}
					{/* {qp.has('search') ? <SearchOverlay /> : null} */}
					{/* <BrowserToastPanel /> */}
					{/* {dragDropCounter} */}
					<SelectionToast />
				</AllRoutes>
				<FlowsControllerBouncer />
			</DialogProvider>
		</React.Fragment>
		// </ImagesWorkerProvider>
	)
}
// {
// 	/* <AnimatePresence>{qp.get('pick-date') && <PickDate />}</AnimatePresence>
// 				<AnimatePresence>{qp.get('notifications') && <Notifications />}</AnimatePresence> */
// }
// {
// 	/* <AnimatePresence>
// 					<Routes location={location} key={location.pathname}>
// 						<Route key="activities-edit" path="/activity-brief/" element={<ActivitiesNew />} />
// 						<Route key="activities-edit" path="/activity-brief/:id" element={<ActivitiesNew />} />
// 						<Route key="activities-edit" path="/activities/:id/share" element={<ActivityIDShareSheet />} />
// 						<Route key="boards-new" path="/boards/new" element={<BoardsNew />} />
// 						<Route key="boards-new" path="/boards/edit/:id" element={<BoardsNew />} />
// 					</Routes>
// 				</AnimatePresence>
// 					*/
// }
const AllRoutes = styled.div`
	display: contents;
`
const Root = styled(motion.div)`
	display: flex;
	align-items: stretch;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	position: relative;
	z-index: 1;
`

const RoutesHolder = styled.div`
	flex-grow: 1;
	display: flex;
`

// const Cover = styled(motion.div)`
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	background-color: rgba(229, 230, 232, 0.7);
// 	z-index: 10000;
// 	backdrop-filter: blur(35px);
// `
