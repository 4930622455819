import { useSelector } from 'react-redux';

import { RootState } from '@store/index';

export const useUser = () => {
	const user = useSelector((state: RootState) => {
		return state.auth.user
	})

	return user
}
