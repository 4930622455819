import { useMemo, useRef } from 'react';

import { FormState } from './useForm.types';

export type FormValues<K> = { [key in keyof K]: string }
export type FormValue<K> = {
	field: keyof K
	message: string
}
export type FormConfig<K> = {
	fields: {
		[key in keyof K]: {
			validate?: string
			defaultValue?: string
		}
	}
	onUpdate?: (s: FormState<K>) => void
	onSubmit?: (s: FormState<K>, values: FormValues<K>) => void
	// validate?: (values: FormValues) => FormValue | undefined
}

export function useFormConfig<R>(config: FormConfig<R>, deps: any[]) {
	const depsRef = useRef(deps)

	let hasChanged = false
	if (deps.length !== depsRef.current.length) {
		hasChanged = true
	} else {
		for (let i = 0; i < deps.length; i++) {
			if (deps[i] !== depsRef.current[i]) {
				hasChanged = true
				break
			}
		}
	}

	if (hasChanged) {
		depsRef.current = deps
	}

	return useMemo<FormConfig<R>>(() => {
		return {
			fields: config.fields,
			onUpdate: config.onUpdate,
			onSubmit: config.onSubmit,
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [depsRef.current])
}
