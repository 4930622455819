import { useSelector } from 'react-redux';

import { RootState } from '@root/store';

import { ActivateEmail } from './Account.EmailActivation';

export const ConditionallyActivateEmail: React.FC = () => {
	const shouldShow = useSelector((state: RootState) => state.interface.showEmailActivation)

	if (!shouldShow) return null

	return <ActivateEmail email={shouldShow} />
}
