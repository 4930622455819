import {
	FetchFavIconRequest,
	FetchFavIconResponse,
	GetCardFilePresignedDownloadURLRequest,
	GetCardFilePresignedDownloadURLResponse,
	ProcessCardFileUploadRequest,
	ProcessCardFileUploadResponse
} from '@clepside/clepsidejs/lib/services/filer_v1_pb'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Logger } from '@root/cores/logger'
import { Database, ImageProcessingPayload } from '@root/database'
import { FilerGrpc } from '@root/grpc/grpcFiler'
import { blobToUint8Array, cacheLocalImage, getImageBlob, getImageBlobFromCache, uint8ArrayToBlob, uploadImage } from '@root/workers/cacheEngine'
import { delay, put } from 'typed-redux-saga'
import { imagesActions } from './imagesStore'

const imageProcessingSlice = createSlice({
	name: 'imageProcessing',
	initialState: {},
	reducers: {
        processImages: (state, { payload }: PayloadAction) => {}
	},
})

export const imageProcessingSagas = {
    // sends queued packets to the server
	*processImages({ }: ReturnType<typeof imageProcessingSlice.actions.processImages>): any {
        let count = yield Database.imageProcessing.where('status')
            .equals('needs-upload').count()

        
        while (count > 0) {
            Logger.statics.info('Starting to upload images: ', count)
        
            const payloads: ImageProcessingPayload[] = yield Database.imageProcessing
                .where('status')
                .equals('needs-upload')
                .limit(10)
                .sortBy('sendAt')
            

            for (const payload of payloads) {
                if (new Date().getTime() < payload.sendAt) continue
                switch (payload.type.op) {
                    case 'upload-card-image':                        
                        const blob = yield getImageBlobFromCache(`/card-images/${payload.id}.jpg`)
                        if (!blob) continue

                        const req = new ProcessCardFileUploadRequest()
                        const uint8 = yield blobToUint8Array(blob)

                        req.setImageId(payload.id)
                        req.setImageData(uint8)
                        req.setFragmentId(payload.fragmentId)

                        try {
                            const response: ProcessCardFileUploadResponse = yield FilerGrpc.processCardFileUpload(req)
                            const uploadUrl = response.getUploadUrl()
                            const checksum = response.getChecksum()
                            Logger.statics.log('Uploaded successfully')

                            try {
                                yield uploadImage(uploadUrl, blob, checksum)
                                yield Database.transaction('rw', [Database.imageProcessing], async () => {
                                    const entry = await Database.imageProcessing.get(payload.id)
                                    if (entry) {
                                        if (entry?.queuedAt == payload.queuedAt) {
                                            await Database.imageProcessing.put({
                                                ...payload,
                                                status: 'fetched'
                                            }, payload.id)
                                        }
                                    }
                                })
                            } catch (e) {
                                throw e    
                            }
                        } catch (e) {
                            yield Database.transaction('rw', [Database.imageProcessing], async () => {
                                const entry = await Database.imageProcessing.get(payload.id)
                                if (entry) {
                                    await Database.imageProcessing.put({
                                        ...entry,
                                        sendAt: entry.sendAt + 60000
                                    }, payload.id)
                                }
                            })
                            if ((e as any).message == 'not-found') {
                                Database.imageProcessing.delete(payload.id)
                            }
                            console.log(e)
                        }

                        break
                    default: 
                        break
                }
            }
            
            count = yield Database.imageProcessing
                .where('status')
                .equals('needs-upload').count()
            
            yield delay(1000)
        }

        count = yield Database.imageProcessing.where('status')
            .equals('needs-fetching').count()

            
        while (count > 0) {
            Logger.statics.info('Starting to fetch images: ', count)
            const payloads: ImageProcessingPayload[] = yield Database.imageProcessing
                .where('status')
                .equals('needs-fetching')
                .limit(20)
                .sortBy('sendAt')

            for (const payload of payloads) {
                if (new Date().getTime() < payload.sendAt) continue
                switch (payload.type.op) {
                    case 'fetch-favicon':
                        Logger.statics.info('Fetching favicon')
                        console.log('fetching favicon')
                        const req = new FetchFavIconRequest()
                        req.setUrl(payload.id)
                        try {
                            const res = (yield FilerGrpc.fetchFavIcon(req) as any) as FetchFavIconResponse
                            const data = res.getImageData_asU8()
                            const blob = uint8ArrayToBlob(data, 'image/png')
                            yield cacheLocalImage(`/favicons/${payload.filepath}.png`, blob, 'favicons')

                            yield Database.transaction('rw', [Database.imageProcessing], async () => {
                                const entry = await Database.imageProcessing.get(payload.id)
                                if (entry) {
                                    if (entry?.queuedAt == payload.queuedAt) {
                                        await Database.imageProcessing.put({
                                            ...payload,
                                            status: 'fetched'
                                        }, payload.id)
                                    }
                                }
                            })

                            yield put(imagesActions.markImageAs({
                                imageId: `${payload.filepath}`,
                                status: 'fetched'
                            }))
                        } catch (e) {
                            // if ((e as any).message == 'forbidden') {
                            //     Database.imageProcessing.delete(payload.id)
                            // }
                            yield Database.transaction('rw', [Database.imageProcessing], async () => {
                                const entry = await Database.imageProcessing.get(payload.id)
                                if (entry) {
                                    await Database.imageProcessing.put({
                                        ...entry,
                                        sendAt: entry.sendAt + 60000
                                    }, payload.id)
                                }
                            })
                            if ((e as any).message == 'not-found') {
                                Database.imageProcessing.delete(payload.id)
                            }
                            console.log(e)
                            // console.error('processing failed to fetch favicon', e)
                        }
                        break;
                    case 'fetch-card-image':
                        Logger.statics.info('Fetching card image')
                        const cardReq = new GetCardFilePresignedDownloadURLRequest()
                        cardReq.setFragmentId(payload.fragmentId)
                        try {
                            const res = (yield FilerGrpc.getPresignedURL(cardReq) as any) as GetCardFilePresignedDownloadURLResponse
                            const url = res.getPresignedDownloadUrl()
                            const blob = yield getImageBlob(url)
                            // const blob = uint8ArrayToBlob(data, 'image/png')
                            yield cacheLocalImage(`/card-images/${payload.id}.jpg`, blob, 'card-images')

                            yield Database.transaction('rw', [Database.imageProcessing], async () => {
                                const entry = await Database.imageProcessing.get(payload.id)
                                if (entry) {
                                    if (entry?.queuedAt == payload.queuedAt) {
                                        await Database.imageProcessing.put({
                                            ...payload,
                                            status: 'fetched'
                                        }, payload.id)
                                    }
                                }
                            })

                            yield put(imagesActions.markImageAs({
                                imageId: payload.id,
                                status: 'fetched'
                            }))
                        } catch (e) {

                            yield Database.transaction('rw', [Database.imageProcessing], async () => {
                                const entry = await Database.imageProcessing.get(payload.id)
                                if (entry) {
                                    await Database.imageProcessing.put({
                                        ...entry,
                                        sendAt: entry.sendAt + 60000
                                    }, payload.id)
                                }
                            })
                            if ((e as any).message == 'not-found') {
                                Database.imageProcessing.delete(payload.id)
                            }
                            console.error('processing failed to fetch favicon', e)
                        }
                }
            }
            
            yield delay(500)
            count = yield Database.imageProcessing
                .where('status')
                .equals('needs-fetching').count()
        }
        yield delay(500)
	}
}
export const imageProcessingActions = imageProcessingSlice.actions
// export const aiChatCardReducers = aiChatCardSlice.reducer
