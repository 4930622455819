import { InsetSize, OutsetSize } from '@comps/layout/inset'
import { FontStyles } from '@comps/typography/text'
import { useRefTaker } from '@hooks/useRefTaker'
import { useDragAndDropHover } from '@root/cores/useDragAndDropHover'
import { FormField } from '@root/hooks/useForm.types'
import { isWeb } from '@utils/general'
import React, { MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'

export type ButtonColor =
	| 'accent'
	| 'clepside'
	| 'subtleAccent'
	| 'outlined'
	| 'floating'
	| 'subtle'
	| 'dark'
	| 'pro'
	| 'textual'
	| 'textualClepside'
	| 'subtleClepside'
	| 'textualSubtle'
	| 'textualAccent'
	| 'textual.onBackground.translucent'
	| 'translucent'
	| 'translucentPassingColor'
	| 'activable'
	| 'darkTranslucent'
	| 'lightTranslucent'
	| 'subtleClepside'
	| 'darkTranslucentElevated'
	| 'toastColored'
	| 'tooltip'
	| 'tooltipRaised'
	| 'toast'

export type ButtonProps = {
	children?: React.ReactNode
	color?: ButtonColor
	onClick?: MouseEventHandler<HTMLButtonElement>
	onMouseUp?: MouseEventHandler<HTMLButtonElement>
	noFocus?: boolean
	tabIndex?: number
	className?: string
	grow?: boolean
	cy?: string
	round?: number
	outset?: keyof typeof OutsetSize
	inset?: keyof typeof InsetSize
	minWidth?: string
	isActive?: boolean
	touchOffset?: number
	surrounded?: boolean
	keepSelection?: boolean
	supportsHoverDragAction?: boolean
}

export const ReffedButton: React.ForwardRefRenderFunction<FormField, ButtonProps> = (
	{
		children,
		touchOffset,
		color = 'subtle',
		round,
		keepSelection,
		onClick,
		onMouseUp,
		inset,
		noFocus,
		grow,
		isActive,
		outset,
		cy,
		surrounded,
		tabIndex,
		className,
		minWidth,
		supportsHoverDragAction,
		...otherProps
	},
	ref
) => {
	const [button, setButton] = useRefTaker()
	useDragAndDropHover(button, supportsHoverDragAction)

	React.useImperativeHandle(
		ref,
		() =>
		({
			focus() {
				button?.focus()
			},
		} as any),
		[button]
	)

	return (
		<ButtonLogic
			onClick={onClick}
			onMouseUp={onMouseUp}
			touchOffset={touchOffset}
			color={color}
			isActive={isActive}
			{...otherProps}
			className={`button-logic ${isActive ? 'active' : ''} ${keepSelection ? 'noseldis' : ''}`}
			round={round}
			surrounded={surrounded}
		>
			<ButtonFrame
				className={`button-frame ${className}`}
				ref={setButton}
				tabIndex={noFocus ? -1 : tabIndex || 0}
				grow={grow}
				round={round}
				outset={outset}
				inset={inset}
				data-cy={cy}
				minWidth={minWidth}
			>
				{children}
			</ButtonFrame>
		</ButtonLogic>
	)
}

export const Button = React.forwardRef(ReffedButton)

const borderOne = '1px'
const borderTwo = '2.5px'
export function ButtonStyles(type: ButtonColor): {
	normal: any
	hovered?: any
	active?: any
	focused?: any
} {
	switch (type) {
		case 'dark':
			return {
				normal: css`
					${(p) => p.theme.standards.white.r.css('color')};
					${(p) => p.theme.backgrounds.dark.r.css('background-color')};
					box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.33);
				`,
				hovered: css`
					${(p) => p.theme.standards.white.t1.css('color')};
					${(p) => p.theme.backgrounds.dark.t1.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.standards.white.t2.css('color')};
					${(p) => p.theme.backgrounds.dark.t2.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.dark.t1.hex} !important;
				`,
			}
		case 'tooltip':
			return {
				normal: css`
					color: rgba(255, 255, 255, 0.9);
				`,
				hovered: css`
					${(p) => p.theme.standards.white.r.css('color')};
					background-color: rgba(255, 255, 255, 0.1);
				`,
				active: css`
					${(p) => p.theme.standards.white.r.css('color')};
					background-color: rgba(255, 255, 255, 0.2);
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.dark.t1.hex} !important;
				`,
			}
		case 'tooltipRaised':
			return {
				normal: css`
					background-color: rgba(255, 255, 255, 0.075);
					color: #fff;
				`,
				hovered: css`
					${(p) => p.theme.standards.white.r.css('color')};
					background-color: rgba(255, 255, 255, 0.2);
				`,
				active: css`
					${(p) => p.theme.standards.white.r.css('color')};
					background-color: rgba(255, 255, 255, 0.35);
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.dark.t1.hex} !important;
				`,
			}
		case 'textual.onBackground.translucent':
			return {
				normal: css`
					${(p) => p.theme.text.regular.r.css('color')};
				`,
				hovered: css`
					${(p) => p.theme.text.regular.s1.css('color')};
					${(p) => p.theme.standards.shade.r.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.text.regular.s2.css('color')};
					${(p) => p.theme.standards.shade.s1.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.standards.shade.s2.hex} !important;
				`,
			}
		case 'textualAccent':
			return {
				normal: css`
					${(p) => p.theme.text.action.r.css('color')};
				`,
				hovered: css`
					${(p) => p.theme.text.action.r.css('color')};
					${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.text.action.r.css('color')};
					${(p) => p.theme.backgrounds.subtle.s1.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.subtle.r.hex} !important;
				`,
			}
		case 'textualSubtle':
			return {
				normal: css`
					${(p) => p.theme.text.subtle.r.css('color')};
				`,
				hovered: css`
					${(p) => p.theme.text.subtle.t2.css('color')};
				`,
				active: css`
					${(p) => p.theme.text.regular.r.css('color')};
					${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.text.subtle.t2.hex} !important;
				`,
			}
		case 'clepside':
			return {
				normal: css`
					${(p) => p.theme.text.clepside.r.css('color')};
					${(p) => p.theme.backgrounds.clepside.r.css('background-color')};
				`,
				hovered: css`
					${(p) => p.theme.text.clepside.r.css('color')};
					${(p) => p.theme.backgrounds.clepside.s1.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.text.clepside.r.css('color')};
					${(p) => p.theme.backgrounds.clepside.s2.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.clepside.r.hex} !important;
				`,
			}
		case 'pro':
			return {
				normal: css`
					background: white;
					background: linear-gradient(180deg, color(display-p3 0.450 0.529 1 / 1) 0%, color(display-p3 0.197 0.305 0.961 / 1) 100%);
					font-size: 15px;
					font-weight: 600;
					border-radius: 15px;
					min-height: 48px;
				`,
				hovered: css`
					background: white;
					background: linear-gradient(180deg, color(display-p3 0.307 0.400 0.961 / 1) 0%, color(display-p3 0.122 0.219 0.804 / 1) 100%);
				`,
				active: css`
					background: white;
					background: linear-gradient(180deg, color(display-p3 0.307 0.400 0.961 / 1) 0%, color(display-p3 0.122 0.219 0.804 / 1) 100%);
				`,
				focused: css`
					/* box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.clepside.r.hex} !important; */
				`,
			}
		case 'textualClepside':
			return {
				normal: css`
					${(p) => p.theme.backgrounds.clepside.r.css('color')};
					background-color: transparent;
				`,
				hovered: css`
					${(p) => p.theme.backgrounds.clepside.r.css('color')};
					${(p) => p.theme.backgrounds.subtleClepside.t2.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.backgrounds.clepside.r.css('color')};
					${(p) => p.theme.backgrounds.subtleClepside.t1.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.clepside.r.hex} !important;
				`,
			}
		case 'subtleClepside':
			return {
				normal: css`
					${(p) => p.theme.backgrounds.clepside.r.css('color')};
					${(p) => p.theme.backgrounds.subtleClepside.r.css('background-color')};
				`,
				hovered: css`
					${(p) => p.theme.backgrounds.clepside.r.css('color')};
					${(p) => p.theme.backgrounds.subtleClepside.t1.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.backgrounds.clepside.r.css('color')};
					${(p) => p.theme.backgrounds.subtleClepside.t2.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.clepside.r.hex} !important;
				`,
			}
		case 'textual':
			return {
				normal: css`
					${(p) => p.theme.text.regular.r.css('color')};
				`,
				hovered: css`
					${(p) => p.theme.text.regular.s1.css('color')};
					${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.text.regular.r.css('color')};
					${(p) => p.theme.backgrounds.subtle.s1.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.subtleAccent.r.hex} !important;
				`,
			}
		case 'toastColored':
			return {
				normal: css`
					color: #fff;
					${(p) => p.theme.toast.background.t2.css('background-color')};
				`,
				hovered: css`
					${(p) => p.theme.toast.background.t3.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.toast.background.t1.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px ${borderOne} ${(p) => p.theme.backgrounds.normal.r.hex},
						0px 0px 0px ${borderTwo} ${(p) => p.theme.backgrounds.subtle.r.hex} !important;
				`,
			}
		case 'toast':
			return {
				normal: css`
					${(p) => p.theme.toast.background.r.css('background-color')};
					${(p) => p.theme.toast.text.r.css('color')};
				`,
				active: css`
					${(p) => p.theme.toast.background.t1.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px ${borderOne} ${(p) => p.theme.backgrounds.normal.r.hex},
						0px 0px 0px ${borderTwo} ${(p) => p.theme.toast.background.r.hex} !important;
				`,
			}
		case 'subtle':
			return {
				normal: css`
					${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
					${(p) => p.theme.text.regular.r.css('color')};
				`,
				hovered: css`
					${(p) => p.theme.backgrounds.subtle.s1.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.subtle.r.hex};
				`,
			}
		case 'activable':
			return {
				normal: css`
					${(p) => p.theme.backgrounds.normal.r.css('background-color')};
					${(p) => p.theme.text.regular.r.css('color')};
				`,
				hovered: css`
					${(p) => p.theme.backgrounds.subtle.s1.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.text.regular.r.css('background-color')};
					${(p) => p.theme.text.clepside.r.css('color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.clepside.r.hex};
				`,
			}
		case 'subtleAccent':
			return {
				normal: css`
					${(p) => p.theme.backgrounds.subtleAccent.r.css('background-color')};
					${(p) => p.theme.backgrounds.accent.r.css('color')};
				`,
				hovered: css`
					${(p) => p.theme.backgrounds.subtleAccent.s1.css('background-color')};
					${(p) => p.theme.backgrounds.accent.r.css('color')};
				`,
				active: css`
					${(p) => p.theme.backgrounds.subtleAccent.r.css('background-color')};
					${(p) => p.theme.backgrounds.accent.r.css('color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.subtle.r.hex};
				`,
			}
		case 'accent':
			return {
				normal: css`
					${(p) => p.theme.backgrounds.accent.r.css('background-color')};
					${(p) => p.theme.standards.white.r.css('color')};
				`,
				hovered: css`
					${(p) => p.theme.backgrounds.accent.s1.css('background-color')};
					${(p) => p.theme.standards.white.r.css('color')};
				`,
				active: css`
					${(p) => p.theme.backgrounds.accent.s2.css('background-color')};
					${(p) => p.theme.standards.white.r.css('color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.accent.s1.hex} !important;
				`,
			}
		case 'translucent':
			return {
				normal: css`
					background-color: transparent;
					border-color: transparent;
					${(p) => p.theme.text.translucent.r.css('color')};
				`,
				hovered: css`
					background-color: rgba(10, 20, 40, 0.035);
				`,
				active: css`
					background-color: rgba(10, 20, 40, 0.083);
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.075) !important;
				`,
			}
		case 'translucentPassingColor':
			return {
				normal: css`
					background-color: transparent;
					border-color: transparent;
					color: currentColor;
				`,
				hovered: css``,
				active: css``,
				focused: css``,
			}
		case 'darkTranslucent':
			return {
				normal: css`
					background-color: transparent;
					border-color: transparent;
					${(p) => p.theme.text.darkTranslucency.r.css('color')};
				`,
				hovered: css`
					${(p) => p.theme.backgrounds.darkTranslucency.t1.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.backgrounds.darkTranslucency.t2.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2) !important;
				`,
			}
		case 'lightTranslucent':
			return {
				normal: css`
					background-color: transparent;
					border-color: transparent;
					${(p) => p.theme.text.regular.r.css('color')};
				`,
				hovered: css`
					background-color: rgba(10, 20, 30, 0.05);
				`,
				active: css`
					background-color: rgba(10, 20, 30, 0.1);
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px rgba(10, 20, 30, 0.03) !important;
				`,
			}
		case 'floating':
			return {
				normal: css`
					border-color: transparent;
					${(p) => p.theme.backgrounds.normal.r.css('background-color')};
					${(p) => p.theme.text.regular.r.css('color')};
					box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
				`,
				hovered: css`
					${(p) => p.theme.backgrounds.normal.s1.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.backgrounds.normal.s2.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.normal.s1.hex};
				`,
			}
		case 'darkTranslucentElevated':
			return {
				normal: css`
					border-color: transparent;

					${(p) => p.theme.backgrounds.darkTranslucencyElevated.r.css('background-color')};
					${(p) => p.theme.text.darkTranslucency.r.css('color')};
					box-shadow: 0 -1px 0px 0 rgba(255, 255, 255, 0);
				`,
				hovered: css`
					${(p) => p.theme.backgrounds.darkTranslucencyElevated.t2.css('background-color')};
				`,
				active: css`
					${(p) => p.theme.backgrounds.darkTranslucencyElevated.t3.css('background-color')};
				`,
				focused: css`
					box-shadow: 0px 0px 0px 1px ${(p) => p.theme.backgrounds.darkTranslucencyElevated.t1.hex};
				`,
			}
		case 'outlined':
			return {
				normal: css`
					background-color: #fff;
					${(p) => p.theme.text.subtle.r.css('color')};
					${(p) => p.theme.border.subtle.r.css('border', '1px solid')};
				`,
				hovered: css`
					${(p) => p.theme.text.regular.s1.css('color')};
					${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
					${(p) => p.theme.border.subtle.r.css('border', '1px solid')};
				`,
				active: css`
					${(p) => p.theme.text.regular.s2.css('color')};
					${(p) => p.theme.backgrounds.subtle.s1.css('background-color')};
					${(p) => p.theme.border.subtle.r.css('border', '1px solid')};
				`,
				focused: css`
					${(p) => p.theme.border.subtle.r.css('box-shadow', '0px 0px 0px 1px')};
				`,
			}
	}
}

export const ButtonFrame = styled.div<{
	grow?: boolean
	round?: number
	outset?: keyof typeof OutsetSize
	inset?: keyof typeof InsetSize
	minWidth?: string
}>`
	border-radius: 11px;
	cursor: default;
	display: flex;
	white-space: nowrap;
	align-items: center;
	transition: 0s ease-in-out background-color;
	padding: 0;
	border: none;
	background-color: transparent;
	text-decoration: none;
	position: relative;
	flex-shrink: 0;
	box-sizing: border-box;

	${FontStyles.body};

	${({ round }) =>
		round &&
		css`
			border-radius: 999px;
			width: ${round}px;
			height: ${round}px;
		`}

	${({ outset }) =>
		outset &&
		css`
			${OutsetSize[outset]}
			margin-top: 0;
			margin-bottom: 0;
		`}

	${({ inset }) =>
		inset &&
		css`
			${InsetSize[inset]}
		`}


	${({ grow }) =>
		grow &&
		css`
			flex-grow: 1;
		`}

	${({ minWidth }) => {
		return (
			minWidth &&
			css`
				min-width: ${minWidth};
			`
		)
	}}

	${isWeb &&
	css`
		cursor: pointer;
	`}

	* {
		color: inherit;
	}
`

export const ButtonLogic = styled.button<{
	surrounded?: boolean
	color: ButtonColor
	isActive?: boolean
	round?: number
	touchOffset?: number
}>`
	border: none;
	background: none;
	margin: 0;
	padding: 0;
	color: currentColor;

	&:focus {
		background: none;
	}
	display: contents;
	${(p) =>
		p.touchOffset &&
		css`
			padding: ${p.touchOffset}px;
			margin: -${p.touchOffset}px;
		`}

	${(p) =>
		p.round &&
		css`
			${ButtonFrame}:focus:before {
				border-radius: 9999px !important;
			}
		`}

	${({ color, isActive }) => {
		return css`
			${ButtonFrame} {
				${ButtonStyles(color).normal};
			}
			&:hover {
				${ButtonFrame} {
					${ButtonStyles(color).hovered};
				}
			}

			
			${ButtonFrame} {
				&:focus {
					${ButtonStyles(color).focused &&
			css`
							position: relative;

							&:before {
								position: absolute;
								top: -1.5px;
								left: -1.5px;
								width: calc(100% + 3px);
								height: calc(100% + 3px);
								content: '';
								display: block;
								border-radius: 12.5px;
								${ButtonStyles(color).focused};
							}
						`
			}
				}
			}


			&:active {
				${ButtonFrame} {
					${ButtonStyles(color).active};
				}

				&:hover {
					${ButtonFrame} {
						${ButtonStyles(color).active};
					}	
				}
			}

			${isActive &&
			css`
					${ButtonFrame} {
						${ButtonStyles(color).active};
					}

					&:hover {
						${ButtonFrame} {
							${ButtonStyles(color).active};
						}
					}
				`
			}
		}
		`
	}}

${({ surrounded }) =>
		surrounded &&
		css`
			margin-top: -1px;
			margin-bottom: -1px;
		`}
`
