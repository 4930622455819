import { Inset, InsetPaddingType } from '@comps/layout/inset';
import styled, { keyframes } from 'styled-components';

export const ButtonLoadingBar: React.FC<{
	padding: InsetPaddingType
}> = ({ padding }) => {
	return (
		<Holder>
			<Inset padding="none" full align="center" justify="center">
				<Bar>
					<Fill />
				</Bar>
			</Inset>
		</Holder>
	)
}

const Holder = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	box-sizing: border-box;
	z-index: 1000000000;
`

const Bar = styled.div`
	background-color: rgba(255, 255, 255, 0.3);
	width: 100%;
	height: 100%;
	max-height: 3px;
	max-width: 70px;
	border-radius: 4px;
	overflow: hidden;
	flex-grow: 1;
	flex-shrink: 0;
	position: relative;
`

const shakeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
`

const Fill = styled.div`
	background-color: #fff;
	width: 33.333333%;
	height: 100%;
	border-radius: 4px;
	transform: translateX(200%);
	animation: ${shakeAnimation} 0.8s ease-in-out both infinite;
	animation-direction: alternate;
`
