export const UtilsListsGetIndexInBounds = {
	goingUp: (count: number, i?: number) => {
		if (i === undefined) return count ? count - 1 : undefined
		const newI = i - 1 < 0 ? undefined : i - 1
		return newI
	},
	goingDown: (count: number, i?: number) => {
		if (i === undefined) return count ? 0 : undefined
		const newI = i + 1 >= count ? undefined : i + 1
		return newI
	},
}
