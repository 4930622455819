import initializeFirebase from './firebase'

import { store } from '@store/index'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { isElementInputable } from './utils/general'

const domNode = document.getElementById('root')
const root = createRoot(domNode as any)

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SKIP_THIS_LINE = initializeFirebase

// --- Get rid of the Firestore emulator line
const range = document.createRange() as any
const frag = range.createContextualFragment(
	`
  <style>
  .firebase-emulator-warning {
	display: none;
}
  </style>
  `
)
	; (document as any).querySelector('head').append(frag)
	; (document as any).onselectstart = function (event: any) {
		if (isElementInputable(event.target)) {
			event.stopPropagation()
			return true
		}

		return false // Prevent selection
	}


if ('serviceWorker' in navigator) {
	window.addEventListener('load', function () {
		navigator.serviceWorker.register('/cacheEngine.worker.js')
			.then(function (registration) {
			}, function (err) {
				console.log('Service Worker registration failed:', err);
			});
	});
}