import { chiselTheme } from '@root/brand/colors';
import { Nuance, PreNuance } from '@root/styled';

export type ActivityColorType<T> = {
	name: string
	active: {
		background: T
		text: T
	}
	inactiveLight: {
		background: T
		text: T
	}
	inactiveDark: {
		background: T
		text: T
	}
	tags: string[]
}

export const RawActivityColors = {
	cherryVenom: {
		name: 'Cherry Venom',
		active: {
			background: {
				color: 'color(display-p3 0.92 0 0)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 1 1 1)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 1 0.88 0.88)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0.73 0 0)'
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
	pumpkinSunrise: {
		name: 'Pumpkin Sunrise',
		active: {
			background: {
				color: 'color(display-p3 1 0.6 0)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 1 1 1)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 1 0.88 0.8 / 1)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0.68 0.41 0)'
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
	cornfieldDream: {
		name: 'Cornfield Dream',
		active: {
			background: {
				color: 'color(display-p3 1 0.92 0)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0.59 0.56 0)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 1 0.98 0.88 / 1)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0.59 0.56 0)'
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
	meadowSparkle: {
		name: 'Meadow Sparkle',
		active: {
			background: {
				color: 'color(display-p3 0.68 1 0.22)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0.33 0.57 0)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 0.91 1 0.88 / 1)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0.33 0.57 0)'
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
	emeraldWhirl: {
		name: 'Emerald Whirl',
		active: {
			background: {
				color: 'color(display-p3 0 1 0.35)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 1 1 1)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 0.81 1 0.88)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0 0.53 0.19)'
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
	fernShadows: {
		name: 'Fern Shadows',
		active: {
			background: {
				color: 'color(display-p3 0.05 0.68 0.08)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 1 1 1)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 0.88 0.94 0.89)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0.02 0.45 0.04)'
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
	lagoonFantasy: {
		name: 'Lagoon Fantasy',
		active: {
			background: {
				color: 'color(display-p3 0.4 1 0.87)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0 0.45 0.35)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 0.88 1 0.97)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0 0.45 0.35)'
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
	oceanGlass: {
		name: 'Ocean Glass',
		active: {
			background: {
				color: 'color(display-p3 0.22 0.87 0.99)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 1 1 1)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 0.88 0.97 1 / 1)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0 0.39 0.46)',
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
	harborSky: {
		name: 'Harbor Sky',
		active: {
			background: {
				color: 'color(display-p3 0 0.65 1)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 1 1 1)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 0.88 0.96 1 / 1)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0 0.32 0.49)'
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
	denimBlueberry: {
		name: 'Denim Blueberry',
		active: {
			background: {
				color: 'color(display-p3 0 0.32 1)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 1 1 1)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 0.88 0.92 1)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0 0.13 0.41)'
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
	duskySapphire: {
		name: 'Dusky Sapphire',
		active: {
			background: {
				color: 'color(display-p3 0.03 0.16 0.62)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 1 1 1)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 0.88 0.91 1)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0 0.1 0.42)'
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
	lilacNebula: {
		name: 'Lilac Nebula',
		active: {
			background: {
				color: 'color(display-p3 0.64 0 1)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 1 1 1)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 0.96 0.88 1)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0.24 0 0.37)'
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
	pinkFlamingo: {
		name: 'Pink Flamingo',
		active: {
			background: {
				color: 'color(display-p3 1 0.34 0.85)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 1 1 1)'
			},
		},
		inactiveLight: {
			background: {
				color: 'color(display-p3 1 0.88 0.97)',
				intensity: 0.075
			},
			text: {
				color: 'color(display-p3 0.5 0.17 0.43)'
			},
		},
		tags: ['blue', 'sky'],
	} as ActivityColorType<PreNuance>,
}

export type ActivityColorKey = keyof typeof RawActivityColors
export const ActivityColors = Object.keys(RawActivityColors).reduce((acc: {
	[key in keyof typeof RawActivityColors]: ActivityColorType<Nuance>
}, key) => {
	const colorSet = (RawActivityColors as any)[key] as any
	(acc as any)[key] = chiselTheme(colorSet as any, ['name', 'tags']) as any
	return acc
}, {} as any)


// '6912ae68-3d4c-469d-b9b7-3055ec9f88ea': {
// 	name: 'Fandango Pink',
// 	active: {
// 		background: '#D74C86',
// 		text: '#FFF',
// 	},
// 	inactiveLight: {
// 		background: '#FFCCE1',
// 		text: '#7E093A',
// 	},
// 	inactiveDark: {
// 		background: '#4F1A30',
// 		text: '#E8669D',
// 	},
// 	tags: ['red'],
// },
// 'b515b81a-332b-45ee-883c-d9afff3ad83f': {
// 	name: 'Stoplight',
// 	active: {
// 		background: '#E46D65',
// 		text: '#FFF',
// 	},
// 	inactiveLight: {
// 		background: '#FFB0B0',
// 		text: '#6D0000',
// 	},
// 	inactiveDark: {
// 		background: '#562323',
// 		text: '#FB5656',
// 	},
// 	tags: ['red'],
// },
// '8919148e-3a70-4547-ace2-19891ebdd874': {
// 	name: 'Lone Star',
// 	active: {
// 		background: '#FFE3C1',
// 		text: '#000',
// 	},
// 	inactiveLight: {
// 		background: '#FFE3C1',
// 		text: '#797979',
// 	},
// 	inactiveDark: {
// 		background: '#433725',
// 		text: '#C7AA7E',
// 	},
// 	tags: ['red'],
// },
// '43199cd6-e254-4f0e-930f-7716f97e5ec8': {
// 	name: 'Hidden Tribe',
// 	active: {
// 		background: '#BD9D00',
// 		text: '#FFF',
// 	},
// 	inactiveLight: {
// 		background: '#F6F0CF',
// 		text: '#513B02',
// 	},
// 	inactiveDark: {
// 		background: '#544F36',
// 		text: '#C89F36',
// 	},
// 	tags: ['red'],
// },
// 'e1b7a4e8-f052-44ec-8808-771bd39b1f69': {
// 	name: 'Rita Repulsa',
// 	active: {
// 		background: '#02A758',
// 		text: '#FFF',
// 	},
// 	inactiveLight: {
// 		background: '#B1E9CE',
// 		text: '#513B02',
// 	},
// 	inactiveDark: {
// 		background: '#12452C',
// 		text: '#C89F36',
// 	},
// 	tags: ['red'],
// },
// 'cb886b5c-32c4-4159-aded-9cf25ad6b89a': {
// 	name: 'Pond Moss',
// 	active: {
// 		background: '#017871',
// 		text: '#FFF',
// 	},
// 	inactiveLight: {
// 		background: '#E3EFEE',
// 		text: '#00433F',
// 	},
// 	inactiveDark: {
// 		background: '#243534',
// 		text: '#96E5E1',
// 	},
// 	tags: ['red'],
// },
// 'E6E59A22-564E-4F2D-8740-6ABCE87D4903': {
// 	name: 'Spinel Violet',
// 	active: {
// 		background: '#E11616',
// 		text: '#FFF',
// 	},
// 	inactiveLight: {
// 		background: '#FFB0B0',
// 		text: '#6D0000',
// 	},
// 	inactiveDark: {
// 		background: '#562323',
// 		text: '#FB5656',
// 	},
// 	tags: ['red'],
// },
// 'cb82625e-e584-4fbf-8b15-3502793dbcbc': {
// 	name: 'Deep Value',
// 	active: {
// 		background: '#352C3E',
// 		text: '#FFF',
// 	},
// 	inactiveLight: {
// 		background: '#CFD8E8',
// 		text: '#352C3E',
// 	},
// 	inactiveDark: {
// 		background: '#302A36',
// 		text: '#C9BDD4',
// 	},
// 	tags: ['red'],
// },