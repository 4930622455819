import { ListInviteesResponse } from '@clepside/clepsidejs/lib/services/users_v1_pb';

import { Collection } from '../commonTypes';

export interface IState {
    invitesLeft: number,
    invites: Collection<ListInviteesResponse.AsObject['inviteesList'][0]>
}

export const State: IState = {
    invitesLeft: 0,
    invites: {
        at: {},
        all: []
    }
}