import { AnimatePresence, motion, useAnimate } from 'framer-motion';
import { useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Text } from '@comps/typography/text';
import { Transitions } from '@root/brand/transitions';
import { CardLayoutObject } from '@root/store/slices/cards.types';

import { getStylesForCard } from './cardGrid.utils';
import { getLayoutKey } from './grid.utils';

export const CardGridPlaceholder: React.FC<{ gridCellWidth: number; layout: CardLayoutObject; beingMovedToANewBoard?: boolean }> = ({
	gridCellWidth,
	layout,
	beingMovedToANewBoard = false,
}) => {
	const [scope, animate] = useAnimate()
	const currentLayout = useRef<CardLayoutObject>()

	// const calculatedRect = useMemo(() => {
	// 	if (!gridCellWidth) return undefined
	// 	return getStylesForCard(layout, gridCellWidth)
	// }, [gridCellWidth, layout])

	const rect = useMemo(() => {
		return getStylesForCard(layout, gridCellWidth)
	}, [layout, gridCellWidth])

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		if (!isEqual(currentLayout.current, layout)) {
	// 			const calculatedRect = getStylesForCard(layout, gridCellWidth)
	// 			console.log('lista', layout, calculatedRect)
	// 			animate(
	// 				'.placeholder',
	// 				{
	// 					x: calculatedRect.x,
	// 					y: calculatedRect.y,
	// 					width: calculatedRect.w,
	// 					height: calculatedRect.h,
	// 					opacity: 1,
	// 				},
	// 				currentLayout.current == undefined ? { duration: 0 } : Transitions.cardRelated
	// 			)
	// 			currentLayout.current = layout
	// 		}
	// 	}, 1)
	// }, [layout, animate, gridCellWidth])

	return (
		<Passer ref={scope}>
			<AnimatePresence>
				<Placeholder
					key={`${getLayoutKey(layout)}`}
					className="placeholder"
					initial={{ opacity: 0, scale: 0.9, x: rect.x, y: rect.y }}
					animate={{ opacity: 1, scale: 1, x: rect.x, y: rect.y }}
					exit={{ opacity: 0, scale: 0.9, x: rect.x, y: rect.y }}
					transition={Transitions.sessionFins}
					beingMovedToANewBoard={beingMovedToANewBoard}
					directStyles={
						rect
							? {
									x: rect.x,
									y: rect.y,
									width: rect.w,
									height: rect.h,
							  }
							: undefined
					}
				>
					{beingMovedToANewBoard && <Text>Paste card here</Text>}
				</Placeholder>
			</AnimatePresence>
		</Passer>
	)
}

const Passer = styled.div`
	display: contents;
`

const Placeholder = styled(motion.div)<{ beingMovedToANewBoard: boolean; directStyles: any }>`
	position: absolute;
	background-color: rgba(0, 0, 0, 0.2);
	/* box-shadow: 0px 0px 0px 2px rgba(30, 30, 30, 0.7); */
	border-radius: ${(p) => p.theme.statics.borderRadius.medium};
	z-index: 0;
	will-change: opacity;

	${(p) =>
		p.beingMovedToANewBoard &&
		css`
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
		`}

	${(p) =>
		p.directStyles &&
		css`
			width: ${p.directStyles.width}px;
			height: ${p.directStyles.height}px;
		`}
`

// transform: translateX(${p.directStyles.x}px) translateY(${p.directStyles.y}px);
