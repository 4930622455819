import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Database } from '@root/database'
import { put } from 'redux-saga/effects'
import { deltaDelete } from './persist'
import { NewPacketDelta, RemovePacketDeltaIdOnly, UpdatePacketDelta } from './persist.utils'
import { InitialRoutinesSchedulesState, NormalisedRoutineScheduleData } from './routineSchedules.types'
import { RoutineSchedulePacketObject } from './sync.tsx.packets.types'

export const normalizeRoutinePacket = (id: string, s: RoutineSchedulePacketObject['data']) => {
	const normalizedRoutine: NormalisedRoutineScheduleData = {
        id,
        ...s
    }

	return normalizedRoutine
}

const routinesSchedulesSlice = createSlice({
	name: 'routineSchedules',
	initialState: InitialRoutinesSchedulesState,
	reducers: {
        new: (state, { payload }: PayloadAction<NewPacketDelta<PacketType.ROUTINE_SCHEDULE>>) => {},
		update: (state, { payload }: PayloadAction<UpdatePacketDelta<PacketType.ROUTINE_SCHEDULE>>) => {},
		delete: (state, { payload }: PayloadAction<RemovePacketDeltaIdOnly>) => {},
		loadAllToStore: (state, { payload }: PayloadAction) => {},
        normalizeAndStore: (state, { payload }: PayloadAction<{ objects: RoutineSchedulePacketObject[], fromSync?: boolean }>) => {
			// TO-DO
			// Add computed properties, etc..
			payload.objects?.forEach((schedule) => {
				if (!schedule.id) return

				if (schedule.deleted?.is) {
                    if (schedule.data.routineId) {
						state.forRoutineId[schedule.data.routineId] = state.forRoutineId[schedule.data.routineId]?.filter(id => id != schedule.data.routineId)
                        state.all = state.all?.filter(id => id != schedule.data.routineId)
                    }
                } else {
					const normalised = normalizeRoutinePacket(schedule.id, schedule.data)

					if (!normalised) {
						return
					}

					if (!state.all) {
						state.all = []
					}

                    if (!state.forRoutineId[normalised.routineId]) {
                        state.forRoutineId[normalised.routineId] = []
                    }
                    if (!state.forRoutineId[normalised.routineId].includes(normalised.id)) {
                        state.forRoutineId[normalised.routineId].push(normalised.id)
                    }
                    state.forRoutineId[normalised.routineId].sort((a, b) => a > b ? 1 : -1)

                    if (!state.all.includes(normalised.id)) {
                        state.all.push(normalised.id)
                    }
					
                    
                    state.all.sort((a, b) => a > b ? 1 : -1)

					state.at[normalised.id] = {
						...(state.at[normalised.id] ? state.at[normalised.id] : {}),
						...normalised
					}
				}
			})
	},
	},
})

export const routineSchedulesSagas = {
	*delete({ payload: { id } }: ReturnType<typeof routinesSchedulesActions.delete>) {
		yield deltaDelete({ type: PacketType.ROUTINE_SCHEDULE, delta: { id, shouldDelete: true } })
	},
	*loadAllToStore({  }: ReturnType<typeof routinesSchedulesActions.loadAllToStore>): any {
		const routineSchedules: RoutineSchedulePacketObject[] = yield Database.routineSchedulePackets.toArray()

		yield put(routinesSchedulesActions.normalizeAndStore({
			objects: routineSchedules,
			fromSync: false
		}))
		// yield put(cardsActions.loadCardsForResourceId({ resourceId: payload.resourceId }))
		// yield put(fragmentsActions.loadFragmentsForResourceId({ resourceId: payload.resourceId, resourceType: GrantableResourceEnum.ACTIVITY }))
	},
}

export const routinesSchedulesActions = routinesSchedulesSlice.actions
export const routinesSchedulesReducers = routinesSchedulesSlice.reducer
