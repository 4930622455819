import { DynamicSidebar } from '@comps/layout/dynamicSidebar'
import { Flex } from '@comps/layout/flex'
import { Logo } from '@comps/static/logo'
import { Text } from '@comps/typography/text'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { SidebarLink } from './_sidebar.link'

export const Sidebar: React.FC<{ dark?: boolean }> = ({ dark }) => {
	const [, setQp] = useSearchParams()

	// const handleSearchClick = React.useCallback(() => {
	// 	setQp({ search: '' })
	// }, [setQp])

	return (
		<DynamicSidebar dark={dark}>
			<Header>
				<Flex align="center">
					<LogoHolder>
						<Logo size={28} />
					</LogoHolder>
					<Text level="largerParagraphTitle">Clepside</Text>
				</Flex>
				{/* <Flex>
					<NotificationsButton />
				</Flex> */}
			</Header>
			<List>
				<SidebarLink to="/today" icon="page.today" text="Today" color="translucentPassingColor" keepSelection />
				<SidebarLink to="/calendar" icon="page.calendar" text="Calendar" color="translucentPassingColor" keepSelection />
				<SidebarLink to="/activities" icon="page.activities" text="Activities" color="translucentPassingColor" keepSelection />
				<SidebarLink to="/boards" icon="page.boards" text="Boards" color="translucentPassingColor" keepSelection />
			</List>
			<Flexer />
			{/* {true ? (
					<Flex direction="column">
						<PlusInset
							onClick={() => {
								setQp({ plus: 'try' })
							}}
						>
							<Text bold>Join Clepside+</Text>
							<Text>Secure syncing across your devices, and more.</Text>
						</PlusInset>
					</Flex>
				) : null} */}
			{/* <SidebarLink icon="search" text="Search" onClick={handleSearchClick} /> */}

			<SidebarLink to="https://support.clepside.com/web" icon="page.support" text="How it works?" newTab />
			<SidebarLink to="/settings" icon="page.settings" text="Settings" />
			<Reddit target="_blank" rel="noopener noreferrer" href="https://reddit.com/r/clepside">
				Join us on <b>r/Clepside</b>
			</Reddit>
			{/* <Spacer size={15} vertical /> */}
			{/* <Text color="subtle" level="tiny" align="center">
				Press ⌥ for Hotkeys
			</Text> */}
		</DynamicSidebar>
	)
}

const Reddit = styled.a`	
	padding: 10px 15px;
	margin-top: 5px;
	font-size: 12px;
	border-radius: 10px;
	${p => p.theme.text.regular.r.css('color')};
	&:hover {
		${p => p.theme.backgrounds.clepside.r.css('color')};
	}

`

// {/* <SidebarLink to="/insights" icon="page.insights" text="Insights" /> */}

const Header = styled.div`
	padding: 20px 10px;
	padding-top: 5px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	position: relative;

	&:before {
		width: calc(100% - 20px);
		height: 1px;
		position: absolute;
		bottom: 0;
		left: 10px;
		content: '';
		border-bottom: 1px solid currentColor;
		opacity: 0.075;
	}
`
const LogoHolder = styled.div`
	height: 28px;
	margin-right: 10px;
	* {
		${(p) => p.theme.backgrounds.clepside.r.css('color')};
	}
`

const Flexer = styled.div`
	flex-grow: 1;
`

const List = styled.div`
	display: grid;
	flex-direction: column;
	grid-template-columns: auto;
	grid-row-gap: 2px;
`
