import { SettingsItem } from '@comps/complex/settingsItem'
import { Button } from '@comps/interactive/button'
import { Flex } from '@comps/layout/flex'
import { Spacer } from '@comps/layout/space'
import { Text } from '@comps/typography/text'
import { Database } from '@root/database'
import { useMemo } from 'react'
import { SettingsAccountDeletion } from './Settings.accountDeletion'
import { SettingsAccountLogin } from './Settings.accountLogin'

export const SettingsAccount = () => {
	const triggerDeletion = useMemo(() => {
		return (
			<Button
				inset="buttonSmall"
				color="textualAccent"
				onClick={async () => {
					await Database.dropAll()
					window.location.replace('/')
				}}
			>
				Trigger Resync
			</Button>
		)
	}, [])

	return (
		<Flex direction="column" grow>
			<Spacer size={12} vertical />
			<Text level="title60">Account</Text>
			<Spacer size={50} vertical />
			<SettingsAccountLogin />
			{process.env.REACT_APP_ENV == 'development' ||
				(process.env.REACT_APP_ENV == 'staging' && (
					<SettingsItem
						title="Your data is being synced"
						subtitle="Your data has never been synced through Clepside Plus. There is always risk of losing your activities and data. For a failsafe backup considering joining Clepside Plus"
						rightside={triggerDeletion}
					/>
				))}
			<SettingsAccountDeletion />
		</Flex>
	)
}
