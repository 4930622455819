import CJSCore from '@clepside/clepsidejs/lib/commons/core_pb'
import {
	FragmentCompletablePacket,
	FragmentCompletablePacketData,
	FragmentCompletablePacketMeta
} from '@clepside/clepsidejs/lib/packets_v1/fragments_completable_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { generatePacketMeta } from './persist.utils'
import { FragmentCompletablePacketObject } from './sync.tsx.packets.types'

export function constructFragmentCompletablePacket(readyToSendPacket: Packet, fragPacket: FragmentCompletablePacketObject) {
    const tbsFragPacket = new FragmentCompletablePacket()
    const { data, deleted, id, type, meta } = fragPacket
    if (!data) throw new Error('Packet data is undefined')
    
    const { cardId, position, title, isCompleted  } = data
    
    tbsFragPacket.setId(id)

    if (!type) {
        throw new Error('packet type not found')
    }

    if (deleted) {
        const deletedMeta = new CJSCore.DeleteMeta()
        deletedMeta.setAt(deleted.at)
        deletedMeta.setBy(deleted.by)
        deletedMeta.setIs(deleted.is)
        tbsFragPacket.setDeleted(deletedMeta)
    }
    if (data) {
        const newData = new FragmentCompletablePacketData()
        const newMeta = new FragmentCompletablePacketMeta()

        if (cardId) { 
            newData.setCardId(cardId)
        }

        if (position !== undefined && meta.position) { 
            newData.setPosition(position) 
            newMeta.setPosition(generatePacketMeta(fragPacket, 'position'))
        }
        
        if (isCompleted !== undefined && meta.isCompleted) { 
            newData.setIsCompleted(isCompleted) 
            newMeta.setIsCompleted(generatePacketMeta(fragPacket, 'isCompleted'))
        }
        if (title !== undefined && meta.title) { 
            newData.setTitle(title) 
            newMeta.setTitle(generatePacketMeta(fragPacket, 'title'))
        }

        tbsFragPacket.setData(newData)
        tbsFragPacket.setMeta(newMeta)
        tbsFragPacket.setType(type)
    }

    readyToSendPacket.setFragmentCompletable(tbsFragPacket)
}