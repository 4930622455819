import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { SettingsItem } from '@comps/complex/settingsItem';
import { Button } from '@comps/interactive/button';
import { Link } from '@comps/interactive/link';
import { Inset } from '@comps/layout/inset';
import { Text } from '@comps/typography/text';
import { authActions } from '@root/store/slices/auth';
import { useUser } from '@selectors/useUser';

export const SettingsAccountLogin: React.FC = () => {
	const user = useUser()
	const dispatch = useDispatch()

	const handleLogout = useCallback(() => {
		dispatch(authActions.logout())
	}, [dispatch])

	if (!user)
		return (
			<SettingsItem
				title={"You're not logged in into a Clepside account"}
				rightside={
					<Inset negative>
						<Link to="/login">
							<Button color="subtle" onClick={() => {}}>
								<Inset>
									<Text noWrap>Back to Login</Text>
								</Inset>
							</Button>
						</Link>
					</Inset>
				}
			/>
		)
	return (
		<SettingsItem
			title={`Logged in as ${user?.email}`}
			rightside={
				<Inset negative>
					<Button color="textualAccent" onClick={handleLogout}>
						<Inset>Logout</Inset>
					</Button>
				</Inset>
			}
		/>
	)
}
