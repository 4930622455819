import { Button, ButtonColor } from '@comps/interactive/button';
import { Inset } from '@comps/layout/inset';
import { Icon } from '@comps/static/icon';
import { Text } from '@comps/typography/text';
import NavigationStackCore from '@root/Router.NavigationStackCore';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';

export const BackButton: React.FC<{
	onlyIcon?: boolean
	defaultBackTo: string
	label?: string
	supportsHoverDragAction?: boolean
	color?: ButtonColor
	onClick?: (e: any) => void
}> = ({ onlyIcon, defaultBackTo, supportsHoverDragAction, onClick, label, color }) => {
	const navigate = useNavigate()
	const { state }: any = useLocation()

	const handleClick = useCallback(
		(e: any) => {
			onClick?.(e)
			const lastRoute = NavigationStackCore.pop()

			if (lastRoute) {
				navigate(lastRoute, { replace: true })
				return
			}

			if (defaultBackTo) {
				navigate(defaultBackTo)
				return
			}
		},
		[onClick, navigate, defaultBackTo]
	)

	if (onlyIcon) {
		return (
			<ShortcutHolder onClick={handleClick}>
				<IconButton className="icon-button">
					<Icon size={5} type="arrow" rotate={180} offsetX={-0.5} offsetY={0.3} />
				</IconButton>
			</ShortcutHolder>
		)
	}

	return (
		<Button
			color={color || 'translucentPassingColor'}
			onClick={handleClick}
			keepSelection
			round={onlyIcon ? 22 : undefined}
			supportsHoverDragAction={supportsHoverDragAction}
		>
			<Inset padding="buttonSmall" align="center">
				<Icon size={5} type="arrow" rotate={180} marginRight={6} offsetY={0} />
				<Text block level="label">
					{label !== undefined ? label : 'Back'}
				</Text>
			</Inset>
		</Button>
	)
}

const IconButton = styled.div`
	border-radius: 999px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const ShortcutHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 54px;
	height: 54px;
	transition: 0.3s ease all;
	${(p) => p.theme.text.darkTranslucency.r.css('color')};
	position: relative;
	left: -3px;

	.icon-button {
		width: 22px;
		height: 22px;
		${(p) => p.theme.backgrounds.darkTranslucencyElevated.r.css('background-color')};
	}

	&:hover .icon-button {
		${(p) => p.theme.backgrounds.darkTranslucencyElevated.t1.css('background-color')};
	}

	&:active .icon-button {
		${(p) => p.theme.backgrounds.darkTranslucencyElevated.t2.css('background-color')};
	}
`
