// source: packets_v1/fragments_completable.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var commons_core_pb = require('../commons/core_pb.js');
goog.object.extend(proto, commons_core_pb);
goog.exportSymbol('proto.packets_v1.FragmentCompletablePacket', null, global);
goog.exportSymbol('proto.packets_v1.FragmentCompletablePacketData', null, global);
goog.exportSymbol('proto.packets_v1.FragmentCompletablePacketMeta', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.FragmentCompletablePacketData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.FragmentCompletablePacketData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.FragmentCompletablePacketData.displayName = 'proto.packets_v1.FragmentCompletablePacketData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.FragmentCompletablePacketMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.FragmentCompletablePacketMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.FragmentCompletablePacketMeta.displayName = 'proto.packets_v1.FragmentCompletablePacketMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.FragmentCompletablePacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.FragmentCompletablePacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.FragmentCompletablePacket.displayName = 'proto.packets_v1.FragmentCompletablePacket';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.FragmentCompletablePacketData.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.FragmentCompletablePacketData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.FragmentCompletablePacketData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FragmentCompletablePacketData.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    isCompleted: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    title: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.FragmentCompletablePacketData}
 */
proto.packets_v1.FragmentCompletablePacketData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.FragmentCompletablePacketData;
  return proto.packets_v1.FragmentCompletablePacketData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.FragmentCompletablePacketData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.FragmentCompletablePacketData}
 */
proto.packets_v1.FragmentCompletablePacketData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPosition(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCompleted(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.FragmentCompletablePacketData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.FragmentCompletablePacketData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.FragmentCompletablePacketData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FragmentCompletablePacketData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getIsCompleted();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string card_id = 1;
 * @return {string}
 */
proto.packets_v1.FragmentCompletablePacketData.prototype.getCardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.FragmentCompletablePacketData} returns this
 */
proto.packets_v1.FragmentCompletablePacketData.prototype.setCardId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double position = 5;
 * @return {number}
 */
proto.packets_v1.FragmentCompletablePacketData.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.FragmentCompletablePacketData} returns this
 */
proto.packets_v1.FragmentCompletablePacketData.prototype.setPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool is_completed = 6;
 * @return {boolean}
 */
proto.packets_v1.FragmentCompletablePacketData.prototype.getIsCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.packets_v1.FragmentCompletablePacketData} returns this
 */
proto.packets_v1.FragmentCompletablePacketData.prototype.setIsCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string title = 7;
 * @return {string}
 */
proto.packets_v1.FragmentCompletablePacketData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.FragmentCompletablePacketData} returns this
 */
proto.packets_v1.FragmentCompletablePacketData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.FragmentCompletablePacketMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.FragmentCompletablePacketMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.FragmentCompletablePacketMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FragmentCompletablePacketMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: (f = msg.getPosition()) && commons_core_pb.Meta.toObject(includeInstance, f),
    isCompleted: (f = msg.getIsCompleted()) && commons_core_pb.Meta.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && commons_core_pb.Meta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.FragmentCompletablePacketMeta}
 */
proto.packets_v1.FragmentCompletablePacketMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.FragmentCompletablePacketMeta;
  return proto.packets_v1.FragmentCompletablePacketMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.FragmentCompletablePacketMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.FragmentCompletablePacketMeta}
 */
proto.packets_v1.FragmentCompletablePacketMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 2:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setIsCompleted(value);
      break;
    case 3:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.FragmentCompletablePacketMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.FragmentCompletablePacketMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.FragmentCompletablePacketMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FragmentCompletablePacketMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getIsCompleted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
};


/**
 * optional commons.Meta position = 1;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.FragmentCompletablePacketMeta.prototype.getPosition = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 1));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.FragmentCompletablePacketMeta} returns this
*/
proto.packets_v1.FragmentCompletablePacketMeta.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.FragmentCompletablePacketMeta} returns this
 */
proto.packets_v1.FragmentCompletablePacketMeta.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.FragmentCompletablePacketMeta.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional commons.Meta is_completed = 2;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.FragmentCompletablePacketMeta.prototype.getIsCompleted = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 2));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.FragmentCompletablePacketMeta} returns this
*/
proto.packets_v1.FragmentCompletablePacketMeta.prototype.setIsCompleted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.FragmentCompletablePacketMeta} returns this
 */
proto.packets_v1.FragmentCompletablePacketMeta.prototype.clearIsCompleted = function() {
  return this.setIsCompleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.FragmentCompletablePacketMeta.prototype.hasIsCompleted = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional commons.Meta title = 3;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.FragmentCompletablePacketMeta.prototype.getTitle = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 3));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.FragmentCompletablePacketMeta} returns this
*/
proto.packets_v1.FragmentCompletablePacketMeta.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.FragmentCompletablePacketMeta} returns this
 */
proto.packets_v1.FragmentCompletablePacketMeta.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.FragmentCompletablePacketMeta.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.FragmentCompletablePacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.FragmentCompletablePacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FragmentCompletablePacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deleted: (f = msg.getDeleted()) && commons_core_pb.DeleteMeta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.packets_v1.FragmentCompletablePacketData.toObject(includeInstance, f),
    meta: (f = msg.getMeta()) && proto.packets_v1.FragmentCompletablePacketMeta.toObject(includeInstance, f),
    lastModifiedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cloudedAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.FragmentCompletablePacket}
 */
proto.packets_v1.FragmentCompletablePacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.FragmentCompletablePacket;
  return proto.packets_v1.FragmentCompletablePacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.FragmentCompletablePacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.FragmentCompletablePacket}
 */
proto.packets_v1.FragmentCompletablePacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.commons.PacketType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new commons_core_pb.DeleteMeta;
      reader.readMessage(value,commons_core_pb.DeleteMeta.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 4:
      var value = new proto.packets_v1.FragmentCompletablePacketData;
      reader.readMessage(value,proto.packets_v1.FragmentCompletablePacketData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = new proto.packets_v1.FragmentCompletablePacketMeta;
      reader.readMessage(value,proto.packets_v1.FragmentCompletablePacketMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModifiedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloudedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.FragmentCompletablePacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.FragmentCompletablePacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.FragmentCompletablePacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.DeleteMeta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.packets_v1.FragmentCompletablePacketData.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.packets_v1.FragmentCompletablePacketMeta.serializeBinaryToWriter
    );
  }
  f = message.getLastModifiedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCloudedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.FragmentCompletablePacket} returns this
 */
proto.packets_v1.FragmentCompletablePacket.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional commons.PacketType type = 2;
 * @return {!proto.commons.PacketType}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.getType = function() {
  return /** @type {!proto.commons.PacketType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commons.PacketType} value
 * @return {!proto.packets_v1.FragmentCompletablePacket} returns this
 */
proto.packets_v1.FragmentCompletablePacket.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional commons.DeleteMeta deleted = 3;
 * @return {?proto.commons.DeleteMeta}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.getDeleted = function() {
  return /** @type{?proto.commons.DeleteMeta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.DeleteMeta, 3));
};


/**
 * @param {?proto.commons.DeleteMeta|undefined} value
 * @return {!proto.packets_v1.FragmentCompletablePacket} returns this
*/
proto.packets_v1.FragmentCompletablePacket.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.FragmentCompletablePacket} returns this
 */
proto.packets_v1.FragmentCompletablePacket.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FragmentCompletablePacketData data = 4;
 * @return {?proto.packets_v1.FragmentCompletablePacketData}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.getData = function() {
  return /** @type{?proto.packets_v1.FragmentCompletablePacketData} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.FragmentCompletablePacketData, 4));
};


/**
 * @param {?proto.packets_v1.FragmentCompletablePacketData|undefined} value
 * @return {!proto.packets_v1.FragmentCompletablePacket} returns this
*/
proto.packets_v1.FragmentCompletablePacket.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.FragmentCompletablePacket} returns this
 */
proto.packets_v1.FragmentCompletablePacket.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FragmentCompletablePacketMeta meta = 5;
 * @return {?proto.packets_v1.FragmentCompletablePacketMeta}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.getMeta = function() {
  return /** @type{?proto.packets_v1.FragmentCompletablePacketMeta} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.FragmentCompletablePacketMeta, 5));
};


/**
 * @param {?proto.packets_v1.FragmentCompletablePacketMeta|undefined} value
 * @return {!proto.packets_v1.FragmentCompletablePacket} returns this
*/
proto.packets_v1.FragmentCompletablePacket.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.FragmentCompletablePacket} returns this
 */
proto.packets_v1.FragmentCompletablePacket.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 last_modified_at = 6;
 * @return {number}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.getLastModifiedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.FragmentCompletablePacket} returns this
 */
proto.packets_v1.FragmentCompletablePacket.prototype.setLastModifiedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.FragmentCompletablePacket} returns this
 */
proto.packets_v1.FragmentCompletablePacket.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 clouded_at = 8;
 * @return {number}
 */
proto.packets_v1.FragmentCompletablePacket.prototype.getCloudedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.FragmentCompletablePacket} returns this
 */
proto.packets_v1.FragmentCompletablePacket.prototype.setCloudedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


goog.object.extend(exports, proto.packets_v1);
