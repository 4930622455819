import { DragDropCore, DraggingPhase } from '@root/cores/dragDropCore';
import { DraggingContext } from '@root/store/slices/interface.types';
import { sessionsActions } from '@root/store/slices/sessions';
// import { sessionsActions } from '@root/store/slices/sessions'
import { InstancedSessionData } from '@root/store/slices/sessionsTypes';
import { DateInterval, intervalFitsInside } from '@root/utils/dateIntervals';
import { roundToNearestMinuteInUTCs } from '@root/utils/dates';
import { addSeconds, differenceInMinutes, subSeconds } from 'date-fns';
import { addMinutes, subMinutes } from 'date-fns/esm';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import { SessionPlacerDimensions } from './sessionPlacer.types';
import { getCursorDateFromCoords } from './sessionPlacer.utils';

export const useDraggingSession = (
	container: HTMLElement,
	vector: number[],
	shownDatesIndex: number[],
	dimensions: SessionPlacerDimensions,
	dragZoneId: string,
	freeIntervals: MutableRefObject<Array<[Date, Date]>>,
	selectedSession?: InstancedSessionData
) => {
	const dispatch = useDispatch()
	const [session, setSession] = useState<
		| undefined
		| {
			id: string
			start: Date
			end: Date
			isACopy?: boolean
		}
	>(undefined)
	const intervals = useRef<Array<DateInterval>>([])
	const cachedFreeIntervals = useRef<Array<DateInterval>>([])

	const calculateDateFromCoords = useCallback(
		(phase: DraggingPhase, startCoords: any, e: any): Date | undefined => {
			return getCursorDateFromCoords(
				container,
				phase === 'start' ? startCoords.x : e.clientX,
				phase === 'start' ? startCoords.y - container.getBoundingClientRect().top : e.clientY - container.getBoundingClientRect().top,
				shownDatesIndex,
				vector,
				dimensions
			)
		},
		[container, dimensions, shownDatesIndex, vector]
	)

	const doesIntervalFitInside = useCallback((start: Date, end: Date): boolean => {
		return intervals.current.some((interval) => intervalFitsInside([start, end], interval))
	}, [])

	const moveSessionIfEndPhase = useCallback(
		(phase: DraggingPhase, start: Date, end: Date, id: string, shouldCopy?: boolean) => {
			if (phase === 'finished') {
				dispatch(
					sessionsActions.move({
						id,
						to: { start: start.getTime(), end: end.getTime() },
					})
				)
				setTimeout(() => {
					setSession(undefined)
				}, 100)
			} else {
				setSession({
					id,
					isACopy: shouldCopy,
					start: start,
					end: end,
				})
			}
		},
		[dispatch]
	)

	const refreshInterval = useCallback(
		(start: Date, end: Date, shouldCopy?: boolean) => {
			// Check if freeIntervals has been updated
			if (cachedFreeIntervals.current !== freeIntervals.current) {
				cachedFreeIntervals.current = freeIntervals.current

				const init: DateInterval = [start, end]

				intervals.current = [...freeIntervals.current, ...(shouldCopy ? [] : [init])]
				intervals.current.sort((a, b) => (a[0].getTime() < b[0].getTime() ? -1 : 1))

				const mergedIntervals: DateInterval[] = []
				for (const interval of intervals.current) {
					if (!mergedIntervals.length || mergedIntervals[mergedIntervals.length - 1][1].getTime() < interval[0].getTime()) {
						// If the list of merged intervals is empty or if the current interval does not overlap with the previous, simply append it.
						mergedIntervals.push(interval)
					} else {
						// Merge overlapping intervals
						mergedIntervals[mergedIntervals.length - 1][1] = new Date(
							Math.max(mergedIntervals[mergedIntervals.length - 1][1].getTime(), interval[1].getTime())
						)
					}
				}

				intervals.current = mergedIntervals
			}
		},
		[freeIntervals]
	)

	const findClosestFeasibleInterval = useCallback(
		(start: Date, end: Date) => {
			const durationInMinutes = differenceInMinutes(end, start)

			const potentialStartFits = intervals.current.map((interval) => {
				return {
					start: interval[0],
					end: addMinutes(interval[0], durationInMinutes),
				}
			})

			const potentialEndFits = intervals.current.map((interval) => {
				return {
					start: subMinutes(interval[1], durationInMinutes),
					end: interval[1],
				}
			})

			const allPotentialFits = [...potentialStartFits, ...potentialEndFits]

			const feasibleFits = allPotentialFits.filter((potential) => {
				return doesIntervalFitInside(potential.start, potential.end)
			})

			if (feasibleFits.length === 0) return null

			feasibleFits.sort((a, b) => {
				const diffA = Math.abs(differenceInMinutes(a.start, start))
				const diffB = Math.abs(differenceInMinutes(b.start, start))

				return diffA - diffB
			})

			return feasibleFits[0]
		},
		[doesIntervalFitInside]
	)

	useEffect(() => {
		if (!selectedSession) return
		if (!container) return

		const listener = (phase: DraggingPhase, context: DraggingContext, e: any) => {
			if (context.type !== 'session') return

			const { shouldCopy, sessionId, startCoords, offset } = context

			const id = shouldCopy ? v4() : sessionId

			if (phase === 'interrupted') {
				setSession(undefined)
				return
			}

			const date = calculateDateFromCoords(phase, startCoords, e)

			if (!date) return

			const start = roundToNearestMinuteInUTCs(subSeconds(date, offset.secondsBefore), 5)
			const end = roundToNearestMinuteInUTCs(addSeconds(date, offset.secondsAfter), 5)

			refreshInterval(start, end, shouldCopy)

			const fitsInside = doesIntervalFitInside(start, end)

			if (fitsInside) {
				moveSessionIfEndPhase(phase, start, end, id, shouldCopy)
			} else {
				const feasibleInterval = findClosestFeasibleInterval(start, end)

				if (feasibleInterval) {
					moveSessionIfEndPhase(phase, feasibleInterval.start, feasibleInterval.end, id, shouldCopy)
				}
			}
		}

		const removeListener = DragDropCore.addListener(dragZoneId, listener)
		const unmount = DragDropCore.mountContainer(dragZoneId, 1, container)
		return () => {
			removeListener()
			unmount()
		}
	}, [
		container,
		refreshInterval,
		findClosestFeasibleInterval,
		selectedSession,
		dispatch,
		shownDatesIndex,
		dimensions,
		vector,
		dragZoneId,
		calculateDateFromCoords,
		moveSessionIfEndPhase,
		doesIntervalFitInside,
	])

	return useMemo(() => {
		return { draggedSession: session } as {
			draggedSession: DraggedSession
		}
	}, [session])
}

export type DraggedSession =
	| {
		id: string
		start: Date
		end: Date
		isACopy?: boolean | undefined
	}
	| undefined
