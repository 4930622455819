import React from 'react'
import styled, { css } from 'styled-components'

interface Props {
	vertical?: boolean
	size: number
	equal?: boolean
	grow?: boolean
	className?: string
}

export const Spacer: React.FC<Props> = (p) => {
	return <Holder {...p} className={p.className} />
}

const Holder = styled.div<Props>`
	flex-shrink: 0;
	flex-grow: 0;

	${({ vertical, equal, size, grow }) => {
		if (grow)
			return css`
				flex-grow: 1;
				width: 0;
			`

		if (vertical)
			return css`
				height: ${size}px;
				width: 1px;
			`
		if (equal)
			return css`
				height: ${size}px;
				width: ${size}px;
			`
		return css`
			height: 1px;
			width: ${size}px;
		`
	}}
`
