import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { useArrowWatcherAddCard } from '@root/hooks/useArrowAddCard';
import { useDragZoneWithPreciseId } from '@root/hooks/useDragZone';
import { useHotkey } from '@root/hooks/useHotkey';
import { useSelection } from '@root/hooks/useSelectable';
import { useCardsSelectionConfig } from '@root/store/selectors/useCardsSelectionConfig';
import { cardsActions } from '@root/store/slices/cards';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { LexicalLineNodeChaser } from './card.lexical.lineNodeChaser';
import { CardDroppablePlace } from './cardDroppablePlace';
import { useCardDatasource } from './cardGrid.useCardDatasource';
import { useCardMatrix } from './cardGrid.useCardMatrix';
import { useCardListCardDragging } from './cardList.useCardDragging';
import { CardListCard } from './cardListCard';
import { CardPaster } from './cardPaster';

export const CardList: React.FC<{ resourceId: string; resourceType: GrantableResourceEnum; paddingTop?: number }> = ({
	resourceId,
	resourceType,
	paddingTop = 150,
}) => {
	const { cards: cardsWithoutLayout } = useCardDatasource(resourceId)
	const dispatch = useDispatch()

	const allCards = useMemo(() => {
		const array = []

		const sorted = cardsWithoutLayout.sort((a, b) => (a.verticalLayout > b.verticalLayout ? 1 : -1))
		for (const c of sorted) {
			array.push(<CardListCard key={c.id} resourceId={resourceId} resourceType={resourceType} cardId={c.id} />)
		}
		return array
	}, [cardsWithoutLayout, resourceId, resourceType])

	const { cards, setGridRef, placeholder, planNewCard, tallness } = useCardMatrix(cardsWithoutLayout, resourceId, resourceType)

	const { template } = useCardsSelectionConfig(resourceId, resourceType)
	const selection = useSelection(template)

	// useDidChange(planNewCard)
	useArrowWatcherAddCard(
		resourceId,
		resourceType,
		useCallback(
			(ctx: { cardType: CardType }) => {
				const layout = planNewCard(ctx.cardType)
				// if (!layout) throw new Error('Failed to find space for the new card')

				if (!resourceId) {
					throw new Error('Resource does not exist.')
				}

				dispatch(
					cardsActions.new({
						cardType: ctx.cardType,
						resourceId,
						resourceType,
						layout: layout.layout,
						verticallayout: {
							order: layout.verticalPosition
						}
					})
				)
			},
			[dispatch, resourceId, resourceType, planNewCard]
		)
	)
	const handleDeletion = useCallback(() => {
		if (!selection) return
		for (const cardId of selection.all) {
			dispatch(cardsActions.delete({ id: cardId.id }))
		}
	}, [selection, dispatch])

	const selectionConfig = useCardsSelectionConfig(resourceId, resourceType)
	useHotkey(selectionConfig.key, 'backspace', handleDeletion)
	// const [container, setContainer] = useRef()

	const { container, setContainer, dragZoneId } = useDragZoneWithPreciseId('card-list')
	const { indicators, isDragging } = useCardListCardDragging(dragZoneId, container, cardsWithoutLayout)

	return (
		<LexicalLineNodeChaser>
			<CardDroppablePlace resourceId={resourceId} resourceType={resourceType}/>
			<CardPaster resourceId={resourceId} resourceType={resourceType} planNewCard={planNewCard} />
			<Holder paddingTop={paddingTop} ref={setContainer} className="card-grid-scroll" isDragging={isDragging}>
				<CardsHolder className="board-grid">{allCards}</CardsHolder>
				{indicators}
			</Holder>
		</LexicalLineNodeChaser>
	)
}

const Holder = styled.div<{ paddingTop: number, isDragging: boolean }>`
	padding-top: ${(p) => p.paddingTop}px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 5000000;

	overflow: auto;
	-webkit-overflow-scrolling: touch;

	${p => p.isDragging && css`
		cursor: grabbing !important;
		* {
			cursor: grabbing !important;
		}
	`}
`

const CardsHolder = styled.div`
	padding: 1;
	box-sizing: border-box;
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 20px 60px 100px 60px;
	align-items: center;
`
