import * as log from 'loglevel'

class LoggerClass {
	lexical: log.Logger

	persist: log.Logger

	sync: log.Logger

	watch: log.Logger

	mutations: log.Logger

	auth: log.Logger

	statics: log.Logger

	hotkeys: log.Logger

	constructor() {
		const colors: any = {
			Persist: 'color: #F00; background: #000;',
			Watch: 'color: #aaa',
		}

		const originalFactory = log.methodFactory
			; (log as any).methodFactory = function (methodName: any, logLevel: any, loggerName: any) {
				const rawMethod = originalFactory(methodName, logLevel, loggerName)
				return function (...rest: any[]) {
					const messages = [`%c[${loggerName}]: `, colors[loggerName] ? colors[loggerName] : '']
					for (let i = 0; i < rest.length; i++) {
						messages.push(rest[i])
					}
					rawMethod(...messages)
				}
			}
		log.setLevel(log.getLevel())

		this.lexical = log.getLogger('lexical')
		this.persist = log.getLogger('Persist')
		this.watch = log.getLogger('Watch')
		this.sync = log.getLogger('Sync')
		this.mutations = log.getLogger('Mutations')
		this.auth = log.getLogger('Auth')
		this.statics = log.getLogger('Statics')
		this.hotkeys = log.getLogger('Hotkeys')

		this.lexical.setDefaultLevel('ERROR')
		this.watch.setDefaultLevel('ERROR')
		this.sync.setDefaultLevel('ERROR')
		this.persist.setDefaultLevel('ERROR')
		this.mutations.setDefaultLevel('ERROR')
		this.statics.setDefaultLevel('ERROR')
		this.auth.setDefaultLevel('ERROR')
		this.hotkeys.setDefaultLevel('ERROR')

		// this.lexical.setDefaultLevel('DEBUG')
		// this.watch.setDefaultLevel('DEBUG')
		// this.sync.setDefaultLevel('DEBUG')
		// this.persist.setDefaultLevel('DEBUG')
		// this.mutations.setDefaultLevel('DEBUG')
		// this.statics.setDefaultLevel('DEBUG')
		// this.auth.setDefaultLevel('DEBUG')
		// this.hotkeys.setDefaultLevel('INFO')

		// this.lexical.setDefaultLevel('ERROR')
		// this.watch.setDefaultLevel('ERROR')
		// this.sync.setDefaultLevel('ERROR')
		// this.persist.setDefaultLevel('ERROR')
		// this.mutations.setDefaultLevel('ERROR')
		// this.auth.setDefaultLevel('ERROR')
		// this.hotkeys.setDefaultLevel('ERROR')
	}
}

export const Logger = new LoggerClass()
