import { DeleteMeta } from '@clepside/clepsidejs/lib/commons/core_pb'
import { ActivityPacket, ActivityPacketData, ActivityPacketMeta } from '@clepside/clepsidejs/lib/packets_v1/activities_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { generatePacketMeta } from './persist.utils'
import { ActivityPacketObject } from './sync.tsx.packets.types'

export function constructActivityPacket(readyToSendPacket: Packet, activityPacket: ActivityPacketObject) {
    const tbsActivityPacket = new ActivityPacket()
    const { type, data, deleted, meta, id } = activityPacket
    if (!data) throw new Error('Packet data is undefined')
    
    const { name, color, icon, wallpaper  } = data

    tbsActivityPacket.setId(id)

    if (!type) {
        throw new Error('packet type not found')
    }

    if (deleted) {
        const deletedMeta = new DeleteMeta()
        deletedMeta.setAt(deleted.at)
        deletedMeta.setBy(deleted.by)
        deletedMeta.setIs(deleted.is)

        tbsActivityPacket.setDeleted(deletedMeta)
    }


    if (data) {
        const newData = new ActivityPacketData()
        const newMeta = new ActivityPacketMeta()


        if (name && meta.name) {
            newData.setName(name)
            newMeta.setName(generatePacketMeta(activityPacket, 'name'))
        }
        if (color && meta.color) {
            newData.setColor(color)
            newMeta.setColor(generatePacketMeta(activityPacket, 'color'))
        }
        if (icon && meta.icon) {
            newData.setIcon(icon)
            newMeta.setIcon(generatePacketMeta(activityPacket, 'icon'))
        }

        if (wallpaper && meta.wallpaper) {
            newData.setWallpaper(wallpaper)
            newMeta.setWallpaper(generatePacketMeta(activityPacket, 'wallpaper'))
        }

        tbsActivityPacket.setData(newData)
        tbsActivityPacket.setMeta(newMeta)
        tbsActivityPacket.setType(type)
    }

    readyToSendPacket.setActivity(tbsActivityPacket)
}