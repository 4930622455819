import { useActivities } from '@root/store/selectors/useActivities';
import { ActivityColorKey, ActivityColors } from '@root/store/slices/activities.colors.types';
import styled, { css } from 'styled-components';

import { ActivityGradient } from './layout/activityGradient';

export const ActivityBackground: React.FC<{ activityId?: string, blurred?: boolean }> = ({ activityId, blurred }) => {
	const actvities = useActivities()
	const color = activityId ? actvities.at[activityId]?.color : undefined
	const wallpaper = activityId ? actvities.at[activityId]?.wallpaper : undefined

	if (!activityId) return <></>

	return (
		<Holder blurred={blurred}>
			<div className='blurrer' />
			<WallpaperBackground color={color || 'denimBlueberry'} wallpaper={wallpaper} />
			{/* <Overlay /> */}
		</Holder>
	)
}

// const Overlay = styled.div`
// 	width: 100%;
// 	height: 100%;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	background-color: rgba(0, 0, 0, 0.5);
// `

const Holder = styled.div<{ blurred?: boolean }>`
	
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;

	.blurrer {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		opacity: 0;

		&:before {
			content: "";
			display: block;
			
			top: 0;
			left: 0;
			width: 100%;
			z-index: 10000;
			height: 100%;
			background-color: rgba(0,0,0,0.25);
			backdrop-filter: blur(32px);
			-webkit-backdrop-filter: blur(32px);
		}

		${p => p.blurred && css`
			opacity: 1;
		`}
	}
`
export const WallpaperBackground = styled.div<{ color: ActivityColorKey; wallpaper?: string }>`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	background-color: #050709;

	${(p) =>
		p.wallpaper &&
		p.wallpaper === 'currentColor' &&
		css`
			${ActivityColors[p.color]?.active.background.r.css('background-color')};
		`}

	${(p) =>
		p.wallpaper && p.wallpaper !== 'currentColor'
			? css`
					background-image: url(${`/static-backgrounds/${p.wallpaper}.jpg`});
					background-position: center;
					background-size: cover;
			  `
			: css`
					&:before {
						content: '';
						${ActivityGradient};
					}
			  `}
`
