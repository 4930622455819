import { SelectedSessionEmpty } from '@comps/complex/planning/selectedSession.empty'
import { CalendarSessionsPlacer } from '@comps/complex/planning/sessionPlacer'
import { Spacer } from '@comps/layout/space'
import { KeyScope } from '@comps/logical/keyScope'
import { useRefTaker } from '@hooks/useRefTaker'
import { useDayIndexes } from '@root/hooks/useDayIndexes'
import { useSessionsPlacerDimensions } from '@root/hooks/useWeekDimensions'
import { useMergedSessions, useSessions } from '@root/store/selectors/useSessions'
import { useSessionsSource } from '@root/store/selectors/useSessionsSource'
import { SessionDisplayConditions } from '@root/store/slices/sessionsTypes'
import { getDataAttribute } from '@utils/general'
import { CalendarDayNavigation } from '@views/Calendar.dayNavigation'
import { add, endOfDay, format, parse, startOfDay, sub } from 'date-fns'
import React, { MouseEventHandler, useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

let savedPosition:
	| undefined
	| {
		date: string
		scroll: number
	} = undefined
export const Calendar: React.FC = () => {
	const [qp, setQp] = useSearchParams()
	const weeksSessions: any = useSessions('this-week')
	const { presentedSessionId } = useParams()
	const presentedDate = useMemo(() => {
		const qpDate = qp.get('date')
		return qpDate ? parse(qpDate, 'dd-MM-yyyy', new Date()) : new Date()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [qp])
	const dispatch = useDispatch()

	const setPresentedDate = useCallback(
		(date: Date) => {
			setQp({
				date: format(date, 'dd-MM-yyyy'),
			})
		},
		[setQp]
	)

	const sessionsConfig = useMemo(() => {
		return {
			start: startOfDay(presentedDate).getTime(),
			end: endOfDay(presentedDate).getTime(),
			inclusive: 'both',
		} as SessionDisplayConditions
	}, [presentedDate])

	useEffect(() => {
		if (presentedSessionId) {
			const id = document.body?.querySelector(`.session-block[data-sid="${presentedSessionId}"]`)
			// dispatch(
			// 	selectionActions.select({
			// 		type: SelectableTypes.SESSION,
			// 		ids: [selectedSessionId],
			// 		scopeTemplate: 'Plan',
			// 		scope: 'Plan',
			// 	})
			// )
		}
	}, [presentedSessionId, dispatch])

	useSessionsSource(sessionsConfig, 'for-date-<0>', `${sessionsConfig.start}`)
	const sessionsForPresentedDate = useSessions('for-date-<0>', `${sessionsConfig.start}`)
	const sessions = useMergedSessions(weeksSessions, sessionsForPresentedDate)

	// useSessions(getDayIndex(presentedDate))
	const [containerRef, setContainerRef] = useRefTaker()

	const scroll = useRef(0)
	useEffect(() => {
		if (!containerRef) return
		const onScroll = (e: any) => {
			scroll.current = e.target.scrollTop
		}
		containerRef.addEventListener('scroll', onScroll)
		return () => {
			containerRef.removeEventListener('scroll', onScroll)
		}
	}, [containerRef])

	const scrollToTimeBar = useCallback(() => {
		const bar = containerRef.querySelector('.time-bar')
		if (!bar) {
			containerRef.scrollTop = 0
			return
		}
		const positionTop = bar.offsetTop - window.innerHeight / 2 + 100
		containerRef.scrollTop = positionTop
	}, [containerRef])

	useEffect(() => {
		if (containerRef) {
			if (savedPosition) {
				if (format(presentedDate, 'dd-MM-yyyy') === savedPosition.date) {
					containerRef.scrollTop = savedPosition.scroll
					savedPosition = undefined
				} else {
					scrollToTimeBar()
				}
			} else {
				scrollToTimeBar()
			}

			return () => {
				const lastPosition = {
					date: format(presentedDate, 'dd-MM-yyyy'),
					scroll: scroll.current,
				}
				savedPosition = lastPosition
			}
		}
	}, [containerRef, scrollToTimeBar, presentedDate])

	const onDayNavigationClicked = useCallback<MouseEventHandler<HTMLButtonElement>>(
		(e) => {
			const direction = getDataAttribute(e, 'direction')
			if (direction == 'left') {
				setPresentedDate(sub(presentedDate, { days: 1 }))
			}
			if (direction == 'reset') {
				setPresentedDate(new Date())
			}
			if (direction == 'right') {
				setPresentedDate(add(presentedDate, { days: 1 }))
			}
		},
		[presentedDate, setPresentedDate]
	)

	// useEffect(() => {
	// 	// dispatch(SessionsActions.LOAD_FOR_DATE(new Date()))
	// }, [dispatch])
	const navigate = useNavigate()
	const location = useLocation()

	const handleSessionSelection = useCallback(
		(sessionId?: string) => {
			if (sessionId) {
				// dispatch(
				// 	selectionActions.select({
				// 		type: SelectableTypes.SESSION,
				// 		ids: [sessionId],
				// 		scopeTemplate: 'Plan',
				// 		scope: 'Plan',
				// 	})
				// )
				navigate(`/calendar/session/${sessionId}` + (location.search || ''))
			} else navigate('/calendar' + (location.search || ''))
		},
		[navigate, location]
	)

	const presentedDates = useMemo(() => {
		return [presentedDate]
	}, [presentedDate])

	const presentedDatesIndexes = useDayIndexes(presentedDates)
	const { dimensions, container, setContainer, dragZoneId } = useSessionsPlacerDimensions(presentedDatesIndexes, true, false)

	return (
		<Page id="SCROLL-CONTAINER" ref={setContainerRef}>
			<KeyScope scope="Plan" />
			<Holder>
				<CalendarDayNavigation
					forDate={presentedDate}
					setPresentedDate={setPresentedDate}
					containerRef={containerRef}
					sessions={sessions}
					onDayNavigationClicked={onDayNavigationClicked}
				/>
				<Timeline>
					<CalendarSessionsPlacer
						offsetLeft={55}
						noPadding
						selectionZoneId="Plan"
						sessions={sessions}
						presentedDates={presentedDates}
						presentedDatesIndexes={presentedDatesIndexes}
						onSelectionMade={handleSessionSelection}
						dragZoneId={dragZoneId}
						dimensions={dimensions}
						container={container}
						setContainer={setContainer}
					/>
					<Spacer size={25} vertical />
				</Timeline>
			</Holder>
			{presentedSessionId ? <Outlet /> : <SelectedSessionEmpty />}
		</Page>
	)
}

const Holder = styled.div`
	display: block;
	padding: 0px 36px 0 54px;
	height: auto;
	min-height: 0;
	box-sizing: border-box;
`

const Page = styled.div`
	width: 100%;
	display: flex;
	background-color: #fff;
	height: 100vh;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	align-items: flex-start;
`

const Timeline = styled.div`
	flex-shrink: 0;
	display: flex;
`
