import { useTime } from '@root/hooks/useTime'
import { getDuration } from '@root/utils/dates'
import { differenceInSeconds } from 'date-fns'
import React, { useMemo } from 'react'

export const SessionStatus: React.FC<{
	start: number
	end: number,
	className?: string
}> = ({ start, end, className }) => {
	const now = useTime('minute')

	const state = useMemo(() => {
		if (now.getTime() > end) {
			return `Ended ${getDuration(differenceInSeconds(now, end))} ago`
		}
		if (now.getTime() < start) {
			return `Starts in ${getDuration(differenceInSeconds(start, now), ['seconds'])}`
		}
		return 'Active'
	}, [start, end, now])

	return <span className={`${className} session-status`}>{state}</span>
}
