import { endOfMonth, startOfMonth } from 'date-fns';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { DatePeriodButton } from '@comps/complex/datePeriodButton';
import { Flex } from '@comps/layout/flex';
import { InnerPage } from '@comps/layout/innerPage';
import { Text } from '@comps/typography/text';
import { useSessionsForActivity } from '@root/store/selectors/useSessionsForActivity';

import { ActivityInsightsChartD3 } from './Activity.ID.Insights.chart3d';

export const ActivityMore = () => {
	const { id } = useParams()
	const sessions = useSessionsForActivity(id)
	const [qP, setQp] = useSearchParams()
	const defaultDates = useMemo((): [Date, Date] => {
		const now = new Date()
		return [startOfMonth(now), endOfMonth(now)]
	}, [])

	return (
		<InnerPage>
			<Flex justify="space-between" align="center">
				<Text level="title70">Insights</Text>
				<DatePeriodButton period={defaultDates} />
			</Flex>
			<ActivityInsightsChartD3 period={defaultDates} activityId={id} sessions={sessions} />
			<BlocksHolder>
				<Block>
					<Text level="body">Planned ahead</Text>
					<Text level="title70">50%</Text>
					<Text>139h out of 146h, this month</Text>
				</Block>
				<Block>
					<Text level="body">You’re on a streak</Text>
					<Text level="title70">3</Text>
					<Text>Every other day for the past week</Text>
				</Block>
				<Block>
					<Text level="body">Time spent</Text>
					<Text level="title70">14%</Text>
					<Text>139h out of 146h, in the period</Text>
				</Block>
				<Block>
					<Text level="body">Avg. per day</Text>
					<Text level="title70">10%</Text>
					<Text>2h 30m out of 24h, in the period</Text>
				</Block>
			</BlocksHolder>
		</InnerPage>
	)
}

const BlocksHolder = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
`

const Block = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 20px;
`
