import { Button } from '@comps/interactive/button';
import { Text } from '@comps/typography/text';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Inset } from './inset';

export const PageHeader: React.FC<{
	title: string
	rightSide?: React.ReactNode
	extraBottomSpace?: boolean
	mainAction?:
		| {
				action: () => void
				label: string
		  }
		| {
				linkTo: string
				label: string
		  }
}> = ({ title, extraBottomSpace, mainAction, rightSide }) => {
	return (
		<Holder extraBottomSpace={extraBottomSpace}>
			<Text level="title70">{title}</Text>
			{mainAction ? (
				'linkTo' in mainAction ? (
					<Link to={mainAction.linkTo}>
						<Button color="subtleAccent">
							<Inset>{mainAction.label}</Inset>
						</Button>
					</Link>
				) : (
					<Button color="subtleAccent" onClick={mainAction.action}>
						<Inset>{mainAction.label}</Inset>
					</Button>
				)
			) : null}
			{rightSide}
		</Holder>
	)
}

const Holder = styled.div<{ extraBottomSpace?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	align-self: stretch;
	border-bottom: 1px solid ${(p) => p.theme.border.subtle.t1.color};
	padding-bottom: 20px;
	margin-bottom: 40px;
	${(p) =>
		p.extraBottomSpace &&
		css`
			margin-bottom: 60px;
		`}
`
