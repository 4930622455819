import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { AddCardMultiButton } from '@comps/complex/addCardMultiButton';
import { BackButton } from '@comps/complex/button/backButton';
import { CardList } from '@comps/complex/cards/cardList';
import { RoutineTag } from '@comps/complex/routineTag';
import { Flex } from '@comps/layout/flex';
import { Inset } from '@comps/layout/inset';
import { ActivityBubble } from '@comps/static/activityBubble';
import { Text } from '@comps/typography/text';
import { DARK_TRANSLUCENCY } from '@root/brand/css';
import { usePagePresence } from '@root/hooks/usePagePresence';
import { useActivities } from '@root/store/selectors/useActivities';
import { routinesActions, useRoutines } from '@root/store/slices/routines';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import styled, { css } from 'styled-components';

export const RoutineIdCards: React.FC = () => {
	const { rid } = useParams()
	const routines = useRoutines()
	const routine = useMemo(() => {
		if (!rid) return undefined
		return routines.at[rid]
	}, [rid, routines])
	const { show } = usePagePresence()
	const activities = useActivities()

	// useBoardStreaming(id)

	// useEffect(() => {
	// 	if (!id) return
	// 	dispatch(boardsActions.initialize())
	// 	// dispatch(cardsActions.initializeForBoardId({ boardId: id }))
	// }, [dispatch, id])
	// useEffect(() => {
	// 	if (!id) {
	// 		return
	// 	}

	// 	dispatch(boardsActions.loadToStore({ resourceId: id }))
	// }, [dispatch, id])

	// const handleNewBoardClick = useCallback(() => {
	// 	if (id) {
	// 		setQp({
	// 			flow: 'create-or-edit-board' as FlowID,
	// 			id: id,
	// 		})
	// 	}
	// }, [setQp, id])

	const dispatch = useDispatch()

	const activity = useMemo(() => {
		if (!routine?.activityId) return undefined

		return activities.at[routine?.activityId]
	}, [routine, activities])

	const activityIcon = useMemo(() => {
		if (!activity) return undefined
		return {
			color: activity.color,
			icon: activity.icon,
		}
	}, [activity])

	useEffect(() => {
		if (rid) {
			dispatch(routinesActions.loadCards({ routineId: rid }))
		}
	}, [rid, dispatch])
	if (!routine || !rid) return null

	return (
		<Holder present={true}>
			<Inset padding="card">
				<BackButton defaultBackTo={`/activities/${activity?.id}/routines`} supportsHoverDragAction color="subtle" />
			</Inset>
			<Content>
				<Flex spacing={15} align="center" justify="space-between">
					<Flex spacing={15} align="center">
						{activityIcon && <ActivityBubble activity={activityIcon} size="medium" status={'inactiveLight'} nuanceAttr="s2" />}
						<Text level="title30">{routine?.name || activity?.name}</Text>
					</Flex>
					<RoutineTag />
				</Flex>
				<CardButtonsHolder>
					<Text color='subtle'>Routine cards will be copied to any new session you create.</Text>
					<AddCardMultiButton resourceId={rid} resourceType={GrantableResourceEnum.ROUTINE} color="textual" />
				</CardButtonsHolder>
				<CardList resourceId={rid} resourceType={GrantableResourceEnum.ROUTINE} paddingTop={0} />
			</Content>
			{/* <Scroller className="card-grid-scroll grid-behaviour-container">
				<DelayCardGrid>
					<CardGrid resourceId={rid} resourceType={GrantableResourceEnum.ROUTINE} />
				</DelayCardGrid>
			</Scroller> */}
		</Holder>
	)
}

const CardButtonsHolder = styled.div`
	padding: 15px 15px 15px 30px;
	background-color: rgba(0, 0, 0, 0.042);
	border-radius: 20px;
	margin: 20px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Content = styled.div`
	max-width: 900px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	margin-top: -40px;

	.card-contents {
		box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
	}
`

const Tool = styled.div`
	position: absolute;
	top: 20px;
	left: 40px;
	height: 46px;
	padding: 0 7px;
	display: flex;
	align-items: center;
	${DARK_TRANSLUCENCY};
	border-radius: 16px;
	color: #fff;
	z-index: 10000;

	box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.05), 0 3px 8px 0 rgba(0, 0, 0, 0.15);

	&.right {
		left: auto;
		right: 48px;
	}
	&.middle {
		left: 50%;
		transform: translateX(-50%);
	}
`

const Holder = styled.div<{ present?: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	${(p) => p.theme.backgrounds.subtle.s3.css('background-color')};
	overflow: hidden;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	transform: translateY(30px);
	opacity: 0;
	will-change: transform;
	transition: ease transform 0.29s, ease opacity 0.29s;

	${(p) =>
		p.present &&
		css`
			transform: translateY(0);
			opacity: 1;
		`}
`
