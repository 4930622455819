// source: unions_v1/packets.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var packets_v1_activities_pb = require('../packets_v1/activities_pb.js');
goog.object.extend(proto, packets_v1_activities_pb);
var packets_v1_boards_pb = require('../packets_v1/boards_pb.js');
goog.object.extend(proto, packets_v1_boards_pb);
var packets_v1_cards_pb = require('../packets_v1/cards_pb.js');
goog.object.extend(proto, packets_v1_cards_pb);
var packets_v1_sessions_pb = require('../packets_v1/sessions_pb.js');
goog.object.extend(proto, packets_v1_sessions_pb);
var packets_v1_fragments_plain_text_pb = require('../packets_v1/fragments_plain_text_pb.js');
goog.object.extend(proto, packets_v1_fragments_plain_text_pb);
var packets_v1_fragments_completable_pb = require('../packets_v1/fragments_completable_pb.js');
goog.object.extend(proto, packets_v1_fragments_completable_pb);
var packets_v1_fragments_uploadable_pb = require('../packets_v1/fragments_uploadable_pb.js');
goog.object.extend(proto, packets_v1_fragments_uploadable_pb);
var packets_v1_vitals_pb = require('../packets_v1/vitals_pb.js');
goog.object.extend(proto, packets_v1_vitals_pb);
var packets_v1_routine_pb = require('../packets_v1/routine_pb.js');
goog.object.extend(proto, packets_v1_routine_pb);
var packets_v1_routine_schedule_pb = require('../packets_v1/routine_schedule_pb.js');
goog.object.extend(proto, packets_v1_routine_schedule_pb);
var packets_v1_settings_pb = require('../packets_v1/settings_pb.js');
goog.object.extend(proto, packets_v1_settings_pb);
goog.exportSymbol('proto.unions_v1.Packet', null, global);
goog.exportSymbol('proto.unions_v1.Packet.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unions_v1.Packet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.unions_v1.Packet.oneofGroups_);
};
goog.inherits(proto.unions_v1.Packet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unions_v1.Packet.displayName = 'proto.unions_v1.Packet';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.unions_v1.Packet.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11]];

/**
 * @enum {number}
 */
proto.unions_v1.Packet.TypeCase = {
  TYPE_NOT_SET: 0,
  ACTIVITY: 1,
  SESSION: 2,
  VITALS: 3,
  BOARD: 4,
  CARD: 5,
  FRAGMENT_PLAIN_TEXT: 6,
  FRAGMENT_COMPLETABLE: 7,
  FRAGMENT_UPLOADABLE: 8,
  ROUTINE: 9,
  ROUTINE_SCHEDULE: 10,
  SETTINGS: 11
};

/**
 * @return {proto.unions_v1.Packet.TypeCase}
 */
proto.unions_v1.Packet.prototype.getTypeCase = function() {
  return /** @type {proto.unions_v1.Packet.TypeCase} */(jspb.Message.computeOneofCase(this, proto.unions_v1.Packet.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unions_v1.Packet.prototype.toObject = function(opt_includeInstance) {
  return proto.unions_v1.Packet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unions_v1.Packet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unions_v1.Packet.toObject = function(includeInstance, msg) {
  var f, obj = {
    activity: (f = msg.getActivity()) && packets_v1_activities_pb.ActivityPacket.toObject(includeInstance, f),
    session: (f = msg.getSession()) && packets_v1_sessions_pb.SessionPacket.toObject(includeInstance, f),
    vitals: (f = msg.getVitals()) && packets_v1_vitals_pb.VitalsPacket.toObject(includeInstance, f),
    board: (f = msg.getBoard()) && packets_v1_boards_pb.BoardPacket.toObject(includeInstance, f),
    card: (f = msg.getCard()) && packets_v1_cards_pb.CardPacket.toObject(includeInstance, f),
    fragmentPlainText: (f = msg.getFragmentPlainText()) && packets_v1_fragments_plain_text_pb.FragmentPlainTextPacket.toObject(includeInstance, f),
    fragmentCompletable: (f = msg.getFragmentCompletable()) && packets_v1_fragments_completable_pb.FragmentCompletablePacket.toObject(includeInstance, f),
    fragmentUploadable: (f = msg.getFragmentUploadable()) && packets_v1_fragments_uploadable_pb.FragmentUploadablePacket.toObject(includeInstance, f),
    routine: (f = msg.getRoutine()) && packets_v1_routine_pb.RoutinePacket.toObject(includeInstance, f),
    routineSchedule: (f = msg.getRoutineSchedule()) && packets_v1_routine_schedule_pb.RoutineSchedulePacket.toObject(includeInstance, f),
    settings: (f = msg.getSettings()) && packets_v1_settings_pb.SettingsPacket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unions_v1.Packet}
 */
proto.unions_v1.Packet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unions_v1.Packet;
  return proto.unions_v1.Packet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unions_v1.Packet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unions_v1.Packet}
 */
proto.unions_v1.Packet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new packets_v1_activities_pb.ActivityPacket;
      reader.readMessage(value,packets_v1_activities_pb.ActivityPacket.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    case 2:
      var value = new packets_v1_sessions_pb.SessionPacket;
      reader.readMessage(value,packets_v1_sessions_pb.SessionPacket.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 3:
      var value = new packets_v1_vitals_pb.VitalsPacket;
      reader.readMessage(value,packets_v1_vitals_pb.VitalsPacket.deserializeBinaryFromReader);
      msg.setVitals(value);
      break;
    case 4:
      var value = new packets_v1_boards_pb.BoardPacket;
      reader.readMessage(value,packets_v1_boards_pb.BoardPacket.deserializeBinaryFromReader);
      msg.setBoard(value);
      break;
    case 5:
      var value = new packets_v1_cards_pb.CardPacket;
      reader.readMessage(value,packets_v1_cards_pb.CardPacket.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 6:
      var value = new packets_v1_fragments_plain_text_pb.FragmentPlainTextPacket;
      reader.readMessage(value,packets_v1_fragments_plain_text_pb.FragmentPlainTextPacket.deserializeBinaryFromReader);
      msg.setFragmentPlainText(value);
      break;
    case 7:
      var value = new packets_v1_fragments_completable_pb.FragmentCompletablePacket;
      reader.readMessage(value,packets_v1_fragments_completable_pb.FragmentCompletablePacket.deserializeBinaryFromReader);
      msg.setFragmentCompletable(value);
      break;
    case 8:
      var value = new packets_v1_fragments_uploadable_pb.FragmentUploadablePacket;
      reader.readMessage(value,packets_v1_fragments_uploadable_pb.FragmentUploadablePacket.deserializeBinaryFromReader);
      msg.setFragmentUploadable(value);
      break;
    case 9:
      var value = new packets_v1_routine_pb.RoutinePacket;
      reader.readMessage(value,packets_v1_routine_pb.RoutinePacket.deserializeBinaryFromReader);
      msg.setRoutine(value);
      break;
    case 10:
      var value = new packets_v1_routine_schedule_pb.RoutineSchedulePacket;
      reader.readMessage(value,packets_v1_routine_schedule_pb.RoutineSchedulePacket.deserializeBinaryFromReader);
      msg.setRoutineSchedule(value);
      break;
    case 11:
      var value = new packets_v1_settings_pb.SettingsPacket;
      reader.readMessage(value,packets_v1_settings_pb.SettingsPacket.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unions_v1.Packet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unions_v1.Packet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unions_v1.Packet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unions_v1.Packet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      packets_v1_activities_pb.ActivityPacket.serializeBinaryToWriter
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      packets_v1_sessions_pb.SessionPacket.serializeBinaryToWriter
    );
  }
  f = message.getVitals();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      packets_v1_vitals_pb.VitalsPacket.serializeBinaryToWriter
    );
  }
  f = message.getBoard();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      packets_v1_boards_pb.BoardPacket.serializeBinaryToWriter
    );
  }
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      packets_v1_cards_pb.CardPacket.serializeBinaryToWriter
    );
  }
  f = message.getFragmentPlainText();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      packets_v1_fragments_plain_text_pb.FragmentPlainTextPacket.serializeBinaryToWriter
    );
  }
  f = message.getFragmentCompletable();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      packets_v1_fragments_completable_pb.FragmentCompletablePacket.serializeBinaryToWriter
    );
  }
  f = message.getFragmentUploadable();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      packets_v1_fragments_uploadable_pb.FragmentUploadablePacket.serializeBinaryToWriter
    );
  }
  f = message.getRoutine();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      packets_v1_routine_pb.RoutinePacket.serializeBinaryToWriter
    );
  }
  f = message.getRoutineSchedule();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      packets_v1_routine_schedule_pb.RoutineSchedulePacket.serializeBinaryToWriter
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      packets_v1_settings_pb.SettingsPacket.serializeBinaryToWriter
    );
  }
};


/**
 * optional packets_v1.ActivityPacket activity = 1;
 * @return {?proto.packets_v1.ActivityPacket}
 */
proto.unions_v1.Packet.prototype.getActivity = function() {
  return /** @type{?proto.packets_v1.ActivityPacket} */ (
    jspb.Message.getWrapperField(this, packets_v1_activities_pb.ActivityPacket, 1));
};


/**
 * @param {?proto.packets_v1.ActivityPacket|undefined} value
 * @return {!proto.unions_v1.Packet} returns this
*/
proto.unions_v1.Packet.prototype.setActivity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.unions_v1.Packet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Packet} returns this
 */
proto.unions_v1.Packet.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Packet.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional packets_v1.SessionPacket session = 2;
 * @return {?proto.packets_v1.SessionPacket}
 */
proto.unions_v1.Packet.prototype.getSession = function() {
  return /** @type{?proto.packets_v1.SessionPacket} */ (
    jspb.Message.getWrapperField(this, packets_v1_sessions_pb.SessionPacket, 2));
};


/**
 * @param {?proto.packets_v1.SessionPacket|undefined} value
 * @return {!proto.unions_v1.Packet} returns this
*/
proto.unions_v1.Packet.prototype.setSession = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.unions_v1.Packet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Packet} returns this
 */
proto.unions_v1.Packet.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Packet.prototype.hasSession = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional packets_v1.VitalsPacket vitals = 3;
 * @return {?proto.packets_v1.VitalsPacket}
 */
proto.unions_v1.Packet.prototype.getVitals = function() {
  return /** @type{?proto.packets_v1.VitalsPacket} */ (
    jspb.Message.getWrapperField(this, packets_v1_vitals_pb.VitalsPacket, 3));
};


/**
 * @param {?proto.packets_v1.VitalsPacket|undefined} value
 * @return {!proto.unions_v1.Packet} returns this
*/
proto.unions_v1.Packet.prototype.setVitals = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.unions_v1.Packet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Packet} returns this
 */
proto.unions_v1.Packet.prototype.clearVitals = function() {
  return this.setVitals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Packet.prototype.hasVitals = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional packets_v1.BoardPacket board = 4;
 * @return {?proto.packets_v1.BoardPacket}
 */
proto.unions_v1.Packet.prototype.getBoard = function() {
  return /** @type{?proto.packets_v1.BoardPacket} */ (
    jspb.Message.getWrapperField(this, packets_v1_boards_pb.BoardPacket, 4));
};


/**
 * @param {?proto.packets_v1.BoardPacket|undefined} value
 * @return {!proto.unions_v1.Packet} returns this
*/
proto.unions_v1.Packet.prototype.setBoard = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.unions_v1.Packet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Packet} returns this
 */
proto.unions_v1.Packet.prototype.clearBoard = function() {
  return this.setBoard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Packet.prototype.hasBoard = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional packets_v1.CardPacket card = 5;
 * @return {?proto.packets_v1.CardPacket}
 */
proto.unions_v1.Packet.prototype.getCard = function() {
  return /** @type{?proto.packets_v1.CardPacket} */ (
    jspb.Message.getWrapperField(this, packets_v1_cards_pb.CardPacket, 5));
};


/**
 * @param {?proto.packets_v1.CardPacket|undefined} value
 * @return {!proto.unions_v1.Packet} returns this
*/
proto.unions_v1.Packet.prototype.setCard = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.unions_v1.Packet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Packet} returns this
 */
proto.unions_v1.Packet.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Packet.prototype.hasCard = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional packets_v1.FragmentPlainTextPacket fragment_plain_text = 6;
 * @return {?proto.packets_v1.FragmentPlainTextPacket}
 */
proto.unions_v1.Packet.prototype.getFragmentPlainText = function() {
  return /** @type{?proto.packets_v1.FragmentPlainTextPacket} */ (
    jspb.Message.getWrapperField(this, packets_v1_fragments_plain_text_pb.FragmentPlainTextPacket, 6));
};


/**
 * @param {?proto.packets_v1.FragmentPlainTextPacket|undefined} value
 * @return {!proto.unions_v1.Packet} returns this
*/
proto.unions_v1.Packet.prototype.setFragmentPlainText = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.unions_v1.Packet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Packet} returns this
 */
proto.unions_v1.Packet.prototype.clearFragmentPlainText = function() {
  return this.setFragmentPlainText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Packet.prototype.hasFragmentPlainText = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional packets_v1.FragmentCompletablePacket fragment_completable = 7;
 * @return {?proto.packets_v1.FragmentCompletablePacket}
 */
proto.unions_v1.Packet.prototype.getFragmentCompletable = function() {
  return /** @type{?proto.packets_v1.FragmentCompletablePacket} */ (
    jspb.Message.getWrapperField(this, packets_v1_fragments_completable_pb.FragmentCompletablePacket, 7));
};


/**
 * @param {?proto.packets_v1.FragmentCompletablePacket|undefined} value
 * @return {!proto.unions_v1.Packet} returns this
*/
proto.unions_v1.Packet.prototype.setFragmentCompletable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.unions_v1.Packet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Packet} returns this
 */
proto.unions_v1.Packet.prototype.clearFragmentCompletable = function() {
  return this.setFragmentCompletable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Packet.prototype.hasFragmentCompletable = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional packets_v1.FragmentUploadablePacket fragment_uploadable = 8;
 * @return {?proto.packets_v1.FragmentUploadablePacket}
 */
proto.unions_v1.Packet.prototype.getFragmentUploadable = function() {
  return /** @type{?proto.packets_v1.FragmentUploadablePacket} */ (
    jspb.Message.getWrapperField(this, packets_v1_fragments_uploadable_pb.FragmentUploadablePacket, 8));
};


/**
 * @param {?proto.packets_v1.FragmentUploadablePacket|undefined} value
 * @return {!proto.unions_v1.Packet} returns this
*/
proto.unions_v1.Packet.prototype.setFragmentUploadable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.unions_v1.Packet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Packet} returns this
 */
proto.unions_v1.Packet.prototype.clearFragmentUploadable = function() {
  return this.setFragmentUploadable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Packet.prototype.hasFragmentUploadable = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional packets_v1.RoutinePacket routine = 9;
 * @return {?proto.packets_v1.RoutinePacket}
 */
proto.unions_v1.Packet.prototype.getRoutine = function() {
  return /** @type{?proto.packets_v1.RoutinePacket} */ (
    jspb.Message.getWrapperField(this, packets_v1_routine_pb.RoutinePacket, 9));
};


/**
 * @param {?proto.packets_v1.RoutinePacket|undefined} value
 * @return {!proto.unions_v1.Packet} returns this
*/
proto.unions_v1.Packet.prototype.setRoutine = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.unions_v1.Packet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Packet} returns this
 */
proto.unions_v1.Packet.prototype.clearRoutine = function() {
  return this.setRoutine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Packet.prototype.hasRoutine = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional packets_v1.RoutineSchedulePacket routine_schedule = 10;
 * @return {?proto.packets_v1.RoutineSchedulePacket}
 */
proto.unions_v1.Packet.prototype.getRoutineSchedule = function() {
  return /** @type{?proto.packets_v1.RoutineSchedulePacket} */ (
    jspb.Message.getWrapperField(this, packets_v1_routine_schedule_pb.RoutineSchedulePacket, 10));
};


/**
 * @param {?proto.packets_v1.RoutineSchedulePacket|undefined} value
 * @return {!proto.unions_v1.Packet} returns this
*/
proto.unions_v1.Packet.prototype.setRoutineSchedule = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.unions_v1.Packet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Packet} returns this
 */
proto.unions_v1.Packet.prototype.clearRoutineSchedule = function() {
  return this.setRoutineSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Packet.prototype.hasRoutineSchedule = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional packets_v1.SettingsPacket settings = 11;
 * @return {?proto.packets_v1.SettingsPacket}
 */
proto.unions_v1.Packet.prototype.getSettings = function() {
  return /** @type{?proto.packets_v1.SettingsPacket} */ (
    jspb.Message.getWrapperField(this, packets_v1_settings_pb.SettingsPacket, 11));
};


/**
 * @param {?proto.packets_v1.SettingsPacket|undefined} value
 * @return {!proto.unions_v1.Packet} returns this
*/
proto.unions_v1.Packet.prototype.setSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.unions_v1.Packet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Packet} returns this
 */
proto.unions_v1.Packet.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Packet.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 11) != null;
};


goog.object.extend(exports, proto.unions_v1);
