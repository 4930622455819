import { ActivityBackground } from '@comps/activityBackground';
import { useActivityOrSession } from '@root/hooks/useActivityOrSession';
import { useSessionWithID } from '@root/store/selectors/useSessionWithID';
import { useUser } from '@root/store/selectors/useUser';
import { activitiesActions } from '@root/store/slices/activities';
import { cardsActions } from '@root/store/slices/cards';
import { syncSessionsActions } from '@root/store/slices/sync.sessions';
import { SessionOutletContextType } from '@root/views/Sessions.ID.useSessionOutletContext';
import { useActivities } from '@store/selectors/useActivities';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { SelectedSessionEmpty } from './selectedSession.empty';
import { SelectedSessionTopBar } from './selectedSessionTopBar';

export const SelectedSession: React.FC = () => {
	const { presentedSessionId } = useParams()
	const session = useSessionWithID(presentedSessionId)
	const activities = useActivities()
	const activity = session ? activities.at[session?.activityId] : undefined
	const dispatch = useDispatch()
	const user = useUser()

	useEffect(() => {
		if (!user?.uid) {
			if (user?.isFromLocal) {
				return
			}
		}

		if (presentedSessionId && user?.isFromLocal === false) {
			dispatch(syncSessionsActions.streamSession({ sessionId: presentedSessionId }))
		}
	}, [dispatch, presentedSessionId, user])

	useEffect(() => {
		if (!activity) {
			return
		}
		dispatch(activitiesActions.loadToStore({ resourceId: activity.id }))
	}, [dispatch, activity])

	useEffect(() => {
		if (presentedSessionId) dispatch(cardsActions.loadCardsForResourceId({ resourceId: presentedSessionId }))
	}, [presentedSessionId, dispatch])

	const place = useActivityOrSession()

	if (!activity || !session) {
		return <SelectedSessionEmpty />
	}

	return (
		<Holder data-session-viewbox>
			<CardShadowHolder>
				<Shadow />
				<ActiveCard>
					<SelectedSessionTopBar activity={activity} session={session} />
					<Outlet context={{ activity, session } satisfies SessionOutletContextType} />
					<ActivityBackground activityId={activity.id} blurred={place == 'activity'} />
				</ActiveCard>
			</CardShadowHolder>
		</Holder>
	)
}

const CardShadowHolder = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`

const Shadow = styled.div`
	position: absolute;
	width: calc(100% - 30px);
	height: calc(100% - 20px);
	background-color: rgba(0, 10, 20, 0.7);
	left: 15px;
	top: 40px;
	z-index: 0;
	filter: blur(40px);
`

const ActiveCard = styled.div`
	background-color: #000;
	width: 100%;
	height: 100%;
	position: relative;
	border-radius: 14px;
	overflow: hidden;
	z-index: 1;
	border-radius: 20px;
	box-shadow: inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.5);
`

// <{ bgColor?: keyof typeof ActivityColors }>

const Holder = styled.div`
	flex-grow: 1;
	position: sticky;
	top: 0;
	left: 0;
	height: 100vh;
	display: flex;
	padding: 20px;
	box-sizing: border-box;
	z-index: 500;
`
