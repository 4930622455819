import { RuneType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { NotificationRune } from '@clepside/clepsidejs/lib/runes_v1/notification_pb';
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb';
import { Database } from '@root/database';

import { RuneObject, SharingInvitationRuneObject } from './runes.types';
import { sharingActions } from './sharing';
import { getRuneObjectFromRemoteObject } from './sync.types';

export class SyncSharingInvitations {
    public static runeOrPacket = 'rune' as const

    public static type = RuneType.SHARING_INVITATION as const

    static fromSendables(res: Sendable.AsObject[] | undefined): RuneObject<any>[] {
        return res?.map((r) => getRuneObjectFromRemoteObject(r?.rune?.sharingInvitation))
            .filter((f): f is RuneObject<any> => f !== undefined) || []
    }


    static placeInStore(objects: SharingInvitationRuneObject[], fromSync?: boolean) {
        return sharingActions.store({ objects: objects.map(o => o.rune), fromSync })
    }

    static fromRune(res: NotificationRune.AsObject | undefined) {
        return getRuneObjectFromRemoteObject(res)
    }


    static database = Database.sharingInvitationRunes
}

