import { InnerPage } from '@comps/layout/innerPage'
import { PageHeader } from '@comps/layout/pageHeader'
import { Text } from '@comps/typography/text'
import { useTime } from '@root/hooks/useTime'
import { useSessions } from '@root/store/selectors/useSessions'
import { useSessionsFilter } from '@root/store/selectors/useSessionsFilter'
import { getDaySpan, getDuration } from '@root/utils/dates'
import { differenceInSeconds, endOfMonth, format, formatDistance, isBefore, startOfMonth } from 'date-fns'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ActivityInsightsChartD3 } from './Activity.ID.Insights.chart3d'

export const ActivityInsights = () => {
	const { id } = useParams()
	const now = useTime('minute')
	const defaultDates = useMemo((): [Date, Date] => {
		const moment = new Date()
		return [startOfMonth(moment), endOfMonth(moment)]
	}, [])
	const yearlySessions = useSessions('for-activity-<0>-year-<1>', id, format(now, 'yyyy'))
	const sessions = useSessionsFilter(yearlySessions, startOfMonth(now).getTime(), endOfMonth(now).getTime())

	const [qP, setQp] = useSearchParams()

	const stats = useMemo(() => {
		let count = 0
		let total = 0

		sessions.all.forEach((sessionId) => {
			const session = sessions.at[sessionId]
			const duration = differenceInSeconds(session.end, session.start)
			count++
			total += duration
		})
		return { count, total }
	}, [sessions])

	const averageSession = useMemo(() => {
		let totalTime = 0
		let count = 0

		sessions.all.forEach((sid) => {
			const session = sessions.at[sid]
			totalTime += differenceInSeconds(session.end, session.start)
			count += 1
		})

		return Math.floor(totalTime / count)
	}, [sessions])

	const timeSinceLast = useMemo(() => {
		let last: Date | undefined = undefined

		sessions.all.forEach((sid) => {
			const session = sessions.at[sid]
			if (isBefore(session.end, now)) {
				if (!last || session.end > last) {
					last = session.end
				}
			}
		})

		if (!last) {
			return 0
		} else {
			return formatDistance(last, now)
		}
	}, [now, sessions])

	const percentage = useMemo(() => {
		return (stats.total / differenceInSeconds(defaultDates[1], defaultDates[0])).toFixed(2)
	}, [stats, defaultDates])

	const streak = useMemo(() => {
		const dayz: number[] = []

		sessions.all.forEach((sid) => {
			const session = sessions.at[sid]
			for (const span of getDaySpan(session.start, session.end)) {
				dayz.push(span)
			}
		})

		dayz.sort((a, b) => (a > b ? 1 : -1))

		let maxStreak = 0
		let streakCount = 0

		for (const [i, day] of dayz.entries()) {
			if (day == dayz[i - 1] + 1) {
				if (streakCount == 0) streakCount = 1
				streakCount++
				if (streakCount > maxStreak) maxStreak = streakCount
			} else {
				streakCount = 0
			}
		}
		return maxStreak
	}, [sessions])

	return (
		<InnerPage>
			{/* <Flex justify="space-between" align="center">
				<Text level="title30">Insights</Text>
				<DatePeriodButton period={defaultDates} />
			</Flex> */}
			<PageHeader title="Insights" extraBottomSpace />
			<ActivityInsightsChartD3 period={defaultDates} activityId={id} sessions={sessions} />
			<BlocksHolder>
				<Block>
					<Text level="title30">{stats.count} sessions</Text>
					<Text block level="body" bold>
						Sessions Count
					</Text>
					<Text color="subtle">Total number of sessions for the past month</Text>
				</Block>
				<Block>
					<Text level="title30">{getDuration(stats.total)}</Text>
					<Text block level="body" bold>
						Total Time Spent
					</Text>
					<Text color="subtle">Total time spent during the past month</Text>
				</Block>
				<Block>
					<Text level="title30">{getDuration(averageSession)}</Text>
					<Text block level="body" bold>
						Average Session Length
					</Text>
					<Text color="subtle">The duration of the average session in the past month</Text>
				</Block>
				<Block>
					<Text level="title30">{timeSinceLast} ago</Text>
					<Text block level="body" bold>
						Last Session
					</Text>
					<Text color="subtle">The total time that went by since your last session</Text>
				</Block>
				<Block highlighted>
					<Text level="title30">{percentage}%</Text>
					<Text block level="body" bold>
						Percentage of Month spent
					</Text>
					<Text color="subtle">The fraction of your time that has been spent on Clepside during the past month</Text>
				</Block>
				<Block highlighted>
					<Text level="title30">{streak}</Text>
					<Text block level="body" bold>
						Longest Streak
					</Text>
					<Text color="subtle">How many consecutive days you've had at least 1 Clepside session</Text>
				</Block>
			</BlocksHolder>
		</InnerPage>
	)
}

const BlocksHolder = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-row-gap: 20px;
	grid-column-gap: 20px;
`

const Block = styled.div<{ highlighted?: boolean }>`
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: min-content min-content min-content;
	grid-row-gap: 15px;
	${(p) =>
		p.highlighted &&
		css`
			grid-column: span 2;
			${p.theme.backgrounds.subtle.r.css('background-color')};
		`}
	padding: 40px 30px;
	border-radius: 20px;
`
