import { Icon } from '@comps/static/icon'
import { Text } from '@comps/typography/text'
import { useDragAndDropHover } from '@root/cores/useDragAndDropHover'
import { useActivityOrSession } from '@root/hooks/useActivityOrSession'
import { useRefTaker } from '@root/hooks/useRefTaker'
import { ActivityData } from '@root/store/slices/activitiesTypes'
import { InstancedSessionData } from '@root/store/slices/sessionsTypes'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const TopBarSwitch: React.FC<{ activity: ActivityData; session: InstancedSessionData; center?: boolean }> = ({
	activity,
	session,
	center
}) => {
	const [sessionButtonRef, setSessionButtonRef] = useRefTaker()
	const [activityButtonRef, setActivityButtonRef] = useRefTaker()
	// const activityCards = useCardsByResourceId(activity.id)
	// const sessionCards = useCardsByResourceId(session.id)

	useDragAndDropHover(sessionButtonRef, true)
	useDragAndDropHover(activityButtonRef, true)

	const place = useActivityOrSession()

	return (
		<Holder center={center} className='noseldis'>
			<Background className={place} center={center} />
			<Link replace to="session" ref={setSessionButtonRef} center={center} >
				{center == true && <Icon type="session" />}
				<Text level="label" block>
					Session
				</Text>
				{/* {sessionCards.all.length ? ` (${sessionCards.all.length})` : ''} */}
			</Link>
			<Link replace to="activity" ref={setActivityButtonRef} center={center} >
				{center == true && <Icon type="activity" />}
				<Text level="label" block>
					Activity
				</Text>
				{/* {activityCards.all.length ? ` (${activityCards.all.length})` : ''} */}
			</Link>
		</Holder>
	)
}

const RADIUS = 10

const Background = styled.div<{ isActivity?: boolean; center?: boolean }>`
	position: absolute;
	background-color: red;
	z-index: -1;
	transition: transform ease-out 0.25s;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.05);
	border-radius: 10px;
	${(p) => p.theme.backgrounds.darkTranslucencyElevated.r.css('background-color')};

	&.activity {
		transform: translateX(100%);
	}
	${(p) =>
		p.center
			? css`
					width: 130px;
					height: calc(100% - 8px);
			  `
			: css`
					width: 86px;
					height: calc(100% - 16px);
			  `}
`

const Link = styled(NavLink) <any>`
	padding: 9px 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-color: transparent;
	${(p) => p.theme.text.darkTranslucency.s2.css('color')};

	&:hover {
		${(p) => p.theme.text.darkTranslucencySubtle.t1.css('color')};
	}

	&.active {
		border-color: rgba(0, 0, 0, 0.25);
		${(p) => p.theme.text.darkTranslucency.t2.css('color')};
	}

	& > *:first-child {
		margin-right: 6px;
	}

	${(p) =>
		p.center
			? css`
					width: 130px;
			  `
			: css`
					width: 86px;
			  `}/* &:first-child {
		border-top-left-radius: ${RADIUS}px;
		border-bottom-left-radius: ${RADIUS}px;
	}

	&:last-child {
		border-top-right-radius: ${RADIUS}px;
		border-bottom-right-radius: ${RADIUS}px;
	} */
`

const Holder = styled.div<{ center?: boolean }>`
	display: flex;
	flex-direction: row;
	z-index: 1;
	${(p) => p.theme.backgrounds.darkTranslucencyShaded.r.css('background-color')};

	${(p) =>
		p.center
			? css`
					padding: 4px;
					border-radius: 13px;
					transform: translateX(-50%) translateY(-50%);
					position: absolute;
					top: 50%;

					left: 50%;
			  `
			: css`
					padding: 3px;
					border-radius: 13px;
					box-sizing: border-box;
			  `}
`
