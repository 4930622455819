import { useMemo } from 'react';
import styled from 'styled-components';

const barStroke = 4
const barSize = 18

export const FillingCircle: React.FC<{ progress: number }> = ({ progress }) => {
	const offset = useMemo(() => {
		return 100 - progress * 100
	}, [progress])

	return (
		<SVG viewBox={`0 0 ${barSize * 2} ${barSize * 2}`}>
			<BarBackground cx={barSize} cy={barSize} r={barSize - barStroke} />
			<BarProgress offset={offset} cx={barSize} cy={barSize} r={barSize - barStroke} />
		</SVG>
	)
}

const SVG = styled.svg`
	height: ${barSize}px;
	transform: rotate(-90deg);
	width: ${barSize}px;
`

const BarBackground = styled.circle`
	fill: none;
	${(p) => p.theme.border.translucent.r.css('stroke')}
	stroke-width: ${barStroke}px;
`

const BarProgress = styled.circle<{ offset: number }>`
	fill: none;
	${(p) => p.theme.text.action.r.css('stroke')}
	stroke-dasharray: 100 100;
	stroke-dashoffset: 100;
	stroke-linecap: round;
	stroke-width: ${barStroke}px;
	stroke-dashoffset: ${(p) => p.offset};
`
