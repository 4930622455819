import { Flex } from '@comps/layout/flex'
import { FontStyles, Text } from '@comps/typography/text'
import { useD3TooltipBuilder } from '@root/hooks/useD3TooltipBuilder'
import { useRefTaker } from '@root/hooks/useRefTaker'
import { Collection } from '@root/store/commonTypes'
import { useActivity } from '@root/store/selectors/useActivities'
import { ActivityColors } from '@root/store/slices/activities.colors.types'
import { InstancedSessionData } from '@root/store/slices/sessionsTypes'
import { getDayIndex, getDaySpan, getDuration } from '@root/utils/dates'
import * as d3 from 'd3'
import { addDays, differenceInDays, differenceInSeconds, endOfDay, format, isAfter, isBefore, startOfDay } from 'date-fns'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

export const ActivityInsightsChartD3: React.FC<{
	activityId?: string
	sessions: Collection<InstancedSessionData>
	type?: 'hours' | 'sessions'
	period: [Date, Date]
}> = ({ activityId, sessions, type = 'hours', period }) => {
	const [ref, setRef] = useRefTaker()
	const activity = useActivity(activityId)

	const countByDate = useMemo(() => {
		const acc: { [dayIndex: string]: number } = {}

		sessions.all.forEach((sId) => {
			const s = sessions.at[sId]
			const span = getDaySpan(s.start, s.end)

			for (const date of span) {
				if (!acc[date]) acc[date] = 0
				acc[date] = acc[date] + 1
			}
		})

		return acc
	}, [sessions])

	const { tooltip, enableTooltip, tooltipHover } = useD3TooltipBuilder<number>(
		useCallback(
			(hover) => {
				const date = addDays(period[0], hover.values[0] - 1)
				const dayIndex = getDayIndex(date)

				return (
					<Flex direction="column" spacing={0}>
						<Text color="toast.text" bold>
							{format(date, 'dd MMMM')}
						</Text>
						<Text color="toast.textSubtle">
							Sessions: <Text color="toast.text">{countByDate[dayIndex]}</Text>
						</Text>
						<Text color="toast.textSubtle">
							Time spent: <Text color="toast.text">{getDuration(hover.values[1])}</Text>
						</Text>
					</Flex>
				)
			},
			[period, countByDate]
		)
	)

	const sessionHoursByDate = useMemo(() => {
		const dates = []
		const diffDays = differenceInDays(period[1], period[0])

		for (let i = 0; i < diffDays; i++) {
			const date = addDays(period[0], i)
			const dayIndex = getDayIndex(date)
			const sessionsForThisDay = sessions.all.filter((k) => {
				const s = sessions.at[k]
				return s.daySpan.includes(dayIndex)
			}, [])
			const sOD = startOfDay(date)
			const eOD = endOfDay(date)
			let acc = 0

			sessionsForThisDay.forEach((sId) => {
				const s = sessions.at[sId]
				let start = s.start
				let end = s.end
				if (isBefore(s.start, sOD)) {
					start = sOD
				}
				if (isAfter(s.end, eOD)) {
					end = eOD
				}
				acc += differenceInSeconds(end, start)
			})

			dates.push([i, acc])
		}
		return dates
	}, [sessions, period])

	const colors = useMemo(() => {
		if (!activity?.color) return undefined
		return ActivityColors[activity.color]
	}, [activity])

	const mounted = useRef(false)
	const [dimensions, setDimensions] = useState<any>(undefined)

	useEffect(() => {
		if (!ref) return
		if (mounted.current) return
		if (!dimensions) return

		if (!colors) return

		const width = ref.getBoundingClientRect().width
		const height = ref.getBoundingClientRect().height
		// const peak = sessionHoursByDate.reduce((iMax, x, i, arr) => (x[1] > iMax ? arr[i][1] : iMax), 0)

		const paddedData: [number, number][] = [
			[0, 0],
			...sessionHoursByDate.map((k) => [k[0] + 1, k[1]] as [number, number]),
			[sessionHoursByDate.length + 2, 0],
		]

		const svg = d3.select(ref).append('svg').attr('width', width).attr('height', height)

		const x = d3
			.scaleLinear()
			.domain([0, sessionHoursByDate.length + 2])
			.range([0, width])
		const y = d3.scaleLinear().domain([0, 86400]).range([height, 0])

		const yAxisGrid = d3
			.axisLeft(y)
			.tickSize(-width)
			.tickFormat(() => '')
			.ticks(12)

		svg.append('g').attr('class', 'grid').call(yAxisGrid).select('.domain').remove()

		const xAxisGrid = d3
			.axisBottom(x)
			.tickSize(-height)
			.tickFormat(() => '')
			.ticks(paddedData.length / 7)

		svg.append('g')
			.attr('class', 'gridt')
			.attr('transform', 'translate(0,' + height + ')')
			.call(xAxisGrid)
			.select('.domain')
			.remove()

		// Add the area
		svg.append('path')
			.data([paddedData])
			.attr('fill', colors.inactiveLight.background.r.hex)
			.attr(
				'd',
				d3
					.area()
					.x(function (d) {
						return x(d[0])
					})
					.y0(y(0))
					.y1(function (d) {
						return y(d[1])
					})
			)

		// Add the line
		svg.append('path')
			.data([paddedData])
			.attr('fill', 'transparent')
			.attr('stroke', colors.active.background.r.hex)
			.attr('strokeWidth', 1)
			.attr(
				'd',
				d3
					.line()
					.x(function (d) {
						return x(d[0])
					})
					.y(function (d) {
						return y(d[1])
					})
			)

		// Add the circles
		const allCircleElements = svg
			.selectAll('myCircles')
			.data(paddedData.filter((d) => d[1]))
			.enter()
			.append('circle')
			.attr('class', 'circle')

			.attr('fill', '#fff')
			.attr('strokeWidth', '1px')
			.attr('stroke', colors.active.background.r.hex)
			.attr('date', function (d) {
				return d[0]
			})
			.attr('cx', function (d) {
				return x(d[0])
			})
			.attr('cy', function (d) {
				return y(d[1])
			})
			.attr('r', 5)

		const bisectDate = d3.bisector(function (d: any) {
			return d[0]
		}).left

		enableTooltip(svg, width, height, (setHover, event) => {
			const x0 = x.invert(d3.pointer(event)[0]),
				i = bisectDate(paddedData, x0, 1),
				d0 = paddedData[i - 1],
				d1 = paddedData[i],
				d = x0 - d0[0] > d1[0] - x0 ? d1 : d0

			const closestX = x0 - d0[0] > d1[0] - x0 ? d1[0] : d0[0]
			let broom: any = undefined
			allCircleElements.each(function (k, nthCircle) {
				const c = this as any
				if (k[0] === closestX) {
					broom = d3.select(c)
					broom.attr('fill', colors?.active.background.r.hex)
					broom.attr('stroke', colors?.active.background.r.hex)
				} else {
					d3.select(c).attr('stroke', colors?.active.background.r.hex)
					d3.select(c).attr('fill', 'white')
				}
			})

			if (broom) {
				const cx = broom.attr('cx')
				const cy = broom.attr('cy')

				setHover({
					values: [d[0], d[1]],
					coords: [Math.round(cx), Math.round(cy)],
				})
			} else setHover(undefined)
		})

		return () => {
			svg.remove()
		}
	}, [ref, colors, enableTooltip, dimensions, sessionHoursByDate])

	useEffect(() => {
		if (!ref) return
		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				setDimensions({
					width: entry.contentRect.width,
					height: entry.contentRect.height,
				})
			})
		})

		resizeObserver.observe(ref)
		return () => {
			resizeObserver.unobserve(ref)
		}
	}, [ref])

	if (!activity) return null

	return (
		<>
			<ChartHolder>
				<ChartFrame key={'chart'} ref={setRef} />
				{tooltip}
			</ChartHolder>
			<Labels sessions={['', ...sessionHoursByDate, '']}>
				{['', ...sessionHoursByDate, ''].map((k, i, arr) => {
					if (i === 0) return <span key={i + 1} />
					if (i === arr.length - 1) return <span key={i + 1} />
					return (
						<Label active={tooltipHover?.values[0] === i} key={i + 1}>
							{i + 1}
						</Label>
					)
				})}
			</Labels>
		</>
	)
}

export const ChartHolder = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
`
export const ChartFrame = styled.div`
	border: 1px solid ${(p) => p.theme.border.subtle.r.color};
	padding: 0;
	border-radius: 24px;
	overflow: hidden;
	width: 100%;
	position: relative;
	padding: 0;
	height: 400px;

	.tick {
		color: ${(p) => p.theme.border.subtle.r.color};
	}

	.circle {
		filter: drop-shadow(0px 2px 2px rgb(0 0 0 / 0.2));
	}
`

const Labels = styled.div<{ sessions: any }>`
	display: grid;
	width: 100%;
	padding: 0 1px;
	grid-template-columns: ${(p) => p.sessions.map((k: any) => '1fr ')};
	margin-bottom: 50px;
	margin-top: 10px;
	position: relative;
	transform: translateX(-${(p) => 100 / p.sessions.length / 2}%);
	left: 1px;
`

const Label = styled.div<{ active?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	${FontStyles.tiny};
	height: 26px;
	border-radius: 10px;
	text-align: center;
	line-height: 0;
	&:nth-child(even) {
		${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
	}

	${(p) =>
		p.active &&
		css`
			background-color: ${p.theme.backgrounds.accent.r.color} !important;
			color: ${p.theme.standards.white.r.color} !important;
		`}
`
