import { createSlice, PayloadAction } from '@reduxjs/toolkit'
/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, put } from 'redux-saga/effects'
import { InitialErrorToastsState } from './errorToastsTypes'
import { collectionDelete, collectionInsert } from '@root/utils/general'
import { ULID } from '../commonTypes'

const errorToastsSlice = createSlice({
	name: 'errorToasts',
	initialState: InitialErrorToastsState,
	reducers: {
		push: (state, { payload }: PayloadAction<{
            message: string | React.ReactNode,
            id: string,
            type?: 'server' | 'client',
            context?: any,
            duration?: 'shorter' | 'normal' | 'longer' | 'infinite',
        }>) => {	
            collectionInsert(state, payload.id, payload)
        },
        pop: (state, { payload }: PayloadAction<{ id: ULID }>) => {	
            collectionDelete(state, payload.id)
		},
	},
})


export const errorToastsSagas = {
	*push({ payload }: ReturnType<typeof errorToastsSlice.actions.push>) {
		switch (payload.duration) {
            case 'shorter':
                yield delay(4000)
                break
            case 'longer':
                yield delay(20000)
                break
            default:
                yield delay(8000)
        }

        yield put(errorToastsActions.pop({ id: payload.id }))
    }
}

export const errorToastsActions = errorToastsSlice.actions
export const errorToastsReducers = errorToastsSlice.reducer
