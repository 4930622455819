import { isAfter, isSameDay } from 'date-fns';
import { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@comps/interactive/button';
import { Icon } from '@comps/static/icon';
import { useTime } from '@hooks/useTime';

export const CalendarDayNavigationButtons: React.FC<{ onDayNavigationClicked: MouseEventHandler<HTMLButtonElement>; forDate: Date }> = ({
	onDayNavigationClicked,
	forDate,
}) => {
	const date = useTime('minute')
	const currentDate = new Date(date)

	return (
		<ButtonsHolder>
			<LeftButton color="outlined" onClick={onDayNavigationClicked} data-direction="left">
				<Icon type="arrow" />
			</LeftButton>
			<RightButton color="outlined" onClick={onDayNavigationClicked} data-direction="right">
				<Icon type="arrow" />
			</RightButton>
			{isSameDay(forDate, currentDate) ? null : (
				<FarButton
					color="clepside"
					showOnLeftSide={isAfter(forDate, currentDate)}
					onClick={onDayNavigationClicked}
					data-direction="reset"
				>
					<Icon type="doubleArrow" offsetX={1} offsetY={0} />
				</FarButton>
			)}
		</ButtonsHolder>
	)
}

const commonStyles = css`
	${(p) => p.theme.text.action.r.css('color')};
	padding: 9px 10px;
	width: auto;
	display: flex;
	align-items: center;
	border-radius: 12px;
	justify-content: center;

	&:focus {
		z-index: 10;
	}
`

const FarButton = styled(Button).attrs((p: any) => ({
	showOnLeftSide: p.showOnLeftSide || false,
}))`
	${commonStyles};

	position: absolute !important;
	border-radius: 7px !important;
	right: -10px;
	top: -10px;
	height: 16px;
	padding: 0;
	width: 22px;
	box-shadow: 0px 3px 4px rgba(10, 20, 30, 0.3);
	z-index: 100;
	transform-origin: center center;

	${(p) =>
		p.showOnLeftSide &&
		css`
			right: auto;
			left: -10px;
			transform: rotate(180deg);
			box-shadow: 0px -3px 4px rgba(10, 20, 30, 0.3);
		`}
`

const LeftButton = styled(Button)`
	${commonStyles};
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	/* border-right: none !important; */
	margin-right: -1px;

	& > * {
		transform: translateX(-1px) rotate(-180deg);
	}

	&:before {
		border-top-right-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
	}
`

const RightButton = styled(Button)`
	${commonStyles};
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	& > * {
		transform: translateX(1px);
	}

	&:before {
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: 0px !important;
	}
`

const ButtonsHolder = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	position: relative;
`
