import { useMemo } from 'react';

import { Collection } from '../commonTypes';
import { InstancedSessionData } from '../slices/sessionsTypes';

export const useSessionsForActivity = (forActivityId?: string) => {
	const allSessions: any = useMemo(
		() =>
			({
				at: {},
				all: [],
			} as any),
		[]
	)

	return useMemo((): Collection<InstancedSessionData> => {
		if (!forActivityId) return { all: [], at: {} }
		const activitySessions = allSessions.all.filter((id: string) => {
			const session = allSessions.at[id]
			return session.activityId === forActivityId
		})
		return {
			all: activitySessions,
			at: activitySessions.reduce((acc: any, sId: string) => {
				if (!acc[sId]) acc[sId] = allSessions.at[sId]
				return acc
			}, {} as any),
		}
	}, [forActivityId, allSessions])
}
