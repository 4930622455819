// source: services/watch_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var unions_v1_sendable_pb = require('../unions_v1/sendable_pb.js');
goog.object.extend(proto, unions_v1_sendable_pb);
goog.exportSymbol('proto.watch_v1.ProcessSendableRequest', null, global);
goog.exportSymbol('proto.watch_v1.ProcessSendableResponse', null, global);
goog.exportSymbol('proto.watch_v1.WatchRequest', null, global);
goog.exportSymbol('proto.watch_v1.WatchResponse', null, global);
goog.exportSymbol('proto.watch_v1.WatchResponse.ResponseTypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.watch_v1.WatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.watch_v1.WatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.watch_v1.WatchRequest.displayName = 'proto.watch_v1.WatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.watch_v1.WatchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.watch_v1.WatchResponse.oneofGroups_);
};
goog.inherits(proto.watch_v1.WatchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.watch_v1.WatchResponse.displayName = 'proto.watch_v1.WatchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.watch_v1.ProcessSendableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.watch_v1.ProcessSendableRequest.repeatedFields_, null);
};
goog.inherits(proto.watch_v1.ProcessSendableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.watch_v1.ProcessSendableRequest.displayName = 'proto.watch_v1.ProcessSendableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.watch_v1.ProcessSendableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.watch_v1.ProcessSendableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.watch_v1.ProcessSendableResponse.displayName = 'proto.watch_v1.ProcessSendableResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.watch_v1.WatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.watch_v1.WatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.watch_v1.WatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.watch_v1.WatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.watch_v1.WatchRequest}
 */
proto.watch_v1.WatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.watch_v1.WatchRequest;
  return proto.watch_v1.WatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.watch_v1.WatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.watch_v1.WatchRequest}
 */
proto.watch_v1.WatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.watch_v1.WatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.watch_v1.WatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.watch_v1.WatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.watch_v1.WatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.watch_v1.WatchRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.watch_v1.WatchRequest} returns this
 */
proto.watch_v1.WatchRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.watch_v1.WatchResponse.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.watch_v1.WatchResponse.ResponseTypeCase = {
  RESPONSE_TYPE_NOT_SET: 0,
  SENDABLE: 1,
  STREAM_FINISHED: 2,
  STREAM_TIMED_OUT: 3,
  STREAM_STARTED_WITH_STREAM_ID: 4
};

/**
 * @return {proto.watch_v1.WatchResponse.ResponseTypeCase}
 */
proto.watch_v1.WatchResponse.prototype.getResponseTypeCase = function() {
  return /** @type {proto.watch_v1.WatchResponse.ResponseTypeCase} */(jspb.Message.computeOneofCase(this, proto.watch_v1.WatchResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.watch_v1.WatchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.watch_v1.WatchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.watch_v1.WatchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.watch_v1.WatchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendable: (f = msg.getSendable()) && unions_v1_sendable_pb.Sendable.toObject(includeInstance, f),
    streamFinished: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    streamTimedOut: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    streamStartedWithStreamId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.watch_v1.WatchResponse}
 */
proto.watch_v1.WatchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.watch_v1.WatchResponse;
  return proto.watch_v1.WatchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.watch_v1.WatchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.watch_v1.WatchResponse}
 */
proto.watch_v1.WatchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new unions_v1_sendable_pb.Sendable;
      reader.readMessage(value,unions_v1_sendable_pb.Sendable.deserializeBinaryFromReader);
      msg.setSendable(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreamFinished(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreamTimedOut(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamStartedWithStreamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.watch_v1.WatchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.watch_v1.WatchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.watch_v1.WatchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.watch_v1.WatchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      unions_v1_sendable_pb.Sendable.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional unions_v1.Sendable sendable = 1;
 * @return {?proto.unions_v1.Sendable}
 */
proto.watch_v1.WatchResponse.prototype.getSendable = function() {
  return /** @type{?proto.unions_v1.Sendable} */ (
    jspb.Message.getWrapperField(this, unions_v1_sendable_pb.Sendable, 1));
};


/**
 * @param {?proto.unions_v1.Sendable|undefined} value
 * @return {!proto.watch_v1.WatchResponse} returns this
*/
proto.watch_v1.WatchResponse.prototype.setSendable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.watch_v1.WatchResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.watch_v1.WatchResponse} returns this
 */
proto.watch_v1.WatchResponse.prototype.clearSendable = function() {
  return this.setSendable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.watch_v1.WatchResponse.prototype.hasSendable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool stream_finished = 2;
 * @return {boolean}
 */
proto.watch_v1.WatchResponse.prototype.getStreamFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.watch_v1.WatchResponse} returns this
 */
proto.watch_v1.WatchResponse.prototype.setStreamFinished = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.watch_v1.WatchResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.watch_v1.WatchResponse} returns this
 */
proto.watch_v1.WatchResponse.prototype.clearStreamFinished = function() {
  return jspb.Message.setOneofField(this, 2, proto.watch_v1.WatchResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.watch_v1.WatchResponse.prototype.hasStreamFinished = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool stream_timed_out = 3;
 * @return {boolean}
 */
proto.watch_v1.WatchResponse.prototype.getStreamTimedOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.watch_v1.WatchResponse} returns this
 */
proto.watch_v1.WatchResponse.prototype.setStreamTimedOut = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.watch_v1.WatchResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.watch_v1.WatchResponse} returns this
 */
proto.watch_v1.WatchResponse.prototype.clearStreamTimedOut = function() {
  return jspb.Message.setOneofField(this, 3, proto.watch_v1.WatchResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.watch_v1.WatchResponse.prototype.hasStreamTimedOut = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string stream_started_with_stream_id = 4;
 * @return {string}
 */
proto.watch_v1.WatchResponse.prototype.getStreamStartedWithStreamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.watch_v1.WatchResponse} returns this
 */
proto.watch_v1.WatchResponse.prototype.setStreamStartedWithStreamId = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.watch_v1.WatchResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.watch_v1.WatchResponse} returns this
 */
proto.watch_v1.WatchResponse.prototype.clearStreamStartedWithStreamId = function() {
  return jspb.Message.setOneofField(this, 4, proto.watch_v1.WatchResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.watch_v1.WatchResponse.prototype.hasStreamStartedWithStreamId = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.watch_v1.ProcessSendableRequest.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.watch_v1.ProcessSendableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.watch_v1.ProcessSendableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.watch_v1.ProcessSendableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.watch_v1.ProcessSendableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    forUserIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    payloadList: jspb.Message.toObjectList(msg.getPayloadList(),
    unions_v1_sendable_pb.Sendable.toObject, includeInstance),
    excludingClientIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.watch_v1.ProcessSendableRequest}
 */
proto.watch_v1.ProcessSendableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.watch_v1.ProcessSendableRequest;
  return proto.watch_v1.ProcessSendableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.watch_v1.ProcessSendableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.watch_v1.ProcessSendableRequest}
 */
proto.watch_v1.ProcessSendableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addForUserIds(value);
      break;
    case 2:
      var value = new unions_v1_sendable_pb.Sendable;
      reader.readMessage(value,unions_v1_sendable_pb.Sendable.deserializeBinaryFromReader);
      msg.addPayload(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addExcludingClientIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.watch_v1.ProcessSendableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.watch_v1.ProcessSendableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.watch_v1.ProcessSendableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.watch_v1.ProcessSendableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getPayloadList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      unions_v1_sendable_pb.Sendable.serializeBinaryToWriter
    );
  }
  f = message.getExcludingClientIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * repeated string for_user_ids = 1;
 * @return {!Array<string>}
 */
proto.watch_v1.ProcessSendableRequest.prototype.getForUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.watch_v1.ProcessSendableRequest} returns this
 */
proto.watch_v1.ProcessSendableRequest.prototype.setForUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.watch_v1.ProcessSendableRequest} returns this
 */
proto.watch_v1.ProcessSendableRequest.prototype.addForUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.watch_v1.ProcessSendableRequest} returns this
 */
proto.watch_v1.ProcessSendableRequest.prototype.clearForUserIdsList = function() {
  return this.setForUserIdsList([]);
};


/**
 * repeated unions_v1.Sendable payload = 2;
 * @return {!Array<!proto.unions_v1.Sendable>}
 */
proto.watch_v1.ProcessSendableRequest.prototype.getPayloadList = function() {
  return /** @type{!Array<!proto.unions_v1.Sendable>} */ (
    jspb.Message.getRepeatedWrapperField(this, unions_v1_sendable_pb.Sendable, 2));
};


/**
 * @param {!Array<!proto.unions_v1.Sendable>} value
 * @return {!proto.watch_v1.ProcessSendableRequest} returns this
*/
proto.watch_v1.ProcessSendableRequest.prototype.setPayloadList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.unions_v1.Sendable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.unions_v1.Sendable}
 */
proto.watch_v1.ProcessSendableRequest.prototype.addPayload = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.unions_v1.Sendable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.watch_v1.ProcessSendableRequest} returns this
 */
proto.watch_v1.ProcessSendableRequest.prototype.clearPayloadList = function() {
  return this.setPayloadList([]);
};


/**
 * repeated string excluding_client_ids = 3;
 * @return {!Array<string>}
 */
proto.watch_v1.ProcessSendableRequest.prototype.getExcludingClientIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.watch_v1.ProcessSendableRequest} returns this
 */
proto.watch_v1.ProcessSendableRequest.prototype.setExcludingClientIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.watch_v1.ProcessSendableRequest} returns this
 */
proto.watch_v1.ProcessSendableRequest.prototype.addExcludingClientIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.watch_v1.ProcessSendableRequest} returns this
 */
proto.watch_v1.ProcessSendableRequest.prototype.clearExcludingClientIdsList = function() {
  return this.setExcludingClientIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.watch_v1.ProcessSendableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.watch_v1.ProcessSendableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.watch_v1.ProcessSendableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.watch_v1.ProcessSendableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.watch_v1.ProcessSendableResponse}
 */
proto.watch_v1.ProcessSendableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.watch_v1.ProcessSendableResponse;
  return proto.watch_v1.ProcessSendableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.watch_v1.ProcessSendableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.watch_v1.ProcessSendableResponse}
 */
proto.watch_v1.ProcessSendableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.watch_v1.ProcessSendableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.watch_v1.ProcessSendableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.watch_v1.ProcessSendableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.watch_v1.ProcessSendableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.watch_v1.ProcessSendableResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.watch_v1.ProcessSendableResponse} returns this
 */
proto.watch_v1.ProcessSendableResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.watch_v1);
