



import { error } from 'console';
import { addMinutes } from 'date-fns';
import { put } from 'redux-saga/effects';
import { delay, take } from 'typed-redux-saga';

import { UserProfile } from '@clepside/clepsidejs/lib/entities/user_profiles_v1_pb';
import { FetchAvatarRequest } from '@clepside/clepsidejs/lib/services/filer_v1_pb';
import {
    FetchProfilesRequest, FetchProfilesResponse, UpdateProfileRequest, UpdateProfileResponse
} from '@clepside/clepsidejs/lib/services/users_v1_pb';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Database } from '@root/database';
import { FilerGrpc } from '@root/grpc/grpcFiler';
import { UsersGRPC } from '@root/grpc/grpcUsers';

import { ULID } from '../commonTypes';
import { authActions } from './auth';
import { LocalUserDetails } from './auth.types';
import { AuthClient } from './authClient';
import { State, UserProfileDetails } from './userProfiles.types';
import { watcherActions } from './watchers';

const userProfilesSlice = createSlice({
	name: 'userProfiles',
	initialState: State,
	reducers: {
		fetchBatchProfiles: (state, { payload }: PayloadAction) => {},
        loadProfileIntoStore: (state, { payload }: PayloadAction<{ uuid: string, forceFetch?: boolean }>) => {},
		fetchUserAvatar: (state, { payload }: PayloadAction<{ uuid: string }>) => {},
		updateProfile: (state, { payload }: PayloadAction<{ profile: Partial<UserProfile.AsObject>, watcherId: string }>) => {},
		storeProfile: (state, { payload }: PayloadAction<{ uuid: string, profile: UserProfileDetails }>) => {
            state.profiles[payload.uuid] = payload.profile
        },
	},
})

let BatchProfilesToFetch: { [key:ULID]: boolean } = {}
export const userProfilesSagas = {
	*loadProfileIntoStore({ payload }: ReturnType<typeof userProfilesSlice.actions.loadProfileIntoStore>): any {
        try {
			const localProfile = yield Database.userProfiles.get(payload.uuid)
			
			if (!localProfile) {
				BatchProfilesToFetch[payload.uuid] = true
			}

			if (localProfile?.cacheTil ? new Date().getTime() > localProfile.cacheTil : false ) {
				BatchProfilesToFetch[payload.uuid] = true
			}

			if (payload.forceFetch) {
				BatchProfilesToFetch[payload.uuid] = true
			}

			if (BatchProfilesToFetch[payload.uuid] == true)
				yield put(userProfilesActions.fetchBatchProfiles())
			
			else
				yield put(userProfilesActions.storeProfile({
					uuid: payload.uuid,
					profile: localProfile
				}))
        }  catch (e) {
            console.error(e, 'Failed to load profile.')
        }
	},
	*updateProfile({ payload }: ReturnType<typeof userProfilesSlice.actions.updateProfile>): any {
		yield put(watcherActions.start({
			watchers: [payload.watcherId]
		}))
		const profile = new UserProfile()
		
		let changes = 0
		if (payload.profile.displayName) {
			profile.setDisplayName(payload.profile.displayName)
			changes++
		}

		if (payload.profile.photoUrl) {
			profile.setPhotoUrl(payload.profile.photoUrl)
			changes++
		}

		if (changes > 0) {
			try {
				const request = new UpdateProfileRequest()
				request.setProfile(profile)

				const { profile: latestProfile }: UpdateProfileResponse.AsObject = (yield UsersGRPC.updateProfile(request)).toObject()
				if (latestProfile) {
					const profileWithCache = {
						...latestProfile,
						cacheTil: addMinutes(new Date(), 15).getTime()
					}

					yield put(userProfilesActions.storeProfile({
						uuid: latestProfile.id,
						profile: profileWithCache
					}))

					yield Database.userProfiles.put(profileWithCache)
					yield put(userProfilesActions.fetchUserAvatar({ uuid: latestProfile.id }))
				}
			} catch (e) {
				yield put(watcherActions.fail({
					watchers: [payload.watcherId]
				}))	

				console.error(e)
				return
			}
		}

		yield put(watcherActions.done({
			watchers: [payload.watcherId]
		}))	
	},
	*fetchBatchProfiles({}: ReturnType<typeof userProfilesSlice.actions.loadProfileIntoStore>): any {
		if (!AuthClient.currentUser) {
			while (true) {
				const settingStateAction: { payload: LocalUserDetails } = yield take(authActions.setState)
				if (settingStateAction?.payload?.isFromLocal == false) {
					break
				}
			}
		}
		yield delay(100)
		const uuidsToFetch = Object.keys(BatchProfilesToFetch).filter(key => key !== 'clepside')
		const request = new FetchProfilesRequest()
		request.setUuidsList(uuidsToFetch)

		try {
			const rawResponse = yield UsersGRPC.fetchProfiles(request)
			const { profilesList }: FetchProfilesResponse.AsObject = rawResponse.toObject()

			const itemsToSave: UserProfileDetails[] = []
			for (const profile of profilesList) {
				const localProfile = {
					...profile,
					cacheTil: addMinutes(new Date(), 15).getTime()
				}
				itemsToSave.push(localProfile)
				yield put(userProfilesActions.storeProfile({
					uuid: profile.id,
					profile: localProfile
				}))
				yield put(userProfilesActions.fetchUserAvatar({ uuid: profile.id }))
			}
			yield Database.userProfiles.bulkPut(itemsToSave)
		} catch (e) {
			console.log(e)
		}	
		BatchProfilesToFetch = {}
	},
	*fetchUserAvatar({ payload }: ReturnType<typeof userProfilesSlice.actions.fetchUserAvatar>): any {
		try {
			const request = new FetchAvatarRequest()
			request.setUserid(payload.uuid)

			const response = yield FilerGrpc.fetchAvatar(request)
			const responseBody = response.toObject()
			const url = responseBody.url
			if (url) {
				const image = yield fetch(url, {
					method: 'GET',
					headers: {
						'Content-Type': 'image/jpeg',
					},
				})
				const imageBlob: Blob = yield image.blob();

				yield Database.userProfileImages.put({
					id: payload.uuid,
					data: imageBlob
				})

				yield put(userProfilesActions.loadProfileIntoStore({ uuid: payload.uuid }))
			} else {
				throw new Error('no url to fetch')
			}
		} catch (e) {
			console.error(e)
		}
	}
}

export const userProfilesActions = userProfilesSlice.actions
export const userProfilesReducers = userProfilesSlice.reducer
