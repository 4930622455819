import { GrantTypeEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { PersonCircle } from '@comps/complex/personCircle'
import { TextInput } from '@comps/forms/textInput'
import { TextInputGroup } from '@comps/forms/textInputGroup'
import { WatcherButton } from '@comps/interactive/watcherButton'
import { Flex } from '@comps/layout/flex'
import { Separator } from '@comps/layout/separator'
import { Spacer } from '@comps/layout/space'
import { Text } from '@comps/typography/text'
import { useForm } from '@root/hooks/useForm'
import { useFormConfig } from '@root/hooks/useFormConfig'
import { useTime } from '@root/hooks/useTime'
import { useActivities } from '@root/store/selectors/useActivities'
import { useActivitiesSharing, useActivityCollaborators } from '@root/store/selectors/useActivitiesSharing'
import { flowsActions } from '@root/store/slices/flows'
import { formatDistance } from 'date-fns'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router'
import styled from 'styled-components'

export const FlowsSharing = () => {
	const params = useMatch('/activities/:activityId/*')
	const activityId = params?.params.activityId
	const activities = useActivities()
	const dispatch = useDispatch()
	const { collaborators } = useActivityCollaborators(activityId)
	const now = useTime('minute')

	const formConfig = useFormConfig(
		{
			fields: {
				email: {
					validate: 'required,email',
				},
			},
			onSubmit: (state, values) => {
				if (activityId)
					dispatch(
						flowsActions.startFlow({
							flowType: 'share-select-role',
							activityId: activityId,
							email: values.email,
						})
					)
			},
		},
		[]
	)
	const { errors, fields, injectFormField, submit } = useForm(formConfig)

	const activity = useMemo(() => {
		return activityId ? activities.at[activityId] : undefined
	}, [activities, activityId])

	const { invitations } = useActivitiesSharing(activityId)

	if (!activity) {
		return null
	}

	return (
		<Flex direction="column" align="stretch">
			<Flex direction="row" grow>
				<Flex direction="column" grow align="stretch">
					<TextInputGroup error={errors?.email}>
						<TextInput
							placeholder="example@example.com"
							ref={injectFormField('email', 'text')}
							{...fields.email}
							inputStyle="block"
						/>
					</TextInputGroup>
				</Flex>
				<Spacer size={10} />
				<WatcherButton insetPadding="buttonMedium" onClick={submit} color="accent">
					Invite to Share
				</WatcherButton>
			</Flex>
			<Spacer size={20} />
			<Separator stretch />
			<Flex direction="column">
				{invitations.all.map((inviteId) => {
					const invite = invitations.at[inviteId]
					const user = collaborators.at[invite.forUserId]?.profile
					const inviteText = (
						<Text key={inviteId}>
							Invite for {invite.forEmail} sent {formatDistance(new Date(invite.createdAt), new Date())}{' '}
						</Text>
					)
					const userCard = (text: string) => {
						return (
							<Person key={inviteId}>
								<PersonCircle type="person" id={user.id} />
								<Spacer size={20} />
								<Flex direction="column" align="stretch" grow>
									<Flex>
										<Text bold>{user.displayName}</Text>
									</Flex>
									<Flex grow justify="space-between">
										<Text>{text}</Text>
										<Text>{grantTypeToRole(invite.grantTypeToGrant)}</Text>
									</Flex>
								</Flex>
							</Person>
						)
					}

					if (!user) {
						if (invite.acceptedAt) return inviteText
						else if (invite.forEmail)
							return (
								<Person key={inviteId}>
									<Flex shrink>
										<Spacer size={56} />
										<Text color="subtle">
											<em>{grantTypeToRole(invite.grantTypeToGrant)}</em> sharing
											invite for&nbsp;<em>{invite.forEmail}</em>&nbsp; sent{' '}
											<em>{formatDistance(invite.lastModifiedAt, now)}</em> ago.{' '}
										</Text>
									</Flex>
								</Person>
							)
						else {
							console.error(invite)
							return <Text key={inviteId}>Something went wrong</Text>
						}
					}

					if (invite.isCreator) {
						return userCard(`Created this ${formatDistance(invite.acceptedAt, now)} ago`)
					}

					if (invite.acceptedAt) {
						return userCard(`Shared ${formatDistance(invite.acceptedAt, now)} ago`)
					}
					return inviteText
				})}
			</Flex>
		</Flex>
	)
}
const Person = styled.div`
	margin: 10px 0;
	display: flex;
	align-self: stretch;

	&:first-child {
		margin-top: 30px;
	}

	em {
		font-style: normal;
		${(p) => p.theme.text.regular.r.css('color')}
		font-weight: 500;
	}
`

function grantTypeToRole(grant: GrantTypeEnum) {
	switch (grant) {
		case GrantTypeEnum.EDITOR:
			return 'Editor'
		case GrantTypeEnum.GUEST:
			return 'Viewer'
		case GrantTypeEnum.OWNER:
			return 'Owner'
		case GrantTypeEnum.VIEWER:
			return 'Viewer'
	}
}
