import { UserProfile } from '@clepside/clepsidejs/lib/entities/user_profiles_v1_pb';

export type UserProfileDetails = UserProfile.AsObject & {
    cacheTil: number
}

export interface IState {
    profiles: {
        [id: string]: UserProfileDetails
    }
}

export const State: IState = {
    profiles: {
    }
}