import { differenceInSeconds } from 'date-fns';
import { useCallback } from 'react';

import { DragDropCore } from '@root/cores/dragDropCore';
import { isNotLeftClick } from '@root/utils/general';

import { SessionPlacerDimensions } from './sessionPlacer.types';
import { getCursorDateFromEvent } from './sessionPlacer.utils';

export const useSessionDragStart = (
	sessionTime: {
		start: Date
		end: Date
	},
	shownDatesIndexes: number[],
	vector: number[],
	container: HTMLDivElement,
	dimensions: SessionPlacerDimensions
) => {
	const handleDragStart = useCallback(
		(e: any) => {
			if (isNotLeftClick(e)) return
			e.stopPropagation()

			let sessionId = undefined
			if (e.target.getAttribute('data-sid')) sessionId = e.target.getAttribute('data-sid')
			if (e.target.closest('[data-sid]')) sessionId = e.target.closest('[data-sid]').getAttribute('data-sid')

			const clickedTimeInsideEvent = getCursorDateFromEvent(container, e, shownDatesIndexes, vector, dimensions)
			if (!clickedTimeInsideEvent) return undefined
			const offsetBettwenClickAndStart = differenceInSeconds(clickedTimeInsideEvent, sessionTime.start)
			const offsetBettwenClickAndEnd = differenceInSeconds(sessionTime.end, clickedTimeInsideEvent)

			DragDropCore.startMoving(e, {
				type: 'session',
				shouldCopy: e.altKey,
				sessionId,
				startCoords: {
					x: e.clientX,
					y: e.clientY,
				},
				offset: {
					secondsBefore: offsetBettwenClickAndStart,
					secondsAfter: offsetBettwenClickAndEnd,
				},
			})
		},
		[container, sessionTime, shownDatesIndexes, vector, dimensions]
	)

	return {
		handleDragStart,
	}
}
