import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../';
import { Collection } from '../commonTypes';
import { CardData } from '../slices/cards.types';

export const useCards = (): Collection<CardData> & { forResource: { [resourceId: string]: string[] } } => {
	const cardsIndex = useSelector((state: RootState) => {
		return state.cards
	})

	return useMemo(() => {
		return {
			at: {
				...cardsIndex.atId,
			},
			all: Object.keys(cardsIndex.atId),
			forResource: Object.keys(cardsIndex.atId).reduce((acc, key) => {
				const card = cardsIndex.atId[key]
				if (!acc[card.resourceId]) acc[card.resourceId] = []
				acc[card.resourceId].push(card.id)
				return acc
			}, {} as { [boardId: string]: string[] }),
		}
	}, [cardsIndex])
}
export const useCardsByResourceId = (resourceId: string): Collection<CardData> => {
	const cards = useCards()
	return useMemo(() => {
		return {
			at: {
				...cards.at,
			},
			all: cards.forResource?.[resourceId] || [],
		}
	}, [cards, resourceId])
}

export const useCard = (id?: string) => {
	const cards = useCards()

	return useMemo(() => {
		if (!id) return undefined
		return cards.at?.[id]
	}, [cards, id])
}
