import { DARK_TRANSLUCENCY } from '@root/brand/css'
import { CardLayoutObject } from '@root/store/slices/cards.types'
import { range } from '@utils/general'
import { css } from 'styled-components'

import { CardID, Matrix } from './cardGrid.types'

export const gridPadding = 20

export const CardInterfaceElementStyles = css`
	border-radius: 9px;
	${DARK_TRANSLUCENCY};
	color: #fff;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), inset 0px 1px 0px rgba(255, 255, 255, 0.1);
`

export const CardInterfaceElementHoveredStyles = css`
	${(p) => p.theme.backgrounds.darkTranslucency.t1.css('background-color')};
`

export const CardInterfaceElementActiveStyles = css`
	${(p) => p.theme.backgrounds.darkTranslucency.t2.css('background-color')};
`

function intersects(representationOne: CardLayoutObject, representationTwo: CardLayoutObject) {
	const representationToCoords = (re: CardLayoutObject) => {
		return {
			left: re.x,
			top: re.y,
			right: re.x + re.w,
			bottom: re.y + re.h,
		}
	}

	const r1 = representationToCoords(representationOne)
	const r2 = representationToCoords(representationTwo)

	return !(r2.left > r1.right || r2.right < r1.left || r2.top > r1.bottom || r2.bottom < r1.top)
}
// export const findBoundaries = (m: Matrix, y: number, x: number): CardLayoutObject | undefined => {
// 	const cardNo = m[y][x]
// 	let originY = undefined
// 	let originX = undefined
// 	let endY = undefined
// 	let endX = undefined

// 	for (let i = 0; i < m.length; i++) {
// 		for (let j = 0; j < m[0].length; j++) {
// 			if (m[i][j] === cardNo) {
// 				if (!originY) {
// 					originY = i
// 				}
// 				if (!originX) {
// 					originX = j
// 				}
// 				if (endY == undefined || i > endY) {
// 					endY = i
// 				}

// 				if (endX == undefined || j > endX) {
// 					endX = j
// 				}
// 			}
// 		}
// 	}

// 	if (!originX || !originY || !endX || !endY) {
// 		return undefined
// 	}

// 	return { w: endX - originX + 1, h: endY - originY + 1, y: originY, x: originX }
// }

export const findBoundaries = (m: Matrix, y: number, x: number): CardLayoutObject | null => {
	if (y < 0 || y >= m.length || x < 0 || x >= m[y].length) {
		return null
	}

	const target = m[y][x]

	let minX = x
	let maxX = x
	let minY = y
	let maxY = y

	// explore left
	for (let i = x - 1; i >= 0; i--) {
		if (m[y][i] !== target) {
			break
		}
		minX = i
	}

	// explore right
	for (let i = x + 1; i < m[y].length; i++) {
		if (m[y][i] !== target) {
			break
		}
		maxX = i
	}

	// explore up
	for (let i = y - 1; i >= 0; i--) {
		if (m[i][x] !== target) {
			break
		}
		minY = i
	}

	// explore down
	for (let i = y + 1; i < m.length; i++) {
		if (m[i][x] !== target) {
			break
		}
		maxY = i
	}

	const w = maxX - minX + 1
	const h = maxY - minY + 1

	return { w, h, x: minX, y: minY }
}

export const printMatrix = (matrix: Matrix) => {
	const m = JSON.parse(JSON.stringify(matrix))
	for (let i = 0; i < m.length; i++) {
		let line = ''
		if (i == 0) {
			line = 'x  '
			for (let j = 0; j < m[0].length; j++) {
				line += j >= 10 ? `${j} ` : `${j}  `
			}
			console.log(line)
		}
		line = i >= 10 ? `${i - 1} ` : `${i}  `

		for (let j = 0; j < m[0].length; j++) {
			line += (m[i][j] ? m[i][j].substr(m[i][j].length - 2, 2) : '..') + ' '
		}
		console.log(line)
	}
}

export const buildMatrix = (height: number, width: number) => {
	const m = range(height).map(() => range(width).map(() => ''))
	return m
}

export const copyMatrix = (m: Matrix) => {
	const n = buildMatrix(m?.length, m?.[0]?.length)
	for (let i = 0; i < m.length; i++) {
		for (let j = 0; j < m[0].length; j++) {
			n[i][j] = m[i][j]
		}
	}
	return n
}
export const fill = (m: Matrix, l: CardLayoutObject, cardId: CardID) => {
	const n = copyMatrix(m)
	try {
		for (let j = l.y; j < l.y + l.h; j++) {
			for (let i = l.x; i < l.x + l.w; i++) {
				n[j][i] = cardId
			}
		}
	} catch (e) {
		console.error('failed to fill', e)
	}
	return n
}

export const gridCellHeight = 64
export const getCardXY = (n: number, gridCellWidth?: number, vertical?: boolean) => {
	const dimension = vertical ? gridCellHeight : gridCellWidth || gridCellHeight
	return Math.round(n * gridPadding + n * dimension)
}

export const getCardWH = (n: number, gridCellWidth?: number, vertical?: boolean) => {
	const dimension = vertical ? gridCellHeight : gridCellWidth || gridCellHeight
	return Math.round(n * dimension + (n > 0 ? (n - 1) * gridPadding : 0))
}
