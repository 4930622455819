import { Icon } from '@comps/static/icon'
import { Database } from '@root/database'
import { RootState } from '@root/store'
import { addHours, isAfter } from 'date-fns'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

function hashString(str: string): number {
	let hash = 0
	if (str.length === 0) return hash

	for (let i = 0; i < str.length; i++) {
		const char = str.charCodeAt(i)
		hash = (hash << 5) - hash + char
		hash |= 0 // Convert to a 32-bit integer
	}

	return hash
}

function getThreeUniqueNumbers(str: string): [number, number, number] {
	const hashedValue = hashString(str)

	// Get three unique numbers between 0 and 255 using bitwise operations
	const num1 = hashedValue & 0xff
	const num2 = (hashedValue >> 8) & 0xff
	const num3 = (hashedValue >> 16) & 0xff

	return [num1, num2, num3]
}

type PersonCircleSize = 'small' | 'regular' | 'large'
const memoizedImages: {
	[id: string]: {
		url: string
		refetchAfter: Date
	}
} = {}
export const PersonCircle: React.FC<{
	type: 'person' | 'plus-button'
	id?: string
	size?: PersonCircleSize
}> = ({ type, id, size = 'regular' }) => {
	const profile = useSelector((state: RootState) => (id ? state.userProfiles.profiles[id] : undefined))

	const displayName = profile?.displayName
	const email = profile?.email
	const lastRenderedImage: any = useRef()

	const [imageUrl, setImageUrl] = useState<string | null>(id ? memoizedImages?.[id]?.url : null)

	const contents = useMemo(() => {
		if (type == 'plus-button') return '+'
		if (displayName && displayName.length > 2) {
			const splitName = displayName.split(' ').reduce((acc: any, part: any, index: any) => {
				if (index <= 1) {
					acc += part[0]
				}
				return acc
			}, '')

			if (splitName.length == 2) return splitName.toUpperCase()
		}
		if (email) return (email[0] + email[1]).toUpperCase()
		return <Icon type="profile" />
	}, [type, displayName, email])

	const color = useMemo(() => {
		if (!email) return 'rgba(0, 0, 0, 0.15)'
		const n = getThreeUniqueNumbers(email)
		return `rgba(${n[0]}, ${n[1]}, ${n[2]}, 0.15)`
	}, [email])

	const insertImage = useCallback(async () => {
		if (!id) return
		if (memoizedImages[id]) {
			setImageUrl(memoizedImages[id].url)
			return
		}
		const image = await Database.userProfileImages.get(id)
		if (image?.data) {
			memoizedImages[id] = {
				url: URL.createObjectURL(image.data),
				refetchAfter: addHours(new Date(), 3),
			}
			setImageUrl(memoizedImages[id].url)
		}
	}, [id])

	const cleanupMemoisedImages = useCallback(() => {
		const d = new Date()
		for (const memId of Object.keys(memoizedImages)) {
			const image = memoizedImages[memId]
			if (isAfter(d, image.refetchAfter)) {
				URL.revokeObjectURL(memoizedImages[memId].url)
				delete memoizedImages[memId]
			}
		}
	}, [])
	useEffect(() => {
		return () => {
			cleanupMemoisedImages()
		}
	}, [, cleanupMemoisedImages])

	useLayoutEffect(() => {
		insertImage()
	}, [insertImage, profile])

	return (
		<PersonCircleElement color={color} size={size}>
			{imageUrl && <Image style={{ backgroundImage: `url(${imageUrl})` }} />}
			<Contents>{contents}</Contents>
		</PersonCircleElement>
	)
}
const Image = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	z-index: 1000;
`

const Contents = styled.div`
	position: relative;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
`

const PersonCircleElement = styled.div<{ color: string; size: PersonCircleSize }>`
	width: 36px;
	height: 36px;
	border-radius: 999px;
	overflow: hidden;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	${(p) => p.theme.backgrounds.darkTranslucencyElevated.r.css('background-color')};

	span {
		position: relative;
		top: -2px;
		font-size: 20px;
		font-weight: 300;
	}

	${(p) =>
		p.size == 'small' &&
		css`
			width: 22px;
			height: 22px;
			* {
				font-size: 11px !important;
			}
		`}

	${(p) =>
		p.size == 'large' &&
		css`
			width: 72px;
			height: 72px;
			* {
				font-size: 20px !important;
			}
		`}
`
