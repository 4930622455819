import { DragDropCore } from '@root/cores/dragDropCore';
import { InstancedSessionData } from '@root/store/slices/sessionsTypes';
import { isNotLeftClick } from '@root/utils/general';
import { useCallback } from 'react';

import { SessionPlacerDimensions } from './sessionPlacer.types';
import { getCursorDateFromEvent } from './sessionPlacer.utils';

// type DIRECTION = 'start' | 'end'
export const useSessionResizer = (
	sessionTime: {
		start: Date
		end: Date
	},
	shownDatesIndexes: number[],
	vector: number[],
	container: HTMLDivElement,
	dimensions: SessionPlacerDimensions,
	selectedSession?: InstancedSessionData
) => {
	// const [resizingDirection, setResizingDirection] = useState<DIRECTION | undefined>(undefined)
	// const resizingDate = useRef<Date | undefined>(undefined)

	// const handleDragEnded = useCallback(
	// 	(direction?: DIRECTION) => {
	// 		if (!direction) return
	// 		if (!resizingDate.current) return
	// 		onResizeCompleted(direction, resizingDate.current)
	// 		setResizingDirection(undefined)
	// 	},
	// 	[onResizeCompleted]
	// )

	// const handleResize = useCallback(
	// 	(direction: DIRECTION, date: Date) => {
	// 		onResize(direction, date)
	// 	},
	// 	[onResize]
	// )

	// const handleResizingStarted = useCallback((direction: DIRECTION) => {
	// 	setResizingDirection(direction)
	// }, [])

	const handleResizingStarted = useCallback(
		(e: any, dir: 'bottom' | 'top') => {
			if (isNotLeftClick(e)) return
			e.stopPropagation()

			let sessionId = undefined
			if (e.target.getAttribute('data-sid')) sessionId = e.target.getAttribute('data-sid')
			if (e.target.closest('[data-sid]')) sessionId = e.target.closest('[data-sid]').getAttribute('data-sid')

			const clickedTimeInsideEvent = getCursorDateFromEvent(container, e, shownDatesIndexes, vector, dimensions)
			if (!clickedTimeInsideEvent) return undefined

			DragDropCore.startMoving(e, {
				type: 'session-resize',
				sessionId,
				direction: dir,
				start: sessionTime.start.getTime(),
				end: sessionTime.end.getTime(),
			})
		},
		[container, shownDatesIndexes, vector, dimensions, sessionTime]
	)

	// useEffect(() => {
	// 	if (!container) return
	// 	if (!resizingDirection) return

	// 	const onDrag = (e: any) => {
	// 		if (!resizingDirection) return

	// 		resizingDate.current = getCursorDateFromEvent(container, e, shownDatesIndexes, vector, dimensions)
	// 		if (!resizingDate.current) return
	// 		resizingDate.current = roundToNearestMinutes(resizingDate.current, {
	// 			nearestTo: 5,
	// 		})

	// 		handleResize(resizingDirection, resizingDate.current)
	// 	}

	// 	const dragEnded = handleDragEnded.bind(undefined, resizingDirection)
	// 	container.addEventListener('mouseup', dragEnded)
	// 	container.addEventListener('mousemove', onDrag)
	// 	return () => {
	// 		container.removeEventListener('mouseup', dragEnded)
	// 		container.removeEventListener('mousemove', onDrag)
	// 	}
	// }, [vector, handleResize, resizingDirection, dimensions, handleDragEnded, container, shownDatesIndexes])

	return { handleResizingStarted }
}
