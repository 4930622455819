import { Logger } from '@root/cores/logger'

// // @ts-ignore
// // eslint-disable-next-line import/extensions
// import Worker from './cacheEngine.worker.js'

export async function cacheLocalImage(url: string, imageBlob: Blob, cacheName: 'card-images' | 'favicons'): Promise<void> {
    try {
        const cache = await caches.open(cacheName);
        const response = new Response(imageBlob, {
            status: 200,
            statusText: 'OK',
            headers: { 'Content-Type': imageBlob.type }
        });
        await cache.put(url, response);
        Logger.statics.info('Image cached successfully: ', url);
    } catch (error) {
        console.error('Failed to cache image:', error);
    }
}
export async function getImageBlob(url: string) {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const imageBlob = await response.blob();
      return imageBlob;
    } catch (error) {
      console.error('Error fetching image:', error);
    }
}
export async function getImageBlobFromCache(url: string) {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      if (response.headers.get('x-from-local-cache') != 'true') {
        return undefined
      }
      const imageBlob = await response.blob();
      return imageBlob;
    } catch (error) {
      console.error('Error fetching image:', error);
    }
}

export async function blobToUint8Array(blob: Blob): Promise<Uint8Array> {
  const arrayBuffer = await blob.arrayBuffer();
  return new Uint8Array(arrayBuffer);
}

export function uint8ArrayToBlob(parts: Uint8Array, mimeType: string): Blob {
    // Combine all Uint8Arrays into one for Blob creation
    const blob = new Blob([parts], { type: mimeType });
    return blob;
}

export const useCacheEngine = () => {
	// const worker = useRef<any>()

    // This function handles the service worker registration.
    // useEffect(() => {
        
    //   }, []);
    
	// useEffect(() => {
	// 	worker.current = new (Worker as any)()

	// 	worker.current.onmessage = (event: any) => {
	// 		if (event.data.success) {
				
	// 		} else {
	// 			console.error(event)
	// 		}
	// 	}

    //     console.log('worker', worker.current)
	// 	return () => {
	// 		worker.current.terminate()
	// 	}
	// }, [])
}

export async function uploadImage(presignedUrl: string, imageFile: Blob, checksum: string) {
  try {
      const response = await fetch(presignedUrl, {
          method: 'PUT',
          body: imageFile,
          headers: {
            'x-goog-content-sha256': checksum,
              'Content-Type': 'image/jpeg' 
          }
      });

      if (response.ok) {
          console.log('Image uploaded successfully');
      } else {
          console.error('Upload failed:', response.status, response.statusText);
      }
  } catch (error) {
      console.error('Error uploading image:', error);
  }
}