import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { PersistResponse } from '@clepside/clepsidejs/lib/services/persist_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { Logger } from '@root/cores/logger'
import { Database } from '@root/database'
import { PersistGRPC } from '@root/grpc/grpcPersist'
import { take } from 'redux-saga/effects'
import { select } from 'typed-redux-saga'
import { RootState } from '../'
import { authActions } from './auth'
import { LocalUserDetails } from './auth.types'
import { AuthClient } from './authClient'
import { constructActivityPacket } from './persist.constructPacket.activity'
import { constructBoardPacket } from './persist.constructPacket.board'
import { constructCardPacket } from './persist.constructPacket.card'
import { constructFragmentCompletablePacket } from './persist.constructPacket.fragmentCompletable'
import { constructFragmentPlainTextPacket } from './persist.constructPacket.fragmentPlainText'
import { constructFragmentUploadablePacket } from './persist.constructPacket.fragmentUploadable'
import { constructRoutinePacket } from './persist.constructPacket.routine'
import { constructSessionPacket } from './persist.constructPacket.session'
import { mergePacketsToLocalTransaction } from './persist.transactionMergeLocal'
import { PacketObject } from './sync.tsx.packets.types'
import { AllSyncablePackets } from './sync.types'

export function* sendPacketObjectFromQueue( packets: PacketObject<any>[] ) {
	if (!AuthClient.currentUser) {
		while (true) {
			const settingStateAction: { payload: LocalUserDetails } = yield take(authActions.setState)
			if (settingStateAction?.payload?.isFromLocal == false) {
				break
			}
		}
	}

	const packetsToSend: Packet[] = []

	for (const packetObject of packets) {
		const readyToSendPacket = new Packet()
	
		switch (packetObject.type) {
			case PacketType.ACTIVITY: {
				constructActivityPacket(readyToSendPacket, packetObject)
				packetsToSend.push(readyToSendPacket)
				break
			}
			case PacketType.BOARD: 
				constructBoardPacket(readyToSendPacket, packetObject)
				packetsToSend.push(readyToSendPacket)
				break
			case PacketType.FRAGMENT_COMPLETABLE: 
				constructFragmentCompletablePacket(readyToSendPacket, packetObject)
				packetsToSend.push(readyToSendPacket)
				break
			case PacketType.CARD: 
				constructCardPacket(readyToSendPacket, packetObject)
				packetsToSend.push(readyToSendPacket)
				break
			case PacketType.SESSION: 
				constructSessionPacket(readyToSendPacket, packetObject)
				packetsToSend.push(readyToSendPacket)
				break
			case PacketType.FRAGMENT_PLAIN_TEXT:
				constructFragmentPlainTextPacket(readyToSendPacket, packetObject)
				packetsToSend.push(readyToSendPacket)
				break
			case PacketType.FRAGMENT_UPLOADABLE:
				constructFragmentUploadablePacket(readyToSendPacket, packetObject)
				packetsToSend.push(readyToSendPacket)
				break
			case PacketType.ROUTINE:
				constructRoutinePacket(readyToSendPacket, packetObject)
				packetsToSend.push(readyToSendPacket)
				break
			default: {
				throw (new Error('Trying to sync an unimplemented packet.'))
			}
		}
	}

	Logger.persist.debug('Persisting packets: ', packets)
	try {
		const clientId = yield* select((state: RootState) => state.auth.clientId)
		if (!clientId) return
		
		const persistResponse: PersistResponse = yield PersistGRPC.persist(packetsToSend, clientId)
		const response = persistResponse.toObject()
		const failedToSyncPackets = response.failedToSyncPacketsList
		const noPermissionPackets = response.noPermissionPacketsList
		const updatedPacketsList = response.packetsList
		
		if (failedToSyncPackets.length) {
			console.error('❌ Failed to sync packets:', failedToSyncPackets)
			for (const id in failedToSyncPackets) {
				yield Database.transaction('rw', [Database.queue], async () => {
					const queued = await Database.queue.get(id) as any
					await Database.queue.put({
						...queued,
						clientOnlyEnqueuedAt: new Date().getTime(),
						clientOnlySendAfter: 60000,
					}, id)
				})
			}
		}
		if (noPermissionPackets.length) {
			console.log('❌ No permissions packets:', noPermissionPackets)
			for (const id in noPermissionPackets) {
				yield Database.transaction('rw', [Database.queue], async () => {
					const queued = await Database.queue.get(id) as any
					await Database.queue.put({
						...queued,
						clientOnlyEnqueuedAt: new Date().getTime(),
						clientOnlySendAfter: 120000,
					}, id)
				})
			}
		}
		// TO-DO
		const returnedPackets: any[] = []
		const packetsReadyForLocal: {
			[type: string]: {
				config: any,
				packets: any[]
			}
		} = {}

		for (const packet of updatedPacketsList) {
			for (const config of AllSyncablePackets) {
				const p = config.fromPacket(packet)
				if (p) { 
					if (!packetsReadyForLocal[`${config.type}`]) {
						packetsReadyForLocal[`${config.type}`] = {
							config: config,
							packets: []
						}
					}
					packetsReadyForLocal[`${config.type}`].packets.push(p)
				}
			}
		}

		try {
			Logger.persist.debug('Packet persisted successfully')
			for (const rfl of Object.values(packetsReadyForLocal)) {
				yield mergePacketsToLocalTransaction(rfl.packets, rfl.config, undefined, true)
				for (const packet of rfl.packets) {
					yield Database.queue.where('id').equals(packet.id).delete()
					returnedPackets.push(packet)
				}
			}

			return { success: true }
		} catch (err: any) {
			console.error('failed to persist', err)
			return { success: false, error: err }
		}
	} catch (err: any) {
		console.error('server failed to persist', err)
		return { success: false, error: err }
	}
}