import { RoutineTag } from '@comps/complex/routineTag'
import { useDialog } from '@comps/dialog'
import { Button } from '@comps/interactive/button'
import { NavigationStackLink } from '@comps/interactive/navigationStackLink'
import { Flex } from '@comps/layout/flex'
import { PageInset } from '@comps/layout/pageInset'
import { Spacer } from '@comps/layout/space'
import { Text } from '@comps/typography/text'
import { routinesActions, useRoutinesForActivityId } from '@root/store/slices/routines'
import { getScheduleDescription } from '@root/store/slices/routineSchedules.utils'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

export const ActivityRoutines = () => {
	const { id } = useParams()
	const routines = useRoutinesForActivityId(id)
	const [qp, setQp] = useSearchParams()
	const dispatch = useDispatch()
	const { showDialog } = useDialog()

	const handleDeleteClicked = useCallback((routineId: string) => {
		if (!id) return

		const title = 'Confirm Routine Deletion'
		const message = 'Are you sure you want to delete this routine?'

		showDialog(title, message, () => {
			dispatch(
				routinesActions.delete({
					id: routineId,
				})
			)
		})
	}, [id, dispatch, showDialog])

	return (
		<PageInset larger>
			<Flex justify="space-between" align="stretch" direction="column" grow>
				<Flex align="center" grow justify="space-between">
					<Text level="title70">Routines</Text>
					{/* <Button onClick={() => { }} inset="buttonMedium" color="accent">
						New Routine
					</Button> */}
				</Flex>
				<Holder>
					{routines.all.length == 0 ? (
						<Flex grow align="center" justify="center">
							<Text color="subtle">You have no routines.</Text>
						</Flex>
					) : null}
					{routines.all.map((routineId) => {
						const routine = routines.at[routineId]
						return (
							<Box key={routineId}>
								<RoutineHeader>
									<Flex align="center" spacing={15}>
										<Flex direction="column">
											<Text level="title10">{routine.name}</Text>
											<Text level="body">{routine.description}</Text>
										</Flex>
										<RoutineTag />
									</Flex>
									<Flex spacing={5}>
										{/* <Button onClick={() => { }} inset="buttonSmall" color="subtleAccent">
											Edit Routine
										</Button>
										<Button onClick={() => { }} inset="buttonSmall" color="subtleAccent">
											+ Schedule
										</Button> */}
										<Button onClick={() => {
											handleDeleteClicked(routineId)
										}} inset="buttonSmall" color="subtleClepside">
											Delete
										</Button>
										<NavigationStackLink to={routineId + '/cards'}>
											<Button inset="buttonSmall" color="accent">
												Setup Cards
											</Button>
										</NavigationStackLink>
									</Flex>
								</RoutineHeader>
								<Schedules>
									{routine.schedules.all.map((sid) => {
										const schedule = routine.schedules.at[sid]
										return (
											<Schedule key={sid}>
												<div className="desc-holder">
													{getScheduleDescription(schedule.period, schedule.time).map((c, i) => {
														return <span style={c.style} key={i}>{c.text}</span>
													})}
												</div>
												<Spacer size={5} vertical />
												<div>
													{/* <Button
														onClick={() => {
															setQp({
																flow: 'routine-schedule-editing' as FlowID,
																id: sid,
															})
														}}
														inset="buttonSmall"
														color="textualAccent"
													>
														Edit Schedule
													</Button> */}
												</div>
											</Schedule>
										)
									})}
								</Schedules>
							</Box>
						)
					})}
				</Holder>
			</Flex>
		</PageInset>
	)
}

const Schedule = styled.div`
	padding: 20px 0;
	display: flex;
	flex-grow: 1;
	align-items: center;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	border-bottom: 1px solid #ccc;

	.desc-holder {
		padding: 10px 12px;
		${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
		line-height: 23px;
		border-radius: 10px;
	}

	&:last-child {
		border-bottom: none;
	}
`

const Box = styled.div`
	background-color: #fff;
	border-radius: 25px;
	padding: 30px;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: stretch;

	&:first-child {
		margin-top: 0;
	}
`
const Holder = styled.div`
	background-color: rgba(0, 0, 0, 0.05);
	margin-top: 30px;
	border-radius: 40px;
	width: 100%;
	padding: 40px;
	flex-grow: 1;
	height: 100%;
	box-sizing: border-box;
`

const RoutineHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	padding-bottom: 20px;
	margin-bottom: 20px;
`

const Schedules = styled.div`
	padding-left: 80px;
`
