import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { useMemo } from 'react'
import { GrantableResourceType } from '../slices/cards.types'
import { DraggableZoneTemplate } from '../slices/selection.types'

export type CardSelectionConfig = {
	template: DraggableZoneTemplate
	key: string
}

export const useCardsSelectionConfig = (resourceId: string, resourceType: GrantableResourceType): CardSelectionConfig => {
	return useMemo(() => {
		switch (resourceType) {
			case GrantableResourceEnum.ACTIVITY:
				return {
					template: 'Activities.<0>.Cards',
					key: 'Activities.<0>.Cards'.replace('<0>', resourceId),
				}
			case GrantableResourceEnum.SESSION:
				return {
					template: 'Sessions.<0>.Cards',
					key: 'Sessions.<0>.Cards'.replace('<0>', resourceId),
				}
			case GrantableResourceEnum.BOARD:
				return {
					template: 'Boards.<0>.Cards',
					key: 'Boards.<0>.Cards'.replace('<0>', resourceId),
				}
			case GrantableResourceEnum.ROUTINE:
				return {
					template: 'Routines.<0>.Cards',
					key: 'Routines.<0>.Cards'.replace('<0>', resourceId),
				}
			default:
				throw new Error('Unsupported resource type')
		}
	}, [resourceId, resourceType])
}
