import { parseTemplate } from '@root/utils/general'
import { InstancedSessionData, SessionsDisplayScopeKeyTemplate } from '@store/slices/sessionsTypes'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../'
import { Collection } from '../commonTypes'
import { useActivities } from './useActivities'

export const useSessions = (
	scopeKeyTemplate: SessionsDisplayScopeKeyTemplate,
	...params: (string | undefined)[]
): {
	at: {
		[key: string]: InstancedSessionData
	}
	all: Array<string>
} => {
	const activites = useActivities()

	const scopeKey = useMemo(() => {
		return parseTemplate(scopeKeyTemplate, ...params) as string
	}, [scopeKeyTemplate, params])

	const sessions = useSelector((state: RootState) => state.sessions)

	return useMemo(() => {
		const state: Collection<InstancedSessionData> = {
			at: {},
			all: [],
		}

		const arr: string[] = sessions.forScope[scopeKey] || []
		arr.forEach((sessionId) => {
			const session = sessions.at[sessionId]
			if (!activites.at[session.activityId]) return
			state.at[sessionId] = {
				...sessions.at[sessionId],
				start: new Date(sessions.at[sessionId].start),
				end: new Date(sessions.at[sessionId].end),
			}
			state.all.push(sessionId)
		})

		return state
	}, [sessions, activites, scopeKey])
}

export const useMergedSessions = (...batches: Collection<InstancedSessionData>[]): Collection<InstancedSessionData> => {
	return useMemo(() => {
		const index: Collection<InstancedSessionData> = { all: [], at: {} }

		for (const batch of batches) {
			for (const sessionId of batch.all) {
				if (!index.at[sessionId]) {
					index.at[sessionId] = batch.at[sessionId]
					index.all.push(sessionId)
				}
			}
		}
		return index
	}, [batches])
}
