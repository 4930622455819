import { PersistServiceClient } from '@clepside/clepsidejs/lib/services/persist_grpc_web_pb';
import { PersistRequest, PersistResponse } from '@clepside/clepsidejs/lib/services/persist_pb';
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb';

import { getGRPCMeta } from './utils';

if (!process.env.REACT_APP_API_URL)
    throw new Error('No RAPURL')
const PersistClient = new PersistServiceClient(process.env.REACT_APP_API_URL)


export const PersistGRPC = {
    persist: (packets: Packet[], clientId: string) => new Promise<PersistResponse>((res, rej) => {
            (async () => {
                const req = new PersistRequest()
                req.setPacketsList(packets)
                req.setClientId(clientId)
                // req.setClientId()
                PersistClient.persist(req, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    )
}