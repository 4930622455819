import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { parseTemplate } from '@root/utils/general';

import { SelectionID, ViewID } from './interface.types';

export enum SelectableTypes {
    DO_NOT_USE,
    CARD,
    ACTIVITY,
    SESSION,
    FRAGMENT
}
export enum SelectionStatus {
    DO_NOT_USE,
    EMPTY,
    SINGLE_SELECTED,
    MULTI_SELECTED,
}

export type ExtractOnlyExisting<T, K extends T> = Extract<T, K> 

export type DraggableZoneTemplate = ExtractOnlyExisting<ViewID, 'Activities.<0>.Cards' | 'Activities.<0>.Cards.<1>.Fragments' |  'Plan' | 'Plan.Weekly' | 'Sessions.<0>.Cards' | 'Sessions.<0>.Cards.<1>.Fragments' | 'Boards.<0>.Cards' | 'Routines.<0>.Cards' | 'Boards.<0>.Cards.<1>.Fragments'>
export type DraggableZone = string

export function getDragZoneFromTemplate(key: DraggableZoneTemplate, ...args: Array<string | undefined>) {
    return parseTemplate<DraggableZoneTemplate, DraggableZone>(key, ...args)
}

// export type SelectionContext = {
//     type: SelectableTypes.CARD,
//     // fromScope: ViewID,
//     // isEditing: boolean
//     // cardType: CardType
// } | {
//     type: SelectableTypes.SESSION,
//     // dayindex: DayIndex
// } 

export type SelectionCardContext = {
    itemType: SelectableTypes.CARD,
    id: string
    // fromScope: ViewID,
    // isEditing: boolean
    isInEditMode: boolean
    cardType: CardType
    fromResourceId: string
    fromResourceType: GrantableResourceEnum
}
export type SelectionSessionContext = {
    itemType: SelectableTypes.SESSION,
    id: string
    // dayindex: DayIndex
}

export type SelectionItemContext =  SelectionCardContext | SelectionSessionContext 

export const State: {
    status: SelectionStatus
    scopeTemplate?: SelectionID,
    scope?: string,
    type?: SelectableTypes
    // context?: SelectionContext
    all: SelectionItemContext[]
} = { 
    status: SelectionStatus.EMPTY,
    all: []
}
