export const Logo: React.FC<{ size?: number; color?: string }> = ({
	size = 32,
	color = 'currentColor',
}) => {
	return (
		<svg width={size} height={size} viewBox="0 0 454 454" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M454 227C454 352.369 352.369 454 227 454C101.631 454 0 352.369 0 227C0 101.631 101.631 0 227 0C352.369 0 454 101.631 454 227ZM401.209 227C401.209 323.213 323.213 401.209 227 401.209C130.787 401.209 52.7908 323.213 52.7908 227C52.7908 163.614 86.6437 108.134 137.256 77.655V290.349C137.256 299.096 144.346 306.186 153.093 306.186C157.845 306.186 162.107 304.094 165.01 300.78L215.757 247.484L215.792 247.485C218.659 244.614 222.622 242.837 227 242.837C231.378 242.837 235.341 244.614 238.208 247.485L238.243 247.484L288.99 300.78C291.893 304.094 296.156 306.186 300.907 306.186C309.654 306.186 316.744 299.096 316.744 290.349V290.317V77.6549C367.356 108.134 401.209 163.614 401.209 227Z" fill={color}/>
		</svg>
	)
}
