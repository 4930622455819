import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@store/index';

// Datestring
export const useTime = (granularity?: 'minute' | 'second' | 'date'): Date => {
	const dateString = useSelector((state: RootState) => {
		switch (granularity) {
			case 'minute':
				return state.time.minutesStamp
			case 'second':
				return state.time.secondsStamp
			case 'date':
				return state.time.date
		}
	})

	return useMemo(() => {
		if (dateString)
			return new Date(dateString)
		return new Date()
	}, [dateString])
}
