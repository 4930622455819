import { Inset } from '@comps/layout/inset';
import { useMemo } from 'react';

import { Button, ButtonColor } from './button';
import { Link } from './link';

export const NavigationButton: React.FC<{ children?: React.ReactNode; newTab?: boolean, keepSelection?: boolean, to?: string; onClick?: () => void; color?: ButtonColor; larger?: boolean }> = ({
	to,
	children,
	keepSelection,
	onClick,
	newTab,
	color = 'darkTranslucent',
	larger,
}) => {
	const content = useMemo(() => {
		return (
			<Button color="translucentPassingColor" onClick={onClick} className={keepSelection ? 'noseldis' : ''}>
				<Inset padding={larger ? 'buttonLarge' : 'buttonMedium'} align="center">
					{children}
				</Inset>
			</Button>
		)
	}, [larger, children, keepSelection, onClick])

	if (to)
		return (
			<Link to={to} colorless newTab={newTab}>
				{content}
			</Link>
		)
	return content
}
