import { END, eventChannel, EventChannel } from 'redux-saga';
import { call, delay, put, takeEvery } from 'redux-saga/effects';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDayIndex, getMinutesIndex, getSecondsIndex } from '@utils/dates';

import { UNIXTime } from '../commonTypes';
// import { sessionsActions } from './sessions'
// import { sessionsRelevanceActions } from './sessionsRelevance';
import { timeSliceState } from './timeTypes';

const timeSlice = createSlice({
	name: 'time',
	initialState: timeSliceState,
	reducers: {
		dateChange: (state, { payload }: PayloadAction<{ date: UNIXTime }>) => {
			state.date = payload.date
		},
		minuteChange: (state, { payload }: PayloadAction<{ date: UNIXTime }>) => {
			state.minutesStamp = payload.date
		},
		secondChange: (state, { payload }: PayloadAction<{ date: UNIXTime }>) => {
			state.secondsStamp = payload.date
		},
		start: () => {},
		end: () => {},
	},
	extraReducers: (builder) => {
		// builder.addCase(sessionsActions.normalizeAndStore, (state, action) => {
		// 	const now = new Date()
		// 	let minimumDistance = Number.MAX_SAFE_INTEGER
		// 	let upNextSession = undefined
		// 	action.payload.forEach((p) => {
		// 		const session = normalizeSessionPacket(p.id, p.data)
		// 		const diff = differenceInSeconds(fromUnixTime(session.start), now)

		// 		if (diff > 0 && diff < minimumDistance) {
		// 			minimumDistance = diff
		// 			upNextSession = session
		// 		}
		// 	})

		// 	if (upNextSession) state.upcomingSession = upNextSession
		// })
	},
})

let timerRunning = false
export const timeSagas = {
	*start() {
		timerRunning = true
		const chan: EventChannel<any> = yield call(run)

		// try {
		yield takeEvery(chan, handleTimeEvent)
		// } finally {
		// 	const hasCancelled: boolean = yield cancelled()
		// 	if (hasCancelled) {
		// 		chan.close()
		// 	}
		// }
		yield delay(500)
	},
	*end() {
		timerRunning = yield false
	},
}

function* handleTimeEvent(change: { type: string; date: Date }) {
	if (change.type === 'date-change') yield put(timeActions.dateChange({ date: change.date.getTime() }))
	if (change.type === 'minute-change') {
		yield put(timeActions.minuteChange({ date: change.date.getTime() }))
		// yield put(sessionsRelevanceActions.setFirstUpcomingAfterDate({ date: change.date.getTime() }))
	}
	if (change.type === 'second-change') {
		yield put(timeActions.secondChange({ date: change.date.getTime() }))
	}
}

export const timeActions = timeSlice.actions
export const timeReducers = timeSlice.reducer

function run() {
	return eventChannel((emitter) => {
		let currentDateIndex = getDayIndex(new Date())
		let currentMinutesIndex = getMinutesIndex(new Date())
		let currentSecondsIndex = getMinutesIndex(new Date())

		const iv = setInterval(() => {
			const date = new Date()
			const newDateIndex = getDayIndex(date)
			const newMinutesIndex = getMinutesIndex(date)
			const newSecondsIndex = getSecondsIndex(date)

			if (newDateIndex !== currentDateIndex) {
				currentDateIndex = newDateIndex
				emitter({ type: 'date-change', date })
			}

			if (newMinutesIndex !== currentMinutesIndex) {
				currentMinutesIndex = newMinutesIndex
				emitter({ type: 'minute-change', date })
			}

			if (newSecondsIndex !== currentSecondsIndex) {
				currentSecondsIndex = newSecondsIndex
				emitter({ type: 'second-change', date })
			}

			if (timerRunning === false) {
				emitter(END)
			}
		}, 150)

		return () => {
			clearInterval(iv)
		}
	})
}
