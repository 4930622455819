/**
 * @fileoverview gRPC-Web generated client stub for watch_v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.1
// source: services/watch_v1.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var unions_v1_sendable_pb = require('../unions_v1/sendable_pb.js')
const proto = {};
proto.watch_v1 = require('./watch_v1_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.watch_v1.WatchServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.watch_v1.WatchServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.watch_v1.WatchRequest,
 *   !proto.watch_v1.WatchResponse>}
 */
const methodDescriptor_WatchService_Watch = new grpc.web.MethodDescriptor(
  '/watch_v1.WatchService/Watch',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.watch_v1.WatchRequest,
  proto.watch_v1.WatchResponse,
  /**
   * @param {!proto.watch_v1.WatchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.watch_v1.WatchResponse.deserializeBinary
);


/**
 * @param {!proto.watch_v1.WatchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.watch_v1.WatchResponse>}
 *     The XHR Node Readable Stream
 */
proto.watch_v1.WatchServiceClient.prototype.watch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/watch_v1.WatchService/Watch',
      request,
      metadata || {},
      methodDescriptor_WatchService_Watch);
};


/**
 * @param {!proto.watch_v1.WatchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.watch_v1.WatchResponse>}
 *     The XHR Node Readable Stream
 */
proto.watch_v1.WatchServicePromiseClient.prototype.watch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/watch_v1.WatchService/Watch',
      request,
      metadata || {},
      methodDescriptor_WatchService_Watch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.watch_v1.ProcessSendableRequest,
 *   !proto.watch_v1.ProcessSendableResponse>}
 */
const methodDescriptor_WatchService_ProcessSendable = new grpc.web.MethodDescriptor(
  '/watch_v1.WatchService/ProcessSendable',
  grpc.web.MethodType.UNARY,
  proto.watch_v1.ProcessSendableRequest,
  proto.watch_v1.ProcessSendableResponse,
  /**
   * @param {!proto.watch_v1.ProcessSendableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.watch_v1.ProcessSendableResponse.deserializeBinary
);


/**
 * @param {!proto.watch_v1.ProcessSendableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.watch_v1.ProcessSendableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.watch_v1.ProcessSendableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.watch_v1.WatchServiceClient.prototype.processSendable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/watch_v1.WatchService/ProcessSendable',
      request,
      metadata || {},
      methodDescriptor_WatchService_ProcessSendable,
      callback);
};


/**
 * @param {!proto.watch_v1.ProcessSendableRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.watch_v1.ProcessSendableResponse>}
 *     Promise that resolves to the response
 */
proto.watch_v1.WatchServicePromiseClient.prototype.processSendable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/watch_v1.WatchService/ProcessSendable',
      request,
      metadata || {},
      methodDescriptor_WatchService_ProcessSendable);
};


module.exports = proto.watch_v1;

