import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb'
import { Database } from '@root/database'
import { routinesSchedulesActions } from './routineSchedules'
import { PacketObject, RoutineSchedulePacketObject } from './sync.tsx.packets.types'
import { getPacketObjectFromRemoteObject } from './sync.types'

export class SyncRoutineSchedules {
    public static runeOrPacket = 'packet' as const

    public static type = PacketType.ROUTINE_SCHEDULE as const

    static fromSendables(res: Sendable.AsObject[] | undefined): PacketObject<any>[] {
        return res?.map((r) => getPacketObjectFromRemoteObject(r?.packet?.routineSchedule))
            .filter((f): f is PacketObject<any> => f !== undefined) || []
    }

    static placeInStore(objects: RoutineSchedulePacketObject[], fromSync?: boolean) {
        return routinesSchedulesActions.normalizeAndStore({ 
            objects: objects as RoutineSchedulePacketObject[],
            fromSync
        })
    }

    static prepareForStorage(object: RoutineSchedulePacketObject): RoutineSchedulePacketObject {
        try {
            return {
                ...object
            }
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    static fromPacket(res: Packet.AsObject | undefined) {
        return getPacketObjectFromRemoteObject(res?.routineSchedule)
    }
    
    static database = Database.routineSchedulePackets
}