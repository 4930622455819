import { WeekdayName } from '@clepside/clepsidejs/lib/packets_v1/routine_schedule_pb'
import { DraggedSession } from '@comps/complex/planning/sessionPlacer.useDraggingSession'
import { SessionPlacerHighlight } from '@comps/complex/planning/sessionPlacer.useHighlightedSession'
import { getDayIndex, setHourMinuteSecond } from '@root/utils/dates'
import { TimeIntervals } from '@root/utils/timeIntervals'
import {
	addHours,
	addSeconds,
	differenceInDays,
	differenceInMonths,
	differenceInSeconds,
	differenceInWeeks,
	eachDayOfInterval,
	getDay,
	getWeek,
	isSameMinute,
	startOfMonth
} from 'date-fns'
import { useMemo } from 'react'
import { DayIndex } from '../commonTypes'
import { ActivityID } from './activitiesTypes'
import { AllRoutinesState, useRoutines } from './routines'
import { NormalisedRoutineData, RoutineID } from './routines.types'
import { NormalisedRoutineScheduleData, RoutineScheduleID } from './routineSchedules.types'

export function useRoutinesRecommandations(freeIntervalsState:[Date, Date][], draggedSession?: DraggedSession, highlightInfo?: SessionPlacerHighlight['info']) {
    const routines = useRoutines()
    
    return useMemo(() => {
        let intervals = freeIntervalsState
        if (draggedSession) {
            intervals = TimeIntervals.returnIntervalsExcluding(intervals, [draggedSession.start, draggedSession.end])
        }
        if (highlightInfo) {
            intervals = TimeIntervals.returnIntervalsExcluding(intervals, [highlightInfo.start, highlightInfo.end])
        }
        const suggestions = buildSuggestions(intervals, routines)
        return suggestions
    }, [routines, freeIntervalsState, draggedSession, highlightInfo])
}

interface ScheduleOpportunity {
    potential: [Date, Date]
    idealTime: Date
    schedule: NormalisedRoutineScheduleData
    routine: NormalisedRoutineData
}

function buildSuggestions(
    availableIntervals: [Date, Date][],
    routines: AllRoutinesState
) {
    const [periodStart, periodEnd] = [availableIntervals[0]?.[0], availableIntervals[availableIntervals.length - 1]?.[1]];

    if (!periodStart || !periodEnd) {
        return;
    }

    const dayStamps = eachDayOfInterval({ start: periodStart, end: periodEnd });
    const localDates = dayStamps.map(d => new Date(d.getTime() - (d.getTimezoneOffset() * 60000)));

    const scheduleOpportunities: { 
        [priority: number]: ScheduleOpportunity[]
    } = {};
    let highestPriority = -1;
    const schedules = routines.schedules

    for (const routineId of routines.all) {
        const schedulesForRoutine = schedules.forRoutineId[routineId];
        if (schedulesForRoutine) {
            for (const scheduleId of schedulesForRoutine) {
                const s = schedules.at[scheduleId];
                if (s) {
                    for (const localDate of localDates) {
                        const isMatchingPeriod = isScheduleableForLocalDate(localDate, s);
                        const time = s.time
                        const priority = s.priority
                        if (time && isMatchingPeriod && priority !== undefined) {
                            let intervalEnd = setHourMinuteSecond(localDate, time.neverAfter + time.minDuration)
                            const ideal = setHourMinuteSecond(localDate, time.idealTime)
                            let intervalStart = setHourMinuteSecond(localDate, time.neverBefore)

                            if (priority > highestPriority) {
                                highestPriority = priority
                            }
                            
                            if (time.neverAfter < time.idealTime) {
                                intervalEnd = addHours(intervalEnd, 24)
                            }
                            if (time.neverBefore > time.idealTime) {
                                intervalStart = addHours(intervalStart, -24)
                            }
                            
                            const interval: [Date, Date] = [intervalStart, intervalEnd]

                            if (!scheduleOpportunities[priority]) {
                                scheduleOpportunities[priority] = []
                            }
                            
                            scheduleOpportunities[s.priority].push({
                                potential: interval,
                                idealTime: ideal,
                                schedule: s,
                                routine: routines.at[routineId]
                            });
                        }
                    }
                }
            }
        }
    }

    let intervals = availableIntervals;
    let recommendations: RoutineRecommendation[] = [];
    // Log.routines('Looping through priorities:', Object.keys(scheduleOpportunities).length);
    
    // Iterate over priorities
    for (let i = highestPriority; i >= 0; i--) {
        const suggestionsForPriority: RoutineRecommendation[] = [];
        const oppsOfPrio = scheduleOpportunities[i];
        if (oppsOfPrio) {
            for (const opp of oppsOfPrio) {
                const scheduleSlots = TimeIntervals.returnOverlappingIntervals(intervals, opp.potential);

                
                const idealStart = opp.idealTime;
                
                if (!opp.schedule.time) {
                    continue
                }

                const idealMinEnd = addSeconds(idealStart, opp.schedule.time.minDuration);
                const slotFit = TimeIntervals.findClosestFeasibleInterval(scheduleSlots, idealStart, idealMinEnd);

                if (slotFit) {
                    const stamp = getDayIndex(slotFit[0])
                    // const hash = getStampHash(opp.schedule.id, stamp);
                    // if (!hasSessionForStamp[hash]) {
                        suggestionsForPriority.push({
                            routineId: opp.schedule.routineId,
                            scheduleId: opp.schedule.id,
                            activityId: opp.routine.activityId,
                            utcInterval: slotFit,
                            scheduleOriginDaystamp: stamp,
                            localStartAt: slotFit[0],
                            localEndAt: slotFit[1],
                        });
                        intervals = TimeIntervals.returnIntervalsExcluding(intervals, slotFit);
                    // }
                }
            }

            for (let j = 0; j < suggestionsForPriority.length; j++) {
                const sugg = suggestionsForPriority[j]
                if (!sugg) continue
                const schedule = schedules.at[sugg.scheduleId];

                if (schedule && schedule.time) {
                    const wantedDuration = schedule.time.duration;
                    const suggDuration = differenceInSeconds(sugg.utcInterval[1], sugg.utcInterval[0]);

                    if (wantedDuration !== suggDuration) {
                        const difference = Math.floor(wantedDuration - suggDuration);
                        for (const interval of intervals) {

                            if (isSameMinute(interval[0], sugg.utcInterval[1])) {
                                const intervalLength = differenceInSeconds(interval[1], interval[0]);
            
                                if (intervalLength <= difference) {
                                    suggestionsForPriority[j].utcInterval = [sugg.utcInterval[0], interval[1]];
                                    suggestionsForPriority[j].localEndAt = interval[1];  
                                } else {
                                    const endpoint = addSeconds(sugg.utcInterval[1], difference);
                                    suggestionsForPriority[j].utcInterval = [sugg.utcInterval[0], endpoint];
                                    suggestionsForPriority[j].localEndAt = endpoint; 
                                }
            
                                intervals = TimeIntervals.returnIntervalsExcluding(intervals, [sugg.utcInterval[0], interval[1]]);
                            }
                        }
                    }
                }
            }

            recommendations = [...recommendations, ...suggestionsForPriority];
        }
    }

    return recommendations
    // Possibly a method to store these recommendations
    // await setRecommendations();
}

interface RoutineRecommendation {
    routineId: RoutineID,
    scheduleId: RoutineScheduleID,
    activityId: ActivityID,
    utcInterval: [Date, Date],
    scheduleOriginDaystamp: DayIndex,
    localStartAt: Date,
    localEndAt: Date,
}


function isScheduleableForLocalDate(date: Date, s: NormalisedRoutineScheduleData): boolean {
    if (date < new Date(s.startingOn)) {
      return false;
    }
  
    const localStartingOn = s.startingOn;
  
    if (s.period?.daily) {
        const daysDifference = differenceInDays(date, localStartingOn);
        const remainingDays = daysDifference % s.period.daily.repeatsEvery;
        return remainingDays === 0;
    }
    if (s.period?.weekly) {
        const weeksDifference = differenceInWeeks(date, localStartingOn);
        const remainingWeeks = weeksDifference % s.period.weekly.repeatsEvery;
        if (remainingWeeks === 0 && s.period.weekly.onWeekdaysList) {
          return s.period.weekly.onWeekdaysList.some(
                (w) => w === [
                WeekdayName.SUNDAY, 
                WeekdayName.MONDAY, 
                WeekdayName.TUESDAY, 
                WeekdayName.WEDNESDAY, 
                WeekdayName.THURSDAY, 
                WeekdayName.FRIDAY, 
                WeekdayName.SATURDAY
            ][getDay(date)]
          );
        }
    }
    if (s.period?.monthly) {
        const monthsDifference = differenceInMonths(date, localStartingOn);
        const remainingMonths = monthsDifference % s.period.monthly.repeatsEvery;
        if (remainingMonths === 0) {
          const monthlyData = s.period.monthly.type;
          
          if (monthlyData?.onDates) {
            if (monthlyData?.onDates.onDatesList.includes(date.getDate())) {
                return true;
            }
            return false
          }

          if (monthlyData?.onWeekdays?.weekdayItemList) {
            const startOfMonthDate = startOfMonth(date);
            const weekStartDifference = getWeek(date) - getWeek(startOfMonthDate);
            return monthlyData.onWeekdays?.weekdayItemList.some(
              (w) => w.nth === weekStartDifference && w.onWeekday === [
                WeekdayName.SUNDAY, 
                WeekdayName.MONDAY, 
                WeekdayName.TUESDAY, 
                WeekdayName.WEDNESDAY, 
                WeekdayName.THURSDAY, 
                WeekdayName.FRIDAY, 
                WeekdayName.SATURDAY
            ][getDay(date)]
            );
          }
        }
    }
  
    return false;
  }