import { generateDayIndexes, getDateFromIndex } from '@utils/dates';
import { findLastIndex, range } from '@utils/general';
import {
    addHours, addMinutes, differenceInMinutes, endOfDay, getUnixTime, isValid, startOfDay
} from 'date-fns';
import { RefObject } from 'react';

import { SessionPlacerDimensions } from './sessionPlacer.types';

export const calculateCalendarCoordinates = (date: Date, vector: number[]) => {
	const pos = differenceInMinutes(date, startOfDay(date))

	const hours = Math.floor(pos / 60)
	const y = range(hours).reduce((acc, i) => {
		return acc + 60 * vector[i]
	}, 0)
	const extraPixels = pos - hours * 60

	return y + extraPixels * vector[hours] + 1
}

export const calculateTimeFromCoordinates = (y: number, vector: number[], forDate: Date) => {
	const startY = 0
	// let hi = forDate

	// let i = 0
	// while (startY < y) {
	// 	hi = addHours(forDate, i)
	// 	startY += vector[i] * 60
	// 	i++
	// }
	// startY -= 60 * vector[i]

	const grid = 60 * vector[0]
	const hours = Math.floor(y / grid)
	// Will fail if changing vector
	const dist = hours * grid

	const minutesInPixels = y - dist
	const mins = (minutesInPixels * 60) / grid

	// const minutes = Math.floor((60 * minutesInPixels) / maximumMinutes)

	let hi = forDate
	hi = addHours(hi, hours)
	hi = addMinutes(hi, mins)
	return hi
}

export const calculateYandHeight = (vector: number[], startDate?: Date, endDate?: Date) => {
	if (!startDate || !endDate) return undefined
	const start = calculateCalendarCoordinates(startDate, vector)
	return {
		y: start,
		height: calculateCalendarCoordinates(endDate, vector) - start,
	}
}

const OFFSET = 75

export const getPlacementDaySpan = (holderRef: RefObject<HTMLDivElement>, presentedRange: number) => {
	if (!holderRef.current) return 0
	const origin = holderRef.current.getBoundingClientRect()
	return (origin.width - OFFSET) / presentedRange
}

export const getXOffsetforDayIndex = (dimensions: SessionPlacerDimensions, i: number) => {
	return dimensions.padding + (i > 0 ? i * dimensions.dayWidth + i * dimensions.gap : 0)
}

const getDate = (shownDatesIndexes: number[], dimensions: SessionPlacerDimensions, x: number) => {
	const bounds: number[] = shownDatesIndexes.map((d, i) => getXOffsetforDayIndex(dimensions, i))
	return findLastIndex(bounds, (b) => {
		return x > b
	})
}

export const getCursorDateFromEvent = (
	container: HTMLDivElement,
	e: MouseEvent,
	shownDatesIndexes: number[],
	vector: number[],
	dimensions: SessionPlacerDimensions
) => {
	if (!container) throw new Error('No holder ref...')
	const origin = container.getBoundingClientRect()
	const y = e.clientY - origin.y

	// maybe maybe
	const dayIndex = getDate(shownDatesIndexes, dimensions, e.clientX - origin.x)

	const movingStartPosition = getDateFromIndex(shownDatesIndexes[dayIndex])
	if (!movingStartPosition) return undefined

	const date = calculateTimeFromCoordinates(y, vector, movingStartPosition)
	return date
}

export const getCursorDateFromCoords = (
	container: HTMLElement,
	x: number,
	y: number,
	shownDatesIndexes: number[],
	vector: number[],
	dimensions: SessionPlacerDimensions
) => {
	if (!container) throw new Error('No holder ref...')
	const dayIndex = getDate(shownDatesIndexes, dimensions, x)

	const movingStartPosition = getDateFromIndex(shownDatesIndexes[dayIndex])
	if (!movingStartPosition) return undefined

	const date = calculateTimeFromCoordinates(y, vector, movingStartPosition)
	if (isValid(date)) return date
	return undefined
}

export const getCalendarRepresentation = (
	vector: number[],
	dimensions: SessionPlacerDimensions,
	presentedDatesIndexes: number[],
	s: Date,
	e: Date
): {
	y: number
	height: number
	duration: number
	x: number
	startTime: number
	endTime: number
	split: 'top' | 'bottom' | 'both' | undefined
}[] => {
	const blocks: Array<{
		y: number
		height: number
		duration: number
		x: number
		startTime: number
		endTime: number
		split: 'top' | 'bottom' | 'both' | undefined
	}> = []
	const sessionDaySpan = generateDayIndexes(s, e)

	// const startDatePresentedIndex = presentedDatesIndexes.findIndex((f) => f === getDayIndex(s))

	const shownBlocks = sessionDaySpan.filter((index) => presentedDatesIndexes.includes(index))

	shownBlocks.forEach((blockDayIndex) => {
		let goFor = calculateCalendarCoordinates(e, vector) - calculateCalendarCoordinates(s, vector)
		let split: 'bottom' | 'top' | 'both' | undefined
		let minSpan = differenceInMinutes(e, s)
		const blockIndexInSessionSpan = sessionDaySpan.findIndex((index) => index == blockDayIndex)
		const blockIndexInPresentedSpan = presentedDatesIndexes.findIndex((index) => index == blockDayIndex)
		const startOn = (blockIndexInSessionSpan == 0 ? calculateCalendarCoordinates(s, vector) : 0) - 1

		if (sessionDaySpan.length > 1) {
			if (blockIndexInSessionSpan === 0) {
				goFor = calculateCalendarCoordinates(endOfDay(s), vector) - calculateCalendarCoordinates(s, vector)
				split = 'bottom'
				minSpan = differenceInMinutes(endOfDay(s), s)
			} else if (blockIndexInSessionSpan === sessionDaySpan.length - 1) {
				split = 'top'
				goFor = calculateCalendarCoordinates(e, vector) - calculateCalendarCoordinates(startOfDay(e), vector)
				minSpan = differenceInMinutes(e, startOfDay(e))
			} else {
				split = 'both'
				goFor = calculateCalendarCoordinates(endOfDay(e), vector) - calculateCalendarCoordinates(startOfDay(e), vector)
				minSpan = differenceInMinutes(startOfDay(s), endOfDay(s))
			}
		}

		const start = getUnixTime(s) * 1000

		blocks.push({
			y: startOn,
			height: goFor + 1,
			duration: minSpan,
			startTime: start,
			endTime: start + minSpan * 60 * 1000,
			split,
			x: getXOffsetforDayIndex(dimensions, blockIndexInPresentedSpan),
		})
	})

	return blocks
}
