import { CardLayoutObject } from '@root/store/slices/cards.types';

import { getCardWH, getCardXY } from './cardGrid.commons';

export const getStylesForCard = (r: CardLayoutObject, gridCellWidth: number) => {
	const grid = {
		y: getCardXY(r.y, gridCellWidth, true),
		x: getCardXY(r.x, gridCellWidth),
		h: getCardWH(r.h, gridCellWidth, true),
		w: getCardWH(r.w, gridCellWidth),
	}
	return grid
}
