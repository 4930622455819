import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { Database } from '@db/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { put } from 'redux-saga/effects';

import { ULID } from '../commonTypes';
import { deltaUpdate } from './persist';
import { NewPacketDelta, RemovePacketDeltaIdOnly, UpdatePacketDelta } from './persist.utils';
import { SettingsData, settingsState } from './settings.types';
import { SyncBoards } from './sync.boards';
import { BoardPacketObject, SettingsPacketObject } from './sync.tsx.packets.types';
import { LocalData } from './sync.types';

export const normalizeSettingsPacket = (id: string, s: LocalData<SettingsPacketObject>) => {
	const normalizedBoard: SettingsData = {
		id,
		uiTimeineOnRight: s.uitimelineonright,
        datePrefersAMPM: s.dateprefersampm,
        dateStartOnMonday: s.datestartonmonday,
        gestureSlideOnTimeline: s.gestureslideontimeline,
	}
	return normalizedBoard
}

const settingsSlice = createSlice({
	name: 'settings',
	initialState: settingsState,
	reducers: {
		initialize() {},
		new: (state, { payload }: PayloadAction<NewPacketDelta<PacketType.BOARD> & { id?: ULID } >) => {},
		update: (state, { payload }: PayloadAction<UpdatePacketDelta<PacketType.BOARD>>) => {},
		delete: (state, { payload }: PayloadAction<RemovePacketDeltaIdOnly>) => {},
		loadToStore: (state, { payload }: PayloadAction<{ resourceId: string }>) => {},
		store: (state, { payload }: PayloadAction<{ object: SettingsPacketObject, fromSync?: boolean }>) => {
            state = normalizeSettingsPacket('_', { id: '_', ...payload.object.data })
		},
	},
})

export const settingsSagas = {
	*initialize() {
		const boardsPackets: BoardPacketObject[] = yield Database.settings.toArray()
		yield put(SyncBoards.placeInStore(boardsPackets))
	},
	*loadToStore({ payload }: ReturnType<typeof settingsSlice.actions.loadToStore>): any {
		const settings: SettingsPacketObject[] = yield Database.settings.where('id').equals('_').toArray()

		yield put(settingsActions.store({ object: settings[0] }))
	},
	*store({ payload }: ReturnType<typeof settingsActions.store>) {
      //   console.log('')
	},
	*update({ payload: delta }: ReturnType<typeof settingsActions.update>) {
		yield deltaUpdate({ type: PacketType.SETTINGS, delta })
	},
}

export const settingsActions = settingsSlice.actions
export const settingsReducers = settingsSlice.reducer
