import { Flex } from '@comps/layout/flex'
import { PageInset } from '@comps/layout/pageInset'
import { KeyScope } from '@comps/logical/keyScope'
import React from 'react'
import styled from 'styled-components'
import { SettingsRouter } from './Settings.router'
import { SettingsSidebar } from './Settings.sidebar'

export const Settings: React.FC = () => {
	return (
		<PageInset>
			<KeyScope scope="Settings" />
			<Flex>
				<Left>
					<SettingsSidebar />
				</Left>
				<SettingsRouter />
			</Flex>
		</PageInset>
	)
}

const Left = styled.div`
	width: 250px;
	display: flex;
	align-items: stretch;
	flex-direction: column;
	margin-right: 60px;
`
