import { BackButton } from '@comps/complex/button/backButton'
import { TextInput } from '@comps/forms/textInput'
import { TextInputGroup } from '@comps/forms/textInputGroup'
import { WatcherButton } from '@comps/interactive/watcherButton'
import { CardStyle } from '@comps/layout/cardContainer'
import { Flex } from '@comps/layout/flex'
import { SimplePage } from '@comps/layout/simplePage'
import { SimplePageLogo } from '@comps/layout/simplePageLogo'
import { Spacer } from '@comps/layout/space'
import PasswordStrengthBar from '@comps/passwordStrengthBar'
import { Text } from '@comps/typography/text'
import { useForm } from '@root/hooks/useForm'
import { useFormConfig } from '@root/hooks/useFormConfig'
import { useFromApp } from '@root/hooks/useFromApp'
import { useWatcher } from '@root/hooks/useWatcher'
import { Medias } from '@root/medias'
import { authActions } from '@root/store/slices/auth'
import { grpcActions } from '@root/store/slices/grpc.DEPRECATED'
import { useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css, useTheme } from 'styled-components'

export const Signup: React.FC = () => {
	const dispatch = useDispatch()
	const theme = useTheme()
	const { watcherId: signupWatcherId } = useWatcher()
	const formConfig = useFormConfig(
		{
			fields: {
				email: {
					validate: 'required,email',
				},
				password: {
					validate: 'required',
				},
				displayName: {
					validate: 'max:64',
				},
			},
			onUpdate: (state) => {
				const bar = strengthBar.current
				bar?.setPassword(state.at.password.value || '')
			},
			onSubmit: (state, values) => {
				dispatch(
					authActions.joinWithEmail({
						watcherId: signupWatcherId,
						email: values.email,
						password: values.password,
						displayName: values.displayName,
					})
				)
			},
		},
		[]
	)
	const { fields, injectFormField, submit, errors } = useForm(formConfig)
	const strengthBar = useRef<any>()
	const { isFromApp } = useFromApp()

	useEffect(() => {
		dispatch(grpcActions.initAuthClients())
	}, [dispatch])

	const passwordRightSideMemo = useMemo(() => {
		return (
			<Flex direction="column" justify="flex-end">
				<PasswordStrengthBar ref={strengthBar} />
				<Spacer size={5} equal />
			</Flex>
		)
	}, [])

	return (
		<SimplePage center isFromApp={isFromApp}>
			<Flex direction="column" width="100%" align="center" justify="space-between" minHeight="100%">
				{isFromApp ? null : <SimplePageLogo noLink={isFromApp} />}
				<Flex direction="column" spacing={15} grow>
					{
						isFromApp ? <></> :
							<Flex justify="flex-start" align="center">
								<BackButton defaultBackTo="/login" label="Back to Login" color="textual.onBackground.translucent" />
							</Flex>
					}
					<Card isFromApp={isFromApp}>
						<Flex direction="column" spacing={25} width="100%" grow align="stretch">
							<Text level="title50">Signup</Text>
							<Text color='proSubtleText'>
								Coupled with Clepside PRO your Clepside account will enable you to sync your data, and collaborate (soon).
							</Text>
							<Flex direction="column" spacing={12} width="100%" grow align="stretch">
								<TextInputGroup isFromApp={isFromApp} label={isFromApp ? undefined : 'Email address'} error={errors?.email}>
									<TextInput
										inputStyle="block"
										placeholder="Email Address"
										autoFocus
										{...fields.email}
										ref={injectFormField('email', 'text')}
										type="email"
										isFromApp={isFromApp}
										className="username"
										name="username"
										inputMode='email'
									/>
								</TextInputGroup>
								<TextInputGroup
									isFromApp={isFromApp}
									label={isFromApp ? undefined : 'Password'}
									rightSideMemo={isFromApp ? undefined : passwordRightSideMemo}
									error={errors?.password}
								>
									<TextInput
										inputStyle="block"
										placeholder="Password"
										isFromApp={isFromApp}
										{...fields.password}
										type="password"
										ref={injectFormField('password', 'text')}
									/>
								</TextInputGroup>
								<TextInputGroup
									isFromApp={isFromApp}
									label={isFromApp ? undefined : 'Display name'} optional error={errors?.displayName}>
									<TextInput
										inputStyle="block"
										placeholder="Display Name"
										isFromApp={isFromApp}
										{...fields.displayName}
										ref={injectFormField('displayName', 'text')}
									/>
								</TextInputGroup>
							</Flex>
							{/* <FormErrorsBox errors={errors}/> */}
							<Flex direction="column" align="stretch" width="100%">
								<Spacer size={40} />
								<WatcherButton
									watcherId={signupWatcherId}
									onClick={submit}
									color={isFromApp ? 'pro' : 'clepside'}
									grow
									insetPadding="buttonMedium"
								>
									Create your Account
								</WatcherButton>
							</Flex>
							{isFromApp ? null : <Text level='tiny' color='proSubtleText'>
								By creating your account you agree to our <a href={`${process.env.REACT_APP_SITE_URL}/terms`}>Terms of Service</a> and our <a href={`${process.env.REACT_APP_SITE_URL}/privacy`}>Privacy Policy</a>
							</Text>}
						</Flex>
					</Card>
				</Flex>
				<div />
				{/* <Flex width='100%' maxWidth='340px'>
						<Button grow color='dark'>
							<Inset expandWidth padding='buttonMedium' justify='center' align='center' spacing={5}>
								<Icon type='apple' />
								Apple Login
							</Inset>
						</Button>
						<Spacer size={10} />
						<Button grow color='dark'>
							<Inset expandWidth padding='buttonMedium' justify='center' align='center' spacing={5}>
								<Icon type='google' />
								Google Login
							</Inset>
						</Button>
					</Flex> */}

				{isFromApp ? <AppNote><Text level='tiny' color='proSubtleText'>
					By creating your account you agree to our <a href={`${process.env.REACT_APP_SITE_URL}/terms`}>Terms of Service</a> and our <a href={`${process.env.REACT_APP_SITE_URL}/privacy`}>Privacy Policy</a>
				</Text></AppNote> : null}
			</Flex>
		</SimplePage>
	)
}

const AppNote = styled.div`
	display: contents;

	a {
		color: white;
	}
`

const Card = styled.div<{ isFromApp: boolean }>`
	${CardStyle};
	padding: 40px;
	max-width: 400px;
	@media ${Medias.PHONE} {
		padding: 0;
		box-shadow: none;
		overflow: visible;
		padding-bottom: 50px;
		text-align: left;
		padding-top: 30px;
	}

	${p => p.isFromApp && css`
		color: white;
		${p.theme.backgrounds.pro.r.css('background-color')};
		flex-grow: 1;
		height: 100%
	`}
`