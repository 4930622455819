import React from 'react'
import styled, { css } from 'styled-components'

export const PageInset: React.FC<{ children: React.ReactNode; larger?: boolean; transparent?: boolean }> = ({ children, larger, transparent }) => {
	return (
		<Inset larger={larger} transparent={transparent}>
			<Centerer larger={larger}>{children}</Centerer>
		</Inset>
	)
}

export const Centerer = styled.div<{ larger?: boolean }>`
	max-width: 1340px;
	box-sizing: border-box;
	display: flex;
	width: 100%;
	min-height: 100%;
	box-sizing: border-box;
	align-items: stretch;
	flex-direction: column;
`

export const Inset = styled.div<{ larger?: boolean; transparent?: boolean }>`
	padding: 40px 120px 60px 120px;
	position: relative;
	flex-grow: 1;
	height: 100%;
	overflow: auto;
	z-index: 100;
	display: flex;
	-webkit-overflow-scrolling: touch;
	flex-direction: column;
	background-color: white;
	min-height: 100vh;
	box-sizing: border-box;
	align-items: center;

	${(p) =>
		p.transparent &&
		css`
			background-color: transparent;
		`}
`
