/* eslint-disable @typescript-eslint/no-unused-vars */
import { RootState } from '@root/store'
import { ULID } from '@root/store/commonTypes'
import { ViewID } from '@root/store/slices/interface.types'
import { selectionActions } from '@root/store/slices/selection'
import { DraggableZoneTemplate, getDragZoneFromTemplate, SelectableTypes, SelectionItemContext } from '@root/store/slices/selection.types'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useIsSelected = (
	type: SelectableTypes,
	id: ULID | undefined,
	fromZoneTemplate: DraggableZoneTemplate,
	...zoneIds: Array<string | undefined>
) => {
	const zone = useMemo(() => {
		return getDragZoneFromTemplate(fromZoneTemplate, ...zoneIds)
	}, [fromZoneTemplate, zoneIds])

	const selection = useSelector(
		(state: RootState) => state.selection
		// (a, b) => {
		// 	//k.all.includes(id) && <--- for anotherofkind
		// 	return selectorSelectiveCompare(a, b, (k) => (id ? !(k.all.includes(id) && k.scope == zone && k.type == type) : false))
		// }
	)

	return useMemo(
		() => ({
			isSolelySelected: id
				? selection.all.find((i) => i.id == id) && selection.scope == zone && selection.type == type && selection.all.length == 1
				: undefined,
			isSelected: id ? selection.all.find((i) => i.id == id) && selection.scope == zone && selection.type == type : undefined,
			anotherOfKind: selection.scope == zone && selection.type == type,
		}),
		[selection, id, zone, type]
	)
}

// export const useContainsSelected = (id: string) => {
// 	const selection = useSelector(
// 		(state: RootState) => state.selection,
// 		(a, b) => {
// 			return selectorSelectiveCompare(a, b, (k) => !k.scope?.includes(id))
// 		}
// 	)

// 	return useMemo(
// 		() => ({
// 			containsSelected: selection.scope?.includes(id),
// 		}),
// 		[selection, id]
// 	)
// }

export const useSelection = (scopeTemplate?: ViewID, scope?: string) => {
	const selection = useSelector(
		(state: RootState) => state.selection
		// (a, b) => {
		// 	return selectorSelectiveCompare(a, b, (k) => k.scopeTemplate === template)
		// }
	)

	return useMemo(() => {
		if (scopeTemplate !== undefined && selection.scopeTemplate == scopeTemplate) {
			return selection
		}
		if (scope !== undefined && selection.scope == scope) {
			return selection
		}
		return undefined
	}, [selection, scope, scopeTemplate])
}

export const useGlobalSelection = () => {
	const selection = useSelector((state: RootState) => state.selection)

	return useMemo(() => {
		return { selection }
	}, [selection])
}

// export const useSelectable = (
// 	type: SelectableTypes,
// 	id: ULID | undefined,
// 	fromZoneTemplate: DraggableZoneTemplate,
// 	...zoneIds: Array<string | undefined>
// ) => {
// 	const dispatch = useDispatch()
// 	const { isSelected, isSolelySelected, anotherOfKind } = useIsSelected(type, id, fromZoneTemplate, ...zoneIds)

// 	const select = useCallback<any>(
// 		(e: any) => {
// 			if (!id) return
// 			if (isSelected) return
// 			e?.stopPropagation()
// 			const zone = getDragZoneFromTemplate(fromZoneTemplate, ...zoneIds)
// 			dispatch(
// 				selectionActions.select({
// 					type: type,
// 					ids: [id],
// 					scopeTemplate: fromZoneTemplate,
// 					scope: zone,
// 				})
// 			)
// 		},
// 		[dispatch, fromZoneTemplate, zoneIds, type, id, isSelected]
// 	)

// 	return useMemo(() => {
// 		return {
// 			select,
// 			isSolelySelected,
// 			isSelected,
// 			anotherOfKind,
// 			selectedClassName: isSelected ? 'selected' : '',
// 		}
// 	}, [select, isSelected, anotherOfKind, isSolelySelected])
// }

export const useSelectable = (
	type: SelectableTypes,
	id: ULID | undefined,
	fromZoneTemplate: DraggableZoneTemplate,
	...zoneIds: Array<string | undefined>
) => {
	const dispatch = useDispatch()
	const selection = useGlobalSelection()

	const [isSelected, isSolelySelected, anotherOfKind] = useMemo(() => {
		if (!id) return [false, false, false]
		const context = undefined

		if (selection.selection.all.length == 1) {
			if (selection.selection.type == type && selection.selection.all.find((i) => i.id == id)) {
				return [true, true, true]
			}
		}
		if (selection.selection.type == type && selection.selection.all.find((i) => i.id == id)) {
			return [true, false, true]
		}
		if (selection.selection.type == type) {
			return [false, false, true]
		}

		return [false, false, false]
	}, [selection, id, type])

	const select = useCallback<any>(
		(e: any, itemContext: SelectionItemContext) => {
			if (!id) return
			if (isSelected) return
			e?.stopPropagation()
			// const zone = getDragZoneFromTemplate(fromZoneTemplate, ...zoneIds)
			dispatch(
				selectionActions.select({
					// context: context,
					ids: [itemContext],
					// scopeTemplate: fromZoneTemplate,
					// scope: zone,
				})
			)
		},
		[isSelected, dispatch, id]
	)

	return useMemo(() => {
		return {
			select,
			selectedClassName: isSelected ? 'selected' : '',
			isSelected,
			isSolelySelected,
			anotherOfKind,
		}
	}, [select, isSelected, isSolelySelected, anotherOfKind])
}

function selectorSelectiveCompare<T>(a: T, b: T, state: (k: T) => boolean): boolean {
	return state(a) == state(b)
}
