import { BackButton } from '@comps/complex/button/backButton';
import { WallpaperSwitcher } from '@comps/complex/wallpaperSwitcher';
import { DynamicSidebar } from '@comps/layout/dynamicSidebar';
import { Flex } from '@comps/layout/flex';
import { Spacer } from '@comps/layout/space';
import { ActivityBubble } from '@comps/static/activityBubble';
import { Text } from '@comps/typography/text';
import { useActivity } from '@root/store/selectors/useActivities';
import { useLastSession } from '@root/store/selectors/useLastSession';
import { getDuration } from '@root/utils/dates';
import { differenceInSeconds, format } from 'date-fns';
import { useLocation, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { SidebarLink } from './_sidebar.link';

export const ActivitySidebar = () => {
	const { id } = useParams()
	const activity = useActivity(id)
	const lastSession = useLastSession(id)
	const location = useLocation()
	const isDark = location.pathname.includes('cards')
	const [qp, setQp] = useSearchParams()

	return (
		<DynamicSidebar dark={isDark} larger>
			<Flex direction="column" align="stretch">
				<Flex grow justify="space-between" align="center">
					<BackButton defaultBackTo="/activities" supportsHoverDragAction />
					{activity && id && (
						<WallpaperSwitcher resourceId={id} placement={'right'} buttonColor={'translucentPassingColor'} resourceType="activity" />
					)}
				</Flex>
				<Flex align="center" direction="column">
					<Spacer size={50} vertical />
					{activity ? (
						<Flex direction="column" align="center">
							<ActivityBubble size="large" activity={activity} skeu />
							<Spacer size={20} vertical />
							<Text level="title60" block align="center">
								{activity.name}
							</Text>
							{/* <Spacer size={40} vertical />
							<SharingButton id={activity.id} /> */}
						</Flex>
					) : null}
					<Spacer size={60} vertical />
				</Flex>
				<Flex direction="column" align="stretch" spacing={2}>
					<SidebarLink color="translucentPassingColor" text="Cards" to="cards" icon="cards" />
					<SidebarLink color="translucentPassingColor" text="Sessions" to="sessions" icon="sessions" keepSelection />
					<SidebarLink color="translucentPassingColor" text="Insights" to="insights" icon="insights" />
					<SidebarLink color="translucentPassingColor" text="Routines" to="routines" icon="routines" keepSelection />
					{/* <SidebarLink color="translucentPassingColor" text="Settings" to="settings" larger /> */}
					{/* <NavigationButton to="cards">Card Stack</NavigationButton>
					<NavigationButton to="sessions">Sessions</NavigationButton>
					<NavigationButton to="insights">Insights</NavigationButton>
					<NavigationButton to="more">More</NavigationButton> */}
				</Flex>
			</Flex>
			{lastSession ? (
				<Text color="subtle">
					Last session took place on{' '}
					<Text color="regular" bold>
						{format(lastSession.start, 'MMMM d')}
					</Text>{' '}
					and lasted{' '}
					<Text color="regular" bold>
						{getDuration(differenceInSeconds(lastSession.end, lastSession.start))}
					</Text>
				</Text>
			) : null}
		</DynamicSidebar>
	)
}
