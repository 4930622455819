import { takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'
import { activitiesActions, activitiesSagas } from './slices/activities'
import { aiChatCardActions, aiChatCardSagas } from './slices/aiChatCard'
import { authActions, authSagas } from './slices/auth'
import { boardsActions, boardsSagas } from './slices/boards'
import { boyActions, boySagas } from './slices/boy'
import { cardsActions, cardsSagas } from './slices/cards'
import { errorToastsActions, errorToastsSagas } from './slices/errorToasts'
import { fragmentsActions, fragmentsSagas } from './slices/fragments'
import { grpcActions, grpcSagas } from './slices/grpc.DEPRECATED'
import { imageProcessingActions, imageProcessingSagas } from './slices/imageProcessing'
import { notificationsActions, notificationsSagas } from './slices/notifications'
import { persistActions, persistSagas } from './slices/persist'
import { routinesActions, routinesSagas } from './slices/routines'
import { routineSchedulesSagas, routinesSchedulesActions } from './slices/routineSchedules'
import { sessionsActions, sessionsSagas } from './slices/sessions'
import { sharingActions, sharingSagas } from './slices/sharing'
import { syncActions, syncSagas } from './slices/sync'
import { syncActivitiesActions, syncActivitiesSagas } from './slices/sync.activities'
import { syncBoardsActions, syncBoardsSagas } from './slices/sync.boards'
import { syncCardsActions, syncCardsSagas } from './slices/sync.cards'
import { syncNotificationsActions, syncNotificationsSagas } from './slices/sync.notifications'
import { syncSessionsActions, syncSessionsSagas } from './slices/sync.sessions'
import { timeActions, timeSagas } from './slices/time'
import { userInvitesActions, userInvitesSagas } from './slices/userInvites'
import { userProfilesActions, userProfilesSagas } from './slices/userProfiles'
import { watchActions, watchSagas } from './slices/watch'
import { watcherActions, watchersSagas } from './slices/watchers'

export function* sagas() {

	// yield takeLeading(boardsActions.initialize.match, boardsSagas.initialize)
	// yield takeEvery(boardsActions.put.match, boardsSagas.put)
	// yield takeEvery(boardsActions.delete.match, boardsSagas.delete)

	// PACKETS
	yield takeLeading(activitiesActions.initialize.match, activitiesSagas.initialize)
	yield takeEvery(activitiesActions.new.match, activitiesSagas.new)
	yield takeEvery(activitiesActions.update.match, activitiesSagas.update)
	yield takeEvery(activitiesActions.delete.match, activitiesSagas.delete)
	yield takeEvery(activitiesActions.store.match, activitiesSagas.store)
	yield takeEvery(activitiesActions.loadToStore.match, activitiesSagas.loadToStore)

	yield takeLeading(boardsActions.initialize.match, boardsSagas.initialize)
	yield takeEvery(boardsActions.new.match, boardsSagas.new)
	yield takeEvery(boardsActions.update.match, boardsSagas.update)
	yield takeEvery(boardsActions.delete.match, boardsSagas.delete)
	yield takeEvery(boardsActions.store.match, boardsSagas.store)
	yield takeEvery(boardsActions.loadToStore.match, boardsSagas.loadToStore)

	

	yield takeEvery(cardsActions.loadCardsForResourceId.match, cardsSagas.loadCardsForResourceId)
	yield takeEvery(cardsActions.new.match, cardsSagas.new)
	yield takeEvery(cardsActions.update.match, cardsSagas.update)
	yield takeEvery(cardsActions.delete.match, cardsSagas.delete)
	yield takeEvery(cardsActions.move.match, cardsSagas.move)
	yield takeEvery(cardsActions.normalizeAndStore.match, cardsSagas.normalizeAndStore)

	yield takeEvery(fragmentsActions.loadFragmentsForCardId.match, fragmentsSagas.loadFragmentsForCardId)
	yield takeEvery(fragmentsActions.fragmentPlainTextNew.match, fragmentsSagas.fragmentPlainTextNew)
	yield takeEvery(fragmentsActions.fragmentPlainTextUpdate.match, fragmentsSagas.fragmentPlainTextUpdate)
	yield takeEvery(fragmentsActions.fragmentCompletableNew.match, fragmentsSagas.fragmentCompletableNew)
	yield takeEvery(fragmentsActions.fragmentCompletableUpdate.match, fragmentsSagas.fragmentCompletableUpdate)
	yield takeEvery(fragmentsActions.fragmentUploadableNew.match, fragmentsSagas.fragmentUploadableNew)
	yield takeEvery(fragmentsActions.fragmentUploadableUpdate.match, fragmentsSagas.fragmentUploadableUpdate)
	yield takeEvery(fragmentsActions.delete.match, fragmentsSagas.delete)

	// yield takeLeading(vitalsActions.initialize, vitalsSagas.initialize)
	// yield takeEvery(vitalsActions.put, vitalsSagas.put)

	yield takeLatest(sessionsActions.refresh, sessionsSagas.refresh)
	yield takeEvery(sessionsActions.setActiveScope, sessionsSagas.setActiveScope)
	yield takeEvery(sessionsActions.scheduleScopeCancelling, sessionsSagas.scheduleScopeCancelling)
	yield takeEvery(sessionsActions.cancelActiveScope, sessionsSagas.cancelActiveScope)
	yield takeEvery(sessionsActions.new.match, sessionsSagas.new)
	yield takeEvery(sessionsActions.update.match, sessionsSagas.update)
	yield takeEvery(sessionsActions.delete.match, sessionsSagas.delete)
	yield takeEvery(sessionsActions.move, sessionsSagas.move)

	yield takeEvery(routinesActions.loadAllToStore, routinesSagas.loadAllToStore)
	yield takeEvery(routinesActions.delete, routinesSagas.delete)
	yield takeEvery(routinesActions.loadCards.match, routinesSagas.loadCards)
	yield takeEvery(routinesSchedulesActions.loadAllToStore, routineSchedulesSagas.loadAllToStore)

	yield takeLeading(authActions.logout.match, authSagas.logout)
	yield takeLeading(authActions.deleteAccount.match, authSagas.deleteAccount)
	yield takeLeading(authActions.joinWithEmail.match, authSagas.joinWithEmail)
	yield takeLeading(authActions.login.match, authSagas.login)
	yield takeLeading(authActions.startWatcher.match, authSagas.startWatcher)
	yield takeLeading(authActions.triggerPasswordChangeRequest.match, authSagas.triggerPasswordChangeRequest)
	yield takeLeading(authActions.authHandleResetPassword.match, authSagas.authHandleResetPassword)
	yield takeLeading(authActions.authHandleRecoverEmail.match, authSagas.authHandleRecoverEmail)
	yield takeLeading(authActions.authHandleEmailVerification.match, authSagas.authHandleEmailVerification)
	yield takeLeading(authActions.setState.match, authSagas.saveLocalState)
	

	// yield takeEvery(billingActions.subscribe.match, billingSagas.subscribe)

	yield takeEvery(grpcActions.send.match, grpcSagas.send)
	yield takeEvery(grpcActions.generatePaymentIntent.match, grpcSagas.generatePaymentIntent)

	yield takeLeading(timeActions.start.match, timeSagas.start)
	yield takeLeading(timeActions.end.match, timeSagas.end)

	yield takeEvery(errorToastsActions.push.match, errorToastsSagas.push)

	yield takeEvery(watcherActions.fail.match, watchersSagas.fail)
	yield takeEvery(watcherActions.done.match, watchersSagas.done)


	yield takeEvery(userInvitesActions.inviteUser.match, userInvitesSagas.inviteUser)
	yield takeEvery(userInvitesActions.fetchPastInvites.match, userInvitesSagas.fetchPastInvites)
	yield takeEvery(userInvitesActions.revokeInvitee.match, userInvitesSagas.revokeInvitee)
	yield takeEvery(userInvitesActions.acceptInvitation.match, userInvitesSagas.acceptInvitation)
	yield takeEvery(userInvitesActions.checkBeenInvited.match, userInvitesSagas.checkBeenInvited)

	yield takeEvery(userProfilesActions.loadProfileIntoStore.match, userProfilesSagas.loadProfileIntoStore)
	yield takeLeading(userProfilesActions.fetchBatchProfiles.match, userProfilesSagas.fetchBatchProfiles)
	yield takeLeading(userProfilesActions.updateProfile.match, userProfilesSagas.updateProfile)
	yield takeEvery(userProfilesActions.fetchUserAvatar.match, userProfilesSagas.fetchUserAvatar)

	yield takeEvery(sharingActions.createSharingInvitation.match, sharingSagas.createSharingInvitation)
	yield takeEvery(sharingActions.acceptSharingInvitation.match, sharingSagas.acceptSharingInvitation)
	yield takeEvery(sharingActions.store.match, sharingSagas.store)

	yield takeLeading(watchActions.startWatching.match, watchSagas.startWatching)
	yield takeEvery(watchActions.restartStream.match, watchSagas.restartStream)

	// yield takeLeading(runesActions.placeInStore.match, runesSagas.placeInStore)

	
	yield takeEvery(syncActions.fetchUpdates.match, syncSagas.fetchUpdates)
	yield takeEvery(syncActions.loadActivitiesSyncStatus.match, syncSagas.loadActivitiesSyncStatus)
	yield takeLatest(syncActions.doCriticalOps.match, syncSagas.doCriticalOps)
	
	yield takeEvery(syncNotificationsActions.fetchNotifications.match, syncNotificationsSagas.fetchNotifications)
	yield takeEvery(syncSessionsActions.streamSession.match, syncSessionsSagas.streamSession)
	yield takeEvery(syncActivitiesActions.fetchActivities.match, syncActivitiesSagas.fetchActivities)
	yield takeEvery(syncActivitiesActions.streamActivity.match, syncActivitiesSagas.streamActivity)
	yield takeEvery(syncBoardsActions.fetchBoards.match, syncBoardsSagas.fetchBoards)
	yield takeEvery(syncBoardsActions.streamBoard.match, syncBoardsSagas.streamBoard)
	yield takeEvery(syncCardsActions.streamCard.match, syncCardsSagas.streamCard)
	yield takeEvery(routinesActions.createSession.match, routinesSagas.createSession)

	yield takeEvery(notificationsActions.placeInStoreLatestNotifications.match, notificationsSagas.placeInStoreLatestNotifications)

	yield takeLeading(persistActions.pendingPackets.match, persistSagas.pendingPackets)
	// yield takeLeading(packetsActions.placeInStore.match, packetsSagas.placeInStore)

	yield takeLatest(cardsActions.moveToNewParentResource.match, cardsSagas.moveToNewParentResource)
	yield takeEvery(aiChatCardActions.createNewChat.match, aiChatCardSagas.createNewChat)
	yield takeEvery(aiChatCardActions.continueChat.match, aiChatCardSagas.continueChat)	
	yield takeEvery(aiChatCardActions.loadMessagesAndChats.match, aiChatCardSagas.loadMessagesAndChats)
	yield takeEvery(aiChatCardActions.constructGist.match, aiChatCardSagas.constructGist)
	yield takeLeading(imageProcessingActions.processImages.match, imageProcessingSagas.processImages)
	
	
	if (process.env.NODE_ENV == 'development') {
		yield takeEvery(boyActions.activateUser.match, boySagas.activateUser)
		yield takeEvery(boyActions.deleteUser.match, boySagas.deleteUser)
		yield takeEvery(boyActions.inviteUser.match, boySagas.inviteUser)
		yield takeEvery(boyActions.grantInvites.match, boySagas.grantInvites)
	}
}
