import CJSCore from '@clepside/clepsidejs/lib/commons/core_pb'
import {
	FragmentPlainTextPacket,
	FragmentPlainTextPacketData,
	FragmentPlainTextPacketMeta
} from '@clepside/clepsidejs/lib/packets_v1/fragments_plain_text_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { generatePacketMeta } from './persist.utils'
import { FragmentPlainTextPacketObject } from './sync.tsx.packets.types'

export function constructFragmentPlainTextPacket(readyToSendPacket: Packet, fragPacket: FragmentPlainTextPacketObject) {
    const tbsFragPacket = new FragmentPlainTextPacket()
    const { data, deleted, id, type, meta } = fragPacket
    if (!data) throw new Error('Packet data is undefined')
    
    const { cardId, position, contents  } = data
    
    tbsFragPacket.setId(id)

    if (!type) {
        throw new Error('packet type not found')
    }

    if (deleted) {
        const deletedMeta = new CJSCore.DeleteMeta()
        deletedMeta.setAt(deleted.at)
        deletedMeta.setBy(deleted.by)
        deletedMeta.setIs(deleted.is)
        tbsFragPacket.setDeleted(deletedMeta)
    }
    if (data) {
        const newData = new FragmentPlainTextPacketData()
        const newMeta = new FragmentPlainTextPacketMeta()

        if (cardId) { 
            newData.setCardId(cardId)
        }

        if (position !== undefined && meta.position) { 
            newData.setPosition(position) 
            newMeta.setPosition(generatePacketMeta(fragPacket, 'position'))
        }
        
        if (contents !== undefined && meta.contents) { 
            newData.setContents(contents) 
            newMeta.setContents(generatePacketMeta(fragPacket, 'contents'))
        }

        tbsFragPacket.setData(newData)
        tbsFragPacket.setMeta(newMeta)
        tbsFragPacket.setType(type)
    }


    readyToSendPacket.setFragmentPlainText(tbsFragPacket)
}