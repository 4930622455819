import { DragDropCore, DragDropListener } from '@root/cores/dragDropCore'
import { Collection } from '@root/store/commonTypes'
import { FlowID } from '@root/store/slices/flows.types'
import { InstancedSessionData } from '@root/store/slices/sessionsTypes'
import { dateFitsInside, DateInterval } from '@root/utils/dateIntervals'
import { roundToNearestMinuteInUTCs } from '@root/utils/dates'
import { Direction } from '@root/utils/general'
import { differenceInMinutes, isBefore, isSameMinute } from 'date-fns'
import { MutableRefObject, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SessionHighlight } from './sessionPlacer.sessionHighlight'
import { SessionPlacerDimensions } from './sessionPlacer.types'
import { getCursorDateFromCoords } from './sessionPlacer.utils'

type Highlight =
	| {
			start: Date
			end: Date
	  }
	| undefined

const getBoundedSession = (start: Date, end: Date, freeIntervals: Array<DateInterval>, direction: Direction) => {
	const findClosestBound = (x: Date) => {
		let max = Number.MAX_SAFE_INTEGER
		let intervalIndex: number | undefined = undefined

		for (let i = 0; i < freeIntervals.length; i++) {
			const interval = freeIntervals[i]
			if (Math.abs(differenceInMinutes(interval[1], x)) < max) {
				max = Math.abs(differenceInMinutes(interval[1], x))
				intervalIndex = i
			}
		}

		if (intervalIndex !== undefined) {
			if (direction === 'backwards') {
				return { interval: freeIntervals[intervalIndex], bound: freeIntervals[intervalIndex][1] }
			} else {
				const next = freeIntervals[intervalIndex + 1]
				return next ? { interval: next, bound: next[0] } : undefined
			}
		}
		return undefined
	}

	let pickedInterval = undefined
	let startFitsInside = false
	let startBound = start
	let endBound = end

	for (const interval of freeIntervals) {
		if (dateFitsInside(startBound, interval)) {
			startFitsInside = true
			pickedInterval = interval
			break
		}
	}
	if (startFitsInside === false) {
		const response = findClosestBound(start)
		if (!response)
			return {
				start: startBound,
				end: endBound,
			}
		const { interval, bound } = response
		if (interval && bound) {
			pickedInterval = interval
			startBound = bound
		}
	}

	if (pickedInterval && !dateFitsInside(end, pickedInterval)) {
		if (isBefore(endBound, pickedInterval[0])) endBound = pickedInterval[0]
		else endBound = pickedInterval[1]
	}

	return {
		start: startBound,
		end: endBound,
	}
}

export const useHighlightedSession = (
	container: HTMLElement,
	vector: number[],
	shownDatesIndex: number[],
	dimensions: SessionPlacerDimensions,
	sessions: Collection<InstancedSessionData>,
	dragZoneId: string,
	freeIntervals: MutableRefObject<Array<[Date, Date]>>,
	offsetLeft?: number
) => {
	const [highlight, setHighlight] = useState<Highlight>(undefined)
	const [qp, setQp] = useSearchParams()
	const placement = useRef<any>(undefined)
	const [direction, setDirection] = useState<Direction>('forward')
	const [buttonLabel, setButtonLabel] = useState<'log' | 'plan'>('log')
	const buttonSide = useRef<'left' | 'right'>('left')
	const lastY = useRef(0)
	const pickupPlace = useRef<number | undefined>()

	useEffect(() => {
		if (!container) return

		const listener: DragDropListener = (phase, context, e) => {
			if (context.type !== 'highlight') return
			if (phase === 'interrupted') {
				setHighlight(undefined)
				return
			}

			if (!e) return

			const end = getCursorDateFromCoords(
				container,
				e.clientX,
				e.clientY - container.getBoundingClientRect().top,
				shownDatesIndex,
				vector,
				dimensions
			)

			if (!end) {
				return
			}

			const dates = {
				start: roundToNearestMinuteInUTCs(new Date(context.startPoint), 5),
				end: roundToNearestMinuteInUTCs(end, 5),
			}

			placement.current = {
				start: dates.start,
				end: dates.end,
			}

			let dir: Direction = 'forward'
			if (isBefore(placement.current.end, placement.current.start)) {
				dir = 'backwards'
			}

			placement.current = getBoundedSession(placement.current.start, placement.current.end, freeIntervals.current, dir)

			const SWAP = new Date(placement.current.start)
			if (dir === 'backwards') {
				placement.current.start = new Date(placement.current.end)
				placement.current.end = SWAP
			}
			setDirection(dir)

			if (!placement.current) {
				setHighlight(undefined)
				return
			}

			if (isBefore(placement.current.start, new Date())) {
				setButtonLabel('log')
			} else {
				setButtonLabel('plan')
			}

			if (phase === 'finished') {
				setHighlight(undefined)
				return
			}

			if (!e) return

			if (phase == 'start') {
				const width = e.clientX - dimensions.padding
				const dayWidth = dimensions.dayWidth + dimensions.gap
				pickupPlace.current = (width - Math.floor(width / dayWidth) * dayWidth) / dayWidth
			}

			if (!isSameMinute(placement.current.start, placement.current.end)) {
				setHighlight((h) => {
					if (h && Math.abs(differenceInMinutes(h?.end, h?.start) - lastY.current) >= 45) {
						buttonSide.current = pickupPlace.current && pickupPlace.current > 0.5 ? 'left' : 'right'
						lastY.current = differenceInMinutes(h?.end, h?.start)
					}
					if (h && isSameMinute(h?.start, placement.current?.start) && isSameMinute(h?.end, placement.current?.end))
						return h
					else {
						return placement.current
					}
				})
			} else setHighlight(undefined)
		}

		const removeListener = DragDropCore.addListener(dragZoneId, listener)
		const unmount = DragDropCore.mountContainer(dragZoneId, 1, container)
		return () => {
			removeListener()
			unmount()
		}
	}, [container, dragZoneId, freeIntervals, setQp, sessions, shownDatesIndex, dimensions, vector])

	const goToPlan = useCallback(() => {
		setHighlight(undefined)
		setQp({
			flow: 'plan-session' as FlowID,
			start: placement.current.start.toISOString(),
			end: placement.current.end.toISOString(),
			...((qp.get('date') ? { date: qp.get('date') } : {}) as any),
		})
	}, [setQp, qp])

	const node = useMemo<SessionPlacerHighlight | undefined>(() => {
		if (!highlight) return undefined

		return (
			{
				info: {
					start: highlight.start,
					end: highlight.end
				},
				element: <SessionHighlight
					vector={vector}
					dimensions={dimensions}
					buttonSide={buttonSide}
					direction={direction}
					offsetLeft={offsetLeft}
					presentedDatesIndexes={shownDatesIndex}
					start={highlight.start}
					end={highlight.end}
					goToPlan={goToPlan}
					buttonLabel={buttonLabel}
				/>
			}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [highlight, dimensions, goToPlan])

	return { highlight: node }
}

export type SessionPlacerHighlight = {
	info: {
		start: Date,
		end: Date
	},
	element: ReactElement
}