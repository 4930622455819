import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { Flex } from '@comps/layout/flex'
import { Spacer } from '@comps/layout/space'
import { Icon } from '@comps/static/icon'
import { Text } from '@comps/typography/text'
import { useActivity } from '@root/store/selectors/useActivities'
import { formatDistance } from 'date-fns'
import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { AddCardMultiButton } from '../addCardMultiButton'
import { GridStyles } from '../cards/cardGrid'
import { useCardDatasource } from '../cards/cardGrid.useCardDatasource'

export const ActivityHeader: React.FC<{ activityId: string }> = ({ activityId }) => {
	const activity = useActivity(activityId)
	const { cards } = useCardDatasource(activityId)

	const lastModifiedAt = useMemo(() => {
		let lamt = 0
		cards.forEach((c) => {
			if (c.lastModifiedAt > lamt) {
				lamt = c.lastModifiedAt
			}
		})
		if (lamt) {
			const date = new Date(lamt)
			return formatDistance(date, new Date())
		}
		return undefined
	}, [cards])

	return (
		<Holder>
			<Flex align="baseline">
				<Icon type="activity-board-medium" offsetY={1.5} />
				<Spacer size={10} />
				<b>Activity Cards</b>
				{lastModifiedAt ? <Text level="label" color="darkTranslucencySubtle" block>
					&nbsp;have been last edited {lastModifiedAt} ago.
				</Text>
					: <Text level="label" color="darkTranslucencySubtle" block>
						&nbsp;have never been used.
					</Text>
				}

			</Flex>
			<AddCardMultiButton resourceId={activityId} resourceType={GrantableResourceEnum.ACTIVITY} />
		</Holder>
	)
}

const Holder = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	${(p) => p.theme.text.darkTranslucency.r.css('color')};
`

const ActivityBoardInfo = styled.div`
	padding: 15px 30px;
	position: sticky;
	top: 0;

	border-radius: ${(p) => p.theme.statics.borderRadius.medium};
	backdrop-filter: blur(180px);
	${(p) => p.theme.backgrounds.darkTranslucency.r.css('background-color')};
	color: white;
	display: flex;
	box-sizing: border-box;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	z-index: 10;
	${GridStyles};
`
