import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { CardLayout } from '@clepside/clepsidejs/lib/packets_v1/cards_pb';

import { CardPacketObject, SettingsPacketObject } from './sync.tsx.packets.types';
import { LocalData } from './sync.types';

export type SettingsData = Omit<LocalData<SettingsPacketObject>, 
'uitimelineonright' | 'dateprefersampm' | 'datestartonmonday' | 'gestureslideontimeline'> & { 
    uiTimeineOnRight: boolean,
    datePrefersAMPM: boolean,
    dateStartOnMonday: boolean,
    gestureSlideOnTimeline: boolean,
    clientId?: string
}


type SettingsState = SettingsData

export const settingsState: SettingsState = {
    id: '_',
    uiTimeineOnRight: false,
    datePrefersAMPM: false,
    dateStartOnMonday: false,
    gestureSlideOnTimeline: false,
    clientId: undefined
}
