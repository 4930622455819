import { useDialog } from '@comps/dialog';
import { Button } from '@comps/interactive/button';
import { Flex } from '@comps/layout/flex';
import { Inset } from '@comps/layout/inset';
import { PageHeader } from '@comps/layout/pageHeader';
import { PageInset } from '@comps/layout/pageInset';
import { Spacer } from '@comps/layout/space';
import { Cell, HeaderCell, Row, Table } from '@comps/layout/table';
import { ActivityBubble } from '@comps/static/activityBubble';
import { FontStyles, Text } from '@comps/typography/text';
import { getDataAttribute } from '@root/utils/general';
import { useActivities } from '@store/selectors/useActivities';
import { activitiesActions } from '@store/slices/activities';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

export const Activities: React.FC = () => {
	const activities = useActivities()
	const dispatch = useDispatch()
	const [filter, setFilter] = useState('')
	const filteredAll = useMemo(() => {
		return activities.all.filter((id) => {
			if (activities.at[id].name.toLowerCase().includes(filter.toLowerCase())) {
				return true
			}
		})
	}, [activities, filter])
	const [, setQp] = useSearchParams()

	const handleDelete = useCallback(
		(id: string) => {
			dispatch(activitiesActions.delete({ id }))
		},
		[dispatch]
	)

	// const byInitial = useMemo(() => {
	// 	const initials = filteredAll.reduce((acc, id) => {
	// 		const activity = activities.at[id]
	// 		const initial = activity.name[0].toUpperCase()

	// 		if (!acc[initial]) {
	// 			acc[initial] = []
	// 		}

	// 		acc[initial].push(id)

	// 		return acc
	// 	}, {} as any)

	// 	return {
	// 		at: initials,
	// 		all: Object.keys(initials),
	// 	}
	// }, [activities, filteredAll])

	const onClick = useCallback(
		(e: any) => {
			const id = getDataAttribute(e, 'id')
			setQp({
				flow: 'create-or-edit-activity',
				id,
			})
		},
		[setQp]
	)
	const handleCreate = useCallback(
		(e: any) => {
			e.preventDefault()
			e.stopPropagation()
			setQp({
				flow: 'create-or-edit-activity',
			})
		},
		[setQp]
	)
	const { showDialog } = useDialog()

	return (
		<PageInset larger>
			<PageHeader
				title="Activities"
				rightSide={
					<Link to="/activity-brief">
						<Button color="subtleAccent" onClick={handleCreate}>
							<Inset padding="buttonMedium">Create an Activity</Inset>
						</Button>
					</Link>
				}
			/>
			<Flex direction="row">
				<Flex direction="column" align="stretch" justify="center" grow>
					<ActivitiesFilter
						placeholder="Filter activities"
						onInput={(e) => {
							setFilter(e.currentTarget.value)
						}}
					/>
					<Spacer size={30} vertical />
					<Inset padding="tiny">
						<Flex direction="column" align="stretch" grow>
							<Table layout="min-content 1fr min-content" type="bordered">
								<Row>
									<HeaderCell>Activity</HeaderCell>
									<HeaderCell></HeaderCell>
									{/* <HeaderCell>Sharing</HeaderCell> */}
									<HeaderCell>Actions</HeaderCell>
								</Row>
								{filteredAll.map((id) => {
									const activity = activities.at[id]
									return (
										<Row key={id}>
											<Cell noBorder>
												<Flex>
													<ActivityBubble
														size="small"
														activity={activity}
														status="inactiveLight"
													/>
													<Spacer size={15} />
												</Flex>
											</Cell>
											<Cell noPaddingLeft>
												<Flex width="100%">
													<Text level="body" noWrap>
														{activities.at[id]?.name || 'Unnamed Activity'}
													</Text>
													<Spacer size={20} />
												</Flex>
											</Cell>
											{/* <Cell>
												<Flex>
													<ActivitiesCollaboratorsColumn id={id} />
													<Spacer size={30} />
												</Flex>
											</Cell> */}
											<Cell>
												<Inset negativeX key={`actions-${id}`}>
													<Flex spacing={5}>
														<Link to={`/activities/${id}`}>
															<Button
																color="textualAccent"
																supportsHoverDragAction
																inset="buttonSmall"
																keepSelection
															>
																<Text
																	level="label"
																	block
																>
																	Open
																</Text>
															</Button>
														</Link>
														<Button
															color="textualAccent"
															onClick={onClick}
															data-id={id}
															inset="buttonSmall"
														>
															<Text level="label" block>
																Edit
															</Text>
														</Button>
														<Button
															color="textualAccent"
															inset="buttonSmall"
															onClick={() => {
																const title = 'Confirm Activity Deletion'
																const message = `Are you sure you want to delete ${activities.at[id]?.name || 'Unnamed Activity'}`
																showDialog(title, message, () => {
																	handleDelete(id)
																})
															}}
														>
															<Text level="label" block>
																Delete
															</Text>
														</Button>
													</Flex>
												</Inset>
											</Cell>
										</Row>
									)
								})}
							</Table>
						</Flex>
					</Inset>
				</Flex>
				<Spacer size={80} />
				<LeftSide>
					<Text level="largerParagraphBody">
						<strong>You currently have {activities.all.length} activities.</strong> Activities help you schedule
						your time, get insights and create contexts.
					</Text>
				</LeftSide>
			</Flex>
			{/* </Flex> */}
		</PageInset>
	)
}

const LeftSide = styled.div`
	max-width: 360px;
	width: 100%;
	flex-shrink: 1;
`

const ActivitiesFilter = styled.input`
	padding: 14px 20px;
	background-color: #fff;
	border-radius: 14px;
	width: 100%;
	border: none;
	color: currentColor;
	box-sizing: border-box;
	margin-top: 4px;
	box-sizing: border-box;

	cursor: text !important;

	${FontStyles.body};
	${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
`

// const tableStructure = useMemo(() => {
// 	return {
// 		Activity: {
// 			render: (id: string) => (
// 				<Flex align="center" key={`activity-${id}`}>
// 					<ActivityBubble size="small" activity={activities.at[id]} />
// 					<Spacer size={10} />
// 					<Text>{activities.at[id]?.name || 'test'}</Text>
// 				</Flex>
// 			),
// 		},
// 		'Next planned session': {
// 			render: (id: string) => <span key={`next-planned-session-${id}`} />,
// 		},
// 		Actions: {
// 			align: 'right' as const,
// 			render: (id: string) => (
// 				<Inset negativeX key={`actions-${id}`}>
// 					<Flex>
// 						<Link to={`/activities/${id}`}>
// 							<Button color="textualAccent">
// 								<Inset oneLine>See Activity</Inset>
// 							</Button>
// 						</Link>
// 						<Link to={`/activity-brief/${id}`}>
// 							<Button color="textualAccent">
// 								<Inset>Edit</Inset>
// 							</Button>
// 						</Link>
// 						<Button color="textualAccent" onClick={() => handleDelete(id)}>
// 							<Inset>Delete</Inset>
// 						</Button>
// 					</Flex>
// 				</Inset>
// 			),
// 		},
// 	}
// }, [activities, handleDelete])
