import CJSCore, { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb';

import { PacketObject, PacketStateData } from './sync.tsx.packets.types';
import { ValidPacketType } from './sync.types';

export type PacketDataDelta<K extends ValidPacketType> = PacketStateData<K> & {
    id?: string
    shouldDelete?: boolean
}


type Intersection<U, V> = 
  (U extends V ? U : never) &
  (V extends U ? V : never);


export type MetaFields<K extends ValidPacketType> = typeof PacketFieldsDeltaConfig[K]['withMeta'][0]
export type InitialFields<K extends ValidPacketType> = typeof PacketFieldsDeltaConfig[K]['skipMeta'][0]

export type NewPacketDelta<K extends ValidPacketType> = Pick<PacketStateData<K>, Intersection<keyof PacketStateData<K>, InitialFields<K> | MetaFields<K>>>
export type UpdatePacketDelta<K extends ValidPacketType> = Partial<Pick<PacketStateData<K>, Intersection<keyof PacketStateData<K>, MetaFields<K>>>> & { id: string }
export type RemovePacketDelta = { id: string, shouldDelete: boolean }
export type RemovePacketDeltaIdOnly = Omit<RemovePacketDelta, 'shouldDelete'>
// type PacketDataDeltaProps<K extends keyof PacketTypesMapping> = {
// 	skipDeleted?: boolean
// 	withMeta: Array<keyof PacketStateData<K>>
// 	handleSpecially?: Array<keyof PacketStateData<K>>
// 	skipMeta?: Array<keyof PacketStateData<K>>
// }

export const PacketFieldsDeltaConfig = {
	[PacketType.ACTIVITY]: {
		withMeta: [
			'color' as const,
			'icon' as const,
			'name' as const,
			'wallpaper' as const
		],
		skipMeta: []
	},
	// [PacketType.BOARD]: {
	// 	withMeta: ['color', 'name']
	// },
	[PacketType.SETTINGS]: {
		withMeta: [
			'uitimelineonright' as const,
			'dateprefersampm' as const,
			'datestartonmonday' as const,
			'gestureslideontimeline' as const
		],
		skipMeta: []
	},
	[PacketType.CARD]: {
		withMeta: [
			'layout' as const,
			'verticallayout' as const,
			'resourceId' as const,
			'resourceType' as const
		],
		skipMeta: [
			'cardType' as const
		]
	},
	[PacketType.BOARD]: {
		withMeta: [
			'name' as const,
			'color' as const,
			'wallpaper' as const
		],
		skipMeta: []
	},
	[PacketType.SESSION]: {
		withMeta: [
			'end' as const,
			'start' as const
		],
		skipMeta: [
			'activityId' as const,
			'role' as const
		]
	},
	[PacketType.FRAGMENT_COMPLETABLE]: {
		withMeta: [
			'isCompleted' as const,
			'position' as const,
			'title' as const
		],
		skipMeta: [
			'cardId' as const,
			// 'resourceId' as const,
			// 'resourceType' as const
		]
	},
	[PacketType.FRAGMENT_PLAIN_TEXT]: {
		withMeta: [
			'contents' as const,
			'specifics' as const,
			'position' as const,
		],
		skipMeta: [
			'cardId' as const,
			// 'resourceId' as const,
			// 'resourceType' as const
		]
	},
	[PacketType.FRAGMENT_UPLOADABLE]: {
		withMeta: [
			'fileInfo' as const,
		],
		skipMeta: [
			'cardId' as const,
			// 'resourceId' as const,
			// 'resourceType' as const
		]
	},
	[PacketType.ROUTINE]: {
		withMeta: [
			'name' as const,
			'description' as const,
		],
		skipMeta: [
			'activityId' as const,
		]
	},
	[PacketType.ROUTINE_SCHEDULE]: {
		withMeta: [
			'period' as const,
			'time' as const,
			'priority' as const,
			'startingOn' as const,
		],
		skipMeta: [
			'routineId' as const,
		]
	},
	// [PacketType.VITALS]: {
	// 	skipDeleted: true,
	// 	withMeta: [],
	// 	skipMeta: ['activitiesLastSync', 'id']
	// },
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CheckPacketTypes = {
    [K in ValidPacketType]: typeof PacketFieldsDeltaConfig[K]
};

// SkipCompletely meant the prop is only used for local
// SkipMeta means it's unchangeable...
// : {
// 	[K in keyof PacketTypesMapping]: PacketDataDeltaProps<K>
// } 


export function getDeltaConfigForPacketType<K extends ValidPacketType>(type: K): typeof PacketFieldsDeltaConfig[K] {
	return PacketFieldsDeltaConfig[type]
}

export const mergePackets = (existing: PacketObject<any> | undefined, update: PacketObject<any> | undefined) => {
	if (!update) {
		return existing
	}
	if (!existing) {
		return update
	}

	const ed = existing.data as any
	const ud = update.data as any
	const em = existing.meta as any
	const um = update.meta as any

	const merged: any = {
		...existing,
		...update
	}

	const datas = new Set([...Object.keys(update?.data || {}), ...Object.keys(existing?.data || {})])
	// const metas = new Set([...Object.keys(update?.meta || {}), ...Object.keys(existing?.meta || {})])

	for (const field of datas) {
		if (em[field]?.at && um[field]?.at) {
			if (em[field].at > um[field].at) {
				merged.meta[field] = em[field]
				merged.data[field] = ed[field]
			} else {
				merged.data[field] = ud[field]
				merged.meta[field] = um[field]
			}
			continue
		}
		if (em[field]?.at) {
			merged.meta[field] = em[field]
			merged.data[field] = ed[field]
			continue
		}
		if (um[field]?.at) {
			merged.data[field] = ud[field]
			merged.meta[field] = um[field]
			continue
		}
		if (ud[field] && ed[field]) {
			if (ed[field] != ud[field]) {
				console.log('found packets.. diff data, no metas', ed, ud, em, um, field)
				// throw new Error('found packets.. diff data, no metas')
			}
		}

		if (ed[field]) {
			merged.data[field] = ed[field]
		}
		if (ud[field]) {
			merged.data[field] = ud[field]
		}
	}

	if ('deleted' in update && update.deleted && 'deleted' in existing && existing.deleted) {
		if (existing.deleted.at > update.deleted.at) {
			merged.deleted = {
				...existing.deleted,
			}
		} else {
			merged.deleted = {
				...update.deleted,
			}
		}
	} else {
		if ('deleted' in update && update.deleted) {
			merged.deleted = {
				...update.deleted,
			}
		}
		if ('deleted' in existing && existing.deleted) {
			merged.deleted = {
				...existing.deleted,
			}
		}
	}

	return merged
			
	// if (existing) {
	// 	const newPacket: PacketObject<any> = JSON.parse(JSON.stringify(existing))
	// 	const newPacketData: any = newPacket.data || {} 
	// 	const newPacketMeta: any = newPacket.meta || {} 
	// 	const payloadDataFieldsSet = new Set([...Object.keys(update?.data || {}), ...Object.keys(existing?.data || {})])


	// 	payloadDataFieldsSet.forEach((fKey) => {
	// 		const payloadData = update.data as any
	// 		const payloadMeta = update.meta as any

	// 		if (newPacketMeta?.[fKey] && payloadMeta?.[fKey]?.at) {
	// 			if (newPacketMeta[fKey]?.at <= payloadMeta[fKey]?.at) {
	// 				newPacketData[fKey] = payloadData[fKey]
	// 				newPacketMeta[fKey] = { ...payloadMeta[fKey] }
	// 			}
	// 		}

	// 		if (!newPacketMeta?.[fKey] && payloadMeta?.[fKey]) {
	// 			newPacketData[fKey] = payloadData[fKey]
	// 			newPacketMeta[fKey] = { ...payloadMeta[fKey] }
	// 		}
	// 	})

	// 	const nP: any = newPacket
	// 	if ('deleted' in update && update.deleted) {
	// 		if ('deleted' in existing && existing.deleted) {
	// 			if (existing.deleted.at < update.deleted.at)
	// 				nP.deleted = {
	// 					...update.deleted,
	// 				}
	// 		} else {
	// 			nP.deleted = {
	// 				...update.deleted,
	// 			}
	// 		}
	// 	}
		
	// 	Object.keys(update).map((key) => {
	// 		if (key.includes('backend')) {
	// 			nP[key] = (update as any)[key]
	// 		}
	// 	})

	// 	if (existing.lastModifiedAt < update.lastModifiedAt) {
	// 		nP.lastModifiedAt = update.lastModifiedAt
	// 	}
		
	// 	return nP
	// } else {
	// 	return update
	// }
}

export const generatePacketMeta = (packet: any, label: any) => {
	const meta = new CJSCore.Meta()
	
	if (!packet?.meta?.[label]) throw new Error('Packet has no meta label')

	meta.setBy(packet.meta[label].by)
	meta.setAt(packet.meta[label].at)

	return meta
}
