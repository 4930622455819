import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { InstancedSessionData, SessionDisplayConditions } from '../slices/sessionsTypes'
import { useSessions } from './useSessions'
import { useSessionsSource } from './useSessionsSource'

export const useSessionWithID = (id?: string) => {
	const sessionsConfig = useMemo(() => {
		return {
			id,
		} as SessionDisplayConditions
	}, [id])
	useSessionsSource(sessionsConfig, 'single-<0>', id)
	const sessions: any = useSessions('single-<0>', id)
	const dispatch = useDispatch()

	return useMemo(() => {
		if (!id) return undefined
		return sessions.at[id] as InstancedSessionData
	}, [sessions, id])
}
