import { Collection } from '@root/store/commonTypes'
import { InstancedSessionData } from '@root/store/slices/sessionsTypes'
import { DateInterval } from '@root/utils/dateIntervals'
import { endOfDay, isAfter, isBefore, startOfDay } from 'date-fns'
import { useEffect, useMemo, useRef, useState } from 'react'

export const useFreeIntervals = (
	sessions: Collection<InstancedSessionData>,
	datesArray: Date[]
): { 
	freeIntervals: React.MutableRefObject<Array<[Date, Date]>>
	freeIntervalsState: DateInterval[]
 } => {
	const freeIntervals = useRef<Array<[Date, Date]>>([])
	const [freeIntervalsState, setFreeIntervalsState] = useState<DateInterval[]>([])

	useEffect(() => {
		const dates: Array<[Date, Date]> = []

		const sortedSessions = sessions.all.map((sId) => sessions.at[sId]).sort((a, b) => a.start.getTime() - b.start.getTime())

		let currentStart = startOfDay(datesArray[0])
		const overallEnd = endOfDay(datesArray[datesArray.length - 1])

		for (const s of sortedSessions) {
			if (isAfter(s.start, currentStart)) {
				dates.push([currentStart, s.start])
			}
			currentStart = s.end
		}

		if (isBefore(currentStart, overallEnd)) {
			dates.push([currentStart, overallEnd])
		}

		freeIntervals.current = dates
		setFreeIntervalsState(freeIntervals.current)
	}, [sessions, datesArray, setFreeIntervalsState])

	return useMemo(() => {
		return { freeIntervals, freeIntervalsState }
	}, [freeIntervalsState])
}

// useEffect(() => {
// 	const dates: Array<[Date, Date]> = []

// 	let start = startOfDay(datesArray[0])
// 	let end = endOfDay(datesArray[datesArray.length - 1])

// 	for (const sId of sessions.all) {
// 		const s = sessions.at[sId]

// 		if (isBefore(s.start, start) || isSameMinute(s.start, start)) {
// 			if (isAfter(s.end, end) || isSameMinute(s.end, end)) {
// 				break
// 			} else {
// 				start = s.end
// 			}
// 		}

// 		if (isAfter(s.end, end) || isSameMinute(s.end, end)) {
// 			if (isBefore(s.start, start) || isSameMinute(s.start, start)) {
// 				break
// 			} else {
// 				end = s.start
// 			}
// 		}

// 		if (isAfter(s.start, start) && isBefore(s.end, end)) {
// 			dates.push([new Date(start), new Date(s.start)])
// 			start = s.end
// 		}

// 		if (sId === sessions.all[sessions.all.length - 1]) {
// 			if (isBefore(s.end, end)) {
// 				dates.push([new Date(s.end), new Date(end)])
// 			}
// 		}
// 	}
// }, [sessions, datesArray])
