import { AuthClient } from '@root/store/slices/authClient'
import { ClientReadableStream, Metadata } from 'grpc-web'
import { END } from 'redux-saga'

export const getGRPCMeta = async () => {
    const token = await AuthClient.currentUser?.getIdToken()

    if (!token) {
        throw new Error('No token found.')
    }

    const meta: Metadata = {
        'Authorization': 'Bearer ' + token
    }

    return meta
}
export function stringTo100Hash(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = ((hash << 5) - hash) + str.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
    }
    // Modulo operation to ensure the hash is between 0 and 100
    return Math.abs(hash) % 101;
}


export const STREAM_ENDED = '_STREAM_ENDED_'
export function mountStreamListeners<T>(stream: ClientReadableStream<T>, name: string) {
    return (emitter: any) => {
        const onData = (d: any) => {
            emitter(d)
        }
        const onError = (e: any) => {
            console.log(`${name} Stream error`, e)
            // emitter({
            //     received: 'error',
            //     data: e,
            // })
            emitter(new Error(e));
        }

        const onEnd = () => {
            // emitter(STREAM_ENDED)
            // console.log('STREAM ENDED')
            emitter(END)
        }

        stream.on('data', onData)
        stream.on('error', onError)
        stream.on('end', onEnd)
        stream.on('metadata', (e) => {
        })
        stream.on('status', (e) => {
        })

        return () => {
            stream?.removeListener('data', onData)
            stream?.removeListener('error', onError)
            stream?.removeListener('end', onEnd)
            stream?.removeListener('metadata', onEnd)
            stream?.removeListener('status', onEnd)
        }
    }
}
