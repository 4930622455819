import { useRef } from 'react';
import { v4 } from 'uuid';

import { useRefTaker } from './useRefTaker';

export const useDragZone = (zoneId: string) => {
	const [container, setContainer] = useRefTaker()
	const dragZoneId = useRef(`${zoneId}${v4()}`)
	return { container, setContainer, dragZoneId: dragZoneId.current }
}

export const useDragZoneWithPreciseId = (zoneId: string) => {
	const [container, setContainer] = useRefTaker()
	const dragZoneId = useRef(`${zoneId}`)
	return { container, setContainer, dragZoneId: dragZoneId.current }
}
