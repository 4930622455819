import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { WallpaperBackground } from '@comps/activityBackground'
import { AddCardMultiButton } from '@comps/complex/addCardMultiButton'
import { BackButton } from '@comps/complex/button/backButton'
import { CardGrid } from '@comps/complex/cards/cardGrid'
import { DelayCardGrid } from '@comps/complex/cards/cardGrid.delayCardGrid'
import { WallpaperSwitcher } from '@comps/complex/wallpaperSwitcher'
import { Button } from '@comps/interactive/button'
import { BoardIcon } from '@comps/layout/boardIcon'
import { Spacer } from '@comps/layout/space'
import { Text } from '@comps/typography/text'
import dog from '@res/dog.jpg'
import { DARK_TRANSLUCENCY } from '@root/brand/css'
import { useBoardStreaming } from '@root/hooks/useBoardStreaming'
import { usePagePresence } from '@root/hooks/usePagePresence'
import { useBoard } from '@root/store/selectors/useBoards'
import { boardsActions } from '@root/store/slices/boards'
import { FlowID } from '@root/store/slices/flows.types'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Board: React.FC = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const board = useBoard(id)
	const { show } = usePagePresence()
	const [qp, setQp] = useSearchParams()
	const dispatch = useDispatch()
	useBoardStreaming(id)

	useEffect(() => {
		if (!id) return
		dispatch(boardsActions.initialize())
		// dispatch(cardsActions.initializeForBoardId({ boardId: id }))
	}, [dispatch, id])
	useEffect(() => {
		if (!id) {
			return
		}

		dispatch(boardsActions.loadToStore({ resourceId: id }))
	}, [dispatch, id])

	const handleNewBoardClick = useCallback(() => {
		if (id) {
			setQp({
				flow: 'create-or-edit-board' as FlowID,
				id: id,
			})
		}
	}, [setQp, id])

	if (!board || !id) return null

	return (
		<Holder present={show}>
			<Tool>
				<BackButton defaultBackTo="/boards" supportsHoverDragAction color="darkTranslucent" />
				<Sep />
				<BoardIcon boardColor={board.color} boardName={board.name} size={'small'} />
				<Spacer size={10} />
				<Text level="label">{board.name} board</Text>
				<Spacer size={15} />
			</Tool>
			<Tool className="middle">
				<AddCardMultiButton resourceId={id} resourceType={GrantableResourceEnum.BOARD} color="darkTranslucent" />
			</Tool>
			<Tool className="right">
				<Spacer size={5} />
				<WallpaperSwitcher buttonColor="darkTranslucent" placement="left" resourceType="board" resourceId={id} />
				<Button color="darkTranslucent" inset="buttonSmall" onClick={handleNewBoardClick}>
					Edit
				</Button>
			</Tool>
			<WallpaperBackground color={board.color} wallpaper={board.wallpaper}></WallpaperBackground>
			<Scroller className="card-grid-scroll grid-behaviour-container">
				<DelayCardGrid>
					<CardGrid resourceId={id} resourceType={GrantableResourceEnum.BOARD} />
				</DelayCardGrid>
			</Scroller>
		</Holder>
	)
}

const Sep = styled.div`
	display: block;
	width: 1px;
	height: 20px;
	background: rgba(255, 255, 255, 0.1);

	margin: 0 15px 0 5px;
`

const Scroller = styled.div`
	padding: 90px 40px 0 40px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	min-height: 100%;
`

const Tool = styled.div`
	position: absolute;
	top: 20px;
	left: 40px;
	height: 46px;
	padding: 0 7px;
	display: flex;
	align-items: center;
	${DARK_TRANSLUCENCY};
	border-radius: 16px;
	color: #fff;
	z-index: 10000;

	box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.05), 0 3px 8px 0 rgba(0, 0, 0, 0.15);

	&.right {
		left: auto;
		right: 48px;
	}
	&.middle {
		left: 50%;
		transform: translateX(-50%);
	}
`

const Holder = styled.div<{ present?: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	transform: translateY(30px);
	opacity: 0;
	will-change: transform;
	transition: ease transform 0.29s, ease opacity 0.29s;

	${(p) =>
		p.present &&
		css`
			transform: translateY(0);
			opacity: 1;
		`}
`
