import React, { useCallback } from 'react'

export type IconType =
	| 'page.today'
	| 'page.calendar'
	| 'page.activities'
	| 'page.insights'
	| 'page.settings'
	| 'page.support'
	| 'page.boards'
	| 'cards'
	| 'sessions'
	| 'insights'
	| 'routines'
	| 'cardBrain'
	| 'modal.navigation.back'
	| 'modal.navigation.close'
	| 'toast.pause'
	| 'recurrent'
	| 'more'
	| 'toast.play'
	| 'support'
	| 'expand'
	| 'arrow'
	| 'resize'
	| 'date'
	| 'grab-area'
	| 'doubleArrow'
	| 'notifications'
	| 'toast.stop'
	| 'diagonalArrow'
	| 'addCard'
	| 'palette'
	| 'activity-board-medium'
	| 'break'
	| 'check'
	| 'xmark'
	| 'apple'
	| 'session'
	| 'google'
	| 'bin'
	| 'start'
	| 'search'
	| 'stop'
	| 'activity'
	| 'profile'

export const Icon: React.FC<
	{
		type: IconType
		size?: number
		strokeWidth?: number
		className?: string
		offsetY?: number
		offsetX?: number
		rotate?: number
		marginRight?: number
		marginLeft?: number
	} & any
> = ({ type, size, strokeWidth, offsetY, marginRight, rotate, marginLeft, offsetX, className, ...otherProps }) => {
	const getRatio = useCallback(
		(width: number) => {
			const s = size || width
			return width / s
		},
		[size]
	)

	const generateSpreadableProps = useCallback(
		(width: number, height: number) => {
			const s = size || width
			const ratio = height / width
			return {
				fill: 'none',
				className: className,
				width: s,
				height: s * ratio,
				style: {
					flexShrink: 0,
					position: 'relative' as const,
					top: `${offsetY}px`,
					left: `${offsetX}px`,
					marginLeft,
					marginRight,
					transform: rotate ? `rotate(${rotate}deg)` : undefined,
				},
			}
		},
		[className, offsetY, offsetX, size, marginRight, marginLeft, rotate]
	)

	switch (type) {
		case 'start':
			return (
				<svg {...generateSpreadableProps(8, 10)} viewBox="0 0 8 10" fill="none">
					<path
						d="M0 8.30049V1.69951C0 0.93703 0.819113 0.455063 1.48564 0.825358L7.42652 4.12584C8.11233 4.50685 8.11233 5.49315 7.42652 5.87416L1.48564 9.17464C0.819112 9.54494 0 9.06297 0 8.30049Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'stop':
			return (
				<svg {...generateSpreadableProps(8, 8)} viewBox="0 0 8 8" fill="none">
					<rect width="8" height="8" rx="1" fill="currentColor" />
				</svg>
			)
		case 'palette':
			return (
				<svg {...generateSpreadableProps(16, 16)} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M7.01291e-05 7.26958C-0.0165562 10.6883 2.92584 13.0909 5.98965 11.7062C9.05347 10.3215 9.37365 13.2125 6.99069 14.2511C4.60772 15.2896 6.99069 16.2869 9.53194 15.9238C12.0732 15.5608 16 12.3592 16 7.96192C16 3.56467 12.3421 0 7.82982 0C3.31756 0 0.0166965 3.85087 7.01291e-05 7.26958ZM8.51068 3.46168C8.51068 4.22641 9.12033 4.84636 9.87237 4.84636C10.6244 4.84636 11.2341 4.22641 11.2341 3.46168C11.2341 2.69694 10.6244 2.07699 9.87237 2.07699C9.12033 2.07699 8.51068 2.69694 8.51068 3.46168ZM5.78727 4.84636C5.03523 4.84636 4.42558 4.22641 4.42558 3.46168C4.42558 2.69694 5.03523 2.07699 5.78727 2.07699C6.53932 2.07699 7.14897 2.69694 7.14897 3.46168C7.14897 4.22641 6.53932 4.84636 5.78727 4.84636ZM1.70219 6.92342C1.70219 7.68815 2.31184 8.3081 3.06389 8.3081C3.81593 8.3081 4.42558 7.68815 4.42558 6.92342C4.42558 6.15868 3.81593 5.53873 3.06389 5.53873C2.31184 5.53873 1.70219 6.15868 1.70219 6.92342ZM12.5958 8.3081C11.8437 8.3081 11.2341 7.68815 11.2341 6.92342C11.2341 6.15868 11.8437 5.53873 12.5958 5.53873C13.3478 5.53873 13.9575 6.15868 13.9575 6.92342C13.9575 7.68815 13.3478 8.3081 12.5958 8.3081Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'session':
			return (
				<svg {...generateSpreadableProps(12, 12)} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M1.03086 3H10.9691C10.9993 3.43754 11 3.99878 11 4.8V7.2C11 8.05658 10.9992 8.63887 10.9624 9.08896C10.9266 9.52738 10.8617 9.75158 10.782 9.90798C10.5903 10.2843 10.2843 10.5903 9.90798 10.782C9.75158 10.8617 9.52738 10.9266 9.08896 10.9624C8.63887 10.9992 8.05658 11 7.2 11H4.8C3.94342 11 3.36113 10.9992 2.91104 10.9624C2.47262 10.9266 2.24842 10.8617 2.09202 10.782C1.71569 10.5903 1.40973 10.2843 1.21799 9.90798C1.1383 9.75158 1.07337 9.52738 1.03755 9.08896C1.00078 8.63887 1 8.05658 1 7.2V4.8C1 3.99878 1.00068 3.43754 1.03086 3ZM0 3C0 2.21378 0.30246 1.4982 0.797352 0.963254C0.856342 0.899458 0.918083 0.838251 0.982391 0.779808C1.51521 0.295322 2.22311 0 3 0H4.8H7.2H9C9.78622 0 10.5018 0.302456 11.0367 0.79735C11.1022 0.857897 11.165 0.921339 11.2248 0.987485C11.7066 1.51972 12 2.22558 12 3H11.9712C12 3.47142 12 4.05259 12 4.8V7.2C12 8.88016 12 9.72024 11.673 10.362C11.3854 10.9265 10.9265 11.3854 10.362 11.673C9.72024 12 8.88016 12 7.2 12H4.8C3.11984 12 2.27976 12 1.63803 11.673C1.07354 11.3854 0.614601 10.9265 0.326981 10.362C0 9.72024 0 8.88016 0 7.2V4.8C0 4.05259 0 3.47142 0.0287857 3H0ZM5 6H4V10H5V6Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'page.support':
			return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM11.9484 10.3941C12.6118 9.42879 13 8.25973 13 7C13 5.71865 12.5983 4.53111 11.9141 3.55642L10.4669 5.00355C10.806 5.59109 11 6.27289 11 7C11 7.70512 10.8175 8.36763 10.4973 8.94289L11.9484 10.3941ZM10.4762 11.891L9.03157 10.4464C8.43614 10.7982 7.74164 11 7 11C6.27289 11 5.59109 10.806 5.00355 10.4669L3.55642 11.9141C4.53111 12.5983 5.71865 13 7 13C8.29567 13 9.49541 12.5893 10.4762 11.891ZM2.0743 10.4269C1.39711 9.45538 1 8.27407 1 7C1 5.70433 1.41069 4.50459 2.10899 3.52384L3.55358 4.96843C3.20184 5.56386 3 6.25836 3 7C3 7.7197 3.19007 8.39501 3.52276 8.97847L2.0743 10.4269ZM5.8028 4.24841C5.76797 4.26358 5.7335 4.27939 5.69938 4.29583C5.08478 4.59197 4.58662 5.09146 4.29216 5.70702C4.10488 6.09854 4 6.53702 4 7C4 7.44053 4.09495 7.85889 4.26551 8.23572C4.27537 8.25749 4.28548 8.27913 4.29583 8.30062C4.59802 8.92776 5.11194 9.43367 5.74485 9.72561C6.12671 9.90175 6.55188 10 7 10C7.46298 10 7.90146 9.89512 8.29298 9.70784C8.29553 9.70662 8.29808 9.70539 8.30062 9.70417C8.94679 9.39281 9.46426 8.85668 9.75159 8.1972C9.91138 7.83046 10 7.42558 10 7C10 6.55188 9.90175 6.12671 9.72561 5.74486C9.71859 5.72963 9.71144 5.71447 9.70417 5.69938C9.3989 5.06584 8.87754 4.55601 8.23572 4.26551C7.85889 4.09495 7.44054 4 7 4C6.57442 4 6.16954 4.08862 5.8028 4.24841ZM8.97847 3.52276C8.39501 3.19007 7.7197 3 7 3C6.29488 3 5.63237 3.18245 5.05711 3.50272L3.60594 2.05155C4.57121 1.38822 5.74027 1 7 1C8.27407 1 9.45538 1.39711 10.4269 2.0743L8.97847 3.52276Z" fill="currentColor" />
			</svg>
		case 'cards':
			return (
				<svg {...generateSpreadableProps(14, 14)} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M3.5 3C3.22386 3 3 3.22386 3 3.5C3 3.77614 3.22386 4 3.5 4H10.5C10.7761 4 11 3.77614 11 3.5C11 3.22386 10.7761 3 10.5 3H3.5Z" fill="currentColor" />
					<path d="M3 5.5C3 5.22386 3.22386 5 3.5 5H6.5C6.77614 5 7 5.22386 7 5.5C7 5.77614 6.77614 6 6.5 6H3.5C3.22386 6 3 5.77614 3 5.5Z" fill="currentColor" />
					<path fillRule="evenodd" clipRule="evenodd" d="M0 4.8C0 3.11984 0 2.27976 0.32698 1.63803C0.614601 1.07354 1.07354 0.614601 1.63803 0.32698C2.27976 0 3.11984 0 4.8 0H9.2C10.8802 0 11.7202 0 12.362 0.32698C12.9265 0.614601 13.3854 1.07354 13.673 1.63803C14 2.27976 14 3.11984 14 4.8V9.2C14 10.8802 14 11.7202 13.673 12.362C13.3854 12.9265 12.9265 13.3854 12.362 13.673C11.7202 14 10.8802 14 9.2 14H4.8C3.11984 14 2.27976 14 1.63803 13.673C1.07354 13.3854 0.614601 12.9265 0.32698 12.362C0 11.7202 0 10.8802 0 9.2V4.8ZM4.8 1H9.2C10.0566 1 10.6389 1.00078 11.089 1.03755C11.5274 1.07337 11.7516 1.1383 11.908 1.21799C12.2843 1.40973 12.5903 1.71569 12.782 2.09202C12.8617 2.24842 12.9266 2.47262 12.9624 2.91104C12.9992 3.36113 13 3.94342 13 4.8V9.2C13 9.4981 12.9999 9.76298 12.9982 10.0006L4.50028 12.9999C3.80416 12.9993 3.30656 12.9948 2.91104 12.9624C2.47262 12.9266 2.24842 12.8617 2.09202 12.782C1.71569 12.5903 1.40973 12.2843 1.21799 11.908C1.1383 11.7516 1.07337 11.5274 1.03755 11.089C1.00078 10.6389 1 10.0566 1 9.2V4.8C1 3.94342 1.00078 3.36113 1.03755 2.91104C1.07337 2.47262 1.1383 2.24842 1.21799 2.09202C1.40973 1.71569 1.71569 1.40973 2.09202 1.21799C2.24842 1.1383 2.47262 1.07337 2.91104 1.03755C3.36113 1.00078 3.94342 1 4.8 1Z" fill="currentColor" />
				</svg>
			)
		case 'sessions':
			return (
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M3.5 5.15887C3.22386 5.15887 3 5.38273 3 5.65887C3 5.93502 3.22386 6.15887 3.5 6.15887H10.5C10.7761 6.15887 11 5.93502 11 5.65887C11 5.38273 10.7761 5.15887 10.5 5.15887H3.5Z" fill="currentColor" />
					<path d="M3 9.65887C3 9.38273 3.22386 9.15887 3.5 9.15887H6.5C6.77614 9.15887 7 9.38273 7 9.65887C7 9.93502 6.77614 10.1589 6.5 10.1589H3.5C3.22386 10.1589 3 9.93502 3 9.65887Z" fill="currentColor" />
					<path d="M3.5 7.15887C3.22386 7.15887 3 7.38273 3 7.65887C3 7.93502 3.22386 8.15887 3.5 8.15887H8.5C8.77614 8.15887 9 7.93502 9 7.65887C9 7.38273 8.77614 7.15887 8.5 7.15887H3.5Z" fill="currentColor" />
					<path fillRule="evenodd" clipRule="evenodd" d="M0.32698 1.63803C0 2.27976 0 3.11984 0 4.8V9.2C0 10.8802 0 11.7202 0.32698 12.362C0.614601 12.9265 1.07354 13.3854 1.63803 13.673C2.27976 14 3.11984 14 4.8 14H9.2C10.8802 14 11.7202 14 12.362 13.673C12.9265 13.3854 13.3854 12.9265 13.673 12.362C14 11.7202 14 10.8802 14 9.2V4.8C14 3.11984 14 2.27976 13.673 1.63803C13.3854 1.07354 12.9265 0.614601 12.362 0.32698C11.7202 0 10.8802 0 9.2 0H4.8C3.11984 0 2.27976 0 1.63803 0.32698C1.07354 0.614601 0.614601 1.07354 0.32698 1.63803ZM1 4.8C1 4.09254 1.00053 3.57218 1.02143 3.15887H12.9786C12.9995 3.57218 13 4.09254 13 4.8V9.2C13 10.0566 12.9992 10.6389 12.9624 11.089C12.9266 11.5274 12.8617 11.7516 12.782 11.908C12.5903 12.2843 12.2843 12.5903 11.908 12.782C11.7516 12.8617 11.5274 12.9266 11.089 12.9624C10.6389 12.9992 10.0566 13 9.2 13H4.8C3.94342 13 3.36113 12.9992 2.91104 12.9624C2.47262 12.9266 2.24842 12.8617 2.09202 12.782C1.71569 12.5903 1.40973 12.2843 1.21799 11.908C1.1383 11.7516 1.07337 11.5274 1.03755 11.089C1.00078 10.6389 1 10.0566 1 9.2V4.8Z" fill="currentColor" />
				</svg>
			)
		case 'insights':
			return (
				<svg {...generateSpreadableProps(14, 14)} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" clipRule="evenodd" d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM12.6936 8.89788C11.8995 11.2815 9.65044 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1V7L12.6936 8.89788Z" fill="currentColor" />
				</svg>
			)

		case 'routines':
			return (
				<svg {...generateSpreadableProps(14, 14)} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1.73767 4.90097L1.73766 1.026L3.24402 2.47964C4.27214 1.65332 5.57833 1.15887 7 1.15887C10.3137 1.15887 13 3.84516 13 7.15887C13 10.4726 10.3137 13.1589 7 13.1589C4.1302 13.1589 1.73097 11.1441 1.13967 8.45186L2.1388 8.33372C2.66728 10.5283 4.64322 12.1589 7 12.1589C9.76143 12.1589 12 9.9203 12 7.15887C12 4.39745 9.76143 2.15887 7 2.15887C5.86115 2.15887 4.81123 2.53962 3.97058 3.18077L5.38546 4.54613L1.73767 4.90097Z" fill="currentColor" />
					<path d="M7 9.15887C8.10457 9.15887 9 8.26344 9 7.15887C9 6.05431 8.10457 5.15887 7 5.15887C5.89543 5.15887 5 6.05431 5 7.15887C5 8.26344 5.89543 9.15887 7 9.15887Z" fill="currentColor" />
				</svg>
			)
		case 'recurrent':
			return (
				<svg {...generateSpreadableProps(12, 12)} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M2.64843 0.603073L1.15726 0.765604L1.58315 4.67303L5.24277 4.92769L5.3469 3.43131L3.74863 3.32009C4.35721 2.8083 5.1426 2.5 6 2.5C7.933 2.5 9.5 4.067 9.5 6C9.5 7.933 7.933 9.5 6 9.5C4.8248 9.5 3.78488 8.92079 3.15013 8.03227L1.52106 8.2249C2.33974 9.8698 4.03788 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23857 8.76142 0.999999 6 0.999999C4.79042 0.999999 3.68116 1.42951 2.81642 2.14433L2.64843 0.603073Z" fill="currentColor" />
				</svg>
			)
		case 'more':
			return (
				<svg {...generateSpreadableProps(14, 14)} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" clipRule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8C4.55228 8 5 7.55228 5 7C5 6.44772 4.55228 6 4 6ZM6 7C6 6.44772 6.44772 6 7 6C7.55228 6 8 6.44772 8 7C8 7.55228 7.55228 8 7 8C6.44772 8 6 7.55228 6 7ZM10 6C9.44771 6 9 6.44772 9 7C9 7.55228 9.44771 8 10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6Z" fill="currentColor" />
				</svg>

			)
		case 'date':
			return (
				<svg {...generateSpreadableProps(12, 12)} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M10.9691 3H1.03086C1.00068 3.43754 1 3.99878 1 4.8V7.2C1 8.05658 1.00078 8.63887 1.03755 9.08896C1.07337 9.52738 1.1383 9.75158 1.21799 9.90798C1.40973 10.2843 1.71569 10.5903 2.09202 10.782C2.24842 10.8617 2.47262 10.9266 2.91104 10.9624C3.36113 10.9992 3.94342 11 4.8 11H7.2C8.05658 11 8.63887 10.9992 9.08896 10.9624C9.52738 10.9266 9.75158 10.8617 9.90798 10.782C10.2843 10.5903 10.5903 10.2843 10.782 9.90798C10.8617 9.75158 10.9266 9.52738 10.9624 9.08896C10.9992 8.63887 11 8.05658 11 7.2V4.8C11 3.99878 10.9993 3.43754 10.9691 3ZM4.8 0H7.2H9C10.6569 0 12 1.34315 12 3H11.9712C12 3.47142 12 4.05259 12 4.8V7.2C12 8.88016 12 9.72024 11.673 10.362C11.3854 10.9265 10.9265 11.3854 10.362 11.673C9.72024 12 8.88016 12 7.2 12H4.8C3.11984 12 2.27976 12 1.63803 11.673C1.07354 11.3854 0.614601 10.9265 0.32698 10.362C0 9.72024 0 8.88016 0 7.2V4.8C0 4.05259 -4.61853e-10 3.47142 0.0287839 3H0C0 1.34315 1.34315 0 3 0H4.8Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'expand':
			return (
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none" className={className}>
					<path
						d="M6 11H4.2C3.0799 11 2.51984 11 2.09202 10.782C1.71569 10.5903 1.40973 10.2843 1.21799 9.90798C1 9.48016 1 8.92011 1 7.8V6"
						stroke="currentColor"
						strokeWidth="1.25"
					/>
					<path d="M2 10L10 2" stroke="currentColor" strokeWidth="1.25" />
					<path
						d="M6 1H7.8C8.9201 1 9.48016 1 9.90798 1.21799C10.2843 1.40973 10.5903 1.71569 10.782 2.09202C11 2.51984 11 3.07989 11 4.2V6"
						stroke="currentColor"
						strokeWidth="1.25"
					/>
				</svg>
			)
		case 'cardBrain':
			return (
				<svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="1.34615" cy="1.34615" r="1.34615" fill="#C4C4C4" />
					<circle cx="5.65377" cy="1.34615" r="1.34615" fill="#C4C4C4" />
					<circle cx="5.65377" cy="5.65377" r="1.34615" fill="#C4C4C4" />
					<circle cx="1.34615" cy="5.65377" r="1.34615" fill="#C4C4C4" />
				</svg>
			)
		case 'apple':
			return (
				<svg
					width="14"
					height="16"
					viewBox="0 0 16 18"
					fill="none"
					className={className}
					style={{ top: -1, position: 'relative' }}
				>
					<path
						d="M12.6236 17.2751C11.6487 18.2227 10.5731 18.075 9.5479 17.6282C8.45791 17.1725 7.46146 17.1436 6.31032 17.6282C4.87679 18.2479 4.11596 18.0677 3.25261 17.2751C-1.62174 12.2493 -0.902277 4.5935 4.63757 4.30528C5.98117 4.37733 6.92186 5.04924 7.71327 5.10508C8.88959 4.8655 10.0155 4.17918 11.2746 4.26925C12.7873 4.39174 13.9186 4.9898 14.6741 6.06522C11.5624 7.93864 12.2998 12.0458 15.1579 13.1986C14.5859 14.7028 13.8521 16.1889 12.6218 17.2878L12.6236 17.2751ZM7.60535 4.25123C7.45966 2.01754 9.26731 0.180137 11.3465 0C11.6325 2.57596 9.0083 4.50343 7.60535 4.25123Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'session-arrow':
			return (
				<svg {...generateSpreadableProps(7, 8)} viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M3 1L6 4L3 7" stroke="currentColor" strokeWidth="1" />
					<path d="M0 4L6 4" stroke="currentColor" strokeWidth="1" />
				</svg>
			)
		case 'arrow':
			return (
				<svg {...generateSpreadableProps(8, 12)} viewBox="0 0 8 12" {...otherProps}>
					<path
						d="M1 1L6 6L1 11"
						stroke="currentColor"
						strokeWidth={(strokeWidth || 1.5) * (8 / (size || 8))}
						strokeLinecap="round"
					/>
				</svg>
			)
		case 'doubleArrow':
			return (
				<svg {...generateSpreadableProps(11, 8)} viewBox="0 0 11 8">
					<path d="M1 1L4 4L1 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
					<path d="M6 1L9 4L6 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
				</svg>
			)
		case 'diagonalArrow':
			return (
				<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M5.99985 0V5H4.74985V2.13379L0.94736 5.93628L0.0634766 5.0524L3.86587 1.25H0.99985V0H5.99985Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'banner':
			return (
				<svg {...generateSpreadableProps(11, 8)} viewBox="0 0 180 287" fill="none">
					<path
						d="M0.260742 0.733154V270.747C0.472168 279.31 7.47961 286.186 16.093 286.186C20.8446 286.186 25.1074 284.094 28.0103 280.78L78.7572 227.484L78.792 227.485C81.6587 224.614 85.6219 222.837 90.0001 222.837C94.3782 222.837 98.3414 224.614 101.208 227.485L101.243 227.484L151.99 280.781C154.893 284.094 159.156 286.186 163.907 286.186C172.52 286.186 179.528 279.31 179.739 270.747V0.733154H0.260742Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'google':
			return (
				<svg width="15" height="15" viewBox="0 0 18 18" fill="none" className={className}>
					<path
						d="M17.6175 9.2025C17.6175 8.61 17.565 8.0475 17.475 7.5H9V10.8825H13.8525C13.635 11.9925 12.9975 12.93 12.0525 13.5675V15.8175H14.9475C16.6425 14.25 17.6175 11.94 17.6175 9.2025Z"
						fill="currentColor"
					/>
					<path
						d="M9.00012 18C11.4301 18 13.4626 17.19 14.9476 15.8175L12.0526 13.5675C11.2426 14.1075 10.2151 14.4375 9.00012 14.4375C6.65262 14.4375 4.66512 12.855 3.95262 10.7175H0.967621V13.035C2.44512 15.975 5.48262 18 9.00012 18Z"
						fill="currentColor"
					/>
					<path
						d="M3.9525 10.7175C3.765 10.1775 3.6675 9.59996 3.6675 8.99996C3.6675 8.39996 3.7725 7.82247 3.9525 7.28247V4.96497H0.967499C0.352499 6.17997 0 7.54496 0 8.99996C0 10.455 0.352499 11.82 0.967499 13.035L3.9525 10.7175Z"
						fill="currentColor"
					/>
					<path
						d="M9.00012 3.5625C10.3276 3.5625 11.5126 4.02 12.4501 4.9125L15.0151 2.3475C13.4626 0.892501 11.4301 0 9.00012 0C5.48262 0 2.44512 2.025 0.967621 4.965L3.95262 7.2825C4.66512 5.145 6.65262 3.5625 9.00012 3.5625Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'addCard':
			return (
				<svg {...generateSpreadableProps(16, 16)} className={className}>
					<rect x="0.625" y="0.625" width="14.75" height="14.75" rx="4.375" stroke="currentColor" strokeWidth="1.25" />
					<path d="M11 8H5" stroke="currentColor" strokeWidth="1.25" />
					<path d="M8 5L8 11" stroke="currentColor" strokeWidth="1.25" />
				</svg>
			)
		case 'activity':
			return (
				<svg {...generateSpreadableProps(12, 12)} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9ZM6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10ZM7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6ZM8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4C7.10457 4 8 4.89543 8 6Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'activity-board-medium':
			return (
				<svg {...generateSpreadableProps(18, 15)} viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M0 3V12C0 13.6569 1.34314 15 3 15H15C16.6569 15 18 13.6569 18 12V3C18 1.34315 16.6569 0 15 0H3C1.34314 0 0 1.34315 0 3ZM15 1H6V14H15C16.1046 14 17 13.1046 17 12V3C17 1.89543 16.1046 1 15 1ZM3 6C3.55231 6 4 5.55228 4 5C4 4.44772 3.55231 4 3 4C2.44769 4 2 4.44772 2 5C2 5.55228 2.44769 6 3 6ZM1 8V9H5V8H1ZM1 11V10H5V11H1Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'resize':
			return (
				<svg {...generateSpreadableProps(8, 8)} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M6.5 5.7929L2.20711 1.5H4V0.5H0.5V4H1.5V2.20711L5.79289 6.5H4V7.5H7.5V4H6.5V5.7929Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'plus':
			return (
				<svg {...generateSpreadableProps(9, 9)} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
					<line x1="0.5" y1="4.25" x2="9" y2="4.25" stroke="currentColor" strokeWidth="1.5" />
					<line x1="4.75" y1="-2.54984e-08" x2="4.75" y2="8.5" stroke="currentColor" strokeWidth="1.5" />
				</svg>
			)
		case 'check':
			return (
				<svg {...generateSpreadableProps(9, 8)} viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 3.69231L3.33333 6L8 1" stroke="white" strokeWidth="1.5" />
				</svg>
			)
		case 'xmark':
			return (
				<svg {...generateSpreadableProps(10, 10)} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1.5 8.5L5 5L8.5 1.5" stroke="white" strokeWidth={1.5 * getRatio(10)} />
					<path d="M8.5 8.5L5 5L1.5 1.5" stroke="white" strokeWidth={1.5 * getRatio(10)} />
				</svg>
			)
		case 'page.today':
			return (
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none">
					<circle cx="7" cy="7" r="6.5" stroke="currentColor" fill="transparent" />
					<path d="M7 4V6.90909L4 9" stroke="currentColor" fill="transparent" />
				</svg>
			)
		case 'page.calendar':
			return (
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" className={className}>
					<rect x="0.5" y="0.5" width="13" height="13" rx="2.5" stroke="currentColor" />
					<path d="M0 3C0 1.34315 1.34315 0 3 0H11C12.6569 0 14 1.34315 14 3H0Z" fill="currentColor" />
					<rect x="7" y="6" width="3" height="2" fill="currentColor" />
				</svg>
			)
		case 'page.activities':
			return (
				<svg width="11" height="13" viewBox="0 0 11 13" fill="none" className={className}>
					<circle cx="9.5" cy="1.5" r="1.5" fill="currentColor" />
					<circle cx="3.5" cy="5" r="3" stroke="currentColor" />
					<circle cx="8.5" cy="10.5" r="2.5" fill="currentColor" />
				</svg>
			)
		case 'notifications':
			return (
				<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M4 7.5V8.5H9V7.5H4Z" fill="currentColor" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M10.3312 3.49639C10.2381 1.54023 8.55038 0 6.49999 0C4.44962 0 2.76193 1.54023 2.66882 3.49639L2.63854 4.13261C2.59618 5.0225 2.26744 5.87823 1.69712 6.58311L0.381902 8.20862C-0.539338 9.34723 0.310436 11 1.81711 11H11.1829C12.6896 11 13.5393 9.34723 12.6181 8.20862L11.3029 6.58311C10.7325 5.87823 10.4038 5.02249 10.3615 4.13261L10.3312 3.49639ZM9.36259 4.18015L9.33229 3.54397C9.26647 2.1608 8.05734 1 6.49999 1C4.94267 1 3.73353 2.16076 3.66769 3.54393L3.63741 4.18015C3.58475 5.28649 3.17608 6.34504 2.47453 7.21211L1.15931 8.83762C0.966853 9.07548 0.959658 9.34314 1.07837 9.57403C1.19943 9.80947 1.45308 10 1.81711 10H11.1829C11.547 10 11.8006 9.80946 11.9216 9.57404C12.0403 9.34316 12.0331 9.07549 11.8407 8.83761L10.5255 7.21212C9.82388 6.34503 9.41525 5.28645 9.36259 4.18015Z"
						fill="currentColor"
					/>
					<path d="M5 12V12.5C5 13.3284 5.67157 14 6.5 14C7.32843 14 8 13.3284 8 12.5V12H5Z" fill="currentColor" />
				</svg>
			)
		case 'page.insights':
			return (
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" className={className}>
					<circle cx="7" cy="7" r="6.5" stroke="currentColor" />
					<path d="M7 7V1H8.875L10.75 2L13.5 5L13 9L7 7Z" fill="currentColor" />
				</svg>
			)
		case 'modal.navigation.back':
			return (
				<svg width="8" height="8" viewBox="0 0 7 7" fill="none" className={className}>
					<path d="M3.5 1L1 3.5M1 3.5L3.5 6M1 3.5H6.5" stroke="currentColor" />
				</svg>
			)
		case 'modal.navigation.close':
			return (
				<svg width="8" height="8" viewBox="0 0 7 7" fill="none" className={className}>
					<path d="M1 1L6 6M6 1L1 6" stroke="currentColor" />
				</svg>
			)
		case 'toast.pause':
			return (
				<svg width="6" height="8" viewBox="0 0 6 8" fill="none" className={className}>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M1 0C0.447723 0 0 0.447715 0 1V7C0 7.55228 0.447723 8 1 8C1.55228 8 2 7.55228 2 7V1C2 0.447715 1.55228 0 1 0ZM5 0C4.44772 0 4 0.447715 4 1V7C4 7.55228 4.44772 8 5 8C5.55228 8 6 7.55228 6 7V1C6 0.447715 5.55228 0 5 0Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'page.settings':
			return (
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" className={className}>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M1.28306 2.50953L3.61235 3.32249C4.10263 2.87062 4.68349 2.51555 5.3246 2.2876L5.71867 0.103083C6.13496 0.0353187 6.56309 0 7.00001 0C7.43692 0 7.86506 0.0353195 8.28135 0.103085L8.67542 2.28761C9.31653 2.51556 9.89738 2.87063 10.3877 3.32249L12.717 2.50954C13.2642 3.12359 13.7013 3.82711 14 4.59336L11.9361 6.1982C11.9781 6.45926 12 6.72707 12 7C12 7.27291 11.9781 7.54071 11.9361 7.80175L14 9.4066C13.7014 10.1728 13.2642 10.8764 12.717 11.4904L10.3877 10.6775C9.89741 11.1293 9.31654 11.4844 8.67541 11.7124L8.28134 13.8969C7.86505 13.9647 7.43692 14 7.00001 14C6.5631 14 6.13497 13.9647 5.71868 13.8969L5.32461 11.7124C4.68348 11.4844 4.1026 11.1294 3.61231 10.6775L1.28302 11.4904C0.735816 10.8764 0.298647 10.1728 0 9.4066L2.06394 7.80177C2.02187 7.54072 2 7.27291 2 7C2 6.72707 2.02187 6.45924 2.06395 6.19818L1.65733e-05 4.59335C0.29867 3.82711 0.735847 3.12358 1.28306 2.50953ZM7 10C8.65685 10 10 8.65685 10 7C10 5.34315 8.65685 4 7 4C5.34315 4 4 5.34315 4 7C4 8.65685 5.34315 10 7 10Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'page.boards':
			return (
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" className={className}>
					<path
						d="M4.8 0.5H9.2C10.0483 0.5 10.6545 0.500389 11.1297 0.539212C11.599 0.577558 11.8963 0.650894 12.135 0.772484C12.6054 1.01217 12.9878 1.39462 13.2275 1.86502C13.3491 2.10366 13.4224 2.40099 13.4608 2.87032C13.4996 3.34549 13.5 3.95167 13.5 4.8V9.2C13.5 10.0483 13.4996 10.6545 13.4608 11.1297C13.4224 11.599 13.3491 11.8963 13.2275 12.135C12.9878 12.6054 12.6054 12.9878 12.135 13.2275C11.8963 13.3491 11.599 13.4224 11.1297 13.4608C10.6545 13.4996 10.0483 13.5 9.2 13.5H4.8C3.95167 13.5 3.34549 13.4996 2.87032 13.4608C2.40099 13.4224 2.10366 13.3491 1.86502 13.2275C1.39462 12.9878 1.01217 12.6054 0.772484 12.135C0.650894 11.8963 0.577558 11.599 0.539212 11.1297C0.500389 10.6545 0.5 10.0483 0.5 9.2V4.8C0.5 3.95167 0.500389 3.34549 0.539212 2.87032C0.577558 2.40099 0.650894 2.10366 0.772484 1.86502C1.01217 1.39462 1.39462 1.01217 1.86502 0.772484C2.10366 0.650894 2.40099 0.577558 2.87032 0.539212C3.34549 0.500389 3.95167 0.5 4.8 0.5Z"
						fill="transparent"
						stroke="currentColor"
					/>
					<path d="M7 5V1H10.5V5L8.77778 3.84615L7 5Z" fill="currentColor" />
					<rect x="1" y="1" width="1" height="12" fill="currentColor" />
					<rect x="2" y="1" width="1" height="12" fill="currentColor" />
				</svg>
			)
		case 'toast.stop':
			return (
				<svg width="6" height="6" viewBox="0 0 6 6" fill="none" className={className}>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M1 0C0.447754 0 0 0.447716 0 1V5C0 5.55228 0.447754 6 1 6H5C5.55225 6 6 5.55228 6 5V1C6 0.447716 5.55225 0 5 0H1Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'toast.play':
			return (
				<svg width="6" height="8" viewBox="0 0 6 8" fill="none" className={className}>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M1.49609 0.854935C0.829468 0.473992 0 0.955361 0 1.72318V6.27682C0 7.04464 0.829468 7.52601 1.49609 7.14507L5.48059 4.86824C6.15234 4.48435 6.15234 3.51565 5.48059 3.13176L1.49609 0.854935Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'grab-area':
			return (
				<svg {...generateSpreadableProps(27, 6)} viewBox="0 0 27 6" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="0.5" y="0.5" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="4" y="0.5" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="7.5" y="0.5" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="11" y="0.5" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="14.5" y="0.5" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="18" y="0.5" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="21.5" y="0.5" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="25" y="0.5" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="0.5" y="4" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="4" y="4" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="7.5" y="4" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="11" y="4" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="14.5" y="4" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="18" y="4" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="21.5" y="4" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="25" y="4" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
				</svg>
			)
		case 'grab-area-y':
			return (
				<svg {...generateSpreadableProps(6, 9)} viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="0.5" y="0.5" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="4" y="0.5" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="0.5" y="4" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="4" y="4" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="0.5" y="7.5" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
					<rect x="4" y="7.5" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
				</svg>
			)
		case 'profile':
			return (
				<svg {...generateSpreadableProps(12, 14)} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="5.99268" cy="3" r="2.5" stroke="currentColor" />
					<path
						d="M6.0001 13.5C3.95153 13.5 2.06176 13.0547 0.516241 12.3048C0.644598 10.4122 1.29094 8.73609 2.24442 7.52324C3.25039 6.24361 4.57731 5.5 6.00011 5.5C7.42292 5.5 8.74984 6.24361 9.75581 7.52324C10.7093 8.73609 11.3556 10.4122 11.484 12.3048C9.93846 13.0547 8.04868 13.5 6.0001 13.5Z"
						fill="transparent"
						stroke="currentColor"
					/>
				</svg>
			)
		case 'break':
			return (
				<svg width="22" height="16" viewBox="0 0 22 16" className={className}>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.8407 11.5H9.82827V9.19891H11.1706C12.6887 9.19891 13.4398 8.28165 13.4398 7.07355C13.4398 5.86865 12.6951 4.95459 11.1738 4.95459H8.8407V11.5ZM9.82827 8.36155V5.80154H11.0683C12.0463 5.80154 12.4458 6.33208 12.4458 7.07355C12.4458 7.81503 12.0463 8.36155 11.0811 8.36155H9.82827Z"
						fill="currentColor"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M19 8C19 12.4183 15.4183 16 11 16C6.58172 16 3 12.4183 3 8C3 3.58172 6.58172 0 11 0C15.4183 0 19 3.58172 19 8ZM18 8C18 11.866 14.866 15 11 15C7.13401 15 4 11.866 4 8C4 4.13401 7.13401 1 11 1C14.866 1 18 4.13401 18 8Z"
						fill="currentColor"
					/>
					<path
						d="M18.1414 15H19.4857C21.0564 13.0981 22 10.6593 22 8C22 5.34074 21.0564 2.90188 19.4857 1H18.1414C19.9097 2.80375 21 5.27455 21 8C21 10.7255 19.9097 13.1962 18.1414 15Z"
						fill="currentColor"
					/>
					<path
						d="M2.51432 15H3.85857C2.09032 13.1962 1 10.7255 1 8C1 5.27455 2.09032 2.80375 3.85857 1H2.51432C0.943634 2.90188 0 5.34074 0 8C0 10.6593 0.943634 13.0981 2.51432 15Z"
						fill="currentColor"
					/>
				</svg>
			)
		case 'bin':
			return <svg {...generateSpreadableProps(14, 14)} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M11 3C11 1.34315 9.65685 0 8 0H6C4.34315 0 3 1.34315 3 3H0V5H1.12934L2.01999 11.4127C2.22599 12.8959 3.49408 14 4.99147 14H9.00863C10.506 14 11.7741 12.8959 11.9801 11.4127L12.8708 5H14V3H11ZM8 2H6C5.44772 2 5 2.44772 5 3H9C9 2.44772 8.55228 2 8 2ZM10.8516 5H9L8 12H9.00863C9.50776 12 9.93046 11.632 9.99912 11.1376L10.8516 5ZM6 12H4.99147C4.49234 12 4.06964 11.632 4.00098 11.1376L3.14854 5H5L6 12Z" fill="currentColor" />
			</svg>
		case 'search':
			return (
				<svg {...generateSpreadableProps(14, 14)} viewBox="0 0 14 14" fill="none">
					<circle cx="6.5" cy="6.5" r="5.875" stroke="currentColor" strokeWidth="1.25" />
					<line x1="10.4419" y1="10.5581" x2="13.4419" y2="13.5581" stroke="currentColor" strokeWidth="1.25" />
				</svg>
			)
		default:
			return <></>
	}
}
