import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { SendableStream, StreamCardRequest } from '@clepside/clepsidejs/lib/services/sync_v1_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Logger } from '@root/cores/logger'
import { Database } from '@root/database'
import { SyncGRPC } from '@root/grpc/grpcSync'
import { STREAM_ENDED } from '@root/grpc/utils'
import { call, takeEvery } from 'redux-saga/effects'
import { cardsActions } from './cards'
import { SyncAICardChats } from './sync.aiCardChats'
import { SyncAICardChatsMessages } from './sync.aiCardChatsMessages'
import { SyncFragmentsCompletable } from './sync.fragmentsCompletable'
import { SyncFragmentsPlainText } from './sync.fragmentsPlainText'
import { SyncFragmentsUploadable } from './sync.fragmentsUploadable'
import { processResourceComprehensiveStream } from './sync.tsx.handlers'
import { CardPacketObject, PacketObject } from './sync.tsx.packets.types'
import { getLastSyncedAt, getPacketObjectFromRemoteObject, ResourceComprehensiveStreamingConfig } from './sync.types'
import { markSyncingAsStarted } from './sync.utils'

export class SyncCards {
    public static runeOrPacket = 'packet' as const

    public static type = PacketType.CARD as const

    static fromSendables(res: Sendable.AsObject[] | undefined): PacketObject<any>[] {
        return res?.map((r) => getPacketObjectFromRemoteObject(r?.packet?.card))
            .filter((f): f is PacketObject<any> => f !== undefined) || []
    }

    static placeInStore(objects: CardPacketObject[], fromSync?: boolean) {
        return cardsActions.normalizeAndStore({ objects, fromSync })
    }

    static fromPacket(res: Packet.AsObject | undefined) {
        return getPacketObjectFromRemoteObject(res?.card)
    }

    static database = Database.cardPackets
}


const syncCardsSlice = createSlice({
    name: 'sync.cards',
    initialState: {},
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        streamCard: (_, {}: PayloadAction<{ cardId: string }>) => {},
    },
})


export const syncCardsActions = syncCardsSlice.actions

export const syncCardsSagas = {
	*streamCard({ payload }: ReturnType<typeof syncCardsSlice.actions.streamCard>): any {
        const request = new StreamCardRequest()
		request.setCardId(payload.cardId)

        const streamBoardResources = [
            new ResourceComprehensiveStreamingConfig(
                SyncFragmentsPlainText, 
                (num: number) => request.setTextFragmentsLastFetchedAt(num),
                'Cards/<0>/TextFragments',
                payload.cardId),
            new ResourceComprehensiveStreamingConfig(
                SyncFragmentsCompletable, 
                (num: number) => request.setCompletableFragmentsLastFetchedAt(num),
                'Cards/<0>/CompletableFragments',
                payload.cardId),
            new ResourceComprehensiveStreamingConfig(
                SyncFragmentsUploadable, 
                (num: number) => request.setUploadableFragmentsLastFetchedAt(num),
                'Cards/<0>/UploadableFragments',
                payload.cardId),
            new ResourceComprehensiveStreamingConfig(
                SyncAICardChats, 
                (num: number) => request.setAiCardChatsLastFetchedAt(num),
                'Cards/<0>/AIChats',
                payload.cardId),
            new ResourceComprehensiveStreamingConfig(
                SyncAICardChatsMessages, 
                (num: number) => request.setAiCardChatsMessagesLastFetchedAt(num),
                'Cards/<0>/AIChatsMessages',
                payload.cardId),
        ]

        for (const resource of streamBoardResources) {
            const lastSyncedAt = yield getLastSyncedAt(resource.lmat_key)
            yield markSyncingAsStarted(resource.lmat_key)
            resource.prepareRequest(lastSyncedAt)
        }
		
        try {
            const channel = yield SyncGRPC.constructCardStreamingChannel(request)
            const receiver = function* (response: SendableStream) {
                yield call(processResourceComprehensiveStream, response, 'Cards', streamBoardResources)
            }
			Logger.sync.debug('[Boards] Starting...')
			yield takeEvery(channel, receiver)
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        }
	},
}