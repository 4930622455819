import React from 'react';
import styled from 'styled-components';

import { Icon, IconType } from '@comps/static/icon';

export const TextInputIconWrapper: React.FC<{
	children?: React.ReactNode
	icon: IconType
	bounds?: number
	offset?: number
}> = ({ children, icon, offset = 2, bounds = 40 }) => {
	return (
		<Holder>
			<IconHolder bounds={bounds} offset={offset}>
				<Icon type={icon} />
			</IconHolder>
			{children}
		</Holder>
	)
}

const Holder = styled.div`
	position: relative;
`

const IconHolder = styled.div<{
	bounds: number
	offset: number
}>`
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	left: ${(p) => p.offset}px;
	height: ${(p) => p.bounds}px;
	width: ${(p) => p.bounds}px;

	&,
	* {
		${(p) => p.theme.text.subtle.r.css('color')};
	}
`
