/* eslint-disable @typescript-eslint/no-unused-vars */
import { BoyServiceClient, BoyServicePromiseClient } from '@clepside/clepsidejs/lib/services/boy_grpc_web_pb'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { State } from './auth.types'
import { WatcherID } from './watchersTypes'
import { call, put } from 'typed-redux-saga'
import { watcherActions } from './watchers'
import { BoyGPRC } from '@root/grpc/grpcBoy'
import { AuthClient } from './authClient'
import { BoyUserPlainRequest } from '@clepside/clepsidejs/lib/services/boy_pb'
import { userInvitesActions } from './userInvites'

const boySlice = createSlice({
	name: 'boy',
	initialState: State,
	reducers: {
		activateUser: (state, { payload }: PayloadAction<{ watcherId: WatcherID, index: number }>) => {},
		deleteUser: (state, { payload }: PayloadAction<{ watcherId: WatcherID, index: number }>) => {},
		inviteUser: (state, { payload }: PayloadAction<{ watcherId: WatcherID, index: number }>) => {},
		grantInvites: (state, { payload }: PayloadAction<{ watcherId: WatcherID, index: number }>) => {},
	},
})

export const boySagas = {
	*activateUser({ payload }: ReturnType<typeof boySlice.actions.activateUser>) {
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))

		try {
			const request = new BoyGPRC.activateUser.request()
			request.setId(payload.index)
			const final = yield* call(BoyGPRC.activateUser.call, request)
			if (!final.getSuccess()) {
				throw new Error()
			}

			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
		} catch (e) { 
			console.error(e)
			yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
		}
	},
	*deleteUser({ payload }: ReturnType<typeof boySlice.actions.deleteUser>) {
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))

		try {
			const request = new BoyGPRC.deleteUser.request()
			request.setId(payload.index)
			const final = yield* call(BoyGPRC.deleteUser.call, request)
			if (!final.getSuccess()) {
				throw new Error()
			}

			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
		} catch (e) { 
			console.error(e)
			yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
		}
	},
	*inviteUser({ payload }: ReturnType<typeof boySlice.actions.inviteUser>) {
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))

		try {
			const request = new BoyGPRC.inviteUser.request()
			request.setId(payload.index)
			const final = yield* call(BoyGPRC.inviteUser.call, request)
			if (!final.getSuccess()) {
				throw new Error()
			}

			yield put(watcherActions.done({ watchers: [payload.watcherId] }))

			yield AuthClient.currentUser?.getIdTokenResult(true)
		} catch (e) { 
			console.error(e)
			yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
		}
	},
	*grantInvites({ payload }: ReturnType<typeof boySlice.actions.grantInvites>): any {
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))

		try {
			const request = new BoyUserPlainRequest()
			request.setId(payload.index)
			yield BoyGPRC.grantInvites(request)

			yield put(userInvitesActions.fetchPastInvites())
			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
		} catch (e) { 
			console.error(e)
			yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
		}
	},
}

function streamToPromise(stream: any) {
	return new Promise((resolve, reject) => {
		let data = '';
	
		stream.on('data', (chunk: any) => {
			data += chunk;
		});
	
		stream.on('end', () => resolve(data));
	
		stream.on('error', (error: any) => reject(error));
	});
}

export const boyActions = boySlice.actions
export const boyReducers = boySlice.reducer