import { GenericJobReponse } from '@clepside/clepsidejs/lib/commons/commons_pb';
import { BoyServiceClient } from '@clepside/clepsidejs/lib/services/boy_grpc_web_pb';
import { BoyUserPlainRequest } from '@clepside/clepsidejs/lib/services/boy_pb';

if (!process.env.REACT_APP_API_URL)
    throw new Error('No RAPURL')
const BoyClient = new BoyServiceClient(process.env.REACT_APP_API_URL)

export const BoyGPRC = {
    activateUser: {
        request: BoyUserPlainRequest,
        call: (request: BoyUserPlainRequest) => new Promise<GenericJobReponse>((resolve, reject) => 
            BoyClient.activateUser(request, undefined, (err, suc) => 
                err ? reject(err) : resolve(suc)
            )
        )
    },
    deleteUser: {
        request: BoyUserPlainRequest,
        call: (request: BoyUserPlainRequest) => new Promise<GenericJobReponse>((resolve, reject) => 
            BoyClient.deleteUser(request, undefined, (err, suc) => 
                err ? reject(err) : resolve(suc)
            )
        )
    },
    inviteUser: {
        request: BoyUserPlainRequest,
        call: (request: BoyUserPlainRequest) => new Promise<GenericJobReponse>((resolve, reject) => 
            BoyClient.inviteUser(request, undefined, (err, suc) => 
                err ? reject(err) : resolve(suc)
            )
        )
    },
    grantInvites: (request: BoyUserPlainRequest) => new Promise<GenericJobReponse>((res, rej) => {
            (async () => {
                BoyClient.grantInvites(request, undefined, (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    )
}