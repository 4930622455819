import styled from 'styled-components';

import { Spacer } from '@comps/layout/space';
import { Text } from '@comps/typography/text';

export const SelectedSessionEmpty = () => {
	return (
		<Holder data-session-viewbox>
			<Instructions>
				<svg width="160" height="77" viewBox="0 0 160 77" fill="none">
					<line y1="0.5" x2="160" y2="0.5" stroke="#F2F2F2" strokeDasharray="3 3" />
					<line y1="19.5" x2="160" y2="19.5" stroke="#F2F2F2" strokeDasharray="3 3" />
					<line y1="38.5" x2="160" y2="38.5" stroke="#F2F2F2" strokeDasharray="3 3" />
					<line y1="57.5" x2="160" y2="57.5" stroke="#F2F2F2" strokeDasharray="3 3" />
					<line y1="76.5" x2="160" y2="76.5" stroke="#F2F2F2" strokeDasharray="3 3" />
					<rect x="52.5" y="15.5" width="55" height="46" rx="6.5" fill="white" stroke="rgba(0,10,20,0.1)" />
					<path d="M88.75 49L82 34L97 39.25L90.25 41.5L88.75 49Z" fill="currentColor" />
					<path d="M67.5 32L71.5 28M71.5 28L75.5 32M71.5 28V49M71.5 49L67.5 45M71.5 49L75.5 45" stroke="currentColor" />
				</svg>
				<Spacer size={20} vertical />
				<Text color="subtle" level="body">
					No session selected.
				</Text>
				<Text color="subtle" level="body">
					You can create a session by dragging on the timeline.
				</Text>
			</Instructions>
		</Holder>
	)
}

const Holder = styled.div`
	flex-grow: 1;
	position: sticky;
	top: 20px;
	left: 0;
	height: calc(100vh - 40px);
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 20px 20px 20px;
	/* box-shadow: 0 5px 50px rgba(10, 25, 40, 0.1); */
	border-radius: 20px;
	box-sizing: border-box;
	border: 1px solid rgba(0, 0, 0, 0.05);
	/* ${(p) => p.theme.backgrounds.subtle.r.css('background-color')}; */
	z-index: 500;
`

const Instructions = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
	border-radius: 20px;
`
