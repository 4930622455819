import CJSCore from '@clepside/clepsidejs/lib/commons/core_pb';
import {
    CardLayout, CardPacket, CardPacketData, CardPacketMeta, CardVerticalLayout
} from '@clepside/clepsidejs/lib/packets_v1/cards_pb';
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb';

import { generatePacketMeta } from './persist.utils';
import { CardPacketObject } from './sync.tsx.packets.types';

export function constructCardPacket(readyToSendPacket: Packet, cardPacket: CardPacketObject) {
    const tbsCardPacket = new CardPacket()
    const { data, deleted, type, id, meta } = cardPacket
    if (!data) throw new Error('Packet data is undefined')
    
    const { resourceId, resourceType, cardType, layout, verticallayout  } = data
			
			
    tbsCardPacket.setId(id)

    if (!type) {
        throw new Error('packet type not found')
    }

    if (deleted) {
        const deletedMeta = new CJSCore.DeleteMeta()
        deletedMeta.setAt(deleted.at)
        deletedMeta.setBy(deleted.by)
        deletedMeta.setIs(deleted.is)
        tbsCardPacket.setDeleted(deletedMeta)
    }

    if (data) {
        const newData = new CardPacketData()
        const newMeta = new CardPacketMeta()

        if (resourceId && meta.resourceId) { 
            newData.setResourceId(resourceId)
            newMeta.setResourceId(generatePacketMeta(cardPacket, 'resourceId'))
        }
        if (resourceType && meta.resourceType) {
            newData.setResourceType(resourceType)
            newMeta.setResourceType(generatePacketMeta(cardPacket, 'resourceType'))
        }
        if (cardType) { 
            newData.setCardType(cardType) 
        }

        if (layout && meta.layout) { 
            const newLayout = new CardLayout()
            newLayout.setH(layout.h)
            newLayout.setW(layout.w)
            newLayout.setX(layout.x)
            newLayout.setY(layout.y)
            newData.setLayout(newLayout) 
            newMeta.setLayout(generatePacketMeta(cardPacket, 'layout'))
        }

        if (verticallayout && meta.verticallayout) { 
            const newLayout = new CardVerticalLayout()
            newLayout.setOrder(verticallayout.order)
            newData.setVerticallayout(newLayout)
            newMeta.setVerticallayout(generatePacketMeta(cardPacket, 'verticallayout'))
        }

        tbsCardPacket.setData(newData)
        tbsCardPacket.setMeta(newMeta)
        tbsCardPacket.setType(type)
    }

    readyToSendPacket.setCard(tbsCardPacket)
}