import { Database, SyncBoardStatus } from '@root/database'
import { parseTemplate } from '@root/utils/general'
import { put } from 'typed-redux-saga'
import { watcherActions } from './watchers'

const localSyncKey = [
    'Notifications' as const,
    'Boards' as const,
    'Boards/<0>/Cards' as const,
    'Boards/<0>/Invitations' as const,
    'Boards/<0>/Sessions' as const,
    'Activities' as const,
    'Activities/<0>/Cards' as const,
    'Activities/<0>/Invitations' as const,
    'Activities/<0>/Sessions' as const,
    'Activities/<0>/Routines' as const,
    'Routines/<0>/Cards' as const,
    'Routines/<0>/Schedules' as const,
    'Sessions/<0>/Invitations' as const,
    'Sessions/<0>/Cards' as const,
    'Cards/<0>/TextFragments' as const,
    'Cards/<0>/CompletableFragments' as const,
    'Cards/<0>/UploadableFragments' as const,
    'Cards/<0>/AIChats' as const,
    'Cards/<0>/AIChatsMessages' as const,
    'PartialActivities' as const,
    'IndividualSessions' as const,
]

export type SyncKeyTemplate = typeof localSyncKey[0]
export type SyncKey = string

export function reachSyncKeyTemplate<K extends SyncKeyTemplate>(k: K): K { return k }
export function getSyncKeyFromTemplate(key: SyncKeyTemplate, ...args: string[]) {
    return parseTemplate<SyncKeyTemplate, SyncKey>(key, ...args)
}

export function extractParams(template: SyncKeyTemplate, value: string): string[] | null {
    const paramRegex = /<(\d+)>/g;  // Regular expression to match placeholders like <0>, <1>, etc.
    let match;
    const params: string[] = [];

    while ((match = paramRegex.exec(template)) !== null) {
        const index = parseInt(match[1]);
        const start = match.index;
        const end = paramRegex.lastIndex;
        
        const prefix = template.substring(0, start);
        const suffix = template.substring(end);

        const prefixIndex = value.indexOf(prefix);
        const suffixIndex = value.indexOf(suffix, prefixIndex + prefix.length);

        if (prefixIndex === -1 || suffixIndex === -1) {
            throw new Error('The template structure doesn\'t match the provided value.');
        }

        const paramValue = value.substring(prefixIndex + prefix.length, suffixIndex);
        params[index] = paramValue;  // Use the index to insert at the correct position
    }

    return params;
}


export function* markSyncingAsInterrupted(key: SyncKey) {
    yield Database.transaction('rw', Database.syncBoard, async () => {
        let previous = undefined
        try {
            previous = (await Database.syncBoard.get(key))
        } catch (e) {
            console.error('Failed to mark syncing as interrupted', e)
        }

        if (previous == undefined)
            return
        
            
        await Database.syncBoard.put({
            ...previous,
            status: SyncBoardStatus.SYNCING_INTERRUPTED
        }, key)
    })
    yield put(watcherActions.fail({
        watchers: [`Syncing.${key}`]
    }))
}

export function* markSyncingAsStarted(key: SyncKey) {
    yield Database.transaction('rw', Database.syncBoard, async () => {
        let previous = undefined
        try {
            previous = (await Database.syncBoard.get(key))
        } catch (e) {
            console.error('Failed to mark syncing as started', e)
        }

        if (previous == undefined)
            return
        
        await Database.syncBoard.put({
            ...previous,
            status: SyncBoardStatus.SYNCING_NOW
        }, key)
    })
    yield put(watcherActions.start({
        watchers: [`Syncing.${key}`]
    }))
}

export function* incrementSyncTimestamp(template: SyncKeyTemplate, key: SyncKey) {
    yield Database.transaction('rw', Database.syncBoard, async () => {
        let localPreviousStamp = 0
        try {
            localPreviousStamp = (await Database.syncBoard.get(key))?.at || 0
        } catch (e) {
            console.error('Failed to increment syncTimestamp')
        }
        if (localPreviousStamp !== undefined) {
            await Database.syncBoard.put({
                key,
                at: localPreviousStamp + 1,
                template,
                status: SyncBoardStatus.FULLY_SYNCED
            }, key)
        }
    })

    yield put(watcherActions.done({
        watchers: [`Syncing.${key}`]
    }))
}

export function* getSyncTimestamp(key: SyncKey): any {
    try {
        return yield Database.syncBoard.get(key)
    } catch (e) {
        console.error('Failed to get syncTimestamp', e)
        return 0
    }
}