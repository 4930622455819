import { CalendarMonth } from '@comps/complex/calendarMonth';
import { Flex } from '@comps/layout/flex';
import { InnerPage } from '@comps/layout/innerPage';
import { PageHeader } from '@comps/layout/pageHeader';
import { Spacer } from '@comps/layout/space';
import { Cell, HeaderCell, Row, Table } from '@comps/layout/table';
import { Icon } from '@comps/static/icon';
import { Text } from '@comps/typography/text';
import { useTime } from '@root/hooks/useTime';
import NavigationStackCore from '@root/Router.NavigationStackCore';
import { useActivities } from '@root/store/selectors/useActivities';
import { useSessions } from '@root/store/selectors/useSessions';
import { ActivityColorKey, ActivityColors } from '@root/store/slices/activities.colors.types';
import { InstancedSessionData } from '@root/store/slices/sessionsTypes';
import { getSessionDuration } from '@root/utils/dates';
import { differenceInSeconds, endOfYear, format, getWeek, isSameDay, startOfYear } from 'date-fns';
import React, { useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

export const ActivitySessions = () => {
	const { id } = useParams()
	const date = useTime('date')
	const activities = useActivities()
	const now = useTime('date')
	const activityColor = useRef<ActivityColorKey | undefined>()

	const defaultDates = useMemo(() => {
		return [startOfYear(date), endOfYear(date)] as [Date, Date]
	}, [date])

	const sessions = useSessions('for-activity-<0>-year-<1>', id, format(date, 'yyyy'))

	const sessionsByMonth = useMemo(() => {
		const acc: {
			[month: string]: {
				monthIndex: string
				weekOrder: number[]
				atWeek: {
					[week: string]: {
						dayOrder: number[]
						atDay: {
							[day: string]: InstancedSessionData[]
						}
					}
				}
			}
		} = {}

		const allSessions = sessions.all.sort((a, b) => (sessions.at[a].start < sessions.at[b].start ? -1 : 1))
		const highlightedByDate: any = {}
		allSessions.forEach((sessionId) => {
			const session = sessions.at[sessionId]
			const startAt = session.start
			const monthKey = format(startAt, 'MMMM')
			const monthIndex = format(startAt, 'M')
			const weekKey = getWeek(startAt)
			const dayKey = format(startAt, 'd')

			if (!acc[monthKey]) {
				acc[monthKey] = {
					monthIndex: monthIndex,
					weekOrder: [],
					atWeek: {},
				}
			}
			if (!acc[monthKey].atWeek[weekKey])
				acc[monthKey].atWeek[weekKey] = {
					dayOrder: [],
					atDay: {},
				}
			if (!acc[monthKey].atWeek[weekKey].atDay[dayKey]) acc[monthKey].atWeek[weekKey].atDay[dayKey] = []

			acc[monthKey].atWeek[weekKey].atDay[dayKey].push(session)

			if (!activityColor.current) activityColor.current = activities.at[session.activityId].color

			// Push for frequency counting
			if (acc[monthKey].atWeek[weekKey].atDay[dayKey]?.length) {
				if (!highlightedByDate[monthIndex]) {
					highlightedByDate[monthIndex] = {}
				}
				let sum = 0
				for (const sess of acc[monthKey].atWeek[weekKey].atDay[dayKey]) {
					sum += differenceInSeconds(sess.end, sess.start)
				}
				highlightedByDate[monthIndex][dayKey] = sum
			}
		})

		for (const month of Object.keys(acc)) {
			acc[month].weekOrder = Object.keys(acc[month].atWeek)
				.map((a) => parseInt(a))
				.sort((a, b) => (a > b ? -1 : 1))
			for (const week of Object.keys(acc[month].atWeek)) {
				acc[month].atWeek[week].dayOrder = Object.keys(acc[month].atWeek[week].atDay)
					.map((a) => parseInt(a))
					.sort((a, b) => (a > b ? -1 : 1))
			}
		}

		return {
			at: acc,
			all: Object.keys(acc).sort((a, b) => {
				const accA = acc[a]
				const accB = acc[b]
				const mA = parseInt(accA.monthIndex)
				const mB = parseInt(accB.monthIndex)
				return mA > mB ? -1 : 1
			}),
			countAtMonth: highlightedByDate,
		}
	}, [sessions, activities])

	const navigate = useNavigate()
	const location = useLocation()

	const handleRowClick = useCallback(
		(sessionId: string) => {
			NavigationStackCore.push(location.pathname)
			navigate(`/session/${sessionId}`)
		},
		[navigate, location]
	)

	return (
		<InnerPage>
			<PageHeader extraBottomSpace title="All Sessions" />
			{sessionsByMonth.all.length > 0 ? (
				sessionsByMonth.all.map((monthIndex) => {
					const monthsSessions = sessionsByMonth.at[monthIndex]
					const monthIndexNumber = parseInt(monthsSessions.monthIndex)
					const weeks = monthsSessions.weekOrder
					return (
						<Flex direction="column" align="stretch" key={monthIndex}>
							<Text level="title10">{monthIndex}</Text>
							<Flex direction="row" grow justify="space-between">
								<Flex direction="column" grow align="stretch">
									<Spacer size={35} vertical />
									<Table
										layout="1fr 1fr min-content 20px 1fr 1fr 1fr 0.5fr"
										onClick={handleRowClick}
									>
										<Row>
											<HeaderCell>Date</HeaderCell>
											<HeaderCell>Day of week</HeaderCell>
											<HeaderCell>From</HeaderCell>
											<HeaderCell></HeaderCell>
											<HeaderCell>Until</HeaderCell>
											<HeaderCell>Duration</HeaderCell>
											<HeaderCell>Week</HeaderCell>
											<HeaderCell></HeaderCell>
										</Row>
										{weeks.map((weekIndex) => {
											const weeksSessions =
												monthsSessions.atWeek[`${weekIndex}`].dayOrder
											return (
												<React.Fragment key={weekIndex}>
													{/* <Spacer vertical size={30} />
												<Text level="tiny" bold color="subtle">
													WEEK {weekIndex}
												</Text>
												<Spacer vertical size={10} /> */}
													{weeksSessions.map((dayIndex) => {
														const daySessions =
															monthsSessions.atWeek[
																weekIndex
															].atDay[`${dayIndex}`]
														return daySessions.map(
															(session, index, arr) => {
																return (
																	<Row
																		className={
																			'row noseldis'
																		}
																		data-id={
																			session.id
																		}
																		key={
																			session.id
																		}
																	>
																		<Cell
																			noBorder={isSameDay(
																				arr[
																					index
																				]
																					?.start,
																				arr[
																					index +
																					1
																				]
																					?.start
																			)}
																		>
																			<Text
																				level="label"
																				bold
																			>
																				{index ==
																					0
																					? format(
																						session.start,
																						'dd MMMM'
																					)
																					: ''}
																			</Text>
																		</Cell>
																		<Cell>
																			<Text level="label">
																				{index ==
																					0
																					? format(
																						session.start,
																						'eeee'
																					)
																					: ''}
																			</Text>
																		</Cell>
																		<Cell align="left">
																			<Text level="label">
																				{format(
																					session.start,
																					'HH:mm'
																				)}
																			</Text>
																		</Cell>
																		<Cell>
																			<Text level="label">
																				&rarr;
																			</Text>
																		</Cell>
																		<Cell>
																			<Text level="label">
																				{format(
																					session.end,
																					'HH:mm'
																				)}
																			</Text>
																		</Cell>
																		<Cell>
																			<Text
																				color="subtle"
																				level="label"
																			>
																				{getSessionDuration(
																					session
																				)}
																			</Text>
																		</Cell>
																		<Cell>
																			<Text
																				color="subtle"
																				level="label"
																			>
																				Week{' '}
																				{
																					weekIndex
																				}
																			</Text>
																		</Cell>
																		<Cell align="right">
																			<Icon type="arrow" />
																		</Cell>
																	</Row>
																)
															}
														)
													})}
												</React.Fragment>
											)
										})}
									</Table>
									<Spacer size={40} vertical />
								</Flex>
								<Spacer size={100} />
								{activityColor.current && (
									<CalendarMonth
										key={`fix-${monthIndexNumber}`}
										year={format(defaultDates[0], 'yyyy')}
										monthIndex={monthIndexNumber}
										highlightColor={
											ActivityColors[activityColor.current]?.inactiveLight
												.background.r.hex
										}
										highlightDates={sessionsByMonth.countAtMonth[monthIndexNumber]}
									/>
								)}
							</Flex>
						</Flex>
					)
				})
			) : (
				<Text>No sessions.</Text>
			)}
		</InnerPage>
	)
}
