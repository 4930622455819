import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { SendableStream, StreamSessionRequest } from '@clepside/clepsidejs/lib/services/sync_v1_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Logger } from '@root/cores/logger'
import { Database, SessionPacketObjectWithDaystamps } from '@root/database'
import { SyncGRPC } from '@root/grpc/grpcSync'
import { STREAM_ENDED } from '@root/grpc/utils'
import { getDaySpan } from '@root/utils/dates'
import { call, takeEvery } from 'redux-saga/effects'
import { sessionsActions } from './sessions'
import { SyncCards } from './sync.cards'
import { SyncSharingInvitations } from './sync.sharingInvitations'
import { processResourceComprehensiveStream } from './sync.tsx.handlers'
import { PacketObject, SessionPacketObject } from './sync.tsx.packets.types'
import { getLastSyncedAt, getPacketObjectFromRemoteObject, ResourceComprehensiveStreamingConfig } from './sync.types'
import { markSyncingAsStarted } from './sync.utils'

export class SyncSessions {
    public static runeOrPacket = 'packet' as const

    public static type = PacketType.SESSION as const

    static fromSendables(res: Sendable.AsObject[] | undefined): PacketObject<any>[] {
        return res?.map((r) => getPacketObjectFromRemoteObject(r?.packet?.session))
            .filter((f): f is PacketObject<any> => f !== undefined) || []
    }

    static fromPacket(res: Packet.AsObject | undefined) {
        return getPacketObjectFromRemoteObject(res?.session)
    }
    
    static placeInStore(objects: SessionPacketObject[], fromSync?: boolean) {
        return sessionsActions.normalizeAndStoreIfMatchesActiveScope({ objects, fromSync })
    }

    static prepareForStorage(object: SessionPacketObject): SessionPacketObjectWithDaystamps {
        return {
            ...object,
            daystamps: getDaySpan(new Date(object.data.start), new Date(object.data.end))
        }
    }


    static database = Database.sessionPackets
}


const syncSessionsSlice = createSlice({
    name: 'sync.sessions',
    initialState: {},
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // fetchSessionsNeedingFetching:  (_, {}: PayloadAction) => {},
        // streamActivity: (_, {}: PayloadAction<{ activityId: string }>) => {},
        streamSession: (_, {}: PayloadAction<{ sessionId: string }>) => {},
    },
})


export const syncSessionsActions = syncSessionsSlice.actions

export const syncSessionsSagas = {
    *streamSession({ payload }: ReturnType<typeof syncSessionsSlice.actions.streamSession>): any {
        const request = new StreamSessionRequest()
        request.setSessionId(payload.sessionId)

        const streamSessionResources = [
            new ResourceComprehensiveStreamingConfig(
                SyncSharingInvitations, 
                (num: number) => request.setInvitationsLastFetchedAt(num),
                'Sessions/<0>/Invitations', 
                payload.sessionId),
            new ResourceComprehensiveStreamingConfig(
                SyncCards,
                (num: number) => request.setCardsLastFetchedAt(num),
                'Sessions/<0>/Cards',
                payload.sessionId,
            ),
        ]

        for (const resource of streamSessionResources) {
            const lastSyncedAt = yield getLastSyncedAt(resource.lmat_key)
            yield markSyncingAsStarted(resource.lmat_key)
            resource.prepareRequest(lastSyncedAt)
        }
        
        try {
            const channel = yield SyncGRPC.constructSessionsStreamingChannel(request)
            const receiver = function* (response: SendableStream) {
                yield call(processResourceComprehensiveStream, response, 'Sessions', streamSessionResources)
            }
            Logger.sync.debug('[ResourceSync] Starting...')
            yield takeEvery(channel, receiver)
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        }
    },
}