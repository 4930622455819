import styled from 'styled-components'

export const Tooltip = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	left: 0;
	height: auto;
	bottom: 0;
	display: none;
	flex-grow: 0;
	flex-shrink: 1;
	z-index: 1000000000;
	& > *:first-child {
		${(p) => p.theme.backgrounds.dark.r.css('background-color')}
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
		border-radius: 16px;
	}
`
