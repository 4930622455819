import { PacketType, RuneType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb';
import { Sendable } from '@clepside/clepsidejs/lib/unions_v1/sendable_pb';
import { Database } from '@root/database';
import Dexie from 'dexie';

import { ULID } from '../commonTypes';
import {
    convertRemoteRuneToRuneObject, RemoteRuneObject, RuneMapping, RuneObject
} from './runes.types';
import { SyncActivities } from './sync.activities';
import { SyncAICardChats } from './sync.aiCardChats';
import { SyncAICardChatsMessages } from './sync.aiCardChatsMessages';
import { SyncBoards } from './sync.boards';
import { SyncCards } from './sync.cards';
import { SyncFragmentsCompletable } from './sync.fragmentsCompletable';
import { SyncFragmentsPlainText } from './sync.fragmentsPlainText';
import { SyncFragmentsUploadable } from './sync.fragmentsUploadable';
import { SyncNotifications } from './sync.notifications';
import { SyncRoutines } from './sync.routines';
import { SyncRoutineSchedules } from './sync.routineSchedules';
import { SyncSessions } from './sync.sessions';
import { SyncSettings } from './sync.settings';
import { SyncSharingInvitations } from './sync.sharingInvitations';
import {
    convertRemotePacketObjectToPacketObject, PacketObject, PacketTypesMapping, RemotePacketObject
} from './sync.tsx.packets.types';
import { getSyncKeyFromTemplate, SyncKey, SyncKeyTemplate } from './sync.utils';

export interface SyncablePacket<K extends keyof PacketTypesMapping> {
    runeOrPacket: 'packet'
    type: PacketType
    placeInStore: (objects: PacketTypesMapping[K]['local'][], fromSync?: boolean) => any
    fromSendables: (res: Sendable.AsObject[] | undefined) => PacketObject<any>[]
    fromPacket: (res: Packet.AsObject | undefined) => PacketObject<any> | undefined
    prepareForStorage?: (object: any) => any
    inTransaction?: (packet: PacketTypesMapping[K]['local']) => void
    afterPersist?: () => Generator<any, any, any>
    database: Dexie.Table<PacketObject<K>, string>
    otherDatabaseLocks?: Array<Dexie.Table<any, any>>
}

export interface SyncableRune<K extends keyof RuneMapping> {
    runeOrPacket: 'rune'
    type: RuneType
    fromSendables: (res: Sendable.AsObject[] | undefined) => RuneObject<any> []
    placeInStore: (objects: RuneMapping[K]['local'][], fromSync?: boolean) => any
    database: Dexie.Table<RuneObject<K>, string>
}


export type Syncable = SyncablePacket<any> | SyncableRune<any>

export class ResourceComprehensiveStreamingConfig<T> {
    public syncable: Syncable

    public lmat_key: SyncKey

    public lmat_template: SyncKeyTemplate

    public prepareRequest: (timestamp: number) => void

    constructor(syncable: Syncable, prepareRequest: (timestamp: number) => void, template: SyncKeyTemplate, ...templateArgs: string[]) {
        this.lmat_key = getSyncKeyFromTemplate(template, ...templateArgs)
        this.lmat_template = template
        this.syncable = syncable
        this.prepareRequest = prepareRequest
    }
}

export const PacketTypeToSyncable: { [K in ValidPacketType]: SyncablePacket<K> } = {
    [PacketType.ACTIVITY]: SyncActivities,
    [PacketType.CARD]: SyncCards,
    [PacketType.BOARD]: SyncBoards,
    [PacketType.FRAGMENT_COMPLETABLE]: SyncFragmentsCompletable,
    [PacketType.SESSION]: SyncSessions,
    [PacketType.FRAGMENT_PLAIN_TEXT]: SyncFragmentsPlainText,
    [PacketType.FRAGMENT_UPLOADABLE]: SyncFragmentsUploadable,
    [PacketType.SETTINGS]: SyncSettings,
    [PacketType.ROUTINE]: SyncRoutines,
    [PacketType.ROUTINE_SCHEDULE]: SyncRoutineSchedules,
}
export const RuneTypeToSyncable: { [K in ValidRuneType]: SyncableRune<K> } = {
    [RuneType.NOTIFICATION]: SyncNotifications,
    [RuneType.SHARING_INVITATION]: SyncSharingInvitations,
    [RuneType.GPT_CARD_CHAT]: SyncAICardChats,
    [RuneType.GPT_CARD_MESSAGE]: SyncAICardChatsMessages,
}

export const AllSyncablePackets = [
    SyncActivities,
    SyncSessions,
    SyncBoards,
    SyncCards,
    SyncFragmentsCompletable,
    SyncFragmentsPlainText,
    SyncFragmentsUploadable,
    SyncRoutines,
    SyncRoutineSchedules,
    SyncSettings
]
export const AllSyncableRunes = [
    SyncNotifications,
    SyncSharingInvitations,
    SyncAICardChats,
    SyncAICardChatsMessages,
]

export const AllSyncables = [
    ...AllSyncablePackets,
    ...AllSyncableRunes
]

export type AllSyncablesTypes = typeof AllSyncables
export type SyncableType = AllSyncablesTypes[0]


type SelectByRop<T, K> = T extends { runeOrPacket: K, type: infer N } ? N : never;


export type ValidPacketType = SelectByRop<SyncableType, 'packet'>
export type ValidRuneType = SelectByRop<SyncableType, 'rune'>

export function getPacketObjectFromRemoteObject<T extends RemotePacketObject>(remotePacket: T | undefined) {
    if (!remotePacket) return undefined
    return convertRemotePacketObjectToPacketObject(remotePacket)
}

export function getRuneObjectFromRemoteObject<T extends RemoteRuneObject>(remoteRune: T | undefined) {
    if (!remoteRune) return undefined
    return convertRemoteRuneToRuneObject(remoteRune)
}

export async function getLastSyncedAtFromTemplate(template: SyncKeyTemplate, ...args: string[]) {
    let lastSyncAt = 0		
    const key = getSyncKeyFromTemplate(template, ...args)

    try {
        const stored = await Database.syncBoard.get(key)
        lastSyncAt = stored?.at || 0
    } catch (e) {
        console.error('Failed to getLastSyncedAtFromTemplate', e)
        if (e == Dexie.NotFoundError) {
            lastSyncAt = 0
        } else {
            throw e
        }
    }
    return lastSyncAt
}

export async function getLastSyncedAt(key: SyncKey) {
    let lastSyncAt = 0	

    try {
        const stored = await Database.syncBoard.get(key)
        lastSyncAt = stored?.at || 0
    } catch (e) {
        console.error('Failed to getLastSyncedAt', e)
        if (e == Dexie.NotFoundError) {
            lastSyncAt = 0
        } else {
            throw e
        }
    }
    return lastSyncAt
}

export type LocalData<T extends PacketObject<any>> = NonNullable<Required<T['data']>> & { id: ULID }

// const filteredJohn = filterProperties<{ syncing: { forActivity: object } }>((value) => value.syncing?.forActivity );

// TODO
// function filterProperties<P>(
//   predicate: (value: any) => boolean
// ): FilteredConfig<P> {
//     const keys = (Object.keys(SendableConfig) as Array<keyof typeof SendableConfig>).filter(key => predicate(SendableConfig[key]));
//     const result = {} as any
//     keys.forEach(key => result[key] = SendableConfig[key]);
//     return result as FilteredConfig<P>
// }


// export const SendableConfig = {
//     'Activity': {
// 		rop: 'packet' as const,
// 		type: PacketType.ACTIVITY as const,
//         syncing: {
//             forActivitiesFetching: () => {
//                 return function* (response: BatchedSendablesStream | typeof STREAM_ENDED) {
//                     yield call(processBatchedSendablesStream, response, getSyncKeyFromTemplate('Activities'), {
//                         rop: 'packet',
//                         extract: (res: Sendable.AsObject | undefined) => {
//                             return getPacketObjectFromRemoteObject(res?.packet?.activity)
//                         }
//                     })
//                 }
//             },
//             forPartialActivitiesFetching: () => {
//                 return function* (response: BatchedSendablesStream | typeof STREAM_ENDED) {
//                     yield call(processBatchedSendablesStream, response, getSyncKeyFromTemplate('PartialActivities'), {
//                         rop: 'packet',
//                         extract: (res: Sendable.AsObject | undefined) => {
//                             return getPacketObjectFromRemoteObject(res?.packet?.activity)
//                         }
//                     })
//                 }
//             }
//         },
// 		extract: {
//             fromSendable: (res: Sendable.AsObject | undefined) => (getPacketObjectFromRemoteObject(res?.packet?.activity)),
//             fromPacket: (res: Packet.AsObject) => (getPacketObjectFromRemoteObject(res?.activity)),
//             fromActivityPacket: (res: ActivityPacket.AsObject) => (getPacketObjectFromRemoteObject(res))
//         },
//         database: getDatabase('activityPackets'),
// 	},
//     'Session': {
// 		rop: 'packet' as const,
// 		type: PacketType.SESSION as const,
//         syncing: {
//             forSessionsByActivityFetching: {
//                 syncKeyTemplate: reachSyncKeyTemplate('Activities/<0>/Sessions'),
//                 prepareRequest: (req: FetchSessionsByActivityRequest, lastFetchedAt: number, activityId: string, pastTimestamp: number) => {
//                     req.setSessionsByActivityLastFetchedAt(lastFetchedAt)
//                     req.setActivityId(activityId)   
//                     req.setPastTimestamp(pastTimestamp)
//                 },
//             },

//             forSessionsByActivityFetching: () => {
//                 return function* (response: BatchedSendablesStream | typeof STREAM_ENDED) {
//                     yield call(processBatchedSendablesStream, response, getSyncKeyFromTemplate('Activities'), {
//                         rop: 'packet',
//                         extract: (res: Sendable.AsObject | undefined) => {
//                             return getPacketObjectFromRemoteObject(res?.packet?.activity)
//                         }
//                     })
//                 }
//             },
//             forIndividualSessionFetching: {
//                 syncKeyTemplate: reachSyncKeyTemplate('IndividualSessions'),
//                 prepareRequest: (req: FetchIndividualSessionsRequest, lastFetchedAt: number, pastTimestamp: number) => {
//                     req.setGuestSessionsLastFetchedAt(lastFetchedAt)
//                     req.setPastTimestamp(pastTimestamp)
//                 },
//             }
//         },
// 		extract: {
//             fromSendable: (res: Sendable.AsObject | undefined) => (getPacketObjectFromRemoteObject(res?.packet?.session)),
//             fromPacket: (res: Packet.AsObject) => (getPacketObjectFromRemoteObject(res?.session)),
//             fromSessionPacket: (res: SessionPacket.AsObject) => (getPacketObjectFromRemoteObject(res))
//         },
//         database: getDatabase('activityPackets'),
// 	},
// 	'Card': {
// 		rop: 'packet' as const,
// 		type: PacketType.CARD as const,
//         syncing: {
//             forActivityStreaming: {
//                 syncKeyTemplate: reachSyncKeyTemplate('Activities/<0>/Cards'),
//                 getResourceId: (sendable: Sendable.AsObject) => sendable.packet?.card?.data?.resourceId,
//                 prepareRequest: (req: StreamActivityRequest, lastFetchedAt: number) => req.setCardsLastFetchedAt(lastFetchedAt),
//             }
//         },
// 		extract: {
//             fromSendable: (res: Sendable.AsObject | undefined) => (getPacketObjectFromRemoteObject(res?.packet?.card)),
//             fromPacket: (res: Packet.AsObject) => (getPacketObjectFromRemoteObject(res?.card))
//         },
//         database: getDatabase('cardPackets'),
// 	},
// 	'Todo Fragment': {
// 		rop: 'packet' as const,
// 		type: PacketType.FRAGMENT_COMPLETABLE as const,
//         syncing: {
//             forActivityStreaming: {
//                 syncKeyTemplate: reachSyncKeyTemplate('Activities/<0>/Fragments'),
//                 getResourceId: (sendable: Sendable.AsObject) => sendable.packet?.todoFragment?.data?.resourceId,
//                 prepareRequest: (req: StreamActivityRequest, lastFetchedAt: number) => req.setFragmentsLastFetchedAt(lastFetchedAt),
//             }
//         },
// 		extract: {
//             fromSendable: (res: Sendable.AsObject | undefined) => (getPacketObjectFromRemoteObject(res?.packet?.todoFragment)),
//             fromPacket: (res: Packet.AsObject) => (getPacketObjectFromRemoteObject(res?.todoFragment))
//         },
//         database: getDatabase('fragmentPackets'),
// 	},
//     'Notification': {
//         rop: 'rune' as const,
// 		type: RuneType.NOTIFICATION as const,
// 		syncing: {
//             forNotificationsFetching: {
//                 syncKeyTemplate: reachSyncKeyTemplate('Notifications'),
//             }
//         },
// 		extract: {
//             fromSendable: (res: Sendable.AsObject | undefined) => (getRuneObjectFromRemoteObject(res?.rune?.notification)),
//             fromRune: (res: Rune.AsObject) => (getRuneObjectFromRemoteObject(res?.notification)),
//         },
//         database: getDatabase('notificationRunes'),
//         store: (object: NotificationRuneObject) => notificationsActions.store({ objects: [object] }),
//     },
// 	'Invitation': {
// 		rop: 'rune' as const,
// 		type: RuneType.SHARING_INVITATION as const,
// 		syncing: {
//             forActivityStreaming: {
//                 syncKeyTemplate: reachSyncKeyTemplate('Activities/<0>/Invitations'),
//                 getResourceId: (sendable: Sendable.AsObject) => sendable.rune?.sharingInvitation?.rune?.resourceId,
//                 prepareRequest: (req: StreamActivityRequest) => req.setInvitationsLastFetchedAt
//             }
//         },
// 		extract: {
//             fromSendable: (res: Sendable.AsObject | undefined) => (getRuneObjectFromRemoteObject(res?.rune?.sharingInvitation)),
//             fromRune: (res: Rune.AsObject) => (getRuneObjectFromRemoteObject(res?.sharingInvitation)),
//         },
//         database: getDatabase('sharingInvitationRunes'),
//         store: (object: SharingInvitationRuneObject) => sharingActions.store({ objects: [object] })
// 	},
// }

// type UnionType = typeof SendableConfig[keyof typeof SendableConfig];
// type PacketsConfigType = Extract<UnionType, { rop: 'packet' }>
// type RunesConfigType = Extract<UnionType, { rop: 'rune' }>