import { useIsDarkMode } from '@brand/colors'
import { Transitions } from '@root/brand/transitions'
import { motion } from 'framer-motion'
import React, { MouseEventHandler, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

const modalVariants = {
	showModal: { opacity: 1, display: 'flex' },
	hideModal: {
		opacity: 0,
		transitionEnd: {
			display: 'none',
		},
	},
}

export const Modal: React.FC<{
	children: any
	backTo?: string
	onBack?: MouseEventHandler<HTMLButtonElement>
	unsetParam?: string
	extraZ?: number
}> = ({ children, backTo, onBack, unsetParam, extraZ }) => {
	const [, setQp] = useSearchParams()
	const navigate = useNavigate()
	const isDark = useIsDarkMode()

	const handleBackClick = useCallback<MouseEventHandler<HTMLDivElement>>(
		(e: any) => {
			if (onBack) {
				onBack?.(e)
				return
			}
			if (unsetParam) {
				setQp({ [unsetParam]: '' })
			} else if (backTo) {
				navigate(backTo)
			} else navigate(-1)
		},
		[setQp, onBack, backTo, navigate, unsetParam]
	)

	return (
		<Holder
			initial="hideModal"
			animate="showModal"
			exit="hideModal"
			variants={modalVariants}
			isDark={isDark}
			transition={Transitions.relaxed}
			onClick={handleBackClick}
			extraZ={extraZ}
		>
			{children}
		</Holder>
	)
}

const Holder = styled(motion.div) <{ isDark?: boolean; hide?: boolean; extraZ?: number }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	z-index: 10000000;
	align-items: center;
	justify-content: center;
	will-change: opacity;
	overflow: hidden;
	background-color: ${(p) => (p.isDark ? 'rgba(15, 17, 20, 0.65)' : 'rgba(210, 220, 230, 0.82)')};
	backdrop-filter: blur(70px);
	backface-visibility: hidden;

	${p => p.extraZ && css`
		z-index: ${10000000 + p.extraZ};
	`}
`
