import styled from 'styled-components'

export const MaxWidth: React.FC<{ children?: React.ReactNode, size: number }> = ({ size, children }) => {
	return <Holder size={size}>{children}</Holder>
}

const Holder = styled.div<{ size: number }>`
	width: 100%;
	max-width: ${(p) => p.size}px;
`
