import styled from 'styled-components';

export const ListHolder = styled.div`
	flex-grow: 1;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	margin: -10px;
	padding: 10px;
	margin-top: 10px;
`
