import { GPTServiceClient } from '@clepside/clepsidejs/lib/services/gpt_v1_grpc_web_pb';
import {
    ContinueChatRequest, CreateNewChatRequest, CreateNewGistRequest, StreamedGPTChatResponse
} from '@clepside/clepsidejs/lib/services/gpt_v1_pb';

import { getGRPCMeta } from './utils';

if (!process.env.REACT_APP_API_URL)
    throw new Error('No RAPURL')
const GPTClient = new GPTServiceClient(process.env.REACT_APP_API_URL)

export const GPTGRPC = {
    createNewGist: (req: CreateNewGistRequest) => new Promise<StreamedGPTChatResponse>((res, rej) => {
        (async () => {
            GPTClient.createNewGist(req, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
        })();
    }),
    continueChat: (req: ContinueChatRequest) => new Promise<StreamedGPTChatResponse>((res, rej) => {
        (async () => {
            GPTClient.continueChat(req, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
        })();
    }),
    createNewChat: (req: CreateNewChatRequest) => new Promise<StreamedGPTChatResponse>((res, rej) => {
        (async () => {
            GPTClient.createNewChat(req, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
        })();
    }),
}
