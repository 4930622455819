// source: unions_v1/runes.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var runes_v1_notification_pb = require('../runes_v1/notification_pb.js');
goog.object.extend(proto, runes_v1_notification_pb);
var runes_v1_sharing_invitation_pb = require('../runes_v1/sharing_invitation_pb.js');
goog.object.extend(proto, runes_v1_sharing_invitation_pb);
var runes_v1_gpt_card_chat_pb = require('../runes_v1/gpt_card_chat_pb.js');
goog.object.extend(proto, runes_v1_gpt_card_chat_pb);
goog.exportSymbol('proto.unions_v1.Rune', null, global);
goog.exportSymbol('proto.unions_v1.Rune.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.unions_v1.Rune = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.unions_v1.Rune.oneofGroups_);
};
goog.inherits(proto.unions_v1.Rune, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.unions_v1.Rune.displayName = 'proto.unions_v1.Rune';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.unions_v1.Rune.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.unions_v1.Rune.TypeCase = {
  TYPE_NOT_SET: 0,
  NOTIFICATION: 1,
  SHARING_INVITATION: 2,
  CARD_CHAT: 3,
  CARD_CHAT_MESSAGE: 4
};

/**
 * @return {proto.unions_v1.Rune.TypeCase}
 */
proto.unions_v1.Rune.prototype.getTypeCase = function() {
  return /** @type {proto.unions_v1.Rune.TypeCase} */(jspb.Message.computeOneofCase(this, proto.unions_v1.Rune.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.unions_v1.Rune.prototype.toObject = function(opt_includeInstance) {
  return proto.unions_v1.Rune.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.unions_v1.Rune} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unions_v1.Rune.toObject = function(includeInstance, msg) {
  var f, obj = {
    notification: (f = msg.getNotification()) && runes_v1_notification_pb.NotificationRune.toObject(includeInstance, f),
    sharingInvitation: (f = msg.getSharingInvitation()) && runes_v1_sharing_invitation_pb.SharingInvitationRune.toObject(includeInstance, f),
    cardChat: (f = msg.getCardChat()) && runes_v1_gpt_card_chat_pb.GPTCardChatRune.toObject(includeInstance, f),
    cardChatMessage: (f = msg.getCardChatMessage()) && runes_v1_gpt_card_chat_pb.GPTCardChatMessageRune.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.unions_v1.Rune}
 */
proto.unions_v1.Rune.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.unions_v1.Rune;
  return proto.unions_v1.Rune.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.unions_v1.Rune} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.unions_v1.Rune}
 */
proto.unions_v1.Rune.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new runes_v1_notification_pb.NotificationRune;
      reader.readMessage(value,runes_v1_notification_pb.NotificationRune.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    case 2:
      var value = new runes_v1_sharing_invitation_pb.SharingInvitationRune;
      reader.readMessage(value,runes_v1_sharing_invitation_pb.SharingInvitationRune.deserializeBinaryFromReader);
      msg.setSharingInvitation(value);
      break;
    case 3:
      var value = new runes_v1_gpt_card_chat_pb.GPTCardChatRune;
      reader.readMessage(value,runes_v1_gpt_card_chat_pb.GPTCardChatRune.deserializeBinaryFromReader);
      msg.setCardChat(value);
      break;
    case 4:
      var value = new runes_v1_gpt_card_chat_pb.GPTCardChatMessageRune;
      reader.readMessage(value,runes_v1_gpt_card_chat_pb.GPTCardChatMessageRune.deserializeBinaryFromReader);
      msg.setCardChatMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.unions_v1.Rune.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.unions_v1.Rune.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.unions_v1.Rune} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.unions_v1.Rune.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      runes_v1_notification_pb.NotificationRune.serializeBinaryToWriter
    );
  }
  f = message.getSharingInvitation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      runes_v1_sharing_invitation_pb.SharingInvitationRune.serializeBinaryToWriter
    );
  }
  f = message.getCardChat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      runes_v1_gpt_card_chat_pb.GPTCardChatRune.serializeBinaryToWriter
    );
  }
  f = message.getCardChatMessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      runes_v1_gpt_card_chat_pb.GPTCardChatMessageRune.serializeBinaryToWriter
    );
  }
};


/**
 * optional runes_v1.NotificationRune notification = 1;
 * @return {?proto.runes_v1.NotificationRune}
 */
proto.unions_v1.Rune.prototype.getNotification = function() {
  return /** @type{?proto.runes_v1.NotificationRune} */ (
    jspb.Message.getWrapperField(this, runes_v1_notification_pb.NotificationRune, 1));
};


/**
 * @param {?proto.runes_v1.NotificationRune|undefined} value
 * @return {!proto.unions_v1.Rune} returns this
*/
proto.unions_v1.Rune.prototype.setNotification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.unions_v1.Rune.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Rune} returns this
 */
proto.unions_v1.Rune.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Rune.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional runes_v1.SharingInvitationRune sharing_invitation = 2;
 * @return {?proto.runes_v1.SharingInvitationRune}
 */
proto.unions_v1.Rune.prototype.getSharingInvitation = function() {
  return /** @type{?proto.runes_v1.SharingInvitationRune} */ (
    jspb.Message.getWrapperField(this, runes_v1_sharing_invitation_pb.SharingInvitationRune, 2));
};


/**
 * @param {?proto.runes_v1.SharingInvitationRune|undefined} value
 * @return {!proto.unions_v1.Rune} returns this
*/
proto.unions_v1.Rune.prototype.setSharingInvitation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.unions_v1.Rune.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Rune} returns this
 */
proto.unions_v1.Rune.prototype.clearSharingInvitation = function() {
  return this.setSharingInvitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Rune.prototype.hasSharingInvitation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional runes_v1.GPTCardChatRune card_chat = 3;
 * @return {?proto.runes_v1.GPTCardChatRune}
 */
proto.unions_v1.Rune.prototype.getCardChat = function() {
  return /** @type{?proto.runes_v1.GPTCardChatRune} */ (
    jspb.Message.getWrapperField(this, runes_v1_gpt_card_chat_pb.GPTCardChatRune, 3));
};


/**
 * @param {?proto.runes_v1.GPTCardChatRune|undefined} value
 * @return {!proto.unions_v1.Rune} returns this
*/
proto.unions_v1.Rune.prototype.setCardChat = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.unions_v1.Rune.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Rune} returns this
 */
proto.unions_v1.Rune.prototype.clearCardChat = function() {
  return this.setCardChat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Rune.prototype.hasCardChat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional runes_v1.GPTCardChatMessageRune card_chat_message = 4;
 * @return {?proto.runes_v1.GPTCardChatMessageRune}
 */
proto.unions_v1.Rune.prototype.getCardChatMessage = function() {
  return /** @type{?proto.runes_v1.GPTCardChatMessageRune} */ (
    jspb.Message.getWrapperField(this, runes_v1_gpt_card_chat_pb.GPTCardChatMessageRune, 4));
};


/**
 * @param {?proto.runes_v1.GPTCardChatMessageRune|undefined} value
 * @return {!proto.unions_v1.Rune} returns this
*/
proto.unions_v1.Rune.prototype.setCardChatMessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.unions_v1.Rune.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.unions_v1.Rune} returns this
 */
proto.unions_v1.Rune.prototype.clearCardChatMessage = function() {
  return this.setCardChatMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.unions_v1.Rune.prototype.hasCardChatMessage = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.unions_v1);
