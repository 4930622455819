export class Point {
	private readonly _x: number

	private readonly _y: number

	constructor(x: number, y: number) {
		this._x = x
		this._y = y
	}

	get x(): number {
		return this._x
	}

	get y(): number {
		return this._y
	}

	public equals({ x, y }: Point): boolean {
		return this.x === x && this.y === y
	}

	public calcDeltaXTo({ x }: Point): number {
		return this.x - x
	}

	public calcDeltaYTo({ y }: Point): number {
		return this.y - y
	}

	public calcHorizontalDistanceTo(point: Point): number {
		return Math.abs(this.calcDeltaXTo(point))
	}

	public calcVerticalDistance(point: Point): number {
		return Math.abs(this.calcDeltaYTo(point))
	}

	public calcDistanceTo(point: Point): number {
		return Math.sqrt(Math.pow(this.calcDeltaXTo(point), 2) + Math.pow(this.calcDeltaYTo(point), 2))
	}

	public calcDistanceToRect(rect: Rectangle): number {
		// Calculate the closest x and y coordinates from the point to the rectangle
		const closestX = Math.max(rect.x, Math.min(this.x, rect.x + rect.width));
		const closestY = Math.max(rect.y, Math.min(this.y, rect.y + rect.height));

		// Calculate the distance from the point to this closest point
		const deltaX = this.x - closestX;
		const deltaY = this.y - closestY;

		// If the point is inside the rectangle, return 0
		if (deltaX === 0 && deltaY === 0) {
			return 0;
		}

		// Otherwise, return the distance to the closest point on the rectangle
		return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
	}
}

export type Rectangle = {
	x: number;
	y: number;
	width: number;
	height: number;
};

export function isPoint(x: unknown): x is Point {
	return x instanceof Point
}
