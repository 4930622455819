

import { LexicalTypeLineNodeLevel } from '@clepside/clepsidejs/cards/lexical/transferTypes'
import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
// import { LineNodeLevel } from '@comps/complex/cards/lexicalEditor.lineNode';
import { ULID } from '../commonTypes'
import { FragmentCompletablePacketObject, FragmentPlainTextPacketObject, FragmentUploadablePacketObject } from './sync.tsx.packets.types'

export type FragmentCompletableData = NonNullable<Required<FragmentCompletablePacketObject['data']>> & { id: ULID }
export type FragmentPlainTextData = NonNullable<Required<FragmentPlainTextPacketObject['data']>> & { id: ULID }
export type FragmentUploadableData = NonNullable<Required<FragmentUploadablePacketObject['data']>> & { id: ULID }


export type LexicalEditorJSONString = string
export type FragmentPlainText_Note_Specifics = {
	type: 'NOTE',
	text: LexicalEditorJSONString,
	// todo
	level: LexicalTypeLineNodeLevel
}
export type FragmentPlainText_Link_Specifics = {
	type: 'LINK',
	link: string,
}
export type FragmentPlainText_Codebox_Specifics = {
	type: 'CODEBOX',
	code: string,
}
export type FragmentPlainTextSpecificsType = FragmentPlainText_Note_Specifics['type'] | FragmentPlainText_Link_Specifics['type'] | FragmentPlainText_Codebox_Specifics['type']
export type FragmentPlainTextSpecifics = FragmentPlainText_Note_Specifics | FragmentPlainText_Link_Specifics | FragmentPlainText_Codebox_Specifics

export type FragmentCompletableNormalisedData = Omit<FragmentCompletableData, 'title'> & { title: LexicalEditorJSONString, lastModifiedAt: number, type: PacketType.FRAGMENT_COMPLETABLE,  clientOnlyLastUpdateAt: number }
export type FragmentUploadableNormalisedData = Omit<FragmentUploadableData, 'fileInfo'> & { fileId: string, fileType: string, lastModifiedAt: number, type: PacketType.FRAGMENT_UPLOADABLE,  clientOnlyLastUpdateAt: number }
export type FragmentPlainTextNormalisedData = Omit<FragmentPlainTextData, 'contents'> & { specifics: FragmentPlainTextSpecifics, lastModifiedAt: number, type: PacketType.FRAGMENT_PLAIN_TEXT,  clientOnlyLastUpdateAt: number }

export type GenericFragmentData = FragmentCompletableData | FragmentPlainTextData
export type GenericFragmentNormalisedData = FragmentCompletableNormalisedData | FragmentPlainTextNormalisedData | FragmentUploadableNormalisedData

interface FragmentsState {
	byActivity: {
		[activityId: ULID]: ULID[]
	}
	byCard: {
		[cardId: ULID]: ULID[]
	}
	at: {
		[fragmentId: ULID]: GenericFragmentNormalisedData
	}
}

export const fragmentsState: FragmentsState = {
	byActivity: {},
	byCard: {},
	at: {}
}

export type FragmentID = string