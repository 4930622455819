import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { ActivityHeader } from '@comps/complex/boards/activityHeader'
import { CardGrid } from '@comps/complex/cards/cardGrid'
import { DelayCardGrid } from '@comps/complex/cards/cardGrid.delayCardGrid'
import { Spacer } from '@comps/layout/space'
import { KeyScope } from '@comps/logical/keyScope'
import { DARK_TRANSLUCENCY } from '@root/brand/css'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'

export const ActivityIDCards = () => {
	const { id: activityId } = useParams()
	// const holder = useRef<any>()
	const dispatch = useDispatch()

	// useEffect(() => {
	//     if (!activityId)
	//         return
	//     const callback = () => {
	//         dispatch(interfaceActions.setScope({
	//             scope: 'Activity.<ID>.Cards',
	//             ids: [activityId]
	//         }))
	//     }

	//     const cucu = holder.current
	//     cucu.addEventListener('focusout', callback)
	//     return () => {
	//         cucu.removeEventListener('focusout', callback)
	//     }
	// }, [activityId, dispatch])

	if (!activityId) {
		throw new Error('Something went wrong with the activity id')
	}
	return (
		<Holder className="card-grid-scroll grid-behaviour-container">
			<KeyScope scope="Activities.<0>.CardStack" />
			<Header>
				<Spacer size={20} />
				<ActivityHeader activityId={activityId} />
			</Header>
			<DelayCardGrid>
				<CardGrid resourceId={activityId} resourceType={GrantableResourceEnum.ACTIVITY} marginTop="50px" />
			</DelayCardGrid>
		</Holder>
	)
	// </div>
}

const Header = styled.div`
	width: calc(100% + 0px);
	padding: 15px;
	padding-left: 25px;
	box-sizing: border-box;
	position: sticky;
	top: 0px;
	border-radius: 20px;
	${DARK_TRANSLUCENCY};
	${(p) => p.theme.backgrounds.darkTranslucency.r.css('background-color')};
	z-index: 10000;
	box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.075), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
`

const Holder = styled.div`
	width: calc(100%);
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 960px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	padding: 100px;
	box-sizing: border-box;
	min-height: 100%;
	padding-top: 20px;
`
