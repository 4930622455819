import React from 'react';
import styled, { keyframes } from 'styled-components';

export const Bouncer: React.FC<{ size?: number }> = ({ size = 36 }) => {
    return <Spinner size={size}>
        <Circle className="circle" />
        <Circle className="circle delayed"/>
    </Spinner>
}

const Animate = keyframes`
    0%, 100% { 
      transform: scale(0.45);
      opacity: 1.0;
    } 50% { 
      transform: scale(1.0);
      opacity: 0.33;
    }
`

const Circle = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    
    position: absolute;
    top: 0;
    left: 0;
    
    animation: ${Animate} 4.0s infinite cubic-bezier(.44,-0.01,.13,.97);
`

const Spinner = styled.div<{ size?: number }>`
    width: ${p => p.size}px;
    height: ${p => p.size}px;
  
    position: relative;
    margin: 100px auto;

    .circle {
        /* box-shadow: inset 0px 0px 0px ${p => (p.size || 0) / 6}px ${ p => p.theme.backgrounds.clepside.r.hex }; */
        background-color: ${ p => p.theme.backgrounds.clepside.r.hex };
    }

    .delayed {
        -webkit-animation-delay: -2.0s;
        animation-delay: -2.0s;
    }
`