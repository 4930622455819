import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { Button } from '@comps/interactive/button';
import { Flex } from '@comps/layout/flex';
import { Spacer } from '@comps/layout/space';
import { ADCMIcon } from '@comps/static/adcmIcon';
import { Text } from '@comps/typography/text';
import { useArrowAddCard } from '@root/hooks/useArrowAddCard';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { CSSProperties } from 'styled-components';

export const AddCardMultiButton: React.FC<{
	style?: CSSProperties
	resourceId: string
	resourceType: GrantableResourceEnum
	color?: any
}> = ({ style, resourceId, resourceType, color = 'darkTranslucentElevated' }) => {
	const [showDropdown, setShowDropdown] = useState(false)
	const [anchor, setAnchor] = useState<any>(null)
	const dispatch = useDispatch()

	// const shoot = useArrowShooter('addCard', useMemo(() => {
	//     return { resourceId, resourceType: GrantableResourceEnum.ACTIVITY }
	// },  [activityId]))
	const { action } = useArrowAddCard(resourceId, resourceType)

	const createCard = useCallback(
		(cardType: CardType) => {
			if (!action) return
			dispatch(action(cardType))
		},
		[dispatch, action]
	)

	const items = useMemo<
		Array<
			| {
				icon: string
				label: string
				type: CardType
			}
			| { space: true }
		>
	>(() => {
		return [
			{
				icon: 'N',
				label: 'Note',
				type: CardType.NOTE,
			},
			{
				icon: 'T',
				label: 'Tasks',
				type: CardType.TODO,
			},
			{
				icon: 'L',
				label: 'Link',
				type: CardType.LINK,
			},
			// {
			// 	icon: 'I',
			// 	label: 'Image',
			// 	type: CardType.LINK,
			// },
			// {
			// 	icon: 'C',
			// 	label: 'Code',
			// 	type: CardType.CODEBOX,
			// },
			// {
			// 	space: true,
			// },
			// {
			// 	icon: 'A',
			// 	label: 'AI Chat',
			// 	type: CardType.AI_CHAT,
			// },
			// {
			// 	icon: 'A',
			// 	label: 'AI Gist',
			// 	type: CardType.AI_GIST,
			// },
			// {
			// 	icon: 'I',
			// 	label: 'Image',
			// 	type: CardType.LINK,
			// },
		]
		// }, [dispatch, planNewCard, boardId])
	}, [])

	const list = useMemo(() => {
		return items.map((i) => {
			if ('space' in i) return <Sep key={`space${i}`} />
			return (
				<React.Fragment key={i.label}>
					<Button
						color={color}
						onClick={() => {
							setShowDropdown(false)
							createCard(i.type)
						}}
						inset="buttonSmall"
					>
						<ADCMIcon type={i.type} />
						<Spacer size={5} />
						<Text noWrap block level="label">
							{i.label}
						</Text>
					</Button>
				</React.Fragment>
			)
		})
	}, [items, createCard, color])

	return (
		<Flex spacing={4} align="stretch">
			{list}
		</Flex>
	)
	// (
	// 	<Holder style={style}>
	// 		<Inset align="center">
	// 		</Inset>
	// 	</Holder>
	// )
}

const Sep = styled.div`
	width: 15px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: '';
		display: block;
		width: 1px;
		height: 16px;
		${(p) => p.theme.text.translucent.s2.css('background-color')};
	}
`

const Holder = styled.div`
	position: relative;
`

const IconHolder = styled.div`
	width: 16px;
	height: 16px;
	display: flex;
	margin: -4px 0;
	margin-right: 8px;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	font-weight: 700;
	text-align: center;
	border-radius: 6px;
	background-color: rgba(255, 255, 255, 0.2);

	&.T {
		background-color: rgba(0, 255, 0, 0.6);
	}

	&.L {
		background-color: rgba(120, 120, 255, 0.5);
	}

	&.N {
		background-color: rgba(255, 0, 0, 0.5);
	}
`
