import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { interfaceActions } from '@root/store/slices/interface';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CardDroppableState from './cardDroppablePlace.timer';

export const CardDroppablePlace: React.FC<{ 
    resourceId: string;
    resourceType: GrantableResourceEnum;
 }> = ({ resourceId, resourceType }) => {
    const dispatch = useDispatch()

    useEffect(() => {
      if (CardDroppableState.timer) clearTimeout(CardDroppableState.timer)
         
      dispatch(interfaceActions.setDroppablePlace({
         resourceId, resourceType
      }))

      return () => {
         CardDroppableState.timer = setTimeout(() => {
            dispatch(interfaceActions.setDroppablePlace(undefined))
         }, 200)
      }
    }, [resourceId, dispatch, resourceType])

    return <></>
}