import { differenceInSeconds } from 'date-fns'
import { useMemo } from 'react'
import { Collection } from '../commonTypes'
import { InstancedSessionData } from '../slices/sessionsTypes'

export const useActivityAnalytics = (sessions: Collection<InstancedSessionData>, period: 'day' | 'week') => {
	return useMemo(() => {
		const total = {
			duration: 0,
		}
		const at: {
			[key: string]: {
				count: number
				duration: number
				percentOfWeek: string
			}
		} = {}
		let all: string[] = []

		sessions.all?.forEach((id) => {
			const session = sessions?.at[id]
			if (!session) return
			total.duration += differenceInSeconds(session?.end, session?.start)
			const duration = at[session.activityId]?.duration
				? at[session.activityId].duration + differenceInSeconds(session?.end, session?.start)
				: differenceInSeconds(session?.end, session?.start)
			if (!all.includes(session.activityId)) all.push(session.activityId)
			at[session.activityId] = {
				count: at[session.activityId]?.count ? at[session.activityId].count + 1 : 1,
				duration: duration,
				percentOfWeek:
					period == 'week'
						? ((duration / (24 * 7 * 3600)) * 100).toFixed(0)
						: ((duration / (24 * 3600)) * 100).toFixed(0),
			}
		})

		all = all.sort((a, b) => (at[a].duration > at[b].duration ? -1 : 1))
		return { insights: { total, at, all } }
	}, [sessions, period])
}
