import { RootState } from '@root/store';
import { flowsActions } from '@root/store/slices/flows';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const KeepParams = ['date']
export const useFlowExit = () => {
	const [qp, setQp] = useSearchParams()
	const dispatch = useDispatch()
	const activeFlows = useSelector((state: RootState) => state.flows.activeFlows)

	const exit = useCallback(() => {
		if (activeFlows.length == 0) {
			const acc: any = {}
			for (const p of KeepParams) {
				const v = qp.get(p)
				if (v) acc[p] = v
			}
			setQp(acc)
		} else {
			const lastFlow = activeFlows[activeFlows.length - 1]
			dispatch(flowsActions.cancelFlow({ id: lastFlow }))
		}
	}, [setQp, activeFlows, qp, dispatch])

	// This prevent the hooks callbacks from refreshing the components and creating i.e. triple activities
	const exitRef = useRef<any>(undefined)
	const memoizedExit = useMemo(() => {
		if (exitRef.current) {
			return exitRef.current
		}
		exitRef.current = exit
		return exit
	}, [exit])

	return memoizedExit
}
