

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	DraggingContext, DroppablePlacePayload, interfaceState, ScheduleRoutinePayload, ViewID
} from './interface.types';

const interfaceSlice = createSlice({
	name: 'interface',
	initialState: interfaceState,
	reducers: {
		showKeyTips(state) {
			state.showKeytips = true
		},
		hideKeyTips(state) {
			state.showKeytips = false
		},
		showScheduleRoutineModal(state, { payload }: PayloadAction<{ routine: ScheduleRoutinePayload }>) {
			state.showScheduleForRoutine = payload.routine
		},
		hideScheduleRoutineModal(state, { payload }: PayloadAction) {
			state.showScheduleForRoutine = undefined
		},
		setDroppablePlace(state, { payload }: PayloadAction<DroppablePlacePayload | undefined>) {
			state.droppablePlace = payload
		},
		setScope: (state, { payload }: PayloadAction<{ scope: ViewID, ids?: string[] }>) => {
			const times = payload.scope.split('<ID>').length - 1
			const arr = payload.ids || []
			
			if (times !== arr.length) {
				throw new Error('Malformated viewID')
			}

			let formattedScope = payload.scope as string
			for (let i = 0; i < times; i++) {
				formattedScope = formattedScope.replace('<ID>', arr[i])
			}

			state.scope = payload.scope
			state.scopeWithIds = formattedScope
		},
		startDragging: (state, { payload }: PayloadAction<{ context?: DraggingContext }>) => {
			state.dragging = payload.context
		},
		stopDragging: (state) => {
			state.dragging = undefined
		},
		needsEmailActivation: (state, { payload }: PayloadAction<{ email?: string }>) => {
			state.showEmailActivation = payload.email
		}
	},
})

export const interfaceActions = interfaceSlice.actions
export const interfaceReducers = interfaceSlice.reducer
