

import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb';;
import {
    ContinueChatRequest, CreateNewChatRequest, CreateNewGistRequest
} from '@clepside/clepsidejs/lib/services/gpt_v1_pb';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Database } from '@root/database';
import { GPTGRPC } from '@root/grpc/grpcGPT';
import { collectionInsert } from '@root/utils/general';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

import { RootState } from '../';
import { InitialAIChatCardState } from './aiChatCard.types';
import { CardChatMessageRuneObject, CardChatRuneObject } from './runes.types';

const aiChatCardSlice = createSlice({
	name: 'aiChatCard',
	initialState: InitialAIChatCardState,
	reducers: {
		createNewChat: (state, { payload }: PayloadAction<{ passContext: boolean, cardId: string, query: string }>) => {},
		continueChat: (state, { payload }: PayloadAction<{ chatId: string, cardId: string, query: string }>) => {},
        loadMessagesAndChats: (state, { payload }: PayloadAction<{ cardId: string }>) => {},
		store: (state, { payload }: PayloadAction<{ objects: CardChatRuneObject[], fromSync?: boolean }>) => {
			payload.objects.forEach(el => {
				const id = el.rune?.id
				const cardId = el.rune?.cardId
				if (!state.atCardId[cardId]) {
					state.atCardId[cardId] = {
						messages: { all: [], at: {} },
						chats: { all: [], at: {} },
						allForChatId: {}
					}
				}

				if (id) {
					collectionInsert(state.atCardId[cardId].chats, id, el.rune)
				}

				state.atCardId[cardId].chats.all = state.atCardId[cardId].chats.all.sort((a, b) => {
					return state.atCardId[cardId].chats.at[a].lastModifiedAt > state.atCardId[cardId].chats.at[b].lastModifiedAt ? -1 : 1
				})
			});
			
		},
		constructGist: (state, { payload }: PayloadAction<{ resourceId: string, prompt: string, cardId: string }>) => {},
		appendResponseBody: (state, { payload }: PayloadAction<{ partialString: string, cardId: string }>) => {
			const messageId = state.atCardId[payload.cardId].streamedMessageId
			if (messageId) {
				const message = state.atCardId?.[payload.cardId]?.messages?.at?.[messageId]
				if (message) {
					message.body += payload.partialString
				}
			}
		},
		markMessageAsBeingStreamed: (state, { payload }: PayloadAction<{ messageId: string, cardId: string }>) => {
			if (state.atCardId[payload.cardId]) {
				state.atCardId[payload.cardId].streamedMessageId = payload.messageId
			}
		},
		unmarkMessageAsBeingStreamed: (state, { payload }: PayloadAction<{ cardId: string }>) => {
			if (state.atCardId[payload.cardId]) {
				delete state.atCardId[payload.cardId].streamedMessageId
			}
		},
        storeMessages: (state, { payload }: PayloadAction<{ objects: CardChatMessageRuneObject[], fromSync?: boolean }>) => {
			payload.objects.forEach(el => {
				const { id, cardId, chatId } = el.rune ?? {};
		
				if (!cardId) return; // Ignore if no cardId available
		
				// Ensure necessary structure exists
				if (!state.atCardId[cardId]) {
					state.atCardId[cardId] = {
						messages: { all: [], at: {} },
						chats: { all: [], at: {} },
						allForChatId: {}
					};
				}
		
				const cardData = state.atCardId[cardId];
		
				// Process messages
				if (id && el.rune) {
					collectionInsert(cardData.messages, id, el.rune);
		
					// Process chats
					if (chatId) {
						if (!cardData.allForChatId[chatId]) {
							cardData.allForChatId[chatId] = [];
						}
		
						if (!cardData.allForChatId[chatId].includes(id)) {
							cardData.allForChatId[chatId].push(id);
		
							// Sort by lastModifiedAt
							cardData.allForChatId[chatId] = cardData.allForChatId[chatId].sort(
								(a, b) => 
									cardData.messages.at[b]?.startedAt < cardData.messages.at[a]?.startedAt ? 1 : -1
							);
						}
					}
				}
			});

			
		},
	},
})

// export function* processGPTResponseMessage(
// 	response: StreamedGPTChatResponse | typeof STREAM_ENDED,
// 	cardId: string
// ): any {
// 	if (response == STREAM_ENDED) {
// 		yield put(aiChatCardActions.unmarkMessageAsBeingStreamed({ cardId }))
// 		return
// 	}
// 	if (!response) return

// 	console.log(response)
// 	const object = response.toObject?.()
// 	if (!object) return
//     const token = object
	
// 	const message  = token.sendable?.rune?.cardChatMessage
// 	if (message?.id && message.rune?.startedAt && !message.rune?.completedAt) {
// 		yield put(aiChatCardActions.markMessageAsBeingStreamed({ cardId, messageId: message.rune.id }))
// 	}

// 	if (token.partialTextUpdate) {
// 		yield put(aiChatCardActions.appendResponseBody({ cardId, partialString: token.partialTextUpdate }))
// 	}

// 	yield ReceiveSendable(token.sendable)
// }


export const aiChatCardSagas = {
	*continueChat({ payload }: ReturnType<typeof aiChatCardSlice.actions.continueChat>): any {
        const req = new ContinueChatRequest()
        
        req.setQuery(payload.query)
        req.setChatId(payload.chatId)
        
        try {
            yield GPTGRPC.continueChat(req)
            // const receiver = function* (response: StreamedGPTChatResponse | typeof STREAM_ENDED) {
            //     yield call(processGPTResponseMessage, response, payload.cardId)
            // }
			// yield takeEvery(channel, receiver)
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        }
    },
	*constructGist({ payload }: ReturnType<typeof aiChatCardSlice.actions.constructGist>): any {
		const cards = yield* select((state: RootState) => state.cards)
		const fragments = yield* select((state: RootState) => state.fragments)
		const relevantCards = cards.forResourceId?.[payload.resourceId].map?.((id: string) => cards.atId[id])
		let context = ''

		for (const card of relevantCards) {
			if (!card) continue
			if (card.cardType == CardType.NOTE) {
				context += 'NOTE CARD:\n'	
				context += card.markdownForCard
				context += '\n'	
			}
			if (card.cardType == CardType.CODEBOX) {
				const frag = fragments.at[fragments.byCard[card.id][0]]
				if (frag.type != PacketType.FRAGMENT_PLAIN_TEXT) continue
				if (frag.specifics.type != 'CODEBOX') continue

				context += 'CODE CARD:\n'	
				context += frag.specifics.code
				context += '\n'	
			}
			if (card.cardType == CardType.TODO) {
				context += 'TASKS CARD:\n'	
				context += card.markdownForCard
				context += '\n'	
			}
			if (card.cardType == CardType.LINK) {
				const frag = fragments.at[fragments.byCard[card.id][0]]
				if (frag.type != PacketType.FRAGMENT_PLAIN_TEXT) continue
				if (frag.specifics.type != 'LINK') continue
				
				context += 'LINK CARD:\n'	
				context += frag.specifics.link.replace('https://', '').replace('http://', '').split('/')[0]
				context += '\n\n'	
			}
		}
		
		const req = new CreateNewGistRequest()
        
        req.setPrompt(payload.prompt)
		req.setCardId(payload.cardId)
        req.setContext(context)
        
        try {
            const channel = yield GPTGRPC.createNewGist(req)
            // const receiver = function* (response: StreamedGPTChatResponse | typeof STREAM_ENDED) {
            //     yield call(creantenew, response, payload.cardId)
            // }
			// yield takeEvery(channel, receiver)
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        }
	},
	*createNewChat({ payload }: ReturnType<typeof aiChatCardSlice.actions.createNewChat>): any {
        const req = new CreateNewChatRequest()
        
        req.setQuery(payload.query)
        req.setCardId(payload.cardId)
        
        try {
            const channel = yield GPTGRPC.createNewChat(req)
            // const receiver = function* (response: StreamedGPTChatResponse | typeof STREAM_ENDED) {
            //     yield call(processGPTResponseMessage, response, payload.cardId)
            // }
			// yield takeEvery(channel, receiver)
        } catch (e) {
            console.log('Failed to start sync channel: ', e)
        }
    },
	*loadMessagesAndChats({ payload }: ReturnType<typeof aiChatCardSlice.actions.loadMessagesAndChats>): any {
		const cardChats: CardChatRuneObject[] = yield Database.aiCardChatsRunes.where('rune.cardId').equals(payload.cardId).toArray()
		const cardChatsMessages: CardChatMessageRuneObject[] = yield Database.aiCardChatsMessagesRunes.where('rune.cardId').equals(payload.cardId).toArray()
		// Shouldn't these be merged with the updates pending in the QUEUE???????????

		yield put(aiChatCardActions.store({ objects: cardChats }))
		yield put(aiChatCardActions.storeMessages({ objects: cardChatsMessages }))

	}
}

export const aiChatCardActions = aiChatCardSlice.actions
export const aiChatCardReducers = aiChatCardSlice.reducer
