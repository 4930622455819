import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { useCard } from '@root/store/selectors/useCards'
import { GrantableResourceType } from '@root/store/slices/cards.types'
import { lazy, Suspense, useMemo } from 'react'
import styled from 'styled-components'

const CardLexical = lazy(() => import('./card.lexical'))
// const CardTodos = lazy(() => import('./card.todos'))
// const CardCodebox = lazy(() => import('./card.codebox'))
const CardAIGist = lazy(() => import('./card.aiGist'))
const CardLink = lazy(() => import('./card.link'))
const CardImage = lazy(() => import('./card.image'))
const CardLinkList = lazy(() => import('./card.linkList'))
const CardAIChat = lazy(() => import('./card.aiChat'))

export const Card: React.FC<{ cardId: string; resourceId: string; resourceType: GrantableResourceType }> = ({ cardId, resourceId, resourceType }) => {
	const card = useCard(cardId)

	const cardContent = useMemo(() => {
		switch (card?.cardType) {
			case CardType.NOTE:
				return <CardLexical cardId={cardId} resourceId={resourceId} resourceType={resourceType} />
			case CardType.TODO:
				return <CardLexical cardId={cardId} resourceId={resourceId} resourceType={resourceType} />
			case CardType.LINK_LIST:
				return <CardLinkList cardId={cardId} resourceId={resourceId} resourceType={resourceType} />
			case CardType.LINK:
				return <CardLink cardId={cardId} resourceId={resourceId} resourceType={resourceType} />
			case CardType.IMAGES:
				return <CardImage cardId={cardId} resourceId={resourceId} resourceType={resourceType} />
			case CardType.AI_CHAT:
				return <CardAIChat cardId={cardId} resourceId={resourceId} resourceType={resourceType} />
			case CardType.AI_GIST:
				return <CardAIGist cardId={cardId} resourceId={resourceId} resourceType={resourceType} />
			case CardType.CODEBOX:
				return <></>
			// return <CardCodebox cardId={cardId} resourceId={resourceId} resourceType={resourceType} />
		}
	}, [card, cardId, resourceId, resourceType])

	return <Suspense>{cardContent}</Suspense>
}
