import { Themes } from '@brand/colors'
import { ThemeProvider } from 'styled-components'

export const SmartThemeProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
	const theme = Themes.light

	return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
