import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../../../src/styled';

export const Separator: React.FC<{
	expand?: boolean
	stretch?: boolean
	size?: number
	margin?: number
	sideMarginLeft?: number
	sideMargin?: number
	hide?: boolean
	vertical?: boolean
	color?: Colors['border']
}> = ({ size, margin, sideMargin, sideMarginLeft, expand, vertical, stretch, hide, color = 'subtle' }) => {
	const sizedMemo = useMemo(() => {
		let h = size || 1
		let w = size || 1
		if (vertical) {
			w = 1
		} else {
			h = 1
		}
		return { w, h }
	}, [size, vertical])

	return (
		<S
			expand={expand}
			hide={hide}
			stretch={stretch}
			sideMargin={sideMargin}
			sideMarginLeft={sideMarginLeft}
			size={sizedMemo}
			vertical={vertical}
			margin={margin}
			color={color}
		></S>
	)
}

const S = styled.div<{
	expand?: boolean
	stretch?: boolean
	hide?: boolean
	size: { h: number; w: number }
	sideMarginLeft?: number
	vertical?: boolean
	margin?: number
	sideMargin?: number
	color: Colors['border']
}>`
	${(p) =>
		p.size &&
		css`
			width: ${p.size.w}px;
			height: ${p.size.h}px;
		`}
	${(p) =>
		p.expand &&
		css`
			${p.vertical
				? css`
						height: 100%;
						width: 1px;
				  `
				: css`
						width: 100%;
						height: 1px;
				  `};
		`}
	
	${(p) => p.theme.border[p.color].r.css('background-color')};

	margin: ${(p) => (p.vertical ? 0 : `${p.margin}px`)} ${(p) => (p.vertical ? `${p.margin}px` : 0)};
	${(p) =>
		p.sideMargin &&
		css`
			${p.vertical
				? css`
						margin-top: ${p.sideMargin}px;
						margin-bottom: ${p.sideMargin}px;
				  `
				: css`
						margin-left: ${p.sideMargin}px;
						margin-right: ${p.sideMargin}px;
				  `};
			${p.expand &&
			css`
				${p.vertical
					? css`
							height: calc(100% - ${p.sideMargin * 2}px);
					  `
					: css`
							width: calc(100% - ${p.sideMargin * 2}px);
					  `};
			`}
		`}

	${(p) =>
		p.hide &&
		css`
			opacity: 0;
		`}

	${(p) =>
		p.sideMarginLeft
			? css`
					margin-left: ${p.sideMarginLeft}px;
					width: calc(100% - ${p.sideMarginLeft}px);
			  `
			: css``};
	${(p) =>
		p.stretch
			? css`
					align-self: stretch;
			  `
			: css``};
`
