import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb';
import {
	SessionPacketData as ClepsideJSSessionPacketData, SessionPacketRoutineLink
} from '@clepside/clepsidejs/lib/packets_v1/sessions_pb';

import { UNIXTime } from '../commonTypes';
import { NewPacketDelta } from './persist.utils';

export type DayIndex = number
export type SessionData = Omit<Required<ClepsideJSSessionPacketData.AsObject>, 'routine'> & { routine?: SessionPacketRoutineLink.AsObject }
export type SessionDataSeed = NewPacketDelta<PacketType.SESSION>
export type SessionDataBlankSeed = {
	start: Date,
	end: Date,
	activityId?: string
}

export type NormalisedSessionData = SessionData & {
	id: string
	daySpan: DayIndex[] //YYYYMMDD
}

export type InstancedSessionData = Omit<NormalisedSessionData, 'start' | 'end'> & { start: Date; end: Date }



export type SessionDisplayConditions = {
	activityId?: string
	start?: UNIXTime
	end?: UNIXTime
	reverseChronological?: boolean
	limit?: number
	inclusive?: SessionsDisplayInclusiveness
}

export type SessionsDisplayScope = {
	key: string
	id?: string
	between?: SessionDisplayConditions
}

export type SessionsDisplayScopeKeyTemplate = 'this-week' | 'today' | 'for-date-<0>' | 'for-week-<0>' | 'for-activity-<0>-year-<1>' | 'single-<0>'
export type SessionsDisplayInclusiveness = 'both' | 'start' | 'end'
export interface SessionsState {
	at: {
		[id: string]: NormalisedSessionData
	},
	forScope: {
		[scope: string]: string[]
	}
	activeScopes: {
		[key: string]: SessionsDisplayScope
	}
}

export const sessionsState: SessionsState = {
	at: {},
	forScope: {},
	activeScopes: {}
}
