import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { interfaceActions } from '@root/store/slices/interface';
import { DraggingContext } from '@root/store/slices/interface.types';

import { DragDropCore } from './dragDropCore';

export const useDragAndDropContextMount = () => {
    const dispatch = useDispatch()
    const onGlobalDragStart = useCallback((context?: DraggingContext) => {
        dispatch(interfaceActions.startDragging({ context }))
    }, [dispatch])

    const onGlobalDragEnd = useCallback(() => {
        dispatch(interfaceActions.stopDragging())
    }, [dispatch])

    useEffect(() => {
        DragDropCore.registerGlobalCallbacks(onGlobalDragStart, onGlobalDragEnd)
    }, [onGlobalDragStart, onGlobalDragEnd])
}