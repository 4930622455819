/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Logger } from '@root/cores/logger'
import { Database } from '@root/database'
import { NewID } from '@root/utils/general'
import { all, delay, put, select } from 'redux-saga/effects'
import { RootState } from '../'
import { imageProcessingActions } from './imageProcessing'
import { sendPacketObjectFromQueue } from './persist.sendPacket'
import { mergePacketsToLocalTransaction } from './persist.transactionMergeLocal'
import { State } from './persist.types'
import { getDeltaConfigForPacketType, NewPacketDelta, PacketFieldsDeltaConfig, RemovePacketDelta, UpdatePacketDelta } from './persist.utils'
import { getIdFromLocalQueueObject, LocalQueueObject, PacketObject } from './sync.tsx.packets.types'
import { PacketTypeToSyncable, ValidPacketType } from './sync.types'

//U extends  | UpdatePacketDelta<K> | RemovePacketDelta 
const persistSlice = createSlice({
	name: 'persist',
	initialState: State,
	reducers: {
        pendingPackets: (state, { payload }: PayloadAction) => {}
	},
})

export const persistSagas = {
    // sends queued packets to the server
	*pendingPackets({ payload }: ReturnType<typeof persistSlice.actions.pendingPackets>): any {
        let count = yield Database.queue.count()

        while (count > 0) {
            const packetsToSend: LocalQueueObject[] = []

            const insertPacket = (packet: LocalQueueObject) => {
                packetsToSend.push(packet)
            }

            // +clientOnlyGrantingResourceId
            yield Database.queue.orderBy('clientOnlySyncOrder').limit(80).each(packet => {
                if (packet.clientOnlySendAfter && packet.clientOnlyEnqueuedAt) {
                    if (new Date().getTime() >= packet.clientOnlyEnqueuedAt + packet.clientOnlySendAfter) {
                        insertPacket(packet)
                    }
                } else {
                    insertPacket(packet)
                    
                }
            })

            if (packetsToSend.length > 0) {
                Logger.persist.info('Sending ', packetsToSend.length, ' packets.')
                const results = yield sendPacketObjectFromQueue(packetsToSend as any)
                // const results = yield sendPacketObjectFromQueue(packetsToSend as any)
                const { success, error } = results
                
                if (!success) {
                    console.error('Failed to send packet, postponing', error)
                    for (const packetSent of packetsToSend) {
                        yield Database.transaction('rw', [Database.queue], async () => {
                            const packet = await Database.queue.get(getIdFromLocalQueueObject(packetSent)) as any
                            await Database.queue.put({
                                ...packet,
                                clientOnlyEnqueuedAt: new Date().getTime(),
                                // Modified for lesser during dev
                                clientOnlySendAfter: 120000,
                                // clientOnlySendAfter: 15000,
                            }, getIdFromLocalQueueObject(packetSent))
                        })
                    }
                }
            }
            
            yield delay(100)
            count = yield Database.queue.count()
        }

        yield put(imageProcessingActions.processImages())
	}
}

export type DeltaSpecialOperation = 'CARD_MOVEMENT'

// Delta Creators:
export function* deltaCreate<K extends ValidPacketType>({ type, delta, id }: { type: K, delta: NewPacketDelta<K>, id?: string }) {
    const packetObject = yield* buildPacketDataDelta('new', type, id || NewID(), delta, false)
    yield persistPacketObject([packetObject], type)
}

export function* deltaUpdate<K extends ValidPacketType>({ type, delta }: { type: K, delta: UpdatePacketDelta<K> }) {
    const packetObject = yield* buildPacketDataDelta('update', type, delta.id, delta, false)
    yield persistPacketObject([packetObject], type)
}
export function* deltaDelete<K extends ValidPacketType>({ type, delta }: { type: K, delta: RemovePacketDelta }) {
    const packetObject = yield* buildPacketDataDelta('delete', type, delta.id, undefined, delta.shouldDelete)
    console.log(packetObject)
    yield persistPacketObject([packetObject], type)
}

// Batch Delta Creators:
export function* deltaBatchCreate<K extends ValidPacketType>({ type, deltas, id }: { type: K, deltas: Array<NewPacketDelta<K>>, id?: string }) {
    const batch: any = []
    for (const delta of deltas) {
        batch.push(buildPacketDataDelta('new', type, id || NewID(), delta, false))
    }
    const packets: Array<PacketObject<K>> = yield all(batch)
    yield persistPacketObject(packets, type)
}
export function* deltaBatchUpdate<K extends ValidPacketType>({ type, deltas, specialOperation }: { type: K, deltas: Array<UpdatePacketDelta<K>>, specialOperation?: DeltaSpecialOperation }) {
    const batch: any = []
    for (const delta of deltas) {
        batch.push(buildPacketDataDelta('update', type, delta.id, delta, false, specialOperation))
    }
    const packets: Array<PacketObject<K>> = yield all(batch)
    
    yield persistPacketObject(packets, type)
}
export function* deltaBatchDelete<K extends ValidPacketType>({ type, deltas }: { type: K, deltas: Array<RemovePacketDelta> }) {
    const batch: any = []
    for (const delta of deltas) {
        batch.push(buildPacketDataDelta('delete', type, delta.id, undefined, delta.shouldDelete))
    }
    const packets: Array<PacketObject<K>> = yield all(batch)
    yield persistPacketObject(packets, type)
}

// Persist function
function* persistPacketObject<K extends ValidPacketType>(packetObjects: Array<PacketObject<K>>, type: K): any {
    const config = PacketTypeToSyncable[type]
    const packets = yield mergePacketsToLocalTransaction(packetObjects, config)
    yield put(config.placeInStore(packets))
    yield put(persistActions.pendingPackets())
    yield put(imageProcessingActions.processImages())
}

function* buildPacketDataDelta<T extends keyof typeof PacketFieldsDeltaConfig>(operation: 'update' | 'new' | 'delete', type: T, id: string, dataDelta?:  NewPacketDelta<T> | UpdatePacketDelta<T>, isDeleted?: boolean, specialOperation?: DeltaSpecialOperation) {
    const { uid } = yield select((state: RootState) => state.auth.user)

    if (!uid) {
        throw new Error('could not build packet, uid not found')
    }

    const deltaPacket: any = {
        id,
        data: {} as any,
        meta: {} as any,
        type,
        lastModifiedAt: -1
    }
    const config = getDeltaConfigForPacketType<T>(type)

    // if ('skipDeleted' in config && config.skipDeleted !== true) {
        
    // }
    if (isDeleted) {
        deltaPacket.deleted = {
            is: true,
            at: Date.now(),
            by: uid,
        }
    }

    // Set for create an update only
    if (dataDelta) {
        if ('withMeta' in config && config.withMeta) {
            for (const field of config.withMeta as any[]) {
                if ((dataDelta as any)[field] !== undefined) {
                    deltaPacket.data[field] = (dataDelta as any)[field]
                    deltaPacket.meta[field] = {
                        at: Date.now(),
                        by: uid,
                    }
                }
            }
        }

        if ('skipMeta' in config && config.skipMeta) {
            for (const field of config.skipMeta as any[]) {
                if ((dataDelta as any)[field] !== undefined) {
                    deltaPacket.data[field] = (dataDelta as any)[field]
                }
            }
        }
    }

    return deltaPacket as PacketObject<T>
}

export const persistActions = persistSlice.actions
export const persistReducers = persistSlice.reducer