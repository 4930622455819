import { formattedDuration, getUTCTimeFromUnix } from '@root/utils/dates'
import { addDays, format } from 'date-fns'
import { CSSProperties } from 'react'
import { NormalisedRoutineScheduleData } from './routineSchedules.types'

export function getScheduleDescription(period: NormalisedRoutineScheduleData['period'], time: NormalisedRoutineScheduleData['time']): {
    text: string,
    style: CSSProperties
}[] {
  const regularStyle = { fontWeight: 'normal', color: '#6c757d', fontSize: '14px' };
  const boldStyle = { fontWeight: 'bold', color: '#000000', fontSize: '14px' };

  if (period == undefined || time == undefined) return []

  const desc: any = [];

  function addPart(text: string, style: CSSProperties) {
    desc.push({ text, style });
  }

  function formattedWithColon(dur: number) {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const newDate = addDays(date, dur / (60 * 24));
    return format(newDate, 'HH:mm');
  }


  if (period.daily !== undefined) {
      addPart('Repeats ', regularStyle);
      addPart(`every ${period.daily.repeatsEvery === 1 ? 'day' : `${period.daily.repeatsEvery} days`}`, boldStyle);
    }
    if (period.weekly !== undefined) {
        addPart('Repeats ', regularStyle);
        addPart(`every ${period.weekly.repeatsEvery === 1 ? 'week' : `${period.weekly.repeatsEvery} weeks`}`, boldStyle);
        addPart(' on ', regularStyle);

        // The iOS app handles this through protobuffs
        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        const humanisedWeekdays = period.weekly.onWeekdaysList.map(day => weekdays[day]);
        addPart(humanisedWeekdays.join(', '), boldStyle);
    }
    if (period.monthly !== undefined) {
        addPart('Repeats on the ', regularStyle);
        if (period.monthly.type?.onDates) {
            const dates = period.monthly.type.onDates.onDatesList.map(date => `${date}th`);
            addPart(dates.join(', '), boldStyle);
        }
        if (period.monthly.type?.onWeekdays) {
            const weekdaysStr = period.monthly.type.onWeekdays.weekdayItemList.map(weekday =>
              `${weekday.nth} ${weekday}`
            );
            addPart(weekdaysStr.join(', '), boldStyle);
        }
        addPart(' of every ', regularStyle);
        addPart(`${period.monthly.repeatsEvery === 1 ? 'month' : `${period.monthly.repeatsEvery} months`}`, boldStyle);
    }   

  const interval = time.duration;
  const min = time.minDuration;
  if (time.idealTime !== time.neverAfter || time.neverAfter !== time.neverBefore) {
    addPart(', ideally ', regularStyle);
    addPart(`at ${getUTCTimeFromUnix(time.idealTime)}`, boldStyle);
    addPart(` (wiggle room between ${getUTCTimeFromUnix(time.neverBefore)} → ${getUTCTimeFromUnix(time.neverAfter)})`, regularStyle);
  } else {
    addPart(', at ', regularStyle);
    addPart(`${getUTCTimeFromUnix(time.idealTime)}`, boldStyle);
  }

  if (time.duration !== time.minDuration) {
    addPart(' for ', regularStyle);
    addPart(`${formattedDuration(interval,  false, ['seconds'],  true )}`, boldStyle);
    addPart(` (min ${formattedDuration(min, true,  ['seconds'],  true )})`, regularStyle);
  } else {
    addPart(' for ', regularStyle);
    addPart(`${formattedDuration(interval, false,  ['seconds'], true )}`, boldStyle);
  }
  
  addPart('.', regularStyle);

  return desc;
}