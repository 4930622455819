



import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb'
import { Database, SyncBoardEntity } from '@root/database'
import { mergePackets } from './persist.utils'
import { EnqueueingExtras, getIdFromPacket, PacketObject, StoringExtras } from './sync.tsx.packets.types'
import { SyncablePacket, ValidPacketType } from './sync.types'
import { SyncKey } from './sync.utils'

// const cornerCases = {
// 	// We're doing this because sessions need a subsequent fetching
// 	// there's a composite filter somewhere waiting for it
// 	[reachSyncKeyTemplate('Activities') as SyncKey]: async (packets: PacketObject<PacketType.ACTIVITY>[]) => {
// 		for (const packet of packets) {
// 			const sessionsKey = getSyncKeyFromTemplate('Activities/<0>/Sessions', packet.id)
// 			let storedSessions: any = {}
			
// 			try {
// 				storedSessions = await Database.syncBoard.get(sessionsKey)
// 			} catch (e) {
// 				console.error('Failed to query the Syncboard', e)
// 			}
			
// 			await Database.syncBoard.put({
// 				key: sessionsKey,
// 				template: 'Activities/<0>/Sessions',
// 				at: storedSessions?.at || 0,
// 				status: SyncBoardStatus.NEVER_SYNCED
// 			}, sessionsKey)
// 		}
// 	}
// }

export function* mergePacketsToLocalTransaction<T extends ValidPacketType>(packets: PacketObject<T>[], config: SyncablePacket<any>, key?: SyncKey, skipEnqueueing?: boolean): any {
	const writes: Promise<any>[] = []
	const { database: db } = config

	
	// This is done beforehand, to make sure we'll fetch the sessions for the activity
	// Works like a queue..
	// if (key && cornerCases[key]) {
		// cornerCases[key](packets as any)
	// }

	const enqueueingExtra: { [id: string]: EnqueueingExtras }  = {}
	const storingExtra: { [id: string]: StoringExtras }  = {}

	if (!skipEnqueueing) {
		for (const packet of packets) {
			const id  = getIdFromPacket(packet)

			const isRealtimeFragment = config.type == PacketType.FRAGMENT_PLAIN_TEXT || config.type == PacketType.FRAGMENT_COMPLETABLE
			//config.type == PacketType.FRAGMENT_UPLOADABLE
			// const clientOnlyGrantingResourceId = id
			let clientOnlySendAfter = 0
			let clientOnlySyncOrder = 0

			if (isRealtimeFragment) {
				clientOnlySendAfter = 200
				// const fragmentId = getIdFromPacket(packet)
				// let cardId: string | undefined = (packet?.data as any)?.cardId
				// if (!cardId) {
				// 	const wholeFragment = yield select((state: RootState) => state.fragments.at[fragmentId] )
				// 	cardId = wholeFragment?.cardId
				// }

				// if (!cardId) {
				// 	console.error("couldn't find cardId for fragmentId")
				// 	return
				// }

				// const card = yield select((state: RootState) => state.cards.atId[cardId as string])
				// clientOnlyGrantingResourceId = card?.resourceId
			}

			switch (config.type) {
				case PacketType.VITALS:
					clientOnlySyncOrder = 100
					break;
				case PacketType.ACTIVITY:
					clientOnlySyncOrder = 1000
					break;
				case PacketType.ROUTINE:
				case PacketType.SESSION:
				case PacketType.BOARD:
					clientOnlySyncOrder = 10000
					break;
				case PacketType.ROUTINE_SCHEDULE:
					clientOnlySyncOrder = 10005
					break
				case PacketType.CARD:
					clientOnlySyncOrder = 100000
					break;
				case PacketType.FRAGMENT_COMPLETABLE:
				case PacketType.FRAGMENT_PLAIN_TEXT:
				case PacketType.FRAGMENT_UPLOADABLE:
				case PacketType.PACKETTYPE_DO_NOT_USE:
					clientOnlySyncOrder = 1000000
					break;
				default:
					throw new Error('no sync order foun')
			}
			
			enqueueingExtra[id] = {
				clientOnlySendAfter,
				// clientOnlyGrantingResourceId,
				clientOnlySyncOrder,
				clientOnlyEnqueuedAt: new Date().getTime()
			}
		}
	}


	// // Make searchable
	// for (const packet of packets) {
	// 	const searchTerms = yield getSearchableTermsFromPacketOrRune(packet, undefined)
	// 	storingExtra[packet.id] = {
	// 		clientOnlySearchTerms: searchTerms
	// 	}
	// }

	const realPackets: PacketObject<any> = yield Database.transaction('rw', [
		Database.queue, 
		db, 
		Database.syncBoard,
		...(config.otherDatabaseLocks ? config.otherDatabaseLocks : [])
	], async () => {
		const commitedPackets = []
		const pendingMetaDeltas = []

		for (const packet of packets) {
			const id = getIdFromPacket(packet)

			let localPacket: PacketObject<any> | undefined
			let queuedPacket: PacketObject<any> | undefined
	
			try {
				localPacket = await db.get(id)
				queuedPacket = await Database.queue.get(id)
			} catch (e) {
				console.error(e)
			}
	
			
			// there's a bug in here somewhere... postponed
			let freshPacket = mergePackets(localPacket, queuedPacket)
			freshPacket = mergePackets(freshPacket, packet)

			if (storingExtra[id]) {
				freshPacket = {
					...freshPacket,
					...storingExtra[id]
				}
			}
			
			commitedPackets.push(freshPacket)
			pendingMetaDeltas.push(packet.meta)
		}

			
		for (const [i, packet] of commitedPackets.entries()) {
			const { id } = packet

			await config.inTransaction?.(packet)
			
			if ('prepareForStorage' in config && !!config.prepareForStorage) {
				commitedPackets[i] = config.prepareForStorage(packet)
				await db.put(commitedPackets[i], id)
			} else {
				writes.push(db.put(packet, id))
			}
			
			if (!skipEnqueueing) {
				writes.push(Database.queue.put({
					...packet,
					meta: pendingMetaDeltas[i],
					...enqueueingExtra[id]
				}, id))
			}
		}

		const highestStamp = commitedPackets.reduce((max, obj) => max.lastModifiedAt > obj.lastModifiedAt ? max : obj).lastModifiedAt

		if (key) {
			let sync = undefined
			try {
				sync = await Database.syncBoard.get(key)
			} catch (e) {
				console.error('Failed to read syncBoard', e)
			}

			if (highestStamp > (sync?.at || 0)) {
				writes.push(Database.syncBoard.put({
					...(sync as SyncBoardEntity),
					key,
					at: highestStamp
				}, key))
			}
		}

		try {
			await Promise.all(writes)
		} catch (e) {
			console.error(e)
		}

		return commitedPackets
	})

	yield config.afterPersist?.()

	return realPackets
}