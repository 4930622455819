import { differenceInSeconds, endOfDay } from 'date-fns';

import { Button } from '@comps/interactive/button';
import { NavigationStackLink } from '@comps/interactive/navigationStackLink';
import { CardContainer } from '@comps/layout/cardContainer';
import { Flex } from '@comps/layout/flex';
import { Inset } from '@comps/layout/inset';
import { Spacer } from '@comps/layout/space';
import { ActivityBubble } from '@comps/static/activityBubble';
import { Icon } from '@comps/static/icon';
import { Text } from '@comps/typography/text';
import { useTime } from '@root/hooks/useTime';
import { useActivities } from '@root/store/selectors/useActivities';
import { useSessions } from '@root/store/selectors/useSessions';
import { useSessionsFilter } from '@root/store/selectors/useSessionsFilter';
import { getDuration, getSessionPeriod } from '@root/utils/dates';

export const TodayHasSessionsLeftCard = () => {
	const now = useTime('minute')
	const allSessions = useSessions('this-week')
	const activities = useActivities()
	const sessions = useSessionsFilter(allSessions, now.getTime(), endOfDay(now).getTime())

	if (sessions.all.length == 0) return null
	return (
		<CardContainer translucent="light" ancestor>
			<Inset padding="todayCard" direction="column" spacing={20} align="flex-start">
				<Flex direction="column" spacing={10}>
					<Text level="title10">Later Today</Text>
					<Text level="body">
						{sessions.all.length == 1
							? 'You have only one session left for today.'
							: `You have ${sessions.all.length} sessions left for today.`}
					</Text>
				</Flex>
				<Inset padding="buttonLarge" direction="column" expand align="stretch" negative>
					<Flex align="stretch" direction="column" alignSelf="stretch">
						{sessions.all.map((sId, index) => {
							const session = sessions.at[sId]
							const activity = activities.at[session.activityId]
							if (session && activity)
								return (
									<NavigationStackLink to={`/session/${sId}`}>
										<Button color="lightTranslucent">
											<Inset padding="buttonLarge">
												<Flex direction="row" grow key={sId} align="center">
													<ActivityBubble
														size="medium"
														activity={activity}
														status="inactiveLight"
													/>
													<Spacer size={15} />
													<Flex
														direction="column"
														align="stretch"
														grow
														spacing={5}
													>
														<Text level="body" bold align="left">
															{activity.name}
														</Text>
														<Flex justify="space-between">
															<Text
																level="tiny"
																color="regular"
																block
															>
																{getSessionPeriod(
																	session.start,
																	session.end
																)}
															</Text>
															{index == 0 ? (
																<Text
																	level="tiny"
																	color="subtle"
																	block
																>
																	Starts in{' '}
																	{getDuration(
																		differenceInSeconds(
																			session.start,
																			now
																		),
																		[
																			'seconds',
																		]
																	)}
																</Text>
															) : (
																<></>
															)}
														</Flex>
													</Flex>
													<Spacer size={15} />
													<Text color="subtle">
														<Icon type="arrow" offsetX={3} />
													</Text>
												</Flex>
											</Inset>
										</Button>
									</NavigationStackLink>
								)
						})}
					</Flex>
				</Inset>
				{/* <Separator expand color="translucent" />
				<Link to="/calendar" state={{ backTo: '/today' }}>
					<Button color="accent">
						<Inset padding="buttonSmall" direction="column">
							Plan the rest of the day
						</Inset>
					</Button>
				</Link> */}
				{/* <Link to="/calendar/week" state={{ backTo: '/today' }}>
            <Button color="accent">
                <Inset padding="buttonSmall">Plan</Inset>
            </Button>
        </Link> */}
			</Inset>
		</CardContainer>
	)
}
