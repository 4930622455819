import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Logo } from '@comps/static/logo';

import { Flex } from './flex';
import { Spacer } from './space';

export const SimplePageLogo: React.FC<{ larger?: boolean, noLink?: boolean }> = ({ larger, noLink }) => {
	const theme = useTheme()
	return (
		<Flex direction="column">
			<Spacer size={20} />
			<Flex align="center" justify="flex-end">
				{noLink ? <Logo size={41} color={theme.backgrounds.clepside.r.p3} /> : <Link to="/">
					<Logo size={41} color={theme.backgrounds.clepside.r.p3} />
				</Link>}
			</Flex>
		</Flex>
	)
}
