import { Button } from '@comps/interactive/button'
import { NavigationStackLink } from '@comps/interactive/navigationStackLink'
import { CardContainer } from '@comps/layout/cardContainer'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { Spacer } from '@comps/layout/space'
import { Text } from '@comps/typography/text'
import { useTime } from '@root/hooks/useTime'
import { useSessions } from '@root/store/selectors/useSessions'
import { secondsPerDateInRange } from '@root/utils/dates'
import { addDays, differenceInSeconds, format, startOfWeek } from 'date-fns'
import { useMemo } from 'react'
import { start } from 'repl'
import styled from 'styled-components'

export const TodayPlanWeek = () => {
	const time = useTime('date')
	const sessions = useSessions('this-week')

	const { totalDuration, perWeekday } = useMemo(() => {
		const perDay: any = {}
		const byWeekday: any = {}
		let all = 0

		sessions.all.forEach((sessionId) => {
			const session = sessions.at[sessionId]
			if (session.start > session.end) return

			all += differenceInSeconds(session.end, session.start)
			const dict = secondsPerDateInRange(session.start, session.end)

			for (const [dateKey, val] of Object.entries(dict)) {
				if (!perDay[dateKey]) perDay[dateKey] = 0
				perDay[dateKey] += val
			}
		})

		const sow = startOfWeek(time)

		for (let i = 0; i < 7; i++) {
			const day = addDays(sow, i)
			byWeekday[format(day, 'EE')] = (perDay[day.toISOString()] || 0) / (24 * 3600)
		}

		return {
			totalDuration: all,
			perWeekday: {
				at: byWeekday,
				all: Object.keys(byWeekday),
			},
		}
	}, [sessions, time])

	const weeklyTotal = 7 * (24 * 60 * 60)
	const fraction = ((totalDuration / weeklyTotal) * 100).toFixed(0)

	return (
		<CardContainer translucent="light" ancestor>
			<Inset padding="todayCard" direction="column" align="stretch" expandWidth>
				<Flex justify="space-between">
					<Text level="title10">Your Current Week</Text>
					<NavigationStackLink to="/calendar/week">
						<Button color="clepside">
							<Inset padding="buttonSmall">Plan</Inset>
						</Button>
					</NavigationStackLink>
				</Flex>
				<Spacer size={10} vertical />
				<Text>
					You have <strong>{fraction}% of time</strong> planned for your current week.
					<br />
					Plan your week fully and leave no room for surprises!
				</Text>
				<Spacer size={20} vertical />
				<Bars>
					{perWeekday.all.map((wd) => {
						const procent = perWeekday.at[wd]
						return (
							<Flex direction="column" align="center" key={wd}>
								<Bar>
									<Barfill style={{ height: `${procent * 100}%` }} />
								</Bar>
								<Text level="tiny" color="subtle">
									{wd.substring(0, 1)}
								</Text>
							</Flex>
						)
					})}
				</Bars>
			</Inset>
		</CardContainer>
	)
}

const Bars = styled.div`
	display: grid;
	grid-template-columns: min-content min-content min-content min-content min-content min-content min-content;
	width: 100%;
	grid-column-gap: 20px;
	margin: 5px 0;
`
const Bar = styled.div`
	width: 8px;
	height: 56px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 9px;
	overflow: hidden;
`
const Barfill = styled.div`
	width: 100%;
	${(p) => p.theme.backgrounds.clepside.r.css('background-color')};
`
