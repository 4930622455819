import { BoardIcon } from '@comps/layout/boardIcon'
import { Spacer } from '@comps/layout/space'
import { Icon } from '@comps/static/icon'
import { Text } from '@comps/typography/text'
import { stringTo100Hash } from '@root/grpc/utils'
import { ActivityColorKey } from '@root/store/slices/activities.colors.types'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const BoardButton: React.FC<{ boardName: string; boardId: string; boardColor: ActivityColorKey }> = ({ boardName, boardId, boardColor }) => {
	const hash = useMemo(() => {
		const num = stringTo100Hash(boardId)

		if (num < 25) return 1
		if (num < 50) return 2
		if (num < 75) return 3
		if (num < 100) return 4
		return num
	}, [boardId])

	return (
		<Link to={`/board/${boardId}`}>
			<Holder>
				<Cover>
					<BoardIcon boardName={boardName} boardColor={boardColor} />
					<Shade className={`type-${hash}`} />
				</Cover>
				<Bottom>
					<Text noWrap>{boardName}</Text>
					<Spacer size={10} />
					<Radial>
						<Icon type="diagonalArrow" />
					</Radial>
				</Bottom>
			</Holder>
		</Link>
	)
}

const Shade = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.1;
	border-radius: inherit;
	box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.8);

	&.type-1 {
		background: linear-gradient(15deg, rgba(3, 0, 54, 1) 0%, rgba(0, 0, 0, 0.2) 40%);
	}
	&.type-2 {
		background: linear-gradient(-30deg, rgba(3, 0, 54, 1) 0%, rgba(0, 0, 0, 0.2) 40%);
	}
	&.type-3 {
		background: linear-gradient(40deg, rgba(3, 0, 54, 1) 0%, rgba(0, 0, 0, 0.2) 40%);
	}
	&.type-4 {
		background: linear-gradient(-60deg, rgba(3, 0, 54, 1) 0%, rgba(0, 0, 0, 0.2) 40%);
	}
`

const Radial = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 999px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1px;
	flex-shrink: 0;
	margin-right: -5px;
	${(p) => p.theme.backgrounds.subtleAccent.r.css('background-color')};
`

const Bottom = styled.div`
	padding: 10px 15px;
	${(p) => p.theme.border.subtle.t1.css('border-top', '1px solid ')};
	display: flex;
	justify-content: space-between;
	${(p) => p.theme.text.action.r.css('color')};
`

const Cover = styled.div`
	${(p) => p.theme.backgrounds.subtle.r.css('background-color')};
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
	border-radius: inherit;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
`

const Holder = styled.div`
	width: 160px;
	height: 180px;
	display: flex;
	flex-direction: column;
	${(p) => p.theme.border.subtle.t1.css('border', '1px solid ')};
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.042);
	border-radius: 15px;
	overflow: hidden;
	transition: 0.1s box-shadow;

	&:hover {
		${Radial} {
			${(p) => p.theme.backgrounds.subtleAccent.s2.css('background-color')};
		}
	}

	&:active {
		${Radial} {
			${(p) => p.theme.backgrounds.subtleAccent.s3.css('background-color')};
		}
	}
`
