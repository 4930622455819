// source: entities/sharing_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var entities_access_grant_v1_pb = require('../entities/access_grant_v1_pb.js');
goog.object.extend(proto, entities_access_grant_v1_pb);
goog.exportSymbol('proto.sharing_v1.SharingInvitation', null, global);
goog.exportSymbol('proto.sharing_v1.SharingInvitationState', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sharing_v1.SharingInvitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sharing_v1.SharingInvitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sharing_v1.SharingInvitation.displayName = 'proto.sharing_v1.SharingInvitation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sharing_v1.SharingInvitation.prototype.toObject = function(opt_includeInstance) {
  return proto.sharing_v1.SharingInvitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sharing_v1.SharingInvitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sharing_v1.SharingInvitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromUserId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    forUserId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, 0),
    resourceType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    grantTypeToGrant: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    acceptedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    rejectedAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    revokedAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    revokedBy: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lastModifiedAt: jspb.Message.getFieldWithDefault(msg, 13, 0),
    forEmail: jspb.Message.getFieldWithDefault(msg, 14, ""),
    isCreator: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sharing_v1.SharingInvitation}
 */
proto.sharing_v1.SharingInvitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sharing_v1.SharingInvitation;
  return proto.sharing_v1.SharingInvitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sharing_v1.SharingInvitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sharing_v1.SharingInvitation}
 */
proto.sharing_v1.SharingInvitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setForUserId(value);
      break;
    case 5:
      var value = /** @type {!proto.sharing_v1.SharingInvitationState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {!proto.access_grant_v1.GrantableResourceEnum} */ (reader.readEnum());
      msg.setResourceType(value);
      break;
    case 7:
      var value = /** @type {!proto.access_grant_v1.GrantTypeEnum} */ (reader.readEnum());
      msg.setGrantTypeToGrant(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcceptedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRejectedAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRevokedAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRevokedBy(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModifiedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setForEmail(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sharing_v1.SharingInvitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sharing_v1.SharingInvitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sharing_v1.SharingInvitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sharing_v1.SharingInvitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getForUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getResourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getGrantTypeToGrant();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAcceptedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getRejectedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getRevokedAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getRevokedBy();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLastModifiedAt();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getForEmail();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getIsCreator();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sharing_v1.SharingInvitation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string resource_id = 2;
 * @return {string}
 */
proto.sharing_v1.SharingInvitation.prototype.getResourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string from_user_id = 3;
 * @return {string}
 */
proto.sharing_v1.SharingInvitation.prototype.getFromUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setFromUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string for_user_id = 4;
 * @return {string}
 */
proto.sharing_v1.SharingInvitation.prototype.getForUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setForUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional SharingInvitationState state = 5;
 * @return {!proto.sharing_v1.SharingInvitationState}
 */
proto.sharing_v1.SharingInvitation.prototype.getState = function() {
  return /** @type {!proto.sharing_v1.SharingInvitationState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.sharing_v1.SharingInvitationState} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional access_grant_v1.GrantableResourceEnum resource_type = 6;
 * @return {!proto.access_grant_v1.GrantableResourceEnum}
 */
proto.sharing_v1.SharingInvitation.prototype.getResourceType = function() {
  return /** @type {!proto.access_grant_v1.GrantableResourceEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.access_grant_v1.GrantableResourceEnum} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional access_grant_v1.GrantTypeEnum grant_type_to_grant = 7;
 * @return {!proto.access_grant_v1.GrantTypeEnum}
 */
proto.sharing_v1.SharingInvitation.prototype.getGrantTypeToGrant = function() {
  return /** @type {!proto.access_grant_v1.GrantTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.access_grant_v1.GrantTypeEnum} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setGrantTypeToGrant = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.sharing_v1.SharingInvitation.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 accepted_at = 9;
 * @return {number}
 */
proto.sharing_v1.SharingInvitation.prototype.getAcceptedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setAcceptedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 rejected_at = 10;
 * @return {number}
 */
proto.sharing_v1.SharingInvitation.prototype.getRejectedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setRejectedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 revoked_at = 11;
 * @return {number}
 */
proto.sharing_v1.SharingInvitation.prototype.getRevokedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setRevokedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string revoked_by = 12;
 * @return {string}
 */
proto.sharing_v1.SharingInvitation.prototype.getRevokedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setRevokedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 last_modified_at = 13;
 * @return {number}
 */
proto.sharing_v1.SharingInvitation.prototype.getLastModifiedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setLastModifiedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string for_email = 14;
 * @return {string}
 */
proto.sharing_v1.SharingInvitation.prototype.getForEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setForEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool is_creator = 15;
 * @return {boolean}
 */
proto.sharing_v1.SharingInvitation.prototype.getIsCreator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sharing_v1.SharingInvitation} returns this
 */
proto.sharing_v1.SharingInvitation.prototype.setIsCreator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * @enum {number}
 */
proto.sharing_v1.SharingInvitationState = {
  NOTIFICATIONSTATE_DO_NOT_USE: 0,
  SENT: 1,
  REVOKED: 2,
  DECLINED: 3,
  ACCEPTED: 4
};

goog.object.extend(exports, proto.sharing_v1);
