import { GenericServerResponse } from '@clepside/clepsidejs/lib/commons/commons_pb';
import {
	RequestAccountDeletionRequest, RequestPasswordChangeRequest, SendVerificationEmailRequest
} from '@clepside/clepsidejs/lib/services/users_v1_pb';
import { Database } from '@db/index';
import {
	browserLocalPersistence, setPersistence, signInWithEmailAndPassword, UserCredential
} from '@firebase/auth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Logger } from '@root/cores/logger';
import { UsersGRPC } from '@root/grpc/grpcUsers';
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	applyActionCode, checkActionCode, confirmPasswordReset, createUserWithEmailAndPassword, getAuth,
	GoogleAuthProvider, OAuthProvider, onAuthStateChanged, sendPasswordResetEmail,
	signInWithRedirect, signOut, updateProfile, User, verifyPasswordResetCode
} from 'firebase/auth';
import { EventChannel, eventChannel } from 'redux-saga';
import { delay, put } from 'redux-saga/effects';
import { call, takeEvery } from 'typed-redux-saga';
import { v4 } from 'uuid';

import { LocalUserDetails, State } from './auth.types';
import { authEncode } from './auth.utils';
import { AuthClient } from './authClient';
import { errorToastsActions } from './errorToasts';
import { interfaceActions } from './interface';
import { watcherActions } from './watchers';
import { WatcherID } from './watchersTypes';

const authSlice = createSlice({
	name: 'auth',
	initialState: State,
	reducers: {
		logout: () => {},
		startWatcher: () => {},
		deleteAccount: () => {},
		setState: (state, { payload }: PayloadAction<LocalUserDetails & { clientId: string } | undefined>) => {
			if (payload) {
				const { clientId, ...user } = payload as any
				state.user = user
				state.clientId = clientId
			}
		},
		joinWithEmail: (state, { payload }: PayloadAction<{ watcherId: WatcherID, email: string, password: string, displayName: string }>) => {},
		triggerPasswordChangeRequest: (state, { payload }: PayloadAction<{ watcherId: WatcherID, email: string }>) => {},
		login: (state, { payload }: PayloadAction<{ watcherId: WatcherID, provider?: 'google' | 'apple' | {
			email: string,
			password: string
		} }>) => {},
		authHandleResetPassword: (state, { payload }: PayloadAction<{ watcherId: WatcherID, actionCode: string, newPassword: string }>) => {},
		authHandleRecoverEmail: (state, { payload }: PayloadAction<{ watcherId: WatcherID, actionCode: string }>) => {},
		authHandleEmailVerification: (state, { payload }: PayloadAction<{ watcherId: WatcherID, actionCode: string }>) => {},
	},
})

export const authSagas = {
	*authHandleResetPassword({ payload }: ReturnType<typeof authSlice.actions.authHandleResetPassword>) {
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))
		const auth = getAuth();
		try {
			yield verifyPasswordResetCode(auth, payload.actionCode)
			yield confirmPasswordReset(auth, payload.actionCode, payload.newPassword)
			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
		} catch (e) {
			console.error('failed to reset password', e)
			yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
		}
	},
	*triggerPasswordChangeRequest({ payload }: ReturnType<typeof authSlice.actions.triggerPasswordChangeRequest>) {
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))
		try {
			const request = new RequestPasswordChangeRequest()
			request.setEmail(payload.email)
			yield UsersGRPC.requestPasswordChange(request)
			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
		} catch (e) {
			console.error('failed to trigger password change')
			yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
		}
	},
	*authHandleRecoverEmail({ payload }: ReturnType<typeof authSlice.actions.authHandleResetPassword>) {
		// This happens after an email change, the previoous owner can rever it?!
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))
		const auth = getAuth();
		try {
			const { data: { email: restoredEmail } } =  yield checkActionCode(auth, payload.actionCode)
			
			// Revert to the old email
			yield applyActionCode(auth, payload.actionCode);

			// Send reset password
			yield sendPasswordResetEmail(auth, restoredEmail)
			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
		} catch (e) {
			console.error('failed to recover email')
			yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
		}
	},
	*authHandleEmailVerification({ payload }: ReturnType<typeof authSlice.actions.authHandleResetPassword>) {
		// Email verification complete
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))
		const auth = getAuth();
		try {
			yield applyActionCode(auth, payload.actionCode)
			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
		} catch (e) {
			console.error('Failed to apply authentication code')
			yield put(watcherActions.fail({ watchers: [payload.watcherId] }))
		}
	},
	*logout() {
		try {
			yield Database.dropAll()
		} catch (error) {
			console.error('Failed to drop all the Databases: ', error)
		}
		try {
			yield signOut(AuthClient)
			localStorage.removeItem('kpi')
			localStorage.removeItem('client_id')
			window.location.replace('/')
		} catch (error) {
			console.error('Auth failed to logout: ', error)
		}
	},
	*deleteAccount() {
		try {
			const req = new RequestAccountDeletionRequest()
			yield UsersGRPC.requestAccountDeletion(req)
			yield put(authActions.logout())
		} catch (e) {
			yield put(errorToastsActions.push({ id: 'delete-acc', message: 'Failed to request account deletion', type: 'server' }))
		}
	},
	*saveLocalState({ payload }: ReturnType<typeof authSlice.actions.setState>) {
		localStorage.setItem('kpi', authEncode(JSON.stringify(payload), 42))
		const clientId = localStorage.getItem('client_id')
		if (!clientId && payload?.clientId) {
			localStorage.setItem('client_id', payload.clientId)
		}
	},
	*joinWithEmail({ payload }: ReturnType<typeof authSlice.actions.joinWithEmail>) {
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))
		const auth = getAuth();
		let user: UserCredential | undefined = undefined

		try {
			user = yield call(createUserWithEmailAndPassword, auth, payload.email, payload.password)
		} catch (error) {
			// const errorCode = (error as any).code;
			console.error('failed to createuser', error)
			yield put(watcherActions.fail({ watchers: [payload.watcherId], message: 'Something went wrong while signing you up.' }))
			return
		}

		if (user) {
			if (payload.displayName) {
				yield updateProfile(user.user, {
					displayName: payload.displayName
				})
			}

			const request = new SendVerificationEmailRequest()
			request.setEmail(payload.email)
			request.setDisplayName(payload.displayName || payload.email)
			const rawResponse: GenericServerResponse = yield UsersGRPC.sendVerificationEmail(request)
			const { response } = rawResponse.toObject()

			yield put(watcherActions.done({ watchers: [payload.watcherId] }))
			yield delay(1000)
			yield put(interfaceActions.needsEmailActivation({ email: payload.email }))
		}	
	},
	*login({ payload }: ReturnType<typeof authSlice.actions.login>) {
		try {
			yield Database.dropAll()
		} catch (error) {
			console.error('Failed to drop all existing Databases: ', error)
		}
		yield put(watcherActions.start({ watchers: [payload.watcherId] }))
		const provider = payload.provider

		// localStorage.setItem('auth', 'waiting')??
		if (payload.provider == 'apple') {
			const auth = (yield new OAuthProvider('apple.com')) as OAuthProvider
			yield signInWithRedirect(AuthClient, auth)
		} else if (payload.provider == 'google') {
			const auth = (yield new GoogleAuthProvider()) as GoogleAuthProvider
			yield signInWithRedirect(AuthClient, auth)
		} else if (payload.provider) {
			const auth = getAuth();
			yield setPersistence(auth, browserLocalPersistence)
			const { email, password } = payload.provider

			try {
				yield call(signInWithEmailAndPassword, auth, email, password)
				window.location.replace('/')
			} catch (error) {
				console.error('Failed to call signin:', error)
				// const errorCode = (error as any).code;
				yield put(watcherActions.fail({ watchers: [payload.watcherId], type: 'server', message: 'Something went wrong while logging you in.' }))
			}
		} else {
			yield put(watcherActions.fail({ watchers: [payload.watcherId], type: 'client', message: 'No provider found.' }))
		}
	},
	*startWatcher({ payload, type }: ReturnType<typeof authSlice.actions.startWatcher>): any {
		const channel: EventChannel<any> = yield eventChannel((emit) => {
			try {
				onAuthStateChanged(AuthClient, (user) => {
					if (user) {
						emit(user)
					} else {
						emit({})
					}
				})
			} catch (e) {
				console.error('Something went wrong while mounting the Firebase watcher.')
			}
			return () => {}
		})

		yield takeEvery(channel, handleUserStateChange)
		
	},
}

function* handleUserStateChange(user: User): any {
	const result = user?.providerData?.[0] || undefined

	if (!result) {
		yield put(authActions.setState(undefined))
		return
	}

	const hasEmailActivated = user.emailVerified || false
	try {
		const idToken = yield user.getIdTokenResult()
		const claims = idToken.claims

		Logger.auth.debug('Remote user status received. Sync can begin.')
		
		yield put(
			authActions.setState({
				emailActivated: hasEmailActivated,
				hasBeenInvited: claims?.has_been_invited || false,
				...result,
				uid: user.uid,
				isFromLocal: false,
				clientId: localStorage.getItem('client_id') || v4()
			})
		)
	} catch (e) {
		console.error('Failed to get token')
		return
	}
}

export const authActions = authSlice.actions
export const authReducers = authSlice.reducer
