import { GrantTypeEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb'
import { Button } from '@comps/interactive/button'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { Spacer } from '@comps/layout/space'
import { Bouncer } from '@comps/static/bouncer'
import { Text } from '@comps/typography/text'
import { useCachedSelector } from '@root/hooks/useCachedSelector'
import { useWatcher, useWatcherObserver } from '@root/hooks/useWatcher'
import { RootState } from '@root/store'
import { flowsActions } from '@root/store/slices/flows'
import { sharingActions } from '@root/store/slices/sharing'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useFlowExit } from './useFlowExit'

export const FlowsSharingSelectRole = () => {
	const dispatch = useDispatch()
	const { watcherId } = useWatcher()
	const { state: watcher } = useWatcherObserver(watcherId)
	const context = useCachedSelector((state: RootState) => state.flows.flowContexts['share-select-role'])
	const exit = useFlowExit()

	const roleButtons = useMemo(() => {
		const buttons = []
		for (const key in GrantTypeEnum) {
			if ([GrantTypeEnum.GRANTTYPEENUM_DO_NOT_USE, GrantTypeEnum.GUEST].includes(GrantTypeEnum[key] as any)) continue
			buttons.push(
				<Button
					key={key}
					grow
					color="subtleAccent"
					onClick={() => {
						const grant = GrantTypeEnum[key] as any
						if (!context) {
							return
						}
						const { email, activityId } = context
						if (grant)
							dispatch(
								sharingActions.createSharingInvitation({
									watcherId,
									email,
									resourceId: activityId,
									role: GrantTypeEnum[key] as any,
								})
							)
					}}
				>
					<Inset padding="buttonMedium" justify="center">
						<Capitalised>{key.toLowerCase()}</Capitalised>
					</Inset>
				</Button>
			)
		}
		return buttons
	}, [dispatch, watcherId, context])

	useEffect(() => {
		if (watcher == 'done') {
			dispatch(flowsActions.popView({ id: 'share-select-role' }))
		}
	}, [watcher, dispatch])

	return (
		<React.Fragment>
			{watcher == 'done' ? (
				<Flex grow align="center" justify="center">
					<Text>Invite sent successfully</Text>
				</Flex>
			) : null}
			{watcher == 'fail' ? (
				<React.Fragment>
					<Text level="title10">Failed to invite</Text>
					<Spacer size={10} vertical />
					<Text>User might not have an account or might not be accepting sharing invitations.</Text>
				</React.Fragment>
			) : null}
			{watcher == 'started' ? <Bouncer /> : null}
			{!watcher ? (
				<React.Fragment key="default">
					<Flex direction="column" spacing={20} align="stretch" width="100%" height="100%">
						<Text level="largerParagraphTitle">What role should {context?.email} have?</Text>
						<Flex direction="column" spacing={10} align="stretch">
							{roleButtons}
						</Flex>
						<Flex grow />
						<Button onClick={exit}>
							<Inset padding="buttonMedium" justify="center">
								Cancel
							</Inset>
						</Button>
					</Flex>
				</React.Fragment>
			) : null}
		</React.Fragment>
	)
}

const Capitalised = styled.div`
	text-transform: capitalize;
`
