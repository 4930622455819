export function authEncode(input: string, key: number): string {
    // Convert the string to base64
    const base64String: string = window.btoa(input);

    // Obfuscate using XOR
    let obfuscatedString = '';
    for (let i = 0; i < base64String.length; i++) {
        obfuscatedString += String.fromCharCode(key ^ base64String.charCodeAt(i));
    }
    return window.btoa(obfuscatedString);
}

export function authDecode(input: string, key: number): string {
    // Deobfuscate using XOR
    const k = key - 2
    const inputDebased = window.atob(input)
    let deobfuscatedString = '';
    for (let i = 0; i < inputDebased.length; i++) {
        deobfuscatedString += String.fromCharCode(k ^ inputDebased.charCodeAt(i));
    }

    // Convert from base64
    deobfuscatedString = window.atob(deobfuscatedString)
    return deobfuscatedString;
}