import styled from 'styled-components';

import { Icon } from '@comps/static/icon';

export const DiagonalArrowCircle = () => {
	return (
		<IconButton className="icon-button">
			<Icon type="diagonalArrow" className="icon" />
		</IconButton>
	)
}
const IconButton = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 999px;
	display: flex;
	align-items: center;
	justify-content: center;
`
