import { Spacer } from '@comps/layout/space'
import { FontStyles } from '@comps/typography/text'
import { addDays, differenceInDays, endOfMonth, endOfWeek, format, isSameMonth, parse, setMonth, startOfWeek, startOfYear } from 'date-fns'
import { useMemo } from 'react'
import styled, { css } from 'styled-components'

export const CalendarMonth: React.FC<{
	year: string
	monthIndex: number
	highlightColor?: string
	highlightDates?: { [key: string]: number }
	onClick?: (d: Date) => void
}> = ({ year, highlightColor, onClick, highlightDates, monthIndex }) => {
	if (!monthIndex) throw new Error('Bad Month Index')

	const startDate = useMemo(() => {
		return setMonth(parse(year, 'yyyy', startOfYear(new Date())), monthIndex - 1)
	}, [monthIndex, year])

	const dayHeaders = useMemo(() => {
		const startRenderingAt = startOfWeek(startDate)
		const cells = []
		for (let i = 0; i < 7; i++) {
			cells.push(format(addDays(startRenderingAt, i), 'EE').substr(0, 2))
		}
		return cells
	}, [startDate])

	const renderedDates = useMemo(() => {
		const startRenderingAt = startOfWeek(startDate)
		const endRenderingAt = endOfWeek(endOfMonth(startDate))
		const diff = differenceInDays(endRenderingAt, startRenderingAt)
		const cellDates = []

		for (let i = 0; i < diff; i++) {
			cellDates.push(addDays(startRenderingAt, i))
		}

		return cellDates
	}, [startDate])

	const mostHighlighted = useMemo(() => {
		if (!highlightDates) return 0
		return Object.keys(highlightDates).reduce((acc, key) => {
			if (highlightDates[key] > acc) {
				return highlightDates[key]
			}
			return acc
		}, 0)
	}, [highlightDates])

	const cells = useMemo(() => {
		const acc: any = {}
		if (!highlightDates) return acc
		for (const date of Object.keys(highlightDates)) {
			acc[date] = (
				<CellBackground
					className="highlight"
					style={{
						backgroundColor: highlightColor || 'red',
						opacity: 0.2 + 0.8 * (highlightDates[date] / mostHighlighted),
					}}
				/>
			)
		}
		return acc
	}, [highlightDates, mostHighlighted, highlightColor])

	return (
		<Block>
			<MonthTitle>{format(startDate, 'MMMM')}</MonthTitle>
			<Spacer size={13} vertical />
			<Cells>
				{dayHeaders.map((t) => (
					<Header key={t}>{t}</Header>
				))}
				{renderedDates.map((d, i) => {
					if (isSameMonth(startDate, d))
						return (
							<Cell hoverable={!!onClick} key={format(d, 'd')} onClick={() => {
								onClick?.(d)
							}}>
								<CellText>{format(d, 'd')}</CellText>
								{cells?.[format(d, 'd')]}
							</Cell>
						)
					return <Cell key={`day${i}`}></Cell>
				})}
			</Cells>
		</Block>
	)
}

const CellText = styled.div`
	position: relative;
	z-index: 1;
`

const MonthTitle = styled.div`
	${FontStyles.body};
	font-weight: 600;
	margin-left: 6px;
	margin-bottom: 6px;
`

const Cells = styled.div`
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-column-gap: 5px;
	grid-row-gap: 5px;
	display: grid;
`

const CellBackground = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 0;
	background-color: red;
	border-radius: 999999px;
`

const Cell = styled.div<{ hoverable?: boolean }>`
	${FontStyles.tiny};
	width: 27px;
	height: 27px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	position: relative;

	${(p) =>
		p.hoverable &&
		css`
			&:hover {
				background-color: ${p.theme.backgrounds.subtle.r.color};
			}
		`}
`

const Block = styled.div`
	display: flex;
	flex-direction: column;
`

const Header = styled.div`
	${FontStyles.tiny};
	text-transform: uppercase;
	color: ${(p) => p.theme.text.subtle.r.color};
	text-align: center;
`
