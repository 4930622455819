import { ULID } from '../commonTypes'

export interface ErrorToast {
    id: string
    message: string | React.ReactNode
    duration: 'normal' | 'longer' | 'infinite' | 'shorter'
    context?: any
}

export interface ErrorToastsState {
    at: {
        [key: ULID]: ErrorToast
    }
    all: ULID[]
}

export const InitialErrorToastsState: ErrorToastsState = {
    at: {},
    all: []
}
