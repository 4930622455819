import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { useDialog } from '@comps/dialog';
import { Button } from '@comps/interactive/button';
import { Flex } from '@comps/layout/flex';
import { Text } from '@comps/typography/text';
import { Transitions } from '@root/brand/transitions';
import { useGlobalSelection } from '@root/hooks/useSelectable';
import { RootState } from '@root/store';
import { cardsActions } from '@root/store/slices/cards';
import { DroppablePlacePayload } from '@root/store/slices/interface.types';
import { selectionActions } from '@root/store/slices/selection';
import { SelectableTypes, SelectionCardContext } from '@root/store/slices/selection.types';
import { sessionsActions } from '@root/store/slices/sessions';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

export const SelectionToast: React.FC = () => {
	const selection = useGlobalSelection()
	const { showDialog } = useDialog()
	const droppablePlace = useSelector((state: RootState) => state.interface.droppablePlace)

	const entityTitles = useMemo(() => {
		switch (selection.selection.type) {
			case SelectableTypes.CARD:
				return ['Card', 'Cards']
			// case SelectableTypes.ACTIVITY:
			// 	return ['Activity', 'Activities']
			case SelectableTypes.SESSION:
				return ['Session', 'Sessions']
			// case SelectableTypes.FRAGMENT:
			// 	return ['Fragment', 'Fragments']
		}
		return ''
	}, [selection])

	const dispatch = useDispatch()

	const actions = useMemo(() => {
		const all = selection.selection.all
		const count = selection.selection.all.length
		const type = selection.selection.type

		if (type == SelectableTypes.SESSION) {
			return [
				<Button
					key={`sess${JSON.stringify(all)}`}
					color="clepside"
					inset="buttonMedium"
					onClick={() => {
						const title = `Confirm Session ${count == 1 ? 'Deletion' : 'Deletions'}`
						const message = `Are you sure you want to delete ${count == 1 ? 'this session' : `these ${count} sessions`}?`
						showDialog(title, message, () => {
							for (const id of all) {
								dispatch(
									sessionsActions.delete({
										id: id.id,
									})
								)
							}
							dispatch(selectionActions.reset())
						})
					}}
				>
					Delete
				</Button>,
			]
		}

		if (type == SelectableTypes.CARD) {
			const cardTypes = new Set<CardType>()
			const droppables: any = {}

			for (const id of all) {
				if (id.itemType == SelectableTypes.CARD) {
					cardTypes.add(id.cardType)
					droppables[`${id.fromResourceId}_${id.fromResourceType}`] = {
						resourceId: id.fromResourceId,
						resourceType: id.fromResourceType,
					}
				}
			}

			let uniformCardtype: CardType | undefined = undefined
			let uniformDroppable: DroppablePlacePayload | undefined = undefined

			if (cardTypes.size == 1) {
				uniformCardtype = cardTypes.values().next().value
			}

			if (Object.keys(droppables).length == 1) {
				uniformDroppable = droppables[Object.keys(droppables)[0]]
			}

			const buttons = []

			if (droppablePlace && uniformDroppable) {
				if (droppablePlace.resourceId == uniformDroppable.resourceId && droppablePlace.resourceType == uniformDroppable.resourceType) {
					buttons.push(<Button
						key={`cards${JSON.stringify(all)}`}
						color="subtleClepside"
						inset="buttonMedium"
						onClick={() => {
							const title = `Confirm Card ${count == 1 ? 'Deletion' : 'Deletions'}`
							const message = `Are you sure you want to delete ${count == 1 ? 'this card' : `these ${count} cards`}?`
							showDialog(title, message, () => {
								for (const id of all) {
									dispatch(
										cardsActions.delete({
											id: id.id,
										})
									)
								}
								dispatch(selectionActions.reset())
							})
						}}
					>
						Delete
					</Button>)

					if (uniformCardtype === CardType.LINK) {
						const ctx = all[0] as SelectionCardContext
						if (ctx.isInEditMode == false) {
							buttons.push(
								<Button
									key={'edit-link'}
									color="clepside"
									inset="buttonMedium"
									onClick={() => {
										dispatch(
											selectionActions.editContext({
												forId: all[0].id,
												ctx: {
													isInEditMode: true,
												},
											})
										)
									}}
								>
									Edit Link
								</Button>
							)
						}
					}
				}
				if (droppablePlace.resourceId != uniformDroppable.resourceId || droppablePlace.resourceType != uniformDroppable.resourceType) {
					buttons.push(
						<Button
							key={'move-card'}
							color="clepside"
							inset="buttonMedium"
							onClick={() => {
								dispatch(
									cardsActions.moveToNewParentResource({
										cardIds: all.map(c => c.id),
										resourceId: droppablePlace.resourceId,
										resourceType: droppablePlace.resourceType,
										deleteAfter: true
									})
								)
							}}
						>
							Move
						</Button>
					)
					buttons.push(
						<Button
							key={'copy-card'}
							color="clepside"
							inset="buttonMedium"
							onClick={() => {
								dispatch(
									cardsActions.moveToNewParentResource({
										cardIds: all.map(c => c.id),
										resourceId: droppablePlace.resourceId,
										resourceType: droppablePlace.resourceType,
										deleteAfter: false
									})
								)
							}}
						>
							Copy
						</Button>
					)
				}
			}


			return buttons
		}
		return []
	}, [selection, dispatch, showDialog, droppablePlace])

	const isVisible = selection.selection.all.length
	const [display, setDisplay] = useState('none')

	const handleAnimationStart = () => {
		if (isVisible) setDisplay('flex')
	}

	const handleAnimationComplete = () => {
		if (!isVisible) setDisplay('none')
	}

	return (
		<AnimatePresence>
			{isVisible && (
				<Toast
					onAnimationStart={handleAnimationStart}
					onAnimationComplete={handleAnimationComplete}
					style={{ display }}
					className="noseldis"
					initial="hide"
					animate="show"
					exit="hide"
					variants={ToastVariants}
					transition={Transitions.relaxed}
				>
					<Flex align="center">
						<Grip />
						<Text level="body">
							{selection.selection.all.length > 1 ? <>{selection.selection.all.length}&nbsp;</> : ''}
							{selection.selection.all.length > 1 ? entityTitles[1] : entityTitles[0]}&nbsp;selected
						</Text>
					</Flex>
					<Flex spacing={8}>{actions}</Flex>
				</Toast>
			)}
		</AnimatePresence>
	)
}

const ToastVariants = {
	show: {
		opacity: 1,
		display: 'flex',
		x: '-50%',
		y: 0,
	},
	hide: {
		opacity: 0,
		x: '-50%',
		y: 100,
	},
}

const Grip = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 3px;
	background-color: rgba(255, 255, 255, 0);
	margin: 0 0px;
`

const Toast = styled(motion.div)`
	position: fixed;
	bottom: 10px;
	left: 50%;
	min-height: 36px;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 15px;
	border-radius: 24px;
	min-width: 600px;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
	${(p) => p.theme.toast.text.r.css('color')};
	${(p) => p.theme.toast.background.r.css('background-color')};
	z-index: 100000000000000;
`
