import { Button } from '@comps/interactive/button'
import { ActivityGradient } from '@comps/layout/activityGradient'
import { Inset } from '@comps/layout/inset'
import { Tooltip } from '@comps/layout/tooltip'
import { Icon } from '@comps/static/icon'
import { Text } from '@comps/typography/text'
import { useOnClickOutside } from '@root/hooks/useOnClickOutside'
import { useRefTaker } from '@root/hooks/useRefTaker'
import { useActivities } from '@root/store/selectors/useActivities'
import { useBoards } from '@root/store/selectors/useBoards'
import { activitiesActions } from '@root/store/slices/activities'
import { ActivityColorKey, ActivityColors } from '@root/store/slices/activities.colors.types'
import { boardsActions } from '@root/store/slices/boards'
import { SidebarTranslucentSubtleColor } from '@root/views/SidebarTranslucentSubtleColor'
import { upperFirst } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'

import { Panel } from './panel'

const wallpapers = ['currentColor', 'clouds', 'coffee', 'desert', 'fly', 'market', 'park', 'sport', 'study', 'writting']

export const WallpaperSwitcher: React.FC<{
	buttonColor: 'darkTranslucent' | 'translucentPassingColor'
	placement: 'left' | 'right'
	resourceId: string
	resourceType: 'activity' | 'board'
}> = ({ resourceId, resourceType, buttonColor, placement }) => {
	const activities = useActivities()
	const boards = useBoards()
	const [show, setShow] = useState(false)
	const dispatch = useDispatch()
	const [ref, setRef] = useRefTaker()
	const [anchor, setAnchor] = useRefTaker()
	useOnClickOutside(ref, () => {
		setShow(false)
	})

	const handleWallpaperClick = useCallback(
		(e: any) => {
			const id = e.currentTarget.getAttribute('data-id')
			if (resourceType == 'board') {
				dispatch(
					boardsActions.update({
						id: resourceId,
						wallpaper: id,
					})
				)
			} else if (resourceType == 'activity') {
				dispatch(
					activitiesActions.update({
						id: resourceId,
						wallpaper: id,
					})
				)
			}
		},
		[resourceId, resourceType, dispatch]
	)

	const color = useMemo(() => {
		if (resourceType == 'board') return boards.at[resourceId].color
		if (resourceType == 'activity') return activities.at[resourceId].color
	}, [resourceType, resourceId, activities, boards])

	if (!resourceId) return null

	return (
		<Holder ref={setRef}>
			<div ref={setAnchor}>
				<Button
					color={buttonColor}
					isActive={show}
					onClick={() => {
						setShow((s) => !s)
					}}
				>
					<Inset equal>
						<SidebarTranslucentSubtleColor>
							<Icon type="palette" size={15} />
						</SidebarTranslucentSubtleColor>
					</Inset>
				</Button>
			</div>
			{show && (
				<Panel anchor={anchor} placement={placement}>
					<Inset padding="card" direction="column">
						<Inset padding="buttonSmall" direction="column">
							<Text level="title10">Select {upperFirst(resourceType)}'s Background</Text>
						</Inset>
						<Grid>
							{wallpapers.map((w) => (
								<WallpaperButton onClick={handleWallpaperClick} data-id={w} key={w}>
									<Wallpaper filename={w} color={color}>
										{w == 'currentColor' ? <Gradient /> : null}
									</Wallpaper>
									<Text level="tiny">{w == 'currentColor' ? 'Activity Color' : w}</Text>
								</WallpaperButton>
							))}
						</Grid>
					</Inset>
				</Panel>
			)}
		</Holder>
	)
}

const Arrow = styled.div`
	position: absolute;
	top: 18px;
	left: -7px;
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	border-right: 7px solid #111215; /* This determines the arrow's color */
	display: block;
`

const Gradient = styled.div`
	${ActivityGradient};
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`

const Grid = styled.div`
	display: grid;
	grid-template-columns: min-content min-content min-content;
`

const WallpaperButton = styled.div`
	display: flex;
	flex-direction: column;
	text-transform: capitalize;
	padding: 8px;
	border-radius: 12px;

	& > *:last-child {
		margin-top: 5px;
		margin-left: 5px;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0.2);
	}
	&:active {
		background-color: rgba(255, 255, 255, 0.05);
	}
`

const Wallpaper = styled.div<{ filename: string; color?: ActivityColorKey }>`
	width: 140px;
	position: relative;
	height: 80px;
	background-color: red;
	border-radius: 10px;
	background-image: url(${(p) => `/static-backgrounds/${p.filename}.jpg`});
	border: 1px solid rgba(255, 255, 255, 0.13);
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
	background-size: cover;
	background-position: center;
	overflow: hidden;

	${(p) =>
		p.color &&
		css`
			${ActivityColors[p.color].active.background.r.css('background-color')};
		`}
`

const Floater = styled(Tooltip)`
	display: flex;
	color: #fff;

	& > *:first-child {
		height: 500px;
		overflow: scroll;
	}
`

const Holder = styled.div`
	position: relative;
	z-index: 1000;
`
