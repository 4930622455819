import deepEqual from 'deep-equal'
import { motion } from 'framer-motion'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

export type TooltipHoverType<T> = {
	values: [T, number]
	coords: [number, number]
}

export function useD3TooltipBuilder<T>(onTooltip: (hover: TooltipHoverType<T>) => any) {
	const [hover, setHover] = useState<TooltipHoverType<T>>()

	const efficientSetHover = useCallback(
		(state: any) => {
			setHover((s: any) => {
				if (deepEqual(s, state)) return s
				return state
			})
		},
		[setHover]
	)

	const tooltip = useMemo(() => {
		return (
			hover && (
				<Tooltip
					initial={{
						x: hover.coords[0] - 20,
						y: hover.coords[1],
						scale: 0.9,
						opacity: 0,
					}}
					exit={{
						opacity: 0,
						scale: 0.9,
						x: hover.coords[0] - 20,
					}}
					animate={{
						scale: 1,
						opacity: 1,
						x: hover.coords[0],
						y: hover.coords[1],
					}}
				>
					{onTooltip(hover)}
				</Tooltip>
			)
		)
	}, [hover, onTooltip])

	const enableTooltip = useCallback(
		(
			svg: any,
			width: number,
			height: number,
			onMouseover: (setHover: (hoverType: undefined | TooltipHoverType<T>) => void, event: any) => void
		) => {
			svg.append('rect')
				.attr('class', 'overlay')
				.attr('width', width)
				.attr('height', height)
				.attr('opacity', 0)
				.on('mouseover', function () {
					setHover(undefined)
				})
				.on('mouseout', function () {
					setHover(undefined)
				})
				.on('mousemove', function (event: any) {
					onMouseover(efficientSetHover, event)
				})
		},
		[setHover, efficientSetHover]
	)

	return useMemo(() => {
		return {
			tooltip,
			tooltipHover: hover,
			enableTooltip,
		}
	}, [hover, tooltip, enableTooltip])
}

const Tooltip = styled(motion.div)`
	display: block;
	border-radius: 10px;
	background-color: ${(p) => p.theme.toast.background.r.color};
	padding: 10px 15px;
	position: absolute;
	top: 0;
	z-index: 100;
	pointer-events: none;
	margin-top: 40px;
	margin-left: 10px;
	left: 0;
`
