import { Icon } from '@comps/static/icon'
import { Text } from '@comps/typography/text'
import styled from 'styled-components'

export const RoutineTag = () => {
    return <RecurrentBox>
        <Icon type='recurrent'/>
        <Text>Routine</Text>
    </RecurrentBox>
}

const RecurrentBox = styled.div`
	background-color: rgba(0, 0, 0, .05);
	padding: 1px 8px;
	border-radius: 999px;
	display: flex;
	align-items: center;
	column-gap: 5px;
`
