import { buildColorFromPreNuance } from '@root/utils/colors'
import { PreNuance, ThemeBackbone } from 'src/styled'
import { DefaultTheme } from 'styled-components'

const Backbones: {
	light: ThemeBackbone<PreNuance>
	// dark: ThemeBackbone<PreNuance>
} = {
	light: {
		statics: {
			borderRadius: {
				small: '12px',
				medium: '18px',
			},
		},
		standards: {
			white: {
				color: 'color(display-p3 1 1 1)',
			},
			shade: {
				color: 'color(display-p3 0 0 0 / 0.033)',
				alphaIntensity: 0.05,
			},
		},
		text: {
			proSubtleText: {
				color: 'color(display-p3 1 1 1 / 0.5)',
			},
			regular: {
				color: 'color(display-p3 0.162 0.177 0.211)',
			},
			subtle: {
				color: 'color(display-p3 0.566 0.566 0.566)',
				intensity: 0.1,
			},
			action: {
				color: 'color(display-p3 0 0.379 0.804)',
				intensity: 0.33,
			},
			accentSemi: {
				color: 'color(display-p3 0.93 0.14 0.09)',
			},
			secondaryAction: {
				color: 'color(display-p3 1 0.097 0.182)',
			},
			clepside: {
				color: 'color(display-p3 1 1 1 / 1)',
				intensity: 1,
			},
			darkTranslucency: {
				color: 'color(display-p3 1 1 1)',
			},
			darkTranslucencySubtle: {
				color: 'color(display-p3 1 1 1 / 0.5)',
				alphaIntensity: 0.2,
			},
			translucent: {
				color: 'color(display-p3 1 1 1 / 0.4)',
			},
		},
		border: {
			subtle: {
				color: 'color(display-p3 0.87 0.876 0.893 / 1)',
				intensity: 0.075,
			},
			translucent: {
				color: 'color(display-p3 0.04 0.07 0.1 / 0.075)',
			},
			tooltip: {
				color: 'color(display-p3 1 1 1 / 0.075)',
			},
			onDarkTranslucency: {
				color: 'color(display-p3 1 1 1 / 0.05)',
				alphaIntensity: 0.05,
			},
		},
		backgrounds: {
			subtle: {
				color: 'color(display-p3 0.969 0.969 0.977 / 1)',
				intensity: 0.015,
			},
			pro: {
				color: 'color(display-p3 0.125 0.149 0.153 / 1)',
				intensity: 0.015,
			},
			routine: {
				color: 'color(display-p3 0.972 0.972 0.972 / 1)',
				intensity: 0.015,
			},
			subtleAccent: {
				color: 'color(display-p3 0 0.444 0.996 / 0.05)',
				alphaIntensity: 0.075,
			},
			accent: {
				color: 'color(display-p3 0 0.444 0.996)',
			},
			accentSemi: {
				color: 'color(display-p3 0.93 0.14 0.09)',
			},
			clepside: {
				color: 'color(display-p3 0.894 0.150 0.220 / 1)',
				intensity: 0.2,
			},
			subtleClepside: {
				color: 'color(display-p3 0.894 0.150 0.220 / 0.17)',
				// color: 'color(display-p3 0.985 0.99 0.998 / 0.92)',
				alphaIntensity: 0.05,
			},
			normal: {
				color: 'color(display-p3 1 1 1)',
				intensity: 0.03,
			},
			lightTranslucency: {
				color: 'color(display-p3 0.98 0.98 0.99 / 0.85)',
				// color: 'color(display-p3 0.985 0.99 0.998 / 0.92)',
				alphaIntensity: 0.05,
			},
			lightTranslucencyHover: {
				color: 'color(display-p3 0.585 0.59 0.598 / 0.92)',
				alphaIntensity: 0.05,
			},
			darkTranslucency: {
				color: 'color(display-p3 0.14 0.145 0.15 / 0.83)',
				intensity: 0.1,
			},
			darkTranslucencyShaded: {
				color: 'color(display-p3 0 0 0 / 0.2)',
			},
			dark: {
				color: 'color(display-p3 0.11 0.12 0.13)',
				intensity: 0.04,
			},
			darkTranslucencyElevated: {
				color: 'color(display-p3 0.9 0.95 1  / 0.042)',
				alphaIntensity: 0.033,
			},
		},
		toast: {
			background: {
				color: 'color(display-p3 0.114 0.114 0.131)',
				intensity: 0.042,
			},
			text: {
				color: 'color(display-p3 1 1 1)',
			},
			textSubtle: {
				color: 'color(display-p3 0.557 0.597 0.631)',
			},
			subtle: {
				color: 'color(display-p3 0.173 0.173 0.19)',
			},
		},
	},
}

export const chiselTheme = (theme: ThemeBackbone<PreNuance>, avoid?: string[]): DefaultTheme => {
	const newTheme: any = {}
	Object.keys(theme).forEach((k) => {
		if (avoid && avoid.includes(k)) {
			newTheme[k] = (theme as any)[k]
			return
		}
		Object.keys((theme as any)[k]).forEach((j) => {
			if (avoid && avoid.includes(j)) {
				newTheme[j] = (theme as any)[j]
				return
			}
			const settings = (theme as any)[k][j]
			if (!newTheme[k]) newTheme[k] = {}
			newTheme[k][j] = buildColorFromPreNuance(settings)
		})
	})
	return newTheme
}

export const Themes = {
	// dark: {
	// 	...chiselTheme(Backbones.dark),
	// },
	light: {
		...chiselTheme(Backbones.light, ['statics']),
	},
}

export const useIsDarkMode = () => {
	// const theme = useTheme()
	return false
	// return theme.name === 'dark'
}
