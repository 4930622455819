import { CardContainer } from '@comps/layout/cardContainer';
import { Inset } from '@comps/layout/inset';
import { Text } from '@comps/typography/text';
import { useTime } from '@root/hooks/useTime';
import { useSessions } from '@root/store/selectors/useSessions';
import { useSessionsFilter } from '@root/store/selectors/useSessionsFilter';
import { endOfDay } from 'date-fns';
import { useMemo } from 'react';

export const TodayNoSessionsLeftCard = () => {
	const now = useTime('minute')
	const sessions = useSessions('this-week')

	const limits = useMemo(() => {
		return [now.getTime(), endOfDay(now).getTime()]
	}, [now])

	const filteredSessions = useSessionsFilter(sessions, limits[0], limits[1], 'end', true)

	if (filteredSessions.all.length != 0) return null

	return (
		<CardContainer translucent="light" ancestor>
			<Inset padding="mediumCard" direction="column" spacing={20} align="flex-start">
				<Text level="title10" block>
					All done.
				</Text>
				<Text>
					All your sessions are done for the day.
					{/* <br />
					Maybe now take a look, review, and plan for tomorrow. */}
				</Text>
				{/* <Button color="accent">
					<Inset padding="buttonSmall">Review tomorrow</Inset>
				</Button> */}
			</Inset>
		</CardContainer>
	)
}
