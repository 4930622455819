import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../'
import { Collection } from '../commonTypes'
import { BoardData } from '../slices/boardsTypes'

export const useBoards = (): Collection<BoardData> => {
	const boardsIndex = useSelector((state: RootState) => {
		return state.boards
	})

	return useMemo(() => {
		return boardsIndex
	}, [boardsIndex])
}

export const useBoard = (id?: string) => {
	const boards = useBoards()

	return useMemo(() => {
		if (!id) return undefined
		return boards.at?.[id]
	}, [boards, id])
}
