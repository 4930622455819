/* eslint-disable @typescript-eslint/no-unused-vars */



import { PacketType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { BillingServicePromiseClient } from '@clepside/clepsidejs/lib/services/billing_grpc_web_pb';
import BillingTypes from '@clepside/clepsidejs/lib/services/billing_pb';
import { PersistServicePromiseClient } from '@clepside/clepsidejs/lib/services/persist_grpc_web_pb';
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { State } from './auth.types';
import { GenericStreamInterceptor, GenericUnaryInterceptor } from './grpcInterceptors';

// import { grpcSyncStreamChannel } from './grpcSyncStreamChannel'

const grpcSlice = createSlice({
	name: 'grpc',
	initialState: State,
	reducers: {
		initAuthClients: () => {},
		initClients: (state, { payload }: PayloadAction<{ token?: string }>) => {},
		pingBoy: (state) => {},
		send: (state, { payload }: PayloadAction<{ packet: Packet; type: PacketType }>) => {},
		generatePaymentIntent: (state, { payload }: PayloadAction<any>) => {},
	},
})

let Persist: PersistServicePromiseClient | undefined = undefined
// let Sync: SyncServicePromiseClient | undefined = undefined
let Billing: BillingServicePromiseClient | undefined = undefined

export const grpcSagas = {
	*initClients({ payload }: ReturnType<typeof grpcSlice.actions.initClients>) {
		if (!payload.token) return undefined

		const interceptors = {
			unaryInterceptors: [new GenericUnaryInterceptor(payload.token)],
			streamInterceptors: [new GenericStreamInterceptor(payload.token)],
		}

		Persist = yield new PersistServicePromiseClient('https://localhost:443', null, interceptors)
		// Sync = yield new SyncServicePromiseClient('https://localhost:443', null, interceptors)
		Billing = yield new BillingServicePromiseClient('https://localhost:443', null, interceptors)
		
	},
	*send({ payload: { packet, type } }: ReturnType<typeof grpcSlice.actions.send>) {
		// if (!Persist) return
	},
	*generatePaymentIntent({ payload }: ReturnType<typeof grpcSlice.actions.send>) {
		if (!Billing) return

		const request = new BillingTypes.BillingRequest()
		request.setPlan('Plus')

		yield Billing.generatePaymentIntent(request)
	},
}

export const grpcActions = grpcSlice.actions
export const grpcReducers = grpcSlice.reducer
