/**
 * @fileoverview gRPC-Web generated client stub for maestro
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.1
// source: services/maestro_v1.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var entities_access_grant_v1_pb = require('../entities/access_grant_v1_pb.js')
const proto = {};
proto.maestro = require('./maestro_v1_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.maestro.MaestroServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.maestro.MaestroServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.maestro.CreateSharingInvitationRequest,
 *   !proto.maestro.CreateSharingInvitationResponse>}
 */
const methodDescriptor_MaestroService_CreateSharingInvitation = new grpc.web.MethodDescriptor(
  '/maestro.MaestroService/CreateSharingInvitation',
  grpc.web.MethodType.UNARY,
  proto.maestro.CreateSharingInvitationRequest,
  proto.maestro.CreateSharingInvitationResponse,
  /**
   * @param {!proto.maestro.CreateSharingInvitationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.maestro.CreateSharingInvitationResponse.deserializeBinary
);


/**
 * @param {!proto.maestro.CreateSharingInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.maestro.CreateSharingInvitationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.maestro.CreateSharingInvitationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.maestro.MaestroServiceClient.prototype.createSharingInvitation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/maestro.MaestroService/CreateSharingInvitation',
      request,
      metadata || {},
      methodDescriptor_MaestroService_CreateSharingInvitation,
      callback);
};


/**
 * @param {!proto.maestro.CreateSharingInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.maestro.CreateSharingInvitationResponse>}
 *     Promise that resolves to the response
 */
proto.maestro.MaestroServicePromiseClient.prototype.createSharingInvitation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/maestro.MaestroService/CreateSharingInvitation',
      request,
      metadata || {},
      methodDescriptor_MaestroService_CreateSharingInvitation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.maestro.AcceptSharingInvitationRequest,
 *   !proto.maestro.AcceptSharingInvitationResponse>}
 */
const methodDescriptor_MaestroService_AcceptSharingInvitation = new grpc.web.MethodDescriptor(
  '/maestro.MaestroService/AcceptSharingInvitation',
  grpc.web.MethodType.UNARY,
  proto.maestro.AcceptSharingInvitationRequest,
  proto.maestro.AcceptSharingInvitationResponse,
  /**
   * @param {!proto.maestro.AcceptSharingInvitationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.maestro.AcceptSharingInvitationResponse.deserializeBinary
);


/**
 * @param {!proto.maestro.AcceptSharingInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.maestro.AcceptSharingInvitationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.maestro.AcceptSharingInvitationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.maestro.MaestroServiceClient.prototype.acceptSharingInvitation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/maestro.MaestroService/AcceptSharingInvitation',
      request,
      metadata || {},
      methodDescriptor_MaestroService_AcceptSharingInvitation,
      callback);
};


/**
 * @param {!proto.maestro.AcceptSharingInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.maestro.AcceptSharingInvitationResponse>}
 *     Promise that resolves to the response
 */
proto.maestro.MaestroServicePromiseClient.prototype.acceptSharingInvitation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/maestro.MaestroService/AcceptSharingInvitation',
      request,
      metadata || {},
      methodDescriptor_MaestroService_AcceptSharingInvitation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.maestro.DismissSharingInvitationRequest,
 *   !proto.maestro.DismissSharingInvitationResponse>}
 */
const methodDescriptor_MaestroService_DismissSharingInvitation = new grpc.web.MethodDescriptor(
  '/maestro.MaestroService/DismissSharingInvitation',
  grpc.web.MethodType.UNARY,
  proto.maestro.DismissSharingInvitationRequest,
  proto.maestro.DismissSharingInvitationResponse,
  /**
   * @param {!proto.maestro.DismissSharingInvitationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.maestro.DismissSharingInvitationResponse.deserializeBinary
);


/**
 * @param {!proto.maestro.DismissSharingInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.maestro.DismissSharingInvitationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.maestro.DismissSharingInvitationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.maestro.MaestroServiceClient.prototype.dismissSharingInvitation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/maestro.MaestroService/DismissSharingInvitation',
      request,
      metadata || {},
      methodDescriptor_MaestroService_DismissSharingInvitation,
      callback);
};


/**
 * @param {!proto.maestro.DismissSharingInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.maestro.DismissSharingInvitationResponse>}
 *     Promise that resolves to the response
 */
proto.maestro.MaestroServicePromiseClient.prototype.dismissSharingInvitation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/maestro.MaestroService/DismissSharingInvitation',
      request,
      metadata || {},
      methodDescriptor_MaestroService_DismissSharingInvitation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.maestro.RevokeSharingInvitationRequest,
 *   !proto.maestro.RevokeSharingInvitationResponse>}
 */
const methodDescriptor_MaestroService_RevokeSharingInvitation = new grpc.web.MethodDescriptor(
  '/maestro.MaestroService/RevokeSharingInvitation',
  grpc.web.MethodType.UNARY,
  proto.maestro.RevokeSharingInvitationRequest,
  proto.maestro.RevokeSharingInvitationResponse,
  /**
   * @param {!proto.maestro.RevokeSharingInvitationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.maestro.RevokeSharingInvitationResponse.deserializeBinary
);


/**
 * @param {!proto.maestro.RevokeSharingInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.maestro.RevokeSharingInvitationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.maestro.RevokeSharingInvitationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.maestro.MaestroServiceClient.prototype.revokeSharingInvitation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/maestro.MaestroService/RevokeSharingInvitation',
      request,
      metadata || {},
      methodDescriptor_MaestroService_RevokeSharingInvitation,
      callback);
};


/**
 * @param {!proto.maestro.RevokeSharingInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.maestro.RevokeSharingInvitationResponse>}
 *     Promise that resolves to the response
 */
proto.maestro.MaestroServicePromiseClient.prototype.revokeSharingInvitation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/maestro.MaestroService/RevokeSharingInvitation',
      request,
      metadata || {},
      methodDescriptor_MaestroService_RevokeSharingInvitation);
};


module.exports = proto.maestro;

