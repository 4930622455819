import { Icon } from '@comps/static/icon'
import { Transitions } from '@root/brand/transitions'
import { motion } from 'framer-motion'
import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

export const SessionBlockFin: React.FC<{ direction: 'top' | 'bottom'; onResizingStarted: (e: any, dir: 'top' | 'bottom') => void }> = ({
	direction,
	onResizingStarted,
}) => {
	const handleMouseDown = useCallback(
		(e: any) => {
			e.stopPropagation()
			onResizingStarted?.(e, direction)
		},
		[onResizingStarted, direction]
	)

	return (
		<FlapHolder
			initial={{ opacity: 0, y: direction === 'top' ? 5 : -5, rotateZ: direction === 'top' ? -90 : 90, scale: 0.8 }}
			animate={{ opacity: 1, y: 0, scale: 1 }}
			transition={Transitions.sessionFins}
			exit={{ opacity: 0, y: direction === 'top' ? 5 : -5, scale: 0.8 }}
			data-dir={direction}
			direction={direction}
			className="flap-holder fin noseldis"
			onMouseDown={handleMouseDown}
		>
			<Flap>
				<Icon offsetX={1} onMouseDown={handleMouseDown} type="arrow" size={4} />
			</Flap>
		</FlapHolder>
	)
}

const Flap = styled.div<{ direction?: 'top' | 'bottom' }>`
	width: 24px;
	height: 24px;
	border-radius: 999px;
	display: flex;
	align-items: center;
	justify-content: center;
	${(p) => p.theme.toast.background.r.css('background-color')};
`

const FlapHolder = styled(motion.div)<{ direction?: 'top' | 'bottom' }>`
	position: absolute;
	left: auto;
	right: 25%;
	width: 24px;
	height: 132px;

	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 1;
	line-height: 0;

	&.flap-holder,
	&.flap-holder * {
		cursor: row-resize !important;
	}

	${(p) =>
		p.direction === 'bottom'
			? css`
					bottom: -68px;
			  `
			: css`
					top: -68px;
			  `}

	&:hover ${Flap} {
		${(p) => p.theme.toast.background.t1.css('background-color')};
	}

	&:active ${Flap} {
		${(p) => p.theme.toast.background.t2.css('background-color')};
	}
`
