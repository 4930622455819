import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { cardLayoutToCells } from '@root/components/complex/cards/grid.utils';
import { DragDropCore, DragDropListener } from '@root/cores/dragDropCore';
import { useGridDimensions } from '@root/hooks/useGridDimensions';
import { CardLayoutObject } from '@root/store/slices/cards.types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CardRectInPx, Matrix } from './cardGrid.types';
import { ReturnCardLayouts } from './cardGrid.useAddCard';
import { useCardBehavioursUtils } from './cardGrid.useCardBehavioursUtils';

export const useCardDrag = (
	gridRef: HTMLDivElement,
	m: React.MutableRefObject<Matrix>,
	addCard: ReturnCardLayouts,
	render: () => void,
	container: HTMLElement,
	dragZoneId: string,
	resourceId: string,
	resourceType: GrantableResourceEnum
	// cardById?: React.MutableRefObject<undefined | { [k: string]: CardDatasourcePlusLayout }>
) => {
	const dispatch = useDispatch()
	const { resetMove, simulateMove, gestureFinished, simulateTheLayoutChange, gestureBegan } = useCardBehavioursUtils(
		m,
		addCard,
		render
		// cardById
	)
	const initialLayout = useRef<any>()
	const { gridCellWidthRef } = useGridDimensions(dragZoneId)
	const [placeholder, setPlaceholder] = useState<
		| {
				movingToANewBoard: boolean
				layout: CardLayoutObject
		  }
		| undefined
	>()

	useEffect(() => {
		if (!gridRef) return

		const listener: DragDropListener = (phase, context, e: any) => {
			if (context.type !== 'card-drag') return

			if (phase === 'start') {
				initialLayout.current = {
					x: context.startCoords.x,
					y: context.startCoords.y,
					width: context.initalSize.width,
					height: context.initalSize.height,
				}
				gestureBegan(initialLayout.current)
				return
			}

			if (!e) return

			const scrollOffsetContainer = document.querySelector('.card-grid-scroll')
			const scrollOffsetX = scrollOffsetContainer ? scrollOffsetContainer.scrollLeft || 0 : 0
			const scrollOffsetY = scrollOffsetContainer ? scrollOffsetContainer.scrollTop || 0 : 0

			const originX = e.clientX - context.topLeftOffset.x
			const originY = e.clientY - context.topLeftOffset.y

			const x = originX - context.gridOffset.x + scrollOffsetX
			const y = originY - context.gridOffset.y + scrollOffsetY

			const rect: CardRectInPx = {
				xPx: x,
				yPx: y,
				wPx: context.initalSize.width,
				hPx: context.initalSize.height,
			}

			let newLayout = { ...cardLayoutToCells('drag', x, y, { hPx: rect.hPx, wPx: rect.wPx }, gridCellWidthRef.current) }

			if (phase === 'interrupted') {
				newLayout = initialLayout.current
			}

			setPlaceholder({
				movingToANewBoard: resourceId !== context.resourceId && resourceType !== context.resourceType,
				layout: newLayout,
			})

			simulateTheLayoutChange(context.cardId, newLayout)

			if (phase == 'finished' || phase == 'interrupted') {
				setPlaceholder(undefined)
			}

			if (phase === 'finished') {
				gestureFinished(
					context,
					'card-drag',
					{
						id: context.cardId,
						layout: newLayout,
					},
					resourceId,
					resourceType
				)
				return
			}
		}

		const removeListener = DragDropCore.addListener(dragZoneId, listener)
		const unmount = DragDropCore.mountContainer(dragZoneId, 1, container)
		return () => {
			removeListener()
			unmount()
		}
	}, [
		gridRef,
		m,
		resourceId,
		resourceType,
		dispatch,
		gestureBegan,
		resetMove,
		gridCellWidthRef,
		dragZoneId,
		container,
		simulateMove,
		gestureFinished,
		simulateTheLayoutChange,
	])

	return useMemo(() => ({ placeholder }), [placeholder])
}
