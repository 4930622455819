import React from 'react';
import styled from 'styled-components';

import { MaxWidth } from '@comps/layout/maxWidth';
import { Text } from '@comps/typography/text';

export const SettingsItem: React.FC<{
	title?: string
	subtitle?: string
	rightside?: React.ReactNode
	children?: React.ReactNode | React.ReactNode[]
}> = ({ title, subtitle, rightside, children }) => {
	return (
		<Item>
			<Header>
				<Text bold>{title}</Text>
				<HeaderRight>{rightside}</HeaderRight>
			</Header>
			<MaxWidth size={525}>
				<Text level="body" color="subtle">
					{subtitle}
				</Text>
				{children}
			</MaxWidth>
		</Item>
	)
}

const HeaderRight = styled.div``

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
	align-items: baseline;
`

const Item = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	width: 100%;
	padding-bottom: 20px;
	border-bottom: 1px dotted #ececec;
	position: relative;
	top: 0;
	right: 0;

	&:last-child {
		border-bottom: none;
	}
`
