import { Medias } from '@root/medias'
import React from 'react'
import styled, { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components'

type TextLevel =
	| 'title80'
	| 'title70'
	| 'title60'
	| 'title50'
	| 'title30'
	| 'title10'
	| 'body'
	| 'bodyTitle'
	| 'label'
	| 'tiny'
	| 'largerParagraphTitle'
	| 'largerParagraphBody'

export const Text: React.FC<{
	children: any
	level?: TextLevel
	align?: React.CSSProperties['textAlign']
	bold?: boolean
	block?: boolean
	noWrap?: boolean
	className?: string
	color?: keyof DefaultTheme['text'] | keyof DefaultTheme['standards'] | 'toast.text' | 'toast.textSubtle'
	hex?: string
	marginBottom?: number
	marginRight?: number
}> = ({ children, block, align, className, hex, bold, color, noWrap, level = 'body', marginBottom, marginRight }) => {
	return (
		<Font
			level={level}
			block={block}
			align={align}
			className={className}
			color={hex ? hex : color}
			bold={bold}
			noWrap={noWrap}
			marginRight={marginRight}
			marginBottom={marginBottom}
		>
			{children}
		</Font>
	)
}

export const BlockHeight: {
	[key in TextLevel]: string
} = {
	title80: '64px',
	title70: '20px',
	title60: '35px',
	title50: '20px',
	title30: '20px',
	title10: '17px',
	body: '16px',
	bodyTitle: '18px',
	label: '12px',
	tiny: '9px',
	largerParagraphBody: '16px',
	largerParagraphTitle: '17px',
}

export const FontStyles: {
	[key in TextLevel]: FlattenSimpleInterpolation
} = {
	title80: css`
		font-style: normal;
		font-weight: bold;
		font-size: 68px;
		line-height: 44px;
		/* or 17% */
		display: flex;
		align-items: center;
		letter-spacing: -2.5px;
		color: #ffffff;
	`,
	title70: css`
		font-style: normal;
		font-size: 40px;
		line-height: 48px;
		font-weight: 700;
	`,
	title60: css`
		font-size: 26px;
		font-weight: 700;
		letter-spacing: 0px;
		letter-spacing: -0.6px;
	`,
	title50: css`
		font-style: normal;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 32px;
		letter-spacing: -1px;

		@media ${Medias.PHONE} {
			font-size: 46px;
			font-weight: bold;
			/* letter-spacing: -1.75px; */
			line-height: 46px;
		}
	`,
	title30: css`
		font-style: normal;
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 37px;
		letter-spacing: -0.5px;
	`,
	title10: css`
		font-style: normal;
		font-style: normal;
		font-weight: 700;
		font-size: 22px;
		line-height: 32px;
		letter-spacing: -0.5px;
	`,
	bodyTitle: css`
		font-size: 15px;
		letter-spacing: -0.05px;
		font-weight: 600;
		line-height: 23px;

		b {
			font-weight: 600;
		}

		@media ${Medias.PHONE} {
			font-size: 16px;
			letter-spacing: -0.2px;
			line-height: 27px;
		}
	`,
	body: css`
		font-size: 14px;
		letter-spacing: -0.05px;
		font-weight: 500;
		line-height: 23px;

		b {
			font-weight: 600;
		}

		@media ${Medias.PHONE} {
			font-size: 17px;
			font-weight: 500;
			line-height: 27px;
		}
	`,
	label: css`
		font-size: 13px;
		letter-spacing: -0.1px;
		font-weight: 500;
		line-height: 23px;

		b {
			font-weight: 600;
		}
	`,
	tiny: css`
		font-size: 11.5px;
		font-weight: 500;
		letter-spacing: -0.05px;
	`,
	largerParagraphTitle: css`
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 23px;
		margin-left: 3px;
		letter-spacing: -0.25px;
	`,
	largerParagraphBody: css`
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 28px;
	`,
}

const Font = styled.span<{
	level?: TextLevel
	bold?: boolean
	block?: boolean
	noWrap?: boolean
	marginBottom?: number
	marginRight?: number
	color?: string
	align?: React.CSSProperties['textAlign']
}>`
	word-wrap: break-all;
	${({ level }) => level && FontStyles[level]}
	${({ noWrap }) =>
		noWrap &&
		css`
			white-space: nowrap;
			text-overflow: ellipsis;
		`}
	${({ align }) =>
		align &&
		css`
			text-align: ${align};
		`}
	
	color: ${(p) => {
		if (!p.color) return 'currentColor'
		const color: any = p.color
		const theme: any = p.theme
		if (theme.text[color]) return theme.text[color].r.color
		if (theme.standards[color]) return theme.standards[color].r.color
		const toastColor = color.replace('toast.', '')
		if (color.includes('toast.') && theme.toast[toastColor]) {
			return theme.toast[toastColor].r.color
		}
		return p.color
	}};

	${({ bold, level }) => {
		if (!bold) return
		if (level === 'body')
			return css`
				font-weight: 600;
				letter-spacing: 0px;
			`
		if (level === 'largerParagraphBody')
			return css`
				font-weight: 700;
				letter-spacing: -0.15px;
			`
		return css`
			font-weight: 600;
		`
	}}}

	${({ marginBottom }) =>
		marginBottom
			? css`
					margin-bottom: ${marginBottom}px;
			  `
			: undefined};

	${({ marginRight }) =>
		marginRight
			? css`
					margin-right: ${marginRight}px;
			  `
			: undefined};
	
	strong {
		font-weight: 600;
	}
	${(p) =>
		p.block &&
		css`
			${p.level &&
			BlockHeight[p.level] &&
			css`
				line-height: ${BlockHeight[p.level]};
			`}
		`}
`
