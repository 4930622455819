import { TextInput } from '@comps/forms/textInput'
import { TextInputGroup } from '@comps/forms/textInputGroup'
import { Link } from '@comps/interactive/link'
import { WatcherButton } from '@comps/interactive/watcherButton'
import { Flex } from '@comps/layout/flex'
import { Inset, InsetSize } from '@comps/layout/inset'
import { Text } from '@comps/typography/text'
import { useBackToApp } from '@root/hooks/useBackToApp'
import { useForm } from '@root/hooks/useForm'
import { useFormConfig } from '@root/hooks/useFormConfig'
import { useWatcher, useWatcherObserver } from '@root/hooks/useWatcher'
import { Medias } from '@root/medias'
import { grpcActions } from '@root/store/slices/grpc.DEPRECATED'
import { authActions } from '@slices/auth'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { useAuthOutletContext } from './Sessions.ID.useSessionOutletContext'

export const Forgot: React.FC = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(grpcActions.initAuthClients())
	}, [dispatch])

	const { watcherId: triggerPasswordChangeWatcherId } = useWatcher()
	const { state: hasSubmitted, context } = useWatcherObserver(triggerPasswordChangeWatcherId)

	const formConfig = useFormConfig(
		{
			fields: {
				email: {
					validate: 'required,email',
				},
			},
			onSubmit: (state, values) => {
				dispatch(
					authActions.triggerPasswordChangeRequest({
						watcherId: triggerPasswordChangeWatcherId,
						email: values.email,
					})
				)
			},
		},
		[]
	)
	const { fields, injectFormField, submit } = useForm(formConfig)
	const { isFromApp } = useAuthOutletContext()
	const [success, setSuccess] = useState(false)
	const backToButton = useBackToApp(success && isFromApp, 'Take me to Login')

	useEffect(() => {
		if (hasSubmitted == 'done') {
			setTimeout(() => {
				setSuccess(true)
			}, 500)
		}
	}, [hasSubmitted])

	const content = useMemo(() => {
		if (!success) {
			return <WatcherButton insetPadding="buttonMedium" onClick={submit} color="pro" watcherId={triggerPasswordChangeWatcherId}>
				Send me the Special Link
			</WatcherButton>
		}

		return <Flex direction='column' spacing={20}>
			<Text color='subtle'>
				✅ We've successfully sent you an email to reset your password.
			</Text>
			{backToButton}
		</Flex>
	}, [success, backToButton, submit, triggerPasswordChangeWatcherId])

	return (
		<CardContent>
			<ContentHolder isFromApp={isFromApp}>
				<Flex direction="column" spacing={25} width="100%">
					<Text level="title50">Lost Password</Text>
					<Text level="body" color={isFromApp ? 'proSubtleText' : undefined}>
						We'll email you a special link through which you'll be able to set up a new password for your account.
					</Text>
					<TextInputGroup label={isFromApp ? undefined : 'Email Address'} stretch>
						<TextInput
							inputStyle="block"
							placeholder="Email Address"
							autoFocus
							type='email'
							isFromApp={isFromApp}
							ref={injectFormField('email', 'text')}
							{...fields.email}
							inputMode='email'
						/>
					</TextInputGroup>
					{content}
				</Flex>
			</ContentHolder>
			{isFromApp ? <></> :
				<Flex direction="column" align="flex-start">
					<Text>
						<Link to="/login">Take me back to Login</Link>
					</Text>
				</Flex>}
		</CardContent>
	)
}
const ContentHolder = styled.div<{ isFromApp: boolean }>`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	
	${p => p.isFromApp && css`
		color: #fff;
	`}
`

const CardContent = styled(Inset)`
	max-width: 320px;
	width: 100%;
	${InsetSize.biggerCard};
	flex-direction: column;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-grow: 1;
	* {
			text-align: left;
		}

	@media ${Medias.PHONE} {
		padding: 0;
		align-items: flex-start;
	}
`
