import { isAfter, isBefore, isSameMinute } from 'date-fns'

export type DateInterval = [Date, Date]

export const intervalIsInterval = (f: DateInterval, t: DateInterval) => {
	if (isSameMinute(f[0], t[0]) && isSameMinute(f[1], t[1])) {
		return true
	}
	return false
}

export const intervalFitsInside = (f: DateInterval, t: DateInterval) => {
	if ((isAfter(f[0], t[0]) || isSameMinute(f[0], t[0])) && (isBefore(f[1], t[1]) || isSameMinute(f[1], t[1]))) {
		return true
	}
	return false
}

export const dateFitsInside = (d: Date, t: DateInterval) => {
	if ((isAfter(d, t[0]) || isSameMinute(d, t[0])) && (isBefore(d, t[1]) || isSameMinute(d, t[1]))) {
		return true
	}
	return false
}
