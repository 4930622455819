// source: packets_v1/settings.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var entities_access_grant_v1_pb = require('../entities/access_grant_v1_pb.js');
goog.object.extend(proto, entities_access_grant_v1_pb);
var commons_core_pb = require('../commons/core_pb.js');
goog.object.extend(proto, commons_core_pb);
goog.exportSymbol('proto.packets_v1.SettingsPacket', null, global);
goog.exportSymbol('proto.packets_v1.SettingsPacketData', null, global);
goog.exportSymbol('proto.packets_v1.SettingsPacketMeta', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.SettingsPacketData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.SettingsPacketData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.SettingsPacketData.displayName = 'proto.packets_v1.SettingsPacketData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.SettingsPacketMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.SettingsPacketMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.SettingsPacketMeta.displayName = 'proto.packets_v1.SettingsPacketMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.SettingsPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.SettingsPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.SettingsPacket.displayName = 'proto.packets_v1.SettingsPacket';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.SettingsPacketData.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.SettingsPacketData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.SettingsPacketData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SettingsPacketData.toObject = function(includeInstance, msg) {
  var f, obj = {
    uitimelineonright: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    datestartonmonday: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    dateprefersampm: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    gestureslideontimeline: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.SettingsPacketData}
 */
proto.packets_v1.SettingsPacketData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.SettingsPacketData;
  return proto.packets_v1.SettingsPacketData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.SettingsPacketData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.SettingsPacketData}
 */
proto.packets_v1.SettingsPacketData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUitimelineonright(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDatestartonmonday(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDateprefersampm(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGestureslideontimeline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.SettingsPacketData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.SettingsPacketData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.SettingsPacketData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SettingsPacketData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUitimelineonright();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDatestartonmonday();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDateprefersampm();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getGestureslideontimeline();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool uiTimelineOnRight = 1;
 * @return {boolean}
 */
proto.packets_v1.SettingsPacketData.prototype.getUitimelineonright = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.packets_v1.SettingsPacketData} returns this
 */
proto.packets_v1.SettingsPacketData.prototype.setUitimelineonright = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool dateStartOnMonday = 2;
 * @return {boolean}
 */
proto.packets_v1.SettingsPacketData.prototype.getDatestartonmonday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.packets_v1.SettingsPacketData} returns this
 */
proto.packets_v1.SettingsPacketData.prototype.setDatestartonmonday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool datePrefersAMPM = 3;
 * @return {boolean}
 */
proto.packets_v1.SettingsPacketData.prototype.getDateprefersampm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.packets_v1.SettingsPacketData} returns this
 */
proto.packets_v1.SettingsPacketData.prototype.setDateprefersampm = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool gestureSlideOnTimeline = 4;
 * @return {boolean}
 */
proto.packets_v1.SettingsPacketData.prototype.getGestureslideontimeline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.packets_v1.SettingsPacketData} returns this
 */
proto.packets_v1.SettingsPacketData.prototype.setGestureslideontimeline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.SettingsPacketMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.SettingsPacketMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.SettingsPacketMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SettingsPacketMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    uitimelineonright: (f = msg.getUitimelineonright()) && commons_core_pb.Meta.toObject(includeInstance, f),
    datestartonmonday: (f = msg.getDatestartonmonday()) && commons_core_pb.Meta.toObject(includeInstance, f),
    dateprefersampm: (f = msg.getDateprefersampm()) && commons_core_pb.Meta.toObject(includeInstance, f),
    gestureslideontimeline: (f = msg.getGestureslideontimeline()) && commons_core_pb.Meta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.SettingsPacketMeta}
 */
proto.packets_v1.SettingsPacketMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.SettingsPacketMeta;
  return proto.packets_v1.SettingsPacketMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.SettingsPacketMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.SettingsPacketMeta}
 */
proto.packets_v1.SettingsPacketMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setUitimelineonright(value);
      break;
    case 2:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setDatestartonmonday(value);
      break;
    case 3:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setDateprefersampm(value);
      break;
    case 4:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setGestureslideontimeline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.SettingsPacketMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.SettingsPacketMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.SettingsPacketMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SettingsPacketMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUitimelineonright();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDatestartonmonday();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getDateprefersampm();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getGestureslideontimeline();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
};


/**
 * optional commons.Meta uiTimelineOnRight = 1;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.SettingsPacketMeta.prototype.getUitimelineonright = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 1));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.SettingsPacketMeta} returns this
*/
proto.packets_v1.SettingsPacketMeta.prototype.setUitimelineonright = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SettingsPacketMeta} returns this
 */
proto.packets_v1.SettingsPacketMeta.prototype.clearUitimelineonright = function() {
  return this.setUitimelineonright(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SettingsPacketMeta.prototype.hasUitimelineonright = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional commons.Meta dateStartOnMonday = 2;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.SettingsPacketMeta.prototype.getDatestartonmonday = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 2));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.SettingsPacketMeta} returns this
*/
proto.packets_v1.SettingsPacketMeta.prototype.setDatestartonmonday = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SettingsPacketMeta} returns this
 */
proto.packets_v1.SettingsPacketMeta.prototype.clearDatestartonmonday = function() {
  return this.setDatestartonmonday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SettingsPacketMeta.prototype.hasDatestartonmonday = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional commons.Meta datePrefersAMPM = 3;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.SettingsPacketMeta.prototype.getDateprefersampm = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 3));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.SettingsPacketMeta} returns this
*/
proto.packets_v1.SettingsPacketMeta.prototype.setDateprefersampm = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SettingsPacketMeta} returns this
 */
proto.packets_v1.SettingsPacketMeta.prototype.clearDateprefersampm = function() {
  return this.setDateprefersampm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SettingsPacketMeta.prototype.hasDateprefersampm = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional commons.Meta gestureSlideOnTimeline = 4;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.SettingsPacketMeta.prototype.getGestureslideontimeline = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 4));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.SettingsPacketMeta} returns this
*/
proto.packets_v1.SettingsPacketMeta.prototype.setGestureslideontimeline = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SettingsPacketMeta} returns this
 */
proto.packets_v1.SettingsPacketMeta.prototype.clearGestureslideontimeline = function() {
  return this.setGestureslideontimeline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SettingsPacketMeta.prototype.hasGestureslideontimeline = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.SettingsPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.SettingsPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.SettingsPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SettingsPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    data: (f = msg.getData()) && proto.packets_v1.SettingsPacketData.toObject(includeInstance, f),
    meta: (f = msg.getMeta()) && proto.packets_v1.SettingsPacketMeta.toObject(includeInstance, f),
    lastModifiedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cloudedAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.SettingsPacket}
 */
proto.packets_v1.SettingsPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.SettingsPacket;
  return proto.packets_v1.SettingsPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.SettingsPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.SettingsPacket}
 */
proto.packets_v1.SettingsPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {!proto.commons.PacketType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.packets_v1.SettingsPacketData;
      reader.readMessage(value,proto.packets_v1.SettingsPacketData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = new proto.packets_v1.SettingsPacketMeta;
      reader.readMessage(value,proto.packets_v1.SettingsPacketMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModifiedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloudedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.SettingsPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.SettingsPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.SettingsPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.SettingsPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.packets_v1.SettingsPacketData.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.packets_v1.SettingsPacketMeta.serializeBinaryToWriter
    );
  }
  f = message.getLastModifiedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCloudedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.packets_v1.SettingsPacket.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.SettingsPacket} returns this
 */
proto.packets_v1.SettingsPacket.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional commons.PacketType type = 3;
 * @return {!proto.commons.PacketType}
 */
proto.packets_v1.SettingsPacket.prototype.getType = function() {
  return /** @type {!proto.commons.PacketType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.commons.PacketType} value
 * @return {!proto.packets_v1.SettingsPacket} returns this
 */
proto.packets_v1.SettingsPacket.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional SettingsPacketData data = 4;
 * @return {?proto.packets_v1.SettingsPacketData}
 */
proto.packets_v1.SettingsPacket.prototype.getData = function() {
  return /** @type{?proto.packets_v1.SettingsPacketData} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.SettingsPacketData, 4));
};


/**
 * @param {?proto.packets_v1.SettingsPacketData|undefined} value
 * @return {!proto.packets_v1.SettingsPacket} returns this
*/
proto.packets_v1.SettingsPacket.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SettingsPacket} returns this
 */
proto.packets_v1.SettingsPacket.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SettingsPacket.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SettingsPacketMeta meta = 5;
 * @return {?proto.packets_v1.SettingsPacketMeta}
 */
proto.packets_v1.SettingsPacket.prototype.getMeta = function() {
  return /** @type{?proto.packets_v1.SettingsPacketMeta} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.SettingsPacketMeta, 5));
};


/**
 * @param {?proto.packets_v1.SettingsPacketMeta|undefined} value
 * @return {!proto.packets_v1.SettingsPacket} returns this
*/
proto.packets_v1.SettingsPacket.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.SettingsPacket} returns this
 */
proto.packets_v1.SettingsPacket.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.SettingsPacket.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 last_modified_at = 6;
 * @return {number}
 */
proto.packets_v1.SettingsPacket.prototype.getLastModifiedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.SettingsPacket} returns this
 */
proto.packets_v1.SettingsPacket.prototype.setLastModifiedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.packets_v1.SettingsPacket.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.SettingsPacket} returns this
 */
proto.packets_v1.SettingsPacket.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 clouded_at = 8;
 * @return {number}
 */
proto.packets_v1.SettingsPacket.prototype.getCloudedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.SettingsPacket} returns this
 */
proto.packets_v1.SettingsPacket.prototype.setCloudedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


goog.object.extend(exports, proto.packets_v1);
