import { FilerServiceClient } from '@clepside/clepsidejs/lib/services/filer_v1_grpc_web_pb'
import {
	FetchAvatarRequest,
	FetchAvatarResponse,
	FetchFavIconRequest,
	FetchFavIconResponse,
	GetCardFilePresignedDownloadURLRequest,
	GetCardFilePresignedDownloadURLResponse,
	ProcessCardFileUploadRequest,
	ProcessCardFileUploadResponse,
	UploadAvatarRequest,
	UploadAvatarResponse
} from '@clepside/clepsidejs/lib/services/filer_v1_pb'
import { getGRPCMeta } from './utils'

//UploadCardFileRequest, UploadCardFileResponse

if (!process.env.REACT_APP_API_URL)
    throw new Error('No RAPURL')
const FilerClient = new FilerServiceClient(process.env.REACT_APP_API_URL)

export const FilerGrpc = {
    uploadAvatar: (request: UploadAvatarRequest) => new Promise<UploadAvatarResponse>((res, rej) => {
            (async () => {
                FilerClient.uploadAvatar(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    fetchFavIcon: (request: FetchFavIconRequest) => new Promise<FetchFavIconResponse>((res, rej) => {
            (async () => {
                try {
                    const meta = await getGRPCMeta()
                    FilerClient.fetchFavicon(request, meta, (err, suc) => err ? rej(err) : res(suc))
                } catch (e) {
                    console.log('failed to fetch favicon')
                }
            })();
        }
    ),
    getPresignedURL: (request: GetCardFilePresignedDownloadURLRequest) => 
        new Promise<GetCardFilePresignedDownloadURLResponse>((res, rej) => {
            (async () => {
                FilerClient.getCardFilePresignedDownloadURL(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    processCardFileUpload: (request: ProcessCardFileUploadRequest) => 
        new Promise<ProcessCardFileUploadResponse>((res, rej) => {
            (async () => {
                FilerClient.processCardFileUpload(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    ),
    fetchAvatar: (request: FetchAvatarRequest) => new Promise<FetchAvatarResponse>((res, rej) => {
            (async () => {
                FilerClient.fetchAvatar(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
            })();
        }
    )
}
// ,
//     uploadCardFile: (request: UploadCardFileRequest) => new Promise<UploadCardFileResponse>((res, rej) => {
//         (async () => {
//             FilerClient.uploadCardFile(request, await getGRPCMeta(), (err, suc) => err ? rej(err) : res(suc))
//         })();
//     }
// ),