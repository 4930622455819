import { Icon } from '@comps/static/icon';
import { interfaceActions } from '@root/store/slices/interface';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const TIME = 0.6
export const SessionBlockRoutineMaker: React.FC<{
    routineId: string
    scheduleId?: string,
    start: number,
    end: number
}> = ({ routineId, scheduleId, start, end }) => {
    const holder = useRef<HTMLDivElement>(null)
    const clicker = useRef<HTMLDivElement>(null)
    const successTimer = useRef<any>()
    const dispatch = useDispatch()

    const [props, setProps] = useState({
        left: 0, top: 0,
        transform: 'scale(0)',
        opacity: 0
    })

    const handleSuccess = useCallback(() => {
        if (!scheduleId) return
        dispatch(interfaceActions.showScheduleRoutineModal({
            routine: {
                routineId,
                scheduleId,
                startAt: start,
                endAt: end
            }
        }))
    }, [routineId, scheduleId, start, end, dispatch])

    const handleTapped = useCallback((e: any) => {
        const tapOrigin = holder.current?.getBoundingClientRect()
        const clickerOrigin = clicker.current?.getBoundingClientRect()

        if (!tapOrigin || !clickerOrigin) return

        const x = e.clientX - tapOrigin.x - 2.5 - (clickerOrigin.x - tapOrigin.x)
        const y = e.clientY - tapOrigin.y - 2.5
        const absX = e.clientX - tapOrigin.x - 2.5
        const absY = e.clientY - tapOrigin.y - 2.5


        const sizeX = Math.max(absX, tapOrigin.width - absX)
        const sizeY = Math.max(absY, tapOrigin.height - absY)
        const maxSize = Math.sqrt(Math.pow(sizeX, 2) + Math.pow(sizeY, 2)) * 2

        clearTimeout(successTimer.current)
        successTimer.current = setTimeout(() => {
            handleSuccess()
        }, TIME * 1000)

        setProps({
            left: x,
            top: y,
            transform: `scale(${maxSize / 5})`,
            opacity: 1
        })
    }, [handleSuccess])

    const handleReleased = useCallback(() => {
        clearTimeout(successTimer.current)
        successTimer.current = null
        setProps(s => ({
            ...s,
            transform: 'scale(0)'
        }))
    }, [])

    const handleClickCancellation = useCallback((e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    return (
        <Holder ref={holder}>
            <Maker onMouseDown={handleTapped}
                ref={clicker}
                onClick={handleClickCancellation} onMouseUp={handleReleased}
                onMouseLeave={handleReleased}>
                <More type='more' />
                <Filler
                    style={props}
                // animate={{
                // }}
                // transition={Transitions.relaxed}
                />
            </Maker>
        </Holder>
    )
}

const More = styled(Icon)`
    width: 16px;
    height: 16px;
    padding: 15px 15px;
`

const Holder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
`

const Filler = styled.div`
    position: absolute;
    width: 5px;
    height: 5px;
    top: 10px;
    left: 10px;
    border-radius: 999px;
    transform: scale(1);
    background-color: rgba(0,0,0,0.15);
    transition: ${TIME}s ease transform, ${TIME}s ease opacity;
`

const Maker = styled.div`
    position: absolute;
    top: 0;
    z-index: 100;
    right: 0;
    width: 60px;
    display: flex;
    justify-content: flex-end;
    opacity: 0.3;
    height: 100%;
    border-radius: inherit;
`