import { differenceInSeconds, endOfDay, isWithinInterval, startOfDay } from 'date-fns';
import React, { useMemo } from 'react';

import { useSessions } from '@root/store/selectors/useSessions';
import { useSessionsSource } from '@root/store/selectors/useSessionsSource';
import { InstancedSessionData, SessionDisplayConditions } from '@root/store/slices/sessionsTypes';
import { getDayIndex } from '@root/utils/dates';

import { useTime } from './useTime';

export interface ScheduledActiveSession {
	activeSession: InstancedSessionData & {
		duration: number
		isScheduled: true
	}
}

export interface StartedActiveSession {
	activityId: string
	start: Date
	isScheduled: false
}

export type ActiveSession = ScheduledActiveSession | StartedActiveSession

export const useActiveSession = () => {
	const now = useTime('date')
	const sessionsConfig = useMemo(() => {
		return {
			start: startOfDay(now).getTime(),
			end: endOfDay(now).getTime(),
			inclusive: 'both',
		} as SessionDisplayConditions
	}, [now])
	useSessionsSource(sessionsConfig, 'for-date-<0>', `${getDayIndex(now)}`)
	const sessions: any = useSessions('for-date-<0>', `${getDayIndex(now)}`)

	const activePlannedSession = useMemo(() => {
		const sessionWithinBoundsID = sessions.all.filter((sId: string) => {
			const s = sessions.at[sId]
			if (isWithinInterval(new Date(), { start: s.start, end: s.end })) {
				return true
			}
			return false
		})[0]
		return sessions.at[sessionWithinBoundsID]
	}, [sessions])

	return useMemo(() => {
		// if (vitals.startedSession)
		// 	return {
		// 		activityId: vitals?.startedSession?.activityId,
		// 		start: new Date(vitals?.startedSession?.start),
		// 		isScheduled: false,
		// 	} as StartedActiveSession
		if (activePlannedSession) {
			return {
				activeSession: {
					...activePlannedSession,
					duration: differenceInSeconds(activePlannedSession.end, activePlannedSession.start),
					isScheduled: true,
				},
			} as ScheduledActiveSession
		}
		return {
			activeSession: undefined,
			isScheduled: false,
		}
	}, [activePlannedSession])
	// vitals.startedSession
}
