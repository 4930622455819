import React, { useMemo } from 'react'
import styled from 'styled-components'
import { SettingsItem } from './settingsItem'

export const SettingsItemYesNo: React.FC<{
	title?: string
	subtitle?: string
	rightside?: React.ReactNode
	onYesNo?: () => void
}> = ({ title, subtitle }) => {
	const rightSide = useMemo(() => {
		return <YesNoButton selected={'no'} onSelect={() => {}} />
	}, [])
	return <SettingsItem title={title} subtitle={subtitle} rightside={rightSide} />
}

const YesNoButton: React.FC<{ selected: 'yes' | 'no'; onSelect: (option: 'yes' | 'no') => void }> = ({ selected, onSelect }) => {
	const options = ['yes', 'no']

	return (
		<Holder>
			{options.map((d) => (
				<Button onClick={() => onSelect(d as any)} selected={d === selected}>
					{d}
				</Button>
			))}
		</Holder>
	)
}

const Holder = styled.div`
	display: flex;
	background-color: #efefef;
	border-radius: 5px;
	padding: 2px;
`

const Button = styled.div<{ selected?: boolean }>`
	cursor: pointer;
	border-radius: 6px;
	padding: 5px 10px;
	text-transform: capitalize;

	&:hover {
		background-color: #fff;
		box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
	}
`
