import { SettingsItem } from '@comps/complex/settingsItem';
import { useDialog } from '@comps/dialog';
import { Button } from '@comps/interactive/button';
import { authActions } from '@root/store/slices/auth';
import { useUser } from '@selectors/useUser';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

export const SettingsAccountDeletion = () => {
	const user = useUser()

	const { showDialog } = useDialog()
	const dispatch = useDispatch()

	const triggerDeletion = useMemo(() => {
		return (
			<Button
				inset="buttonSmall"
				color='textualClepside'
				onClick={async () => {
					const title = 'Confirm Account Deletion'
					const message = 'Your account will be disabled until we process your deletion request and delete all your data. Once that is done, you will be able to create a new account again.'
					showDialog(title, message, () => {
						dispatch(authActions.deleteAccount())
					})
				}}
			>
				Delete Account
			</Button>
		)
	}, [showDialog, dispatch])
	
	if (!user) return null

	return (
		<SettingsItem
			title="Account deletion"
			subtitle="You can delete your account at any time. The deletion procedure follows the European GDPR guidelines, it's permanent and all your data will be lost."
			rightside={triggerDeletion}
		/>
	)
}
