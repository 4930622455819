import { RoutineSchedulePeriod } from '@clepside/clepsidejs/lib/packets_v1/routine_schedule_pb'
import { Button } from '@comps/interactive/button'
import { Flex } from '@comps/layout/flex'
import { Inset } from '@comps/layout/inset'
import { Separator } from '@comps/layout/separator'
import { Text } from '@comps/typography/text'
import { useCachedSelector } from '@root/hooks/useCachedSelector'
import { RootState } from '@root/store'
import { flowsActions, flowsSpecialActionsPushView } from '@root/store/slices/flows'
import { formatDate } from '@root/utils/dates'
import { fromUnixTime } from 'date-fns'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

export const FlowsRoutineScheduleEditing: React.FC = () => {
	const context = useCachedSelector((state: RootState) => state.flows.flowContexts['routine-schedule-editing'])

	const dispatch = useDispatch()
	// const exit = useFlowExit()


	// const handleCancel = useCallback(() => {
	// 	dispatch(flowsActions.cancelFlow({ id: 'create-or-edit-activity' }))
	// }, [dispatch])

	if (!context) return <span>No context available. Inform Support</span>

	return (
		<React.Fragment>
            <Flex spacing={10} direction='column' align='stretch' grow>
                <Button color={getColor(context.schedule?.period, 'daily')} grow tabIndex={40}  onClick={() => {
                    dispatch(flowsSpecialActionsPushView('routine-schedule-editing', 'routine-period-daily'))
                }}>
                    <Inset padding="buttonMedium" align="center" direction='column'>
                        Daily
                    </Inset>
                </Button>
                <Button color={getColor(context.schedule?.period, 'weekly')} grow tabIndex={40} >
                    <Inset padding="buttonMedium" align="center" direction='column'>
                        Weekly
                    </Inset>
                </Button>
                <Button color={getColor(context.schedule?.period, 'monthly')} grow tabIndex={40} >
                    <Inset padding="buttonMedium" align="center" direction='column'>
                        Monthly
                    </Inset>
                </Button>
                <Separator/>
                <Flex align='baseline' justify='space-between' grow direction='row'>
                    <Text>Start date:</Text>
                    <Button inset='tiny' color='subtleAccent' onClick={() => {
                       dispatch(flowsActions.startFlow({
                            flowType: 'select-date',
                            ancestor: 'routine-schedule-editing'
                       })

                       )
                    }}>
                        {context.schedule?.startingOn ? formatDate(fromUnixTime(context.schedule.startingOn / 1000)) : 'Select date'}
                    </Button>
                </Flex>
            </Flex>
		</React.Fragment>
	)
}


function getColor(period: RoutineSchedulePeriod.AsObject | undefined, key: keyof RoutineSchedulePeriod.AsObject ) {
    if (period?.[key]) {
        return 'accent'
    }
    return 'subtleAccent'
}