import React from 'react';
import styled, { css } from 'styled-components';

import { Medias } from '@root/medias';
import { Spacer } from './space';

export type InsetPaddingType =
	| 'tiny'
	| 'buttonTiny'
	| 'buttonSmall'
	| 'buttonMedium'
	| 'buttonLarge'
	| 'medium'
	| 'cardSmaller'
	| 'card'
	| 'larger'
	| 'page'
	| 'todayCard'
	| 'toast'
	| 'mediumCard'
	| 'biggerCard'
	| 'mediumCircleContainer'
	| 'none'

export const Inset: React.FC<{
	padding?: InsetPaddingType
	children: React.ReactNode
	align?: React.CSSProperties['alignItems']
	justify?: React.CSSProperties['justifyContent']
	equal?: boolean
	negative?: boolean
	direction?: React.CSSProperties['flexDirection']
	negativeX?: boolean
	oneLine?: boolean
	spacing?: number
	skipBottomPadding?: boolean
	className?: string
	stretch?: boolean
	shrink?: boolean
	expandWidth?: boolean
	maxWidth?: string
	full?: boolean
	expand?: boolean
}> = ({
	direction = 'row',
	expand,
	full,
	padding = 'medium',
	equal,
	oneLine,
	skipBottomPadding,
	className,
	spacing,
	children,
	align = 'flex-start',
	justify = 'flex-start',
	negative,
	shrink,
	maxWidth,
	negativeX,
	expandWidth,
}) => {
	return (
		<Holder
			padding={padding}
			oneLine={oneLine}
			align={align}
			justify={justify}
			direction={direction}
			negative={negative}
			negativeX={negativeX}
			equal={equal}
			expandWidth={expandWidth}
			expand={expand}
			shrink={shrink}
			maxWidth={maxWidth}
			full={full}
			skipBottomPadding={skipBottomPadding}
			className={`${className || ''} inset`}
		>
			{spacing && Array.isArray(children)
				? children.map((child, index) => {
						return (
							<React.Fragment key={index}>
								{child}
								{index !== children.length - 1 && <Spacer size={spacing} equal />}
							</React.Fragment>
						)
				  })
				: children}
		</Holder>
	)
}

export const EqualInsetSize = {
	tiny: css`
		padding: 4px;
	`,
	medium: css`
		padding: 8px;
	`,
	larger: css`
		padding: 14px 15px 15px 15px;
	`,
	buttonTiny: css`
		padding: 2px;
	`,
	buttonSmall: css`
		padding: 4px;
	`,
	none: css`
		padding: 0;
	`,
	buttonMedium: css`
		padding: 10px;
	`,
	buttonLarge: css`
		padding: 10px;
	`,
	cardSmaller: css`
		padding: 10px 10px 10px 10px;
	`,
	card: css`
		padding: 20px 20px 20px 20px;
	`,
	mediumCircleContainer: css`
		padding: 8px;
	`,
	page: css`
		padding: -20px;
	`,
	biggerCard: css`
		padding: 30px 30px 30px 30px;
	`,
	toast: css`
		padding: 25px;
	`,
	todayCard: css`
		padding: 30px;
	`,
	mediumCard: css`
		padding: 25px;
	`,
}
export const InsetSize = {
	tiny: css`
		padding: 2px 8px 3px 8px;
	`,
	medium: css`
		padding: 4px 15px 5px 15px;
	`,
	larger: css`
		padding: 7px 15px 8px 15px;
	`,
	none: css`
		padding: 0;
	`,
	page: css`
		padding: 20px;

		@media ${Medias.PHONE} {
			padding: 30px 25px;
		}
	`,
	buttonTiny: css`
		padding: 2px 9px;
	`,
	buttonSmall: css`
		line-height: 1em;
		padding: 8px 12px;
	`,
	buttonMedium: css`
		line-height: 1em;
		padding: 11px 14px;

		@media ${Medias.PHONE} {
			padding: 15px 20px;
		}
	`,
	buttonLarge: css`
		padding: 14px 15px;
	`,
	cardSmaller: css`
		padding: 10px 10px 10px 10px;
	`,
	card: css`
		padding: 20px 20px 20px 20px;
	`,
	biggerCard: css`
		padding: 40px 40px 40px 40px;
	`,

	todayCard: css`
		padding: 30px 38px;
	`,
	toast: css`
		padding: 20px 30px;
	`,
	mediumCard: css`
		padding: 32px;
	`,
	mediumCircleContainer: css`
		padding: 7px 10px 8px 9px;
	`,
}

export const OutsetSize = {
	tiny: css`
		width: calc(100% + 30px);
		margin: -10px -15px -11px -15px;
	`,
	none: css`
		padding: 0;
	`,
	medium: css`
		width: calc(100% + 30px);
		margin: -7px -15px -8px -15px;
	`,
	larger: css`
		width: calc(100% + 30px);
		margin: -7px -15px -8px -15px;
	`,
	buttonTiny: css`
		/* width: calc(100% + 18px); */
		margin: -2px -9px;
		width: 100%;
	`,
	buttonSmall: css`
		width: calc(100% + 18px);
		margin: -6px -12px;
	`,
	buttonMedium: css`
		width: calc(100% + 18px);
		margin: -4px -9px;
	`,
	buttonLarge: css`
		width: calc(100% + 30px);
		margin: -14px -15px;
	`,
	page: css`
		padding: -20px;
	`,
	cardSmaller: css`
		width: calc(100% + 20px);
		margin: -10px -10px -10px -10px;
	`,
	card: css`
		width: calc(100% + 40px);
		margin: -20px -20px -20px -20px;
	`,
	biggerCard: css`
		margin: -30px -30px -30px -30px;
	`,
	todayCard: css`
		padding: 30px 38px;
	`,
	toast: css`
		padding: 20px 30px;
	`,
	mediumCard: css`
		padding: -25px;
	`,
	mediumCircleContainer: css`
		width: calc(100% + 19px);
		margin: -7px -10px -8px -9px;
	`,
}

const Holder = styled.div<{
	padding: InsetPaddingType
	negative?: boolean
	negativeX?: boolean
	equal?: boolean
	align?: React.CSSProperties['alignItems']
	justify?: React.CSSProperties['justifyContent']
	direction?: React.CSSProperties['flexDirection']
	stretch?: boolean
	expand?: boolean
	full?: boolean
	oneLine?: boolean
	shrink?: boolean
	expandWidth?: boolean
	maxWidth?: string
	skipBottomPadding?: boolean
}>`
	display: flex;
	flex-grow: 0;
	width: 100%;
	box-sizing: border-box;

	${({ shrink }) => {
		return shrink
			? css`
					flex-shrink: 1 !important;
					width: auto !important;
			  `
			: undefined
	}};

	${({ direction, align, justify }) => {
		return css`
			flex-direction: ${direction};
			justify-content: ${justify};
			align-items: ${align};
			text-align: ${align};
		`
	}}

	${({ oneLine }) => {
		return oneLine
			? css`
					white-space: nowrap;
			  `
			: undefined
	}};

	${({ maxWidth }) => {
		return maxWidth
			? css`
					max-width: ${maxWidth};
			  `
			: undefined
	}};
	${({ expandWidth }) => {
		return expandWidth
			? css`
					width: 100%;
			  `
			: undefined
	}};

	${({ full }) => {
		return full
			? css`
					width: 100%;
					height: 100%;
			  `
			: undefined
	}};

	${({ expand }) => {
		return expand
			? css`
					min-width: 100%;
					min-height: 100%;
					flex-grow: 1;
			  `
			: undefined
	}};

	${({ padding, equal, negative, negativeX }) => {
		if (negativeX)
			return css`
				${OutsetSize[padding]};
				margin-top: 0;
				margin-bottom: 0;
			`
		if (negative)
			return css`
				${OutsetSize[padding]};
			`
		if (equal) return EqualInsetSize[padding]
		return InsetSize[padding]
	}};

	${({ skipBottomPadding }) =>
		skipBottomPadding &&
		css`
			padding-bottom: 0;
		`}
`
