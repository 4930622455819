import { WeekDimensions } from '@root/hooks/useWeekDimensions.types';
import { range } from '@utils/general';
import styled from 'styled-components';

export const CalenderFullWeekGrid = styled.div<{ numberOfDaysShown: number; dimensions: WeekDimensions }>`
	display: grid;
	grid-template-columns: ${(p) =>
		range(p.numberOfDaysShown)
			.map(() => p.dimensions.dayWidth + 'px')
			.join(' ')};
	margin-top: 5px;
	grid-column-gap: ${(p) => p.dimensions.gap + 'px'};
	padding: 0px ${(p) => p.dimensions.padding};
	margin-left: 50px;
	box-sizing: border-box;
`
