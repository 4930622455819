import { UserInfo } from '@firebase/auth';
import { Logger } from '@root/cores/logger';

import { authDecode } from './auth.utils';

export type LocalUserDetails = {
	hasBeenInvited?: boolean,
	emailActivated: boolean,
	isFromLocal: boolean,
} & Pick<UserInfo, 'displayName' | 'email' | 'uid'>


export interface IAuth {
	user?: LocalUserDetails
	clientId?: string
}

const previousAuthStateString: string | null = localStorage.getItem('kpi')
let previousAuthState = undefined
try {
	previousAuthState = previousAuthStateString  ? JSON.parse(authDecode(previousAuthStateString, 44)) : null
} catch (e) {
	// console.error('Failed to read the previous auth state')
}

Logger.auth.debug(`${previousAuthStateString ? 'Found user state locally.' : 'No user state found.'}`)

export const State: IAuth = {
	user: previousAuthState ? { ...previousAuthState, isFromLocal: true } : undefined,
	clientId: undefined
}
