/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Logger } from '@root/cores/logger';
import { CriticalOp, Database } from '@root/database';
import { all, delay, put } from 'redux-saga/effects';

import { Collection } from '../commonTypes';
import { activitiesActions } from './activities';
import { syncActivitiesActions, syncActivitiesSagas } from './sync.activities';
import { syncBoardsActions } from './sync.boards';
import { syncRoutinesSagas } from './sync.routines';
import { extractParams } from './sync.utils';

export const State: {
	syncedActivities: Collection<boolean>
} = {
	syncedActivities: { all: [], at: {} },
}

const syncSlice = createSlice({
	name: 'sync',
	initialState: State,
	reducers: {
		fetchUpdates: (state, { payload }: PayloadAction) => { },
		loadActivitiesSyncStatus: (state, { payload }: PayloadAction) => { },
		placeInStoreActivitiesSyncStatus: (state, { payload }: PayloadAction<{ status: Collection<boolean> }>) => {
			state.syncedActivities = payload.status
		},
		clearActivitiesSyncStatus: (state, { payload }: PayloadAction) => {
			state.syncedActivities = {
				all: [],
				at: {},
			}
		},
		doCriticalOps: (state, { payload }: PayloadAction) => { },
	},
})

export const syncSagas = {
	*fetchUpdates({ }: ReturnType<typeof syncSlice.actions.fetchUpdates>) {
		Logger.sync.debug('[Sync] Fetching updates.')
		yield put(syncActivitiesActions.fetchActivities())
		yield put(syncBoardsActions.fetchBoards())
		// yield put(syncSessionsActions.fetchSessionsNeedingFetching())
		yield delay(500)
	},
	*doCriticalOps({ }: ReturnType<typeof syncSlice.actions.doCriticalOps>): any {
		yield delay(300)
		let count = yield Database.criticalOps.count()
		while (count > 0) {
			const ops: CriticalOp[] = []

			const insertOp = (op: CriticalOp) => {
				ops.push(op)
			}
			yield Database.criticalOps
				.orderBy('id')
				.limit(40)
				.each((op) => {
					insertOp(op)
				})

			const tasks = []
			for (const eachOp of ops) {
				const { op } = eachOp
				switch (op.type) {
					case 'fetch-sessions-for-activity':
						{
							const activityId = op.activityId
							tasks.push(
								(function* () {
									try {
										yield syncActivitiesSagas.streamActivity({
											payload: {
												activityId: activityId,
												fetchRoutines: true,
											},
											type: 'sync.activities/streamActivity',
										})
										yield Database.criticalOps.delete(eachOp.id)
									} catch (e) {
										console.error(e)
									} finally {
									}
								})()
							)
						}
						break
					case 'stream-activity-routines':
						{
							const activityId = op.activityId
							tasks.push(
								(function* () {
									try {
										yield syncActivitiesSagas.fetchSessionsForActivity({
											payload: {
												activityId: activityId,
											},
											type: 'sync.activities/fetchSessionsForActivity',
										})
										yield Database.criticalOps.delete(eachOp.id)
									} catch (e) {
										console.error(e)
									} finally {
									}
								})()
							)
						}
						break
					case 'stream-routine':
						{
							const routineId = op.routineId
							tasks.push(
								(function* () {
									try {
										yield syncRoutinesSagas.streamRoutine({
											payload: {
												routineId: routineId,
											},
											type: 'sync.routines/streamRoutine',
										})
										yield Database.criticalOps.delete(eachOp.id)
									} catch (e) {
										console.error(e)
									} finally {
									}
								})()
							)
						}
						break
				}
			}
			yield all(tasks)
			count = yield Database.criticalOps.count()
			if (count == 0) {
				yield delay(1000)
			}
			count = yield Database.criticalOps.count()
		}
	},
	*loadActivitiesSyncStatus({ }: ReturnType<typeof syncSlice.actions.loadActivitiesSyncStatus>): any {
		const results = yield Database.syncBoard.where('template').equals('Activities/<0>/Invitations').toArray()
		const coll: Collection<boolean> = { at: {}, all: [] }
		for (const result of results) {
			const activityId = extractParams('Activities/<0>/Invitations', result.key)?.[0]

			if (activityId) {
				coll.all.push(activityId)
				coll.at[activityId] = true
				yield put(activitiesActions.loadToStore({ resourceId: activityId }))
			}
		}
		yield put(syncActions.placeInStoreActivitiesSyncStatus({ status: coll }))
	},
}

export const syncActions = syncSlice.actions
export const syncReducers = syncSlice.reducer
