import CJSCore from '@clepside/clepsidejs/lib/commons/core_pb'
import {
	FileInfo,
	FragmentUploadablePacket,
	FragmentUploadablePacketData,
	FragmentUploadablePacketMeta
} from '@clepside/clepsidejs/lib/packets_v1/fragments_uploadable_pb'
import { Packet } from '@clepside/clepsidejs/lib/unions_v1/packets_pb'
import { generatePacketMeta } from './persist.utils'
import { FragmentUploadablePacketObject } from './sync.tsx.packets.types'

export function constructFragmentUploadablePacket(readyToSendPacket: Packet, fragPacket: FragmentUploadablePacketObject) {
    const tbsUpldPacket = new FragmentUploadablePacket()
    const { data, deleted, id, type, meta } = fragPacket
    if (!data) throw new Error('Packet data is undefined')
    
    const { cardId, fileInfo } = data
    
    tbsUpldPacket.setId(id)

    if (!type) {
        throw new Error('packet type not found')
    }

    if (deleted) {
        const deletedMeta = new CJSCore.DeleteMeta()
        deletedMeta.setAt(deleted.at)
        deletedMeta.setBy(deleted.by)
        deletedMeta.setIs(deleted.is)
        tbsUpldPacket.setDeleted(deletedMeta)
    }
    if (data) {
        const newData = new FragmentUploadablePacketData()
        const newMeta = new FragmentUploadablePacketMeta()

        if (cardId) { 
            newData.setCardId(cardId)
        }

        if (fileInfo && meta.fileInfo) { 
            const info = new FileInfo()
            info.setId(fileInfo.id)
            info.setType(fileInfo.type)

            newData.setFileInfo(info) 
            newMeta.setFileInfo(generatePacketMeta(fragPacket, 'fileInfo'))
        }

        tbsUpldPacket.setData(newData)
        tbsUpldPacket.setMeta(newMeta)
        tbsUpldPacket.setType(type)
    }

    readyToSendPacket.setFragmentUploadable(tbsUpldPacket)
}