import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { CardLayout } from '@clepside/clepsidejs/lib/packets_v1/cards_pb';
import { Position, Size } from '@comps/complex/cards/grid.utils';

import { GrantableResourceType } from './cards.types';

export type ViewID =
	| 'Activities'
	| 'Activities.NewActivity.Overview'
	| 'Activities.NewActivity.SelectIcon'
	| 'Activities.Sharing'
	| 'Activities.NewActivity.SelectColor'
	| 'Activities.ShareSheet.List'
	| 'Activities.ShareSheet.SelectRole'
	| 'Routines.NewRoutine.Overview'
	| 'Routines.<0>.Cards'
	| 'Today'
	| 'Settings'
	| 'Calendar'
	| 'Insights'
	| 'Plan'
	| 'Plan.Weekly'
	| 'Plan.Details'
	| 'Plan.ConfirmDeletion'
	| 'Plan.SelectDate'
	| 'Activities.<0>.CardStack'
	| 'Boards.<0>.Cards'
	| 'Boards.<0>.Cards.<1>.Fragments'
	| 'Activities.<0>.Cards'
	| 'Activities.<0>.Cards.<1>.Fragments'
	| 'Sessions.<0>.Cards'
	| 'Sessions.<0>.Cards.<1>.Fragments'
	| 'Plan.SelectActivity'
	| 'Global.SearchOverlay'
	| 'TBD'
export type SelectionID = ViewID

export type Keytip = {
	keys: string
	description: string
}

export type InterfaceDragTypes = 'session'
export type DraggingSession = {
	type: 'session'
	sessionId: string
	offset: {
		secondsBefore: number
		secondsAfter: number
	}
}

export type ScheduleRoutinePayload = {
	routineId: string,
	scheduleId: string,
	startAt: number,
	endAt: number
}

export type DroppablePlacePayload = {
	resourceId: string
	resourceType: GrantableResourceType
}

export const interfaceState: {
	showKeytips: boolean
	scope?: ViewID
	scopeWithIds?: string
	showScheduleForRoutine?: ScheduleRoutinePayload
	showEmailActivation?: string
	dragging?: DraggingContext
	droppablePlace?: DroppablePlacePayload
} = {
	showKeytips: false,
	showEmailActivation: undefined,
	showScheduleForRoutine: undefined,
	scope: undefined,
	dragging: undefined,
	droppablePlace: undefined
}

const ListHotkeys = [
	{ keys: 'up', description: 'Select previous item' },
	{ keys: 'down', description: 'Select next item' },
	{ keys: 'enter', description: 'Made a selection' },
]

export const HotkeysMap: { [key in ViewID]: Keytip[] } = {
	Activities: [
		{
			keys: 'cmd+a',
			description: 'New activity',
		},
	],
	Today: [
		{
			keys: 'cmd+a',
			description: 'New event',
		},
	],
	Calendar: [],
	Plan: [],
	Insights: [],
	TBD: [],
	Settings: [],
	'Plan.Weekly': [
		{
			keys: 'delete',
			description: 'Delete selected session',
		},
	],
	'Plan.Details': [],
	'Plan.SelectActivity': [],
	'Plan.ConfirmDeletion': [],
	'Plan.SelectDate': [],
	'Activities.ShareSheet.List': [],
	'Activities.ShareSheet.SelectRole': [],
	'Activities.Sharing': [...ListHotkeys],
	'Activities.NewActivity.Overview': [],
	'Activities.NewActivity.SelectIcon': [...ListHotkeys],
	'Activities.NewActivity.SelectColor': [...ListHotkeys],
	'Routines.NewRoutine.Overview': [...ListHotkeys],
	'Routines.<0>.Cards': [],
	'Boards.<0>.Cards': [
		{
			keys: 'cmd+n',
			description: 'Add a new card',
		},
		{
			keys: 'tab',
			description: 'Cycle through cards',
		},
		{
			keys: 'delete',
			description: 'Delete card',
		},
	],
	'Boards.<0>.Cards.<1>.Fragments': [
		{
			keys: 'backspace',
			description: 'Remove an item',
		},
		{
			keys: 'alt+n',
			description: 'Add a new item',
		},
		{
			keys: 'tab',
			description: 'Cycle through cards',
		},
		{
			keys: 'enter',
			description: 'Go to next fragment',
		},
	],
	'Global.SearchOverlay': [
		{
			keys: 'esc',
			description: 'Close the search overlay'
		}
	],
	'Activities.<0>.CardStack': [
		{
			keys: 'cmd+n',
			description: 'Add a new card',
		},
	],
	'Activities.<0>.Cards': [
		{
			keys: 'cmd+n',
			description: 'Add a new card',
		},
		{
			keys: 'tab',
			description: 'Cycle through cards',
		},
		{
			keys: 'delete',
			description: 'Delete card',
		},
	],
	'Activities.<0>.Cards.<1>.Fragments': [
		{
			keys: 'backspace',
			description: 'Remove an item',
		},
		{
			keys: 'alt+n',
			description: 'Add a new item',
		},
		{
			keys: 'tab',
			description: 'Cycle through cards',
		},
		{
			keys: 'enter',
			description: 'Go to next fragment',
		},
	],
	'Sessions.<0>.Cards': [
		{
			keys: 'tab',
			description: 'Cycle through cards',
		},
		{
			keys: 'delete',
			description: 'Delete card',
		},
	],
	'Sessions.<0>.Cards.<1>.Fragments': [
		{
			keys: 'backspace',
			description: 'Remove an item',
		},
		{
			keys: 'tab',
			description: 'Cycle through cards',
		},
		{
			keys: 'enter',
			description: 'Go to next fragment',
		},
	],
}

export type DraggingContext =
	| {
			type: 'session'
			sessionId: string
			shouldCopy?: boolean
			startCoords: {
				x: number
				y: number
			}
			offset: {
				secondsBefore: number
				secondsAfter: number
			}
	  }
	| {
			  type: 'session-resize'
			  sessionId: string
			  direction: 'top' | 'bottom'
			  start: number
			  end: number
		}
	| {
		type: 'fragment'
		fragmentId: string
		shouldCopy?: boolean
		position?: number
		origin?: {
			x: number
			y: number
		}
		key: string
  	}
	| {
			type: 'highlight'
			startPoint: number
	  }
	| {
			type: 'card-resize' | 'card-drag'
			resourceId: string
			resourceType: GrantableResourceEnum
			cardId: string
			initialLayout: CardLayout.AsObject
			gridOffset: Position
			startCoords: Position
			bottomRightOffset: Position
			topLeftOffset: Position
			initalSize: Size
	  }