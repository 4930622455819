import styled, { css } from 'styled-components';

import { ActivityColorKey, ActivityColors } from '@root/store/slices/activities.colors.types';

export const ActivityColoredText = styled.span<{ colorId?: ActivityColorKey }>`
	${(p) =>
		p.colorId &&
		css`
			${ActivityColors[p.colorId].inactiveLight.background.s2.css('color')};
		`}
`
