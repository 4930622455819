import React from 'react'
import styled, { css } from 'styled-components'

export const DelayCardGrid: React.FC<{ children: any }> = ({ children }) => {
	// const [rendered, setRendered] = useState(false)
	// const [animate, setAnimate] = useState(false)

	// useLayoutEffect(() => {
	// 	setTimeout(() => {
	// 		requestAnimationFrame(() => {
	// 			setRendered(true)
	// 			setTimeout(() => {
	// 				setAnimate(true)
	// 			}, 32)
	// 		})
	// 	}, 300)
	// }, [])

	// if (!rendered) return null

	return (
		<Holder key="holder" animate={true}>
			{children}
		</Holder>
	)
}

const Holder = styled.div<{ animate: boolean }>`
	width: 100%;
	height: 100%;
	display: block;
	/* will-change: transform, opacity; */
	/* transition: 1s ease-out opacity, 0.6s ease-out transform; */

	/* opacity: 0; */
	/* transform: translateY(15px); */

	${(p) =>
		p.animate &&
		css`
			opacity: 1;
			transform: translateY(0);
		`}
`

// import React from 'react'
// import styled, { css } from 'styled-components'

// // export const DelayCardGrid: React.FC<{ children: any }> = ({ children }) => {
// // 	const [rendered, setRendered] = useState(false)
// // 	const [shown, setShown] = useState(false)

// // 	useLayoutEffect(() => {
// // 		setTimeout(() => {
// // 			requestAnimationFrame(() => {
// // 				setRendered(true)
// // 				setTimeout(() => {
// // 					setShown(true)
// // 				}, 16)
// // 			})
// // 		}, 200)
// // 	}, [])

// // 	if (!rendered) return null

// // 	return (
// // 		<Holder key="holder" shown={shown}>
// // 			{children}
// // 		</Holder>
// // 	)
// // }

// export const DelayCardGrid: React.FC<{ children: any }> = ({ children }) => {
// 	return (
// 		<Holder key="holder" shown={true}>
// 			{children}
// 		</Holder>
// 	)
// }

// const Holder = styled.div<{ shown: boolean }>`
// 	width: 100%;
// 	height: 100%;
// 	display: block;
// 	/* will-change: transform, opacity; */
// 	/* transition: 0.7s ease-out opacity, 0.7s ease-out transform; */

// 	/* opacity: 0; */
// 	/* transform: translateY(30px); */

// 	${(p) =>
// 		p.shown &&
// 		css`
// 			opacity: 1;
// 			transform: translateY(0);
// 		`}
// `
