import { LexicalEventTypeSelection, LexicalTypeLineNodeFormat, LexicalTypeLineNodeLevel } from '@clepside/clepsidejs/cards/lexical/transferTypes'
import { createContext, Dispatch, useContext } from 'react'

export type LexicalLineNodeChaserState = {
	selection?: LexicalEventTypeSelection & { holdingCardId: string } 
};
  
export type LexicalLineNodeChaserAction = { type: 'changed', selection: (LexicalEventTypeSelection & { holdingCardId: string }) | undefined }

export type LexicalLineNodeChaserCommands = {
	setLevel: (level: LexicalTypeLineNodeLevel) => void,
	setFormat: (format: LexicalTypeLineNodeFormat) => void
} | undefined

export type LexicalLineNodeChaserContextPayload = {
	ref: React.MutableRefObject<LexicalLineNodeChaserCommands>,
	dispatch: Dispatch<LexicalLineNodeChaserAction>
} | undefined
export const LexicalLineNodeChaserContext = createContext<LexicalLineNodeChaserContextPayload>(undefined);

export const useLexicalLineNodeChaserContext = () => {
	const context = useContext(LexicalLineNodeChaserContext);
	if (context === undefined) {
	  throw new Error('useCount must be used within a CountProvider');
	}
	return context;
};
