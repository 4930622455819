// source: packets_v1/cards.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var commons_core_pb = require('../commons/core_pb.js');
goog.object.extend(proto, commons_core_pb);
var entities_access_grant_v1_pb = require('../entities/access_grant_v1_pb.js');
goog.object.extend(proto, entities_access_grant_v1_pb);
goog.exportSymbol('proto.packets_v1.CardLayout', null, global);
goog.exportSymbol('proto.packets_v1.CardPacket', null, global);
goog.exportSymbol('proto.packets_v1.CardPacketData', null, global);
goog.exportSymbol('proto.packets_v1.CardPacketMeta', null, global);
goog.exportSymbol('proto.packets_v1.CardSpecialOperation', null, global);
goog.exportSymbol('proto.packets_v1.CardVerticalLayout', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.CardLayout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.CardLayout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.CardLayout.displayName = 'proto.packets_v1.CardLayout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.CardVerticalLayout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.CardVerticalLayout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.CardVerticalLayout.displayName = 'proto.packets_v1.CardVerticalLayout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.CardPacketData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.CardPacketData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.CardPacketData.displayName = 'proto.packets_v1.CardPacketData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.CardPacketMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.CardPacketMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.CardPacketMeta.displayName = 'proto.packets_v1.CardPacketMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.packets_v1.CardPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.packets_v1.CardPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.packets_v1.CardPacket.displayName = 'proto.packets_v1.CardPacket';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.CardLayout.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.CardLayout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.CardLayout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.CardLayout.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFieldWithDefault(msg, 1, 0),
    y: jspb.Message.getFieldWithDefault(msg, 2, 0),
    w: jspb.Message.getFieldWithDefault(msg, 3, 0),
    h: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.CardLayout}
 */
proto.packets_v1.CardLayout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.CardLayout;
  return proto.packets_v1.CardLayout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.CardLayout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.CardLayout}
 */
proto.packets_v1.CardLayout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setW(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setH(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.CardLayout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.CardLayout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.CardLayout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.CardLayout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getW();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getH();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 X = 1;
 * @return {number}
 */
proto.packets_v1.CardLayout.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.CardLayout} returns this
 */
proto.packets_v1.CardLayout.prototype.setX = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 Y = 2;
 * @return {number}
 */
proto.packets_v1.CardLayout.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.CardLayout} returns this
 */
proto.packets_v1.CardLayout.prototype.setY = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 W = 3;
 * @return {number}
 */
proto.packets_v1.CardLayout.prototype.getW = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.CardLayout} returns this
 */
proto.packets_v1.CardLayout.prototype.setW = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 H = 4;
 * @return {number}
 */
proto.packets_v1.CardLayout.prototype.getH = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.CardLayout} returns this
 */
proto.packets_v1.CardLayout.prototype.setH = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.CardVerticalLayout.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.CardVerticalLayout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.CardVerticalLayout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.CardVerticalLayout.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.CardVerticalLayout}
 */
proto.packets_v1.CardVerticalLayout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.CardVerticalLayout;
  return proto.packets_v1.CardVerticalLayout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.CardVerticalLayout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.CardVerticalLayout}
 */
proto.packets_v1.CardVerticalLayout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.CardVerticalLayout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.CardVerticalLayout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.CardVerticalLayout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.CardVerticalLayout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double Order = 1;
 * @return {number}
 */
proto.packets_v1.CardVerticalLayout.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.CardVerticalLayout} returns this
 */
proto.packets_v1.CardVerticalLayout.prototype.setOrder = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.CardPacketData.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.CardPacketData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.CardPacketData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.CardPacketData.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cardType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    layout: (f = msg.getLayout()) && proto.packets_v1.CardLayout.toObject(includeInstance, f),
    verticallayout: (f = msg.getVerticallayout()) && proto.packets_v1.CardVerticalLayout.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.CardPacketData}
 */
proto.packets_v1.CardPacketData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.CardPacketData;
  return proto.packets_v1.CardPacketData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.CardPacketData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.CardPacketData}
 */
proto.packets_v1.CardPacketData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceId(value);
      break;
    case 2:
      var value = /** @type {!proto.access_grant_v1.GrantableResourceEnum} */ (reader.readEnum());
      msg.setResourceType(value);
      break;
    case 3:
      var value = /** @type {!proto.commons.CardType} */ (reader.readEnum());
      msg.setCardType(value);
      break;
    case 4:
      var value = new proto.packets_v1.CardLayout;
      reader.readMessage(value,proto.packets_v1.CardLayout.deserializeBinaryFromReader);
      msg.setLayout(value);
      break;
    case 5:
      var value = new proto.packets_v1.CardVerticalLayout;
      reader.readMessage(value,proto.packets_v1.CardVerticalLayout.deserializeBinaryFromReader);
      msg.setVerticallayout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.CardPacketData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.CardPacketData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.CardPacketData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.CardPacketData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCardType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLayout();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.packets_v1.CardLayout.serializeBinaryToWriter
    );
  }
  f = message.getVerticallayout();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.packets_v1.CardVerticalLayout.serializeBinaryToWriter
    );
  }
};


/**
 * optional string resource_id = 1;
 * @return {string}
 */
proto.packets_v1.CardPacketData.prototype.getResourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.CardPacketData} returns this
 */
proto.packets_v1.CardPacketData.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional access_grant_v1.GrantableResourceEnum resource_type = 2;
 * @return {!proto.access_grant_v1.GrantableResourceEnum}
 */
proto.packets_v1.CardPacketData.prototype.getResourceType = function() {
  return /** @type {!proto.access_grant_v1.GrantableResourceEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.access_grant_v1.GrantableResourceEnum} value
 * @return {!proto.packets_v1.CardPacketData} returns this
 */
proto.packets_v1.CardPacketData.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional commons.CardType card_type = 3;
 * @return {!proto.commons.CardType}
 */
proto.packets_v1.CardPacketData.prototype.getCardType = function() {
  return /** @type {!proto.commons.CardType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.commons.CardType} value
 * @return {!proto.packets_v1.CardPacketData} returns this
 */
proto.packets_v1.CardPacketData.prototype.setCardType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional CardLayout layout = 4;
 * @return {?proto.packets_v1.CardLayout}
 */
proto.packets_v1.CardPacketData.prototype.getLayout = function() {
  return /** @type{?proto.packets_v1.CardLayout} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.CardLayout, 4));
};


/**
 * @param {?proto.packets_v1.CardLayout|undefined} value
 * @return {!proto.packets_v1.CardPacketData} returns this
*/
proto.packets_v1.CardPacketData.prototype.setLayout = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.CardPacketData} returns this
 */
proto.packets_v1.CardPacketData.prototype.clearLayout = function() {
  return this.setLayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.CardPacketData.prototype.hasLayout = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CardVerticalLayout verticalLayout = 5;
 * @return {?proto.packets_v1.CardVerticalLayout}
 */
proto.packets_v1.CardPacketData.prototype.getVerticallayout = function() {
  return /** @type{?proto.packets_v1.CardVerticalLayout} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.CardVerticalLayout, 5));
};


/**
 * @param {?proto.packets_v1.CardVerticalLayout|undefined} value
 * @return {!proto.packets_v1.CardPacketData} returns this
*/
proto.packets_v1.CardPacketData.prototype.setVerticallayout = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.CardPacketData} returns this
 */
proto.packets_v1.CardPacketData.prototype.clearVerticallayout = function() {
  return this.setVerticallayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.CardPacketData.prototype.hasVerticallayout = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.CardPacketMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.CardPacketMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.CardPacketMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.CardPacketMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceId: (f = msg.getResourceId()) && commons_core_pb.Meta.toObject(includeInstance, f),
    resourceType: (f = msg.getResourceType()) && commons_core_pb.Meta.toObject(includeInstance, f),
    cardType: (f = msg.getCardType()) && commons_core_pb.Meta.toObject(includeInstance, f),
    layout: (f = msg.getLayout()) && commons_core_pb.Meta.toObject(includeInstance, f),
    verticallayout: (f = msg.getVerticallayout()) && commons_core_pb.Meta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.CardPacketMeta}
 */
proto.packets_v1.CardPacketMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.CardPacketMeta;
  return proto.packets_v1.CardPacketMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.CardPacketMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.CardPacketMeta}
 */
proto.packets_v1.CardPacketMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setResourceId(value);
      break;
    case 2:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setResourceType(value);
      break;
    case 3:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setCardType(value);
      break;
    case 4:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setLayout(value);
      break;
    case 5:
      var value = new commons_core_pb.Meta;
      reader.readMessage(value,commons_core_pb.Meta.deserializeBinaryFromReader);
      msg.setVerticallayout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.CardPacketMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.CardPacketMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.CardPacketMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.CardPacketMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getResourceType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getCardType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getLayout();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
  f = message.getVerticallayout();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      commons_core_pb.Meta.serializeBinaryToWriter
    );
  }
};


/**
 * optional commons.Meta resource_id = 1;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.CardPacketMeta.prototype.getResourceId = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 1));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.CardPacketMeta} returns this
*/
proto.packets_v1.CardPacketMeta.prototype.setResourceId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.CardPacketMeta} returns this
 */
proto.packets_v1.CardPacketMeta.prototype.clearResourceId = function() {
  return this.setResourceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.CardPacketMeta.prototype.hasResourceId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional commons.Meta resource_type = 2;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.CardPacketMeta.prototype.getResourceType = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 2));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.CardPacketMeta} returns this
*/
proto.packets_v1.CardPacketMeta.prototype.setResourceType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.CardPacketMeta} returns this
 */
proto.packets_v1.CardPacketMeta.prototype.clearResourceType = function() {
  return this.setResourceType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.CardPacketMeta.prototype.hasResourceType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional commons.Meta card_type = 3;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.CardPacketMeta.prototype.getCardType = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 3));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.CardPacketMeta} returns this
*/
proto.packets_v1.CardPacketMeta.prototype.setCardType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.CardPacketMeta} returns this
 */
proto.packets_v1.CardPacketMeta.prototype.clearCardType = function() {
  return this.setCardType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.CardPacketMeta.prototype.hasCardType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional commons.Meta layout = 4;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.CardPacketMeta.prototype.getLayout = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 4));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.CardPacketMeta} returns this
*/
proto.packets_v1.CardPacketMeta.prototype.setLayout = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.CardPacketMeta} returns this
 */
proto.packets_v1.CardPacketMeta.prototype.clearLayout = function() {
  return this.setLayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.CardPacketMeta.prototype.hasLayout = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional commons.Meta verticalLayout = 5;
 * @return {?proto.commons.Meta}
 */
proto.packets_v1.CardPacketMeta.prototype.getVerticallayout = function() {
  return /** @type{?proto.commons.Meta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.Meta, 5));
};


/**
 * @param {?proto.commons.Meta|undefined} value
 * @return {!proto.packets_v1.CardPacketMeta} returns this
*/
proto.packets_v1.CardPacketMeta.prototype.setVerticallayout = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.CardPacketMeta} returns this
 */
proto.packets_v1.CardPacketMeta.prototype.clearVerticallayout = function() {
  return this.setVerticallayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.CardPacketMeta.prototype.hasVerticallayout = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.packets_v1.CardPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.packets_v1.CardPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.packets_v1.CardPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.CardPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deleted: (f = msg.getDeleted()) && commons_core_pb.DeleteMeta.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.packets_v1.CardPacketData.toObject(includeInstance, f),
    meta: (f = msg.getMeta()) && proto.packets_v1.CardPacketMeta.toObject(includeInstance, f),
    lastModifiedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    executeSpecialOperation: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    cloudedAt: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.packets_v1.CardPacket}
 */
proto.packets_v1.CardPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.packets_v1.CardPacket;
  return proto.packets_v1.CardPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.packets_v1.CardPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.packets_v1.CardPacket}
 */
proto.packets_v1.CardPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.commons.PacketType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new commons_core_pb.DeleteMeta;
      reader.readMessage(value,commons_core_pb.DeleteMeta.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 4:
      var value = new proto.packets_v1.CardPacketData;
      reader.readMessage(value,proto.packets_v1.CardPacketData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = new proto.packets_v1.CardPacketMeta;
      reader.readMessage(value,proto.packets_v1.CardPacketMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastModifiedAt(value);
      break;
    case 7:
      var value = /** @type {!proto.packets_v1.CardSpecialOperation} */ (reader.readEnum());
      msg.setExecuteSpecialOperation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloudedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.packets_v1.CardPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.packets_v1.CardPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.packets_v1.CardPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.packets_v1.CardPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commons_core_pb.DeleteMeta.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.packets_v1.CardPacketData.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.packets_v1.CardPacketMeta.serializeBinaryToWriter
    );
  }
  f = message.getLastModifiedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getExecuteSpecialOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCloudedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.packets_v1.CardPacket.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.packets_v1.CardPacket} returns this
 */
proto.packets_v1.CardPacket.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional commons.PacketType type = 2;
 * @return {!proto.commons.PacketType}
 */
proto.packets_v1.CardPacket.prototype.getType = function() {
  return /** @type {!proto.commons.PacketType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commons.PacketType} value
 * @return {!proto.packets_v1.CardPacket} returns this
 */
proto.packets_v1.CardPacket.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional commons.DeleteMeta deleted = 3;
 * @return {?proto.commons.DeleteMeta}
 */
proto.packets_v1.CardPacket.prototype.getDeleted = function() {
  return /** @type{?proto.commons.DeleteMeta} */ (
    jspb.Message.getWrapperField(this, commons_core_pb.DeleteMeta, 3));
};


/**
 * @param {?proto.commons.DeleteMeta|undefined} value
 * @return {!proto.packets_v1.CardPacket} returns this
*/
proto.packets_v1.CardPacket.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.CardPacket} returns this
 */
proto.packets_v1.CardPacket.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.CardPacket.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CardPacketData data = 4;
 * @return {?proto.packets_v1.CardPacketData}
 */
proto.packets_v1.CardPacket.prototype.getData = function() {
  return /** @type{?proto.packets_v1.CardPacketData} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.CardPacketData, 4));
};


/**
 * @param {?proto.packets_v1.CardPacketData|undefined} value
 * @return {!proto.packets_v1.CardPacket} returns this
*/
proto.packets_v1.CardPacket.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.CardPacket} returns this
 */
proto.packets_v1.CardPacket.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.CardPacket.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CardPacketMeta meta = 5;
 * @return {?proto.packets_v1.CardPacketMeta}
 */
proto.packets_v1.CardPacket.prototype.getMeta = function() {
  return /** @type{?proto.packets_v1.CardPacketMeta} */ (
    jspb.Message.getWrapperField(this, proto.packets_v1.CardPacketMeta, 5));
};


/**
 * @param {?proto.packets_v1.CardPacketMeta|undefined} value
 * @return {!proto.packets_v1.CardPacket} returns this
*/
proto.packets_v1.CardPacket.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.packets_v1.CardPacket} returns this
 */
proto.packets_v1.CardPacket.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.packets_v1.CardPacket.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 last_modified_at = 6;
 * @return {number}
 */
proto.packets_v1.CardPacket.prototype.getLastModifiedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.CardPacket} returns this
 */
proto.packets_v1.CardPacket.prototype.setLastModifiedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional CardSpecialOperation execute_special_operation = 7;
 * @return {!proto.packets_v1.CardSpecialOperation}
 */
proto.packets_v1.CardPacket.prototype.getExecuteSpecialOperation = function() {
  return /** @type {!proto.packets_v1.CardSpecialOperation} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.packets_v1.CardSpecialOperation} value
 * @return {!proto.packets_v1.CardPacket} returns this
 */
proto.packets_v1.CardPacket.prototype.setExecuteSpecialOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.packets_v1.CardPacket.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.CardPacket} returns this
 */
proto.packets_v1.CardPacket.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 clouded_at = 9;
 * @return {number}
 */
proto.packets_v1.CardPacket.prototype.getCloudedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.packets_v1.CardPacket} returns this
 */
proto.packets_v1.CardPacket.prototype.setCloudedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * @enum {number}
 */
proto.packets_v1.CardSpecialOperation = {
  CARDSPECIALOPERATION_DO_NOT_USE: 0,
  CARD_MOVEMENT: 1
};

goog.object.extend(exports, proto.packets_v1);
