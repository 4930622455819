import { css } from 'styled-components';

const Color = '54, 65, 76'
export const ActivityGradient = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	background: linear-gradient(45deg, rgba(${Color}, 0.5) 0%, rgba(${Color}, 0.7) 42%, rgba(${Color}, 0.86) 100%);
	opacity: 0.8;
`
