import { Navigate, Route, Routes } from 'react-router';
import styled from 'styled-components';

import { SettingsAccount } from './Settings.account';
import { SettingsAppearence } from './Settings.appearence';
import { SettingsInvites } from './Settings.invites';
import { SettingsProfile } from './Settings.profile';

export const SettingsRouter = () => {
	return (
		<Container>
			<Routes>
				<Route path="/account" element={<SettingsAccount />} />
				<Route path="/profile" element={<SettingsProfile />} />
				<Route path="/appearence" element={<SettingsAppearence />} />
				<Route path="/notifications" element={<SettingsAppearence />} />
				<Route path="/invites" element={<SettingsInvites />} />
				<Route path="*" element={<Navigate to="/settings/account" />} />
			</Routes>
		</Container>
	)
}

const Container = styled.div`
	width: 600px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-height: 30px;
	flex-shrink: 0;
	flex-grow: 1;
`
