import { put } from '@redux-saga/core/effects';
import { createSlice } from '@reduxjs/toolkit';

import { State } from './billingTypes';
import { grpcActions } from './grpc.DEPRECATED';

const billingSlice = createSlice({
	name: 'billing',
	initialState: State,
	reducers: {
		subscribe: () => {},
	},
})

export const billingSagas = {
	*subscribe() {
		yield put(grpcActions.generatePaymentIntent({}))
	},
}

export const billingActions = billingSlice.actions
export const billingReducers = billingSlice.reducer
