import { CardType } from '@clepside/clepsidejs/lib/commons/core_pb';
import { GrantableResourceEnum } from '@clepside/clepsidejs/lib/entities/access_grant_v1_pb';
import { useCard } from '@root/store/selectors/useCards';
import { useLayoutEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Card } from './card';

type CardListDimension = 'small' | 'medium' | 'large'
export const CardListCard: React.FC<{ resourceId: string; resourceType: GrantableResourceEnum; cardId: string; className?: string }> = ({
	cardId,
	resourceId,
	resourceType,
	className,
}) => {
	// const { select, selectedClassName } = useSelectable(SelectableTypes.CARD, cardId, 'Sessions.<0>.Cards', resourceId)
	const card = useCard(cardId)
	const [dimension, setDimension] = useState<CardListDimension | undefined>(undefined)

	useLayoutEffect(() => {
		if (card?.cardType == CardType.LINK) {
			if (!document.querySelector(`.card-${cardId} .youtube-holder`)) {
				setDimension('small')
			}
		} else {
			setDimension('medium')
		}
	}, [cardId, card])
	return (
		<CardWrapper className={`${className} card-${cardId} card board-card`} dimension={dimension} data-id={cardId}>
			<Card resourceId={resourceId} resourceType={resourceType} cardId={cardId} key={cardId} />
		</CardWrapper>
	)
}

const CardWrapper = styled.div<{ dimension: CardListDimension | undefined }>`
	display: flex;
	border: none;
	z-index: 1;
	transition: 0.3s ease background-color;
	position: relative;
	min-height: 320px;
	max-height: 540px;
	height: 30vh;
	width: 30vw;
	max-width: 700px;
	margin-bottom: 20px;

	${p => p.dimension == undefined && css`
		display: none;
	`}

	${(p) =>
		p.dimension == 'small' &&
		css`
			max-height: 60px;
			min-height: 60px;
		`}

	${(p) => p.theme.text.regular.r.css('color')};

	.show-only-on-hover {
		transition: 0.6s ease transform;
		opacity: 0;

		&.bottom {
			transform: translateY(10px);
		}
	}

	&.selected {
		&:before {
			position: absolute;
			top: -1.5px;
			left: -1.5px;
			width: calc(100% + 3px);
			height: calc(100% + 3px);
			content: '';
			display: block;
			border-radius: 19.6px;
			box-shadow: 0px 0px 0px 1.5px #fff !important ;
		}
		.show-only-on-hover {
			opacity: 1;
			display: flex;
			transform: translateY(0) translateX(0);
		}
	}
`
