import React, { useEffect } from 'react';

export const useCardPaste = () => {
	useEffect(() => {
		const pasteHandler = async (event: ClipboardEvent) => {
			const clipboardText = await navigator.clipboard.readText()

			// Link Handling
			try {
				const urlPattern = /(http(s?):\/\/[^\s]*)/
				const match = clipboardText.match(urlPattern)
				if (match?.[0]) console.log('Pasted a link:', match?.[0])
			} catch (err) {
				console.error('Error reading clipboard:', err)
			}
		}

		window.addEventListener('paste', pasteHandler)

		return () => {
			window.removeEventListener('paste', pasteHandler)
		}
	}, [])
}
